import * as React from "react";
import * as classNames from "classnames";
import * as ReactModal from "react-modal";

import {Icon} from "../components/icon";
import {CloseSvgIcon} from "../components/svg_icons/CloseSvgIcon";


type ModalType = "full" | "window";

interface IProps {
    type?: ModalType;
    onModalClose?(event: any): void;
    modalState: boolean;
    closeTimeout?: number;
    className?: string;
    overlayClassName?: string;
    contentLabel?: string;
    closeButton?: boolean;
    hideHeader?: boolean;
    header?: string | JSX.Element;
    headerLogo?: boolean;
    headerClassName?: string;
}

export class Modal extends React.Component<IProps, {}> {

    public static defaultProps = {
        type: "full",
        closeTimeout: 150,
        contentLabel: "modal",
        closeButton: true
    };

    private isOpened = false;
    private timeoutHandler = 0;
    private pxFromTopViewport = 0;

    /**
     * Lifecycle
     */

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.type === "full") {
            if (!this.isOpened && this.props.modalState) {
                this.openLogic();
            }
            if (this.isOpened && !this.props.modalState) {
                this.closeLogic();
            }
        }
    }

    public componentWillUnmount() {
        if (this.props.type === "full" && this.isOpened) {
            this.closeLogic();
        }
    }

    private openLogic = () => {
        // Fix the body
        this.isOpened = true;
        this.pxFromTopViewport = window.scrollY;
        const root = document.getElementById("root");

        if (root) {
            this.timeoutHandler = window.setTimeout(() => {
                root.style.position = "fixed";
                root.style.top = `-${this.pxFromTopViewport}`;
                root.style.left = "0";
                root.style.right = "0";
            }, 200);
        }
    };

    private closeLogic = () => {
        // Unfix the body
        this.isOpened = false;
        const root = document.getElementById("root");

        if (root) {
            clearTimeout(this.timeoutHandler);
            root.style.position = "";
            root.style.top = "";
            root.style.left = "";
            root.style.right = "";

            if (this.pxFromTopViewport) {
                window.setTimeout(() => {
                    // `scrollTo` did not work without `setTimeout`, maybe it was scrolling modal content
                    window.scrollTo(0, this.pxFromTopViewport);
                    this.pxFromTopViewport = 0;
                }, 0);
            }
        }
    };

    /**
     * Callback
     */

    private onModalClose = (e: (React.MouseEvent | React.KeyboardEvent)) => {
        e.stopPropagation();
        this.props.onModalClose && this.props.onModalClose(e);
    };

    /**
     * Render
     */

    public render() {
        const {props} = this;

        const className = classNames(props.className,
            {"modal-full": props.type === "full"},
            {"modal-window": props.type === "window"}
        );

        const overlayClassName = classNames("modal-overlay", props.overlayClassName);
        const headerClassName = classNames("modal-header fg-1 fs18 truncate psr", props.headerClassName);

        const closeButtonClassName = classNames("btn-close curp fg-0 df fai-center");
        const closeButton = (
            <div className={closeButtonClassName} onClick={this.onModalClose}>
                <CloseSvgIcon width={12} height={12} color="#222" />
            </div>
        );
        const headerLogo = <div className="modal-header-logo" />;

        const modalHeader = (
            <div className="modal-header-holder df fs-0">
                <div className={headerClassName}>
                    {props.header}
                    {props.headerLogo && headerLogo}
                </div>
                {closeButton}
            </div>
        );

        return (
            <ReactModal
                isOpen={props.modalState}
                onRequestClose={this.onModalClose}
                closeTimeoutMS={props.closeTimeout}
                className={className}
                overlayClassName={overlayClassName}
                contentLabel={props.contentLabel || ""}
                ariaHideApp={false}
            >
                {!props.hideHeader && modalHeader}
                {props.children}
            </ReactModal>
        );
    }
}
