import {Dispatch} from "redux";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {nphApiLink} from "../../project/route/nph_api_link";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {PAGE_PARAM} from "../../../../shared/ts/constants/queryParams";


export interface IPlanOfferGallery {
    pk: number;
    image: string;
    sort: number;
}
export interface IPlanOffer {
    address: string;
    construction_date_range: {
        lower: string;
        upper: string;
    };
    construction_end_date_quarter: string;
    floors_above_ground_range: {
        lower: number;
        upper: number;
        bounds: string;
    };
    gallery: IPlanOfferGallery[];
    main_image: string;
    name: string;
    pk: number;
    price_type: null;
    stats: {};
    type: {
        pk: number,
        name_plural: string;
    };
    vendor: {
        name: string;
        pk: number;
        slug: string;
        logo: string;
    };
}

/**
 * Offer detail
 */

const FETCH_PLAN_OFFER_DETAIL = "offer/plan-offers/FETCH_PLAN_OFFER_DETAIL";
export const fetchPlanOfferTypes = createRequestActionTypes(FETCH_PLAN_OFFER_DETAIL);

export const fetchPlanOffer = (offerId: number) => (dispatch: Dispatch): Promise<IPlanOffer | null> => {
    dispatch({type: fetchPlanOfferTypes.start});
    const url = nphApiLink.offers.detail({offerId});
    return getRequest(url)
        .then((res: IPlanOffer) => {
            dispatch({type: fetchPlanOfferTypes.success, result: res});
            return res;
        })
        .catch(catch404(err => {
            dispatch({type: fetchPlanOfferTypes.error});
            return null;
        }));
};

export const resetPlanOffer = () => ({type: fetchPlanOfferTypes.reset});

/**
 * Offer list
 */

const FETCH_PLAN_OFFER_LIST = "offer/plan-offers/FETCH_PLAN_OFFER_LIST";
export const fetchPlanOfferListTypes = createRequestActionTypes(FETCH_PLAN_OFFER_LIST);

export const fetchPlanOfferList = (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch({type: fetchPlanOfferListTypes.start, latestQuery: queryObj});
    const url = appendQueryString(nphApiLink.offers.list(), queryObj);
    return getRequest(url)
        .then((res) => {
            dispatch({
                type: fetchPlanOfferListTypes.success,
                results: res.results,
                pagination: {
                    pageSize: res.page_size,
                    total: res.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }});
            return res.results;
        })
        .catch(catch404(err => {
            dispatch({type: fetchPlanOfferListTypes.error});
            return null;
        }));
};

export const resetPlanOfferList = () => ({type: fetchPlanOfferListTypes.reset});
