import * as React from "react";

interface IProps {
    className?: string;
    width?: number;
    height?: number;
}

export const DeleteIcon = (props: IProps) => {

    const className = props.className ? props.className : "";
    const width = props.width ? props.width : 19;
    const height = props.height ? props.height : 20;

    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <path fill="#a6a6a6" fillRule="evenodd"
                  d="M7.33333333,-0.000166666667 L11,-0.000166666667 C12.34,-0.000166666667 13.2576,0.987481333 13.328864,2.47764709 L13.3333333,2.6665 L13.3333333,3.33316667 L17.5,3.33316667 C17.9608333,3.33316667 18.3333333,3.70566667 18.3333333,4.1665 C18.3333333,4.59188462 18.0159369,4.94200296 17.6046488,4.99335025 L17.5,4.99983333 L16.6666667,4.99983333 L16.6666667,17.3331667 C16.6666667,18.8466235 15.2409465,19.9180684 13.9038062,19.9953586 L13.75,19.9998333 L4.58333333,19.9998333 C3.25524691,19.9998333 1.78033836,18.9844424 1.67290767,17.5058033 L1.66666667,17.3331667 L1.66666667,4.99983333 L0.833333333,4.99983333 C0.3725,4.99983333 -6.03961325e-13,4.62733333 -6.03961325e-13,4.1665 C-6.03961325e-13,3.74111538 0.31739645,3.39099704 0.72868457,3.33964975 L0.833333333,3.33316667 L5,3.33316667 L5,2.6665 C5,1.1353 5.864,0.086404 7.168064,0.00494224 L7.33333333,-0.000166666667 L11,-0.000166666667 Z M15,4.99983333 L3.33333333,4.99983333 L3.33333333,17.3331667 C3.33333333,17.7866111 3.89302222,18.264737 4.46156741,18.3264719 L4.58333333,18.3331667 L13.75,18.3331667 C14.317,18.3331667 14.9144889,17.8852704 14.9916341,17.4305504 L15,17.3331667 L15,4.99983333 Z M7.5,8.33333333 C7.96083333,8.33333333 8.33333333,8.70583333 8.33333333,9.16666667 L8.33333333,14.1666667 C8.33333333,14.6275 7.96083333,15 7.5,15 C7.03916667,15 6.66666667,14.6275 6.66666667,14.1666667 L6.66666667,9.16666667 C6.66666667,8.70583333 7.03916667,8.33333333 7.5,8.33333333 Z M10.8333333,8.33333333 C11.2941667,8.33333333 11.6666667,8.70583333 11.6666667,9.16666667 L11.6666667,14.1666667 C11.6666667,14.6275 11.2941667,15 10.8333333,15 C10.3725,15 10,14.6275 10,14.1666667 L10,9.16666667 C10,8.70583333 10.3725,8.33333333 10.8333333,8.33333333 Z M11,1.6665 L7.33333333,1.6665 C6.82184524,1.6665 6.68543367,2.13570493 6.66855594,2.56769989 L6.66666667,2.6665 L6.66666667,3.33316667 L11.6666667,3.33316667 L11.6666667,2.6665 C11.6666667,2.21066667 11.5508333,1.6665 11,1.6665 Z"/>
        </svg>
    );
};
