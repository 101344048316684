import {isArray, each} from "lodash";

import {RequestActionTypes, createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {
    Action,
    Dispatch,
    ErrorAction,
    LatestQueryAction,
    PaginatedResponse,
    PaginatedResponseAction
} from "../../../../../shared/ts/interfaces/dispatch";
import {getRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {Dictionary} from "../../../../../shared/ts/interfaces/structure";
import {PAGE_PARAM} from "../../../../../shared/ts/constants/queryParams";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {addErrorAlert} from "../../../shared/alerts/actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {appendQueryString} from "../../../../../shared/ts/helpers/append_query_string";
import {IReel} from "../../reducers/reels";

export const createReelsFetch = (type: string, actionTypes: RequestActionTypes) => (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch<LatestQueryAction>({ type: actionTypes.start, latestQuery: queryObj });

    const urlString = appendQueryString(nphApiLink.reels.list(), queryObj);

    return getRequest(urlString)
        .then((json: PaginatedResponse<IReel>) => {
            dispatch<PaginatedResponseAction<IReel>>({
                type: actionTypes.success,
                results: json.results,
                pagination: {
                    pageSize: json.page_size,
                    total: json.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }
            });
            return json;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, type)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", type));
            }
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};
export const createReelsDetailFetch = (type: string, actionTypes: RequestActionTypes) => (reelId: number) => (dispatch: Dispatch) => {
    const urlString = nphApiLink.reels.view({reelId});

    return getRequest(urlString)
        .then((json: IReel) => {
            dispatch<Action>({
                type: actionTypes.success
            });
            return json;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};

export const REEL_FETCH = "reel/FETCH";
export const reelRequest = createRequestActionTypes(REEL_FETCH);
export const reelFetch = createReelsFetch(REEL_FETCH, reelRequest);

export const REEL_FETCH_DETAIL = "reel/FETCH_DETAIL";
export const reelDetailRequest = createRequestActionTypes(REEL_FETCH_DETAIL);
export const reelFetchDetail = createReelsDetailFetch(REEL_FETCH_DETAIL, reelDetailRequest);

export const invalidateReelList = () => ({type: reelRequest.reset});
