import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import Main from "../../../shared/layout/Main";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import {Button} from "../../../shared/button/Button";
import ContentBody from "../../../project/components/content/ContentBody";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {RouterState} from "../../../../../shared/ts/interfaces/router";
import {createFileName, urlBase64, urlToCroppedBase64} from "../../../../../shared/ts/helpers/urlToCroppedBase64";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {Offer} from "../../../shared/models/Offer";
import {fetchOffer, fetchOfferDetailForNatives, resetOfferDetailForNative} from "../../../offer/actions/fetch";
import {NativeCreationForm} from "../../native/components/NativeCreationForm"; // TODO: change to new `MonthlyCreationForm`
import {addMobileNativesFormActions, clearAddEditErrors, clearAddEditViewState} from "../../../advertisments/actions/natives/add";
import {nativesFetchDetail} from "../../../advertisments/actions/natives/fetch";
import {nativeEditPatch} from "../../../advertisments/actions/natives/edit";
import {INativesForms} from "../../../advertisments/reducers/natives";
import {saveAsHtml} from "../../native/actions/save_as_html";
import {htmlToImgDownload} from "../../native/actions/html_to_img";
import {IMonthlyCreation, MonthlyCreationForm} from "../components/MonthlyCreationForm";
import {MonthlyCreationAdModal} from "../components/MonthlyCreationAdModal";
import {CreationType} from "../../CreationType";

interface IStateProps {
    nativesDetail: INativesForms;
    nativesOfferDetail: Offer | null;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    mobileFormActions: FormActions;
    fetchOfferDetailForNatives: (id: number | string) => Promise<any>;
    actions: {
        fetchOffer: typeof fetchOffer;
        nativeEditPatch: typeof nativeEditPatch;
        nativesFetchDetail: typeof nativesFetchDetail;
        clearAddEditErrors: typeof clearAddEditErrors;
        clearAddEditViewState: typeof clearAddEditViewState;
        resetOfferDetailForNative: typeof resetOfferDetailForNative;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
    };
}

interface IOwnProps extends RouterState {
    hoc: HocModalix;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    initialImage: string | Blob; // base64 representation of offer's main image
}

@modalix(MonthlyCreationAdModal)
class MonthlyCreationEditViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            initialImage: ""
        };
    }

    /**
     * Lifecycle
     */

    public async componentDidMount() {
        const creationId = parseInt(this.props.params["id"], 10);
        const creationDetail: IMonthlyCreation = await this.props.actions.nativesFetchDetail(creationId) as any; // ANY: bind action typing problem

        if (creationDetail.offer) {
            try {
                await this.props.fetchOfferDetailForNatives(creationDetail.offer);
            } catch (e) {
                console.error('Fetch offer error:', e)
            }
        }

        this.initializeFormData(creationDetail);
    }

    public componentWillUnmount(): void {
        this.props.actions.clearAddEditViewState();
        this.props.actions.resetOfferDetailForNative();
    }

    private initializeFormData = (native: IMonthlyCreation) => {
        const rawFormValues = NativeCreationForm.fromJSON(native);
        this.props.mobileFormActions.update(rawFormValues);
        this.prepareFormImages(native);
    };

    // This is hack over `NativeCreationForm.fromJSON(native)` and overwrites previous `mobileFormActions.update` because is async.
    // Unfortunately we upload base64 blob every time we click save, even though we use PATCH (and should update only changes).
    // That enables crop button for every file URL.
    private prepareFormImages = async (data: IMonthlyCreation) => {
        this.setState({initialImage: data.mobile_image} as IState);

        const hasMobileValues = Boolean(data.mobile_image);
        hasMobileValues && this.props.mobileFormActions.update({
            mobile_image: {
                ...this.props.nativesDetail.mobileForm.mobile_image,
                selected: `main_${createFileName(data.mobile_name, data.id, "mobile")}`,
                base64value: "",
                stored: data.mobile_image
            }
        });
    };

    /**
     * Submit form
     */

    private onSubmit = (shouldGenerate: boolean = false, toHtml: boolean = false) => {
        const creation: IMonthlyCreation = {
            ...MonthlyCreationForm.toJSON(this.props.nativesDetail.mobileForm),
            type: CreationType.MONTHLY
        };
        this.handleFormSubmit(creation, shouldGenerate, toHtml);
    };

    private handleFormSubmit = (creation: IMonthlyCreation, shouldGenerate: boolean, toHtml: boolean) => {
        const creationId = parseInt(this.props.params["id"], 10);

        (this.props.actions.nativeEditPatch(creationId, creation as any) as any) // ANY: actions are based on old creation types
            .then((response: IMonthlyCreation) => {
                if (Boolean(response)) {
                    if (shouldGenerate) {
                        if (toHtml) {
                            this.handleSaveHtml(response.mobile_name, response.id);
                        } else {
                            this.handleConvert(response.mobile_name, response.id);
                        }
                    }

                    this.props.mobileFormActions.update({
                        mobile_image: {
                            ...this.props.nativesDetail.mobileForm.mobile_image,
                            selected: `main_${createFileName(response.mobile_name, response.id, "mobile")}`,
                            base64value: "",
                            stored: response.mobile_image
                        }
                    })

                    this.props.actions.clearAddEditErrors();
                }
            });
    };

    private handleConvert = (name: string, id: number) => {
        this.openPreviewModal();
        htmlToImgDownload(document.getElementById("v2-ad")!, name, id);
        // do we want to wait?
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    private handleSaveHtml = (name: string, id: number) => {
        this.openPreviewModal();
        const element = document.getElementById("v2-ad")!;
        saveAsHtml(element, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    /**
     * Callback
     */

    private openPreviewModal = () => {
        this.props.hoc.modalix.show<any>({
            monthlyCreationFormData: {
                ...this.props.nativesDetail.mobileForm,
            },
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    private onImageCropped = (key: ("mobile_image"), image?: string) => {
        // similar to mobileForm triggerCrop logic is overly complex for the purpose of (previous and) future possible different size images
        const imgOptions = image ?
            {base64value: image} :
            {
                base64value: this.state.initialImage,
                selected: this.props.nativesOfferDetail ? createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk) : "initial_image"
            };
        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.nativesDetail.mobileForm[key],
                ...imgOptions
            }
        });
    };

    /**
     * Render
     */

    public render() {
        const {request} = this.props.nativesDetail;
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklamy natywne</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd Reklam
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit()}
                            >
                                Zapisz Reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(true)}
                            >
                                Zapisz i Wygeneruj Reklamy
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <MonthlyCreationForm
                            onSubmit={() => this.onSubmit(false, false)}
                            errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                            values={this.props.nativesDetail.mobileForm}
                            updateFormAction={this.props.mobileFormActions.update}
                            handlePreview={() => this.openPreviewModal()}
                            submitAndGenerate={() => this.onSubmit(true, false)}
                            submitAndGenerateHtml={() => this.onSubmit(true, true)}
                            updateImageAfterCropping={this.onImageCropped}
                            initialImage={this.state.initialImage}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const MonthlyCreationEditView = connect(mapStateToProps, mapActionsToProps)(MonthlyCreationEditViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        nativesDetail: store.adverts.natives.detail,
        nativesOfferDetail: store.adverts.natives.nativesOfferDetail,
        contextPanelOpened: store.adverts.natives.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        mobileFormActions: bindActionCreators(addMobileNativesFormActions, dispatch),
        fetchOfferDetailForNatives: fetchOfferDetailForNatives(dispatch),
        actions: bindActionCreators({
            fetchOffer,
            nativeEditPatch,
            nativesFetchDetail,
            openPanel,
            closePanel,
            openTab,
            clearAddEditViewState,
            clearAddEditErrors,
            resetOfferDetailForNative,
        }, dispatch)
    };
}
