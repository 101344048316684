import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";

interface FlexScrollSectionProps extends StyleProps {}

export const FlexScrollSectionC: React.SFC<FlexScrollSectionProps> = props => {
    const className = classnames("flex-scroll-section dn fg-1 fs-1 fb-a overflow-y-a", props.className);

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

const FlexScrollSection = FlexScrollSectionC;
export default FlexScrollSection;
