/**
 * This component probably renders only in `Edit` view and only when campaign-status is `ready-to-send`.
 * That's why we do not need `onSubmit` callback in `Add` view.
 */

import * as React from "react";
import {reduxForm, FormProps} from "redux-form";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {Dictionary} from "../../../../../shared/ts/interfaces/structure";
import Textarea from "../../../shared/form/fields/textarea/Textarea";
import {createGenerateFieldProps} from "../../../shared/form/generate_form_props";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {Button} from "../../../shared/button/Button";



export interface CampaignTestSmsFormProps extends FormProps<Object, {}, {}> {
    onSubmit?: (values: Object) => void;
    request?: RequestState;
    fieldErrors?: Dictionary<string[]> | null;
    initialValues?: {test_phones?: string};
}


export const CampaignTestSmsFormName = "CampaignTestSmsForm";
export const CampaignTestSmsFormFields = {
    test_phones: Textarea
};

@reduxForm({form: CampaignTestSmsFormName, destroyOnUnmount: false})
export class CampaignTestSmsForm extends React.Component<CampaignTestSmsFormProps, {}> {

    private submit?: React.EventHandler<any>;
    private fieldProps = createGenerateFieldProps(this);

    constructor(props: CampaignTestSmsFormProps) {
        super(props);
        if (props.handleSubmit) {
            this.submit = props.handleSubmit((values: Object, dispatch: Dispatch) => {
                props.onSubmit!(values);
            });
        }
    }

    public render(): JSX.Element {
        return (
            <form onSubmit={this.submit}>
                <Textarea {...this.fieldProps("test_phones")} label="Telefony do wiadomości testowej"/>

                <div className="tar">
                    <Button
                        color="success"
                        className="ttu w100"
                        type="submit"
                        submitting={this.props.request === RequestState.Waiting}
                    >
                        Wyślij test
                    </Button>
                </div>
            </form>
        );
    }
}
