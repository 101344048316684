import * as React from "react";

interface IProps {
    img: string;
}

export const OfferGalleryImageListItemImg = (props: IProps) => {
    return (
        <div className="gallery-tags-img" style={{backgroundImage: `url(${props.img})`}}>
            {``}
        </div>
    );
};
