import * as React from "react";
import * as _ from "lodash";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {Dispatch, Action, ErrorAction} from "../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../shared/ts/helpers/errors";
import {campaignEditPatchTypes} from "./edit";
import {addErrorAlert, addSuccessAlert} from "../../shared/alerts/actions";
import {CampaignFormFields} from "../components/CampaignForm";
import {CampaignTestSmsFormFields} from "../components/context_panel/CampaignTestSmsForm";
import {nphApiLink} from "../../project/route/nph_api_link";


export const CAMPAIGN_TEST_SMS = "campaign/TEST_SMS";
export const campaignTestSmsRequest = createRequestActionTypes(CAMPAIGN_TEST_SMS);
export const campaignSmsTest = (uuid: string, values: Dictionary<string>) => (dispatch: Dispatch) => {
    dispatch<Action>({type: campaignTestSmsRequest.start});

    const postUrl = nphApiLink.campaigns.smsTest({id: uuid});
    return postRequest(postUrl, values)
        .then((res: any) => {
            dispatch<Action>({type: campaignTestSmsRequest.success});
            dispatch<ErrorAction>({type: campaignEditPatchTypes.error, error: null}); // clear campaign form errors

            if (!_.isEmpty(res.phone_list_sent)) {
                const phones = _.map(res.phone_list_sent, (phone: any, idx: number) => <div key={idx}>{phone}<br/></div>);
                const successAlertMsg = <span>Testowego smsa wysłano pod następujące numery: {phones} </span>;
                dispatch(addSuccessAlert(successAlertMsg, null, false, 0));
            }
            return res;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;

            // CampaignTestSmsForm errors
            const testFieldErrors = _.pick(appError.fieldErrors, _.keys(CampaignTestSmsFormFields));
            if (_.isEmpty(testFieldErrors)) {
                dispatch<Action>({type: campaignTestSmsRequest.success});
            }
            else {
                dispatch<ErrorAction>({type: campaignTestSmsRequest.error, error: appError});
            }

            // CampaignForm errors
            const campaignFieldErrors = _.pick(appError.fieldErrors, _.keys(CampaignFormFields));
            if (_.isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                _.each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, uuid)));
            }
            else if (!_.isEmpty(campaignFieldErrors)) {
                dispatch(addErrorAlert("Popraw błędy formularza kampanii", uuid));
            }
            // always update `appErrors`, when no `if` condition match, errors will contain no `CampaignForm` fields, so errors won't display
            dispatch<ErrorAction>({type: campaignEditPatchTypes.error, error: appError});
            return null;
        }));
};


export const CAMPAIGN_UPDATE_SUMMARY = "campaign/UPDATE_SUMMARY";
export function updateSummary(stats: Dictionary<string>): any {
    return {
        type: CAMPAIGN_UPDATE_SUMMARY,
        stats
    };
}

export const CAMPAIGN_INVALIDATE_SUMMARY = "campaign/INVALIDATE_SUMMARY";
export function invalidateSummary(): Action {
    return {
        type: CAMPAIGN_INVALIDATE_SUMMARY
    };
}



export const CAMPAIGN_FETCH_STATS = "campaign/FETCH_STATS";
export const campaignFetchStatsRequest = createRequestActionTypes(CAMPAIGN_FETCH_STATS);
export interface CampaignFetchStats {regions: number[], sms_text: string, sms_notification_text: string, recipients_months_range: string}
export const fetchCampaignStats = (data: CampaignFetchStats) => (dispatch: Dispatch) => {
    dispatch<Action>({type: campaignFetchStatsRequest.start});
    const {regions, sms_text, sms_notification_text, recipients_months_range} = data;
    const postUrl = nphApiLink.campaigns.stats();
    const postData = {
        regions,
        sms_text,
        sms_notification_text,
        recipients_months_range
    };
    return postRequest(postUrl, postData)
        .then((res: any) => {
            dispatch(updateSummary(res));
            return res;
        })
        .catch(catch400(responseError => {
            return null;
        }));


};
