import * as _ from "lodash";

import {catch400, catch404} from "../../../../shared/ts/helpers/errors";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {Action, Dispatch, ErrorAction} from "../../../../shared/ts/interfaces/dispatch";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Middleware} from "../../../../shared/ts/decorators/middleware";

import {Data} from "../../shared/cached_data";
import {nphApiLink} from "../route/nph_api_link";


const VARIABLES_PREFIX = "variables/fetch";
const fetchVariablesTypes = createRequestActionTypes(VARIABLES_PREFIX);

export const fetchVariables = (): Middleware => (dispatch: Dispatch) => {

    dispatch<Action>({type: fetchVariablesTypes.start});

    return getRequest(nphApiLink.variables())
        .then((variables: Dictionary<any[]>[]) => {
            dispatch({type: fetchVariablesTypes.success, data: variables});
            _.each(variables, (variable: Dictionary<any[]>) => {
                _.each(variable, (val: any[], key: string) => {
                    if (!(Data as any)[key]) { // runtime validation
                        return console.warn(`fetchVariables: key "${key}" exists in server response but not in Data`);
                    }
                    (Data as any)[key].set(val);
                });
            });
            return variables;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: fetchVariablesTypes.error, error: responseError.appError});
        }))
        .catch(catch404(responseError => {
            dispatch<Action>({type: fetchVariablesTypes.error});
        }));
};
