import {isArray, each} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, Action} from "../../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";

import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {createFormActionTypes} from "../../../../../shared/ts/helpers/create_form_action_types";
import {createFormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {IHeroAd} from "../../reducers/hero";
import {invalidateHeroAdvertsList} from "./fetch";

export const HERO_ADD_PREFIX = "hero_ad/ADD";
export const heroRequestTypes = createRequestActionTypes(HERO_ADD_PREFIX);

export const addHeroFormTypes = createFormActionTypes(HERO_ADD_PREFIX);
export const addHeroFormActions = createFormActions(addHeroFormTypes);

export const heroAdsAdd = (values: IHeroAd) => (dispatch: Dispatch): Promise<IHeroAd | null> => {
    dispatch<Action>({type: heroRequestTypes.start});
    return postRequest(nphApiLink.heroAd.add(), values)
        .then((heroAD: IHeroAd) => {
            dispatch<Action>({type: heroRequestTypes.success});
            dispatch(invalidateHeroAdvertsList());
            dispatch(addSuccessAlert("Dodano baner hero"));
            return heroAD;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, HERO_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", HERO_ADD_PREFIX));
            }
            dispatch<ErrorAction>({type: heroRequestTypes.error, error: responseError.appError});
            return null;
        }));
};


export const CLEAR_HERO_ADD_EDIT_VIEW_STATE = "hero/add_edit/CLEAR_ADD_EDIT_VIEW_STATE";
export const clearHeroAddEditViewState = () => ({type: CLEAR_HERO_ADD_EDIT_VIEW_STATE});

export const CLEAR_HERO_ADD_EDIT_ERRORS = "hero/add_edit/CLEAR_ADD_EDIT_ERRORS";
export const clearHeroAddEditErrors = () => ({type: CLEAR_HERO_ADD_EDIT_ERRORS});
