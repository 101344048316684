import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {map, filter} from "lodash";
import {ITagGalleryOfferGallery} from "../../actions/fetch_offer_for_tag_gallery";
import {TagLayer} from "./TagLayer";
import {TagList} from "./TagList";
import {fetchImageTags, IImageTag} from "../../actions/fetch_image_tags";
import {createImageTag} from "../../actions/create_image_tag";
import {deleteImageTag} from "../../actions/delete_image_tag";
import {updateImageTag} from "../../actions/update_image_tag";


const FLOAT_PRECISION = 8;

export type ISelectedTag = {
    type: "create" | "update";
    position: IPosition;
    description: string;
} & ({type: "create"} | {type: "update"; id: number});
export interface IPosition {
    x: string;
    y: string;
}

interface IProps {
    offerId: number;
    image: ITagGalleryOfferGallery;
}

/**
 * - display all tags over the picture
 * - enable tag click to open edit form
 * - implement interaction modals on add/edit/delete
 * - proper styling
 */
export const PictureTagging = (props: IProps) => {

    const imgRef = useRef<HTMLImageElement>(null);
    const [isImageReady, setIsImageReady] = useState(false);

    /**
     * Tag list
     */
    const [tags, setTags] = useState<IImageTag[]>([]);
    useEffect(() => {
        (async () => {
            const fetchedTags = await fetchImageTags(props.offerId, props.image.pk);
            setTags(fetchedTags);
        })();
    }, []);

    /**
     * Selected tag
     */
    const [selectedTag, setSelectedTag] = useState<ISelectedTag | null>(null);
    const selectNewTag = useCallback((e: React.MouseEvent) => {
        if (imgRef.current == null) {
            throw new Error("selectNewTag: no image ref");
        }
        const position = getPositionFromClick(imgRef.current, e);
        setSelectedTag({type: "create", position, description: ""});
    }, [imgRef.current]);
    const selectExistingTag = useCallback((tag: IImageTag) => {
        const point = tag.polygon[0];
        const position = {x: point[0], y: point[1]};
        setSelectedTag(({type: "update", position, description: tag.label, id: tag.id}));
    }, []);
    const clearTagSelection = useCallback(() => setSelectedTag(null), []);

    /**
     * Change tags
     */
    const submitTag = async (description: string) => {
        if (selectedTag == null) {
            throw new Error("PictureTagging.submitTag: no selected tag");
        }

        const tagData = {
            gallery: props.image.pk,
            label: description,
            polygon: [[selectedTag.position.x, selectedTag.position.y]] as [string, string][]
        };
        if (selectedTag.type === "create") {
            const newTag = await createImageTag(props.offerId, props.image.pk, tagData);
            setTags(prevTags => [newTag, ...prevTags]);
        }
        else {
            const updatedTag = await updateImageTag(props.offerId, props.image.pk, {id: selectedTag.id, ...tagData});
            setTags(prevTags => map(prevTags, t => t.id === selectedTag.id ? updatedTag : t));
        }
        clearTagSelection();
    };
    const deleteTag = async (tag: IImageTag) => {
        await deleteImageTag(props.offerId, props.image.pk, tag.id);
        setTags(prevTags => filter(prevTags, t => t.id !== tag.id));
    };

    /**
     * Render
     */
    return (
        <div className="df">
            <div className="df fjc-center fai-center bgc-black w100 h100">
                <div className="psr">
                    <img ref={imgRef} onClick={selectNewTag} className="gallery-tags-modal-img fb-0" src={props.image.image} onLoad={() => setIsImageReady(true)}/>

                    {imgRef.current && isImageReady && (
                        <TagLayer imageElem={imgRef.current} tags={tags} selectedTag={selectedTag}
                                  onTagClick={selectExistingTag}
                                  onAcceptClick={submitTag} onRejectClick={clearTagSelection}
                        />
                    )}
                </div>
            </div>

            <div className="gallery-tags-list-holder">
                <TagList tags={tags} onTagEditClick={selectExistingTag} onTagDeleteClick={deleteTag}/>
            </div>
        </div>
    );
};

/**
 * Helper
 */
function getPositionFromClick(elem: HTMLElement, event: React.MouseEvent): IPosition {
    const rect = elem.getBoundingClientRect();
    if (event.clientX < rect.left || rect.right < event.clientX) {
        throw new Error("getPositionFromClick: click outside of horizontal range");
    }
    if (event.clientY < rect.top || rect.bottom < event.clientY) {
        throw new Error("getPositionFromClick: click outside of vertical range");
    }
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const fractionX = (x / rect.width);
    const fractionY = (y / rect.height);
    return {x: fractionX.toPrecision(FLOAT_PRECISION), y: fractionY.toPrecision(FLOAT_PRECISION)};
}
