import * as React from "react";
import {Tab} from "../../../../shared/ts/components/tabs/Tab";
import Tooltip from "../../shared/overlays/Tooltip";
import Icon from "../../shared/icon/Icon";
import StyleProps from "../../../../shared/ts/interfaces/style";

type PlacementType = "bottom" | "left" | "top" | "right";


interface HeaderTabProps extends StyleProps {
    id: number;
    openedTabIndex: number;
    onTabOpen: (tabId: number) => void;
    icon: string;
    tooltipContent: string;
    tooltipPlacement: PlacementType;
    tooltipId: string;
}

const HeaderTabC: React.SFC<HeaderTabProps> = props => {
    return (
        <Tab
            id={props.id}
            active={props.id === props.openedTabIndex}
            onTabOpen={props.onTabOpen}
            className="item-holder curp fs18"
        >
            <Tooltip
                content={props.tooltipContent}
                id={props.tooltipId}
                placement={props.tooltipPlacement}
            >
                <div className="item"><Icon icon={props.icon} /></div>
            </Tooltip>
        </Tab>
    );
};

const HeaderTab = HeaderTabC;
export default HeaderTab;
