import * as React from "react";
import {useState} from "react";
import {map} from "lodash";

import {Button} from "../../../shared/button/Button";
import {IPlanOfferGallery} from "../../actions/fetch_plan_offer_actions";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {AddImageForm, addPlanImageFormFields} from "./AddImageForm";


interface IProps {
    gallery: IPlanOfferGallery[];
    onSelectImage: (photo: IPlanOfferGallery) => void;
    onUploadImage: (e: React.FormEvent) => void;
    formValues: Record<keyof typeof addPlanImageFormFields, any>;
    formActions: FormActions;
    isPending: boolean;
    disableSelectButton?: boolean;
}

export const AddImageModalBody = (props: IProps) =>  {

    const [modalState, setModalState] = useState("");

    switch (modalState) {
        case "select":
            return (
                <ul className="list-unstyled df fw-wrap mb-0" style={{maxWidth: "800px"}}>
                    {map(props.gallery, (photo, indx) => {
                        return (
                            <li key={indx}>
                                <Button
                                    onClick={() => props.onSelectImage(photo)}
                                    className="p-sm bd-0">
                                    <img src={photo.image} width={150} height={80}/>
                                </Button>
                            </li>
                        );
                    })}
                </ul>
            );
        case "upload":
            return (
                <div style={{width: "500px", height: "120px"}}>
                    <AddImageForm
                        values={props.formValues}
                        errors={{}}
                        onChange={props.formActions.update}
                        onSubmit={props.onUploadImage}
                        isPending={props.isPending}
                    />
                </div>
            );
        default:
            return (
                <>
                    <Button
                        type="button"
                        color="default"
                        className="pv-xxxl ph-xl w45"
                        onClick={() => setModalState("select")}
                        disabled={!!props.disableSelectButton}
                    >
                        Zdjęcie z portalu<br/>RynekPierwotny
                    </Button>

                    <Button
                        type="button"
                        color="default"
                        className="pv-xxxl ph-xl w45"
                        onClick={() => setModalState("upload")}
                    >
                        Wybierz zdjęcie z dysku
                    </Button>
                </>
            );
    }
};
