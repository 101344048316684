export const urlBase64 = (url: string) => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        const imageBlob = new Blob([blob], {type: "image/png"});
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(imageBlob);
    }));

export const urlToCroppedBase64 = (url: string, width: number, height: number) => {
    return new Promise<string>((resolve) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        img.onload = function () {
            const ratio = img.width / img.height;
            canvas.height = height;
            canvas.width = width;

            let normalizedImgWidth = width;
            let normalizedImgHeight = width / ratio;

            if (normalizedImgHeight < height) {
                normalizedImgWidth = height * ratio;
                normalizedImgHeight = height;
            }

            const offsetY = (normalizedImgHeight - canvas.height) / -2;
            ctx && ctx.drawImage(img, 0, offsetY, normalizedImgWidth, normalizedImgHeight);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };
        img.src = url;

    });
};


export const createFileName = (name: string, id: number, size?: string) => {
    return `${name}_${id}${size ? "_" + size : ""}.jpg`;
};

