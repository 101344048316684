import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {nphApiLink} from "../../project/route/nph_api_link";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";


interface IImageTagsResponse {
    count: number;
    results: IImageTag[];
}
export interface IImageTag {
    gallery: number;
    id: number;
    label: string;
    polygon: [string, string][];
}

export const fetchImageTags = (offerId: number, imageId: number): Promise<IImageTag[]> => {
    const url = nphApiLink.offers.gallery.tagBase({offerId, imageId});
    return getRequest(appendQueryString(url, {page_size: 1000}))
        .then((res: IImageTagsResponse) => {
            return res.results;
        });
};
