import * as React from "react";
import {reduce, times} from "lodash";
import {Button} from "../../../shared/button/Button";
import {FormSelect} from "../../../shared/form/fields/select/FormSelect";
import {createForm, FormProps, HocForm} from "../../../shared/form/create_form";
import {IPlanProperty} from "../../actions/fetch_plan_property_actions";
import {Select} from "../../../shared/form/fields/select/Select";
import Icon from "../../../shared/icon/Icon";
import Input from "../../../shared/form/fields/input/Input";
import {FormInput} from "../../../shared/form/fields/input/FormInput";
import {PlanPolygonPropertyStatus} from "../../actions/plan_polygon_actions";


export const assignPropertyFormFields = {
    property: Select,
    name: Input,
    rooms: Select,
    area: Input,
    status: Select,
    custom: Select
};

interface IOwnProps extends FormProps {
    onClose: () => void;
    properties: IPlanProperty[];
    initialPropertyId: number;
}

interface IProps extends IOwnProps {
    hoc: HocForm<typeof assignPropertyFormFields>;
}

export const AssignPropertyForm = createForm<IOwnProps>()((props: IProps) => {

    const {fieldProps} = props.hoc.form;

    const generatePropertyOptions = reduce(props.properties, (acc, property) => {
        if (property.plan_polygon && props.initialPropertyId !== property.pk) {
            // do not include properties related to other polygons
            // but explicitly add (do not exclude) property with `initialPropertyId` ID
            return acc;
        }

        const label = (
            <div>
                <span className="mr-md fwb">
                    {property.number}
                </span>

                <span className="mr-md">
                    {property.rooms} pok.
                </span>

                <span className="mr-md">
                    {property.area} m2
                </span>
            </div>
        );

        return [...acc, {
            value: property.pk.toString(),
            label: property.number == null ? "Nieokreślona" : label as any
        }];
    }, []);

    const floorOptions = times(5, (indx) => ({value: (indx + 1).toString(), label: (indx + 1) === 5 ? "5 i więcej" : (indx + 1).toString()}));

    const statusOptions = [
        {value: PlanPolygonPropertyStatus.UNAVAILABLE.toString(), label: "Niedostępne"},
        {value: PlanPolygonPropertyStatus.SOLD.toString(), label: "Sprzedane"}
    ];

    const renderForm =  () => {
        if (!props.values.custom) {
            return generatePropertyOptions.length > 0 ? (
                    <FormSelect
                        {...fieldProps("property")}
                        label="Wybierz nieruchomość"
                        showAllValues
                        options={generatePropertyOptions}
                        async={false}
                        creatable
                        multi={false}
                        resultLimit={1000}
                    />
                ) : (
                    <p className="c-danger">
                        Brak nieruchomości do przypisania
                    </p>
                );
        } else {
            return (
                <>
                    <Button color="link" size="sm" className="p-0 mb-lg" onClick={() => fieldProps("custom").onChange("custom", false)}>
                        <Icon icon="arrow-left" className="mr-sm"/>
                        Wróć
                    </Button>

                    <div>
                        <FormInput
                            {...fieldProps("name")}
                            autoFocus
                            type="text"
                            label="Nazwa mieszkania"
                        />

                        <FormSelect
                            {...fieldProps("status")}
                            label="Status"
                            showAllValues
                            options={statusOptions}
                            async={false}
                            creatable
                            multi={false}
                        />

                        <FormSelect
                            {...fieldProps("rooms")}
                            label="Ilość pokoi"
                            showAllValues
                            options={floorOptions}
                            async={false}
                            creatable
                            multi={false}
                        />

                        <FormInput
                            {...fieldProps("area")}
                            autoFocus
                            type="text"
                            label="Powierzchnia"
                        />
                    </div>
                </>
            );
        }
    };

    return (
        <form className="p-xxxl " onSubmit={props.onSubmit} style={{minWidth: "400px"}}>
            <div className="mb-xl select-property-form psr">
                {renderForm()}
            </div>

            {!props.values.custom && (
                <Button
                    onClick={() => fieldProps("custom").onChange("custom", true)}
                    color="link"
                    type="button"
                    className="p-0 mb-xl c-info tdn-h"
                >
                    <Icon icon="pencil" className="mr-md"/>
                    Dodaj ręcznie mieszkania
                </Button>
            )}

            <div className="df fjc-end">
                <Button type="button" color="default" size="lg" className="mr-lg" onClick={props.onClose}>
                    Anuluj
                </Button>

                <Button color="success" type="submit" size="lg" disabled={props.values.custom ? !props.values.name : !props.values.property}>
                    Zapisz
                </Button>
            </div>
        </form>
    );
});
