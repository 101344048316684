import {combineReducers} from "redux";
import {map} from "lodash";
import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {fetchPlanPropertyListTypes, IPlanProperty} from "../actions/fetch_plan_property_actions";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {ResponseAction} from "../../../../shared/ts/helpers/interfaces";
import {reducePaginatedResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response_with_reset";
import {patchPropertyPlanTypes} from "../actions/patch_property_plan";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {assignPropertyFormTypes} from "../actions/assign_property_form";
import {fromJson} from "../../shared/form/translate/from_json";
import {assignPropertyFormFields} from "../components/floor/AssignPropertyForm";


export interface IPlanPropertyStore {
    list: IPlanProperty[];
    assignPropertyFormValues: Record<keyof typeof assignPropertyFormFields, any>;
    fetchRequest: RequestState;
}

export const planPropertyReducer = combineReducers({
    list: reduceReducers(
        reducePaginatedResponseWithReset(fetchPlanPropertyListTypes),
        customPropertyListReducer
    ),
    assignPropertyFormValues: reduceFormData(assignPropertyFormTypes, fromJson(assignPropertyFormFields, {})),
    fetchRequest: reduceRequestState(fetchPlanPropertyListTypes)
});

/**
 * Custom
 */

function customPropertyListReducer(list: IPlanProperty[], action: ResponseAction<IPlanProperty>): IPlanProperty[] {
    if (action.type === patchPropertyPlanTypes.success) {
        return map(list, property => property.pk === action.result.pk ? action.result : property);
    }
    return list;
}
