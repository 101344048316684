import * as React from "react";
import * as _ from "lodash";
import * as classnames from "classnames";
import StyleProps from "../../interfaces/style";


interface TabProps extends StyleProps {
    id: number;
    active?: boolean;
    onTabOpen?: (tabId: number) => void;
}


//TODO: ostylować klasę active z zewnątrz - style per miejsce wystąpienia, a nie defaultowe

export const Tab: React.SFC<TabProps> = props => {
    const className = classnames("tab", props.active ? "tab-active" : "", props.className);

    const onClick = (): void => {
        if (_.isFunction(props.onTabOpen)) {
            props.onTabOpen(props.id);
        }
    };

    return <li className={className} onClick={onClick}>{props.children}</li>;
};

