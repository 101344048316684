import * as React from "react";
import * as classNames from "classnames";
import {NativeMobileAd} from "./NativeMobileAd";
import {NativeDesktopAd} from "./NativeDesktopAd";
import {IDesktopNativesFormValues, IMobileNativesFormValues} from "../../reducers/natives";
import {NativeFormType} from "../../helpers/constants";

interface IProps {
    className?: string;
    desktopFormData: IDesktopNativesFormValues;
    mobileFormData: IMobileNativesFormValues;
    minPrice: number;
    distance: {
        center: boolean;
        distance: number;
        region: string;
    };
    formType: NativeFormType;
}

export const NativeAdHolder = (props: IProps) => {
    const className = classNames("p-xl bgc-gray-bright", props.className);
    const showDesktop = props.formType !== NativeFormType.MOBILE;
    const showMobile = props.formType !== NativeFormType.DESKTOP;
    return (
        <div className={className}>
            {showMobile && (
                <div className="pb-xl ph-xxxl df df-col fw-wrap fjc-center">
                    <NativeMobileAd
                        id="mobile-ad"
                        formData={props.mobileFormData}
                        minPrice={props.minPrice}
                        image={props.mobileFormData.mobile_image.base64value}
                    />
                </div>
            )}

            {showDesktop && (
                <div className="pb-xl df fjc-center">
                    <NativeDesktopAd
                        id="desktop-ad"
                        formData={props.desktopFormData}
                        minPrice={props.minPrice}
                        distance={props.distance}
                    />
                </div>
            )}
        </div>
    );
};
