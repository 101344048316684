import * as React from "react";
import * as qs from "qs";

import {IMobileNativesFormValues} from "../../../advertisments/reducers/natives";
import {creationPreviewLinks} from "../../creation_preview_path";

interface IProps {
    nativeCreationFormData: IMobileNativesFormValues;
}

interface INativeCreationAdPreviewData {
    desktop_distance: string | null;
    mobile_address: string;
    mobile_region: string;
    desktop_vendor_logo: {
        v_log_80x60: string;
    } | null;
    mobile_image_native: {
        m_img_375x211: string;
    } | null;
    mobile_name: string;
    mobile_construction_end_date: string | null; // monthly, native
    mobile_individual_price_m2: string | null; // string when `mobile_offer_price_type === 2`
    mobile_vendor: string;
}

export const NativeCreationAdPreview = (props: IProps) => {
    const data: INativeCreationAdPreviewData = {
        ...props.nativeCreationFormData,
        desktop_vendor_logo: {
            v_log_80x60: props.nativeCreationFormData.desktop_vendor_logo.stored
        },
        mobile_image_native: {
            m_img_375x211: props.nativeCreationFormData.mobile_image_native.stored
        }
    }

    const query = qs.stringify(data)

    const previewUrl = `${creationPreviewLinks.nativeCreation()}?${query}`;
    return <iframe frameBorder={0} src={previewUrl} width="100%" height="600px"></iframe>;
}
