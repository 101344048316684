export interface RequestActionTypes {
    start: string;
    success: string;
    error: string;
    reset: string;
}


export const createRequestActionTypes = (name: string): RequestActionTypes => ({
    start: `${name}_REQUEST_START`,
    success: `${name}_REQUEST_SUCCESS`,
    error: `${name}_REQUEST_ERROR`,
    reset: `${name}_REQUEST_RESET`
});
