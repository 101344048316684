import {combineReducers} from "redux";
import {map, filter} from "lodash";
import {fetchVendorGroupListTypes, IVendorGroup} from "../../vendor/actions/fetch_vendor_group_list";
import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {patchVendorGroupTypes} from "../../vendor/actions/patch_vendor_group";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {ResponseAction} from "../../../../shared/ts/helpers/interfaces";
import {
    createPlanPolygonTypes,
    deletePlanPolygonTypes,
    IGroupPlanPolygon,
    updatePlanPolygonTypes
} from "../actions/plan_polygon_actions";


export interface IPlanVendorGroupStore {
    list: IVendorGroup[];
    patchRequest: RequestState;
}

export const planVendorGroupReducer = combineReducers({
    list: reduceReducers(
        reducePaginatedResponse(fetchVendorGroupListTypes),
        customGroupListPatchDetailReducer, // patch image
        customGroupListPlanPolygonReducer // update vendorGroup.polygons
    ),
    patchRequest: reduceRequestState(patchVendorGroupTypes)
});

/**
 * Custom
 */
function customGroupListPatchDetailReducer(list: IVendorGroup[], action: ResponseAction<IVendorGroup>): IVendorGroup[] {
    if (action.type === patchVendorGroupTypes.success) {
        return map(list, group => group.id === action.result.id ? action.result : group);
    }
    return list;
}

function customGroupListPlanPolygonReducer(list: IVendorGroup[], action: ResponseAction<IGroupPlanPolygon>): IVendorGroup[] {
    const [first, ...tail] = list;
    if (first == null) {
        return list;
    }
    if (action.type === createPlanPolygonTypes.success) {
        return [{...first, polygons: [...first.polygons, action.result]}, ...tail];
    }
    else if (action.type === updatePlanPolygonTypes.success) {
        return [{...first, polygons: map(first.polygons, p => p.id === action.result.id ? action.result : p)}, ...tail];
    }
    else if (action.type === deletePlanPolygonTypes.success) {
        return [{...first, polygons: filter(first.polygons, p => p.id !== (action as any).id)}, ...tail];
    }
    return list;
}
