import {RequestActionTypes} from "../create_request_action_types";
import {PaginatedResponseAction} from "../../interfaces/dispatch";


export function reducePaginatedResponse<T>(requestTypes: RequestActionTypes) {
    return (state: T[] = [], action: PaginatedResponseAction<T>): T[] => {
        switch (action.type) {
            case requestTypes.success:
                return action.results;
            default:
                return state;
        }
    };
}
