import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Store} from "../../project/reducer";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";

import {
    addDesktopNativesFormActions,
    addMobileNativesFormActions, clearAddEditErrors,
    clearAddEditViewState,
    nativesAdd
} from "../actions/natives/add";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../project/components/content/ContentHeaderControls";
import {Button} from "../../shared/button/Button";
import ContentBody from "../../project/components/content/ContentBody";
import {TabsContent} from "../../../../shared/ts/components/tabs/TabsContent";
import {NativesTypeTabs} from "../components/tabs/NativesTypeTabs";
import {DesktopNativesForm} from "../components/DesktopNativesForm";
import {MobileNativesForm} from "../components/MobileNativesForm";
import {FormActions} from "../../../../shared/ts/helpers/create_form_actions";
import {INativeAd, INativesForms} from "../reducers/natives";
import {closePanel, openPanel, openTab} from "../../context_panel/actions/context_panel";
import {advertsConvert, INativeFileNameData} from "../actions/natives/converts_canvas_to_jpg";
import {nativesFetchDetail} from "../actions/natives/fetch";
import {RouterState} from "../../../../shared/ts/interfaces/router";
import {createFileName, urlBase64, urlToCroppedBase64} from "../../../../shared/ts/helpers/urlToCroppedBase64";
import {nativeEditPatch} from "../actions/natives/edit";
import {getPreparedAdsElements, NativeFormType} from "../helpers/constants";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {HocModalix, modalix} from "../../../../shared/ts/decorators/modalix";
import {NativeAdModal} from "../components/ads_templates/NativeAdModal";
import {Offer} from "../../shared/models/Offer";
import {fetchOffer, fetchOfferDetailForNatives, resetOfferDetailForNative} from "../../offer/actions/fetch";

interface IStateProps {
    nativesDetail: INativesForms;
    nativesOfferDetail: Offer;
    contextPanelOpened: boolean;
}
interface IActionsProps {
    desktopFormActions: FormActions;
    mobileFormActions: FormActions;
    fetchOfferDetailForNatives: (id: number | string) => Promise<any>;
    actions: {
        fetchOffer: typeof fetchOffer;
        nativeEditPatch: typeof nativeEditPatch;
        nativesFetchDetail: typeof nativesFetchDetail;
        clearAddEditErrors: typeof clearAddEditErrors;
        clearAddEditViewState: typeof clearAddEditViewState;
        resetOfferDetailForNative: typeof resetOfferDetailForNative;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
        advertsConvert: typeof advertsConvert;
    };
}

interface IOwnProps extends RouterState {
    hoc: HocModalix;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    openedFormTab: number;
    initialImage: string | Blob;
}

@modalix(NativeAdModal)
class EditViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            openedFormTab: 1,
            initialImage: ""
        };
    }

    public async componentDidMount() {
        const nativeAdId = parseInt(this.props.params["id"], 10);
        const nativeValues: any = await this.props.actions.nativesFetchDetail(nativeAdId);
        (nativeValues as INativeAd).offer && await this.props.fetchOfferDetailForNatives(nativeValues.offer);

        this.triggerPopulatingDataToForm(nativeValues as INativeAd);
    }

    public componentWillUnmount(): void {
        this.props.actions.clearAddEditViewState();
        this.props.actions.resetOfferDetailForNative();
    }

    private handleTabs = (openedFormTab: number) => {
        this.setState({openedFormTab});
    };

    private handleConvert = (nativeData: INativeFileNameData, formType: NativeFormType) => {
        this.handleModalOpen(formType);
        this.props.actions.advertsConvert(getPreparedAdsElements(formType), nativeData);

        // do we want to wait?
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    private handleFormSubmit = (formValues: INativeAd, shouldGenerate: boolean) => {
        const nativeAdId = parseInt(this.props.params["id"], 10);

        (this.props.actions.nativeEditPatch(nativeAdId, formValues) as any)
            .then((response: INativeAd) => {
                if (response != null) {
                    if (shouldGenerate) {
                        this.handleConvert({name: response.desktop_name || response.mobile_name, id: response.id}, formValues.type);
                    }
                    this.props.actions.clearAddEditErrors();
                }
            });
    };

    private onSubmit = (formType: number, shouldGenerate: boolean = false) => {
        let formValues: INativeAd;
        switch (formType) {
            case NativeFormType.DESKTOP:
                formValues = {
                    ...DesktopNativesForm.toJSON(this.props.nativesDetail.desktopForm),
                    type: NativeFormType.DESKTOP
                };
                break;
            case NativeFormType.MOBILE:
                formValues = {
                    ...MobileNativesForm.toJSON(this.props.nativesDetail.mobileForm),
                    type: NativeFormType.MOBILE
                };
                break;
            case NativeFormType.ALL:
                formValues = {
                    ...DesktopNativesForm.toJSON(this.props.nativesDetail.desktopForm),
                    ...MobileNativesForm.toJSON(this.props.nativesDetail.mobileForm),
                    type: NativeFormType.ALL
                };
                break;
            default:
                return null;
        }
        this.handleFormSubmit(formValues, shouldGenerate);
    };

    private prepareBase64Images = async (data: INativeAd) => {
        const initialImage = this.props.nativesOfferDetail && this.props.nativesOfferDetail.main_image && await urlBase64(this.props.nativesOfferDetail.main_image) as string;
        const desktopImage = data.desktop_image && await urlToCroppedBase64(data.desktop_image, 336,  189);
        const mobileImage = data.mobile_image && await urlToCroppedBase64(data.mobile_image, 375,  211);
        const desktopVendorLogo = data.desktop_vendor_logo && await urlBase64(data.desktop_vendor_logo);

        this.setState({initialImage});

        const hasDesktopValues = desktopImage || desktopVendorLogo;
        const hasMobileValues = mobileImage;

        hasDesktopValues && this.props.desktopFormActions.update({
            desktop_image: {
                ...this.props.nativesDetail.desktopForm.desktop_image,
                selected: `main_${createFileName(data.desktop_name, data.id, "desktop")}`,
                base64value: desktopImage
            },
            desktop_vendor_logo: {
                ...this.props.nativesDetail.desktopForm.desktop_vendor_logo,
                selected: `main_${createFileName(data.desktop_vendor, data.id)}`,
                base64value: desktopVendorLogo
            }
        });
        hasMobileValues && this.props.mobileFormActions.update({
            mobile_image: {
                ...this.props.nativesDetail.mobileForm.mobile_image,
                selected: `main_${createFileName(data.mobile_name, data.id, "mobile")}`,
                base64value: mobileImage
            }
        });
    };

     private updateDesktopImages = (image?: string) => {
        const imgOptions = image ?
            {base64value: image} :
            {base64value: this.state.initialImage, selected: createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk)};
        this.props.desktopFormActions.update({
            desktop_image: {
                ...this.props.nativesDetail.desktopForm.desktop_image,
                ...imgOptions
            }
        });
    };
    private updateMobileImages = (key: ("mobile_image"), image?: string) => {
        // similar to mobileForm triggerCrop logic is overly complex for the purpose of (previous and) future possible different size images
        const imgOptions = image ?
            {base64value: image} :
            {base64value: this.state.initialImage, selected: createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk)};
        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.nativesDetail.mobileForm[key],
                ...imgOptions
            }
        });
    };

    private triggerPopulatingDataToForm = (native: INativeAd) => {
        this.prepareBase64Images(native);
        const desktopValues = DesktopNativesForm.fromJSON(native);
        this.props.desktopFormActions.update(desktopValues);
        const mobileValues = MobileNativesForm.fromJSON(native);
        this.props.mobileFormActions.update(mobileValues);
    };

    private handleModalOpen = (formType: NativeFormType) => {
        const minPrice = this.props.nativesOfferDetail && this.props.nativesOfferDetail.stats.ranges_price_m2_min || 0;
        const distanceData = this.props.nativesOfferDetail && this.props.nativesOfferDetail.stats.distance_from_region || null;
        this.props.hoc.modalix.show<any>({
            mobileFormData: this.props.nativesDetail.mobileForm,
            desktopFormData: this.props.nativesDetail.desktopForm,
            distance: distanceData,
            minPrice: minPrice,
            formType,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    public render() {
        const {request} = this.props.nativesDetail;
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklamy natywne</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.handleModalOpen(NativeFormType.ALL)}
                            >
                                Podgląd Reklam
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(NativeFormType.ALL)}
                            >
                                Zapisz Reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(NativeFormType.ALL, true)}
                            >
                                Zapisz i Wygeneruj Reklamy
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <NativesTypeTabs onTabOpen={this.handleTabs} openedTabIndex={this.state.openedFormTab} errors={this.props.nativesDetail.errors}/>

                        <TabsContent openedTabIndex={this.state.openedFormTab}>
                            {/*<Notifications formValues={this.props.formValues}/>*/}
                            {this.state.openedFormTab === NativeFormType.DESKTOP && (
                                <DesktopNativesForm
                                    onSubmit={() => this.onSubmit(NativeFormType.DESKTOP, false)}
                                    errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                                    values={this.props.nativesDetail.desktopForm}
                                    updateFormAction={this.props.desktopFormActions.update}
                                    handlePreview={() => this.handleModalOpen(NativeFormType.DESKTOP)}
                                    submitAndGenerate={() => this.onSubmit(NativeFormType.DESKTOP, true)}
                                    updateImageAfterCropping={this.updateDesktopImages}
                                    initialImage={this.state.initialImage}
                                />
                            )}

                            {this.state.openedFormTab === NativeFormType.MOBILE && (
                                <MobileNativesForm
                                    onSubmit={() => this.onSubmit(NativeFormType.MOBILE, false)}
                                    errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                                    values={this.props.nativesDetail.mobileForm}
                                    updateFormAction={this.props.mobileFormActions.update}
                                    handlePreview={() => this.handleModalOpen(NativeFormType.MOBILE)}
                                    submitAndGenerate={() => this.onSubmit(NativeFormType.MOBILE, true)}
                                    updateImageAfterCropping={this.updateMobileImages}
                                    initialImage={this.state.initialImage}
                                />
                            )}
                        </TabsContent>
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}
export const EditView = connect(mapStateToProps, mapActionsToProps)(EditViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        nativesDetail: store.adverts.natives.detail,
        nativesOfferDetail: store.adverts.natives.nativesOfferDetail,
        contextPanelOpened: store.adverts.natives.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        desktopFormActions: bindActionCreators(addDesktopNativesFormActions, dispatch),
        mobileFormActions: bindActionCreators(addMobileNativesFormActions, dispatch),
        fetchOfferDetailForNatives: fetchOfferDetailForNatives(dispatch),

        actions: bindActionCreators({
            fetchOffer,
            nativeEditPatch,
            nativesFetchDetail,
            openPanel,
            closePanel,
            openTab,
            clearAddEditViewState,
            clearAddEditErrors,
            resetOfferDetailForNative,
            advertsConvert
        }, dispatch)
    };
}
