import * as React from "react";
import * as classnames from "classnames";
import {StatelessProps} from "../helpers/interfaces";


// Icon
interface IconProps extends StatelessProps {
    icon: string;
    circle?: string;
    title?: string;
    light?: boolean;
    onClick?: React.MouseEventHandler;
}

export const Icon: React.SFC<IconProps> = props => {
    let bgColor: string = "";
    if (props.circle && props.light) {
        bgColor = "icon-bg-light";
    } else if (props.circle && !props.light) {
        bgColor = "icon-bg-dark";
    }
    const className = classnames(props.className, `icon-${props.icon} ${bgColor}`);

    // Ikona
    let icon = (
        <i
            className={className}
            title={props.title}
            onClick={props.onClick} />
    );

    // Ikona w kole
    if (props.circle) {
        icon = <span className={`icon-circle-${props.circle}`} onClick={props.onClick}>{icon}</span>;
    }

    // Ikona z napisem
    if (props.children) {
        icon = <span className="mr-md">{icon}</span>;
        icon = <span onClick={props.onClick}>{icon}{props.children}</span>;
    }

    return icon;
};
