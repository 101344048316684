import * as React from "react";
import {useEffect, useMemo} from "react";
import {RefObject} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {map, find, isFinite, reduce} from "lodash";
import {AssignPropertyForm, assignPropertyFormFields} from "./AssignPropertyForm";
import {Modal} from "../../../../../shared/ts/modals/Modal";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {assignPropertyFormActions} from "../../actions/assign_property_form";
import {patchPropertyPlan} from "../../actions/patch_property_plan";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {BoundAction} from "../../../../../typings/custom_typings/custom_redux";
import {IFabricApi} from "../draw_polygon/init_fabric";
import {IPlanProperty} from "../../actions/fetch_plan_property_actions";
import {
    createPlanPolygon,
    deletePlanPolygon, IFloorPlanPolygon,
    PlanPolygonPropertyStatus,
    updatePlanPolygon
} from "../../actions/plan_polygon_actions";


const customPolygonConst = 1000000000;
export const toCustomPolygonId = (id: number) => id + customPolygonConst;
export const fromCustomPolygonId = (id: number) => id - customPolygonConst;
export const isCustomPolygonId = (id: number) => id > customPolygonConst;

interface IStateProps {
    assignPropertyFormValues: Record<keyof typeof assignPropertyFormFields, any>;
}
interface IActionsProps {
    assignPropertyFormActions: FormActions;
    actions: {
        createPlanPolygon: BoundAction<typeof createPlanPolygon>;
        deletePlanPolygon: BoundAction<typeof deletePlanPolygon>;
        updatePlanPolygon: BoundAction<typeof updatePlanPolygon>;
        patchPropertyPlan: BoundAction<typeof patchPropertyPlan>;
    };
}
type ConditionalOwnProps = {
    planPolygons: IFloorPlanPolygon[] | null;
    floorId: number;
    groupId: undefined;
} | {
    planPolygons: IFloorPlanPolygon[] | null; // TODO: this type should be related to Property rather than to Floor
    groupId: number;
    floorId: undefined;
};
type IOwnProps = ConditionalOwnProps & {
    assignPropertyModalState: number | null;
    setAssignPropertyModalState: (state: number | null) => void;
    apiRef: RefObject<IFabricApi>;
    properties: IPlanProperty[];
};
type IProps = IStateProps & IActionsProps & IOwnProps;

const AssignPropertyModalC = (props: IProps) => {

    useEffect(() => {
        const polygonId = props.assignPropertyModalState;
        if (polygonId == null || props.planPolygons == null) {
            return;
        }

        if (polygonId === -1) {
            props.assignPropertyFormActions.replace({status: PlanPolygonPropertyStatus.UNAVAILABLE.toString()});
            return;
        }

        if (isCustomPolygonId(polygonId)) {
            const customId = fromCustomPolygonId(polygonId);
            const value = find(props.planPolygons, p => p.id === customId) as IFloorPlanPolygon;
            props.assignPropertyFormActions.replace({
                custom: true,
                name: value.name,
                rooms: value.rooms,
                area: value.area,
                status: value.status
            });
        }
        else {
            props.assignPropertyFormActions.replace({custom: false, property: polygonId.toString()});
        }
    }, [props.assignPropertyModalState]);

    const propertyMap = useMemo(() => reduce(props.properties, (acc, p) => ({...acc, [p.pk]: p}), {}), [props.properties]) as Record<number, IPlanProperty>;

    const onAssignPropertyModalSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (props.apiRef.current == null || props.assignPropertyModalState == null) {
            throw new Error(`onAssignPropertyModalSubmit: error state`);
        }
        const polygon = props.apiRef.current.getPolygon(props.assignPropertyModalState);
        if (polygon == null) {
            throw new Error(`onAssignPropertyModalSubmit: no polygon with id ${props.assignPropertyModalState}`);
        }
        const apiPolygon = map(polygon, point => [point.fractionX, point.fractionY] as [string, string]);
        const values = props.assignPropertyFormValues;
        const {floorId, groupId} = props;
        if (values.custom) { // custom form sent
            const planPolygonData: any = { // `IPlanPolygon` type problem: `group` has `floor` properties
                ...(floorId ? {floor: floorId} : {group: groupId}),
                polygon: apiPolygon,
                name: values.name,
                rooms: parseInt(values.rooms, 10),
                area: values.area,
                status: values.status
            };
            // decide on prev id
            if (isCustomPolygonId(props.assignPropertyModalState)) { // prev is custom, so update that
                const customId = fromCustomPolygonId(props.assignPropertyModalState);
                await props.actions.updatePlanPolygon(customId, planPolygonData);
                // there is nothing to clear
            }
            else { // prev is property or none (add polygon)
                const custom = await props.actions.createPlanPolygon(planPolygonData);
                const prevId = props.assignPropertyModalState;
                if (prevId !== -1) { // edit - clear old assignment
                    await props.actions.patchPropertyPlan(prevId, {plan_polygon: null});
                }
                props.apiRef.current.changeId(prevId, toCustomPolygonId(custom.id));
                floorId && props.apiRef.current.changeColor(toCustomPolygonId(custom.id), getPropertyColor(null));
            }
        }
        else { // standard property form sent
            const formPropertyIdStr = props.assignPropertyFormValues.property;
            const formPropertyId = parseInt(formPropertyIdStr, 10);
            if (!isFinite(formPropertyId)) {
                throw new Error(`onAssignPropertyModalSubmit: cannot parse formPropertyId from ${formPropertyIdStr}`);
            }
            if (props.assignPropertyModalState === formPropertyId) { // edit without property assignment change
                return closeAssignPropertyModal(); // close modal
            }
            // decide on prev id
            if (isCustomPolygonId(props.assignPropertyModalState)) { // prev is custom
                const customId = fromCustomPolygonId(props.assignPropertyModalState);
                await props.actions.patchPropertyPlan(formPropertyId, {plan_polygon: apiPolygon});
                await props.actions.deletePlanPolygon(customId);
                props.apiRef.current.changeId(toCustomPolygonId(customId), formPropertyId);
                const rooms = propertyMap[formPropertyId] ? propertyMap[formPropertyId].rooms : null;
                floorId && props.apiRef.current.changeColor(formPropertyId, getPropertyColor(rooms));
            }
            else { // prev is property or none (add polygon)
                await props.actions.patchPropertyPlan(formPropertyId, {plan_polygon: apiPolygon});
                const prevId = props.assignPropertyModalState;
                if (prevId !== -1) { // edit - clear old assignment
                    await props.actions.patchPropertyPlan(prevId, {plan_polygon: null});
                }
                props.apiRef.current.changeId(prevId, formPropertyId);
                const rooms = propertyMap[formPropertyId] ? propertyMap[formPropertyId].rooms : null;
                floorId && props.apiRef.current.changeColor(formPropertyId, getPropertyColor(rooms));
            }
        }
        closeAssignPropertyModal();
    };

    const onAssignPropertyModalClose = () => {
        if (props.assignPropertyModalState === -1) { // new polygon drawn
            props.apiRef.current && props.apiRef.current.remove(props.assignPropertyModalState);
        }
        closeAssignPropertyModal();
    };

    const closeAssignPropertyModal = () => {
        props.setAssignPropertyModalState(null);
        props.assignPropertyFormActions.clear();
    };

    return (
        <Modal
            modalState={!!props.assignPropertyModalState}
            onModalClose={onAssignPropertyModalClose}
            type="window"
            header="Przypisz nieruchomość"
        >
            <AssignPropertyForm
                values={props.assignPropertyFormValues}
                errors={null}
                onChange={props.assignPropertyFormActions.update}
                onSubmit={onAssignPropertyModalSubmit}
                onClose={onAssignPropertyModalClose}
                properties={props.properties}
                initialPropertyId={props.assignPropertyModalState!}
            />
        </Modal>
    );
};

export const AssignPropertyModal: React.ComponentClass<IOwnProps> = connect(mapStateToProps, mapActionsToProps)(AssignPropertyModalC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        assignPropertyFormValues: state.plan.property.assignPropertyFormValues
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        assignPropertyFormActions: bindActionCreators(assignPropertyFormActions, dispatch),
        actions: bindActionCreators<Record<keyof IActionsProps["actions"], any>, IActionsProps["actions"]>({
            createPlanPolygon,
            deletePlanPolygon,
            updatePlanPolygon,
            patchPropertyPlan
        }, dispatch)
    };
}

/**
 * Helper
 */
export const getPropertyColor = (rooms: number | null, is_reserved?: boolean) => {

    if (is_reserved) {
        return "#D32F2F";
    }

    switch (rooms) {
        case 1: return "#E8C750";
        case 2: return "#71CCBD";
        case 3: return "#8C9AD5";
        case 4: return "#8FC1FF";
        case 5: return "#FF8F00";
        default: return "#696969";
    }
};
