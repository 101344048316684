import * as React from "react";
import {Panel} from "../../../shared/panel/Panel";
import {Button} from "../../../shared/button/Button";
import Icon from "../../../shared/icon/Icon";
import {EmtpyImg} from "../../../../../shared/ts/components/plan_offers/EmptyImg";



interface IOwnProps {
    deletePolygon?: (propertyId: number) => void;
    editPolygon?: () => void;
    editAssignment?: (propertyId: number) => void;
    property: {
        plan_image: string | null;
        number: string;
        rooms: number | null;
        area: number | null;
        floor: number | null;
        for_sale: boolean;
        is_reserved: boolean;
        pk: number;
    };
    prefix?: string;
}
interface IProps extends IOwnProps {}

export const FloorPropertiesListItem = (props: IProps) => {

    const {deletePolygon, editPolygon, editAssignment} = props;

    const renderAdditionalData = (title: string, info: string | number) => (
        <div className="mr-lg">
            <p className="fs11 mb-xs c-gray-dark">
                {title}
            </p>

            <p className="fs14 mb-0">
                {info}
            </p>
        </div>
    );

    return (
        <Panel color="default">
            <div className="df fjc-sb fai-center ph-lg pv-xl">
                {props.property.plan_image ? (
                    <img src={props.property.plan_image} width="155" height="90"/>
                ) : (
                    <EmtpyImg className="bdrs-md bdc-gray-light bdw-sm bds-solid" width={155} height={90}/>
                )}

                <div className="fg-1 fs-1 ph-md">
                    <p className="fs22 fwb mb-xs">
                        {props.prefix != null ? props.prefix : "Mieszkanie"} {props.property.number}
                    </p>

                    <div className="df">
                        {props.property.rooms && renderAdditionalData("Pokoje", props.property.rooms)}

                        {props.property.area && renderAdditionalData("Metraż", props.property.area)}

                        {props.property.floor != null && renderAdditionalData("Piętro", props.property.floor === 0 ? "Parter" : props.property.floor)}

                        {!props.property.for_sale ? renderAdditionalData("Dostępność", "Niedostępne") : props.property.is_reserved ? renderAdditionalData("Dostępność", "Rezerwacja") : renderAdditionalData("Dostępność", "Dostępne")}

                    </div>
                </div>

                <div className="fg-0 fs-0">
                    {editPolygon && (
                        <Button color="default" size="sm" className="mr-sm" onClick={editPolygon}>
                            <Icon icon="pencil" className="mr-xs"/>
                            Edytuj poligon
                        </Button>
                        )}

                    {editAssignment && (
                        <Button color="default" size="sm" className="mr-sm" onClick={() => editAssignment(props.property.pk)}>
                            <Icon icon="main-data" className="mr-xs"/>
                            Edytuj przypisanie
                        </Button>
                    )}

                    {deletePolygon && (
                        <Button color="danger" size="sm" onClick={() => deletePolygon(props.property.pk)}>
                            <Icon icon="delete"/>
                        </Button>
                    )}
                </div>
            </div>
        </Panel>
    );
};
