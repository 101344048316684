import {nphApiLink} from "../../project/route/nph_api_link";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch} from "redux";
import {IBuildingPlanPolygon} from "./plan_polygon_actions";


interface IBuildingResponse {
    count: number;
    results: IPlanBuilding[];
}
export interface IPlanBuilding {
    autogenerated: boolean;
    geo_area: unknown;
    geo_point: unknown;
    id: number;
    name: string;
    offer: number;
    plan: string | null;
    plan_image: string | null;
    plan_image_height: number | null;
    plan_image_width: number | null;
    plan_polygon: [string, string][];
    polygons: IBuildingPlanPolygon[];
    region: number;
    street_name: string;
    street_number: string;
    zipcode: string;
}

/**
 * Building list
 */

const FETCH_PLAN_BUILDING_LIST = "offer-plans/buildings/FETCH_PLAN_BUILDING_LIST";
export const fetchPlanBuildingListTypes = createRequestActionTypes(FETCH_PLAN_BUILDING_LIST);

export const fetchPlanBuildingList = (offerId: number | number []) => (dispatch: Dispatch): Promise<IPlanBuilding[]> => {
    dispatch({type: fetchPlanBuildingListTypes.start});
    const url = nphApiLink.buildings.list();
    return getRequest(appendQueryString(url, {offer: offerId, page_size: 1000}))
        .then((res: IBuildingResponse) => {
            dispatch({type: fetchPlanBuildingListTypes.success, results: res.results});
            return res.results;
        });
};

export const resetPlanBuildingList = () => ({type: fetchPlanBuildingListTypes.reset});

/**
 * Building detail
 */

const FETCH_PLAN_BUILDING_DETAIL = "offer-plans/buildings/FETCH_PLAN_BUILDING_DETAIL";
export const fetchPlanBuildingTypes = createRequestActionTypes(FETCH_PLAN_BUILDING_DETAIL);

export const fetchPlanBuilding = (buildingId: number) => (dispatch: Dispatch): Promise<IPlanBuilding> => {
    dispatch({type: fetchPlanBuildingTypes.start});
    const url = nphApiLink.buildings.detail({buildingId});
    return getRequest(url)
        .then((res: IPlanBuilding) => {
            dispatch({type: fetchPlanBuildingTypes.success, result: res});
            return res;
        });
};

export const resetPlanBuilding = () => ({type: fetchPlanBuildingTypes.reset});
