import {isArray, each} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, Action} from "../../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";

import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {createFormActionTypes} from "../../../../../shared/ts/helpers/create_form_action_types";
import {createFormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {IReelsCreator} from "../../reducers/reelsCreator";

export const REEL_CREATOR_ADD_PREFIX = "reel_creator/ADD";
export const reelCreatorRequestTypes = createRequestActionTypes(REEL_CREATOR_ADD_PREFIX);

export const addReelCreatorFormTypes = createFormActionTypes(REEL_CREATOR_ADD_PREFIX);
export const addReelCreatorFormActions = createFormActions(addReelCreatorFormTypes);

export const reelCreatorAdd = (values: Pick<IReelsCreator, "name" | "image">) => (dispatch: Dispatch): Promise<IReelsCreator | null> => {
    dispatch<Action>({type: reelCreatorRequestTypes.start});

    return postRequest(nphApiLink.reels.creator.add(), values)
        .then((reelCreator: IReelsCreator) => {
            dispatch<Action>({type: reelCreatorRequestTypes.success});
            dispatch(addSuccessAlert("Dodano autora"));
            return reelCreator;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, REEL_CREATOR_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza w polach dotyczących autora", REEL_CREATOR_ADD_PREFIX));
            }
            dispatch<ErrorAction>({type: reelCreatorRequestTypes.error, error: responseError.appError});
            return null;
        }));
};


export const CLEAR_REEL_CREATOR_ADD_EDIT_VIEW_STATE = "reel_creator/add_edit/CLEAR_ADD_EDIT_VIEW_STATE";
export const clearReelCreatorAddEditViewState = () => ({type: CLEAR_REEL_CREATOR_ADD_EDIT_VIEW_STATE});

export const CLEAR_REEL_CREATOR_ADD_EDIT_ERRORS = "reel_creator/add_edit/CLEAR_ADD_EDIT_ERRORS";
export const clearReelCreatorAddEditErrors = () => ({type: CLEAR_REEL_CREATOR_ADD_EDIT_ERRORS});
