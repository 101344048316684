export enum CreationType {
    DESKTOP = 1,
    MOBILE = 2,
    ALL = 3,
    NATIVE = 4,
    MONTHLY = 5,
    IMAGE = 6,
    SURPRISE = 7,
    HERO = 8,
    TDA = 9
}
