import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {OPEN_PANEL, CLOSE_PANEL} from "../actions/nav_panel";
import {assign} from "../../../../shared/ts/helpers/util";


export interface NavPanelStore {
    opened: boolean;
}

const initialState = {
    opened: true
};

const navPanel = (state: NavPanelStore = initialState, action: Action) : NavPanelStore => {
    switch (action.type) {
        case OPEN_PANEL:
            return assign(state, { opened: true });
        case CLOSE_PANEL:
            return assign(state, { opened: false });
        default:
            return state;
    }
};


export default navPanel;
