export function truncateWords(text: string, count: number): string {
    const wordsSeparator = " ";
    const separatedText = text.split(wordsSeparator);

    let truncatedText: string;

    // Text will be truncated
    if (separatedText.length > count) {
        truncatedText = separatedText.slice(0, count).join(wordsSeparator);

        // Add "..." at the end of string
        if (truncatedText.slice(-1) === ".") {
            truncatedText += "..";
        } else {
            truncatedText += "...";
        }
    }
    // Text won't be truncated
    else {
        truncatedText = text;
    }

    return truncatedText;
}

export function truncateString(text: string, count: number): string {
    let truncatedText: string;

    if (text.length > count) {
        truncatedText = text.slice(0, count);

        // Add "..." at the end of string
        truncatedText += "...";
    }
    // Text won't be truncated
    else {
        truncatedText = text;
    }

    return truncatedText;
}
