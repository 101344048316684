import {postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../shared/ts/helpers/errors";
import {Action, Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {nphApiLink} from "../../project/route/nph_api_link";


export const CAMPAIGN_NOTIFICATIONS_UPDATE_ERRORS = "campaignForm/UPDATE_ERRORS_NOTIFICATION";
export const CAMPAIGN_NOTIFICATIONS_CLEAR_ERRORS = "campaignForm/CLEAR_ERRORS_NOTIFICATIONS_CLEAR";


export const campaignEditValidate = (uuid: string, values: any) => (dispatch: Dispatch) => {
    const urlString = nphApiLink.campaigns.editValidate({id: uuid});
    return postRequest(urlString, values)
        .then(() => {
            dispatch({type: CAMPAIGN_NOTIFICATIONS_UPDATE_ERRORS, errors: {} });
        })
        .catch(catch400(responseError => {
            dispatch({type: CAMPAIGN_NOTIFICATIONS_UPDATE_ERRORS, errors: responseError.appError.fieldErrors });
            return null;
        }));
};

export const campaignAddValidate = (values: any) => (dispatch: Dispatch) => {
    const urlString = nphApiLink.campaigns.addValidate();
    return postRequest(urlString, values)
        .then(() => {
            dispatch({type: CAMPAIGN_NOTIFICATIONS_UPDATE_ERRORS, errors: {} });
        })
        .catch(catch400(responseError => {
            dispatch({type: CAMPAIGN_NOTIFICATIONS_UPDATE_ERRORS, errors: responseError.appError.fieldErrors });
            return null;
        }));
};

export const clearValidateErrors = (): Action => ({ type: CAMPAIGN_NOTIFICATIONS_CLEAR_ERRORS });
