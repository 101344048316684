import {isArray, each} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, Action} from "../../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";

import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {createFormActionTypes} from "../../../../../shared/ts/helpers/create_form_action_types";
import {createFormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {invalidateAdvertsList} from "./fetch";
import {INativeAd} from "../../reducers/natives";
import {nphApiLink} from "../../../project/route/nph_api_link";


export const NATIVES_ADD_PREFIX = "natives/ADD";
export const nativesRequestTypes = createRequestActionTypes(NATIVES_ADD_PREFIX);
const DESKTOP_NATIVES = `${NATIVES_ADD_PREFIX}_desktop`;
export const addDesktopNativesFormTypes = createFormActionTypes(DESKTOP_NATIVES);
export const addDesktopNativesFormActions = createFormActions(addDesktopNativesFormTypes);

const MOBILE_NATIVES = `${NATIVES_ADD_PREFIX}_mobile`;
export const addMobileNativesFormTypes = createFormActionTypes(MOBILE_NATIVES);
export const addMobileNativesFormActions = createFormActions(addMobileNativesFormTypes);

export const nativesAdd = (nativeAdValues: INativeAd) => (dispatch: Dispatch): Promise<any> => {
    dispatch<Action>({type: nativesRequestTypes.start});

    return postRequest(nphApiLink.natives.add(), nativeAdValues)
        .then((native: INativeAd) => {
            dispatch<Action>({type: nativesRequestTypes.success});
            dispatch(invalidateAdvertsList());
            dispatch(addSuccessAlert("Dodano reklamę natywną"));
            return native;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, NATIVES_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", NATIVES_ADD_PREFIX));
            }
            dispatch<ErrorAction>({type: nativesRequestTypes.error, error: responseError.appError});
            return null;
        }));
};


export const CLEAR_ADD_EDIT_VIEW_STATE = "natives/add_edit/CLEAR_ADD_EDIT_VIEW_STATE";
export const clearAddEditViewState = () => ({type: CLEAR_ADD_EDIT_VIEW_STATE});

export const CLEAR_ADD_EDIT_ERRORS = "natives/add_edit/CLEAR_ADD_EDIT_ERRORS";
export const clearAddEditErrors = () => ({type: CLEAR_ADD_EDIT_ERRORS});
