import * as React from "react";

interface IProps {
    className?: string;
    width?: number;
    height?: number;
}

export const EditIcon = (props: IProps) => {

    const className = props.className ? props.className : "";
    const width = props.width ? props.width : 21;
    const height = props.height ? props.height : 21;

    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
            <path fill="#a6a6a6" d="M2,19.5247 L5.711,19.5247 L16.263,8.8417 L12.896,5.5387 L2,16.2227 L2,19.5247 Z M6.129,21.5247 L1,21.5247 C0.447,21.5247 0,21.0777 0,20.5247 L0,15.8027 C0,15.5347 0.107,15.2767 0.3,15.0887 L12.197,3.4227 C12.586,3.0427 13.208,3.0427 13.598,3.4227 L18.38,8.1177 C18.569,8.3037 18.678,8.5577 18.6800283,8.8237 C18.682,9.0887 18.578,9.3447 18.391,9.5347 L6.84,21.2277 C6.652,21.4177 6.396,21.5247 6.129,21.5247 L6.129,21.5247 Z"/>
            <g transform="translate(13 .525)">
              <mask id="edit-b" fill="#fff">
                <polygon id="edit-a" points=".754 .475 7.525 .475 7.525 7.141 .754 7.141"/>
              </mask>
              <path fill="#a6a6a6" d="M6.5254,7.1406 C6.2734,7.1406 6.0214,7.0456 5.8264,6.8556 L1.0544,2.1896 C0.6604,1.8036 0.6524,1.1696 1.0394,0.7756 C1.4254,0.3816 2.0584,0.3736 2.4534,0.7596 L7.2244,5.4256 C7.6194,5.8116 7.6274,6.4456 7.2404,6.8396 C7.0454,7.0396 6.7854,7.1406 6.5254,7.1406" mask="url(#edit-b)"/>
            </g>
          </g>
        </svg>
    );
};
