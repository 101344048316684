import * as React from "react";
import * as _ from "lodash";
import * as ReactDatePicker from "react-datepicker";
import * as moment from "moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import * as classNames from "classnames";

import {FormComponent, FormComponentProps, FormComponentState} from "./formComponent";
import {Select, SelectOption, SelectValue} from "./select";
import {HelpOverlay} from "../help_overlay";
import {randomString} from "../../helpers/utils";

const DatePicker = (ReactDatePicker as any).default;
export type DatePickerPopperPlacement = "top" | "bottom" | "right" | "left";

// todo interfejs zaimportowac
export interface DatePickerProps extends FormComponentProps<DatePickerObj> {
    format?: string;
    label?: string | JSX.Element;
    id: string;
    time?: boolean;
    timeFirst?: boolean;
    onDateTimeChange?: (date?: string, time?: string) => void;
    groupClassName?: string;
    labelClassName?: string;
    required?: boolean;
    className?: string;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
    popperPlacement?: DatePickerPopperPlacement;
    placeholder?: string;
}

export interface DatePickerObj {
    date?: string;
    time?: string;
}

interface DatePickerState extends FormComponentState {
    didValueChange?: boolean;
    key?: string;
}



export class DateTimePicker extends FormComponent<DatePickerObj, DatePickerProps, DatePickerState> {


    public static toJSON(name: string, values: any): any {
        return {
            [name]: DateTimePicker.parseDateToString(values[name])
        };
    }

    public static toFormData(name: string, values: any): any {
        let value = values[name];
        if (!DateTimePicker.isEmpty(value)) {
            return {
                [name]: DateTimePicker.parseDateToString(value)
            };
        }
    }

    public static toFormDataPost(name: string, values: any): any {
        return DateTimePicker.toFormData(name, values);
    }

    public static fromJSON(name: string, values: any): any {
        let momentObj = values[name] && moment.utc(values[name]);
        if (momentObj) {
            return {
                [name]: DateTimePicker.getDateObjFromMoment(momentObj, values[name].length > 10)
            };
        }
        return {
            [name]: {
                date: null,
                time: null
            }
        };
    }

    public static isEmpty(value: DatePickerObj): boolean {
        return !value || (!value.date && !value.time);
    }

    // NOTE: Nie usuwać, metoda wywoływana dynamicznie
    public static renderValue(val: DatePickerObj): any {
        let valDate = val.date  && `od ${moment(val.date).format("DD.MM.YYYY")}`;
        let valTime = val.time  && `do ${moment(val.time).format("HH:MM")}`;

        return valDate && valTime ? `${valDate} ${valTime}` : valTime ? valTime : valDate;
    }

    public static isEqual(val1: DatePickerObj, val2: DatePickerObj): boolean {
        let date1 = val1 ? val1.date : null;
        let date2 = val2 ? val2.date : null;
        let time1 = val1 ? val1.time : null;
        let time2 = val2 ? val2.time : null;


        return (!val1 && !val2) || (date1 === date2 && time1 === time2);
    }

    public static fromFormData(name: string, values: any): any {
        return DateTimePicker.fromJSON(name, values);
    }

    public static getDateObjFromMoment(momentObj: moment.Moment, time: boolean): DatePickerObj {
        return {
            date: momentObj.format(DateTimePicker.dateFormat),
            time: time ? momentObj.format("HH:mm") : undefined
        };
    }

    public static parseDateToString(dateObj?: DatePickerObj): string | undefined {
        if (!dateObj) {
            return undefined;
        }

        if (dateObj.date) {
            if (dateObj.time) {
                let timeParts = DateTimePicker.getTimeparts(dateObj.time);
                if (timeParts) {
                    return moment.utc(dateObj.date)
                        .hour(timeParts.hour)
                        .minute(timeParts.minute)
                        .format();
                } else {
                    return dateObj.date + " " + dateObj.time;
                }
            } else {
                return dateObj.date;
            }
        } else if (dateObj.time) {
            return dateObj.time;
        }

        return undefined;
    }

    private static getTimeparts(time: string): any {
        if (time == null) {
            return null;
        }

        let timeArr = time.split(":");
        if (timeArr.length !== 2 || (timeArr[0].length !== 2 || timeArr[1].length !== 2)) {
            return null;
        }
        let hour = parseInt(timeArr[0], 10);
        let minute = parseInt(timeArr[1], 10);
        if (_.isNaN(hour) || _.isNaN(minute)) {
            return null;
        }

        return {hour, minute};
    }

    private static displayFormat = "DD.MM.YYYY";
    private static dateFormat = "YYYY-MM-DD";
    private static dateTimeFormat = "YYYY-MM-DD HH:mm";

    public constructor(props: DatePickerProps) {
        super(props);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            didValueChange: false,
            key: randomString()
        };
    }

    /**
     * Lifecycle
     */

    public createMoment(date: string) {
        if (date && date.length > DateTimePicker.dateFormat.length) {
            return moment.utc(date, this.props.time ? DateTimePicker.dateTimeFormat : DateTimePicker.dateFormat, true);
        } else {
            return moment.utc(date, DateTimePicker.dateFormat, true);
        }
    }

    public componentDidMount(): void {
        this.afterRender(this.props);
    }

    public componentDidUpdate(): void {
        this.afterRender(this.props);
    }

    private afterRender(props: DatePickerProps): void {
        const { name, value, onValueChange } = props;
        const { didValueChange } = this.state;
        if (didValueChange && _.isFunction(onValueChange)) {
            this.setState({
                didValueChange: false
            });
            onValueChange(name, value);
        }
    }

    /**
     * Helpers
     */

    private getSelectedTime(): string {
        return this.props.value && this.props.value.time || "";
    }

    /**
     * Callbacks
     */

    public onChange(date: moment.Moment): void {
        let dateString = date && date.format(DateTimePicker.dateFormat) || undefined;

        if (_.isFunction(this.props.onFormUpdate)) {
            this.setState({
                didValueChange: true,
                key: randomString()
            });
            this.props.onFormUpdate(this.props.name, {
                date: dateString,
                time: this.props.value && this.props.value.time
            });
        }
        if (_.isFunction(this.props.onDateTimeChange)) {
            this.props.onDateTimeChange(dateString, this.props.value && this.props.value.time);
        }
    }

    public onTimeChange(name: string, timeSelectValue: SelectValue): void {
        let time = timeSelectValue ? timeSelectValue.value : "";
        if (!DateTimePicker.getTimeparts(time)) {
            let newTime = "0" + time;
            let newTimeParts = DateTimePicker.getTimeparts(newTime);
            if (newTimeParts) {
                time = newTime;
            }
        }

        if (_.isFunction(this.props.onFormUpdate)) {
            this.setState({
                didValueChange: true
            });
            this.props.onFormUpdate(this.props.name, {
                date: this.props.value && this.props.value.date,
                time
            });
        }
        if (_.isFunction(this.props.onDateTimeChange)) {
            this.props.onDateTimeChange(this.props.value && this.props.value.date, time);
        }
    }

    /**
     * Render methods
     */

    private renderLabel(forName: string): JSX.Element | null {
        let helpOverlay: JSX.Element | undefined;
        if (this.props.helpText) {
            const helpOverlayId = `${this.props.id}helpOverlay`;
            helpOverlay = (
                <div className="dib ml-md">
                    <HelpOverlay id={helpOverlayId} placement={this.props.helpPlacement || "right"} className={this.props.helpClassName}>
                        {this.props.helpText}
                    </HelpOverlay>
                </div>
            );
        }

        if (this.props.label) {
            return (
                <div>
                    <label htmlFor={forName} className={`control-label ${this.props.labelClassName}`}>
                        {this.props.label} {helpOverlay}
                    </label>
                </div>
            );
        }

        return null;
    }

    private renderDatepickerInput(name: string, selected?: moment.Moment, startDate?: moment.Moment, endDate?: moment.Moment): JSX.Element {
        let selectedDate: moment.Moment | undefined;

        if (selected) {
            selectedDate = selected;
        } else if (this.props.value && this.props.value.date) {
            selectedDate = this.createMoment(this.props.value.date);
        }

        let additionalProps: any = {};
        if (startDate) {
            additionalProps.startDate = startDate;
        }
        if (endDate) {
            additionalProps.endDate = endDate;
        }

        return (
            <DatePicker
                ref="datepicker"
                {...additionalProps}
                onChange={this.onChange}
                id={this.props.id}
                selected={selectedDate}
                locale="pl"
                key={this.state.key}
                dateFormat={DateTimePicker.displayFormat}
                className={this.props.className}
                popperPlacement={this.props.popperPlacement}
                placeholderText={this.props.placeholder}
            />
        );
    }

    private renderTimepickerInput(name: string): JSX.Element {
        let options: SelectOption[] = [];
        for (let i = 7; i <= 22; i++) {
            options.push({value: (i < 10 ? "0" : "") + i + ":00", label: (i < 10 ? "0" : "") + i + ":00"});
            options.push({value: (i < 10 ? "0" : "") + i + ":30", label: (i < 10 ? "0" : "") + i + ":30"});
        }

        return (
            <Select
                allowCreate
                dummy
                searchable
                searchInside
                showAllValues
                options={options}
                groupClassName="mb-0"
                value={{value: this.getSelectedTime()}}
                onFormUpdate={this.onTimeChange}
                name={name + "_time"}
                error={null} />
        );
    }

    private defaultDateTimeComponentRenderer(params: {getDatepickerInput: () => JSX.Element; getTimepickerInput: () => JSX.Element}): JSX.Element {
        if (this.props.time) {
            if (this.props.timeFirst) {
                return (
                    <div className="row date-time-picker">
                        <div className="col-xs-12 col-sm-5">
                            {params.getTimepickerInput()}
                        </div>

                        <div className="col-xs-12 col-sm-7">
                            {params.getDatepickerInput()}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="row date-time-picker">
                        <div className="col-xs-12 col-sm-7">
                            {params.getDatepickerInput()}
                        </div>

                        <div className="col-xs-12 col-sm-5">
                            {params.getTimepickerInput()}
                        </div>
                    </div>
                );
            }
        }

        return params.getDatepickerInput();
    }

    private defaultRenderer(params: {error: any; getDatepickerInput: () => JSX.Element; getTimepickerInput: () => JSX.Element}): JSX.Element {
        const EmptyTooltip = () => <span />;

        const hasError = !!params.error;
        const tooltip = hasError ? (<Tooltip className="tooltip-error" id={`tooltip-${this.props.name}`}>{params.error}</Tooltip>) : <EmptyTooltip />;
        const groupClassName = classNames(hasError ? "form-group psr" : "form-group react-datepicker-holder", this.props.groupClassName, this.props.markable && !DateTimePicker.isEmpty(this.props.value) ? "is-active" : "");

        return (
            <div className={hasError ? "has-error" : "psr"}>
                {this.renderLabel(this.props.id)}
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <div className={groupClassName}>
                        {this.defaultDateTimeComponentRenderer(params)}
                        {hasError && (<span className="glyphicon glyphicon-remove form-control-feedback" />)}
                    </div>
                </OverlayTrigger>
            </div>
        );
    }

    public render(): JSX.Element {
        const { error } = this.props;

        return this.defaultRenderer({
            getDatepickerInput: () => this.renderDatepickerInput(this.props.name),
            getTimepickerInput: () => this.renderTimepickerInput(this.props.name),
            error
        });
    }
}
