import * as React from "react";
import * as qs from "qs";

import {IReelFormValues} from "../reducers/reels";
import {reelsPreviewLinks} from "../video_preview_path";

interface IProps {
    reelData: IReelFormValues;
}

interface IReelData extends IReelFormValues{
    id: number;
    url: string;
}

export function ReelPreview(props: IProps) {
    const data: IReelData = {
        ...props.reelData,
        id: 123,
        url: "#"
    }

    const query = qs.stringify(data)
    const previewUrl = `${reelsPreviewLinks.reels()}?${query}`;

    return <iframe frameBorder={0} src={previewUrl} width="100%" height="600px"></iframe>;
}
