import * as html2canvas from "html2canvas";
import {saveAs} from "file-saver";

export const htmlToImgDownload = (elem: HTMLElement, name: string, id: number) => html2canvas(elem, {scale: 1})
    .then((canvas: HTMLCanvasElement) => {
        const fullQualityImage = canvas.toDataURL("image/jpeg", .96);
        const fileName = createFileName(name, id);
        saveAs(fullQualityImage, fileName);
        document.body.appendChild(canvas);
    });

const createFileName = (name: string, id: number) => `${name}_${id}.jpg`;
