import {Dispatch} from "redux";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {nphApiLink} from "../../project/route/nph_api_link";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {PAGE_PARAM} from "../../../../shared/ts/constants/queryParams";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {IPlanOfferGallery} from "../../offer_plans/actions/fetch_plan_offer_actions";

/**
 * Offer list
 */

export interface ITagsOffer {
    address: string;
    construction_date_range: {
        lower: string;
        upper: string;
    };
    construction_end_date_quarter: string;
    floors_above_ground_range: {
        lower: number;
        upper: number;
        bounds: string;
    };
    gallery: IPlanOfferGallery[];
    main_image: string;
    name: string;
    pk: number;
    price_type: null;
    stats: {};
    type: {
        pk: number,
        name_plural: string;
    };
    region: {
        pk: number;
        short_name: string;
    };
    tags: {
        id: number;
        name: string;
        category: number;
    }[];
    banned_tags: {
        id: number;
        name: string;
        category: number;
    }[];
    ban_all: boolean;
    vendor: {
        name: string;
        pk: number;
        slug: string;
        logo: string;
    };
}

const FETCH_TAGS_OFFER_LIST = "offer/plan-offers/FETCH_TAGS_OFFER_LIST";
export const fetchTagsfferListTypes = createRequestActionTypes(FETCH_TAGS_OFFER_LIST);

export const fetchTagsOfferList = (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch({type: fetchTagsfferListTypes.start, latestQuery: queryObj});
    const url = appendQueryString(nphApiLink.offers.list(), queryObj);
    return getRequest(url)
        .then((res) => {
            dispatch({
                type: fetchTagsfferListTypes.success,
                results: res.results,
                pagination: {
                    pageSize: res.page_size,
                    total: res.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }});
            return res.results;
        })
        .catch(catch404(err => {
            dispatch({type: fetchTagsfferListTypes.error});
            return null;
        }));
};

export const resetTagsOfferList = () => ({type: fetchTagsfferListTypes.reset});
