import * as React from "react";
import * as classnames from "classnames";

import {SortDirection} from "../../../../shared/ts/decorators/sort";

import StyleProps from "../../../../shared/ts/interfaces/style";
import TableColor from "./table_color";
import DirectionArrows from "../icon/DirectionArrows";
import {DirectionArrow} from "../icon/DirectionArrows";

export interface TableHeaderProps extends StyleProps {
    color?: TableColor;
    sortable?: boolean;
    sortName?: string;
    sortDirection?: SortDirection | null;
    onHeaderClick?: (column: string) => void;
}


export default class TableHeader extends React.Component<TableHeaderProps, {}> {

    constructor(props: TableHeaderProps) {
        super(props);
        this.onHeaderClick = this.onHeaderClick.bind(this);
    }

    private onHeaderClick(e: React.MouseEvent): void {
        if (this.props.sortName) {
            this.props.onHeaderClick && this.props.onHeaderClick(this.props.sortName);
        }
    }

    public render(): JSX.Element {
        const props = this.props;

        let tableHeaderStyle = "vam";
        let directionIcon: DirectionArrow | undefined;

        // Is sortable
        if (props.sortable) {
            tableHeaderStyle = "sortable vam curp unselectable";

            // Icon sort direction
            if (props.sortDirection != null) {
                tableHeaderStyle = "sorted vam curp unselectable";
                directionIcon = props.sortDirection === SortDirection.Asc ? "up" : "down";
            }
        }
        const className = classnames(props.color, props.className, tableHeaderStyle);

        return (
            <th className={className} onClick={this.onHeaderClick}>
                <div className="df fai-center">
                    {props.sortable && <DirectionArrows active={directionIcon} />}
                    {props.children}
                </div>
            </th>
        );
    }
}
