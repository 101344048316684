import {reduce, map, isArray} from "lodash";
import DatePicker from "../fields/date_picker/DatePicker";
import DatePickerRange from "../fields/date_picker/DatePickerRange";
import Input from "../fields/input/Input";
import {Select, SelectAsync, SelectCreatable, SelectAsyncCreatable, SelectOption} from "../fields/select/Select";
import {DateTimePicker, DateTimePickerRange} from "../fields/date_time_picker/DateTimePicker";
import Textarea from "../fields/textarea/Textarea";
import TimePicker from "../fields/time_picker/TimePicker";
import {TimePickerRange} from "../fields/time_picker/TimePicker";


type RawData = any;
type FormData = any;
type FormFieldClass = any;


const notEmpty = (value: any) => value == null ? undefined : value;


export const toFormData = (types: {[key: string]: FormFieldClass}, rawData: RawData): FormData => {
    return reduce(types, (acc: FormData, type: FormFieldClass, key: string) => {
        switch (type) {
            case DatePicker:
            case Input:
            case Textarea:
                return {...acc, [key]: notEmpty(rawData[key])};
            case Select:
            case SelectCreatable:
                if (rawData[key] == null) {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: isArray(rawData[key]) ? map(rawData[key], (o: SelectOption) => o.value) : rawData[key]
                };
            case SelectAsync:
            case SelectAsyncCreatable:
                if (rawData[key] == null) {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: isArray(rawData[key]) ? map(rawData[key], (o: SelectOption) => o.value) : rawData[key].value
                };
            case DatePickerRange:
                return {
                    ...acc,
                    [`${key}_0`]: notEmpty(rawData[`${key}__lower`]),
                    [`${key}_1`]: notEmpty(rawData[`${key}__upper`])
                };
            default:
                return console.error(`toFormData: not implemented for ${key}`);
        }
    }, {});
};
