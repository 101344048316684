import * as React from "react";
import * as _ from "lodash";
import shallowCompare = require("react-addons-shallow-compare");
import {Dict, ClassProps} from "../helpers/interfaces";
import {RequestState} from "../helpers/util";
import ClassAttributes = React.ClassAttributes;


/**
 * Class helpers
 */

/**
 * Sprawdza czy istnieje wpis zawierający klasę w obiekcie definiującym pola formularza, oraz czy ta klasa implementuje odpowiednią funkcję
 */
function isClassValid(elemClassesList: any, elemName: string, functionName: string): boolean {
    const ElemClass = elemClassesList[elemName].type;
    if (_.isUndefined(ElemClass)) {
        console.error(`Missing class definition for field ${elemName}`);
        return false;
    }
    if (!_.isFunction(ElemClass[functionName])) {
        console.error(`Missing ${functionName} definition for field ${elemName} of type ${ElemClass.name}`);
        return false;
    }
    return true;
}

/**
 * Wywołuje funkcję na każdej klasie z przekazanymi danymi - zwraca połączony wynik
 */
export function reduceClasses(functionName: string, elemClassesObj: ElementClasses, storeValues: any): any {
    return _.reduce(elemClassesObj, (acc: any, ElemClass: ElementClasses | ElementInfo, elemName: string) => {
        if (_.isUndefined((ElemClass as ElementInfo).type)) {
            return _.assign(acc, _.assign({}, acc[elemName], {
                [elemName]: reduceClasses(functionName, ElemClass as ElementClasses, storeValues[elemName])
            }));
        }
        if (isClassValid(elemClassesObj, elemName, functionName)) {
            return _.assign(acc, (ElemClass as any).type[functionName](elemName, storeValues || {}));
        }
        return acc;
    }, {});
}

const appendToFormData = (formData: FormData, elementClassesObj: ElementClasses, storeValues: any): any => {
    _.each(elementClassesObj, (ElemClass: {type: any}, elemName: string) => {
        if (_.isObject(ElemClass) && !_.isFunction(ElemClass.type)) {
            appendToFormData(formData, ElemClass, storeValues[elemName]);
        }
        if (isClassValid(elementClassesObj, elemName, "toFormDataPost")) {
            _.each(ElemClass.type.toFormDataPost(elemName, storeValues), (value: any, name: any) => {
                if (_.isArray(value)) {
                    _.each(value as any[], (elemValue) => {
                        formData.append(name, elemValue);
                    });
                } else {
                    formData.append(name, value);
                }
            });
        }
    });

    return formData;
};

const areValuesEqual = (elementClassesObj: ElementClasses, values1: any, values2: any): boolean => {
    if ((!values1 || !values2) && (values1 != values2))
        return false;

    let result = true;
    _.each(elementClassesObj, (ElemClass: {type: any}, elemName: string) => {
        if (_.isObject(ElemClass) && !_.isFunction(ElemClass.type)) { // it is plain object
            result = result && areValuesEqual(ElemClass, values1[elemName], values2[elemName]);
        }
        else { // it is simply form field
            result = result && ElemClass.type.isEqual(values1[elemName], values2[elemName]);
        }
    });
    return result;
};

/**
 * Class definition
 */

export interface FormDataObject {
    [field: string]: any;
}

export interface FieldErrors {
    [s: string]: string[];
}

export interface ElementInfo {
    // type: typeof FormComponent; // TODO: types: problem with React typings
    type: any;
    label?: string | JSX.Element;
    unit?: string | JSX.Element;
}

export interface ElementClasses {
    [field: string]: ElementClasses | ElementInfo;
}

export interface PanelElementClasses {
    [panel: string]: ElementClasses;
}

export interface FieldOrderInfo {
    panelName?: string;
    fields: string[];
}

export interface FormProps extends ClassProps, ClassAttributes<any> {
    onSubmit: React.FormEventHandler;
    onReset?: React.MouseEventHandler;
    values: Dict;
    errors: FieldErrors | null;
    onValuesChange?: (name: string) => void;
    updateFormAction: (data: any) => void;
    buttonState?: boolean;
    requestState?: RequestState;
    name?: string;
}

export interface FormState {}

type transformFunction = (values: any) => any;

export class Form<T extends FormProps, U extends FormState> extends React.Component<T, U> {

    public static elementClasses: ElementClasses = {};

    public static fieldOrder: FieldOrderInfo[] = [];

    /**
     * Tworzy funkcję wywołującą na elementach formularza funkcję (formName) i łączy wyniki
     */
    private static transformFactory(functionName: string): transformFunction {
        return function (storeValues: any): any {
            const elemClassesList = this.elementClasses;
            return reduceClasses(functionName, elemClassesList, storeValues);
        };
    }

    private static getFormDataObject(): any {
        return function (storeValues: any): any {
            const elemClassesList = this.elementClasses;
            let formData = new FormData();
            return appendToFormData(formData, elemClassesList, storeValues);
        };
    }

    private static checkIsEqual(): any {
        return function (values1: any, values2: any): boolean {
            return areValuesEqual(this.elementClasses, values1, values2);
        };
    }

    private static checkIsEmpty(): any {
        return function (values: any): boolean {
            return values && _.every(values, (value: any, name: string) => {
                    const type = (this.elementClasses[name] as ElementInfo).type;
                    return type.isEmpty(value);
                });
        };
    }

    public static toJSON: transformFunction = Form.transformFactory("toJSON");
    public static fromJSON: transformFunction = Form.transformFactory("fromJSON");
    public static toFormData: transformFunction = Form.transformFactory("toFormData");
    public static fromFormData: transformFunction = Form.transformFactory("fromFormData");

    public static createFormDataObject: (storeValues: any) => FormData = Form.getFormDataObject();

    public static isEqual: (values1: any, values2: any) =>  boolean  = Form.checkIsEqual();
    public static isEmpty: (values: any) =>  boolean  = Form.checkIsEmpty();

    constructor(props: T) {
        super(props);
        this.onValueChange = this.onValueChange.bind(this);
        this.onFormUpdate = this.onFormUpdate.bind(this);
    }

    public shouldComponentUpdate(nextProps: T, nextState: U): boolean {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * Callback wywoływany przez form component przy zmianie jego wartości
     */
    protected onValueChange(name: string, value: any): void {
        if (_.isFunction(this.props.onValuesChange)) {
            const idx = name.indexOf(".");
            const field = idx === -1 ? name : name.slice(0, idx);
            this.props.onValuesChange(field);
        }
    }

    protected onFormUpdate(name: string, value: any): void {
        if (_.isFunction(this.props.updateFormAction)) {
            this.props.updateFormAction(_.set({}, name, value));
        }
    }

    /**
     * Generuje propsy dla FormComponent
     * todo     sprawdzic czy mozna zamiast any dac FormComponentProps, na razie jest problem z typem, ktorego
     * todo     FormComponentProps wymaga (jest generyczny)
     * @param name - nazwa pola
     * @returns {any} - propsy komponentu
     */
    protected generateProps(name: string): any {
        const value = _.get(this.props.values, name);
        return {
            name,
            id: name,
            ref: name,
            requestState: this.props.requestState,
            value: !_.isUndefined(value) ? value : null,
            error: this.props.errors ? _.get(this.props.errors, name) : null,
            onValueChange: this.onValueChange,
            onFormUpdate: this.onFormUpdate,
            formName: this.props.name,
            label: (_.get((this.constructor as typeof Form).elementClasses, name) as ElementInfo).label
        };
    }

}

export class SimpleForm extends Form<FormProps, FormState> {}
