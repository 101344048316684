import * as React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";
import Icon from "../../shared/icon/Icon";
import {Link} from "react-router";
import {url} from "../../../../shared/ts/helpers/routing";
import {invalidateCampaignList} from "../../sms/actions/fetch";
import {Store} from "../../project/reducer";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RouterProps} from "../../../../shared/ts/interfaces/router";


interface IProps extends StyleProps {}
/**
 * Navigation Item Component
 */

const NavItemC: React.SFC<IProps> = props => {
    const className = classnames(props.className, "item p-0 db psr tal");
    return <li className={className}>{props.children}</li>;
};

const NavItem = NavItemC;


/**
 * Navigation Group Component
 */
const NavSubmenuC: React.SFC<IProps> = props => {
    const className = classnames(props.className, "sub-nav dn p-0 psa t0 z1");
    return <ul className={className}>{props.children}</ul>;
};

const NavSubmenu = NavSubmenuC;


/**
 * Navigation Main Component
 */

interface StateProps {
    campaignListLatestQuery: Dictionary<string>;
}
interface ActionsProps {
    invalidateCampaignList: typeof invalidateCampaignList;
}
interface OwnProps {
    onPanelOpel: React.MouseEventHandler;
}
interface NavigationProps extends StateProps, ActionsProps, OwnProps, StyleProps, RouterProps {}


@withRouter
@connect(mapStateToProps, {invalidateCampaignList})
class NavigationC extends React.Component<NavigationProps, {}> {
    public render() {
        return (
            <div className="navigation-list">
                <div className="nav psr p-0 m-0 tac nowrap">
                {/*    div className="psr sub-nav-holder">*/}
                {/*    <Link to={url("app:in:sms:list")} className="sub-nav-title-holder curp db tdn" activeClassName="active"*/}
                {/*          onClick={(e: React.MouseEvent) => {*/}
                {/*              e.preventDefault();*/}
                {/*              this.props.invalidateCampaignList();*/}
                {/*              this.props.router.push(url("app:in:sms:list", null, this.props.campaignListLatestQuery));*/}
                {/*          }}>*/}
                {/*            <span className="sub-nav-icon-holder dib vam mr-0 fs22">*/}
                {/*                <Icon icon="sms"/>*/}
                {/*            </span>*/}

                {/*        <span className="sub-nav-title dn vam">*/}
                {/*                Kampanie SMS*/}
                {/*            </span>*/}
                {/*    </Link>*/}

                {/*    <NavSubmenu>*/}
                {/*        <NavItem>*/}
                {/*            <Link to={url("app:in:sms:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active"*/}
                {/*                  onClick={(e: React.MouseEvent) => {*/}
                {/*                      e.preventDefault();*/}
                {/*                      this.props.invalidateCampaignList();*/}
                {/*                      this.props.router.push(url("app:in:sms:list", null, this.props.campaignListLatestQuery));*/}
                {/*                  }}>*/}
                {/*                Lista kampanii*/}
                {/*            </Link>*/}
                {/*        </NavItem>*/}

                {/*        <NavItem>*/}
                {/*            <Link to={url("app:in:sms:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">*/}
                {/*                Dodaj kampanię*/}
                {/*            </Link>*/}
                {/*        </NavItem>*/}
                {/*    </NavSubmenu>*/}
                {/*</div><*/}

                    {/*<div className="psr sub-nav-holder">
                        <Link to={url("app:in:natives:list")} className="sub-nav-title-holder curp db tdn" activeClassName="active"
                              onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    this.props.invalidateCampaignList();
                                    this.props.router.push(url("app:in:natives:list", null));
                                }}>
                            <span className="sub-nav-icon-holder dib vam mr-0 fs22">
                                <Icon icon="screen"/>
                            </span>

                            <span className="sub-nav-title dn vam">
                                Reklamy natywne
                            </span>
                        </Link>

                        <NavSubmenu>
                            <NavItem>
                                <Link to={url("app:in:natives:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active"
                                      onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            this.props.invalidateCampaignList();
                                            this.props.router.push(url("app:in:natives:list", null));
                                        }}>
                                    Lista reklam
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:natives:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj reklamę
                                </Link>
                            </NavItem>
                        </NavSubmenu>
                    </div>*/}

                    <div className="psr sub-nav-holder">
                        <Link to={url("app:in:creation:native:list")} className="sub-nav-title-holder curp db tdn" activeClassName="active">
                            <span className="sub-nav-icon-holder dib vam mr-0 fs22">
                                <Icon icon="screen"/>
                            </span>

                            <span className="sub-nav-title dn vam">
                                Kreacje reklamowe
                            </span>
                        </Link>

                        <NavSubmenu>
                            <NavItem>
                                <Link to={url("app:in:creation:native:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista kreacji natywnych
                                </Link>
                            </NavItem>

                            <NavItem className="mb-md">
                                <Link to={url("app:in:creation:native:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj kreację natywną
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:monthly:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista kreacji miesiąca
                                </Link>
                            </NavItem>

                            <NavItem className="mb-md">
                                <Link to={url("app:in:creation:monthly:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj kreację miesiąca
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:image:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista reklam obrazkowych
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:image:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj reklamę obrazkową
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:surprise:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista reklam zakrytych
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:surprise:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj reklamę zakrytą
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:hero:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista reklam Hero
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:hero:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj reklamę Hero
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:tda:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista reklam TDA
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:creation:tda:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj reklamę TDA
                                </Link>
                            </NavItem>
                        </NavSubmenu>
                    </div>

                    <div className="psr sub-nav-holder">
                        <Link to={url("app:in:offerPlans")}
                              className="sub-nav-title-holder curp db tdn"
                              activeClassName="active"
                              onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    this.props.router.push(url("app:in:offerPlans", null));
                                }}>
                            <span className="sub-nav-icon-holder dib vam mr-0 fs22">
                                <Icon icon="photo"/>
                            </span>

                            <span className="sub-nav-title dn vam">
                                Plany Inwestycji
                            </span>
                        </Link>
                    </div>

                    <div className="psr sub-nav-holder">
                        <Link
                            to={url("app:in:offerTags")}
                            className="sub-nav-title-holder curp db tdn"
                            activeClassName="active"
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.props.router.push(url("app:in:offerTags", null));
                            }}
                        >
                            <span className="sub-nav-icon-holder dib vam mr-0 fs22">
                                <Icon icon="pencil"/>
                            </span>

                            <span className="sub-nav-title dn vam">
                                Tagi inwestycji
                            </span>
                        </Link>
                    </div>

                    <div className="psr sub-nav-holder">
                        <Link
                            to={url("app:in:video")}
                            className="sub-nav-title-holder curp db tdn"
                            activeClassName="active"
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.props.router.push(url("app:in:video", null));
                            }}
                        >
                            <span className="sub-nav-icon-holder dib vam mr-0 fs22">
                                <Icon icon="screen"/>
                            </span>

                            <span className="sub-nav-title dn vam">
                                Materiały wideo
                            </span>
                        </Link>

                        <NavSubmenu>
                            <NavItem>
                                <Link to={url("app:in:video:reel:list")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista reels
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:video:reel:add")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Dodaj reels
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link to={url("app:in:video:reel:creator")} className="item-name curp tdn-h db tdn pv-md pr-md pl-xl" activeClassName="active">
                                    Lista autorów
                                </Link>
                            </NavItem>
                        </NavSubmenu>
                    </div>
                </div>
            </div>
        );
    }
}

export const Navigation = NavigationC as React.ComponentClass<OwnProps>;

/**
 * Connect
 */
function mapStateToProps(store: Store): StateProps {
    return {
        campaignListLatestQuery: store.sms.fetch.latestQuery
    };
}
