import {each, isArray} from "lodash";

import {createRequestActionTypes, RequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, ResponseAction} from "../../../../../shared/ts/interfaces/dispatch";
import {patchRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {REEL_ADD_PREFIX, reelRequestTypes} from "./add";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {IReel, IReelFormValues} from "../../reducers/reels";
import {transformReelsFormToFormData} from "../../helpers/transform_reels_form_to_form_data";

const createReelEdit = (type: string, actionTypes: RequestActionTypes) => (id: number, values: IReelFormValues) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.reels.edit({reelId: id});
    return patchRequest(urlString, transformReelsFormToFormData(values), null, {addDefaultHeaders: false})
        .then((reel: IReel) => {
            dispatch<ResponseAction<any>>({type: actionTypes.success, result: reel});
            dispatch(addSuccessAlert("Zapisano reel", type));
            return reel;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, REEL_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", EDIT_REEL_PATCH));
            }
            dispatch<ErrorAction>({type: reelRequestTypes.error, error: responseError.appError});
            return null;
        }));
};

export const CLEAR_REEL_EDIT_VIEW_STATE = "reel/CLEAR_REEL_EDIT_VIEW_STATE";
export const clearReelEditViewState = () => ({type: CLEAR_REEL_EDIT_VIEW_STATE});

const EDIT_REEL_PATCH = "reel/edit/EDIT_REEL_PATCH";
export const reelEditPatchTypes = createRequestActionTypes(EDIT_REEL_PATCH);
export const reelEditPatch = createReelEdit(EDIT_REEL_PATCH, reelEditPatchTypes);
