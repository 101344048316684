import * as React from "react";
import * as hoistStatics from "hoist-non-react-statics";
import {connect} from "react-redux";

import {ErrorPageStore, ForcedPage} from "../reducers/error_page";
import {disableForcedState} from "../actions/error_page";
import {BlankPage} from "../views/blank_page";
import {RouterState} from "../interfaces/router";


interface StateProps {
    errorPage: ErrorPageStore;
}
interface ActionsProps {
    disableForcedState: typeof disableForcedState;
}
interface ErrorPageProps extends StateProps, ActionsProps, RouterState {}


export const errorPage = (NotFoundComponent: any) => (InnerComponent: any) => {

    @connect(mapStateToProps, {disableForcedState})
    class ErrorPageComponent extends React.Component<ErrorPageProps, {}> {

        /**
         * Lifecycle
         */

        public componentDidUpdate(prevProps: ErrorPageProps): void {
            if (prevProps.location.key !== this.props.location.key) {
                this.props.disableForcedState();
            }
        }

        /**
         * Render
         */

        public render(): JSX.Element {
            switch (this.props.errorPage.forcedPage) {
                case ForcedPage.BlankPage:
                    return <BlankPage />;
                case ForcedPage.ErrorPage:
                    const {code, title, description, showBackButton} = this.props.errorPage;
                    let errorProps: any = {};
                    if (code != null) { errorProps.code = code; }
                    if (title != null) { errorProps.title = title; }
                    if (description != null) { errorProps.description = description; }
                    if (showBackButton != null) { errorProps.showBackButton = showBackButton; }
                    return <NotFoundComponent {...errorProps} />;
                default:
                    return <InnerComponent {...this.props} />;
            }
        }
    }

    /**
     * Connect
     */

    function mapStateToProps(state: {errorPage: ErrorPageStore}): StateProps {
        return {
            errorPage: state.errorPage
        };
    }

    return hoistStatics(ErrorPageComponent, InnerComponent);
};
