import * as React from "react";
import {Field, WrappedFieldProps} from "redux-form";
import * as classnames from "classnames";
import {Store} from "../../../project/reducer";
import FieldError from "../FieldError";
import FieldLabel from "../FieldLabel";

export const createRange = (InnerComponent: any) => {

    /**
     * Field
     */
    interface RangeFieldProps extends WrappedFieldProps<Store>, RangeProps {
        error?: string;
        lowerProps?: {};
        upperProps?: {};
        upperHour?: number;
        lowerHour?: number;
    }

    class RangeField extends React.Component<RangeFieldProps, {}> {

        private renderFields(): JSX.Element {
            const {input: {name}} = this.props;

            return (
                <div className="row bottom-xs">
                    <div className="col-xs-6">
                        <InnerComponent
                            name={`${name}__lower`}
                            disabled={this.props.disabled}
                            onValueUpdate={this.props.onValueUpdate}
                            className={this.props.className}
                            groupClassName={this.props.groupClassName}
                            lowerHour={this.props.lowerHour}
                            upperHour={this.props.upperHour}
                        />
                    </div>

                    <div className="col-xs-6">
                        <InnerComponent
                            name={`${name}__upper`}
                            disabled={this.props.disabled}
                            onValueUpdate={this.props.onValueUpdate}
                            className={this.props.className}
                            groupClassName={this.props.groupClassName}
                            lowerHour={this.props.lowerHour}
                            upperHour={this.props.upperHour}
                        />
                    </div>
                </div>
            );
        }

        public render() {
            const {error, label, input} = this.props;
            const holderClassName = classnames(error && "has-error");
            const fields = this.renderFields();

            return (
                <div className={holderClassName}>
                    {label && <FieldLabel label={label} htmlFor={`${input.name}__lower`}/>}
                    {error ? <FieldError error={error}>{fields}</FieldError> : fields}
                </div>
            );
        }
    }

    /**
     * Main
     */
    interface RangeProps {
        name: string;
        label?: string;
        disabled?: boolean;
        onValueUpdate?: (name: string) => void;
        className?: string;
        groupClassName?: string;
    }

    class Range extends React.Component<RangeProps, {}> {
        public render() {
            return <Field {...this.props} component={RangeField} />;
        }
    }

    return Range;
};
