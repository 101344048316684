import * as React from "react";

import BaseAlert from "./BaseAlert";


interface SuccessAlertProps {
    onCloseClick: () => void;
}


const SuccessAlert: React.SFC<SuccessAlertProps> = props => {
    return (
        <BaseAlert color="success" icon="check-rounded" onCloseClick={props.onCloseClick}>{props.children}</BaseAlert>
    );
};

export default SuccessAlert;
