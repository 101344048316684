import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";

interface IconProps extends StyleProps {
    icon: string;
    onClick?: React.MouseEventHandler;
}

const IconC: React.SFC<IconProps> = props => {
    const className = classnames(props.className, `icon-${props.icon}`);

    return (
        <i className={className} onClick={props.onClick} />
    );
};


const Icon = IconC;
export default Icon;
