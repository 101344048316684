import * as React from "react";

import TableCell from "../../shared/table/TableCell";
import {Button} from "../../shared/button/Button";
import Icon from "../../shared/icon/Icon";
import TableRow from "../../shared/table/TableRow";
import {HocModalix, modalix} from "../../../../shared/ts/decorators/modalix";
import {DateFormat, DateFormatType} from "../../../../shared/ts/components/datetime";
import {RemoveModal} from "../../shared/modal/RemoveModal";


interface IOwnProps {
    className?: string;
    name: string;
    createdBy: string;
    id: number;
    onDeleteClick: (id: number) => void;
    onClick: (id: number) => void;
}
interface IProps extends IOwnProps {
    hoc: HocModalix;
}

interface IState {}

@modalix(RemoveModal)
class TdaListItemC extends React.Component<IProps, IState> {

    private onDeleteClick = (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        this.props.hoc.modalix.show<any>({
            modalTitle: "Usunięcie baneru",
            modalText: "Czy na pewno chcesz usunąć wybrany baner?",
            onConfirm: () => {
                this.props.onDeleteClick(id);
            },
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    public render() {
        const {id, name, createdBy} = this.props;

        return (
            <TableRow className="curp" onClick={() => this.props.onClick(this.props.id)}>
                <TableCell className="vam">
                    {name}
                </TableCell>

                <TableCell className="vam">
                    {createdBy}
                </TableCell>

                <TableCell className="vam tac">
                    <Button color="danger" size="sm" onClick={(e: React.MouseEvent) => this.onDeleteClick(e, id)}><Icon icon="delete" /></Button>
                </TableCell>
            </TableRow>
        );
    }
}

export const TdaListItem = TdaListItemC as React.ComponentClass<IOwnProps>;
