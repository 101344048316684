import * as React from "react";
import * as classNames from "classnames";
import {Select} from "../../shared/form/fields/select/Select";

import {createForm, FormProps, HocForm} from "../../shared/form/create_form";
import {FormSelect} from "../../shared/form/fields/select/FormSelect";
import {FormCheckbox} from "../../shared/form/fields/checkbox/FormCheckbox";
import {SelectOption} from "../../../../shared/ts/components/forms/select";

export const offerTagsDetailFormFields = {
    tags: Select,
    banned_tags: Select,
    ban_all: FormCheckbox
};

interface IOwnProps extends FormProps {
    className?: string;
    tagOptions: SelectOption[];
}

interface IProps extends IOwnProps {
    hoc: HocForm<typeof offerTagsDetailFormFields>;
}

const TAGS_LIMIT = 6;
export const OfferTagsForm = createForm<IOwnProps>()((props: IProps) => {

    const {fieldProps} = props.hoc.form;
    const className = classNames("psr p-lg mb-xxxl row", props.className);
    const {value} = fieldProps("tags");
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className={className}>
                <div className="col-xs-12 col-sm-12 col-md-6">
                    <FormSelect
                        {...fieldProps("tags")}
                        label="Tagi przypisane"
                        showAllValues
                        options={value && value.length >= TAGS_LIMIT ? [] : props.tagOptions}
                        async={false}
                        creatable={false}
                        multi={true}
                        searchable
                        optionsLimit={TAGS_LIMIT}
                    />
                </div>
            </div>

            <div className={className}>
                <div className="col-xs-12 col-sm-12 col-md-6">
                    <FormSelect
                        {...fieldProps("banned_tags")}
                        label="Tagi zbanowane"
                        showAllValues
                        options={props.tagOptions}
                        async={false}
                        creatable={false}
                        multi={true}
                        searchable
                    />
                </div>
            </div>

            <div className={className}>
                <div className="col-xs-12 col-sm-12 col-md-6">
                    <FormCheckbox
                        {...fieldProps("ban_all")}
                        label="Dotyczy wszystkich inwestycji dewelopera"
                    />
                </div>
            </div>
        </form>
    );
});

