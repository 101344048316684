import {browserHistory} from "react-router";
import {isArray, each} from "lodash";

import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, Action} from "../../../../shared/ts/interfaces/dispatch";
import {url} from "../../../../shared/ts/helpers/routing";
import {postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../shared/ts/helpers/errors";

import {Campaign} from "../models/Campaign";
import {addErrorAlert, addSuccessAlert} from "../../shared/alerts/actions";
import {invalidateCampaignList} from "./fetch";
import {nphApiLink} from "../../project/route/nph_api_link";


export const CAMPAIGN_ADD = "campaigns/ADD";
export const campaignAddRequest = createRequestActionTypes(CAMPAIGN_ADD);


export const campaignAdd = (jsonData: Campaign) => (dispatch: Dispatch) => {
    dispatch<Action>({type: campaignAddRequest.start});

    return postRequest(nphApiLink.campaigns.add(), jsonData)
        .then((campaign: Campaign) => {
            dispatch<Action>({type: campaignAddRequest.success});
            browserHistory.push(url("app:in:sms:edit", {id: campaign.uuid}));
            dispatch(invalidateCampaignList());
            dispatch(addSuccessAlert("Dodano kampanię SMS"));
            return campaign;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, CAMPAIGN_ADD)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", CAMPAIGN_ADD));
            }
            dispatch<ErrorAction>({type: campaignAddRequest.error, error: responseError.appError});
            return null;
        }));
};


export const CLEAR_ADD_VIEW_STATE = "campaigns/add/CLEAR_ADD_VIEW_STATE";
export const clearAddViewState = () => ({type: CLEAR_ADD_VIEW_STATE});
