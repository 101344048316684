import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {map, sortBy, reduce, filter} from "lodash";
import {RouterProps, RouterState} from "../../../../shared/ts/interfaces/router";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentBody from "../../project/components/content/ContentBody";
import {Store} from "../../project/reducer";
import {fetchPlanOffer, IPlanOffer, IPlanOfferGallery, resetPlanOffer} from "../actions/fetch_plan_offer_actions";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import {IFabricApi, IFabricMode} from "../components/draw_polygon/init_fabric";
import {PlanItemCanvasHolder} from "../components/add_plan/PlanItemCanvasHolder";
import {PlanItemHeader} from "../components/add_plan/PlanItemHeader";
import {PlanType} from "./OfferPlanView";
import {url} from "../../../../shared/ts/helpers/routing";
import {BoundAction} from "../../../../typings/custom_typings/custom_redux";
import {fetchPlanFloor, IPlanFloor, resetPlanFloor} from "../actions/fetch_plan_floor_actions";
import {PlanFloorAddImage} from "../components/floor/PlanFloorAddImage";
import {fetchPlanPropertyList, IPlanProperty, resetPlanPropertyList} from "../actions/fetch_plan_property_actions";
import {FloorPropertiesListItem} from "../components/floor/FloorPropertiesListItem";
import {patchPropertyPlan} from "../actions/patch_property_plan";
import {
    AssignPropertyModal,
    fromCustomPolygonId,
    getPropertyColor,
    isCustomPolygonId,
    toCustomPolygonId
} from "../components/floor/AssignPropertyModal";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {deletePlanPolygon, updatePlanPolygon} from "../actions/plan_polygon_actions";
import {Modal} from "../../../../shared/ts/modals/Modal";
import {Button} from "../../shared/button/Button";
import {AreaFormat} from "../../../../shared/ts/components/NumbersFormater";
import {Panel} from "../../shared/panel/Panel";


interface IStateProps {
    offer: IPlanOffer | null;
    floor: IPlanFloor | null;
    properties: IPlanProperty[];
    propertiesRequest: RequestState;
}
interface IActionsProps {
    actions: {
        fetchPlanOffer: BoundAction<typeof fetchPlanOffer>;
        resetPlanOffer: BoundAction<typeof resetPlanOffer>;
        fetchPlanFloor: BoundAction<typeof fetchPlanFloor>;
        resetPlanFloor: BoundAction<typeof resetPlanFloor>;
        fetchPlanPropertyList: BoundAction<typeof fetchPlanPropertyList>;
        resetPlanPropertyList: BoundAction<typeof resetPlanPropertyList>;
        // patch
        deletePlanPolygon: BoundAction<typeof deletePlanPolygon>;
        updatePlanPolygon: BoundAction<typeof updatePlanPolygon>;
        patchPropertyPlan: BoundAction<typeof patchPropertyPlan>;
    };
}
interface IProps extends IActionsProps, IStateProps, RouterState, RouterProps {}

const FloorPlanViewC = (props: IProps) => {

    useEffect(() => {
        Promise.all([
            props.actions.fetchPlanOffer(parseInt(props.params["offer_id"], 10)),
            props.actions.fetchPlanFloor(parseInt(props.params["floor_id"], 10)),
            props.actions.fetchPlanPropertyList(parseInt(props.params["floor_id"], 10))
        ]);
        return () => {
            props.actions.resetPlanOffer();
            props.actions.resetPlanFloor();
            props.actions.resetPlanPropertyList();
        };
    }, []);

    const apiRef = useRef<IFabricApi | null>(null);
    const [assignPropertyModalState, setAssignPropertyModalState] = useState<number | null>(null);
    const [mode, setMode] = useState<IFabricMode>({type: "view"});

    /**
     * Polygon modification logic
     */

    const propertyMap = useMemo(() => reduce(props.properties, (acc, p) => ({...acc, [p.pk]: p}), {}), [props.properties]) as Record<number, IPlanProperty>;
    const onPolygonEditStop = async () => {
        if (mode.type !== "edit") {
            throw new Error(`BuildingPlanView, onPolygonEditStop: wrong mode ${mode.type}`);
        }
        if (apiRef.current == null) {
            throw new Error("BuildingPlanView, onPolygonEditStop: apiRef is not defined");
        }
        const polygon = apiRef.current.editStop();
        const apiPolygon = map(polygon, point => [point.fractionX, point.fractionY] as [string, string]);
        // patch property with polygon
        if (isCustomPolygonId(mode.polygonId)) {
            const customId = fromCustomPolygonId(mode.polygonId);
            apiRef.current && apiRef.current.changeColor(mode.polygonId, getPropertyColor(null));
            await props.actions.updatePlanPolygon(customId, {polygon: apiPolygon, floor: parseInt(props.params["floor_id"], 10)});
        }
        else {
            const propertyId = mode.polygonId; // property ID equals polygon ID
            const rooms = propertyMap[propertyId] ? propertyMap[propertyId].rooms : null;
            apiRef.current && apiRef.current.changeColor(mode.polygonId, getPropertyColor(rooms));
            await props.actions.patchPropertyPlan(propertyId, {plan_polygon: apiPolygon});
        }
    };

    const [deleteModalState, setDeleteModalState] = useState<number | null>(null);
    const deletePolygon = async () => {
        const polygonId = deleteModalState as number;
        if (isCustomPolygonId(polygonId)) {
            const customId = fromCustomPolygonId(polygonId);
            await props.actions.deletePlanPolygon(customId);
        }
        else {
            await props.actions.patchPropertyPlan(polygonId, {plan_polygon: null});
        }
        apiRef.current && apiRef.current.remove(polygonId);
        setDeleteModalState(null);
    };

    /**
     * Render
     */

    const sortedProperties = useMemo(() => sortBy(props.properties, property => property.number), [props.properties]);
    const assignedProperties = useMemo(() => filter(props.properties, (property) => property.plan_polygon != null), [props.properties]);
    const sortedAssignedProperties = useMemo(() => sortBy(assignedProperties, property => property.number), [assignedProperties]);
    const unassignedProperties = useMemo(() => filter(props.properties, (property) => !property.plan_polygon), [props.properties]);
    const sortedUnassignedProperties = useMemo(() => sortBy(unassignedProperties, property => property.number), [unassignedProperties]);

    const propertyPolygonEntries = useMemo(() => map(props.properties, p => ({
        id: p.pk, plan_polygon: p.plan_polygon, color: getPropertyColor(p.rooms, p.is_reserved),
        text: (
            <div className="df fw-wrap" style={{minWidth: "180px"}}>
                <span className="fwb">{`${p.number}`}</span>

                {p.rooms && <span className="ml-md">{p.rooms} pok.</span>}

                {p.area && <div className="ml-md"><AreaFormat area={p.area}/></div>}
            </div>
        )
    })), [props.properties]);
    const customPolygonEntries = useMemo(() => {
        if (props.floor == null) {
            return [];
        }
        return map(props.floor.polygons, p => ({
            id: toCustomPolygonId(p.id), plan_polygon: p.polygon, color: getPropertyColor(null),
            text: (
                <div className="df fw-wrap" style={{minWidth: "180px"}}>
                    <span className="fwb mr-md">{`${p.name}`}</span>

                    {p.rooms && <span className="mr-md ">{p.rooms} pok.</span>}

                    {p.area && <AreaFormat area={p.area}/>}
                </div>
            )
        }));
    }, [props.floor]);
    const polygonEntries = useMemo(() => [...propertyPolygonEntries, ...customPolygonEntries], [propertyPolygonEntries, customPolygonEntries]);

    const renderBody = () => {
        const paramOfferId = parseInt(props.params["offer_id"], 10);
        const paramBuildingId = parseInt(props.params["building_id"], 10);
        const paramFloorId = parseInt(props.params["floor_id"], 10);
        const {offer, floor} = props;
        if (offer == null || offer.pk !== paramOfferId || floor == null || floor.id !== paramFloorId || props.propertiesRequest === RequestState.Waiting) {
            return (
                <CenterBox className="vhc">
                    <Loader size="lg"/>
                </CenterBox>
            );
        }

        const photos: IPlanOfferGallery[] = [{image: offer.main_image, pk: 0, sort: 0}, ...offer.gallery];

        return (
            <ContentBody className="h100 psr">
                <PlanItemHeader
                    onBackClick={() => props.router.push(url(
                        "app:in:offerPlans:building",
                        {offer_id: paramOfferId, building_id: paramBuildingId}
                    ))}
                    offerName={offer.name}
                    type={PlanType.FLOOR}
                    floorName={floor.floor.toString()}
                    canvasMode={mode.type}
                    drawStartPolygon={() => apiRef.current && apiRef.current.drawStart()}
                    drawStopPolygon={() => apiRef.current && apiRef.current.drawStop()}
                    editStopPolygon={onPolygonEditStop}
                    renderAdditionalHeader={() => (
                        <PlanFloorAddImage floorId={floor.id} offerGallery={photos} changeMode/>
                    )}
                />

                {floor.plan_image ? (
                    <PlanItemCanvasHolder
                        key={floor.plan_image}
                        offerName={offer.name}
                        image={floor.plan_image}
                        polygons={polygonEntries}
                        onInitCanvas={api => apiRef.current = api}
                        onModeChange={setMode}
                        onDrawComplete={() => setAssignPropertyModalState(-1)}
                    />
                ) : (
                    <PlanFloorAddImage floorId={floor.id} offerGallery={photos}/>
                )}

                <div className="df mb-xxxl">
                    <Panel color="default" className="mr-lg mb-0">
                        <div className="p-lg">
                            Przypisanych nieruchomości: <span className="fwb">{assignedProperties.length}</span>
                        </div>
                    </Panel>

                    <Panel color="default" className="mr-lg mb-0">
                        <div className="p-lg">
                            Nieprzypisanych nieruchomości: <span
                            className="fwb">{props.properties.length - assignedProperties.length}</span>
                        </div>
                    </Panel>

                    <Panel color="default" className="mr-lg mb-0">
                        <div className="p-lg">
                            Stworzonych ręcznie nieruchomości: <span className="fwb">{floor.polygons.length}</span>
                        </div>
                    </Panel>
                </div>

                {sortedAssignedProperties.length > 0 && (
                    <>
                        <p className="fwb fs16 pb-md mb-lg bdbc-gray-light bdbs-solid bdbw-sm">
                            Przypisane nieruchomości
                        </p>

                        <ul className="list-unstyled">
                            {map(sortedAssignedProperties, property => property.plan_polygon && (
                                <FloorPropertiesListItem
                                    key={property.pk}
                                    property={property}
                                    deletePolygon={setDeleteModalState}
                                    editAssignment={() => setAssignPropertyModalState(property.pk)}
                                    editPolygon={() => apiRef.current && apiRef.current.editStart(property.pk)}
                                />
                            ))}
                        </ul>
                    </>
                )}

                {floor.polygons.length > 0 && (
                    <>
                        <p className="fwb fs16 pb-md mb-lg bdbc-gray-light bdbs-solid bdbw-sm">
                            Nieruchomości dodane ręcznie
                        </p>

                        <ul className="list-unstyled">
                            {map(floor.polygons, polygon => (
                                <FloorPropertiesListItem
                                    key={toCustomPolygonId(polygon.id)}
                                    property={{
                                        plan_image: null,
                                        number: polygon.name,
                                        rooms: polygon.rooms,
                                        area: polygon.area,
                                        floor: floor.floor,
                                        for_sale: false,
                                        is_reserved: false,
                                        pk: polygon.id
                                    }}
                                    deletePolygon={id => setDeleteModalState(toCustomPolygonId(id))}
                                    editAssignment={() => setAssignPropertyModalState(toCustomPolygonId(polygon.id))}
                                    editPolygon={() => apiRef.current && apiRef.current.editStart(toCustomPolygonId(polygon.id))}
                                />
                            ))}
                        </ul>
                    </>
                )}

                {sortedUnassignedProperties.length > 0 && (
                    <>
                        <p className="fwb fs16 pb-md mb-lg bdbc-gray-light bdbs-solid bdbw-sm">
                            Nieprzypisane nieruchomości
                        </p>

                        <ul className="list-unstyled">
                            {map(sortedUnassignedProperties, property => (
                                <FloorPropertiesListItem
                                    key={property.pk}
                                    property={property}
                                />
                            ))}
                        </ul>
                    </>
                )}
            </ContentBody>
        );
    };

    return (
        <Main>
            <Content>
                <ContentHeader>
                    <ContentHeaderTitle>
                        Plany inwestycji
                    </ContentHeaderTitle>
                </ContentHeader>

                {renderBody()}

                <AssignPropertyModal
                    assignPropertyModalState={assignPropertyModalState}
                    setAssignPropertyModalState={setAssignPropertyModalState}
                    apiRef={apiRef}
                    properties={sortedProperties}
                    planPolygons={props.floor && props.floor.polygons}
                    floorId={parseInt(props.params["floor_id"], 10)}
                    groupId={undefined}
                />

                <Modal
                    modalState={!!deleteModalState}
                    onModalClose={() => setDeleteModalState(null)}
                    type="window"
                    header="Czy na pewno chcesz usunąć poligon nieruchomości?"
                >
                    <div className="df fjc-center fai-center p-xxl">
                        <Button
                            onClick={deletePolygon}
                            className="pv-md ph-xl mr-lg"
                            color="default"
                            type="button"
                        >
                            Tak
                        </Button>

                        <Button
                            onClick={() => setDeleteModalState(null)}
                            className="pv-md ph-xl"
                            color="default"
                            type="button"
                        >
                            Nie
                        </Button>
                    </div>
                </Modal>
            </Content>
        </Main>
    );
};

export const FloorPlanView = connect(mapStateToProps, mapActionsToProps)(FloorPlanViewC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        offer: state.plan.main.offer,
        floor: state.plan.floor.detail,
        properties: state.plan.property.list,
        propertiesRequest: state.plan.property.fetchRequest
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        actions: bindActionCreators<Record<keyof IActionsProps["actions"], any>, IActionsProps["actions"]>({
            fetchPlanOffer,
            resetPlanOffer,
            fetchPlanFloor,
            resetPlanFloor,
            fetchPlanPropertyList,
            resetPlanPropertyList,
            // patch
            deletePlanPolygon,
            updatePlanPolygon,
            patchPropertyPlan
        }, dispatch)
    };
}
