import * as React from "react";
import * as _ from "lodash";
import {Tooltip, OverlayTrigger, FormGroup} from "react-bootstrap";
import * as classNames from "classnames";

import {FormComponent, FormComponentProps, FormComponentState} from "./formComponent";
import {HelpOverlay} from "../help_overlay";

export interface CheckboxProps extends FormComponentProps<boolean> {
    label?: string | JSX.Element;
    groupClassName?: string;
    className?: string;
    id?: string;
    onChange?: React.FormEventHandler;
    disabled?: boolean;
    onOffSwitch?: boolean;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
}

interface CheckboxState extends FormComponentState {
    didValueChange: boolean;
}

export class Checkbox extends FormComponent<boolean, CheckboxProps, CheckboxState> {

    public static toJSON(name: string, values: any): any {
        return {[name]: values[name] || false};
    }

    public static toFormData(name: string, values: any): any {
        let value = values[name];
        if (!Checkbox.isEmpty(value)) {
            return {[name]: value};
        }
    }

    public static toFormDataPost(name: string, values: any): any {
        return {
            [name]: !!values[name]
        };
    }

    public static fromJSON(name: string, values: any): any {
        return {[name]: !!values[name]};
    }

    public static fromFormData(name: string, values: any): any {
        return {[name]: !!values[name]};
    }

    public static isEmpty(value: boolean): boolean {
        return !value;
    }

    // NOTE: Nie usuwać, metoda wywoływana dynamicznie
    public static renderValue(val: boolean): string {
        let valString = "";
        if (val === true) {
            valString = "Tak";
        }
        return valString;
    }

    public constructor(props: CheckboxProps) {
        super(props);
        this.state = {
            didValueChange: false
        };
        this.onChange = this.onChange.bind(this);
    }

    /**
     * Lifecycle
     */

    public componentDidMount(): void {
        this.afterRender(this.props);
    }

    public componentDidUpdate(): void {
        this.afterRender(this.props);
    }

    private afterRender(props: any): void {
        const { name, value, onValueChange } = props;
        const { didValueChange } = this.state;
        if (didValueChange && _.isFunction(onValueChange)) {
            this.setState({
                didValueChange: false
            });
            onValueChange(name, value);
        }

    }

    /**
     * Callbacks
     */

    public onChange(e: React.FormEvent): void {
        let value = (e.target as HTMLInputElement).checked;
        if (_.isFunction(this.props.onFormUpdate)) {
            this.setState({
                didValueChange: true
            });
            this.props.onFormUpdate(this.props.name, value);
        }
    }

    /**
     * Render methods
     */

    public render(): JSX.Element {
        const { name, error, label, className, helpText, helpPlacement, helpClassName, onOffSwitch } = this.props;
        const groupClassName = classNames("form-group", this.props.groupClassName, this.props.markable && !Checkbox.isEmpty(this.props.value) ? "is-active" : "");
        const groupClassNameError = classNames("has-error psr", groupClassName);

        let props = _.assign({},
            _.omit(
                this.props,
                [
                    "className", "onOffSwitch", "error", "onValueChange", "onFormUpdate", "formName", "groupClassName",
                    "helpText", "helpPlacement", "requestState", "markable",
                    "label",    // label is displayed alongside checkbox
                    "value"     // value is passed via `checked` property
                ]
            ), {
                ref: "input",
                checked: !!this.props.value || false,
                onChange: this.props.onChange || this.onChange
            });

        // Help overlay
        let helpOverlay: JSX.Element | undefined;
        if (helpText) {
            const helpOverlayId = `${name}helpOverlay`;
            helpOverlay = (
                <div className="dib vam ml-md">
                    <HelpOverlay id={helpOverlayId} placement={helpPlacement || "right"} className={helpClassName}>
                        {helpText}
                    </HelpOverlay>
                </div>
            );
        }

        let checkBox: JSX.Element;
        if (onOffSwitch) {
            checkBox = (
                <div className="dib vam">
                    <div className={`checkbox onoffswitch ${error ? "has-error" : ""}`}>
                        <label>
                            <input className={className}
                                   type="checkbox"
                                   id={name}
                                   {...props}
                            />
                            {label}
                            <span className="onoffswitch-inner"/>
                            <span className="onoffswitch-switch"/>
                        </label>
                    </div>

                </div>
            );
        } else {
            checkBox = (
                <div className="dib vam">
                    <div className={`checkbox ${error ? "has-error" : ""}`}>
                        <label>
                            <input className={className}
                                   type="checkbox"
                                   id={name}
                                   {...props}
                            />
                            {label}
                        </label>
                    </div>
                </div>
            );
        }


        if (error) {
            const tooltip = (<Tooltip className="tooltip-error" id={`tooltip-${name}`}>{error}</Tooltip>);
            return (
                <FormGroup bsClass={groupClassNameError}>
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        {checkBox}
                    </OverlayTrigger>
                    {helpOverlay}
                </FormGroup>
            );
        } else {
            return (
                <FormGroup bsClass={groupClassName}>
                    {checkBox}{helpOverlay}
                </FormGroup>
            );
        }
    }
}

export class DjangoCheckbox extends Checkbox {
    public static toJSON(name: string, values: any): any {
        return {[name]: values[name] || false};
    }

    public static toFormData(name: string, values: any): any {
        let value = values[name];
        if (!Checkbox.isEmpty(value)) {
            return {[name]: "True"};
        }
    }

    public static toFormDataPost(name: string, values: any): any {
        return DjangoCheckbox.toFormData(name, values);
    }

    public static fromJSON(name: string, values: any): any {
        return {[name]: !!values[name]};
    }

    public static fromFormData(name: string, values: any): any {
        return {[name]: values[name] === "True"};
    }

    public static isEmpty(value: boolean): boolean {
        return !value;
    }
}
