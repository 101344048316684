import * as React from "react";
import Table from "../../../../../shared/ts/components/table/Table";
import TableRow from "../../../shared/table/TableRow";
import TableHeader, {TableHeaderProps} from "../../../shared/table/TableHeader";
import CenterBox from "../../../shared/layout/CenterBox";
import Loader from "../../../shared/loader/Loader";
import * as classnames from "classnames";
import * as _ from "lodash";
import {SortDirection} from "../../../../../shared/ts/decorators/sort";
import {IPlanOffer} from "../../actions/fetch_plan_offer_actions";
import PlanOfferListItem from "./PlanOfferListItem";


interface IProps {
    offers: IPlanOffer[];
    onSort: (column: string) => void;
    sortName: string;
    sortDirection: SortDirection | null;
    loading?: boolean;
}

export const PlanOfferList = (props: IProps) => {
    const renderLoader = () => {
        return (
            <CenterBox className="vhc pt-lg">
                <Loader size="lg"/>
            </CenterBox>
        );
    };

    const tbodyClassName = classnames({"o40": !!props.loading});
    const loader = props.loading ? renderLoader() : null;

    const offerListItem = _.map(props.offers, (offer: IPlanOffer, idx: number) => {
        return <PlanOfferListItem key={idx} offer={offer} />;
    });

    // Sortable table columns
    const sortableHeaderProps = (sortName: string): TableHeaderProps => ({
        sortable: true,
        onHeaderClick: props.onSort,
        sortDirection: sortName === props.sortName ? props.sortDirection : null,
        sortName
    });

    return (
        <div className="psr">
            <Table hover className="table mb-0">
                <thead>
                <TableRow>
                    <TableHeader {...sortableHeaderProps("name")}>Nazwa inwestycji</TableHeader>

                    <TableHeader {...sortableHeaderProps("vendor__name")}>Deweloper</TableHeader>

                    <TableHeader>Lokalizacja</TableHeader>
                </TableRow>
                </thead>

                <tbody className={tbodyClassName}>
                    {offerListItem}
                </tbody>
            </Table>

            {loader}
        </div>
    );
};


