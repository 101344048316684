import * as pathToRegexp from "path-to-regexp";
import {nphApiPath} from "./nph_api_path";


interface IIdNumberParams {
    id: number;
}
interface IIdStringParams {
    id: string;
}

export const nphApiLink = {
    users: {
        me: createNoParamLink(nphApiPath.users.me),
        login: createNoParamLink(nphApiPath.users.login),
        logout: createNoParamLink(nphApiPath.users.logout)
    },
    buildings: {
        list: createNoParamLink(nphApiPath.buildings.base),
        detail: createLink<{buildingId: number}>(nphApiPath.buildings.detail)
    },
    campaigns: {
        add: createNoParamLink(nphApiPath.campaigns.base),
        base: createNoParamLink(nphApiPath.campaigns.base),
        delete: createLink<IIdStringParams>(nphApiPath.campaigns.detail.base),
        edit: createLink<IIdStringParams>(nphApiPath.campaigns.detail.base),
        view: createLink<IIdStringParams>(nphApiPath.campaigns.detail.base),
        editValidate: createLink<IIdStringParams>(nphApiPath.campaigns.detail.validate),
        addValidate: createNoParamLink(nphApiPath.campaigns.validate),
        stats: createNoParamLink(nphApiPath.campaigns.stats),
        smsTest: createLink<IIdStringParams>(nphApiPath.campaigns.detail.sendTestSms)
    },
    floors: {
        list: createNoParamLink(nphApiPath.floors.base),
        detail: createLink(nphApiPath.floors.detail)
    },
    natives: {
        add: createNoParamLink(nphApiPath.natives.base),
        base: createNoParamLink(nphApiPath.natives.base),
        delete: createLink<IIdNumberParams>(nphApiPath.natives.detail.base),
        edit: createLink<IIdNumberParams>(nphApiPath.natives.detail.base),
        view: createLink<IIdNumberParams>(nphApiPath.natives.detail.base),
        editValidate: createLink<IIdNumberParams>(nphApiPath.natives.detail.validate)
    },
    surpriseAd: {
        add: createNoParamLink(nphApiPath.surprise.base),
        base: createNoParamLink(nphApiPath.surprise.base),
        delete: createLink<IIdNumberParams>(nphApiPath.surprise.detail.base),
        edit: createLink<IIdNumberParams>(nphApiPath.surprise.detail.base),
        view: createLink<IIdNumberParams>(nphApiPath.surprise.detail.base),
        editValidate: createLink<IIdNumberParams>(nphApiPath.surprise.detail.validate)
    },
    heroAd: {
        add: createNoParamLink(nphApiPath.hero.base),
        base: createNoParamLink(nphApiPath.hero.base),
        delete: createLink<IIdNumberParams>(nphApiPath.hero.detail.base),
        edit: createLink<IIdNumberParams>(nphApiPath.hero.detail.base),
        view: createLink<IIdNumberParams>(nphApiPath.hero.detail.base),
        editValidate: createLink<IIdNumberParams>(nphApiPath.hero.detail.validate)
    },
    tdaAd: {
        add: createNoParamLink(nphApiPath.tda.base),
        base: createNoParamLink(nphApiPath.tda.base),
        delete: createLink<IIdNumberParams>(nphApiPath.tda.detail.base),
        edit: createLink<IIdNumberParams>(nphApiPath.tda.detail.base),
        view: createLink<IIdNumberParams>(nphApiPath.tda.detail.base),
        editValidate: createLink<IIdNumberParams>(nphApiPath.tda.detail.validate)
    },
    variables: createNoParamLink(nphApiPath.variables),
    offers: {
        list: createNoParamLink(nphApiPath.offers.base),
        detail: createLink<IOfferParams>(nphApiPath.offers.detail.base),
        gallery: {
            list: createLink<IOfferParams>(nphApiPath.offers.detail.gallery.base),
            tagBase: createLink<IOfferGalleryParams>(nphApiPath.offers.detail.gallery.detail.tags.base),
            tagDetail: createLink<IOfferGalleryTagParams>(nphApiPath.offers.detail.gallery.detail.tags.detail)
        }
    },
    planPolygons: {
        list: createNoParamLink(nphApiPath.planPolygons.base),
        detail: createLink<{planPolygonId: number}>(nphApiPath.planPolygons.detail)
    },
    properties: {
        list: createNoParamLink(nphApiPath.properties.base),
        detail: createLink<{propertyId: number}>(nphApiPath.properties.detail)
    },
    reels: {
        creator: {
            list: createNoParamLink(nphApiPath.reels.creator.base),
            add: createNoParamLink(nphApiPath.reels.creator.base),
            view: createLink<{creatorId: number}>(nphApiPath.reels.creator.detail),
            delete: createLink<{creatorId: number}>(nphApiPath.reels.creator.detail),
            edit: createLink<{creatorId: number}>(nphApiPath.reels.creator.detail)
        },
        list: createNoParamLink(nphApiPath.reels.reel.base),
        add: createNoParamLink(nphApiPath.reels.reel.base),
        view: createLink<{reelId: number}>(nphApiPath.reels.reel.detail),
        delete: createLink<{reelId: number}>(nphApiPath.reels.reel.detail),
        edit: createLink<{reelId: number}>(nphApiPath.reels.reel.detail)
    },
    regions: createNoParamLink(nphApiPath.regions.base),
    regionsView: createLink<IIdNumberParams>(nphApiPath.regions.detail),
    tags: {
        list: createNoParamLink(nphApiPath.tags.base)
    },
    vendors: {
        list: createNoParamLink(nphApiPath.vendors.base),
        detail: createLink<IVendorParams>(nphApiPath.vendors.detail.base),
        groupList: createLink<IVendorParams>(nphApiPath.vendors.detail.group.base),
        groupDetail: createLink<IVendorGroupParams>(nphApiPath.vendors.detail.group.detail)
    },
    members: createNoParamLink(nphApiPath.members.base),
    membersView: createLink<IIdNumberParams>(nphApiPath.members.detail)

};

interface IOfferParams {
    offerId: number;
}
interface IOfferGalleryParams extends IOfferParams {
    imageId: number;
}
interface IOfferGalleryTagParams extends IOfferGalleryParams {
    tagId: number;
}

interface IVendorParams {
    vendorId: number;
}
interface IVendorGroupParams extends IVendorParams {
    groupId: number;
}

/**
 * Helper
 */
function createNoParamLink(path: string): () => string {
    return () => path;
}

function createLink<TParams extends object>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);
    return (apiPathParams: TParams): string => compiler(apiPathParams);
}
