import * as React from "react";
import {Fields, WrappedFieldProps} from "redux-form";
import * as classnames from "classnames";

import {Store} from "../../../../project/reducer";
import FieldError from "../../FieldError";
import FieldLabel from "../../FieldLabel";
import DatePicker from "./DatePicker";


interface IDatePickerRangeFieldProps extends WrappedFieldProps<Store> {
    name: string;
    names: string[];
    label?: string;
    disabled?: boolean;
    onValueUpdate?: (name: string, value?: any) => void;
    className?: string;
    groupClassName?: string;
    popoverTargetOffset?: string;
    popoverAttachment?: string;
    popoverTargetAttachment?: string;
    minDate?: {};
    maxDate?: {};
    error?: string;
}

class DatePickerRangeField extends React.Component<IDatePickerRangeFieldProps, {}> {

    private renderFields(): JSX.Element {
        const lowerName = this.props.names[0];
        const upperName = this.props.names[1];

        return (
            <div className="row bottom-xs">
                <div className="col-xs-6">
                    <DatePicker
                        name={lowerName}
                        disabled={this.props.disabled}
                        onValueUpdate={this.props.onValueUpdate}
                        className={this.props.className}
                        groupClassName={this.props.groupClassName}
                        popoverTargetOffset={this.props.popoverTargetOffset}
                        popoverAttachment={this.props.popoverAttachment}
                        popoverTargetAttachment={this.props.popoverTargetAttachment}
                    />
                </div>

                <div className="col-xs-6">
                    <DatePicker
                        name={upperName}
                        disabled={this.props.disabled}
                        onValueUpdate={this.props.onValueUpdate}
                        className={this.props.className}
                        groupClassName={this.props.groupClassName}
                        popoverTargetOffset={this.props.popoverTargetOffset}
                        popoverAttachment={this.props.popoverAttachment}
                        popoverTargetAttachment={this.props.popoverTargetAttachment}
                    />
                </div>
            </div>
        );
    }

    public render() {
        const lowerName = this.props.names[0];
        const {error, label} = this.props;
        const holderClassName = classnames(error && "has-error");
        const fields = this.renderFields();

        return (
            <div className={holderClassName}>
                {label && <FieldLabel label={label} htmlFor={lowerName}/>}
                {error ? <FieldError error={error}>{fields}</FieldError> : fields}
            </div>
        );
    }
}

class DatePickerRange extends React.Component<IDatePickerRangeFieldProps, {}> {
    constructor(props: IDatePickerRangeFieldProps) {
        super(props);
    }
    public render() {
        return <Fields
            {...this.props}
            names={[`${this.props.name}__lower`, `${this.props.name}__upper`]}
            component={DatePickerRangeField}/>;
    }
}
export default DatePickerRange;
