import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../interfaces/style";

interface TableProps extends StyleProps {
    striped?: boolean;
    bordered?: boolean;
    condensed?: boolean;
    hover?: boolean;
    responsive?: boolean;
}

const Table: React.SFC<TableProps> = props => {
    const className = classnames("table", props.className, {
            "table-striped": !!props.striped,
            "table-bordered": !!props.bordered,
            "table-condensed": !!props.condensed,
            "table-hover": !!props.hover
        });

    const table = <table className={className}>{props.children}</table>;

    return props.responsive ? <div className="table-responsive">{table}</div> : table;
};

export default Table;
