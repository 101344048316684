import * as React from "react";

import BaseAlert from "./BaseAlert";


interface WarningAlertProps {
    onCloseClick: () => void;
}


const WarningAlert: React.SFC<WarningAlertProps> = props => {
    return (
        <BaseAlert color="warning" icon="warning-rounded" onCloseClick={props.onCloseClick}>{props.children}</BaseAlert>
    );
};

export default WarningAlert;
