import * as React from "react";
import {useRef} from "react";
import {useDrag, useDrop, DropTargetMonitor, DragSourceMonitor} from "react-dnd";
import {OfferGalleryImageListItemImg} from "./OfferGalleryImageListItemImg";
import {draggableItemTypes} from "./draggable_item_types";
import Icon from "../../shared/icon/Icon";
import {Button} from "../../shared/button/Button";


const PIXEL_THRESHOLD = 20;

interface IDragItem {
    pk: number;
    type: string;
}

interface IProps {
    imagePk: number;
    imageSrc: string;
    imageIndexMap: Record<number, number>;
    onDragImage: (dragIndex: number, hoverIndex: number) => void;
    onDropImage: () => void;
    onTagButtonClick: (imagePk: number) => void;
}

export const OfferGalleryListItem = (props: IProps) => {

    /**
     * Drag
     */
    const initialDragItemIndex = useRef<number | null>(null);
    const [{isDragging}, drag] = useDrag({
        item: {type: draggableItemTypes.GALLERY_IMAGE, pk: props.imagePk},
        begin: () => {
            initialDragItemIndex.current = props.imageIndexMap[props.imagePk];
        },
        end: () => {
            initialDragItemIndex.current = null;
        },
        collect: (monitor: DragSourceMonitor) => {
            return {
                isDragging: monitor.isDragging()
            };
        }
    });

    /**
     * Drop
     */
    const [, drop] = useDrop({
        accept: draggableItemTypes.GALLERY_IMAGE,
        hover(dragItem: IDragItem, monitor: DropTargetMonitor) {

            if (!ref.current) {
                return;
            }

            const dragIndex = props.imageIndexMap[dragItem.pk];
            const hoverIndex = props.imageIndexMap[props.imagePk];
            if (dragIndex === hoverIndex) {
                return;
            }

            const pointerOffset = monitor.getClientOffset();
            if (pointerOffset == null) {
                return;
            }

            const hoverRect = ref.current.getBoundingClientRect();
            // we drag right or down
            if (dragIndex < hoverIndex && (pointerOffset.x - hoverRect.left < PIXEL_THRESHOLD || pointerOffset.y - hoverRect.top < PIXEL_THRESHOLD)) {
                return;
            }
            // we drag left or up
            if (hoverIndex < dragIndex && (hoverRect.right - pointerOffset.x < PIXEL_THRESHOLD || hoverRect.bottom - pointerOffset.y < PIXEL_THRESHOLD)) {
                return;
            }

            props.onDragImage(dragIndex, hoverIndex);
        },
        drop(dragItem, monitor) {
            const currentDragItemIndex = props.imageIndexMap[dragItem.pk];
            if (initialDragItemIndex.current === currentDragItemIndex) {
                return;
            }
            props.onDropImage();
        }
    });

    /**
     * Attach
     */
    const ref = useRef<HTMLDivElement>(null);
    drag(ref);
    drop(ref);

    /**
     * Render
     */
    return (
        <div ref={ref}
             className="p-md psr"
             style={{
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: isDragging ? "#ff7253" : "#fff"
             }}
        >
            <Button onClick={() => props.onTagButtonClick(props.imagePk)} className="psa b20 r30 bgc-black-o70 c-white-h fs11 gallery-tags-img-btn">
                <Icon icon="photo" className="pr-md"/>
                Dodaj tagi
            </Button>

            <OfferGalleryImageListItemImg img={props.imageSrc}/>
        </div>
    );
};
