import * as React from "react";
import {Modal} from "react-bootstrap"

import {Button} from "../../shared/button/Button";

interface IProps {
    isModalOpen: boolean;
    handleClose: () => void;
    message: string;
    onOk?: () => void;
    onCancel?: () => void;
}

class ReelDialog extends React.Component<IProps> {
    public render() {
        return (
            <Modal show={this.props.isModalOpen} onHide={this.props.handleClose}>
                <Modal.Body className="tac">
                    <h4>{this.props.message}</h4>
                </Modal.Body>
                <Modal.Footer className="tac bd-0 pt-0i">
                    {this.props.onCancel ? (
                        <Button color="default" size="sm" className="ttu ph-xxl" onClick={this.props.onCancel}>
                            Anuluj
                        </Button>
                    ) : null}
                    {this.props.onOk ? (
                        <Button color="primary-ghost" size="sm" className="ttu ph-xxl" onClick={this.props.onOk}>
                            Potwierdź
                        </Button>
                    ) : null}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ReelDialog;
