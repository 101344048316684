import * as React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {RouterProps} from "../../../../../shared/ts/interfaces/router";
import {url} from "../../../../../shared/ts/helpers/routing";
import {CreationType} from "../../CreationType";
import {PaginationState} from "../../../../../shared/ts/helpers/pagination";
import {AppError, RequestState} from "../../../../../shared/ts/helpers/util";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {fetch, HocFetch} from "../../../../../shared/ts/decorators/fetch";
import {HocPagination, pagination} from "../../../../../shared/ts/decorators/pagination";
import {HocSort, sort} from "../../../../../shared/ts/decorators/sort";
import {PAGE_PARAM, SORT_PARAM} from "../../../../../shared/ts/constants/queryParams";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {Dictionary} from "../../../../../shared/ts/interfaces/structure";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {IHeroAd, IHeroFilterForm} from "../../../advertisments/reducers/hero";
import {heroFetch, invalidateHeroAdvertsList} from "../../../advertisments/actions/hero/fetch";
import {heroDelete} from "../../../advertisments/actions/hero/delete";
import {filterHeroListFormActions} from "../../../advertisments/actions/hero/filter_hero_list";
import {nativesDelete} from "../../../advertisments/actions/natives/delete";
import ContentBody from "../../../project/components/content/ContentBody";
import CenterBox from "../../../shared/layout/CenterBox";
import Icon from "../../../shared/icon/Icon";
import Loader from "../../../shared/loader/Loader";
import * as _ from "lodash";
import Main from "../../../shared/layout/Main";
import {Panel, PanelBody} from "../../../shared/panel/Panel";
import {Pagination} from "../../../shared/pagination/Pagination";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import {Button} from "../../../shared/button/Button";
import ContextPanel from "../../../context_panel/components/ContextPanel";
import {NativeListContextPanelHeader} from "../../../advertisments/components/context_panel/NativeListContextPanelHeader";
import ContextPanelBody from "../../../context_panel/components/ContextPanelBody";
import {TabsContent} from "../../../../../shared/ts/components/tabs/TabsContent";
import {HeroListFilterForm} from "../../../advertisments/components/context_panel/hero/HeroListFilterForm";
import {campaignListContextPanelActions} from "../../../context_panel/reducers/context_panel";
import {HeroList} from "../../../advertisments/components/HeroList";

const defaultPage = 1;
const defaultSort = "-create_date";

interface IStateProps {
    natives: IHeroAd[];
    pagination: PaginationState;
    request: RequestState;
    errors: AppError | null;
    contextPanelOpened: boolean;
    openedTabIndex: number;
    listFilterForm: IHeroFilterForm;
}

interface IActionsProps {
    listFilterFormActions: FormActions;
    actions: {
        invalidateAdvertsList: typeof invalidateHeroAdvertsList;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
        nativesDelete: typeof nativesDelete;
    };
}

interface IOwnProps {
    hoc: HocFetch & HocPagination & HocSort;
    onItemClick: (id: number) => void;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {
    additionalQuery: Record<string, string>;
    addNewItemUrl: string;
}


@pagination(PAGE_PARAM)
@sort(SORT_PARAM)
@fetch((state: Store) => state.adverts.hero.fetch, fetchActions, validateQuery)
export class HeroListViewC extends React.Component<IProps, {}> {

    /**
     * Lifecycle
     */
    public componentWillUnmount() {
        this.props.actions.invalidateAdvertsList();
    }

    /**
     * Callback
     */
    private onFilterSubmit = (values: Dictionary<any>) => {
        this.props.hoc.fetch.reset(values);
    };

    private onFilterClear = () => {
        this.props.listFilterFormActions.clear();
        this.props.hoc.fetch.reset({});
    };

    private onSortChange = (column: string): void => {
        this.props.hoc.sort.toggle(column, this.props.hoc.pagination.get());
    };

    private onContextPanelTabOpen = (tabId: number): void => {
        this.props.actions.openTab(campaignListContextPanelActions.openTab, tabId);
        this.props.actions.openPanel(campaignListContextPanelActions.openPanel);
    };

    private onContextPanelToggle = (): void => {
        if (this.props.contextPanelOpened) {
            this.props.actions.closePanel(campaignListContextPanelActions.closePanel);
        } else {
            this.props.actions.openPanel(campaignListContextPanelActions.openPanel);
        }
    };

    /**
     * Render
     */
    private renderEmptyList(): JSX.Element {
        return (
            <ContentBody className="df fg-1">
                <CenterBox>
                    <Icon icon="empty-list" className="empty-list-icon" />
                    <div className="tac fs30">Nic tu nie ma, zmień parametry szukania</div>
                </CenterBox>
            </ContentBody>
        );
    }

    private renderLoader(): JSX.Element {
        return (
            <CenterBox className="vhc">
                <Loader size="lg" />
            </CenterBox>
        );
    }

    private renderError(): JSX.Element {
        return (
            <CenterBox>
                <Icon icon="empty-list" className="empty-list-icon" />
                <div className="error-message tac fs30">Popraw błędne parametry szukania</div>
            </CenterBox>
        );
    }

    private renderList(loading: boolean): JSX.Element {
        const {hoc, natives, pagination} = this.props;
        return (
            <ContentBody>
                <Panel color="default" className="psr mb-xl">
                    <PanelBody>
                        <HeroList
                            listItems={natives}
                            onSort={this.onSortChange}
                            sortDirection={hoc.sort.getDirection()}
                            sortName={hoc.sort.getName()}
                            loading={loading}
                            onDeleteClick={this.props.actions.nativesDelete}
                            onItemClick={this.props.onItemClick}
                        />
                    </PanelBody>
                </Panel>

                <div className="pagination-holder tac pss b-0">
                    <Pagination
                        totalCount={pagination.total}
                        pageSize={pagination.pageSize}
                        activePage={hoc.pagination.currentPage()}
                        onPageChange={hoc.pagination.set}
                    />
                </div>
            </ContentBody>
        );
    }

    private renderContent(): JSX.Element {
        const {request, natives} = this.props;

        if (request === RequestState.Success && _.isEmpty(natives)) {
            return this.renderEmptyList();
        }

        if (request === RequestState.Success || (request === RequestState.Waiting && !_.isEmpty(natives))) {
            return this.renderList(request === RequestState.Waiting);
        }

        if (request === RequestState.Error) {
            return this.renderError();
        }

        return this.renderLoader();
    }

    public render(): JSX.Element {
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Lista reklam hero</ContentHeaderTitle>
                        <ContentHeaderControls>
                            <Button color="success" className="ttu" href={url(this.props.addNewItemUrl)} size="sm">
                                Dodaj reklamę
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>
                    {this.renderContent()}
                </Content>

                <ContextPanel opened={this.props.contextPanelOpened}>
                    <NativeListContextPanelHeader
                        opened={this.props.contextPanelOpened}
                        openedTabIndex={this.props.openedTabIndex}
                        onTabOpen={this.onContextPanelTabOpen}
                        onPanelToggle={this.onContextPanelToggle}
                    />

                    <ContextPanelBody className="filters-holder">
                        <TabsContent openedTabIndex={this.props.openedTabIndex}>
                            <HeroListFilterForm
                                onSubmit={this.onFilterSubmit}
                                errors={this.props.errors ? this.props.errors.fieldErrors : null}
                                values={this.props.listFilterForm}
                                updateFormAction={this.props.listFilterFormActions.update}
                                clearForm={this.onFilterClear}
                            />
                        </TabsContent>
                    </ContextPanelBody>
                </ContextPanel>
            </Main>
        );
    }
}

const ConnectedHeroListView = connect(mapStateToProps, mapActionsToProps)(HeroListViewC)

export const HeroAdCreationListView = withRouter(
    (props: RouterProps) => (
        <ConnectedHeroListView
            additionalQuery={{type: CreationType.HERO.toString()}}
            onItemClick={(id: number) => props.router.push(url("app:in:creation:hero:edit", {id}))}
            addNewItemUrl="app:in:creation:hero:add"
        />
    )
);

function mapStateToProps(store: Store): IStateProps {
    return {
        natives: store.adverts.hero.natives,
        pagination: store.adverts.hero.pagination,
        request: store.adverts.hero.fetch.requestState,
        errors: store.adverts.hero.fetch.errors,
        contextPanelOpened: store.adverts.hero.list.contextPanel.opened,
        openedTabIndex: store.adverts.hero.list.contextPanel.openedTabIndex,
        listFilterForm: store.adverts.hero.list.filterForm
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        listFilterFormActions: bindActionCreators(filterHeroListFormActions, dispatch),
        actions: bindActionCreators({
            invalidateAdvertsList: invalidateHeroAdvertsList,
            openPanel,
            closePanel,
            openTab,
            nativesDelete: heroDelete
        }, dispatch)
    };
}

function fetchActions(dispatch: Dispatch, query: Dictionary<string>): void {
    dispatch(heroFetch(query));
}

function validateQuery(query: Dictionary<string>, props: IProps): Dictionary<string> {
    return {
        [PAGE_PARAM]: query[PAGE_PARAM] || defaultPage.toString(),
        [SORT_PARAM]: query[SORT_PARAM] || defaultSort,
        ...props.additionalQuery
    };
}