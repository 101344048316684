import * as _ from "lodash";
import {guid} from "./utils";
import {
    Response400,
    Response401,
    Response403,
    Response404,
    Response504,
    Response5xx,
    ResponseUnknownStatus,
    ResponseStalledError,
    ResponseUnknownError
} from "./errors";


/**
 * Zwraca sparsowany tekst w postaci poprawnego JSONa.
 */
export function safeJsonParse(text: string): Object {
    let json = {};
    try {
        json = JSON.parse(text);
    }
    catch (e) {
        console.warn("parse Error: ", e);
    }
    return json;
}

const actionGuidObj: {[s: string]: string} = {};

// Zapisuje i zwraca guid dla danego typu akcji
function createActionGuid(actionType: string | null): string | null {
    if (actionType !== null) {
        const actionGuid = guid();
        actionGuidObj[actionType] = actionGuid;
        return actionGuid;
    }
    return null;
}

// Sprawdza czy actionGuid jest ostatnim dla danego typu akcji (actionType)
function isResponseValid(actionType: string | null, actionGuid: string | null): boolean {
    return actionType === null || actionGuid === actionGuidObj[actionType];
}

const defaultOptions: RequestOptions = {
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
    // stringifyJson: true
};

interface RequestOptions {
    headers?: {[s: string]: string};
    addDefaultHeaders?: boolean;
    data?: any;
}

export function request(method: string, url: string, requestId: string | null = null, options: RequestOptions = {}): Promise<any> {
    const actionGuid = createActionGuid(requestId);

    let fetchOptions: RequestInit = _.merge({}, {
        method: method,
        headers: _.assign({}, options.addDefaultHeaders !== false && defaultOptions.headers, options.headers) as any
    });

    if (options.data) { // dodajemy dane do zapytania HTTP
        // brzydkie, przepisac
        fetchOptions.body = options.data instanceof FormData ? (options.data as any) : JSON.stringify(options.data);
    }

    return fetch(url, fetchOptions)
        .then((res: Response) => {
            return res.text().then((body: string) => {
                const status = res.status;

                if (!isResponseValid(requestId, actionGuid)) {
                    throw new ResponseStalledError(body, url);
                }

                if (status === 204) { // poprawna odpowiedź - puste body
                    return null;
                }

                if (status >= 200 && status < 300) { // poprawna odpowiedź - parsujemy body
                    return safeJsonParse(body);
                }

                if (status === 400) {
                    throw new Response400(body, url);
                }

                if (status === 403) {
                    throw new Response403(body, url);
                }

                if (status === 401) { // brak autoryzacji
                    throw new Response401(body, url);
                }

                if (status === 404 || status === 410) { // brak zasobu
                    throw new Response404(body, url);
                }

                if (status >= 500) { // pokazujemy alert tylko na 5xx
                    if (status === 504) {
                        throw new Response504(body, url);
                    } else {
                        throw new Response5xx(body, url);
                    }
                }

                throw new ResponseUnknownStatus(body, url);
            });
    }, (err: any) => {
        throw new ResponseUnknownError(err, url);
    });
}

export function requestBlob(method: string, url: string, requestId: string | null = null, options: RequestOptions = {}): Promise<any> {
    const actionGuid = createActionGuid(requestId);

    let fetchOptions: RequestInit = _.merge({}, {
        method: method,
        headers: _.assign({}, defaultOptions.headers, options.headers) as any
    });

    return fetch(url, fetchOptions)
        .then((res: Response) => {
            return res.blob().then((file: any) => {
                const status = res.status;

                if (!isResponseValid(requestId, actionGuid)) {
                    throw new ResponseStalledError(file, url);
                }

                if (status === 204) { // poprawna odpowiedź - puste body
                    return null;
                }

                if (status >= 200 && status < 300) { // poprawna odpowiedź - zwracamy plik
                    return file;
                }

                throw new ResponseUnknownStatus(file, url);
            });
    }, (err: any) => {
        throw new ResponseUnknownError(err, url);
    });
}
