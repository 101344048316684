import {combineReducers} from "redux";

import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";

import {campaignsRequest} from "../actions/fetch";
import {Campaign} from "../models/Campaign";
import {campaignAddRequest, CLEAR_ADD_VIEW_STATE} from "../actions/add";
import {
    campaignListContextPanel, campaignAddContextPanel,
    campaignEditContextPanel, ContextPanelState
} from "../../context_panel/reducers/context_panel";
import {campaignFormNotifications, CampaignFormNotificationsState} from "./notifications";
import {
    campaignEditFetchTypes, campaignEditPatchTypes, CLEAR_EDIT_VIEW_STATE,
    UPDATE_CAMPAIGN_PATCH_TIMESTAMP
} from "../actions/edit";
import {reduceResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_response";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {CampaignSummaryState, campaignSummary} from "./summary";
import {campaignTestSmsRequest} from "../actions/summary";


export interface SmsStore {
    campaigns: Campaign[];
    pagination: PaginationState;
    fetch: {
        requestState: RequestState;
        errors: AppError | null;
        latestQuery: Dictionary<string>;
    };
    campaignNotifications: CampaignFormNotificationsState,
    campaignSummary: CampaignSummaryState,
    list: {
        contextPanel: ContextPanelState;
    },
    add: {
        requestState: RequestState;
        errors: AppError | null;
        contextPanel: ContextPanelState;
    };
    edit: {
        fetchRequest: RequestState;
        patchRequest: RequestState;
        patchErrors: AppError | null;
        patchTimestamp: number,
        campaign: Campaign | null;
        contextPanel: ContextPanelState;
    };
    testSms: {
        request: RequestState;
        errors: AppError | null;
    };
}

// TODO: move `clearAddView` and `clearEditView` to different files and handle ANY types
const clearAddView = (store: any, action: Action): any => {
    switch (action.type) {
        case CLEAR_ADD_VIEW_STATE:
            return {
                ...store,
                requestState: RequestState.None,
                errors: null
            };
        default:
            return store;
    }
};

const clearEditView = (store: any, action: Action): any => {
    switch (action.type) {
        case CLEAR_EDIT_VIEW_STATE:
            return {};
        default:
            return store;
    }
};


const patchTimestamp = (store: number = 0, action: Action & {timestamp: number}): number => {
    switch (action.type) {
        case UPDATE_CAMPAIGN_PATCH_TIMESTAMP:
            return action.timestamp;
        default:
            return store;
    }
};


const sms = combineReducers({
    campaigns: reducePaginatedResponse(campaignsRequest),
    pagination: reducePagination(campaignsRequest),
    fetch: combineReducers({
        requestState: reduceRequestState(campaignsRequest),
        errors: reduceRequestErrors(campaignsRequest),
        latestQuery: reduceLatestQuery(campaignsRequest)
    }),
    campaignNotifications: campaignFormNotifications,
    campaignSummary: campaignSummary,
    list: combineReducers({
       contextPanel: campaignListContextPanel
    }),
    add: reduceReducers(
        combineReducers({
            requestState: reduceRequestState(campaignAddRequest),
            errors: reduceRequestErrors(campaignAddRequest),
            contextPanel: campaignAddContextPanel
        }),
        clearAddView
    ),
    edit: reduceReducers(
        combineReducers({
            fetchRequest: reduceRequestState(campaignEditFetchTypes),
            patchRequest: reduceRequestState(campaignEditPatchTypes),
            patchErrors: reduceRequestErrors(campaignEditPatchTypes),
            campaign: reduceReducers(
                reduceResponse<Campaign>(campaignEditFetchTypes),
                reduceResponse<Campaign>(campaignEditPatchTypes)
            ),
            contextPanel: campaignEditContextPanel,
            patchTimestamp
        }),
        clearEditView
    ),
    testSms: combineReducers({
        request: reduceRequestState(campaignTestSmsRequest),
        errors: reduceRequestErrors(campaignTestSmsRequest)
    })
});

export default sms;
