import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";


export enum DateFormatType {
    shortDate,
    longDate,
    shortDateTime,
    shortDateNoYear,
    longDateTime,
    time
}

interface DateFormatProps {
    auto?: boolean;
    relative?: boolean;
    date?: string | null;
    relativeBreakpoint?: number;
    format: DateFormatType | string;
    locale?: string;
    withoutSuffix?: boolean;
}

interface DateFormatState {
    format: string;
}

/**
 * Komponent renderujący datę w wybranym formacie oraz typie.
 *
 * Daty mogą być wyświetlone jako relatywne lub standardowe.
 * Standardowa: 14 marca 2015, 13:15.
 * Relatywna: 1 godzinę temu.
 *
 * Format daty można konfigurować. Domyślne formaty są ukazane powyżej.
 * Więcej informacji: http://formatjs.io/react/#formatted-date.
 *
 * Datę należy przekazywać jako properties date, relative lub auto.
 * date: Wyświetlona będzie data standardowa.
 * relative: Wyświetlona będzie data relatywna.
 * auto: Jeżeli różnica pomiędzy obecnym czasem a przekazanym jest mniejsza niż relativeBreakpoint (domyślnie 6 godzin),
 *  wyświetlona jest data relatywna, w innym przypadku wyświetlona jest data standardowa.
 */
export class DateFormat extends React.Component<DateFormatProps, DateFormatState> {

    // Domyślny format daty
    public static defaultProps: DateFormatProps = {
        locale: "pl",
        format: DateFormatType.longDate,
        relativeBreakpoint: 6 * 60 * 60, // 6 godzin,
        date: null,
        relative: false,
        auto: false,
        withoutSuffix: false
    };

    public componentWillMount(): void {
        this.setFormat(this.props.format);
    }

    public componentWillReceiveProps(newProps: DateFormatProps): void {
        this.setFormat(newProps.format);
    }

    private setFormat(format: DateFormatType | string): void {
        let finalFormat = "DD MMMM YYYY";
        if (_.isString(format)) {
            finalFormat = format;
        } else {
            switch (format as DateFormatType) {
                case DateFormatType.longDate:
                    finalFormat = "D MMMM YYYY";
                    break;
                case DateFormatType.shortDate:
                    finalFormat = "DD.MM.YYYY";
                    break;
                case DateFormatType.shortDateNoYear:
                    finalFormat = "DD.MM";
                    break;
                case DateFormatType.longDateTime:
                    finalFormat = "D MMMM YYYY, HH:mm";
                    break;
                case DateFormatType.shortDateTime:
                    finalFormat = "DD.MM.YYYY HH:mm";
                    break;
                case DateFormatType.time:
                    finalFormat = "HH:mm";
                    break;
                default:
                    break;
            }
        }

        this.setState({
            format: finalFormat
        });
    }

    public render(): JSX.Element {
        const {date, relative, auto, relativeBreakpoint, locale} = this.props;

        // Change moment's locale
        moment.locale(locale);

        if (!date) {
            console.warn("Empty date");
            return <span />;
        }

        let momentObj = moment(date);
        if (auto || relative) {
            momentObj = moment(date) > moment() ? moment() : moment(date);
        }

        if (auto) {
            if (moment().unix() - momentObj.unix() > relativeBreakpoint!) {
                return <span>{momentObj.format(this.state.format)}</span>;
            } else {
                return <span>{momentObj.fromNow(this.props.withoutSuffix)}</span>;
            }
        } else if (relative) {
            return <span>{momentObj.fromNow(this.props.withoutSuffix)}</span>;
        } else {
            return <span>{momentObj.format(this.state.format)}</span>;
        }
    }
}
