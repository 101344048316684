import * as React from "react";
import * as qs from "qs";

import {CreationType} from "../../CreationType";
import {creationPreviewLinks} from "../../creation_preview_path";
import {IHeroFormValues} from "../../../advertisments/reducers/hero";

interface IProps {
    heroAdCreationData: IHeroFormValues;
}

interface IHeroAdCreationData {
    id: number;
    url: string;
    desktop_image: {
        img_1536x295: string;
    } | null;
    mobile_image: {
        img_375x484: string;
    } | null;
    type: CreationType.HERO;
}

export function HeroCreationAdPreview(props: IProps) {
    const data: IHeroAdCreationData = {
        ...props.heroAdCreationData,
        id: 123,
        url: "#",
        type: CreationType.HERO,
        desktop_image: {
            img_1536x295: props.heroAdCreationData.desktop_image.stored
        },
        mobile_image: {
            img_375x484: props.heroAdCreationData.mobile_image.stored
        }
    }

    const query = qs.stringify(data)
    const previewUrl = `${creationPreviewLinks.heroAdCreation()}?${query}`;

    return <iframe frameBorder={0} src={previewUrl} width="100%" height="600px"></iframe>;
}
