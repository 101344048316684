import {Dispatch} from "redux";

import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {nphApiLink} from "../../project/route/nph_api_link";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";

/**
 * Fetch available tags list
 */

const FETCH_TAGS_LIST = "offer/tags-offers/FETCH_TAGS_LIST";
export const fetchAvailableTagsListTypes = createRequestActionTypes(FETCH_TAGS_LIST);

export interface IOfferTagItem {
    id: number;
    category: number;
    name: string;
    is_active: boolean;
    region: null;
}
export const fetchAvailableTagsList = (region: number) => (dispatch: Dispatch) => {
    dispatch({type: fetchAvailableTagsListTypes.start});
    const url = appendQueryString(nphApiLink.tags.list(), {region, page_size: 1000});
    return getRequest(url)
        .then((res: { results: IOfferTagItem[] }) => {
            dispatch({
                type: fetchAvailableTagsListTypes.success,
                result: res.results
            });
            return res.results;
        })
        .catch(catch404(err => {
            dispatch({type: fetchAvailableTagsListTypes.error});
            return null;
        }));
};

export const resetTagsOfferList = () => ({type: fetchAvailableTagsListTypes.reset});
