import * as React from "react";
import {connect} from "react-redux";
import * as hoistStatics from "hoist-non-react-statics";

import {Loader} from "../components/loader";
import {Dispatch} from "../interfaces/dispatch";
import {reduceAsync} from "../helpers/reduce_async";


const DEBUG = false;

const debugLog = (...args: any[]) => DEBUG ? console.log("MIDDLEWARE debug: ", ...args) : null;

/**
 * Helper
 */

export interface Middleware {
    (dispatch?: Dispatch, getState?: () => any): Promise<any>;
}

/**
 * Decorator
 */

interface MiddlewareComponentProps {
    dispatch: Dispatch;
}
interface MiddlewareComponentState {
    renderContent: boolean;
}

export const middleware = (...middlewares: Middleware[]) => (InnerComponent: any) => {

    @connect()
    class MiddlewareComponent extends React.Component<MiddlewareComponentProps, MiddlewareComponentState> {

        constructor(props: MiddlewareComponentProps) {
            super(props);
            this.state = { renderContent: false };
        }

        /**
         * Lifecycle
         */

        public componentDidMount(): void {
            debugLog("start, number: ", middlewares.length);
            reduceAsync<Middleware, boolean>(middlewares, (acc: boolean, m: Middleware, idx: number) => {
                    if (acc === false) { // do not call next middleware action
                            debugLog("stopped at: ", idx);
                            return Promise.resolve(acc);
                        }
                        debugLog("call middleware, idx: ", idx);
                        return this.props.dispatch!(m).then((result: any) => !!result); // return boolean so next iteration decides on continuation
                }, true)
                .then((acc: boolean) => { // after all middlewares
                    if (acc === true) {
                        debugLog("completed, number: ", middlewares.length);
                        this.setState({ renderContent: true });
                    }
                    return acc;
                });
        }

        /**
         * Render
         */

        public render(): JSX.Element {
            if (this.state.renderContent) {
                return <InnerComponent {...this.props} />;
            }
            return <div className="loader-holder"><Loader /></div>;
        }
    }

    return hoistStatics(MiddlewareComponent, InnerComponent);
};
