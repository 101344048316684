import * as React from "react";
import * as classnames from "classnames";
import FieldLabel from "../../FieldLabel";
import FieldError from "../../FieldError";
import {IFormFieldProps} from "../../create_form";


export type InputSize = "sm" | "lg";

interface IProps extends IFormFieldProps<string> {
    type?: string;
    label?: string;
    placeholder?: string;
    size?: InputSize;
    groupClassName?: string;
    className?: string;
    styleName?: string;
    autoFocus?: boolean;
    maxLength?: number;
    disabled?: boolean;
}

interface IState {
    latestUpdateValue: string;
}

export class FormInput extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {latestUpdateValue: this.props.value};
    }

    protected onFocus = (e: React.FocusEvent) => {
        this.setState({latestUpdateValue: this.props.value});
    };

    private onBlur = (e: React.FocusEvent) => {
        if (this.state.latestUpdateValue !== this.props.value) {
            // call only when value has changed
            this.props.onAfterChange(this.props.name, this.props.value);
        }
    };

    private onChange = (e: any) => {
        let value = e.target.files ? e.target.files[0] : (e.target as HTMLInputElement).value;
        this.props.onChange(this.props.name, value);
    };

    public render(): JSX.Element {
        const {error, label, type, placeholder, className, size, groupClassName, maxLength} = this.props;
        const holderClassName = classnames("form-group", groupClassName, error && "has-error");
        const fieldClassName = classnames("form-control", className, {
            [`input-${size}`]: !!size
        });

        // Field
        const field = (
            <input
                name={this.props.name}
                id={this.props.name}
                value={this.props.value || ""}
                placeholder={placeholder}
                type={type}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
                className={`${fieldClassName} form-control`}
                autoFocus={this.props.autoFocus}
                maxLength={maxLength}
                disabled={this.props.disabled}
            />
        );

        return (
            <div className={holderClassName}>
                {label && <FieldLabel label={label} htmlFor={this.props.name}/>}
                {error ? <FieldError error={error.join("\n")}>{field}</FieldError> : field}
            </div>
        );
    }
}
