import {combineReducers} from "redux";

import alerts, {AlertsStore} from "../shared/alerts/reducer";
import sms, {SmsStore} from "../sms/reducers/campaigns";
import navPanel, {NavPanelStore} from "../nav_panel/reducers/nav_panel";

import {reduceRequestState} from "../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {fetchAccountInfoTypes, MyAccountResponse} from "./actions/fetch_account_info";
import {reduceResponse} from "../../../shared/ts/helpers/reduce_factory/reduce_response";
import {RequestState} from "../../../shared/ts/helpers/util";
import {ErrorPageStore, errorPage} from "../../../shared/ts/reducers/error_page";
import {IOfferStore, offerReducer} from "../offer/reducers/offer_reducer";
import {IOfferPlansStore, offerPlansReducer} from "../offer_plans/reducers/offer_plans_reducer";
import {ITagsOfferStore, tagsOfferReducer} from "../offer_tags/reducers/tags_offer_reducer";
import adverts, {IAdvertsStore} from "../advertisments/reducers";
import video, {IVideoStore} from "../video/reducers";


export interface Store {
    alerts: AlertsStore;
    adverts: IAdvertsStore;
    sms: SmsStore;
    navPanel: NavPanelStore;
    offer: IOfferStore;
    plan: IOfferPlansStore;
    tags: ITagsOfferStore;
    accountInfo: {
        request: RequestState;
        data: MyAccountResponse;
    };
    errorPage: ErrorPageStore;
    form: any;
    video: IVideoStore;
}

// TODO: move `accountInfo` into separate file
const reducer = {
    alerts,
    sms,
    adverts,
    navPanel,
    offer: offerReducer,
    plan: offerPlansReducer,
    tags: tagsOfferReducer,
    accountInfo: combineReducers({
        request: reduceRequestState(fetchAccountInfoTypes),
        data: reduceResponse<MyAccountResponse>(fetchAccountInfoTypes)
    }),
    errorPage,
    video
};

export default reducer;
