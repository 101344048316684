import {assign} from "../../../../shared/ts/helpers/util";
import {Action} from "../../../../shared/ts/interfaces/dispatch";


interface ContextPanelAction extends Action {
    tabIndex: number;
}

export interface ContextPanelState {
    opened: boolean;
    openedTabIndex: number;
}

const contextPanelInitialState: ContextPanelState = {
    opened: true,
    openedTabIndex: 1
};

export interface ContextPanelActionTypes {
    openTab: string;
    openPanel: string;
    closePanel: string;
}

export const createContextPanelActionTypes = (name: string) => ({
    openTab: `${name}/contextPanel/OPEN_TAB`,
    openPanel: `${name}/contextPanel/OPEN_PANEL`,
    closePanel: `${name}/contextPanel/CLOSE_PANEL`
});


export const reduceContextPanel = (actionTypes: ContextPanelActionTypes) =>
    (state: ContextPanelState = contextPanelInitialState, action: ContextPanelAction): ContextPanelState => {

        switch (action.type) {
            case actionTypes.openTab:
                return assign(state, { openedTabIndex: action.tabIndex });
            case actionTypes.openPanel:
                return assign(state, { opened: true });
            case actionTypes.closePanel:
                return assign(state, { opened: false });
            default:
                return state;
        }
    };


export const campaignEditContextPanelActions = createContextPanelActionTypes("campaign/edit");
export const campaignEditContextPanel = reduceContextPanel(campaignEditContextPanelActions);

export const campaignAddContextPanelActions = createContextPanelActionTypes("campaign/add");
export const campaignAddContextPanel = reduceContextPanel(campaignAddContextPanelActions);

export const campaignListContextPanelActions = createContextPanelActionTypes("campaign/list");
export const campaignListContextPanel = reduceContextPanel(campaignListContextPanelActions);

export const planOfferListContextPanelActions = createContextPanelActionTypes("plan_offer/list");
export const planOfferListContextPanel = reduceContextPanel(planOfferListContextPanelActions);

export const tagsOfferListContextPanelActions = createContextPanelActionTypes("tags_offer/list");
export const tagsOfferListContextPanel = reduceContextPanel(tagsOfferListContextPanelActions);
