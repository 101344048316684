import * as React from "react";
import * as classNames from "classnames";
import {OverlayTrigger, Popover} from "react-bootstrap";

import {Icon} from "./icon";
import {StatelessProps} from "../helpers/interfaces";


interface HelpOverlayProps extends StatelessProps {
    id?: string;
    placement?: string;
    title?: string;
    content?: string | JSX.Element;
    iconSize?: string;
    lightIcon?: boolean;
    badge?: boolean;
    badgeColor?: string; // badge color class
    popoverClassName?: string;
}

export const HelpOverlay: React.SFC<HelpOverlayProps> = props => {
    const className = classNames("dib", props.className);

    // Default content of component
    let content = <Icon icon="info" circle={props.iconSize} light={props.lightIcon}/>;
    if (props.badge) {
        const badgeColor = props.badgeColor ? props.badgeColor : "bgc-primary";
        content = <span className={`badge fwn curhe ${badgeColor}`}>{props.content || "Co to jest?"}</span>;
    }

    // Content of popover
    const popover = (
        <Popover id={props.id} title={props.title} className={props.popoverClassName}>
            {props.children}
        </Popover>
    );

    return (
        <OverlayTrigger placement={props.placement} overlay={popover}>
            <div className={className}>{content}</div>
        </OverlayTrigger>
    );
};

HelpOverlay.defaultProps = {
    placement: "bottom",
    iconSize: "md",
    title: "Pomoc"
};
