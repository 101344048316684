import {combineReducers} from "redux";

import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";

import {nativesRequest} from "../actions/natives/fetch";
import {
    campaignListContextPanel, ContextPanelState
} from "../../context_panel/reducers/context_panel";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {DesktopNativesForm} from "../components/DesktopNativesForm";
import {
    addDesktopNativesFormTypes,
    addMobileNativesFormTypes, CLEAR_ADD_EDIT_ERRORS, CLEAR_ADD_EDIT_VIEW_STATE,
    nativesRequestTypes
} from "../actions/natives/add";
import {MobileNativesForm} from "../components/MobileNativesForm";
import {Offer} from "../../shared/models/Offer";
import {NativeListFilterForm} from "../components/context_panel/NativeListFilterForm";
import {filterNativeListFormTypes} from "../actions/natives/filter_natives_list";
import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {nativesOfferSearchRequest} from "../../offer/actions/fetch";
import {reduceResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_response_with_reset";


export interface INativeImage {
    stored: string;
    selected: string;
    id: string;
    base64value: string;
}

export interface IMobileNativesFormValues {
    mobile_image: INativeImage; // old field, now used by MonthlyCreationForm
    mobile_image_native: INativeImage; // new special field for NativeCreationForm to distinguish it from MonthlyCreationForm
    mobile_name: string;
    mobile_offer_price_type: number;
    mobile_address: string;
    mobile_region: string;
    mobile_individual_price_m2: string;
    mobile_construction_end_date: string;
    mobile_type: {
        value: string;
        option: {
            value: string;
            label: string;
        };
    };
    mobile_properties_count_for_sale: string;
    mobile_area: {lower: number, upper: number};
    mobile_ranges_rooms: {lower: string, upper: string};
    mobile_vendor: string;
    type: number;
    mobile_button_text_type: number;
    desktop_vendor_logo: INativeImage;
    desktop_distance: string | null;
}

export interface IDesktopNativesFormValues {
    desktop_properties_count_for_sale: string;
    desktop_area: {lower: number, upper: number};
    desktop_construction_end_date: string;
    desktop_distance: string;
    desktop_distance_region: string;
    desktop_image: INativeImage;
    desktop_individual_price_m2: string;
    desktop_name: string;
    desktop_offer_price_type: number;
    desktop_price_type: {
        value: string;
        option: {
            value: string;
            label: string;
        };
    };
    desktop_type: {
        value: string;
        option: {
            value: string;
            label: string;
        };
    };
    desktop_ranges_rooms: {lower: string, upper: string};
    desktop_address: string;
    desktop_vendor: string;
    desktop_vendor_logo: INativeImage;
    type: number;
}


export interface INativesForms {
    request: RequestState;
    errors: AppError | null;
    desktopForm: IDesktopNativesFormValues;
    mobileForm: IMobileNativesFormValues;
}

export interface INativeAd {
    create_date: string;
    desktop_area: { lower: number, upper: number };
    desktop_construction_date_range: string;
    desktop_distance: string;
    desktop_distance_region: string;
    desktop_image: string;
    desktop_individual_price_m2: string;
    desktop_name: string;
    desktop_offer_price_type: number;
    desktop_type: number;
    desktop_vendor: string;
    desktop_vendor_logo: string;
    desktop_properties_count_for_sale: string;
    desktop_ranges_rooms: string;
    desktop_street: string;
    mobile_image: string;
    mobile_name: string;
    mobile_offer_price_type: number;
    mobile_street: string;
    id: number;
    user: {
        pk: number;
        name: string;
    };
    update_date: string;
    type: number;
    offer: number;
}

export interface INativesFilterForm {
    offer_name: string;
    vendor_name: string;
    create_by: string;
}

export interface NativesStore {
    natives: INativeAd[];
    pagination: PaginationState;
    fetch: {
        requestState: RequestState;
        errors: AppError | null;
        latestQuery: Dictionary<string>;
    };
    list: {
        contextPanel: ContextPanelState;
        nativesFilterForm: INativesFilterForm;
    };
    detail: INativesForms;
    nativesOfferDetail: Offer;
}

const clearAddEditView = (store: INativesForms, action: Action): any => {
    switch (action.type) {
        case CLEAR_ADD_EDIT_VIEW_STATE:
            return {
                desktopForm: DesktopNativesForm.fromJSON({}),
                mobileForm: MobileNativesForm.fromJSON({}),
                request: RequestState.None,
                errors: null
            };
        case CLEAR_ADD_EDIT_ERRORS:
            return {
                ...store,
                errors: null
            };
        default:
            return store;
    }
};


const adverts = combineReducers({
    natives: reducePaginatedResponse(nativesRequest),
    pagination: reducePagination(nativesRequest),
    fetch: combineReducers({
        requestState: reduceRequestState(nativesRequest),
        errors: reduceRequestErrors(nativesRequest),
        latestQuery: reduceLatestQuery(nativesRequest)
    }),
    list: combineReducers({
        contextPanel: campaignListContextPanel,
        nativesFilterForm: reduceFormData(filterNativeListFormTypes, NativeListFilterForm.fromJSON({}))
    }),
    detail: reduceReducers(combineReducers({
            request: reduceRequestState(nativesRequestTypes),
            errors: reduceRequestErrors(nativesRequestTypes),
            desktopForm: reduceFormData(addDesktopNativesFormTypes, DesktopNativesForm.fromJSON({})),
            mobileForm: reduceFormData(addMobileNativesFormTypes, MobileNativesForm.fromJSON({}))
        }),
        clearAddEditView
    ),
    nativesOfferDetail: reduceResponseWithReset(nativesOfferSearchRequest)
});

export default adverts;
