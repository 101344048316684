import {patchRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {nphApiLink} from "../../project/route/nph_api_link";
import {IImageTag} from "./fetch_image_tags";


export const updateImageTag = (offerId: number, imageId: number, tag: IImageTag): Promise<IImageTag> => {
    const url = nphApiLink.offers.gallery.tagDetail({offerId, imageId, tagId: tag.id});
    return patchRequest(url, tag)
        .then((res: IImageTag) => {
            return res;
        });
};
