import * as React from "react";
import {Field} from "redux-form";

import StyleProps from "../../../../../../shared/ts/interfaces/style";
import TextareaField from "./TextareaField";


interface TextareaProps extends StyleProps {
    id?: string;
    type?: string;
    name: string;
    label?: string;
    placeholder?: string;
    onValueUpdate?: (name: string) => void;
    autoFocus?: boolean;
    disabled?: boolean;
    groupClassName?: string;
    maxLength?: number;
}


export default class Textarea extends React.Component<TextareaProps, {}> {
    public render(): JSX.Element {
        return (
            <Field
                {...this.props}
                component={TextareaField}
            />
        );
    }
}
