import * as React from "react";
import {Field} from "redux-form";

import StyleProps from "../../../../../../shared/ts/interfaces/style";
import FileInputField from "./FileField";

interface FileProps extends StyleProps {
    id?: string;
    type?: string;
    name: string;
    label?: string;
    allowedExtensions?: string[];
    maxSize?: number;
    groupClassName?: string;
    onValueUpdate?: (name: string) => void;
}

export default class File extends React.Component<FileProps, {}> {
    public render(): JSX.Element {
        return (
            <Field
                {...this.props}
                component={FileInputField}
            />
        );
    }
}
