import {fetchPlanOfferTypes, IPlanOffer} from "../actions/fetch_plan_offer_actions";
import {addPlanImageFormFields} from "../components/add_plan/AddImageForm";
import {combineReducers} from "redux";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {offerAddImageFormTypes} from "../actions/add_image_form";
import {fromJson} from "../../shared/form/translate/from_json";
import {IPlanVendorGroupStore, planVendorGroupReducer} from "./plan_vendor_group_reducer";
import {planBuildingReducer, IPlanBuildingStore} from "./plan_building_reducer";
import {planFloorReducer, IPlanFloorStore} from "./plan_floor_reducer";
import {reduceResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_response_with_reset";
import {IPlanPropertyStore, planPropertyReducer} from "./plan_property_reducer";
import {IPlanOfferStore, planOfferReducer} from "./plan_offer_reducer";


export interface IOfferPlansStore {
    main: {
        offer: IPlanOffer | null;
        addPlanImageForm: Record<keyof typeof addPlanImageFormFields, any>;
    };
    building: IPlanBuildingStore;
    floor: IPlanFloorStore;
    offer: IPlanOfferStore;
    property: IPlanPropertyStore;
    vendorGroup: IPlanVendorGroupStore;
}

export const offerPlansReducer = combineReducers({
    main: combineReducers({
        offer: reduceResponseWithReset(fetchPlanOfferTypes),
        addPlanImageForm: reduceFormData(offerAddImageFormTypes, fromJson(addPlanImageFormFields, {}))
    }),
    building: planBuildingReducer,
    floor: planFloorReducer,
    offer: planOfferReducer,
    property: planPropertyReducer,
    vendorGroup: planVendorGroupReducer
});
