import * as React from "react";
import {useState} from "react";
import {map} from "lodash";
import {IImageTag} from "../../actions/fetch_image_tags";
import {Modal} from "../../../../../shared/ts/modals/Modal";
import {Button} from "../../../shared/button/Button";
import CenterBox from "../../../shared/layout/CenterBox";
import Loader from "../../../shared/loader/Loader";
import {truncateString} from "../../../../../shared/ts/helpers/truncate";
import {DeleteIcon} from "../../../../../shared/ts/components/gallery_tags/DeleteIcon";
import {EditIcon} from "../../../../../shared/ts/components/gallery_tags/EditIcon";
import {TagListEmpty} from "../../../../../shared/ts/components/gallery_tags/TagListEmpty";


interface IProps {
    tags: IImageTag[];
    onTagEditClick: (tag: IImageTag) => void;
    onTagDeleteClick: (tag: IImageTag) => void;
}

export const TagList = (props: IProps) => {

    const [selectedTag, setSelectedTag] = useState<IImageTag | null>(null);

    const showDeleteConfirmationModal = (tag: IImageTag) => {
        setSelectedTag(tag);
    };

    const onDeleteTagConfirm = (tag: IImageTag) => {
        props.onTagDeleteClick(tag);
        setSelectedTag(null);
    };

    return (
        <div className="ph-xl pv-md h100 overflow-a">
            <h1 className="fs24 mt-0 mb-lg">Lista tagów</h1>
            {props.tags.length > 0 ? (
                <ul className="list-unstyled">
                    {map(props.tags, tag => {
                        return (
                            <li key={tag.id} className="df fjc-sb fai-center pv-xl bdbs-solid bdbc-gray-lighter bdbw-sm">
                                <p className="fs16 m-0 pr-md fs-1 fg-1">
                                    {tag.label}
                                </p>

                                <div className="fg-0 fs-0">
                                    <div className="dib curp mr-lg" onClick={() => props.onTagEditClick(tag)}>
                                        <EditIcon/>
                                    </div>

                                    <div className="dib curp" onClick={() => showDeleteConfirmationModal(tag)}>
                                        <DeleteIcon/>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <div className="df fd-col fjc-center fai-center" style={{height: "90%"}}>
                    <p className="tac mb-xxxl fs16 fwb c-gray">
                        Brak dodanych tagów
                    </p>

                    <TagListEmpty/>
                </div>
            )}

            <Modal modalState={selectedTag !== null}
                   onModalClose={() => setSelectedTag(null)}
                   type="window"
                   hideHeader
            >
                {selectedTag ? (
                    <div style={{maxWidth: "400px"}}>
                        <div className="p-xxxl tac">
                            <p className="fs16 fwb mb-lg">
                                Czy na pewno chcesz usunąć tag pod nazwą “{truncateString(selectedTag.label, 60)}”?
                            </p>

                            <p className="fs14 mb-0">
                                Tag zostanie usunięty bezpowrotnie
                            </p>
                        </div>

                        <div>
                            <Button className="gallery-tags-delete-confirmation bdrs-solid bdrc-gray-lighter bdrw-sm bdrs-top-0 c-info" onClick={() => setSelectedTag(null)}>
                                Anuluj
                            </Button>

                            <Button className="gallery-tags-delete-confirmation bdrs-top-0 c-info" onClick={() => onDeleteTagConfirm(selectedTag)}>
                                Potwierdź
                            </Button>
                        </div>
                    </div>
                ) : (
                    <CenterBox className="vhc"><Loader size="lg" /></CenterBox>
                )}
            </Modal>
        </div>
    );
};
