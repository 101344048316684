import * as moment from "moment";
import {Offer} from "../../../nph/ts/shared/models/Offer";


moment.locale("pl");

const dateTimeFormat = {
    standard: "DD MMMM YYYY",
    shortDate: "DD.MM.YYYY",
    longDate: "D MMMM YYYY",
    shortDateTime: "DD.MM.YYYY HH:mm",
    longDateTime: "D MMMM YYYY, HH:mm",
    time: "HH:mm",
    quarter: "Q [kw.] YYYY",
    year: "YYYY",
    dashedDateFormat: "YYYY-MM-DD",
    verboseMonthDatePL: "LL"
};

const formatDate = (dateString: string | null = null, dateFormat = dateTimeFormat.standard): string => {
    const date = dateString ? moment(dateString) : moment();
    return date.isValid() ? date.format(dateFormat) : "";
};


export const getDate = (offer: Offer) => moment() <= moment(offer.construction_date_range.upper) ?
    formatDate(offer.construction_date_range.upper, dateTimeFormat.quarter)
    : "Gotowe";
