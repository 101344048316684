import {Action} from "../helpers/interfaces";
export const SHOW_MODAL = "modal/SHOW_MODAL";
export const HIDE_MODAL = "modal/HIDE_MODAL";


export interface ModalAction extends Action {
    id: string;
    callbackParams?: any;
}

export function showModal(id: string, callbackParams?: any): ModalAction {
    return {
        type: SHOW_MODAL,
        id,
        callbackParams
    };
}

export function hideModal(id: string): ModalAction {
    return {
        type: HIDE_MODAL,
        id
    };
}
