import {combineReducers} from "redux";

import reel, {ReelStore} from "./reels";
import reelsCreator, {ReelsCreatorStore} from "./reelsCreator";

export interface IVideoStore {
    reel: ReelStore;
    reelsCreator: ReelsCreatorStore;
}

const video = combineReducers({
    reel,
    reelsCreator
});

export default video;
