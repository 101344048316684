import * as React from "react";
import StyleProps from "../../../../shared/ts/interfaces/style";
import {Offer} from "../../shared/models/Offer";

interface OfferSelectOptionProps extends StyleProps {
    offer: Offer;
}

const OfferSelectOptionC: React.SFC<OfferSelectOptionProps> = props => {
    return (
        <div className="df fai-center">
            <div className="fg-1 fs1 fb-a">
                <div className="psr">{props.offer.name}</div>
                <div className="fs12 c-gray">{props.offer.vendor.name}</div>
            </div>

            <div className="fg-0 fs-1 fb-a pl-md">
                <span className="fs12 nowrap c-gray">{props.offer.type.name_plural}</span>
            </div>
        </div>
    );
};


export default OfferSelectOptionC;
