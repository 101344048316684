import {reduce, map, isArray, isNaN} from "lodash";
import DatePicker from "../fields/date_picker/DatePicker";
import DatePickerRange from "../fields/date_picker/DatePickerRange";
import Input from "../fields/input/Input";
import {Select, SelectAsync, SelectCreatable, SelectAsyncCreatable} from "../fields/select/Select";
import {DateTimePicker, DateTimePickerRange} from "../fields/date_time_picker/DateTimePicker";
import Textarea from "../fields/textarea/Textarea";
import TimePicker from "../fields/time_picker/TimePicker";
import {TimePickerRange} from "../fields/time_picker/TimePicker";


type RawData = any;
type FormData = any;
type FormFieldClass = any;


const safeParseInt = (value: string) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? value : parsed;
};


export const fromFormData = (types: {[key: string]: FormFieldClass}, formData: FormData): RawData => {
    return reduce(types, (acc: RawData, type: FormFieldClass, key: string) => {
        switch (type) {
            case DatePicker:
            case Input:
            case Textarea:
                return {...acc, [key]: formData[key]};
            case Select:
            case SelectCreatable:
            case SelectAsync:
            case SelectAsyncCreatable:
                return {...acc, [key]: isArray(formData[key]) ? map(formData[key], (s: string) => safeParseInt(s)) : safeParseInt(formData[key])};
            case DateTimePicker:
                const dateTime = formData[key].split(" ");
                return {...acc,
                    [`${key}__date`]: dateTime[0],
                    [`${key}__time`]: dateTime[1]
                };
            case DatePickerRange:
                return {
                    ...acc,
                    [`${key}__lower`]: formData[`${key}_0`],
                    [`${key}__upper`]: formData[`${key}_1`]
                };
            case DateTimePickerRange:
                const lowerDateTime = formData[`${key}_0`];
                const upperDateTime = formData[`${key}_1`];
                return {
                    ...acc,
                    [`${key}__lower__date`]: lowerDateTime[0],
                    [`${key}__lower__time`]: lowerDateTime[1],
                    [`${key}__upper__date`]: upperDateTime[0],
                    [`${key}__upper__time`]: upperDateTime[1]
                };
            default:
                return console.error(`fromFormData: not implemented for ${key}`);
        }
    }, {});
};
