import * as React from "react";
import * as qs from "qs";

import {IMobileNativesFormValues} from "../../../advertisments/reducers/natives";
import {creationPreviewLinks} from "../../creation_preview_path";

interface IProps {
    nativeCreationFormData: IMobileNativesFormValues;
}

interface IImageAdCreationAdPreviewData {
    mobile_image: {
        m_img_375x484: string;
    } | null;
}

export const ImageAdCreationPreview = (props: IProps) => {
    const data: IImageAdCreationAdPreviewData = {
        ...props.nativeCreationFormData,
        mobile_image: {
            m_img_375x484: props.nativeCreationFormData.mobile_image.stored
        }
    }

    const query = qs.stringify(data)

    const previewUrl = `${creationPreviewLinks.imageAdCreation()}?${query}`;
    return <iframe frameBorder={0} src={previewUrl} width="100%" height="600px"></iframe>;
}
