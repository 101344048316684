import * as React from "react";
import * as _ from "lodash";
import {Radio as RadioBase, Tooltip, OverlayTrigger, FormGroup} from "react-bootstrap";
import * as classNames from "classnames";

import {FormComponent, FormComponentProps, FormComponentState} from "./formComponent";
import {HelpOverlay} from "../help_overlay";

interface RadioProps extends FormComponentProps<string> {
    options: {value: string, label: string}[];
    label?: string | JSX.Element;
    groupClassName?: string;
    className?: string;
    id?: string;
    onChange?: React.FormEventHandler;
    disabled?: boolean;
    onOffSwitch?: boolean;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
}

interface RadioState extends FormComponentState {
    didValueChange: boolean;
}

export class Radio extends FormComponent<string, RadioProps, RadioState> {

    public static toJSON(name: string, values: any): any {
        return {[name]: values[name] || false};
    }

    public static toFormData(name: string, values: any): any {
        let value = values[name];
        if (!Radio.isEmpty(value)) {
            return {[name]: value};
        }
    }

    public static toFormDataPost(name: string, values: any): any {
        return Radio.toFormData(name, values);
    }

    public static fromJSON(name: string, values: any): any {
        return {[name]: values[name]};
    }

    public static fromFormData(name: string, values: any): any {
        return {[name]: values[name]};
    }

    public static isEmpty(value: boolean): boolean {
        return !value;
    }

    public constructor(props: RadioProps) {
        super(props);
        this.state = {
            didValueChange: false
        };
        this.onChange = this.onChange.bind(this);
    }

    /**
     * Lifecycle
     */

    public componentDidMount(): void {
        this.afterRender(this.props);
    }

    public componentDidUpdate(): void {
        this.afterRender(this.props);
    }

    private afterRender(props: any): void {
        const {name, value, onValueChange} = props;
        const {didValueChange} = this.state;
        if (didValueChange && _.isFunction(onValueChange)) {
            this.setState({
                didValueChange: false
            });
            onValueChange(name, value);
        }

    }

    /**
     * Callbacks
     */

    public onChange(e: React.FormEvent): void {
        let value = (e.target as HTMLInputElement).value;
        if (_.isFunction(this.props.onFormUpdate)) {
            this.setState({
                didValueChange: true
            });
            this.props.onFormUpdate(this.props.name, value);
        }
    }

    /**
     * Render methods
     */

    public render(): JSX.Element {
        const {name, error, label, className, helpText, helpPlacement, helpClassName, onOffSwitch} = this.props;
        const groupClassName = classNames("form-group", this.props.groupClassName, this.props.markable);
        const groupClassNameError = classNames("has-error psr", groupClassName);

        let props = _.assign({}, _.pick(this.props, [
            "error",
            "name"
        ]), {
            ref: "input",
            onChange: this.props.onChange || this.onChange
        });

        // Help overlay
        let helpOverlay: JSX.Element | null = null;
        if (helpText) {
            const helpOverlayId = `${name}helpOverlay`;
            helpOverlay = (
                <div className="dib ml-md">
                    <HelpOverlay id={helpOverlayId} placement={helpPlacement || "right"} className={helpClassName}>
                        {helpText}
                    </HelpOverlay>
                </div>
            );
        }

        return (
            <>
                <h3 className="fs14 fwb mt-0">{label}{helpOverlay}</h3>

                <div className="df fd-row">
                    {this.props.options.map((option, index) => {
                        const id = `${name}_${index}`;
                        const checked = this.props.value === option.value;

                        return (
                            <div key={option.value} className="pr-xxxl">
                                {error ? (
                                    <OverlayTrigger
                                        key={option.value}
                                        placement="top"
                                        overlay={<Tooltip className="tooltip-error" id={`tooltip-${name}`}>{error}</Tooltip>}
                                    >
                                        <FormGroup bsClass={classNames(groupClassNameError, checked ? "is-active" : "")}>
                                            <div className="dib">
                                                <RadioBase
                                                    className={onOffSwitch ? (`onoffswitch ${className}`) : className}
                                                    type="radio"
                                                    id={id}
                                                    hasFeedback
                                                    bsStyle="error"
                                                    {...props}
                                                    checked={checked}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                    <span className="onoffswitch-inner"/>
                                                    <span className="onoffswitch-switch"/>
                                                </RadioBase>
                                            </div>
                                        </FormGroup>
                                    </OverlayTrigger>
                                ) : (
                                    <span key={option.value}>
                                        <FormGroup bsClass={classNames(groupClassName, checked ? "is-active" : "")}>
                                            <RadioBase
                                                className={onOffSwitch ? (`onoffswitch ${className}`) : className}
                                                type="radio"
                                                id={id}
                                                {...props}
                                                checked={checked}
                                                value={option.value}
                                            >
                                                {option.label}
                                                <span className="onoffswitch-inner"/>
                                                <span className="onoffswitch-switch"/>
                                            </RadioBase>
                                        </FormGroup>
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
}
