import * as React from "react";

import {ModalProps} from "../../../../shared/ts/decorators/modalix";
import * as classnames from "classnames";
import {Button} from "../button/Button";

interface IProps extends ModalProps {
    children?: React.ReactNode;
    modalTitle?: JSX.Element | string;
    modalText?: JSX.Element | string;

    // Confirm button
    confirmButtonStyle?: string;
    confirmButtonText?: string;

    // Reject button
    rejectButtonStyle?: string;
    rejectButtonText?: string;
}

export const RemoveModal = (props: IProps) => {
    const confirmButtonStyle = classnames("mr-lg", props.confirmButtonStyle);
    const rejectButtonStyle = classnames("", props.rejectButtonStyle);

    return (
        <div className="modal-remove modal-dialog" >
            <div className="bd-0 modal-content" tabIndex={0}>
                <div className="modal-header-box p-md fs16 modal-header text-center">
                    <button className="btn-close close" type="button" onClick={() => props.onReject()}>&times;</button>

                    <h4 className="modal-title">{props.modalTitle || "Usunięcie kampanii"}</h4>
                </div>

                <div className="modal-body text-center">{props.modalText || "Czy na pewno chcesz usunąć wybraną kampanię?"}</div>

                <div className="pb-md text-center">
                    <Button color="danger" size="sm" className={confirmButtonStyle} onClick={props.onConfirm}>
                        {props.confirmButtonText || "Tak"}
                    </Button>

                    <Button color="default" size="sm" className={rejectButtonStyle} onClick={props.onReject}>
                        {props.rejectButtonText || "Nie"}
                    </Button>
                </div>
            </div>
        </div>
    );
};
