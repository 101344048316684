import * as React from "react";
import * as _ from "lodash";

import {Button} from "../../../../shared/button/Button";
import {Input} from "../../../../../../shared/ts/components/forms/input";
import {ElementClasses, Form, FormProps} from "../../../../../../shared/ts/components/form";
import {DateTimePickerRange} from "../../../../../../shared/ts/components/forms/dateTimePickerRange";
import {getRegion, getRegions} from "../../../helpers/autocomplete";
import {Select} from "../../../../../../shared/ts/components/forms/select";

interface IProps extends FormProps {
    className?: string;
    clearForm: () => void;
}

interface IState {}

export class ReelListFilterForm extends Form<IProps, IState> {
     public static elementClasses: ElementClasses = {
         "title": {
            type: Input,
            label: "Hasło reklamujące"
         },
         "display_date_range": {
             type: DateTimePickerRange,
             label: "Okres wyświetlania"
         },
         "region": {
             type: Input,
             label: "Wybierz lokalizację"
         }
    };

    constructor(props: IProps) {
        super(props);
    }

    public componentDidMount(): void {
        this.props.updateFormAction(this.getDefaultValues());
    }

    private getDefaultValues = () => ({
        title: "",
        display_date_range: {lower: "", upper: ""},
        region: {value: ""}
    });

    private onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        const formValues = ReelListFilterForm.toFormData(this.props.values);
        this.props.onSubmit({
            ..._.pick(formValues, ["title", "display_date_range_0", "display_date_range_1"]),
            ...(formValues.region.value ? {region: formValues.region.value} : {}),
        } as any);
    };

    private getRegions = (name: string) => {
        return getRegions(name);
    }

    private getRegion = (id: number) => {
        return getRegion(id);
    }

    public render() {
        return (
            <div className="pv-md ph-xl fg-1">
                <form>
                    <Input {...this.generateProps("title")} onBlur={this.onSubmit} />

                    <DateTimePickerRange
                        {...this.generateProps("display_date_range")}
                        displayFormat={"DD.MM.YYYY"}
                        inputFormatDatetime={"YYYY-MM-DD"}
                        inputFormatDate={"YYYY-MM-DD"}
                        leftPlaceholder={"Data rozpoczęcia"}
                        rightPlaceholder={"Data zakończenia"}
                        datePickerProps={{
                            className: "form-control",
                        }}
                        onBlur={this.onSubmit}
                    />

                    <Select
                        {...this.generateProps("region")}
                        groupClassName="mb-sm"
                        searchable
                        async
                        loadOptions={this.getRegions}
                        getOption={this.getRegion}
                        onBlur={this.onSubmit}
                    />

                    <div className="tac">
                        <div>
                            <Button
                                color="success"
                                size="lg"
                                submitting={false}
                                type={"submit"}
                                onClick={this.onSubmit}
                            >
                                Wyszukaj
                            </Button>
                        </div>
                        <div>
                            <Button color="link" onClick={this.props.clearForm}>
                                wyczyść pola
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
