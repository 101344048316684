import * as _ from "lodash";

import {IReelFormValues} from "../reducers/reels";
import {ReelForm} from "../components/ReelForm";
import {getReelsFormActionButtonProperties} from "./get_reels_form_action_button_properties";
import {File} from "../../../../shared/ts/components/forms/file";

export const getReelsFormValues = (values: IReelFormValues) => {
    const {creator, creator_image, ...restForm} = ReelForm.toJSON(values) as IReelFormValues;
    const shouldNewCreatorBeCreated = typeof creator === "string";
    const region = typeof restForm.region === "object" ? _.get(restForm, "region.value") : restForm.region;
    const reel = {
        ..._.pick(restForm, [
            "cover",
            "cover_variant",
            "display_date_range",
            "title",
            "type",
            "display_in_offer_enabled"
        ]),
        ...(region ? {region} : {}),
        ...getReelsFormActionButtonProperties(restForm),
        ...(values.video ? File.toFormDataPost("video", values) : {}),
        ...(restForm.display_in_offer_enabled ? {display_in_offer: restForm.display_in_offer} : {display_in_offer: ""}),
    };

    const cover = values.cover as string | {selected: string};
    if (typeof cover === "object" && !cover.selected) {
        throw new ReelFormError("Wybierz okładkę dla rolki");
    }

    if (!creator) {
        throw new ReelFormError("Podaj autora reels");
    }

    if (shouldNewCreatorBeCreated && !creator_image) {
        throw new ReelFormError("Wybierz zdjęcie autora reels");
    }

    if (!restForm.title.length) {
        throw new ReelFormError("Musisz podać hasło reklamujące");
    }

    if (restForm.display_in_offer_enabled && !restForm.display_in_offer) {
        throw new ReelFormError("Nie wybrano oferty, w której ma być wyświetlany reel");
    }

    const reelCreator = {
        ...(shouldNewCreatorBeCreated ? {
            name: creator as string,
            image: creator_image
        } : {
            pk: creator as number,
            image: creator_image
        }),
    };

    return {
        reelCreator,
        reel
    }
}

export class ReelFormError extends Error {
    name = "ReelFormError";
    constructor(message: string) {
        super(message);
        this.name = "ReelFormError";
    }
}
