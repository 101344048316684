import * as React from "react";
import * as numeral from "numeral";
import {StatelessProps} from "../helpers/interfaces";


// Our own configuration, with "zł" instead of "PLN" currency
numeral.register("locale", "pl-rp", {
    delimiters: {
        thousands: " ",
        decimal: ","
    },
    abbreviations: {
        thousand: "tys.",
        million: "mln",
        billion: "mld",
        trillion: "bln"
    },
    ordinal: function () {
        return ".";
    },
    currency: {
        symbol: "zł"
    }
});

numeral.locale("pl-rp");

// Currency format.
interface CurrencyFormatProps extends StatelessProps {
    price: number;
    withoutUnit?: boolean;
}

export const CurrencyFormat = (props: CurrencyFormatProps) => {
    let format = "0,0.[00] $";
    if (props.withoutUnit === true) {
        format = "0,0.[00]";
    }
    const price = numeral(props.price).format(format);
    return <span className={props.className}>{price}</span>;
};


// Area format
interface AreaFormatProps extends StatelessProps {
    area: number;
    withoutUnit?: boolean;
}

export const AreaFormat = (props: AreaFormatProps) => {
    const area = numeral(props.area).format("0,0.[00]");
    const unit: JSX.Element | null = props.withoutUnit === true ? null : <span>m<sup>2</sup></span>;
    return <span className={props.area.toString()}>{area} {unit}</span>;
};


// Number format
interface NumberFormatProps extends StatelessProps {
    value: number;
}

export const NumberFormat = (props: NumberFormatProps) => {
    const value = numeral(props.value).format("0,0.[00]");
    return <div className={props.className}>{value}</div>;
};
