import {browserHistory} from "react-router";
import * as _ from "lodash";

import * as auth from "../../../../shared/ts/helpers/auth";
import {url} from "../../../../shared/ts/helpers/routing";
import {postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../shared/ts/helpers/errors";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";

import {addErrorAlert} from "../../shared/alerts/actions";

import {Store} from "../../project/reducer";
import {LoginFormValues, LoginFormProps} from "../components/LoginForm";
import {nphApiLink} from "../../project/route/nph_api_link";


const LOG_IN_PREFIX = "auth/LOG_IN";
const loginRequest = createRequestActionTypes(LOG_IN_PREFIX);


interface Action {
    type: string;
}
interface LoginRequest {
    email: string;
    password: string;
}

interface LoginResponse {
    auth_token: string;
}


const login = (email: string, password: string, next?: string) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch<Action>({type: loginRequest.start});
    return postRequest<LoginRequest>(nphApiLink.users.login(), {email, password})
        .then((json: LoginResponse) => {
            auth.logIn({apikey: json.auth_token});
            browserHistory.push((next && decodeURIComponent(next) || url("app:in")));
            dispatch<Action>({type: loginRequest.success});
            return json;
        })
        .catch(catch400(responseError => {
            if (responseError.appError.alerts) {
                _.each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, LOG_IN_PREFIX)));
            }
        }));
};

export const submitLoginForm = (values: LoginFormValues, dispatch: Dispatch, props: LoginFormProps) => {
    const next = ""; // TODO: implement `next` query param
    return dispatch(login(values.email, values.password, next) as any); // TODO: ANY - `dispatch` takes only object-actions
};
