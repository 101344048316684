import {reduce} from "lodash";
/**
 * Motivation to use `lodash` library:
 * We need to keep coherent code style concerning operations on data collections.
 * Using `lodash` library allows us to implement our own missing utils and keep consistent utils API.
 * Compare proposed `reduceAsync` interface with `lodash` reduce (match) and JS reduce (different).
 */

type IReduceReducer<T, TResult> = (acc: TResult, elem: T, idx: number, array: T[]) => Promise<TResult>;
export const reduceAsync = <T, TResult>(
    collection: T[],
    reducer: IReduceReducer<T, TResult>,
    initAcc: TResult
): Promise<TResult> =>
    reduce<T, Promise<TResult>>(collection, async (acc, elem, idx, array) => {
        const accRes = await acc;
        return reducer(accRes, elem, idx, array);
    }, Promise.resolve(initAcc));
