import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Main from "../../../shared/layout/Main";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import ContentBody from "../../../project/components/content/ContentBody";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {Button} from "../../../shared/button/Button";
import {RouterProps} from "../../../../../shared/ts/interfaces/router";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {resetOfferDetailForNative} from "../../../offer/actions/fetch";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {saveAsHtml} from "../../native/actions/save_as_html";
import {htmlToImgDownload} from "../../native/actions/html_to_img";
import { url } from "../../../../../shared/ts/helpers/routing";
import {ITdaForms} from "../../../advertisments/reducers/tda";
import {addTdaFormActions, clearTdaAddEditViewState, tdaAdsAdd} from "../../../advertisments/actions/tda/add";
import {TdaAdCreationAdModal} from "../components/TdaAdCreationAdModal";
import {ITdaAdCreation, TdaAdCreationForm} from "../components/TdaAdCreationForm";

interface IStateProps {
    advertDetail: ITdaForms;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    mobileFormActions: FormActions;
    actions: {
        resetOfferDetailForNative: typeof resetOfferDetailForNative;
        clearTdaAddEditViewState: typeof clearTdaAddEditViewState;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
        tdaAdsAdd: typeof tdaAdsAdd;
    };
}

interface IOwnProps extends RouterProps {
    hoc: HocModalix;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    initialImage: string | Blob;
}

@modalix(TdaAdCreationAdModal)
class TdaAdCreationAddViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            initialImage: ""
        };
    }

    public componentDidMount() {
        this.getDefaultFormValues();
    }

    public componentWillUnmount(): void {
        this.props.actions.clearTdaAddEditViewState();
    }

    private getDefaultFormValues = () => {
        this.props.mobileFormActions.update({});
    };

    private onSubmit = (shouldGenerate: boolean = false, toHtml: boolean = false) => {
        const creation: ITdaAdCreation = {
            ...TdaAdCreationForm.toJSON(this.props.advertDetail.form),
        };

        if (this.props.advertDetail.form.desktop_image.base64value === "") {
           delete creation.desktop_image;
        }

        if (this.props.advertDetail.form.mobile_image.base64value === "") {
            delete creation.mobile_image;
        }

        const creationUpdated = {...creation}
        this.handleFormSubmit(creationUpdated, shouldGenerate, toHtml);
    };

    private handleFormSubmit = (creation: ITdaAdCreation, shouldGenerate: boolean, toHtml: boolean) => {
        (this.props.actions.tdaAdsAdd(creation as any) as any)
            .then((response: ITdaAdCreation) => {
                if (response != null) {
                    if (shouldGenerate) {
                        if (toHtml) {
                            this.handleSaveHtml(response.name, response.id);
                        } else {
                            this.handleConvert(response.name, response.id);
                        }
                    }
                    return response.id;
                }
            })
            .then((id: number) => {
                if (id != null) {
                    const link = url("app:in:creation:tda:edit", {id});
                    this.props.router.push(link);
                }
            });
    };

    private handleConvert = (name: string, id: number) => {
        this.openPreviewModal();
        htmlToImgDownload(document.getElementById("v2-ad")!, name, id);

        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    private handleSaveHtml = (name: string, id: number) => {
        this.openPreviewModal();
        const element = document.getElementById("v2-ad")!;
        saveAsHtml(element, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    /**
     * Callback
    */

    private openPreviewModal = () => {
        this.props.hoc.modalix.show<any>({
            imageAdCreationFormData: this.props.advertDetail.form,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    private onImageCropped = (key: "mobile_image" | "desktop_image", image?: string) => {
        const imgOptions = this.getImgOptions(key, image);

        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.advertDetail.form[key],
                ...imgOptions
            }
        });
    };

    private getImgOptions = (key: "mobile_image" | "desktop_image", image?: string) => {
        if (image) {
            return {
                base64value: image
            }
        }
    }

    /**
     * Render
     */

    public render() {
        const {request} = this.props.advertDetail;
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklama TDA</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit()}
                            >
                                Zapisz reklamę
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(true)}
                            >
                                Zapisz i Wygeneruj reklamę
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <TdaAdCreationForm
                            onSubmit={() => this.onSubmit(false)}
                            errors={this.props.advertDetail.errors ? this.props.advertDetail.errors.fieldErrors : null}
                            values={this.props.advertDetail.form}
                            updateFormAction={this.props.mobileFormActions.update}
                            handlePreview={() => this.openPreviewModal()}
                            submitAndGenerate={() => this.onSubmit(true)}
                            submitAndGenerateHtml={() => this.onSubmit(true, true)}
                            updateImageAfterCropping={this.onImageCropped}
                            initialImage={this.state.initialImage}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const TdaAdCreationAddView = connect(mapStateToProps, mapActionsToProps)(TdaAdCreationAddViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        advertDetail: store.adverts.tda.detail,
        contextPanelOpened: store.adverts.tda.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        mobileFormActions: bindActionCreators(addTdaFormActions, dispatch),
        actions: bindActionCreators({
            tdaAdsAdd,
            openPanel,
            closePanel,
            openTab,
            clearTdaAddEditViewState,
            resetOfferDetailForNative
        }, dispatch)
    };
}
