import * as React from "react";
import {useEffect} from "react";
import {RefObject} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {map, find, isFinite} from "lodash";
import {AssignBuildingForm, assignBuildingFormFields} from "./AssignBuildingForm";
import {Modal} from "../../../../../shared/ts/modals/Modal";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {assignBuildingFormActions} from "../../actions/asssign_building_form";
import {patchBuildingPlan} from "../../actions/patch_building_plan";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {BoundAction} from "../../../../../typings/custom_typings/custom_redux";
import {IFabricApi} from "../draw_polygon/init_fabric";
import {IPlanBuilding} from "../../actions/fetch_plan_building_actions";
import {
    createPlanPolygon,
    deletePlanPolygon,
    IGroupPlanPolygon,
    IPlanPolygon, updatePlanPolygon
} from "../../actions/plan_polygon_actions";
import {fromCustomPolygonId, isCustomPolygonId, toCustomPolygonId} from "../floor/AssignPropertyModal";


interface IStateProps {
    assignBuildingFormValues: Record<keyof typeof assignBuildingFormFields, any>;
}
interface IActionsProps {
    assignBuildingFormActions: FormActions;
    actions: {
        createPlanPolygon: BoundAction<typeof createPlanPolygon>;
        deletePlanPolygon: BoundAction<typeof deletePlanPolygon>;
        updatePlanPolygon: BoundAction<typeof updatePlanPolygon>;
        patchBuildingPlan: BoundAction<typeof patchBuildingPlan>;
    };
}
interface IOwnProps {
    assignBuildingModalState: number | null;
    setAssignBuildingModalState: (state: number | null) => void;
    apiRef: RefObject<IFabricApi>;
    buildings: IPlanBuilding[];
    groupPolygons: IGroupPlanPolygon[] | null;
    groupId: number;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {}

const AssignBuildingModalC = (props: IProps) => {

    useEffect(() => {
        const polygonId = props.assignBuildingModalState;
        if (polygonId == null || props.groupPolygons == null || polygonId === -1) {
            return;
        }

        if (isCustomPolygonId(polygonId)) {
            const customId = fromCustomPolygonId(polygonId);
            const value = find(props.groupPolygons, p => p.id === customId) as IGroupPlanPolygon;
            props.assignBuildingFormActions.replace({custom: true, name: value.name});
        }
        else {
            props.assignBuildingFormActions.replace({custom: false, building: polygonId.toString()});
        }
    }, [props.assignBuildingModalState]);

    const onAssignBuildingModalSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (props.apiRef.current == null || props.assignBuildingModalState == null) {
            throw new Error(`onAssignFloorModalSubmit: error state`);
        }
        const polygon = props.apiRef.current.getPolygon(props.assignBuildingModalState);
        if (polygon == null) {
            throw new Error(`onAssignFloorModalSubmit: no polygon with id ${props.assignBuildingModalState}`);
        }
        const apiPolygon = map(polygon, point => [point.fractionX, point.fractionY] as [string, string]);
        const values = props.assignBuildingFormValues;
        if (values.custom) { // custom form sent
            const planPolygonData: IPlanPolygon = {
                group: props.groupId,
                polygon: apiPolygon,
                name: values.name
            };
            // decide of prev id
            if (isCustomPolygonId(props.assignBuildingModalState)) { // prev is custom, so update that
                const customId = fromCustomPolygonId(props.assignBuildingModalState);
                await props.actions.updatePlanPolygon(customId, planPolygonData)
                // there is nothing to clear
            }
            else { // prev is building or none (add polygon)
                const custom = await props.actions.createPlanPolygon(planPolygonData);
                const prevId = props.assignBuildingModalState;
                if (prevId !== -1) { // edit - clear old assignment
                    await props.actions.patchBuildingPlan(prevId, {plan_polygon: null});
                }
                props.apiRef.current.changeId(prevId, toCustomPolygonId(custom.id));
            }
        }
        else { // standard building form sent
            const formBuildingIdStr = props.assignBuildingFormValues.building;
            const formBuildingId = parseInt(formBuildingIdStr, 10);
            if (!isFinite(formBuildingId)) {
                throw new Error(`onAssignBuildingModalSubmit: cannot parse formBuildingId from ${formBuildingIdStr}`);
            }
            if (props.assignBuildingModalState === formBuildingId) { // edit without building assignment change
                return closeAssignBuildingModal(); // close modal
            }
            // decide on prev id
            if (isCustomPolygonId(props.assignBuildingModalState)) { // prev is custom
                const customId = fromCustomPolygonId(props.assignBuildingModalState);
                await props.actions.patchBuildingPlan(formBuildingId, {plan_polygon: apiPolygon});
                await props.actions.deletePlanPolygon(customId);
                props.apiRef.current.changeId(toCustomPolygonId(customId), formBuildingId);
            }
            else { // prev is building or none (add polygon)
                await props.actions.patchBuildingPlan(formBuildingId, {plan_polygon: apiPolygon});
                const prevId = props.assignBuildingModalState;
                if (prevId !== -1) { // edit - clear old assignment
                    await props.actions.patchBuildingPlan(prevId, {plan_polygon: null});
                }
                props.apiRef.current.changeId(prevId, formBuildingId);
            }
        }
        closeAssignBuildingModal();
    };

    const onAssignBuildingModalClose = () => {
        if (props.assignBuildingModalState === -1) { // new polygon drawn
            props.apiRef.current && props.apiRef.current.remove(props.assignBuildingModalState);
        }
        closeAssignBuildingModal();
    };

    const closeAssignBuildingModal = () => {
        props.setAssignBuildingModalState(null);
        props.assignBuildingFormActions.clear();
    };

    return (
        <Modal
            modalState={!!props.assignBuildingModalState}
            onModalClose={onAssignBuildingModalClose}
            type="window"
            header="Przypisz budynek"
        >
            <AssignBuildingForm
                values={props.assignBuildingFormValues}
                errors={null}
                onChange={props.assignBuildingFormActions.update}
                onSubmit={onAssignBuildingModalSubmit}
                onClose={onAssignBuildingModalClose}
                buildings={props.buildings}
                initialBuildingId={props.assignBuildingModalState!}
            />
        </Modal>
    );
};

export const AssignBuildingModal: React.ComponentClass<IOwnProps> = connect(mapStateToProps, mapActionsToProps)(AssignBuildingModalC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        assignBuildingFormValues: state.plan.building.assignBuildingFormValues
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        assignBuildingFormActions: bindActionCreators(assignBuildingFormActions, dispatch),
        actions: bindActionCreators<Record<keyof IActionsProps["actions"], any>, IActionsProps["actions"]>({
            createPlanPolygon,
            deletePlanPolygon,
            updatePlanPolygon,
            patchBuildingPlan
        }, dispatch)
    };
}
