import {Dispatch} from "redux";
import {nphApiLink} from "../../project/route/nph_api_link";
import {patchRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";


const PATCH_VENDOR_GROUP = "vendor_group/patch";
export const patchVendorGroupTypes = createRequestActionTypes(PATCH_VENDOR_GROUP);

export const patchVendorGroup = (vendorId: number, groupId: number, data: {}) => async (dispatch: Dispatch) => {
    dispatch({type: patchVendorGroupTypes.start});

    const url = nphApiLink.vendors.groupDetail({vendorId: vendorId, groupId: groupId});

    return patchRequest(url, data)
        .then((res) => {
            dispatch({type: patchVendorGroupTypes.success, result: res});
            return res;
        });
};
