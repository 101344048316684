import * as React from "react";
import {map} from "lodash";
import {ISelectedTag, IPosition} from "./PictureTagging";
import {TagForm} from "./TagForm";
import {TagPoint} from "./TagPoint";
import {IImageTag} from "../../actions/fetch_image_tags";


const tagPointSizeX = 24;
const tagPointSizeY = 24;
const tagFormSizeX = 220;
const tagFormSizeY = 174;
const spaceBetweenPointAndForm = 10;

interface IProps {
    imageElem: HTMLImageElement;
    tags: IImageTag[];
    selectedTag: ISelectedTag | null;
    onTagClick: (tag: IImageTag) => void;
    onAcceptClick: (description: string) => void;
    onRejectClick: () => void;
}

export const TagLayer = (props: IProps) => {

    return (
        <div>
            {map(props.tags, tag => {
                const point = tag.polygon[0];
                const position = {x: point[0], y: point[1]};
                const {x, y} = getPixelsFromPosition(props.imageElem, position);
                return (
                    <div key={tag.id} className="psa curp" style={{
                        top: `${y - tagPointSizeY / 2}px`,
                        left: `${x - tagPointSizeX / 2}px`,
                        width: `${tagPointSizeX}px`,
                        height: `${tagPointSizeY}px`
                    }} onClick={() => props.onTagClick(tag)}><TagPoint/></div>
                );
            })}
            {props.selectedTag && (() => {
                const {x, y} = getPixelsFromPosition(props.imageElem, props.selectedTag.position);
                const tagFormKey = `${props.selectedTag.type}_${props.selectedTag.position.x}_${props.selectedTag.position.y}`;
                const [formLeft, formTop] = calculateTopLeftOfForm(props.imageElem, {x, y});
                return (
                    <>
                        <div className="psa" style={{
                            top: `${y - tagPointSizeY / 2}px`,
                            left: `${x - tagPointSizeX / 2}px`,
                            width: `${tagPointSizeX}px`,
                            height: `${tagPointSizeY}px`
                        }}>
                            <TagPoint active/>
                        </div>

                        <div className="psa" style={{top: `${formTop}px`, left: `${formLeft}px`}}>
                            <TagForm key={tagFormKey}
                                     width={tagFormSizeX}
                                     height={tagFormSizeY}
                                     tagType={props.selectedTag.type}
                                     tagDescription={props.selectedTag.description}
                                     onAcceptClick={props.onAcceptClick}
                                     onRejectClick={props.onRejectClick}
                            />
                        </div>
                    </>
                );
            })()}
        </div>
    );
};

/**
 * Helper
 */
interface IPositionInPixels {
    x: number;
    y: number;
}
function getPixelsFromPosition(elem: HTMLElement, position: IPosition): IPositionInPixels {
    const rect = elem.getBoundingClientRect();
    const fractionX = parseFloat(position.x);
    const fractionY = parseFloat(position.y);
    const x = fractionX * rect.width;
    const y = fractionY * rect.height;
    return {x, y};
}

function calculateTopLeftOfForm(elem: HTMLElement, pos: IPositionInPixels): [number, number] {
    const rect = elem.getBoundingClientRect();
    const inRangeX = pos.x <= rect.width - tagFormSizeX;
    const inRangeY = pos.y <= rect.height - tagFormSizeY;
    if (inRangeX && inRangeY) {
        return [
            pos.x + tagPointSizeX / 2 + spaceBetweenPointAndForm,
            pos.y - tagPointSizeY / 2
        ];
    }
    if (inRangeX) {
        return [
            pos.x + tagPointSizeX / 2 + spaceBetweenPointAndForm,
            pos.y + tagPointSizeY / 2 - tagFormSizeY
        ];
    }
    if (inRangeY) {
        return [
            pos.x - tagPointSizeX / 2 - tagFormSizeX - spaceBetweenPointAndForm,
            pos.y - tagPointSizeY / 2
        ];
    }
    return [
        pos.x - tagPointSizeX / 2 - tagFormSizeX - spaceBetweenPointAndForm,
        pos.y + tagPointSizeY / 2 - tagFormSizeY
    ];
}
