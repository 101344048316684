import {each, isArray} from "lodash";

import {createRequestActionTypes, RequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, ResponseAction} from "../../../../../shared/ts/interfaces/dispatch";
import {patchRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {INativeAd} from "../../reducers/natives";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {NATIVES_ADD_PREFIX, nativesRequestTypes} from "./add";
import {nphApiLink} from "../../../project/route/nph_api_link";


/**
 * POST Factories
 */

const createNativesEdit = (type: string, actionTypes: RequestActionTypes) => (id: number, jsonData: INativeAd) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.natives.edit({id});
    return patchRequest(urlString, jsonData)
        .then((native: INativeAd) => {
            dispatch<ResponseAction<any>>({type: actionTypes.success, result: native});
            dispatch(addSuccessAlert("Zapisano reklamę natywną", type));
            return native;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, NATIVES_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", EDIT_NATIVES_PATCH));
            }
            dispatch<ErrorAction>({type: nativesRequestTypes.error, error: responseError.appError});
            return null;
        }));
};

/**
 * Clear Edit View
 */
export const CLEAR_NATIVES_EDIT_VIEW_STATE = "natives/CLEAR_NATIVES_EDIT_VIEW_STATE";
export const clearEditViewState = () => ({type: CLEAR_NATIVES_EDIT_VIEW_STATE});


const EDIT_NATIVES_PATCH = "natives/edit/EDIT_NATIVES_PATCH";
export const nativeEditPatchTypes = createRequestActionTypes(EDIT_NATIVES_PATCH);
export const nativeEditPatch = createNativesEdit(EDIT_NATIVES_PATCH, nativeEditPatchTypes);

