import {combineReducers} from "redux";
import {fetchPlanOfferListTypes, IPlanOffer} from "../actions/fetch_plan_offer_actions";
import {AppError, RequestState} from "../../../../shared/ts/helpers/util";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {ContextPanelState, planOfferListContextPanel} from "../../context_panel/reducers/context_panel";
import {offerPlanListFormFields} from "../components/offer_list/PlanOfferListFilterForm";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {offerPlanListFormTypes} from "../actions/offer_plan_list_form";
import {fromJson} from "../../shared/form/translate/from_json";


export interface IPlanOfferStore {
    offerPlansList: {
        offers: IPlanOffer[];
        requestState: RequestState;
        latestQuery: Dictionary<string>;
        pagination: PaginationState;
        contextPanel: ContextPanelState;
        errors: AppError | null;
    };
    offerPlanListFormValues: Record<keyof typeof offerPlanListFormFields, any>;
}

export const planOfferReducer = combineReducers({
    offerPlansList: combineReducers({
        offers: reducePaginatedResponse(fetchPlanOfferListTypes),
        requestState: reduceRequestState(fetchPlanOfferListTypes),
        latestQuery: reduceLatestQuery(fetchPlanOfferListTypes),
        pagination: reducePagination(fetchPlanOfferListTypes),
        contextPanel: planOfferListContextPanel,
        errors: reduceRequestErrors(fetchPlanOfferListTypes)
    }),
    offerPlanListFormValues: reduceFormData(offerPlanListFormTypes, fromJson(offerPlanListFormFields, {}))
});
