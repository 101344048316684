import {deleteRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {nphApiLink} from "../../project/route/nph_api_link";


export const deleteImageTag = (offerId: number, imageId: number, tagId: number): Promise<boolean> => {
    const url = nphApiLink.offers.gallery.tagDetail({offerId, imageId, tagId});
    return deleteRequest(url)
        .then((res: null) => {
            return true;
        });
};
