import * as React from "react";
import {withRouter} from "react-router";
import * as _ from "lodash";
import * as hoistStatics from "hoist-non-react-statics";

import {createHocExtend, HocExtend, fromQueryToURL} from "./hoc_helpers";
import {RouterProps} from "../helpers/decorators";
import {RouterState} from "../interfaces/router";
import {Dictionary} from "../interfaces/structure";


export interface HocPagination {
    pagination: {
        set: (page: number, ...queries: Dictionary<any>[]) => void;
        get: (page?: number) => Dictionary<any>;
        currentPage: () => number;
    };
}

export interface PaginationProps extends RouterState, RouterProps {
    hoc: any;
}


export const pagination = (paramName: string) => (InnerComponent: any): any => {

    @withRouter
    class Pagination extends React.Component<PaginationProps, {}> {

        public static defaultProps: any = { hoc: {} };

        private hocExtend: HocExtend = createHocExtend();
        private hocPagination: HocPagination = {
            pagination: {
                set: this.set.bind(this),
                get: this.get.bind(this),
                currentPage: this.currentPage.bind(this)
            }
        };

        /**
         * HOC API
         */

        private set(page: number, ...queries: Dictionary<any>[]): void {
            this.props.router!.push(
                fromQueryToURL(
                    this.props.location,
                    this.props.location.query,
                    { [paramName]: page },
                    ...queries
                )
            );
        }

        private get(page?: number): Dictionary<any> {
            const value = _.isUndefined(page) ? this.currentPage() : page;
            return _.isNumber(value) && value === value ? {[paramName]: value} : {}; // check whether number and not NaN
        }

        private currentPage(): number | null {
            const query = this.props.location.query as Dictionary<any>;
            return _.isString(query[paramName]) ? parseInt(query[paramName], 10) : null;
        }

        /**
         * Render
         */

        public render(): JSX.Element {
            return <InnerComponent {...this.props} hoc={this.hocExtend(this.props.hoc, this.hocPagination)} />;
        }
    }

    return hoistStatics(Pagination, InnerComponent);
};
