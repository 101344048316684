import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import Main from "../../../shared/layout/Main";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import {Button} from "../../../shared/button/Button";
import ContentBody from "../../../project/components/content/ContentBody";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {RouterState} from "../../../../../shared/ts/interfaces/router";
import {createFileName, urlBase64, urlToCroppedBase64} from "../../../../../shared/ts/helpers/urlToCroppedBase64";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {Offer} from "../../../shared/models/Offer";
import {fetchOffer, fetchOfferDetailForNatives, resetOfferDetailForNative} from "../../../offer/actions/fetch";
import {INativeCreation, NativeCreationForm} from "../components/NativeCreationForm";
import {
    addMobileNativesFormActions,
    clearAddEditErrors,
    clearAddEditViewState
} from "../../../advertisments/actions/natives/add";
import {nativesFetchDetail} from "../../../advertisments/actions/natives/fetch";
import {nativeEditPatch} from "../../../advertisments/actions/natives/edit";
import {INativesForms} from "../../../advertisments/reducers/natives";
import {NativeCreationAdModal} from "../components/NativeCreationAdModal";
import {saveAsHtml} from "../actions/save_as_html";
import {htmlToImgDownload} from "../actions/html_to_img";
import {CreationType} from "../../CreationType";

interface IStateProps {
    nativesDetail: INativesForms;
    nativesOfferDetail: Offer | null;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    mobileFormActions: FormActions;
    fetchOfferDetailForNatives: (id: number | string) => Promise<any>;
    actions: {
        fetchOffer: typeof fetchOffer;
        nativeEditPatch: typeof nativeEditPatch;
        nativesFetchDetail: typeof nativesFetchDetail;
        clearAddEditErrors: typeof clearAddEditErrors;
        clearAddEditViewState: typeof clearAddEditViewState;
        resetOfferDetailForNative: typeof resetOfferDetailForNative;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
    };
}

interface IOwnProps extends RouterState {
    hoc: HocModalix;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    initialImage: string | Blob; // base64 representation of offer's main image
    initialVendorImage: string | Blob;
}

@modalix(NativeCreationAdModal)
class NativeCreationEditViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            initialImage: "",
            initialVendorImage: ""
        };
    }

    /**
     * Lifecycle
     */

    public async componentDidMount() {
        const creationId = parseInt(this.props.params["id"], 10);
        const creationDetail: INativeCreation = await this.props.actions.nativesFetchDetail(creationId) as any; // ANY: bind action typing problem
        if (creationDetail.offer) {
            await this.props.fetchOfferDetailForNatives(creationDetail.offer);
        }
        this.triggerPopulatingDataToForm(creationDetail);
    }

    public componentWillUnmount(): void {
        this.props.actions.clearAddEditViewState();
        this.props.actions.resetOfferDetailForNative();
    }

    private triggerPopulatingDataToForm = (creation: INativeCreation) => {
        const rawFormValues = NativeCreationForm.fromJSON(creation);
        this.props.mobileFormActions.update(rawFormValues);
        this.prepareBase64Images(creation); // at the very end, because it is async anyway
    };

    // This is hack over `NativeCreationForm.fromJSON(native)` and overwrites previous `mobileFormActions.update` because is async.
    // Unfortunately we upload base64 blob every time we click save, even though we use PATCH (and should update only changes).
    // That enables crop button for every file URL.
    private prepareBase64Images = async (creation: INativeCreation) => {
        const initialMobileImage = this.props.nativesOfferDetail && this.props.nativesOfferDetail.main_image && await urlBase64(this.props.nativesOfferDetail.main_image) as string;
        const mobileImage = creation.mobile_image_native && await urlToCroppedBase64(creation.mobile_image_native, 1125,  633);

        const initialVendorImage = this.props.nativesOfferDetail && this.props.nativesOfferDetail.vendor.logo && await urlBase64(this.props.nativesOfferDetail.vendor.logo) as string;
        const mobileVendorImage = creation.desktop_vendor_logo && await urlToCroppedBase64(creation.desktop_vendor_logo, 80,  60);

        this.setState({initialImage: initialMobileImage, initialVendorImage} as IState);

        Boolean(mobileImage) && this.props.mobileFormActions.update({
            mobile_image_native: {
                ...this.props.nativesDetail.mobileForm.mobile_image_native,
                selected: `main_${createFileName(creation.mobile_name, creation.id, "mobile")}`,
                base64value: mobileImage
            }
        });

        Boolean(mobileVendorImage) && this.props.mobileFormActions.update({
            desktop_vendor_logo: {
                ...this.props.nativesDetail.mobileForm.desktop_vendor_logo,
                selected: `main_${createFileName(`${creation.mobile_name}_vendor`, creation.id, "mobile")}`,
                base64value: mobileVendorImage
            }
        });
    };

    /**
     * Submit form
     */

    private onSubmit = (shouldGenerate: boolean = false, toHtml: boolean = false) => {
        const creation: INativeCreation = {
            ...NativeCreationForm.toJSON(this.props.nativesDetail.mobileForm),
            type: CreationType.NATIVE
        };
        this.handleFormSubmit(creation, shouldGenerate, toHtml);
    };

    private handleFormSubmit = (creation: INativeCreation, shouldGenerate: boolean, toHtml: boolean) => {
        const nativeAdId = parseInt(this.props.params["id"], 10);

        const updatedCreation = {
            ...creation,
            mobile_offer_price_type: 1
        };

        (this.props.actions.nativeEditPatch(nativeAdId, updatedCreation as any) as any) // ANY: actions' types are based on old creation types
            .then((response: INativeCreation) => {
                if (response != null) {
                    if (shouldGenerate) {
                        if(toHtml) {
                            this.handleSaveHtml(response.mobile_name, response.id)
                        } else this.handleConvert(response.mobile_name, response.id);
                    }
                    this.props.actions.clearAddEditErrors();
                }
            });
    };

    private handleConvert = (name: string, id: number) => {
        this.openPreviewModal();
        htmlToImgDownload(document.getElementById("v2-ad")!, name, id);
        // do we want to wait?
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

     private handleSaveHtml = (name: string, id: number) => {
        this.openPreviewModal();
        const element = document.getElementById("v2-ad")!;
        saveAsHtml(element, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    /**
     * Callback
     */

    private openPreviewModal = () => {
        const minPrice = this.props.nativesOfferDetail && this.props.nativesOfferDetail.stats.ranges_price_m2_min || 0;
        const distanceData = this.props.nativesOfferDetail && this.props.nativesOfferDetail.stats.distance_from_region || null;
        this.props.hoc.modalix.show<any>({
            nativeCreationFormData: this.props.nativesDetail.mobileForm,
            distance: distanceData,
            minPrice: minPrice,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    private onImageCropped = (key:  "mobile_image_native" | "desktop_vendor_logo", image?: string) => {
        // similar to mobileForm triggerCrop logic is overly complex for the purpose of (previous and) future possible different size images

        const imgOptions = this.getImgOptions(key, image);
        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.nativesDetail.mobileForm[key],
                ...imgOptions
            }
        });
    };

    private getImgOptions = (key: "mobile_image_native" | "desktop_vendor_logo", image?: string) => {
        if (image) {
            return {
                base64value: image
            }
        }

        return {
            base64value: key === "mobile_image_native" ? this.state.initialImage : this.state.initialVendorImage,
            selected: this.props.nativesOfferDetail ? createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk) : "initial_image"
        };
    }

    /**
     * Render
     */

    public render() {
        const {request} = this.props.nativesDetail;

        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklamy natywne</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd Reklam
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit()}
                            >
                                Zapisz Reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(true)}
                            >
                                Zapisz i Wygeneruj Reklamy
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <NativeCreationForm
                            onSubmit={() => this.onSubmit(false, false)}
                            errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                            values={this.props.nativesDetail.mobileForm}
                            updateFormAction={this.props.mobileFormActions.update}
                            handlePreview={() => this.openPreviewModal()}
                            submitAndGenerate={() => this.onSubmit(true, false)}
                            submitAndGenerateHtml={() => this.onSubmit(true, true)}
                            updateImageAfterCropping={this.onImageCropped}
                            initialImage={this.state.initialImage}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const NativeCreationEditView = connect(mapStateToProps, mapActionsToProps)(NativeCreationEditViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        nativesDetail: store.adverts.natives.detail,
        nativesOfferDetail: store.adverts.natives.nativesOfferDetail,
        contextPanelOpened: store.adverts.natives.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        mobileFormActions: bindActionCreators(addMobileNativesFormActions, dispatch),
        fetchOfferDetailForNatives: fetchOfferDetailForNatives(dispatch),
        actions: bindActionCreators({
            fetchOffer,
            nativeEditPatch,
            nativesFetchDetail,
            openPanel,
            closePanel,
            openTab,
            clearAddEditViewState,
            clearAddEditErrors,
            resetOfferDetailForNative,
        }, dispatch)
    };
}
