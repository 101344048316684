import {addErrorAlert} from "../actions/alert";
import {alertText} from "../constants/alert";
import {
    catch401, catch403, catch404, catch504, catch5xx, catchStalled, catchUnknownError, catchUnknownStatus
} from "./errors";


function isPromise(value: any) {
    if (value !== null && typeof value === "object") {
        return value && typeof value.then === "function";
    }
    return false;
}

export function promiseMiddleware(logOutAction: (dispatch: (action: any) => any, getState?: () => any) => void) {
    return ({dispatch, getState}: any) => (next: any) => (action: any) => {
        if (typeof action !== "function") {
            return next(action);
        }

        const result = action(dispatch, getState);
        if (!isPromise(result)) {
            return result;
        }

        return result
            .then((data: any) => {
                return data;
            })
            .catch(catch401(() => {
                dispatch(logOutAction);
                dispatch(addErrorAlert(
                    alertText.error401Title, alertText.error401Message, false, 5000
                ));
                return null;
            }))
            .catch(catch403(() => {
                // dispatch(enableErrorPage());
                dispatch(addErrorAlert(
                    alertText.error403Title, alertText.error403Message, true, 5000
                ));
            }))
            .catch(catch404((err: any) => {
                safeNotifyBugsnag(err, "Response404");
                // dispatch(enableErrorPage());
                dispatch(addErrorAlert(
                    alertText.error404Title, alertText.error404Message, true, 5000
                ));
            }))
            .catch(catch504((err: any) => {
                safeNotifyBugsnag(err, "Response504");
                dispatch(addErrorAlert(
                    alertText.error504Title, alertText.error504Message, true, 5000
                ));
            }))
            .catch(catch5xx((err: any) => {
                safeNotifyBugsnag(err, "Response5xx");
                // dispatch(enableErrorPage("500", "Wystąpił błąd"));
                dispatch(addErrorAlert(
                    alertText.error500Title, alertText.error500Message, true, 5000
                ));
            }))
            .catch(catchStalled(() => {
                console.warn("stalled response");
            }))
            .catch(catchUnknownStatus((err: any) => {
                safeNotifyBugsnag(err, "ResponseUnknownStatus");
                console.warn("status error");
                dispatch(addErrorAlert(
                    alertText.errorUnknownStatusTitle, alertText.errorUnknownStatusMessage, true, 0
                ));
            }))
            .catch(catchUnknownError((err: any) => {
                safeNotifyBugsnag(err, "ResponseUnknownError");
                console.warn("network error");
                dispatch(addErrorAlert(
                    alertText.errorUnknownErrorTitle, alertText.errorUnknownErrorMessage, true, 0
                ));
            }))
            .catch((err: any) => {
                safeNotifyBugsnag(err, "Catch all - error");
                throw err;
            });
    };
}

/**
 * Helper
K */
function safeNotifyBugsnag(error: any, message: string): void {
    const bugsnagClient = (window as any).Bugsnag;
    if (bugsnagClient && bugsnagClient.notify) {
        bugsnagClient.notify(error, {context: message});
    }
    else {
        console.warn("Bugsnag missing");
    }
}
