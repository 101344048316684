import * as React from "react";
import * as classNames from "classnames";
import {FavouriteIcon} from "./FavouriteIcon";
import {IDesktopNativesFormValues} from "../../reducers/natives";
import {formatIntegerToHaveSpaces} from "../../../../../shared/ts/helpers/formatNumber";
import {getOfferTypeNamePluralWhat, OfferPriceType, OfferType} from "../../../../../shared/ts/constants/OfferType";


interface IProps {
    className?: string;
    id: string;
    formData: IDesktopNativesFormValues;
    minPrice: number;
    distance: {
        center: boolean;
        distance: number;
        region: string;
    };
}

export const NativeDesktopAd = (props: IProps) => {
    const className = classNames("native-ad native-desktop-ad", props.className);
    const offerType = props.formData.desktop_type && parseInt(props.formData.desktop_type.value, 10);
    const offerPriceType = props.formData.desktop_price_type && parseInt(props.formData.desktop_price_type.value, 10);
    const isCommercial = props.formData.desktop_type && parseInt(props.formData.desktop_type.value, 10) === OfferType.COMMERCIAL;
    const isConstructionReady = props.formData.desktop_construction_end_date === "Gotowe do zamieszkania";
    const renderPriceType = () => {
        switch (offerPriceType) {
            case OfferPriceType.OFFER_PRICE_TYPE_NETTO:
                return " netto";
            case OfferPriceType.OFFER_PRICE_TYPE_BRUTTO:
                return " brutto";
            default:
                return "";
        }
    };

    const renderPriceButtons = () => {
        switch (props.formData.desktop_offer_price_type) {
            case 0:
                return <div className="btn-ask-price fwb">Zapytaj o ceny</div>;
            case 1:
                return (props.minPrice ?
                    <div>Ceny od: <strong>{formatIntegerToHaveSpaces(props.minPrice)} zł{renderPriceType()}/m<sup>2</sup></strong></div> :
                    <div className="btn-ask-price fwb">Zapytaj o ceny</div>);
            case 2:
                return <div>Ceny od: <strong>{formatIntegerToHaveSpaces(parseInt(props.formData.desktop_individual_price_m2, 10))} zł{renderPriceType()}/m<sup>2</sup></strong></div>;
            default:
                return null;
        }
    };

    const renderDistanceInfo = () => {
        if (props.distance) {
            const {center, region} = props.distance;

            return `(${props.formData.desktop_distance} km od ${center ? "centrum" : ""} ${props.formData.desktop_distance_region || region})`;
        }

        return `(${props.formData.desktop_distance} km od centrum${props.formData.desktop_distance_region && ` ${props.formData.desktop_distance_region}`})`;
    };

    const renderRangeNumber = (lower: string | number, upper: string | number) => {
        if (lower === upper) {
            return lower;
        } else {
            return `${lower} - ${upper}`;
        }
    };


    return (
        <div className={className} id={props.id}>
            <div className="df fd-row fai-center box-header" style={{height: "52px"}}>
                <div className="fg-1">
                    <h2 className="mt-0 mb-md mr-md fwb fs20 offer-name" style={{height: "22px"}}>
                        {props.formData.desktop_name}
                    </h2>

                    <div className="offer-address">
                        {props.formData.desktop_address}
                        {props.formData.desktop_distance && (<small className="offer-distance"> {renderDistanceInfo()}</small>)}
                    </div>
                </div>

                <div className="fg-0 pl-md lh1-5 fs18">
                    {renderPriceButtons()}
                </div>
            </div>

            <div className="psr mt-md df fd-row">
                <div className="fg-0 mr-lg offer-image psr">
                    <FavouriteIcon />

                    <img src={props.formData.desktop_image.base64value} alt={props.formData.desktop_name} width={336} height={189} />
                </div>

                <div className="fg-1 bdtw-xl bdts-solid df fd-row" style={{borderColor: "#eee"}}>
                    <div className="pt-xl pl-xl ml-md mb-0 fg-1">

                        {isConstructionReady ?
                            <div className="mb-xl"><span className="fwb fs15">{props.formData.desktop_construction_end_date}</span></div> :
                            <div className="mb-xl">Termin oddania: <span className="fwb fs15">{props.formData.desktop_construction_end_date}</span></div>
                        }

                        {offerType && (
                            <div className="mb-xl">Liczba {getOfferTypeNamePluralWhat(offerType)}: <span className="fwb fs15">{props.formData.desktop_properties_count_for_sale}</span></div>
                        )}

                        <div className="mb-xl">Powierzchnia: <span className="fwb fs15">{renderRangeNumber(props.formData.desktop_area.lower, props.formData.desktop_area.upper)} m<sup>2</sup></span></div>

                        {!isCommercial && (<div>Liczba pokoi: <span className="fwb fs15">{renderRangeNumber(props.formData.desktop_ranges_rooms.lower, props.formData.desktop_ranges_rooms.upper)}</span></div>)}
                    </div>

                    <div className="fg-0 pl-md pt-lg mt-xs">
                        <div className="vendor-image">
                            <img src={props.formData.desktop_vendor_logo.base64value} alt={props.formData.desktop_vendor} width={150} height={113} />
                        </div>

                        <div className="btn-check-offer"><span className="psr t-1">Sprawdź ofertę</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
