import withProps from "recompose/withProps";

import AppWrapper from "../views/AppWrapper";
import LoggedInWrapper from "../views/LoggedInWrapper";
import LoggedOutWrapper from "../views/LoggedOutWrapper";

import NotFound from "../views/NotFound";
import Redirect from "../../shared/route/Redirect";
import authRoutes from "../../auth/route/frontend";
import adsRoute from "../../advertisments/route/frontend";
import {OfferGalleryView} from "../..//offer/views/OfferGalleryView";
import {OfferPlanView} from "../../offer_plans/views/OfferPlanView";
import {OfferPlanListView} from "../../offer_plans/views/OfferPlanListView";
import {url} from "../../../../shared/ts/helpers/routing";
import {BuildingPlanView} from "../../offer_plans/views/BuildingPlanView";
import {FloorPlanView} from "../../offer_plans/views/FloorPlanView";
import {EstatePlanView} from "../../offer_plans/views/EstatePlanView";
import {OfferTagsListView} from "../../offer_tags/views/OfferTagsListView";
import {OfferTagsOfferView} from "../../offer_tags/views/OfferTagsOfferView";
import {creationFrontendRoute} from "../../creation/creation_frontend_route";
import {videoFrontendRoute} from "../../video/video_frontend_route";

export const nphAppRoutes = [
    {
        path: "",
        name: "app",
        component: AppWrapper,
        childRoutes: [
            {
                path: "/",
                name: "in",
                component: LoggedInWrapper,
                indexRoute: { // I could not make `onEnter` to work properly
                    component: withProps({path: "/kreacje-reklamowe/"})(Redirect)
                },
                childRoutes: [
                    // smsRoute,
                    adsRoute,
                    creationFrontendRoute,
                    videoFrontendRoute,
                    {path: "tagowanie-zdjec/", name: "pictureTags", childRoutes: [
                        {path: ":offer_id/", name: "offer", component: OfferGalleryView}
                    ]},
                    {path: "plany-inwestycji/", name: "offerPlans",
                        indexRoute: {
                            onEnter: (nextState: any, replace: any) => {
                                replace(
                                    url("app:in:offerPlans:list")
                                );
                            }
                        },
                        childRoutes: [
                            {path: "lista/", name: "list", component: OfferPlanListView},
                            {path: ":offer_id/", name: "offer", component: OfferPlanView},
                            {path: ":offer_id/osiedle/", name: "estate", component: EstatePlanView},
                            {path: ":offer_id/budynek/:building_id/", name: "building", component: BuildingPlanView},
                            {path: ":offer_id/budynek/:building_id/pietro/:floor_id/", name: "floor", component: FloorPlanView}
                        ]
                    },
                    {
                        path: "tagi-inwestycji/", name: "offerTags",
                         indexRoute: {
                            onEnter: (nextState: any, replace: any) => {
                                replace(
                                    url("app:in:offerTags:list")
                                );
                            }
                        },
                        childRoutes: [
                            {path: "lista/", name: "list", component: OfferTagsListView},
                            {path: ":offer_id/", name: "offer", component: OfferTagsOfferView}
                        ]
                    }
                ]
            },
            {
                path: "/",
                name: "out",
                component: LoggedOutWrapper,
                childRoutes: [
                    ...authRoutes
                ]
            }
        ]
    },
    {
        path: "/*",
        name: "notFound",
        component: NotFound,
        onEnter: (nextState: any, replace: any) => {
            const path = nextState.location.pathname;
            if (path.slice(-1) !== "/") {
                replace(`${path}/`);
            }
        }
    }
];
