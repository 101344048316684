import * as React from "react";
import {withRouter} from "react-router";
import * as _ from "lodash";
import * as hoistStatics from "hoist-non-react-statics";

import {createHocExtend, HocExtend, fromQueryToURL} from "./hoc_helpers";
import {RouterProps} from "../helpers/decorators";
import {RouterState} from "../interfaces/router";
import {Dictionary} from "../interfaces/structure";


export enum SortDirection {
    Asc,
    Desc
}

export interface HocSort {
    sort: {
        set: (sortBy: string, ...queries: Dictionary<any>[]) => void;
        get: (sortBy?: string) => Dictionary<any>;
        toggle: (sortBy: string, ...queries: Dictionary<any>[]) => string;
        getName: (sortBy?: string) => string;
        getDirection: (sortBy?: string) => SortDirection;
    };
}

export interface SortProps extends RouterState, RouterProps {
    hoc: any;
}


export const sort = (paramName: string, descPrefix: string = "-") => (InnerComponent: any): any => {

    @withRouter
    class Sort extends React.Component<SortProps, {}> {

        public static defaultProps: any = { hoc: {} };

        private hocExtend: HocExtend = createHocExtend();
        private hocSort: HocSort = {
            sort: {
                set: this.set.bind(this),
                get: this.get.bind(this),
                toggle: this.toggle.bind(this),
                getName: this.getName.bind(this),
                getDirection: this.getDirection.bind(this),
            }
        };

        /**
         * HOC API
         */

        private set(sortBy: string, ...queries: Dictionary<any>[]): void {
            const url = fromQueryToURL(this.props.location, this.props.location.query, {[paramName]: sortBy}, ...queries);
            this.props.router!.push(url);
        }

        private get(sortBy: string | null = null): Dictionary<any> {
            const query = this.props.location.query as Dictionary<any>;
            const value = _.isNull(sortBy) ? query[paramName] : sortBy;
            return _.isString(value) ? { [paramName]: value } : {};
        }

        private toggle(sortBy: string, ...queries: Dictionary<any>[]): string {
            const query = this.props.location.query as Dictionary<any>;
            const sortString = query[paramName] || "";  // when `sort` is not defined
            const desc = sortString[0] === descPrefix;  // is descending sort
            const value = desc ? sortString.slice(descPrefix.length) : sortString;
            // toggle sort or set to given `sortBy`
            const finalSort = sortBy === value ? (desc ? value : descPrefix + value) : sortBy;

            const url = fromQueryToURL(this.props.location, this.props.location.query, {[paramName]: finalSort}, ...queries);
            this.props.router!.push(url);
            return finalSort;
        }

        private getName(sortBy: string | null = null): string {
            const query = this.props.location.query as Dictionary<any>;
            const value = _.isNull(sortBy) ? query[paramName] : sortBy;
            return _.startsWith(value, descPrefix) ? value.slice(descPrefix.length) : value;
        }

        private getDirection(sortBy: string | null = null): SortDirection {
            const query = this.props.location.query as Dictionary<any>;
            const value = _.isNull(sortBy) ? query[paramName] : sortBy;
            return _.startsWith(value, descPrefix) ? SortDirection.Desc : SortDirection.Asc;
        }

        /**
         * Render
         */

        public render(): JSX.Element {
            return <InnerComponent {...this.props} hoc={this.hocExtend(this.props.hoc, this.hocSort)}/>;
        }
    }

    return hoistStatics(Sort, InnerComponent);
};
