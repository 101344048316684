export const getPreparedAdsElements = (type: NativeFormType) => {
    const desktopElement = [{element: document.getElementById("desktop-ad")!, size: "desktop"}];
    const mobileElements = [{element: document.getElementById("mobile-ad")!, size: "mobile"}];
    switch (type) {
        case NativeFormType.DESKTOP: {
            return desktopElement;
        }
        case NativeFormType.MOBILE: {
            return mobileElements;
        }
        case NativeFormType.ALL: {
            return [...mobileElements, ...desktopElement];
        }
        default:
            return [];
    }
};

export const offerPriceTypeOptions = [{
    value: 0,
    label: "Zapytaj o ceny"
}, {
    value: 1,
    label: "Cena jak na portalu"
}, {
    value: 2,
    label: "Cena spersonalizowana"
}];

export const buttonTextOptions = [{
    value: 0,
    label: "Zobacz inwestycję"
}, {
    value: 1,
    label: "Zobacz na stronie dewelopera"
}];

export enum NativeFormType {
    DESKTOP = 1,
    MOBILE = 2,
    ALL = 3
}
