import {Dictionary, isEmpty} from "lodash";
import * as queryString from "query-string";


export const appendQueryString = (currentUrl: string, query: Dictionary<string | string[] | number | number[] | boolean>): string => {
    if (isEmpty(query)) {
        return currentUrl;
    }

    const search = queryString.stringify(query); // query may have undefined values & returns empty string
    if (isEmpty(search)) {
        return currentUrl;
    }

    // append query string to currentUrl
    return currentUrl.indexOf("?") === -1 ? `${currentUrl}?${search}` : `${currentUrl}&${search}`;
};
