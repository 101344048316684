import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";

interface CenterBoxProps extends StyleProps {}


const CenterBoxC: React.SFC<CenterBoxProps> = props => {
    const className = classnames("df fg-1 fs-0 fb-a", props.className);

    return (
        <div className={className}>
            <div className="center-box c-text holder df fd-col fg-1 fs-1 fb-0 fai-center fjc-center m-0a">{props.children}</div>
        </div>
    );
};

const CenterBox = CenterBoxC;
export default CenterBox;
