// General alert text
export const alertText = {
    // Success
    successTitle: "Sukces!",

    // Warning
    warningTitle: "Uwaga",

    // Info
    infoTitle: "Ważna informacja!",

    // Error
    errorTitle: "Mamy problem...",
    errorMessage: "Wystąpił błąd.",

    error400Title: "Błąd w formularzu",
    error400Message: "Proszę poprawić błędy zaznaczone w formularzu.",

    error401Title: "Brak autoryzacji",
    error401Message: "Wykonanie tej czynności wymaga zalogowania do systemu, proszę wrócić do strony głównej i zalogować się ponownie. W przypadku dalszych problemów proszę skontaktować się z administratorem aplikacji.",

    error403Title: "Brak uprawnień",
    error403Message: "Nie masz wystarczających uprawnień aby wykonać tę czynność. Skontaktuj się z Administratorem systemu w twojej firmie.",

    error404Title: "Nie znaleziono adresu",
    error404Message: "Żądana operacja nie została wykonana, w celu uzyskania pomocy proszę skontaktować się z administratorem aplikacji.",

    error500Title: "Błąd serwera",
    error500Message: "Wystąpił błąd podczas przetwarzania operacji, prosimy powiadomić administratora aplikacji o zaistniałej sytuacji.",

    error504Title: "Przekroczono czas oczekiwania",
    error504Message: "Czas oczekiwania na wykonanie operacji został przekroczony, prosimy powiadomić administratora aplikacji o zaistniałej sytuacji.",

    errorUnknownStatusTitle: "Mamy problem...",
    errorUnknownStatusMessage: "Nieoczekiwana odpowiedź serwera, prosimy powiadomić administratora aplikacji o zaistniałej sytuacji.",

    errorUnknownErrorTitle: "Mamy problem...",
    errorUnknownErrorMessage: "Wystąpił błąd podczas przetwarzania danych, prosimy spróbować później.",

    confirmPasswordSuccessText: "Hasło zostało zmienione"
};

export const signInText = {
    // Error
    errorTitle: "Błąd logowania"
};


export const remindPasswordText = {
    successTitle: alertText.successTitle,
    successMessage: (email: string) => `Za chwilę zostanie wysłana wiadomość e-mail na adres ${email}
     z prośbą o utworzenie nowego hasła. Pamiętaj, że takie wiadomości czasami wpadają do folderu SPAM.`
};
