import {fabric} from "fabric";
import {Canvas, ICircleOptions, IEvent, Line, Point, Polygon} from "fabric/fabric-impl";
import {findIndex, each, reduce, map} from "lodash";
import {IFabricPolygon, IFullCircle} from "./init_fabric";


export const initEdit = (canvas: Canvas, points: IFabricPolygon): () => IFabricPolygon => {

    // print the polygon
    let pointArray: IFullCircle[] = map(points, p => {
        const circle = new fabric.Circle(getCircleOptions(p.x, p.y)) as IFullCircle;
        circle.on("moving", onCircleMoving);
        circle.on("moved", onCircleMoved);
        circle.on("mousedblclick", onCircleDoubleClick);
        return circle;
    });
    each(pointArray, p => canvas.add(p));
    let lineArray: Line[] = map(points, (point, pointIdx) => {
        const nextPoint = points[pointIdx + 1 === pointArray.length ? 0 : pointIdx + 1];
        return new fabric.Line([point.x, point.y, nextPoint.x, nextPoint.y], getLineOptions());
    });
    each(lineArray, l => canvas.add(l));
    const activeShape: Polygon = new fabric.Polygon(points, getPolygonOptions());
    canvas.add(activeShape);
    // edit state
    let edgePointArray: IFullCircle[] = [];
    let leftMoveLine: Line | null = null;
    let rightMoveLine: Line | null = null;

    calculateEdgePoints();

    /**
     * Object events
     */
    function onCircleMoving(e: IEvent) {
        const point = e.target as IFullCircle | null;
        if (point == null || activeShape == null) {
            return;
        }

        const pointIdx = findIndex(pointArray, p => p.id === point.id);
        const prevPoint = pointArray[pointIdx === 0 ? pointArray.length - 1 : pointIdx - 1];
        const nextPoint = pointArray[pointIdx + 1 === pointArray.length ? 0 : pointIdx + 1];
        // update left and add right line
        if (leftMoveLine == null || rightMoveLine == null) {
            leftMoveLine = new fabric.Line([prevPoint.left!, prevPoint.top!, point.left!, point.top!], getMoveLineOptions());
            rightMoveLine = new fabric.Line([point.left!, point.top!, nextPoint.left!, nextPoint.top!], getMoveLineOptions());
            canvas.add(leftMoveLine);
            canvas.add(rightMoveLine);
        }
        else {
            leftMoveLine.set({x2: point.left!, y2: point.top!});
            rightMoveLine.set({x1: point.left!, y1: point.top!});
        }
    }

    function onCircleMoved(e: IEvent) {
        const point = e.target as IFullCircle | null;
        if (point == null || activeShape == null) {
            return;
        }

        const pointIdx = findIndex(pointArray, p => p.id === point.id);
        // remove move-lines
        leftMoveLine && canvas.remove(leftMoveLine);
        rightMoveLine && canvas.remove(rightMoveLine);
        leftMoveLine = null;
        rightMoveLine = null;
        // update two lines that touch in given point
        const leftLine = lineArray[pointIdx === 0 ? lineArray.length - 1 : pointIdx - 1];
        const rightLine = lineArray[pointIdx];
        leftLine.set({x2: point.left, y2: point.top});
        rightLine.set({x1: point.left, y1: point.top});
        // update active shape
        const shapePoints = activeShape.get("points") as Point[];
        shapePoints[pointIdx] = {x: point.left, y: point.top} as Point;
        activeShape.set({points: shapePoints});
        // update edge points
        calculateEdgePoints();
    }

    function onCircleDoubleClick(e: IEvent) {
        const point = e.target as IFullCircle | null;
        if (point == null || activeShape == null) {
            return;
        }

        const pointIdx = findIndex(pointArray, p => p.id === point.id);
        // remove point
        canvas.remove(point);
        pointArray = [...pointArray.slice(0, pointIdx), ...pointArray.slice(pointIdx + 1)];
        // combine two lines
        const leftLine = lineArray[pointIdx === 0 ? lineArray.length - 1 : pointIdx - 1];
        const rightLine = lineArray[pointIdx];
        leftLine.set({x2: rightLine.x2, y2: rightLine.y2});
        canvas.remove(rightLine);
        lineArray = [...lineArray.slice(0, pointIdx), ...lineArray.slice(pointIdx + 1)];
        // update active shape
        const shapePoints = activeShape.get("points") as Point[];
        activeShape.set({points: [...shapePoints.slice(0, pointIdx), ...shapePoints.slice(pointIdx + 1)]});
        // update edge points
        calculateEdgePoints();
    }

    function onEdgeCircleMoving(e: IEvent) {
        const edgePoint = e.target as IFullCircle | null;
        if (edgePoint == null || activeShape == null) {
            return;
        }

        const edgePointIdx = findIndex(edgePointArray, p => p.id === edgePoint.id);
        const prevPoint = pointArray[edgePointIdx];
        const nextPoint = pointArray[edgePointIdx + 1 === pointArray.length ? 0 : edgePointIdx + 1];
        // update left and add right line
        if (leftMoveLine == null || rightMoveLine == null) {
            leftMoveLine = new fabric.Line([prevPoint.left!, prevPoint.top!, edgePoint.left!, edgePoint.top!], getMoveLineOptions());
            rightMoveLine = new fabric.Line([edgePoint.left!, edgePoint.top!, nextPoint.left!, nextPoint.top!], getMoveLineOptions());
            canvas.add(leftMoveLine);
            canvas.add(rightMoveLine);
        }
        else {
            leftMoveLine.set({x2: edgePoint.left!, y2: edgePoint.top!});
            rightMoveLine.set({x1: edgePoint.left!, y1: edgePoint.top!});
        }
    }

    function onEdgeCircleMoved(e: IEvent) {
        const edgePoint = e.target as IFullCircle | null;
        if (edgePoint == null || activeShape == null) {
            return;
        }

        const edgePointIdx = findIndex(edgePointArray, p => p.id === edgePoint.id);
        const prevPoint = pointArray[edgePointIdx];
        const nextPoint = pointArray[edgePointIdx + 1 === pointArray.length ? 0 : edgePointIdx + 1];
        // render proper point
        canvas.remove(edgePoint);
        const circle = new fabric.Circle(getCircleOptions(edgePoint.left!, edgePoint.top!)) as IFullCircle;
        circle.on("moving", onCircleMoving);
        circle.on("moved", onCircleMoved);
        circle.on("mousedblclick", onCircleDoubleClick);
        pointArray = [...pointArray.slice(0, edgePointIdx + 1), circle, ...pointArray.slice(edgePointIdx + 1)];
        canvas.add(circle);
        // render proper lines
        leftMoveLine && canvas.remove(leftMoveLine);
        rightMoveLine && canvas.remove(rightMoveLine);
        leftMoveLine = null;
        rightMoveLine = null;
        canvas.remove(lineArray[edgePointIdx]);
        const leftLine = new fabric.Line([prevPoint.left!, prevPoint.top!, edgePoint.left!, edgePoint.top!], getLineOptions());
        const rightLine = new fabric.Line([edgePoint.left!, edgePoint.top!, nextPoint.left!, nextPoint.top!], getLineOptions());
        lineArray = [...lineArray.slice(0, edgePointIdx), leftLine, rightLine, ...lineArray.slice(edgePointIdx + 1)];
        canvas.add(leftLine);
        canvas.add(rightLine);
        // update active shape
        const shapePoints = activeShape.get("points") as Point[];
        activeShape.set({
            points: [...shapePoints.slice(0, edgePointIdx + 1), {x: edgePoint.left, y: edgePoint.top} as Point, ...shapePoints.slice(edgePointIdx + 1)]
        });
        // update edge points
        calculateEdgePoints();
    }

    function calculateEdgePoints() {
        each(edgePointArray, point => canvas.remove(point));
        edgePointArray = reduce(lineArray, (acc, line: Line): IFullCircle[] => {
            const x = (line.x1! + line.x2!) / 2;
            const y = (line.y1! + line.y2!) / 2;
            // render point (circle)
            const circle = new fabric.Circle(getEdgeCircleOptions(x, y)) as IFullCircle;
            circle.on("moving", onEdgeCircleMoving);
            circle.on("moved", onEdgeCircleMoved);
            canvas.add(circle);
            return [...acc, circle];
        }, []);
    }

    function finishEditing(): IFabricPolygon {
        // clear canvas
        each(edgePointArray, point => canvas.remove(point));
        leftMoveLine && canvas.remove(leftMoveLine);
        rightMoveLine && canvas.remove(rightMoveLine);
        activeShape && canvas.remove(activeShape);
        each(pointArray, point => canvas.remove(point));
        each(lineArray, line => canvas.remove(line));
        // pass current state
        return map(pointArray, p => ({x: p.left!, y: p.top!}));
    }

    return finishEditing;
};

/**
 * Helper
 */
export function getCircleOptions(left: number, top: number) {
    return {
        radius: 5,
        fill: "#006",
        stroke: "#009",
        strokeWidth: 0.5,
        left,
        top,
        id: getRandomId(),
        evented: true,      // enables events
        selectable: true,   // enables drag
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        objectCaching: false
    } as ICircleOptions;
}
export function getEdgeCircleOptions(left: number, top: number) {
    return {
        radius: 5,
        fill: "#fff",
        stroke: "#009",
        strokeWidth: 0.5,
        opacity: 0.8,
        left,
        top,
        id: getRandomId(),
        evented: true,      // enables events
        selectable: true,   // enables drag
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        objectCaching: false
    } as ICircleOptions;
}
export function getLineOptions() {
    return {
        strokeWidth: 2,
        stroke: "#00f",
        evented: false,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        objectCaching: false
    };
}
export function getMoveLineOptions() {
    return {
        strokeWidth: 2,
        stroke: "#06f",
        evented: false,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        objectCaching: false
    };
}
export function getPolygonOptions() {
    return {
        stroke: "#0c0",
        strokeWidth: 1,
        fill: "#00f",
        opacity: 0.3,
        evented: false,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        objectCaching: false
    };
}
function getRandomId() {
    const min = 99;
    const max = 999999;
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    return new Date().getTime() + random;
}
