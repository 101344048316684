import {Dispatch} from "redux";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {nphApiLink} from "../../project/route/nph_api_link";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {enableErrorPage} from "../../../../shared/ts/actions/error_page";


export interface ITagGalleryOfferGallery {
    pk: number;
    image: string;
    sort: number;
}
export interface ITagGalleryOffer {
    address: string;
    construction_date_range: {
        lower: string;
        upper: string;
    };
    construction_end_date_quarter: string;
    gallery: ITagGalleryOfferGallery[];
    main_image: string;
    name: string;
    pk: number;
    price_type: null;
    stats: {};
    type: {
        pk: number,
        name_plural: string;
    };
    vendor: {
        name: string;
        pk: number;
        slug: string;
        logo: string;
    };
}

const FETCH_OFFER_FOR_TAG_GALLERY = "offer/fetch-detail/tag-gallery";
export const fetchOfferForTagGalleryTypes = createRequestActionTypes(FETCH_OFFER_FOR_TAG_GALLERY);

export const fetchOfferForTagGallery = (offerId: number) => (dispatch: Dispatch) => {
    dispatch({type: fetchOfferForTagGalleryTypes.start});
    const url = nphApiLink.offers.detail({offerId});
    return getRequest(url)
        .then((offer: ITagGalleryOffer) => {
            dispatch({type: fetchOfferForTagGalleryTypes.success, result: offer});
            return offer;
        })
        .catch(catch404(err => {
            dispatch(enableErrorPage("404"));
        }));

};
