import * as React from "react";
import StyleProps from "../../../../shared/ts/interfaces/style";
import * as classnames from "classnames";

export type LoaderSize = "sm" | "md" | "lg";
export type LoaderColor = "dark" | "light";

interface LoaderProps extends StyleProps {
    size?: LoaderSize;
    color?: LoaderColor;
}

const LoaderC: React.SFC<LoaderProps> = props => {
    const className = classnames(props.className, `loader-${props.size}-${props.color}`);

    return  (
        <div className={className}/>
    )
};

LoaderC.defaultProps = {
    size: "md",
    color: "dark"
};

const Loader = LoaderC;
export default Loader;
