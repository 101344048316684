import * as React from "react";
import {useEffect, useState, useCallback, useMemo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {isFinite, reduce} from "lodash";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import {RouterState} from "../../../../shared/ts/interfaces/router";
import {fetchOfferForTagGallery, ITagGalleryOfferGallery} from "../actions/fetch_offer_for_tag_gallery";
import {Store} from "../../project/reducer";
import {IGalleryTagsState} from "../reducers/offer_reducer";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {OfferGalleryImageList} from "../components/OfferGalleryImageList";
import {patchOfferGalleryOrder} from "../actions/patch_offer_gallery_order";
import {Modal} from "../../../../shared/ts/modals/Modal";
import Loader from "../../shared/loader/Loader";
import CenterBox from "../../shared/layout/CenterBox";
import {PictureTagging} from "../components/picture_tagging/PictureTagging";
import {Button} from "../../shared/button/Button";
import {rpSites} from "../../../../shared/ts/config";


interface IStateProps {
    galleryTagsState: IGalleryTagsState;
}
interface IActionsProps {
    fetchOfferForTagGallery: typeof fetchOfferForTagGallery;
    patchOfferGalleryOrder: typeof patchOfferGalleryOrder;
}
interface IOwnProps {}
interface IProps  extends IStateProps, IActionsProps, IOwnProps, RouterState {}

export const OfferGalleryViewC = (props: IProps) => {

    const {offer, request} = props.galleryTagsState;
    // fetch gallery
    useEffect(() => {
        const offerIdParam = props.params["offer_id"];
        const offerId = parseInt(offerIdParam, 10);
        if (isFinite(offerId)) {
            props.fetchOfferForTagGallery(offerId);
        }
    }, []);
    // patch gallery
    const onGalleryUpdate = useCallback((sortMap: Record<number, number>) => {
        if (offer == null) {
            return;
        }
        props.patchOfferGalleryOrder(offer.pk, sortMap);
    }, [offer]);
    // handle modal state
    const imageMap: Record<number, ITagGalleryOfferGallery> = useMemo(() => {
        if (offer == null) {
            return {};
        }
        return reduce(offer.gallery, (acc, image) => ({...acc, [image.pk]: image}), {});
    }, [offer]);
    const [selectedImagePk, setSelectedImagePk] = useState<number | null>(null);
    const openModalForOffer = useCallback((imagePk: number) => setSelectedImagePk(imagePk), []);
    const closeModal = useCallback(() => setSelectedImagePk(null), []);
    const currentImage = selectedImagePk != null ? (imageMap[selectedImagePk] || null) : null;

    return (
        <Main className="overflow-a">
            <Content>
                <ContentHeader>
                    <ContentHeaderTitle>Tagowanie zdjęć</ContentHeaderTitle>
                </ContentHeader>

                <div className="p-xl">
                    {request === RequestState.Success && offer ? (
                        <>
                            <h1>{offer.name}</h1>

                            <DndProvider backend={HTML5Backend}>
                                <OfferGalleryImageList
                                    gallery={offer.gallery}
                                    onGalleryUpdate={onGalleryUpdate}
                                    onTagButtonClick={openModalForOffer}
                                />
                            </DndProvider>
                            <Modal
                                modalState={selectedImagePk != null}
                                onModalClose={closeModal}
                                className="gallery-tags-modal"
                                header={
                                    <div>
                                        <p className="fs28 mb-sm lh1-1">
                                            {offer.name}
                                        </p>

                                        <div className="fs14 mb-0">
                                            Mieszkania na sprzedaż oferowane bezpośrednio przez

                                            <a href={`${getDomain()}/deweloperzy/${offer.vendor.slug}-${offer.vendor.pk}/`} target="_blank">
                                                <Button type="button" color="link" className="ph-sm pv-0">
                                                    {offer.vendor.name}
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                }
                            >
                                {currentImage && <PictureTagging offerId={offer.pk} image={currentImage}/>}
                            </Modal>
                        </>
                    ) : (
                        <CenterBox className="vhc"><Loader size="lg" /></CenterBox>
                    )}
                </div>
            </Content>
        </Main>
    );
};

export const OfferGalleryView = connect(mapStateToProps, mapActionsToProps)(OfferGalleryViewC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        galleryTagsState: state.offer.detail.galleryTags
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return bindActionCreators({
        fetchOfferForTagGallery,
        patchOfferGalleryOrder
    }, dispatch);
}

function getDomain() {
    const developmentDomain = rpSites.development.desktop;
    const stagingDomain = rpSites.staging.desktop;
    const productionDomain = rpSites.production.desktop;

    const env = process as any  && process.env;

    if (env.RUN_ENV === "production") {
        return productionDomain;
    } else if (env.RUN_ENV === "staging") {
        return stagingDomain;
    } else {
        return developmentDomain;
    }
}
