import {assign} from "../../../../shared/ts/helpers/util";
import {CAMPAIGN_NOTIFICATIONS_CLEAR_ERRORS, CAMPAIGN_NOTIFICATIONS_UPDATE_ERRORS} from "../actions/validate";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";


export interface CampaignFormNotificationsState {
    errors: Dictionary<string>;
}

const initialState = {
    errors: {}
};


export function campaignFormNotifications(state: CampaignFormNotificationsState = initialState, action: any): CampaignFormNotificationsState {
    switch (action.type) {
        case CAMPAIGN_NOTIFICATIONS_UPDATE_ERRORS:
            return assign(state, { errors: action.errors });
        case CAMPAIGN_NOTIFICATIONS_CLEAR_ERRORS:
            return assign(state, { errors: [] });
        default:
            return state;
    }
}
