import {map} from "lodash";
import * as html2canvas from "html2canvas";
import { saveAs } from "file-saver";

import {RequestActionTypes, createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, Action} from "../../../../../shared/ts/interfaces/dispatch";
import {createFileName} from "../../../../../shared/ts/helpers/urlToCroppedBase64";

/**
 * Factories
 */

export interface INativeFileNameData {
    name: string;
    id: number;
}

interface IDomElement {
    element: any;
    size: string;
}

const createConverCanvasToJpg = (type: string, actionTypes: RequestActionTypes) => (domElementsToSave: IDomElement[], nativeData: INativeFileNameData) => (dispatch: Dispatch) => {
    dispatch<Action>({type: actionTypes.start});
    const {name, id} = nativeData;
    return map(domElementsToSave, (item: IDomElement) => {
        return html2canvas(item.element, {scale: 1}).then((canvas: HTMLCanvasElement) => {
            const fullQualityImage = canvas.toDataURL("image/jpeg", .96);
            const fileName = createFileName(name, id, item.size);
            saveAs(fullQualityImage, fileName);
            document.body.appendChild(canvas);
        });
    });
};

/**
 * List fetch
 */

export const ADVERTS_CONVERT = "adverts/CONVERT";
export const advertsConvertRequest = createRequestActionTypes(ADVERTS_CONVERT);
export const advertsConvert = createConverCanvasToJpg(ADVERTS_CONVERT, advertsConvertRequest);
