import * as _ from "lodash";

import {assign} from "../helpers/util";
import {generateQueryString} from "../helpers/routing";
import {Location} from "../interfaces/router";


/**
 * Extend HOC prop, memoize references
 */

export interface HocExtend {
    (current: any, additional: any): any;
}

export const createHocExtend = (): HocExtend => {
    let prevHOC = null as any;
    let result = null as any;

    return (current: any, additional: any): any => {
        if (current !== prevHOC) {
            prevHOC = current;
            result = assign(current, additional);
        }
        return result;
    };
};

/**
 * Generate URL based on current path and queries
 */

export const fromQueryToURL = (location: {pathname: string, hash?: string}, ...queries: _.Dictionary<any>[]): string => {
    const queryObj = _.reduce(queries, (acc, query) => _.assign(acc, query), {});
    const link = location.pathname + generateQueryString(queryObj);
    return location.hash ? link + location.hash : link;
};
