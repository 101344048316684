import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../../shared/ts/interfaces/style";

interface ContentBodyProps extends StyleProps {}


export const ContentBodyC: React.SFC<ContentBodyProps> = props => {
    const className = classnames(props.className, "overflow-y-a pt-xxxl pb-0 ph-xxxl");
    return <div className={className}>{props.children}</div>;
};


const ContentBody = ContentBodyC;
export default ContentBody;
