import * as React from "react";
import * as classNames from "classnames";
import {FavouriteIcon} from "./FavouriteIcon";
import {IMobileNativesFormValues} from "../../reducers/natives";
import {formatIntegerToHaveSpaces} from "../../../../../shared/ts/helpers/formatNumber";
import {OfferType} from "../../../../../shared/ts/constants/OfferType";
import {pluralize} from "../../../../../shared/ts/helpers/pluralize";

interface IProps {
    className?: string;
    id: string;
    formData: IMobileNativesFormValues;
    minPrice: number;
    image: string;
}

export const NativeMobileAd = (props: IProps) => {
    const className = classNames(`native-ad native-mobile-ad bgc-white`, props.className);
    const offerType = props.formData.mobile_type && parseInt(props.formData.mobile_type.value, 10);
    const propertiesCount = parseInt(props.formData.mobile_properties_count_for_sale, 10);
    const isConstructionReady = props.formData.mobile_construction_end_date === "Gotowe do zamieszkania";

    const renderPriceButtons = () => {
        switch (props.formData.mobile_offer_price_type) {
            case 0:
                return <div className="btn-ask-price fwb"><span className="psr t-1">Zapytaj o ceny</span></div>;
            case 1:
                return (props.minPrice ?
                    <div><span className="fs12 fwm">od:</span> <strong>{props.minPrice} zł/m<sup>2</sup></strong></div> :
                    <div className="btn-ask-price fwb">Zapytaj o ceny</div>);
            case 2:
                return <div><span className="fs12 fwm">od:</span> <strong>{formatIntegerToHaveSpaces(parseInt(props.formData.mobile_individual_price_m2, 10))} zł/m<sup>2</sup></strong></div>;
            default:
                return null;
        }
    };

    const offerTypePluralize = (amount: number, offerTypeNumber: number) => {
        switch (offerTypeNumber) {
            case OfferType.FLAT: {
                return pluralize(amount, ["mieszkanie", "mieszkania", "mieszkań"]);
            }
            case OfferType.HOUSE: {
                return pluralize(amount, ["dom", "domy", "domów"]);
            }
            case OfferType.COMMERCIAL: {
                return pluralize(amount, ["lok. użyt.", "lok. użyt.", "lok. użyt."]);
            }
            default: {
                break;
            }
        }
    };

    const renderRangeNumber = (lower: string | number, upper: string | number) => {
        if (lower === upper) {
            return lower;
        } else {
            return `${lower} - ${upper}`;
        }
    };

    return (
        <div className={className} id={props.id}>
            <FavouriteIcon />

            <div className="offer-box-img-holder psr" style={{height: "211px"}}>
                <img src={props.image} alt={props.formData.mobile_name} width={375} height={211}/>

                <div className="psa b20 l10 lh2-5 fwb bgc-white offer-box-price">
                    {renderPriceButtons()}
                </div>
            </div>

            <div className="offer-box-info-holder">
                <h2 className="offer-box-info-header mt-0 truncate mb-md lh1-3">{props.formData.mobile_name}</h2>

                <div className="df fai-center mb-md">
                    <div className="mr-sm df fd-row fai-center">
                        <svg width={22} height={25} viewBox="0 0 17 21">
                            <path fill="#ccc" d="M8.61,0A8.64,8.64,0,0,0,0,8.61a9.24,9.24,0,0,0,2.72,6.26l5.89,6.19,6.06-6.34a9.25,9.25,0,0,0,2.55-6.11A8.64,8.64,0,0,0,8.61,0Zm0,11.69h0a3.29,3.29,0,0,1,0-6.57h0a3.29,3.29,0,1,1,0,6.57Z"/>
                        </svg>
                    </div>

                    <div className="offer-box-address-holder truncate w90 fs14">
                        <p className="mobile-ad-gray mb-0 truncate">{props.formData.mobile_region}</p>

                        <p className="mobile-ad-gray mb-0 truncate">{props.formData.mobile_address}</p>
                    </div>
                </div>

                <div className="df fd-row fjc-sb offer-box-properties-info fwm">
                    <div className="tac">
                        <p className="p-sm info-badge mb-sm nowrap bdw-sm bds-solid bdc-transparent fs14">
                            <div className="psr t-1">{isConstructionReady ? "gotowe" : props.formData.mobile_construction_end_date}</div>
                        </p>

                        <p className="mobile-ad-gray info-badge-label ttc fs12">Oddanie</p>
                    </div>

                    <div className="tac">
                        <p className="p-sm info-badge mb-sm nowrap bdw-sm bds-solid bdc-transparent fs14">
                            <div className="psr t-1">{props.formData.mobile_properties_count_for_sale}</div>
                        </p>

                        <p className="mobile-ad-gray info-badge-label ttc fs12">
                            {offerTypePluralize(propertiesCount, offerType)}
                        </p>
                    </div>

                    <div className="tac">
                        <p className="p-sm info-badge mb-sm nowrap bdw-sm bds-solid bdc-transparent fs14">
                            <div className="psr t-1">{renderRangeNumber(props.formData.mobile_ranges_rooms.lower, props.formData.mobile_ranges_rooms.upper)}</div>
                        </p>

                        <p className="mobile-ad-gray info-badge-label ttc fs12">Pokoje</p>
                    </div>

                    <div className="tac">
                        <p className="p-sm info-badge mb-sm nowrap bdw-sm bds-solid bdc-transparent fs14">
                            <div className="psr t-1">{renderRangeNumber(props.formData.mobile_area.lower, props.formData.mobile_area.upper)}<span>m<sup>2</sup></span></div>
                        </p>

                        <p className="mobile-ad-gray info-badge-label ttc fs12">Powierzchnia</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
