import * as React from "react";

import TableCell from "../../shared/table/TableCell";
import {Button} from "../../shared/button/Button";
import Icon from "../../shared/icon/Icon";
import TableRow from "../../shared/table/TableRow";
import {HocModalix, modalix} from "../../../../shared/ts/decorators/modalix";
import {DateFormat, DateFormatType} from "../../../../shared/ts/components/datetime";
import {RemoveModal} from "../../shared/modal/RemoveModal";


interface IOwnProps {
    className?: string;
    title: string;
    createDate: string;
    createdBy: string;
    id: number;
    region?: {short_name: string};
    displayDateRange?: {lower: string; upper: string};
    onDeleteClick: (id: number) => void;
    onClick: (id: number) => void;
}
interface IProps extends IOwnProps {
    hoc: HocModalix;
}

interface IState {}

@modalix(RemoveModal)
class ReelListItemC extends React.Component<IProps, IState> {

    private onDeleteClick = (e: React.MouseEvent, reel: {id: number; title: string}) => {
        e.stopPropagation();
        this.props.hoc.modalix.show<any>({
            modalTitle: "Usunięcie reel",
            modalText: `Czy na pewno chcesz usunąć "${reel.title} (ID: ${reel.id})"?`,
            onConfirm: () => {
                this.props.onDeleteClick(reel.id);
            },
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    public render() {
        const {id, title,  createDate, createdBy, displayDateRange, region} = this.props;
        const displayDateRangeInfo = displayDateRange ? `${displayDateRange.lower ? `od ${displayDateRange.lower}` : ""} ${displayDateRange.upper ? `do ${displayDateRange.upper}` : ""}` : "Bez ograniczeń";
        const regionInfo = region ? region.short_name : "Bez ograniczeń";

        return (
            <TableRow className="curp" onClick={() => this.props.onClick(this.props.id)}>
                <TableCell className="vam">
                    {id}
                </TableCell>
                <TableCell className="vam">
                    {title}
                </TableCell>

                <TableCell className="vam">
                    <DateFormat date={createDate} format={DateFormatType.shortDate} />
                </TableCell>

                <TableCell className="vam">
                    {createdBy}
                </TableCell>

                <TableCell className="vam">
                    {displayDateRangeInfo}
                </TableCell>

                <TableCell className="vam">
                    {regionInfo}
                </TableCell>

                <TableCell className="vam tac">
                    <Button color="danger" size="sm" onClick={(e: React.MouseEvent) => this.onDeleteClick(e, {id, title})}><Icon icon="delete" /></Button>
                </TableCell>
            </TableRow>
        );
    }
}

export const ReelListItem = ReelListItemC as React.ComponentClass<IOwnProps>;
