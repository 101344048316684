import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch} from "redux";
import {nphApiLink} from "../../project/route/nph_api_link";
import {patchRequest} from "../../../../shared/ts/helpers/request_wrapper";


const PATCH_PROPERTY_PLAN = "offer-building-floor-property/PATCH_PROPERTY_PLAN";
export const patchPropertyPlanTypes = createRequestActionTypes(PATCH_PROPERTY_PLAN);

export const patchPropertyPlan = (propertyId: number, data: {}) => async (dispatch: Dispatch) => {
    dispatch({type: patchPropertyPlanTypes.start});

    const url = nphApiLink.properties.detail({propertyId});

    return patchRequest(url, data)
        .then((res) => {
            dispatch({type: patchPropertyPlanTypes.success, result: res});
            return res;
        });
};
