import {Dispatch} from "redux";
import {deleteRequest, patchRequest, postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {nphApiLink} from "../../project/route/nph_api_link";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";


export enum PlanPolygonPropertyStatus {
    SOLD = 1,
    UNAVAILABLE = 2
}

export interface IGroupPlanPolygon {
    name: string;
    id: number;
    polygon: [string, string][];
}

export interface IBuildingPlanPolygon {
    name: string;
    id: number;
    polygon: [string, string][];
    property_count: number;
}

export interface IFloorPlanPolygon {
    area: number | null;
    name: string;
    id: number;
    polygon: [string, string][];
    rooms: number | null;
    status: number;
}

export type IPlanPolygon = {
    group: number;
    polygon: [string, string][];
    name: string;
} | {
    building: number;
    polygon: [string, string][];
    name: string;
    property_count: number;
} | {
    floor: number;
    polygon: [string, string][];
    name: string;
    rooms: number;
    area: number;
    status: PlanPolygonPropertyStatus;
};

// WARNING: those actions apply to three different Store data: vendor-group-detail, building-detail and floor-detail
// we could differentiate them by some special param in success actions, but we bet that never two of them are in Store at once

const CREATE_PLAN_POLYGON = "plan-polygon/CREATE_PLAN_POLYGON";
export const createPlanPolygonTypes = createRequestActionTypes(CREATE_PLAN_POLYGON);
export const createPlanPolygon = (planPolygon: IPlanPolygon) => (dispatch: Dispatch) =>  {
    dispatch({type: createPlanPolygonTypes.start});
    const url = nphApiLink.planPolygons.list();
    return postRequest(url, planPolygon)
        .then(res => {
            dispatch({type: createPlanPolygonTypes.success, result: res});
            return res;
        });
};

const UPDATE_PLAN_POLYGON = "plan-polygon/UPDATE_PLAN_POLYGON";
export const updatePlanPolygonTypes = createRequestActionTypes(UPDATE_PLAN_POLYGON);
export const updatePlanPolygon = (planPolygonId: number, planPolygon: Partial<IPlanPolygon>) => (dispatch: Dispatch) => {
    dispatch({type: updatePlanPolygonTypes.start});
    const url = nphApiLink.planPolygons.detail({planPolygonId});
    return patchRequest(url, planPolygon)
        .then(res => {
            dispatch({type: updatePlanPolygonTypes.success, result: res});
            return res;
        });
};

const DELETE_PLAN_POLYGON = "plan-polygon/DELETE_PLAN_POLYGON";
export const deletePlanPolygonTypes = createRequestActionTypes(DELETE_PLAN_POLYGON);
export const deletePlanPolygon = (planPolygonId: number) => (dispatch: Dispatch) =>  {
    dispatch({type: deletePlanPolygonTypes.start});
    const url = nphApiLink.planPolygons.detail({planPolygonId});
    return deleteRequest(url)
        .then(res => {
            dispatch({type: deletePlanPolygonTypes.success, result: res, id: planPolygonId});
            return res;
        });
};
