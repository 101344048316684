import * as React from "react";
import * as _ from "lodash";
import * as classnames from "classnames";
import StyleProps from "../../interfaces/style";

interface TabsContent extends StyleProps {
    openedTabIndex: number;
}


export const TabsContent: React.SFC<TabsContent> = props => {
    const className = classnames(props.className, "psr df fd-col fg-0 fs-1 fb-a span-df-no-overflow");
    let renderContent: React.ReactNode | null = null;

    if (_.isArray(props.children)) {
        renderContent = props.children[props.openedTabIndex - 1] as JSX.Element;
    } else if (props.children) {
        renderContent = props.children;
    }

    return (
        <div className={className}>
            {renderContent}
        </div>
    );
};

