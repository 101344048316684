import {Dispatch} from "redux";
import {nphApiLink} from "../../project/route/nph_api_link";
import {postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {IVendorGroup} from "./fetch_vendor_group_list";


const POST_VENDOR_GROUP = "vendor_group/post";
export const postVendorGroupTypes = createRequestActionTypes(POST_VENDOR_GROUP);

export const postVendorGroup = (offerId: number, vendorId: number) => async (dispatch: Dispatch): Promise<IVendorGroup> => {
    dispatch({type: postVendorGroupTypes.start});

    const data = {
        vendor: vendorId,
        offers: [offerId]
    };

    const url = nphApiLink.vendors.groupList({vendorId: vendorId});
    return postRequest(url, data)
        .then((res: IVendorGroup) => {
            dispatch({type: postVendorGroupTypes.success, result: res});
            return res;
        });
};
