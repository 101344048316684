import * as React from "react";
import * as qs from "qs";

import {CreationType} from "../../CreationType";
import {creationPreviewLinks} from "../../creation_preview_path";
import {ISurpriseFormValues} from "../../../advertisments/reducers/surprise";

interface IProps {
    surpriseAdCreationData: ISurpriseFormValues;
}

interface ISurpriseAdCreationData {
    id: number;
    description: string;
    title: string;
    image: {
        m_img_375x484: string;
        m_img_321x200: string;
        m_img_375x211: string;
    } | null;
    logo: {
        v_log_80x60: string;
    };
    type: CreationType.SURPRISE;
    name: string;
}

export function SurpriseCreationAdPreview(props: IProps) {
    const data: ISurpriseAdCreationData = {
        ...props.surpriseAdCreationData,
        id: 123,
        type: CreationType.SURPRISE,
        image: {
            m_img_375x484: props.surpriseAdCreationData.image.stored,
            m_img_321x200: props.surpriseAdCreationData.image.stored,
            m_img_375x211: props.surpriseAdCreationData.image.stored
        },
        logo: {
            v_log_80x60: props.surpriseAdCreationData.logo.stored
        }
    }

    const query = qs.stringify(data)

    const previewUrl = `${creationPreviewLinks.surpriseCreation()}?${query}`;

    return <iframe frameBorder={0} src={previewUrl} width="100%" height="600px"></iframe>;
}
