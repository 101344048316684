import {IReelFormValues} from "../reducers/reels";

export const transformReelsFormToFormData = (form: IReelFormValues): FormData => {
    return Object.entries(form).reduce((formData, [key, value]) => {
        if (isDateTimeRange(value)) {
            if (value.lower) {
                formData.append(`${key}.lower`, value.lower);
            }
            if (value.upper) {
                formData.append(`${key}.upper`, value.upper);
            }
        } else {
            formData.append(key, value);
        }
        return formData;
    }, new FormData());
}

const isDateTimeRange = (value: any): value is {lower: string, upper: string} => {
    return typeof value === "object" && !!value && ("lower" in value || "upper" in value);
}
