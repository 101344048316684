import * as React from "react";
import {Field} from "redux-form";
import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {SelectProps, SelectValue} from "../../../../../../shared/ts/components/forms/select";
import SelectField from "./SelectField";

export interface SelectOption {
    value: string | number;
    label: string;
}

interface CommonSelectProps extends StyleProps {
    label?: string;
    onValueUpdate?: (name: string) => void;
    groupClassName?: string;
    menuUp?: boolean;
    fetchData?: (ids: number[]) => Promise<any>; // repeated in `SelectField`
    hideLabel?: boolean;
}

export const Select = (props: SelectProps<SelectValue> & CommonSelectProps) => {
    return <Field {...props} component={SelectField} async={false} creatable={false}/>;
};

export const SelectAsync = (props: SelectProps<SelectValue> & CommonSelectProps) =>
    <Field {...props} component={SelectField} async={true} creatable={false}/>;

export const SelectCreatable = (props: SelectProps<SelectValue> & CommonSelectProps) =>
    <Field {...props} component={SelectField} async={false} creatable={true}/>;

export const SelectAsyncCreatable = (props: SelectProps<SelectValue> & CommonSelectProps) =>
    <Field {...props} component={SelectField} async={true} creatable={true}/>;
