import * as React from "react";
import {includes, reduce} from "lodash";
import StyleProps from "../../../../../shared/ts/interfaces/style";
import {Tab} from "../../../../../shared/ts/components/tabs/Tab";
import {AppError} from "../../../../../shared/ts/helpers/util";


interface TabProps extends StyleProps {
    onTabOpen?: (tabId: number) => void;
    openedTabIndex: number;
    errors: AppError | null;
}

interface IErrors {
    mobile: number;
    desktop: number;
}


export const NativesTypeTabs = (props: TabProps) => {
    const findErrors = () => {
        if (props.errors != null) {
            return reduce(props.errors.fieldErrors, (acc: IErrors, value, key) => {
                if (includes(key, "mobile")) {
                    return {...acc, "mobile": acc.mobile + 1};
                }
                if (includes(key, "desktop")) {
                    return {...acc, "desktop": acc.desktop + 1};
                }
            }, {
                mobile: 0,
                desktop: 0
            });
        }
    };
    const foundErrors = findErrors();
    return (
        <ul className="lsn pl-0 df df-row mb-xxxl bdbw-sm bdbs-solid bdbc-black-o10 tab-content-switcher">
            <Tab id={1} onTabOpen={props.onTabOpen} active={props.openedTabIndex === 1} className="ph-xxxl pv-lg curp tac psr">
                <div style={{minWidth: "60px"}}>Desktop</div>

                {foundErrors && !!foundErrors.desktop && (
                    <div className="psa t0 r0 bgc-danger c-white lh1 df fai-center fjc-center p-sm bdrs-50p fs11 fwb" style={{width: "24px", height: "24px"}}>
                        {foundErrors.desktop}
                    </div>
                )}
            </Tab>

            <Tab id={2} onTabOpen={props.onTabOpen} active={props.openedTabIndex === 2} className="ph-xxxl pv-lg curp tac psr">
                <div style={{minWidth: "60px"}}>Mobile</div>

                {foundErrors && !!foundErrors.mobile && (
                    <div className="psa t0 r0 bgc-danger c-white lh1 df fai-center fjc-center p-sm bdrs-50p fs11 fwb" style={{width: "24px", height: "24px"}}>
                        {foundErrors.mobile}
                    </div>
                )}
            </Tab>
        </ul>
    );
};

