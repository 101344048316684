import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";
import TableColor from "./table_color";

interface TableCellProps extends StyleProps {
    color?: TableColor;
}

const TableCell: React.SFC<TableCellProps> = props => {
    const className = classnames(props.color, props.className);

    return <td className={className}>{props.children}</td>;
};

export default TableCell;
