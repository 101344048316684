import * as React from "react";
import {Dispatch} from "redux";

import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {guid} from "../../../../shared/ts/helpers/utils";

import {AlertData} from "./reducer";
import ErrorAlert from "./components/ErrorAlert";
import InfoAlert from "./components/InfoAlert";
import SuccessAlert from "./components/SuccessAlert";
import WarningAlert from "./components/WarningAlert";
import {Store} from "../../project/reducer";


const ALERT_REMAIN_TIME = 5000;


/**
 * remove
 */
export const REMOVE_ALERT = "alerts/REMOVE_ALERT";
export interface RemoveAlertAction extends Action {
    id: string;
}
export const removeAlert = (id: string): RemoveAlertAction => ({
    type: REMOVE_ALERT,
    id
});

/**
 * add
 */
export const ADD_ALERT = "alerts/ADD_ALERT";
export interface AddAlertAction extends Action {
    alert: AlertData;
}
export const addAlert = (id: string, content: React.ReactNode, origin: string | null = null, remain: boolean = false, time: number = 0): AddAlertAction => ({
    type: ADD_ALERT,
    alert: {id, content, origin, remain, time}
});

export const addErrorAlert = (children: React.ReactNode, origin: string | null = null, remain: boolean = false, time: number = ALERT_REMAIN_TIME) => (dispatch: Dispatch) => {
    const id = guid();
    const onCloseClick = () => dispatch(removeAlert(id));
    const content = <ErrorAlert onCloseClick={onCloseClick}>{children}</ErrorAlert>;
    return dispatch(addAlert(id, content, origin, remain, time));
};

export const addInfoAlert = (children: React.ReactNode, origin: string | null = null, remain: boolean = false, time: number = ALERT_REMAIN_TIME) => (dispatch: Dispatch) => {
    const id = guid();
    const onCloseClick = () => dispatch(removeAlert(id));
    const content = <InfoAlert onCloseClick={onCloseClick}>{children}</InfoAlert>;
    return dispatch(addAlert(id, content, origin, remain, time));
};

export const addSuccessAlert = (children: React.ReactNode, origin: string | null = null, remain: boolean = false, time: number = ALERT_REMAIN_TIME) => (dispatch: Dispatch) => {
    const id = guid();
    const onCloseClick = () => dispatch(removeAlert(id));
    const content = <SuccessAlert onCloseClick={onCloseClick}>{children}</SuccessAlert>;
    return dispatch(addAlert(id, content, origin, remain, time));
};

export const addWarningAlert = (children: React.ReactNode, origin: string | null = null, remain: boolean = false, time: number = ALERT_REMAIN_TIME) => (dispatch: Dispatch) => {
    const id = guid();
    const onCloseClick = () => dispatch(removeAlert(id));
    const content = <WarningAlert onCloseClick={onCloseClick}>{children}</WarningAlert>;
    return dispatch(addAlert(id, content, origin, remain, time));
};

/**
 * refresh
 */
export const REFRESH_ALERT = "alerts/REFRESH_ALERT";
export const refreshAlerts = (): Action => ({
    type: REFRESH_ALERT
});
