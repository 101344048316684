import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Main from "../../../shared/layout/Main";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import ContentBody from "../../../project/components/content/ContentBody";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {Button} from "../../../shared/button/Button";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {RouterState} from "../../../../../shared/ts/interfaces/router";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {CreationType} from "../../CreationType";
import {htmlToImgDownload} from "../../native/actions/html_to_img";
import {saveAsHtml} from "../../native/actions/save_as_html";
import {createFileName, urlToCroppedBase64} from "../../../../../shared/ts/helpers/urlToCroppedBase64";
import {ITdaForms} from "../../../advertisments/reducers/tda";
import {tdaEditPatch} from "../../../advertisments/actions/tda/edit";
import {tdaFetchDetail} from "../../../advertisments/actions/tda/fetch";
import {addTdaFormActions, clearTdaAddEditErrors, clearTdaAddEditViewState} from "../../../advertisments/actions/tda/add";
import {TdaAdCreationAdModal} from "../components/TdaAdCreationAdModal";
import {ITdaAdCreation, TdaAdCreationForm} from "../components/TdaAdCreationForm";


interface IStateProps {
    advertDetail: ITdaForms;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    mobileFormActions: FormActions;
    actions: {
        tdaEditPatch: typeof tdaEditPatch;
        tdaFetchDetail: typeof tdaFetchDetail;
        clearTdaAddEditErrors: typeof clearTdaAddEditErrors;
        clearTdaAddEditViewState: typeof  clearTdaAddEditViewState;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
    };
}

interface IOwnProps extends RouterState {
    hoc: HocModalix;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    initialImage: string | Blob;
}

@modalix(TdaAdCreationAdModal)
class TdaAdCreationEditViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            initialImage: ""
        };
    }

    public async componentDidMount() {
        const creationId = parseInt(this.props.params["id"], 10);
        const creationDetail: ITdaAdCreation = await this.props.actions.tdaFetchDetail(creationId) as any;
        this.triggerPopulatingDataToForm(creationDetail);
    }

    private triggerPopulatingDataToForm = (creation: ITdaAdCreation) => {
        const rawFormValues = TdaAdCreationForm.fromJSON(creation);
        this.props.mobileFormActions.update(rawFormValues);
        this.prepareBase64Images(creation);
    };

    private prepareBase64Images = async (creation: ITdaAdCreation) => {
        const desktopImage = creation.desktop_image && await urlToCroppedBase64(creation.desktop_image, 1100,  220);
        const mobileImage = creation.mobile_image && await urlToCroppedBase64(creation.mobile_image, 375,  484);

        const hasDesktopValues = desktopImage;
        hasDesktopValues && this.props.mobileFormActions.update({
            desktop_image: {
                ...this.props.advertDetail.form.desktop_image,
                selected: `main_${createFileName(creation.desktop_image, creation.id, "desktop")}`,
                base64value: desktopImage
            }
        });

        const hasMobileValues = mobileImage;
        hasMobileValues && this.props.mobileFormActions.update({
            mobile_image: {
                ...this.props.advertDetail.form.mobile_image,
                selected: `main_${createFileName(creation.mobile_image, creation.id, "mobile")}`,
                base64value: mobileImage
            }
        });
    };

    public componentWillUnmount(): void {
        this.props.actions.clearTdaAddEditViewState();
    }

    /**
     * Callback
    */

    private openPreviewModal = () => {
        this.props.hoc.modalix.show<any>({
            imageAdCreationFormData: this.props.advertDetail.form,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    private onImageCropped = (key: "mobile_image" | "desktop_image", image?: string) => {
        const imgOptions = this.getImgOptions(key, image);

        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.advertDetail.form[key],
                ...imgOptions
            }
        });
    };

    private getImgOptions = (key: "mobile_image" | "desktop_image", image?: string) => {
        if (image) {
            return {
                base64value: image
            }
        }
    }

    /**
     * Submit form
     */

    private onSubmit = (shouldGenerate: boolean = false, toHtml: boolean = false) => {
        const creation: ITdaAdCreation = {
            ...TdaAdCreationForm.toJSON(this.props.advertDetail.form),
        };

        if (this.props.advertDetail.form.desktop_image.base64value === "") {
           delete creation.desktop_image;
        }

        if (this.props.advertDetail.form.mobile_image.base64value === "") {
            delete creation.mobile_image;
        }

        this.handleFormSubmit(creation, shouldGenerate, toHtml);
    };

    private handleFormSubmit = (creation: ITdaAdCreation, shouldGenerate: boolean, toHtml: boolean) => {
        const tdaAdId = parseInt(this.props.params["id"], 10);

        const updatedCreation = {
            ...creation
        };

        (this.props.actions.tdaEditPatch(tdaAdId, updatedCreation as any) as any)
            .then((response: ITdaAdCreation) => {
                if (response != null) {
                    if (shouldGenerate) {
                        if(toHtml) {
                            this.handleSaveHtml(response.name, response.id)
                        } else {
                            this.handleConvert(response.name, response.id);
                        }
                    }
                    this.props.actions.clearTdaAddEditErrors();
                }
            });
    };

    private handleConvert = (name: string, id: number) => {
        this.openPreviewModal();
        htmlToImgDownload(document.getElementById("v2-ad")!, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

     private handleSaveHtml = (name: string, id: number) => {
        this.openPreviewModal();
        const element = document.getElementById("v2-ad")!;
        saveAsHtml(element, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    /**
     * Render
     */

    public render() {
        const {request} = this.props.advertDetail;

        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklama TDA</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit()}
                            >
                                Zapisz reklamę
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(true)}
                            >
                                Zapisz i Wygeneruj reklamę
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <TdaAdCreationForm
                            onSubmit={() => this.onSubmit(false)}
                            errors={this.props.advertDetail.errors ? this.props.advertDetail.errors.fieldErrors : null}
                            values={this.props.advertDetail.form}
                            updateFormAction={this.props.mobileFormActions.update}
                            handlePreview={() => this.openPreviewModal()}
                            submitAndGenerate={() => this.onSubmit(true)}
                            submitAndGenerateHtml={() => this.onSubmit(true, true)}
                            updateImageAfterCropping={this.onImageCropped}
                            initialImage={this.state.initialImage}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const TdaAdCreationEditView = connect(mapStateToProps, mapActionsToProps)(TdaAdCreationEditViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        advertDetail: store.adverts.tda.detail,
        contextPanelOpened: store.adverts.tda.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        mobileFormActions: bindActionCreators(addTdaFormActions, dispatch),
        actions: bindActionCreators({
            tdaEditPatch,
            tdaFetchDetail,
            openPanel,
            closePanel,
            openTab,
            clearTdaAddEditViewState,
            clearTdaAddEditErrors,
        }, dispatch)
    };
}
