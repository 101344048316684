import * as React from "react";
import * as classNames from "classnames";
import Icon from "../../shared/icon/Icon";
import {ElementClasses, Form, FormProps} from "../../../../shared/ts/components/form";
import {Select} from "../../../../shared/ts/components/forms/select";
import {asyncNativesOffer, fetchOffer} from "../../offer/actions/fetch";
import {offerRenderer} from "../../offer/helpers/offerRenderer";
interface IProps extends FormProps {
    className?: string;
}

interface IState {
}


export class NativeOffersAutocomplete extends Form<IProps, IState> {
    public static elementClasses: ElementClasses = {
        "offer": {
            type: Select
        }
    };
    public render() {
        const className = classNames("bgc-white bdrs-lg psr p-lg mb-xxxl native-offer-autocomplete select-lg", this.props.className);

        return (
            <form onSubmit={(e) => e.preventDefault()}>
                <div className={className} style={{width: "500px", paddingRight: "50px"}}>
                    <Select
                        {...this.generateProps("offer")}
                        searchable
                        async
                        loadOptions={asyncNativesOffer}
                        getOption={fetchOffer}
                        showAllValues
                        autoFocus
                        optionRenderer={offerRenderer}
                        groupClassName="mb-0"
                        placeholder="Wyszukaj inwestycję"
                        showInputPlaceholder
                        menuClassName="native-offer-autocomplete-menu"
                    />

                    <Icon icon="search" className="psa vc r20 c-gray fs24"/>
                </div>
            </form>
        );
    }
}
