import * as React from "react";
import {includes} from "lodash";
import {Panel, PanelBody, PanelFooter, PanelHeader} from "../../../shared/panel/Panel";
import {Button} from "../../../shared/button/Button";
import {ElementClasses, Form, FormProps, FormState} from "../../../../../shared/ts/components/form";
import {Input} from "../../../../../shared/ts/components/forms/input";
import Icon from "../../../shared/icon/Icon";
import {File, FileValue} from "../../../../../shared/ts/components/forms/file";
import {ModalOpener} from "../../../advertisments/components/ModalOpener";
import {CreationType} from "../../CreationType";


interface IProps extends FormProps {
    handlePreview: () => void;
    submitAndGenerate: () => void;
    submitAndGenerateHtml: () => void;
    updateImageAfterCropping: (key: string, image?: string) => void;
    initialImage: string | Blob;
}

interface IState extends FormState {
    show_crop_v2_image: boolean;
}

export interface ISurpriseAdFormValues {
    logo: FileValue;
    image: FileValue;
    name: string;
}

export type ISurpriseAdCreation = Record<keyof ISurpriseAdFormValues, string> & {
    id: number;
    offer: number;
    type: CreationType;
};

export class SurpriseAdCreationForm extends Form<IProps, IState> {
    public static elementClasses: ElementClasses = {
        "name": {
            type: Input,
            label: "Nazwa inwestycji (poglądowa nazwa kreacji na liście, nie pokazuje się na karcie)"
        },
        "logo": {
            type: File,
            label: "Logo dewelopera"
        },
        "image": {
            type: File,
            label: "Zdjęcie"
        },
        "title": {
            type: Input,
            label: "Tytuł reklamy"
        },
        "description": {
            type: Input,
            label: "Opis reklamy"
        },
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            show_crop_v2_image: false
        };
    }

    private handleResettingImage = (e: React.MouseEvent, fileFieldName: ("image" | "logo")) => {
        e.preventDefault();
        this.triggerCrop(fileFieldName);
        this.props.updateImageAfterCropping(fileFieldName);
    };

    private triggerCrop = (name: "image" | "logo") => {
        // this is too slightly over complex now, but it used to handle 3 different size images.
        // let's leave it this way for the possible future case of coming back to multi sizes.
        // what it does is always using "image" as name
        const stateName = `show_crop_${name}` as keyof IState;
        this.setState({[stateName]: true} as Pick<IState, keyof IState>);
        // just needed a trigger
        window.setTimeout(() => this.setState({[stateName]: false} as Pick<IState, keyof IState>), 500);
    };

    private renderFile = (fileFieldName: ("image" | "logo"), width: number, height: number) => {
        const isInitialImage = this.props.values[fileFieldName] && includes(this.props.values[fileFieldName].selected, "main");
        const stateName = (this.state as any)[`show_crop_${fileFieldName}`];

        return (
            <div>
                <File {...this.generateProps(fileFieldName)}
                      allowedExtensions={["jpg", "jpeg", "png"]}
                      imgFilePreview
                      triggerCropOnFileChange={this.triggerCrop}
                      previewPath={this.props.values[fileFieldName].base64value}
                      label={`Do beneru ze zdjęciem (${width}x${height})`}
                />

                <div className="psr t-5 mb-xl">
                    {this.props.values[fileFieldName].base64value && (
                        <ModalOpener
                            isInitialImage={isInitialImage}
                            name={fileFieldName}
                            width={width}
                            initialImage={this.props.initialImage}
                            image={this.props.values[fileFieldName]}
                            isOpen={stateName}
                            height={height}
                            updateImageAfterCropping={(image: string) => this.props.updateImageAfterCropping(fileFieldName, image)}
                        />
                    )}

                    {this.props.initialImage && (
                        <Button size="sm" color="primary-ghost" onClick={(e) => this.handleResettingImage(e, fileFieldName)}>
                            Pobierz ponownie zdjęcie
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    public render(): JSX.Element {
        return (
            <form>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df df-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="screen" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Reklama z zdjęciem
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <h3 className="fs16 fwb">Informacje o reklamie</h3>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-sm-4">
                                        <Input {...this.generateProps("name")} />
                                    </div>
                                </div>

                                <div className="bdbw-sm bdbs-solid bdbc-black-o10 mb-xxxl" />

                                <div className="row">
                                    <div className="col-xs-6">
                                        <h3 className="fs14 fwb mt-0">Zdjęcie zakryte pod kartą - logo dewelopera w prawym górnym rogu</h3>

                                        {this.renderFile("logo", 80, 60)}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-6">
                                        <h3 className="fs14 fwb mt-0">Zdjęcie zakryte pod kartą w środku karty</h3>

                                        {this.renderFile("image", 375, 211)}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-sm-4">
                                        <Input {...this.generateProps("title")} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-sm-4">
                                        <Input {...this.generateProps("description")} />
                                    </div>
                                </div>

                            </PanelBody>

                            <PanelFooter className="tar pv-xl">
                                <Button color="primary-ghost" size="lg" className="mr-xl" onClick={this.props.handlePreview}>Podgląd kreacji</Button>

                                <Button color="success" size="lg" className="mr-xl" onClick={this.props.onSubmit}>Zapisz kreację</Button>

                                <Button color="primary" size="lg" className="mr-xl" onClick={this.props.submitAndGenerate}>Zapisz i Wygeneruj kreację</Button>
                                <Button color="warning" size="lg" onClick={this.props.submitAndGenerateHtml}>Zapisz i Wygeneruj HTML</Button>
                            </PanelFooter>
                        </Panel>
                    </div>
                </div>
            </form>
        );
    }
}
