import * as React from "react";
import * as classnames from "classnames";
import {WrappedFieldProps} from "redux-form";
import * as ReactDatePicker from "react-datepicker";
import * as moment from "moment";
import {isFunction} from "lodash";
import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {Store} from "../../../../project/reducer";
import FieldLabel from "../../FieldLabel";
import FieldError from "../../FieldError";

const DatePicker = (ReactDatePicker as any).default;


interface DatePickerFieldProps extends StyleProps, WrappedFieldProps<Store> {
    error?: string;
    type?: string;
    label?: string;
    placeholder?: string;
    onValueUpdate?: (name: string, value?: any) => void;
    groupClassName?: string;
    popoverTargetOffset?: string;
    popoverAttachment?: string;
    popoverTargetAttachment?: string;
    minDate?: {};
    maxDate?: {};
}

interface DatePickerFieldState {
    triggerOnValueUpdate: boolean;
}


export default class DatePickerField extends React.Component<DatePickerFieldProps, DatePickerFieldState> {

    private static displayFormat: string = "DD.MM.YYYY";
    private static storeFormat: string = "YYYY-MM-DD";

    constructor(props: DatePickerFieldProps) {
        super(props);
        this.state = {triggerOnValueUpdate: false};
    }

    public componentDidUpdate(prevProps: DatePickerFieldProps) {
        const {input: {value, name}, onValueUpdate} = this.props;
        if (this.state.triggerOnValueUpdate) {
            if (isFunction(onValueUpdate)) {
                this.setState({triggerOnValueUpdate: false});
                onValueUpdate(name, value);
            }
        }
    }

    /**
     * Callback
     */

    private onChange = (date: moment.Moment): void => {
        const {input} = this.props;
        input.onChange(date && date.format(DatePickerField.storeFormat));
        this.setState({triggerOnValueUpdate: true});
    };

    /**
     * Render
     */

    private renderDatepickerInput = (): JSX.Element => {
        const {input: {value, name}, className, popoverTargetOffset, popoverAttachment, popoverTargetAttachment} = this.props;
        const fieldClassName = classnames("form-control", className);


        let selected: moment.Moment | undefined = moment(value, DatePickerField.storeFormat, true);
        if (!selected.isValid()) {
            selected = undefined;
        }

        return (
            <DatePicker
                id={name}
                className={fieldClassName}
                selected={selected}
                onChange={this.onChange}
                locale="pl"
                dateFormat={DatePickerField.displayFormat}
                popoverTargetOffset={popoverTargetOffset}
                popoverAttachment={popoverAttachment}
                popoverTargetAttachment={popoverTargetAttachment}
            />
        );
    };

    public render(): JSX.Element {
        const {error, input, label, groupClassName} = this.props;
        const holderClassName = classnames("psr form-group", groupClassName, error && "has-error");
        const DTInput = this.renderDatepickerInput();
        const field = <div>{DTInput}</div>;

        return (
            <div className={`${holderClassName} form-group`}>
                {label && <FieldLabel label={label} htmlFor={input.name}/>}
                {error ? <FieldError error={error}>{field}</FieldError> : field}
            </div>
        );
    }
}
