import * as React from "react";
import {withRouter} from "react-router";
import {Panel} from "../../../shared/panel/Panel";
import {Button} from "../../../shared/button/Button";
import Icon from "../../../shared/icon/Icon";
import {url} from "../../../../../shared/ts/helpers/routing";
import {RouterProps} from "../../../../../shared/ts/interfaces/router";
import {EmtpyImg} from "../../../../../shared/ts/components/plan_offers/EmptyImg";


interface IOwnProps {
    buildingId: number;
    offerId: number;
    disableLink?: boolean;
    deletePolygon: (floorId: number) => void;
    editPolygon: () => void;
    editAssignment: (floorId: number) => void;
    floor: {
        plan_image: string | null;
        floor: string | number;
        id: number;
    };
}
interface IProps extends IOwnProps, RouterProps {}

const PlanBuildingFloorListItemC = (props: IProps) => {

    const floor = props.floor;
    const link = url("app:in:offerPlans:floor", {offer_id: props.offerId, building_id: props.buildingId, floor_id: floor.id});
    const onEditItemClick = () => props.router.push(link);

    return (
        <Panel color="default">
            <div className="df fjc-sb fai-center ph-lg pv-xl">
                {floor.plan_image ? (
                    <img src={floor.plan_image} width="155" height="90" />
                ) : (
                    <EmtpyImg className="bdrs-md bdc-gray-light bdw-sm bds-solid" width={155} height={90}/>
                )}

                <div className="fg-1 fs-1 ph-md">
                    <p className="fs22 fwb mb-xs">
                        Piętro {floor.floor}
                    </p>
                </div>

                <div className="fg-0 fs-0">
                    <Button color="default" size="sm" className="mr-sm" onClick={props.editPolygon}>
                        <Icon icon="pencil" className="mr-xs"/>
                        Edytuj poligon
                    </Button>

                    <Button color="default" size="sm" className="mr-sm" onClick={() => props.editAssignment(props.floor.id)}>
                        <Icon icon="main-data" className="mr-xs"/>
                        Edytuj przypisanie
                    </Button>

                    {!props.disableLink && (
                        <Button color="default" size="sm" className="mr-sm" onClick={onEditItemClick}>
                            <Icon icon="arrow-right" className="mr-xs"/>
                            Przejdź do piętra
                        </Button>
                    )}

                    <Button color="danger" size="sm" onClick={() => props.deletePolygon(props.floor.id)}>
                        <Icon icon="delete"/>
                    </Button>
                </div>
            </div>
        </Panel>
    );
};

export const PlanBuildingFloorListItem: React.ComponentClass<IOwnProps> = withRouter(PlanBuildingFloorListItemC);
