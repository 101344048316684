import * as React from "react";
import {reduce} from "lodash";
import {Button} from "../../../shared/button/Button";
import {FormSelect} from "../../../shared/form/fields/select/FormSelect";
import {createForm, FormProps, HocForm} from "../../../shared/form/create_form";
import {IPlanFloor} from "../../actions/fetch_plan_floor_actions";
import {Select} from "../../../shared/form/fields/select/Select";
import Input from "../../../shared/form/fields/input/Input";
import Icon from "../../../shared/icon/Icon";
import {FormInput} from "../../../shared/form/fields/input/FormInput";


export const assignFloorFormFields = {
    floor: Select,
    name: Input,
    propertyCount: Input,
    custom: Select
};

interface IOwnProps extends FormProps {
    onClose: () => void;
    floors: IPlanFloor[];
    initialFloorId: number;
}

interface IProps extends IOwnProps {
    hoc: HocForm<typeof assignFloorFormFields>;
}

export const AssignFloorForm = createForm<IOwnProps>()((props: IProps) => {

    const {fieldProps} = props.hoc.form;

    const generateFloorOptions = reduce(props.floors, (acc, floor) => {
        if (floor.plan_polygon && props.initialFloorId !== floor.id) {
            // do not include floors related to other polygons
            // but explicitly add (do not exclude) floor with `initialFloorId` ID
            return acc;
        }
        return [...acc, {
            value: floor.id.toString(),
            label: floor.floor == null ? "Nieokreślone" : `Piętro ${floor.floor}`
        }];
    }, []);

    const renderForm = () => {
        if (!props.values.custom) {
            return generateFloorOptions.length > 0 ? (
                <FormSelect
                    {...fieldProps("floor")}
                    label="Wybierz piętro"
                    showAllValues
                    options={generateFloorOptions}
                    async={false}
                    creatable
                    multi={false}
                />
            ) : (
                <p className="c-danger">
                    Brak pięter do przypisania
                </p>
            );
        } else {
            return (
                <>
                    <Button color="link" size="sm" className="p-0 mb-lg"
                            onClick={() => fieldProps("custom").onChange("custom", false)}>
                        <Icon icon="arrow-left" className="mr-sm"/>
                        Wróć
                    </Button>

                    <div>
                        <FormInput
                            {...fieldProps("name")}
                            autoFocus
                            type="text"
                            label="Numer piętra"
                        />

                        <FormInput
                            {...fieldProps("propertyCount")}
                            autoFocus
                            type="text"
                            label="Ilość mieszkań na piętrze"
                        />
                    </div>
                </>
            );
        }
    };

    return (
        <form className="p-xxxl " onSubmit={props.onSubmit} style={{minWidth: "400px"}}>
            <div className="mb-xxxl select-floor-form psr">
                {renderForm()}
            </div>

            {!props.values.custom && (
                <Button
                    onClick={() => fieldProps("custom").onChange("custom", true)}
                    color="link"
                    type="button"
                    className="p-0 mb-xl c-info tdn-h"
                >
                    <Icon icon="pencil" className="mr-md"/>
                    Dodaj ręcznie piętra
                </Button>
            )}

            <div className="df fjc-end">
                <Button type="button" color="default" size="lg" className="mr-lg" onClick={props.onClose}>
                    Anuluj
                </Button>

                <Button color="success" type="submit" size="lg" disabled={props.values.custom ? !props.values.name : !props.values.floor}>
                    Zapisz
                </Button>
            </div>
        </form>
    );
});
