import {RequestActionTypes} from "../create_request_action_types";
import {ResponseAction} from "../../interfaces/dispatch";

export function reduceResponseWithReset<T>(requestTypes: RequestActionTypes) {
    return (state: T | null = null, action: ResponseAction<T>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                return action.result;
            case requestTypes.reset:
                return null;
            default:
                return state;
        }
    };
}
