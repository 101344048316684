import * as React from "react";
import {connect} from "react-redux";
import Loader from "../../shared/loader/Loader";
import {logOut} from "../actions/logout";


interface ActionsProps {
    logOut: typeof logOut;
}

interface OwnProps {
}

interface LogoutProps extends ActionsProps, OwnProps {}


@connect((null as any), {logOut})
class Logout extends React.Component<LogoutProps, {}> {

    public componentDidMount() {
        this.props.logOut();
    }

    public render() {
        return (
            <div className="psr c-white z1">
                <div className="dib psr fs18">
                    Wylogowuje&hellip;
                    {" "}
                    <Loader size="sm" color="light" />
                </div>
            </div>
        );
    }
}

export default Logout as React.ComponentClass<OwnProps>;
