import * as React from "react";
import TableCell from "../../shared/table/TableCell";
import {Button} from "../../shared/button/Button";
import Icon from "../../shared/icon/Icon";
import TableRow from "../../shared/table/TableRow";
import {HocModalix, modalix} from "../../../../shared/ts/decorators/modalix";
import {DateFormat, DateFormatType} from "../../../../shared/ts/components/datetime";
import {RemoveModal} from "../../shared/modal/RemoveModal";
import {getOfferTypeNamePlural, OfferType} from "../../../../shared/ts/constants/OfferType";

interface IOwnProps {
    className?: string;
    name: string;
    vendor: string;
    createDate: string;
    createdBy: string;
    id: number;
    offerType: OfferType;
    generatedNatives: (string|null)[];
    onDeleteClick: (id: number) => void;
    onClick: (id: number) => void;
}
interface IProps extends IOwnProps {
    hoc: HocModalix;
}

interface IState {}

@modalix(RemoveModal)
class NativeListItemC extends React.Component<IProps, IState> {

    private onDeleteClick = (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        this.props.hoc.modalix.show<any>({
            modalTitle: "Usunięcie reklamy",
            modalText: "Czy na pewno chcesz usunąć wybraną reklamę?",
            onConfirm: () => {
                this.props.onDeleteClick(id);
            },
            onReject: () => this.props.hoc.modalix.hide()
        });
    };
    private renderNativeImagesInfo = (generatedNatives: (string|null)[]) => {
        return (
            <div>
                {generatedNatives[0] && "Web "}
                {(generatedNatives[0] && generatedNatives[1]) && ", "}
                {generatedNatives[1] && "Mobile "}
            </div>
        );
    };

    public render() {
        const {id, name, offerType, vendor, createDate, generatedNatives, createdBy} = this.props;

        return (
            <TableRow className="curp" onClick={() => this.props.onClick(this.props.id)}>
                <TableCell className="vam">
                    {name}{" "}<span className="c-gray-dark fs12">({getOfferTypeNamePlural(offerType)})</span>
                </TableCell>

                <TableCell className="vam">
                    {vendor}
                </TableCell>

                <TableCell className="vam">
                    <DateFormat date={createDate} format={DateFormatType.shortDate} />
                </TableCell>

                <TableCell className="vam">
                    {this.renderNativeImagesInfo(generatedNatives)}
                </TableCell>

                <TableCell className="vam">
                    {createdBy}
                </TableCell>

                <TableCell className="vam tac">
                    <Button color="danger" size="sm" onClick={(e: React.MouseEvent) => this.onDeleteClick(e, id)}><Icon icon="delete" /></Button>
                </TableCell>
            </TableRow>
        );
    }
}

export const NativeListItem = NativeListItemC as React.ComponentClass<IOwnProps>;
