import {assign} from "../../../../shared/ts/helpers/util";
import {CAMPAIGN_UPDATE_SUMMARY, CAMPAIGN_INVALIDATE_SUMMARY} from "../actions/summary";


export interface CampaignSummaryState {
    recipients_count: number;
    sms_text: string;
    sms_count_per_recipient: number;
    sms_notification_text: string;
    sms_notification_count_per_recipient: number;
    total_price: number;
    total_sms_count: number;
}

const initialState = {
    recipients_count: 0,
    sms_count_per_recipient: 0,
    sms_notification_text: "",
    sms_notification_count_per_recipient: 0,
    sms_text: "",
    total_price: 0,
    total_sms_count: 0
};


export function campaignSummary(state: CampaignSummaryState = initialState, action: any): CampaignSummaryState {
    switch (action.type) {
        case CAMPAIGN_UPDATE_SUMMARY:
            return assign(state, {
                recipients_count: action.stats.recipients_count,
                sms_count_per_recipient: action.stats.sms_count_per_recipient ,
                sms_notification_text: action.stats.sms_notification_text,
                sms_notification_count_per_recipient: action.stats.sms_notification_count_per_recipient,
                sms_text: action.stats.sms_text,
                total_price: action.stats.total_price,
                total_sms_count: action.stats.total_sms_count
            });
        case CAMPAIGN_INVALIDATE_SUMMARY:
            return initialState;
        default:
            return state;
    }
}
