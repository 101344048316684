import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../../shared/ts/interfaces/style";

interface ContentProps extends StyleProps {
    noContextPanel?: boolean;
}


export const ContentC: React.SFC<ContentProps> = props => {
    const className = classnames(props.className, "df fd-col fb-a fg-1 fs-1", props.noContextPanel ? "pr-0" : undefined);
    return <div className={className}>{props.children}</div>;
};

const Content = ContentC;
export default Content;
