import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import Main from "../../../shared/layout/Main";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import {Button} from "../../../shared/button/Button";
import ContentBody from "../../../project/components/content/ContentBody";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {Offer} from "../../../shared/models/Offer";
import {fetchOfferDetailForNatives, resetOfferDetailForNative} from "../../../offer/actions/fetch";
import {noop} from "../../../../../shared/ts/helpers/lodash";
import {createFileName, urlBase64, urlToCroppedBase64} from "../../../../../shared/ts/helpers/urlToCroppedBase64";
import {url} from "../../../../../shared/ts/helpers/routing";
import {RouterProps} from "../../../../../shared/ts/interfaces/router";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {addMobileNativesFormActions, clearAddEditViewState, nativesAdd} from "../../../advertisments/actions/natives/add";
import {INativesForms} from "../../../advertisments/reducers/natives";
import {NativeOffersAutocomplete} from "../../../advertisments/components/NativeOffersAutocomplete";
import {htmlToImgDownload} from "../../native/actions/html_to_img";
import {saveAsHtml} from "../../native/actions/save_as_html";
import {IImageAdCreation, ImageAdCreationForm} from "../components/ImageAdCreationForm";
import {ImageAdCreationAdModal} from "../components/ImageAdCreationAdModal";
import {CreationType} from "../../CreationType";

interface IStateProps {
    nativesDetail: INativesForms;
    nativesOfferDetail: Offer | null;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    mobileFormActions: FormActions;
    fetchOfferDetailForNatives: (id: number) => Promise<any>;
    actions: {
        nativesAdd: typeof nativesAdd;
        resetOfferDetailForNative: typeof resetOfferDetailForNative;
        clearAddEditViewState: typeof clearAddEditViewState;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
    };
}

interface IOwnProps extends RouterProps {
    hoc: HocModalix;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    initialImage: string | Blob;
}

@modalix(ImageAdCreationAdModal)
class ImageAdCreationAddViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            initialImage: ""
        };
    }

    /**
     * Lifecycle
     */
    public componentDidMount() {
        this.getDefaultFormValues();
    }

    public componentWillUnmount(): void {
        this.props.actions.clearAddEditViewState();
        this.props.actions.resetOfferDetailForNative();
    }

    private getDefaultFormValues = () => {
        const formValues = {
            mobile_offer_price_type: 0
        };

        this.props.mobileFormActions.update(formValues);
    };

    /**
     * Fetch offer and initialize form
     */

    private onSearchedOfferUpdate = async (data: {offer: {option: unknown; value: number} | null}): Promise<void> => {
        if (data.offer != null) {
            await this.props.fetchOfferDetailForNatives(data.offer.value);
            if (this.props.nativesOfferDetail == null) {
                throw new Error("onSearchedOfferUpdate: offer not saved in store");
            }
            this.triggerPopulatingDataToForm(this.props.nativesOfferDetail);
        }
    };

    private triggerPopulatingDataToForm = (offer: Offer): void => {
        const rawFormValues = this.prepareNativeCreationValues(offer);
        this.props.mobileFormActions.update(rawFormValues);
        this.prepareBase64Images(offer); // at the very end, because it is async anyway
    };

    // This supplements `prepareNativeCreationValues`.
    private prepareBase64Images = async (offer: Offer): Promise<void> => {
        const initialImage = await urlBase64(offer.main_image) as string;
        const mobileImage = await urlToCroppedBase64(offer.main_image, 375,  484);
        this.setState({initialImage});
        this.props.mobileFormActions.update({
            mobile_image: {
                ...this.props.nativesDetail.mobileForm.mobile_image,
                selected: `main_${createFileName(offer.name, offer.pk)}`,
                base64value: mobileImage
            }
        });
    };

    private prepareNativeCreationValues = (offer: Offer) => {
        return {
            mobile_name: offer.name,
            mobile_address: offer.street_name + (offer.street_number ? ` ${offer.street_number}` : ""),
            mobile_region: offer.region.short_name,
            mobile_vendor: offer.vendor.name
        };
    };

    /**
     * Submit form
     */
    private onSubmit = (shouldGenerate: boolean = false, toHtml: boolean = false) => {
        const creation: IImageAdCreation = {
            ...ImageAdCreationForm.toJSON(this.props.nativesDetail.mobileForm),
            type: CreationType.IMAGE,
        };
        this.handleFormSubmit(creation, shouldGenerate, toHtml);
    };

    private handleFormSubmit = (creation: IImageAdCreation, shouldGenerate: boolean, toHtml: boolean) => {
        (this.props.actions.nativesAdd(creation as any) as any) // ANY: actions' types are based on old creation types
            .then((response: IImageAdCreation) => {
                if (response != null) {
                    if (shouldGenerate) {
                        if (toHtml) {
                            this.handleSaveHtml(response.mobile_name, response.id);
                        } else {
                            this.handleConvert(response.mobile_name, response.id);
                        }
                    }
                    return response.id;
                }
            })
            .then((id: number) => {
                    if (id != null) {
                        const link = url("app:in:creation:image:edit", {id});
                        this.props.router.push(link);
                    }
                }
            );
    };

    private handleConvert = (name: string, id: number) => {
        this.openPreviewModal();
        htmlToImgDownload(document.getElementById("v2-ad")!, name, id);

        // do we want to wait?
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    private handleSaveHtml = (name: string, id: number) => {
        this.openPreviewModal();
        const element = document.getElementById("v2-ad")!;
        saveAsHtml(element, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    /**
     * Callback
     */

    private openPreviewModal = () => {
        this.props.hoc.modalix.show<any>({
            imageAdCreationFormData: {
                ...this.props.nativesDetail.mobileForm,
                mobile_image: this.props.nativesOfferDetail ? { base64: "", stored: this.props.nativesOfferDetail.main_image} : this.props.nativesDetail.mobileForm.mobile_image
            },
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    private onImageCropped = (key: ("mobile_image"), image?: string) => {
        const imgOptions = image ?
            {base64value: image} :
            {base64value: this.state.initialImage, selected: this.props.nativesOfferDetail ? createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk) : "initial_image"};
        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.nativesDetail.mobileForm[key],
                ...imgOptions
            }
        });
    };

    /**
     * Render
     */

    private emptyAutocompleteFormValues = {}; // autocomplete does not need `value` entry

    public render() {
        const {request} = this.props.nativesDetail;
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklamy natywne</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd Reklam
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit()}
                            >
                                Zapisz Reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(true)}
                            >
                                Zapisz i Wygeneruj Reklamy
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <NativeOffersAutocomplete
                            onSubmit={noop}
                            errors={null}
                            values={this.emptyAutocompleteFormValues}
                            updateFormAction={this.onSearchedOfferUpdate}
                        />

                        <ImageAdCreationForm
                            onSubmit={() => this.onSubmit(false)}
                            errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                            values={this.props.nativesDetail.mobileForm}
                            updateFormAction={this.props.mobileFormActions.update}
                            handlePreview={() => this.openPreviewModal()}
                            submitAndGenerate={() => this.onSubmit(true)}
                            submitAndGenerateHtml={() => this.onSubmit(true, true)}
                            updateImageAfterCropping={this.onImageCropped}
                            initialImage={this.state.initialImage}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const ImageAdCreationAddView = connect(mapStateToProps, mapActionsToProps)(ImageAdCreationAddViewC);


/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        nativesDetail: store.adverts.natives.detail,
        nativesOfferDetail: store.adverts.natives.nativesOfferDetail,
        contextPanelOpened: store.adverts.natives.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        mobileFormActions: bindActionCreators(addMobileNativesFormActions, dispatch),
        fetchOfferDetailForNatives: fetchOfferDetailForNatives(dispatch),
        actions: bindActionCreators({
            nativesAdd,
            openPanel,
            closePanel,
            openTab,
            clearAddEditViewState,
            resetOfferDetailForNative
        }, dispatch)
    };
}

