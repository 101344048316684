import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../../shared/ts/interfaces/style";

interface ContentHeaderTitleProps extends StyleProps {}

export const ContentHeaderTitleC: React.SFC<ContentHeaderTitleProps> = props => {
    const className = classnames(props.className, "truncate fg-1 fs-1 fb-0 pr-md");
    return <div className={className}>{props.children}</div>;
};

const ContentHeaderTitle = ContentHeaderTitleC;
export default ContentHeaderTitle;
