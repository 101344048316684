import {combineReducers} from "redux";

import natives, {NativesStore} from './natives'
import surprise, {SurpriseStore} from "./surprise";
import hero, {HeroStore} from "./hero";
import tda, {TdaStore} from "./tda";

export interface IAdvertsStore {
    natives: NativesStore,
    surprise: SurpriseStore,
    hero: HeroStore,
    tda: TdaStore
}

const adverts = combineReducers({
    natives, // TODO: Natives is used as common store for all CreationTypes. As the apis for those get separated, the store should get separated as well
    surprise,
    hero,
    tda
});

export default adverts;
