import * as React from "react";
import Tooltip from "../overlays/Tooltip";
import StyleProps from "../../../../shared/ts/interfaces/style";
import * as classnames from "classnames";

interface FieldErrorProps extends StyleProps {
    error: string;
    className?: string;
}

const FieldError: React.SFC<FieldErrorProps> = props => {
    const className = classnames("tooltip-error", props.className);

    return (
        <Tooltip content={props.error} id={props.error} className={className}>
            {props.children}
        </Tooltip>
    );
};

export default FieldError;
