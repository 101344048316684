import * as React from "react";
import * as _ from "lodash";

import {Panel, PanelBody, PanelFooter, PanelHeader} from "../../shared/panel/Panel";
import {Button} from "../../shared/button/Button";
import {ElementClasses, Form, FormProps, FormState} from "../../../../shared/ts/components/form";
import {Input} from "../../../../shared/ts/components/forms/input";
import Icon from "../../shared/icon/Icon";
import {File} from "../../../../shared/ts/components/forms/file";
import {VideoPreview} from "../../../../shared/ts/components/VideoPreview";
import {Select} from "../../../../shared/ts/components/forms/select";
import {
    getOffer,
    getOffers,
    getProperties,
    getProperty,
    getReelCreator,
    getReelsCreators,
    getRegion,
    getRegions
} from "../helpers/autocomplete";
import {videoCreationTypeOptions} from "../constants/video_creation_type_options";
import {Checkbox} from "../../../../shared/ts/components/forms/checkbox";
import {Radio} from "../../../../shared/ts/components/forms/radio";
import {reelActionButtonTypeOptions} from "../constants/reel_action_button_type_options";
import {DateTimePickerRange} from "../../../../shared/ts/components/forms/dateTimePickerRange";
import {ImagePreview} from "../../../../shared/ts/components/ImagePreview";
import {Property} from "../../property/models/Property";
import {getRandomId} from "../helpers/get_random_id";
import {REEL_MAX_VIDEO_SIZE} from "../constants/reel_max_video_size";

interface IProps extends FormProps {
    handleCancel: () => void;
    handlePreview: () => void;
}

interface IState extends FormState {}

export class ReelForm extends Form<IProps, IState> {
    public static elementClasses: ElementClasses = {
        "type": {
            type: Select,
            label: "Typ kreacji"
        },
        "creator": {
            type: Select,
            label: "Autor"
        },
        "creator_image": {
            type: File,
            label: "Zdjęcie/logo autora"
        },
        "video": {
            type: File,
            label: "Wideo"
        },
        "cover": {
            type: File,
            label: "Okładka"
        },
        "cover_variant": {
            type: File,
            label: "Okładka (strona inwestycji i nieruchomości)"
        },
        "title": {
            type: Input,
            label: "Hasło reklamujące"
        },
        "display_in_offer_enabled": {
            type: Checkbox,
            label: "Dodaj rolkę do prezentacji inwestycji"
        },
        "display_in_offer": {
            type: Select,
            label: "Wybierz inwestycję"
        },
        "action_button_enabled": {
            type: Checkbox,
            label: "Dodaj przycisk podczas odtwarzania rolki"
        },
        "action_button_name": {
            type: Input,
            label: "Nazwa na przycisku"
        },
        "action_button_type": {
            type: Radio,
            label: "Strona docelowa"
        },
        "action_button_url": {
            type: Input,
            label: "URL"
        },
        "action_button_offer": {
            type: Select,
            label: "Wybierz inwestycję"
        },
        "action_button_property_enabled": {
            type: Checkbox,
            label: "Zapytanie na nieruchomość"
        },
        "action_button_property": {
            type: Select,
            label: "Wybierz nieruchomość"
        },
        "display_date_range": {
            type: DateTimePickerRange,
            label: "Okres wyświetlania rolki"
        },
        "region": {
            type: Input,
            label: "Wybierz lokalizację"
        },
    };

    private handleCreatorChange = async (creator?: {label: string; value: number; image: string} | null) => {
        this.props.updateFormAction({
            creator_image: creator && creator.image ? {
                id: getRandomId(),
                selected: creator.image.split("/").pop(),
                stored: creator.image,
                base64value: ""
            } : {
                id: getRandomId(),
                selected: "",
                stored: "",
                base64value: ""
            },
            creator: {...creator}
        });
    }

    private getReelsCreatorsOptions = async (name: string) => {
        const existingCreatorOptions = await getReelsCreators(name);
        if (existingCreatorOptions.options.length > 0) {
            return existingCreatorOptions;
        }

        return {
            options: [{label: name, value: name, isNew: true}]
        };
    };

    private getReelCreatorOption = (idOrValue: number | string) => {
        if (typeof idOrValue === "string") {
            return Promise.resolve({
                label: idOrValue,
                value: idOrValue,
                isNew: true
            });
        }

        return getReelCreator(idOrValue);
    };

    private getReelCreatorOptionRenderer = (option: {label: string; value: string | number; isNew?: boolean}) => {
        if (option.isNew) {
            return <div>Stwórz nowego autora: <b>{option.label}</b></div>;
        }

        return <div>{option.label}</div>;
    }

    private getPropertiesOptions = async (propertyNumber: string) => {
        const selectedOffer = _.get(this.props.values, "action_button_offer");
        return selectedOffer ? getProperties(selectedOffer.value)(propertyNumber, 10) : {options: []};
    }

    private renderImageFile = (fileFieldName: "cover" | "creator_image" | "cover_variant") => {

        const src = this.props.values[fileFieldName] ? (this.props.values[fileFieldName].base64value || this.props.values[fileFieldName].stored) : null;
        return (
            <div className="pt-lg">
                <File {...this.generateProps(fileFieldName)}
                      allowedExtensions={["jpg", "jpeg", "png"]}
                      customPreview={src ? () => (
                          <ImagePreview width={"100"} height={"auto"} src={src} />
                      ) : undefined}
                      label={ReelForm.elementClasses[fileFieldName].label}
                      className="vabi"
                />
            </div>
        );
    };

    private renderVideoFile = (fileFieldName: "video") => {
        const hasStoredFile = !!(this.props.values[fileFieldName] && this.props.values[fileFieldName].stored);

        return (
            <div>
                <File {...this.generateProps(fileFieldName)}
                      allowedExtensions={["mp4"]}
                      maxSize={REEL_MAX_VIDEO_SIZE}
                      label={`Wgraj plik wideo`}
                      customPreview={hasStoredFile ? () => (
                          <VideoPreview width="100" src={this.props.values[fileFieldName].stored} />
                      ) : undefined}
                      className="vabi"
                />
            </div>
        );
    };

    private renderDisplayInOfferFields = () => {
        const selectedOffer = _.get(this.props.values, "display_in_offer");
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <Select
                                    {...this.generateProps("display_in_offer")}
                                    groupClassName="mb-sm"
                                    searchable
                                    async
                                    loadOptions={getOffers}
                                    getOption={getOffer}
                                    optionRenderer={(option?: string | { label: string; address: string }) => {
                                        if (!option) {
                                            return "";
                                        }

                                        return (
                                            <div>
                                                {typeof option === "string" ? option : (
                                                    <div>
                                                        <div><b>{option.label}</b></div>
                                                        <div className="c-gray">{option.address}</div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                                {selectedOffer && (
                                    <div className="c-gray-light">{_.get(selectedOffer, "option.address")}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderActionButtonFields = () => {
        const actionButtonTypeValue = (+this.props.values.action_button_type || reelActionButtonTypeOptions[0].value).toString();
        const selectedOffer = _.get(this.props.values, "action_button_offer");
        return (
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <Input {...this.generateProps("action_button_name")} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <Radio
                                        {...this.generateProps("action_button_type")}
                                        value={actionButtonTypeValue}
                                        onFormUpdate={(name, value) => {
                                            this.onFormUpdate(name, +value);
                                        }}
                                        label="Strona docelowa"
                                        options={reelActionButtonTypeOptions}
                                    />
                                </div>
                            </div>
                            {actionButtonTypeValue === reelActionButtonTypeOptions[0].value && (
                                <div className="row">
                                    <div className="col-xs-12">
                                        <Input {...this.generateProps("action_button_url")} />
                                    </div>
                                </div>
                            )}
                            {actionButtonTypeValue === reelActionButtonTypeOptions[1].value && (
                                <>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <Select
                                                {...this.generateProps("action_button_offer")}
                                                groupClassName="mb-sm"
                                                searchable
                                                async
                                                loadOptions={getOffers}
                                                getOption={getOffer}
                                                optionRenderer={(option?: string | {label: string; address: string}) => {
                                                    if (!option) {
                                                        return "";
                                                    }

                                                    return (
                                                        <div>
                                                            {typeof option === "string" ? option : (
                                                                <div>
                                                                    <div><b>{option.label}</b></div>
                                                                    <div className="c-gray">{option.address}</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                            />
                                            {selectedOffer && (
                                                <div className="c-gray-light">{_.get(selectedOffer, "option.address")}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <Checkbox {...this.generateProps("action_button_property_enabled")} />
                                        </div>
                                    </div>
                                    {this.props.values.action_button_property_enabled && (
                                        <div className="row">
                                            <div className="col-xs-12">
                                                {_.get(selectedOffer, "value") > 0 ? (
                                                    <>
                                                        <Select
                                                            {...this.generateProps("action_button_property")}
                                                            groupClassName="mb-sm"
                                                            searchable
                                                            async
                                                            ignoreCase={false}
                                                            loadOptions={this.getPropertiesOptions}
                                                            getOption={getProperty}
                                                        />
                                                        {_.get(this.props.values, "action_button_property.option") ? (
                                                            <div className="mb-sm c-gray-light">
                                                                {this.generatePropertyDescription(_.get(this.props.values, "action_button_property.option"))}
                                                            </div>
                                                        ) : null}
                                                    </>
                                                ) : (
                                                    <div className="mb-xxxl c-warning">
                                                        Wybierz inwestycję, aby móc wybrać nieruchomość
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private generatePropertyDescription = (property: Property) => {
        const area = property.area ? `${property.area} m²` : "";
        const rooms = property.rooms ? `${property.rooms} pok.` : "";
        const floor = property.floor ? `piętro ${property.floor}` : "";
        return [area, rooms, floor].filter(Boolean).join(", ");
    }

    public render(): JSX.Element {
        const creatorProps = this.generateProps("creator");
        const isCreatorExisting = !_.get(this.props.values, "creator.option.isNew");

        return (
            <form>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df df-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="screen" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Dodaj reels
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <Select
                                            {...this.generateProps("type")}
                                            options={videoCreationTypeOptions}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <Select
                                            {...creatorProps}
                                            groupClassName="mb-sm"
                                            searchable
                                            async
                                            ignoreAccents={false}
                                            ignoreCase={false}
                                            loadOptions={this.getReelsCreatorsOptions}
                                            getOption={this.getReelCreatorOption}
                                            onChange={this.handleCreatorChange}
                                            optionRenderer={this.getReelCreatorOptionRenderer}
                                        />
                                        {!isCreatorExisting && (
                                            <div className="c-gray-light">
                                                Zostanie utworzony nowy
                                                autor: <b>{_.get(this.props.values, "creator.option.label")}</b>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        {this.renderImageFile("creator_image")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <div>
                                            {this.renderVideoFile("video")}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        {this.renderImageFile("cover")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        {this.renderImageFile("cover_variant")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <Input {...this.generateProps("title")} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <Checkbox {...this.generateProps("display_in_offer_enabled")} />
                                    </div>
                                </div>
                                {this.props.values.display_in_offer_enabled && this.renderDisplayInOfferFields()}

                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <Checkbox {...this.generateProps("action_button_enabled")} />
                                    </div>
                                </div>
                                {this.props.values.action_button_enabled && this.renderActionButtonFields()}

                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <DateTimePickerRange
                                            {...this.generateProps("display_date_range")}
                                            displayFormat={"DD.MM.YYYY"}
                                            inputFormatDatetime={"YYYY-MM-DD"}
                                            inputFormatDate={"YYYY-MM-DD"}
                                            leftPlaceholder={"Data rozpoczęcia"}
                                            rightPlaceholder={"Data zakończenia"}
                                            datePickerProps={{
                                                className: "form-control",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <Select
                                            {...this.generateProps("region")}
                                            groupClassName="mb-sm"
                                            searchable
                                            async
                                            loadOptions={getRegions}
                                            getOption={getRegion}

                                        />
                                    </div>
                                </div>

                            </PanelBody>

                            <PanelFooter className="tar pv-xl">
                                <Button color="success" size="sm" className="mr-xl ttu ph-xxl"
                                        onClick={this.props.onSubmit}>Zapisz kreację</Button>
                                <Button color="primary-ghost" size="sm" className="mr-xl ttu ph-xxl" onClick={this.props.handlePreview}>Podgląd kreacji</Button>
                                <Button color="default" size="sm" className="ttu ph-xxxl" onClick={this.props.handleCancel}>Anuluj</Button>
                            </PanelFooter>
                        </Panel>
                    </div>
                </div>
            </form>
        );
    }
}
