import * as React from "react";
import {isFunction} from "lodash";
import * as hoistStatics from "hoist-non-react-statics";
import {createHocExtend} from "../../../../shared/ts/decorators/hoc_helpers";


export interface HocForm<TFields> {
    form: {
        fieldProps: (name: keyof TFields) => IFormFieldProps<any>;
    };
}

export interface IFormFieldProps<T> {
    error?: string[] | null;
    name: string;
    onAfterChange: (name: string, value: T) => void;
    onChange: (name: string, value: T) => void;
    value: T;
    // additional
    callAfterChangeOnEnter?: boolean;
    className?: string;
    clearButton?: boolean;
    groupClassName?: string;
    maxLength?: number;
    maxNumberLength?: number;
    onBlur?: (event?: React.FocusEvent<any>, name?: string) => void;
    onFocus?: (event?: React.FocusEvent<any>, name?: string) => void;
}

export interface FormProps {
    values: Record<string, any>;
    errors: Record<string, string[]> | null;
    onSubmit: (e: React.FormEvent<HTMLElement>) => void;
    onChange: (storeData: Record<string, any>) => void;
    onAfterChange?: (fieldName: string, value: any) => void;
    onReset?: (e: React.MouseEvent<HTMLElement>) => void;
    hoc?: Record<string, any>;
}

export function createForm<OwnProps>() {
    return (InnerComponent: any): React.ComponentClass<OwnProps> => {
        class Form extends React.PureComponent<FormProps, {}> {

            public static defaultProps: Partial<FormProps> = {hoc: {}};

            private hocExtend = createHocExtend();
            private hocFetch: HocForm<any> = {
                form: {
                    fieldProps: this.fieldProps.bind(this)
                }
            };

            private onChange = (name: string, value: any): void => {
                return this.props.onChange({[name]: value});
            };

            private onAfterChange = (name: string, value: any): void => {
                if (isFunction(this.props.onAfterChange)) {
                    this.props.onAfterChange(name, value);
                }
            };

            private fieldProps(name: string): IFormFieldProps<any> {
                return {
                    name,
                    value: this.props.values[name],
                    error: this.props.errors && this.props.errors[name],
                    onChange: this.onChange,
                    onAfterChange: this.onAfterChange
                };
            }

            public render() {
                // BANG: typescript does not handle conditional props with default props logic - hoc is default
                return <InnerComponent {...this.props} hoc={this.hocExtend(this.props.hoc!, this.hocFetch)}/>;
            }
        }

        return hoistStatics(Form, InnerComponent);
    };
}
