import * as React from "react";
import {useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Panel} from "../../../shared/panel/Panel";
import {Button} from "../../../shared/button/Button";
import {Modal} from "../../../../../shared/ts/modals/Modal";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {IPlanOfferGallery} from "../../actions/fetch_plan_offer_actions";
import {imgUrlToBase64} from "../../utils/imgUrlToBase64";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {offerAddImageFormActions} from "../../actions/add_image_form";
import {patchBuildingPlan} from "../../actions/patch_building_plan";
import {Store} from "../../../project/reducer";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {BoundAction} from "../../../../../typings/custom_typings/custom_redux";
import {addPlanImageFormFields} from "../add_plan/AddImageForm";
import {AddImageModalBody} from "../add_plan/AddImageModalBody";
import Icon from "../../../shared/icon/Icon";


interface IStateProps {
    addImageFormValues: Record<keyof typeof addPlanImageFormFields, any>;
    patchBuildingRequest: RequestState;
}
interface IActionsProps {
    addImageFormActions: FormActions;
    actions: {
        patchBuildingPlan: BoundAction<typeof patchBuildingPlan>;
    };
}
interface IOwnProps {
    buildingId: number;
    offerGallery: IPlanOfferGallery[];
    changeMode?: boolean;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {}

const PlanBuildingAddImageC = (props: IProps) => {

    const [imageModalState, setImageModalState] = useState(false);

    const onSelectImage = async (photo: IPlanOfferGallery) => {
        const dataUrl = await imgUrlToBase64(photo.image);
        setImageModalState(false);
        await props.actions.patchBuildingPlan(props.buildingId, {plan_image: dataUrl});
    };

    const onUploadImage = async (e: React.FormEvent) => {
        e.preventDefault();
        setImageModalState(false);
        await props.actions.patchBuildingPlan(props.buildingId, {plan_image: props.addImageFormValues.image.base64});
    };

    return (
        <>
            {props.changeMode ? (
                <Button
                    onClick={() => setImageModalState(true)}
                    className="pv-md ph-xl"
                    color="default"
                    type="button"
                >
                    <Icon icon="photo" className="mr-md"/>
                    Zmień plan budynku
                </Button>
            ) : (
                <Panel color="default" className="plan-offer-panel">
                    <div className="w100 h100 df fjc-center fai-center p-lg">
                        <Button onClick={() => setImageModalState(true)}
                                type="button"
                                color="info"
                                size="lg"
                                className="mr-lg"
                                submitting={props.patchBuildingRequest === RequestState.Waiting}
                        >
                            Dodaj plan budynku
                        </Button>
                    </div>
                </Panel>
            )}
            <Modal
                modalState={imageModalState}
                onModalClose={() => setImageModalState(false)}
                type="window"
                header="Wybierz zdjęcie"
            >
                <div className="df fjc-sb overflow-a p-xxxl" style={{minWidth: "500px"}}>
                    <AddImageModalBody
                        gallery={props.offerGallery}
                        onSelectImage={onSelectImage}
                        onUploadImage={onUploadImage}
                        formValues={props.addImageFormValues}
                        formActions={props.addImageFormActions}
                        isPending={props.patchBuildingRequest === RequestState.Waiting}
                    />
                </div>
            </Modal>
        </>
    );
};

export const PlanBuildingAddImage = connect(mapStateToProps, mapActionsToProps)(PlanBuildingAddImageC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        addImageFormValues: state.plan.main.addPlanImageForm,
        patchBuildingRequest: state.plan.building.patchRequest
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        addImageFormActions: bindActionCreators(offerAddImageFormActions, dispatch),
        actions: bindActionCreators<Record<keyof IActionsProps["actions"], any>, IActionsProps["actions"]>({
            patchBuildingPlan
        }, dispatch)
    };
}
