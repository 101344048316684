import * as React from "react";
import {HocModalix, modalix} from "../../../../shared/ts/decorators/modalix";
import {CropperModal} from "./ads_templates/CropperModal";
import {Button} from "../../shared/button/Button";

interface IState {}
interface IOwnProps {
    image: {
        selected: string;
        base64value: string;
    };
    initialImage: string | Blob;
    isInitialImage: boolean;
    name: string;
    updateImageAfterCropping: (image?: string) => void;
    width: number;
    height: number;
    isOpen: boolean;
}

interface IProps extends IOwnProps {
    hoc: HocModalix;
}


@modalix(CropperModal)
export class ModalOpenerC extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
    }

    public componentDidMount(): void {
        !this.props.isInitialImage && this.props.isOpen && this.handleModalOpen();
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState, prevContext: any): void {
        !this.props.isInitialImage && prevProps.image !== this.props.image && this.handleModalOpen();
    }

    private handleModalOpen = (e?: React.MouseEvent) => {
        e && e.preventDefault();
        this.props.hoc.modalix.show<any>({
            image: this.props.isInitialImage ? this.props.initialImage : this.props.image.base64value,
            name: this.props.name,
            width: this.props.width,
            height: this.props.height,
            onUpdate: (image: string) => {
                this.props.updateImageAfterCropping(image);
                this.props.hoc.modalix.hide();
            },
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    public render() {
        return (
            <Button size="sm" color="info" onClick={this.handleModalOpen} className="mr-xl">Przytnij zdjęcie</Button>
        );
    }

}

export const ModalOpener = ModalOpenerC as React.ComponentClass<IOwnProps>;
