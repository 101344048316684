import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import NavPanelHeader from "./NavPanelHeader";
import NavPanelProfile from "./NavPanelProfile";
import {Navigation} from "./Navigation";
import {Store} from "../../project/reducer";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {openPanel, closePanel} from "../actions/nav_panel";
import {User} from "../../auth/models/User";
import {MyAccountResponse} from "../../project/actions/fetch_account_info";

interface StateProps {
    opened: boolean;
    accountData: MyAccountResponse;
}

interface ActionsProps {
    openPanel: typeof openPanel;
    closePanel: typeof closePanel;
}

interface OwnProps {}

interface NavPanelProps extends StateProps, ActionsProps, OwnProps {
    user: User;
}



@connect(mapStateToProps, mapActionsToProps)
class NavPanel extends React.Component<NavPanelProps, {}> {

    private onPanelToggle = (): void => {
        this.props.opened ? this.props.closePanel() : this.props.openPanel();
    };

    public render(): JSX.Element {
        const holderClass = this.props.opened ? "panel-opened" : "panel";

        return (
            <aside className={`${holderClass} nav-panel vh100 df fd-col fg-0 fs-0 p-0`}>
                <NavPanelHeader onPanelToggle={this.onPanelToggle}/>
                <NavPanelProfile user={this.props.accountData.name} />
                <Navigation onPanelOpel={this.props.openPanel}/>
            </aside>
        );
    }
}


export default NavPanel as React.ComponentClass<OwnProps>;


/**
 * Connect
 */

function mapStateToProps(state: Store): StateProps {
    return {
        opened: state.navPanel.opened,
        accountData: state.accountInfo.data
    };
}

function mapActionsToProps(dispatch: Dispatch): ActionsProps {
    return bindActionCreators({
        openPanel: openPanel,
        closePanel: closePanel
    }, dispatch);
}
