import * as React from "react";

import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {SelectOption} from "../select/Select";
import {createRange} from "../create_range";
import {Field} from "redux-form";
import SelectField from "../select/SelectField";


interface TimePickerProps extends StyleProps {
    name: string;
    label?: string;
    disabled?: boolean;
    groupClassName?: string;
    onValueUpdate?: (name: string) => void;
    upperHour: number;
    lowerHour: number;
}

export default class TimePicker extends React.Component<TimePickerProps, {}> {
    public static defaultProps = {
        upperHour: 20,
        lowerHour: 8
    };

    public render(): JSX.Element {
        const timeOptions = [] as SelectOption[];
        for (let i = this.props.lowerHour; i <= this.props.upperHour; i++) {
            timeOptions.push({value: `${(i < 10 ? `0${i}` : i)}:00:00`, label: `${(i < 10 ? `0${i}` : i)}:00`});
            // timeOptions.push({value: (i < 10 ? "0" : "") + i + ":30:00", label: (i < 10 ? "0" : "") + i + ":30"});
        }

        return (
            <Field {...this.props} options={timeOptions}
                   showAllValues component={SelectField}/>
        );
    }
}

export const TimePickerRange = createRange(TimePicker);
