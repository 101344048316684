import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../project/components/content/ContentHeaderControls";
import ContentBody from "../../project/components/content/ContentBody";
import {FormActions} from "../../../../shared/ts/helpers/create_form_actions";
import {Store} from "../../project/reducer";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {Button} from "../../shared/button/Button";
import {RouterProps} from "../../../../shared/ts/interfaces/router";
import {HocModalix, modalix} from "../../../../shared/ts/decorators/modalix";
import {closePanel, openPanel, openTab} from "../../context_panel/actions/context_panel";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {url} from "../../../../shared/ts/helpers/routing";
import {addReelFormActions, clearReelAddEditViewState, reelAdd} from "../actions/reels/add";
import {ReelModal} from "../components/ReelModal";
import {ReelForm} from "../components/ReelForm";
import {IReel, IReelForms, IReelFormValues} from "../reducers/reels";
import {reelCreatorAdd} from "../actions/reels_creator/add";
import {reelCreatorEditPatch} from "../actions/reels_creator/edit";
import {addErrorAlert} from "../../shared/alerts/actions";
import {IReelsCreator} from "../reducers/reelsCreator";
import ReelDialog from "../components/ReelDialog";
import {getReelsFormValues, ReelFormError} from "../helpers/get_reels_form_values";

interface IStateProps {
    reelDetail: IReelForms;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    formActions: FormActions;
    actions: {
        addErrorAlert: typeof addErrorAlert;
        clearReelAddEditViewState: typeof clearReelAddEditViewState;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
        reelAdd: typeof reelAdd;
        reelCreatorAdd: typeof reelCreatorAdd;
        reelCreatorEditPatch: typeof reelCreatorEditPatch;
    };
}

interface IOwnProps extends RouterProps {
    hoc: HocModalix;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    isCancelDialogOpen: boolean;
    hasBeenMounted: boolean;
}

@modalix(ReelModal)
class ReelAddViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isCancelDialogOpen: false,
            hasBeenMounted: false
        };
    }

    public componentDidMount() {
        this.getDefaultFormValues();
        this.setState({hasBeenMounted: true});
    }

    public componentWillUnmount(): void {
        this.props.actions.clearReelAddEditViewState();
    }

    private getDefaultFormValues = () => {
        this.props.formActions.update({
            type: null,
            creator: {
                value: ""
            },
            title: "",
            action_button_enabled: false,
            action_button_name: "",
            action_button_url: "",
            action_button_offer: null,
            action_button_property_enabled: false,
            action_button_property: null,
            display_date_range: {
                lower: null,
                upper: null
            },
            region: {
                value: ""
            }
        });
    };

    private onCancelDialogOpen = () => {
        this.setState({isCancelDialogOpen: false});
    };

    private onOkDialogOpen = () => {
        const link = url("app:in:video:reel:list");
        this.props.router.push(link);
    };

    private onCancel = () => {
        this.setState({isCancelDialogOpen: true});
    };

    private onSubmit = async () => {
        try {
            const {reelCreator, reel} = getReelsFormValues(this.props.reelDetail.form);
            const savedCreator = await this.handleCreatorFormSubmit(reelCreator);

            if (savedCreator) {
                this.handleCreatorFieldsUpdate(savedCreator);
                return this.handleFormSubmit({
                    ...reel,
                    creator: savedCreator.pk
                });
            }
        } catch (error) {
            const message = error.name === "ReelFormError" ? error.message : "Wystąpił błąd podczas zapisywania reels";
            this.props.actions.addErrorAlert(message);
        }
    };

    private handleCreatorFieldsUpdate = (creator: IReelsCreator) => {
        this.props.formActions.update({
            creator: {
                label: creator.name,
                value: creator.pk,
                image: creator.image
            }
        });
    };

    private handleCreatorFormSubmit = async (reelCreator: {pk: number; image?: string} | {name: string; image?: string}) => {
        if (!("pk" in reelCreator)) {
            return this.props.actions.reelCreatorAdd({name: reelCreator.name, image: reelCreator.image || ""}) as any;
        }

        if ("pk" in reelCreator && reelCreator.image) {
            return this.props.actions.reelCreatorEditPatch(reelCreator.pk, {image: reelCreator.image}) as any;
        }

        return Promise.resolve(reelCreator);
    }

    private handleFormSubmit = async (creation: Omit<IReelFormValues, "creator_image">) => {
        (this.props.actions.reelAdd(creation as any) as any)
            .then((response: IReel) => {
                if (response != null) {
                    return response.pk;
                }
            })
            .then((id: number) => {
                if (id != null) {
                    const link = url("app:in:video:reel:edit", {id});
                    setTimeout(() => {
                        this.props.router.push(link);
                    }, 2000);
                }
            });
    };

    /**
     * Callback
    */
    private openPreviewModal = () => {
        this.props.hoc.modalix.show<any>({
            imageAdCreationFormData: this.props.reelDetail.form,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    /**
     * Render
     */

    public render() {
        const {request} = this.props.reelDetail;
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklama Reel</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={this.onSubmit}
                            >
                                Zapisz kreację
                            </Button>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd kreacji
                            </Button>
                            <Button
                                className="ttu ph-xxxl"
                                type="submit"
                                color="default"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={this.onCancel}
                            >
                                Anuluj
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        {this.state.hasBeenMounted && (
                            <ReelForm
                                onSubmit={this.onSubmit}
                                errors={this.props.reelDetail.errors ? this.props.reelDetail.errors.fieldErrors : null}
                                values={this.props.reelDetail.form}
                                updateFormAction={this.props.formActions.update}
                                handlePreview={() => this.openPreviewModal()}
                                handleCancel={this.onCancel}
                            />
                        )}
                        <ReelDialog
                            message="Czy na pewno chcesz porzucić wprowadzane zmiany?"
                            handleClose={this.onCancelDialogOpen}
                            onCancel={this.onCancelDialogOpen}
                            onOk={this.onOkDialogOpen}
                            isModalOpen={this.state.isCancelDialogOpen}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const ReelAddView = connect(mapStateToProps, mapActionsToProps)(ReelAddViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        reelDetail: store.video.reel.detail,
        contextPanelOpened: store.video.reel.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        formActions: bindActionCreators(addReelFormActions, dispatch),
        actions: bindActionCreators({
            addErrorAlert,
            reelCreatorAdd,
            reelCreatorEditPatch,
            reelAdd,
            openPanel,
            closePanel,
            openTab,
            clearReelAddEditViewState,
        }, dispatch)
    };
}
