import * as React from "react";
import * as classNames from "classnames";
import * as _ from "lodash";
import {FormControl, Tooltip, OverlayTrigger, FormGroup, ControlLabel} from "react-bootstrap";

import {HelpOverlay} from "../help_overlay";
import {FormComponent, FormComponentProps, FormComponentState} from "./formComponent";
import {Button} from "../button";
import {Icon} from "../icon";
import {randomString} from "../../helpers/utils";
import {modal, HocModal} from "../../decorators/modal";
import {pluralize} from "../../helpers/pluralize";

interface IFile extends Blob {
    readonly lastModified: number;
    readonly name: string;
}

// description of file raw data, kept in form Store
export interface FileValue {
    id: string; // input `id` attribute, we need this value in `toFormDataPost` logic
    stored: string; // file URL from API or empty string, it is used as preview
    selected: string; // recent (URL or base64) file name or empty string, set on image selected, removed on image remove
    base64value: string; // FileReader's result of `readAsDataURL` is string (base64) representation of file handler
}

interface IProps extends FormComponentProps<FileValue> {
    id?: string;
    label?: string;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
    className?: string;
    groupClassName?: string;
    maxSize?: number;
    allowedExtensions?: string[];
    onInvalidFile?: Function;
    hoc?: HocModal;
    imgFilePreview?: boolean;
    customPreview?: () => React.ReactNode;
    previewPath?: string;
    triggerCropOnFileChange?: (name: string) => void;
    multiple?: boolean;
}

interface IState extends FormComponentState {
    inputAndLabelKey: string;
}

@modal()
export class File extends FormComponent<FileValue, IProps, IState> {

    /**
     * Static
     */

    public static defaultProps: IProps = {
        name: "file",
        error: null,
        value: {
            stored: "",
            selected: "",
            base64value: "",
            id: ""
        },
        maxSize: 1024 * 1024 * 10,
        allowedExtensions: ["jpg", "jpeg", "png", "pdf"],
        imgFilePreview: false
    };

    public static emptyValue: FileValue = {
        id: randomString(),
        stored: "",
        selected: "",
        base64value: ""
    };

    public static toJSON(name: string, values: any): {[key: string]: string} {
        let value: FileValue = values[name];

        if (value.selected) {
            return { // save new file
                [name]: value.base64value
            };
        } else if (value.stored && !value.selected) {
            return { // delete current file
                [name]: ""
            };
        } else {
            return {}; // do not change file
        }
    }

    public static toFormData(name: string, values: any): void {
        throw new Error("File input is not serializable");
    }

    public static toFormDataPost(name: string, values: any): {[key: string]: unknown} {
        const isMultiple = values[name].length > 1;
        if (isMultiple) {
            const files = (document.getElementById(name) as HTMLInputElement).files;
            return { // update file
                [name]: files
            };
        }

        const file = (document.getElementById(values[name].id) as HTMLInputElement).files![0];
        let value: FileValue = values[name];
        if (file) {
            return { // update file
                [name]: file
            };
        } else if (value.stored && !value.selected) {
            return { // delete current file
                [name]: ""
            };
        } else { // do not change file
            return {};
        }
    }

    public static fromJSON(name: string, values: any): {[key: string]: FileValue} {
        const fileName = values[name] ? values[name].replace(/^.*[\\\/]/, "") : "";
        const rawFileValue: FileValue = {
            id: Math.random().toString(36).substr(2, 10),
            stored: values[name] || "", // keep API file path
            selected: fileName, // keep API file name
            base64value: ""
        };
        return {[name]: rawFileValue};
    }

    public static fromFormData(name: string, values: any): void {
        throw new Error("File input is not serializable");
    }

    public static isEmpty(value: FileValue): boolean {
        // NOTE: this logic may not work, please test when touching related logic
        return value == null || value === File.emptyValue;
    }

    public static isEqual(val1: FileValue, val2: FileValue): boolean {
        return _.isEqual(_.omit(val1, "id"), _.omit(val2, "id"));
    }

    /**
     * Lifecycle
     */

    constructor(props: IProps) {
        super(props);
        this.state = {inputAndLabelKey: randomString()};
    }

    private file: HTMLInputElement | null = null;

    /**
     * Helper
     */

    private isFileValid(file: IFile): boolean {
        const {props} = this;
        let fileSplit = file.name.split(".");
        let ext = fileSplit[fileSplit.length - 1];
        ext = ext ? ext.toLowerCase() : "";
        return !((props.maxSize && file.size > props.maxSize) || (props.allowedExtensions && props.allowedExtensions.length && props.allowedExtensions.indexOf(ext) === -1));
    }

    private getPreviewImageSrc(): string | null {
        if (!this.props.value) {
            return null;
        }

        const {selected, stored, base64value} = this.props.value;
        const {previewPath} = this.props;

        if (base64value) {
            return base64value;
        } else if (stored && selected) {
            return previewPath || stored;
        }

        return null;
    }

    /**
     * Callbacks
     */

    public onInputChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (!_.isFunction(this.props.onFormUpdate)) {
            return;
        }
        const fileList = (e.target as HTMLInputElement).files as FileList | [];
        if (fileList.length > 1) {
            const files: (FileValue | unknown)[] = await Promise.all(_.map(fileList, (f): Promise<FileValue | undefined> => {
                if (this.isFileValid(f)) {
                    return this.onValidMultiFileSelected(f);
                } else {
                    this.onInvalidFileSelected();
                    return Promise.resolve(undefined);
                }
            }));
            this.props.onFormUpdate(this.props.name, files as any); // (name: string, files: (FileValue | unknown)[]) => void
        } else if (fileList.length === 1) {
            const file =fileList[0];
            if (file) {
                if (this.isFileValid(file)) {
                    this.onValidFileSelected(file);
                } else {
                    this.onInvalidFileSelected();
                }
            }
        } else {
            this.onRemoveFile();
        }
    };

    public onResetClick = (): void => {
        this.onRemoveFile();
    }

    public onRemoveFile(): void {
        this.props.onFormUpdate && this.props.onFormUpdate(this.props.name, {
            ...this.props.value,
            selected: "",
            base64value: ""
        });
        this.setState({inputAndLabelKey: randomString()}); // podmieniamy key, zeby input sie przerenderowal i wyczyscil plik, file inputy sa pojebane
    }

    public onValidFileSelected(file: IFile): void {
        const FR = new FileReader();
        FR.onload = (e: any) => {
            this.props.onFormUpdate && this.props.onFormUpdate(this.props.name, {
                ...this.props.value,
                selected: file.name,
                base64value: e.target.result
            });
        };
        FR.readAsDataURL(file);
        if (this.props.triggerCropOnFileChange) {
            this.props.triggerCropOnFileChange(this.props.name);
        }
    }

    public onValidMultiFileSelected = async (file: IFile): Promise<FileValue> => {
        return new Promise((resolve) => {
            const FR = new FileReader();
            FR.onload = (e: any) => {
                const fileValue: FileValue = {
                    id: randomString(),
                    stored: "",
                    selected: file.name,
                    base64value: e.target.result
                };
                resolve(fileValue);
            };
            FR.readAsDataURL(file);
        });
    };

    public onInvalidFileSelected(): void {

        this.setState({inputAndLabelKey: randomString()});

        const modal = (
            <div className="tal">
                Wybrano niepoprawny plik. Proszę upewnić się, że:

                <ul className="mb-0 pl-xl">
                    <li>jego rozmiar nie przekracza {this.props.maxSize! / (1024 ** 2)}MB,</li>
                    <li>plik jest w jednym z formatów: {this.props.allowedExtensions && this.props.allowedExtensions.join(", ")}.</li>
                </ul>
            </div>
        );

        this.props.hoc!.modal.show(
            () => modal,
            {
                modalProps: {
                    bsStyle: "info",
                    confirmBsStyle: "info",
                    title: "Niepoprawny plik",
                    confirmText: "Wybierz inny plik",
                    cancelText: "Cofnij"
                },
                name: "Ostrzeżenie"
            },
            () => {
                this.file && this.file.click();

            }
        );
    }

    /**
     * Render
     */

    private renderHelp(): JSX.Element | null {
        const {props} = this;
        if (props.helpText) {
            return (
                <div className="dib ml-md">
                    <HelpOverlay id={props.id} placement={props.helpPlacement || "right"} className={props.helpClassName}>
                        {props.helpText}
                    </HelpOverlay>
                </div>
            );
        }
        return null;
    }

    private renderPreview(): JSX.Element | null {
        const {props} = this;
        if (props.value && props.value.selected && !props.value.base64value) {
            return (
                <a className="fs12 c-gray fwb" href={props.value.stored} target="_blank">podgląd</a>
            );
        }
        return null;
    }

    private renderLabel(): JSX.Element | null {
        const {props} = this;

        if (props.label) {
            return (
                <div className="dt w100">
                    <div className="dtc vat">
                        <ControlLabel htmlFor={props.value && props.value.id}>
                            {props.label}{this.renderHelp()}
                        </ControlLabel>
                    </div>

                    <div className="dtc vat tar">{this.renderPreview()}</div>
                </div>
            );
        }
        return null;
    }

    private renderChooseButton(hasMultiFiles: boolean): JSX.Element {
        const {value, id} = this.props;
        return (
            <ControlLabel className="btn btn-default bdrs-0 file-choose" htmlFor={hasMultiFiles ? id : value && value.id}>
                {(hasMultiFiles || value && value.selected) ? "Zmień" : "Wybierz"}
            </ControlLabel>
        );
    }

    private renderCancelButton(): JSX.Element {
        const {props} = this;
        const tooltip = <Tooltip id={props.id}>Odrzuć plik</Tooltip>;
        return (
            <OverlayTrigger placement="top" overlay={tooltip}>
                <Button className="bdrs-0 file-cancel" color="default" onClick={this.onResetClick}>
                    <Icon icon="delete" />
                </Button>
            </OverlayTrigger>
        );
    }

    private renderField(hasMultiFiles: boolean): JSX.Element {
        const {props, state} = this;
        const attachmentsPluralize = hasMultiFiles && pluralize(this.file!.files!.length, ["załącznik", "załączniki", "załączników"]);
        const placeholder = hasMultiFiles ? `Dodano ${this.file!.files!.length} ${attachmentsPluralize}` : props.value && props.value.selected || "Brak pliku";

        return (
            <div>
                <input
                    ref={(elem) => {this.file = elem;}}
                    className="file-field"
                    type="file"
                    id={hasMultiFiles ? props.id : props.value && props.value.id}
                    onChange={this.onInputChange}
                    key={state.inputAndLabelKey}
                    multiple={props.multiple}
                />

                <div className="dt w100">
                    <div className="dtc vam psr">
                        <label className="psa t0 r0 b0 l0 z1 curp m-0" htmlFor={props.value && props.value.id} />

                        <FormControl ref="display" className="file-text bdrw-0"
                            type="text"
                            id={this.props.name}
                            placeholder={placeholder}
                            disabled
                            multiple={props.multiple}
                        />
                    </div>

                    <div className="dtc vam nowrap file-buttons">
                        {this.renderChooseButton(hasMultiFiles)}
                        {this.renderCancelButton()}
                    </div>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const {props} = this;
        const hasMultiFiles = this.file && this.file.files && this.file.files instanceof FileList && this.file.files.length > 1 || false;
        const className = classNames("file-field-group dtc w100", props.label ? "vam" : "vat", this.props.className, {
            "file-cancel-visible": !!hasMultiFiles || props.value && !!props.value.selected
        });

        const groupClassName = classNames("dt w100 form-group", this.props.groupClassName);
        const prevClassName = classNames("dtc vam pr-md", props.label ? "vam" : "vat");

        const src = this.getPreviewImageSrc();

        // File preview
        const filePreviewStyle = {
            backgroundImage: `url("${src}")`
        };
        let filePreview: JSX.Element | undefined;
        if (props.imgFilePreview && src) {
            filePreview = (
                <div className={prevClassName}>
                    <div className="file-field-preview" style={filePreviewStyle} />
                </div>
            );
        } else if (props.customPreview && src) {
            filePreview = (
                <div className={prevClassName}>
                    {props.customPreview()}
                </div>
            );
        }

        if (props.error) {
            const tooltip = <Tooltip className="tooltip-error" id={`tooltip-${name}`}>{props.error}</Tooltip>;
            return (
                <div className={groupClassName}>
                    {filePreview}
                    <FormGroup className={className} validationState="error">
                        {this.renderLabel()}
                        <OverlayTrigger placement="top" overlay={tooltip}>
                            {this.renderField(hasMultiFiles)}
                        </OverlayTrigger>
                        <FormControl.Feedback />
                    </FormGroup>
                </div>
            );
        }

        return (
            <div className={groupClassName}>
                {filePreview}
                <FormGroup className={className}>
                    {this.renderLabel()}
                    {this.renderField(hasMultiFiles)}
                </FormGroup>
            </div>
        );
    }
}
