import * as React from "react";

export const FavouriteIcon = () => {
    return (
        <div className="favourite-icon-holder bdrs-50p bgc-black-o30 df fai-center fjc-center psa t10 r10 z1">
            <div className="df fai-center fjc-center favourite-icon favourite-icon-light">
                <div className="heart-icon" />
            </div>
        </div>
    );
};
