import * as React from "react";
import * as _ from "lodash";
import {connect} from "react-redux";
import * as classNames from "classnames";
import {FormControl, Tooltip, OverlayTrigger, FormGroup, ControlLabel} from "react-bootstrap";

import {HelpOverlay} from "../help_overlay";
import {FormComponent, FormComponentProps, FormComponentState} from "./formComponent";
import {arrayField} from "./array_field";
import {addErrorAlert} from "../../actions/alert";
import {alertText} from "../../constants/alert";
import {RequestState} from "../../helpers/util";
import {Dispatch} from "../../interfaces/dispatch";


type InputValue = string;

export interface InputProps extends FormComponentProps<InputValue> {
    dispatch?: Dispatch;
    componentClass?: string;
    formControlHolderClassName?: string;
    format?: string;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
    info?: JSX.Element;
    warning?: boolean;
    label?: string;
    maxLength?: number;
    min?: number;
    max?: number;
    placeholder?: string;
    autoFocus?: boolean;
    type?: string;
    groupClassName?: string;
    className?: string;
    required?: boolean;
    onChange?: React.FormEventHandler;
    onBlur?: React.FormEventHandler;
    onClick?: React.FormEventHandler;
    id?: string;
    labelClassName?: string;
    disabled?: boolean;
    step?: string;
    bsSize?: string;
    maxNumberLength?: number;
    readOnly?: boolean;
}
interface InputState extends FormComponentState {
    focused?: boolean;
    latestTidyValue?: InputValue;       // value set when change `dirty` state
    dirty?: boolean;                    // does input faced `change` event
}

@connect()
export class Input extends FormComponent<InputValue, InputProps, InputState> {

    public static emptyValue: InputValue = "";

    public static toJSON(name: string, values: any): any {
        let value = values[name] == null || values[name] === Input.emptyValue ? null : values[name];
        return {[name]: value};
    }

    public static toFormData(name: string, values: any): any {
        let value = values[name];
        if (!Input.isEmpty(value)) {
            return {[name]: values[name]};
        }
        return {};
    }

    public static toFormDataPost(name: string, values: any): any {
        return {
            [name]: values[name] == null ? "" : values[name]
        };
    }

    public static fromJSON(name: string, values: any): any {
        let value = values[name] == null ? Input.emptyValue : values[name];
        return {[name]: value};
    }

    public static fromFormData(name: string, values: any): any {
        let value = values[name] == null ? Input.emptyValue : values[name];
        return {[name]: value};
    }

    public static isEmpty(value: string): boolean {
        return value == null || value === Input.emptyValue;
    }

    constructor(props: InputProps) {
        super(props);
        this.state = {
            focused: false,
            latestTidyValue: Input.emptyValue,
            dirty: false
        };
    }

    public componentDidUpdate(prevProps: InputProps): void {
        if (prevProps.requestState !== RequestState.Error && this.props.requestState === RequestState.Error && this.props.error) {
            this.props.dispatch!(addErrorAlert(
                alertText.errorTitle, this.props.error, true, 5000
            ));
        }
    }

    /**
     * Callbacks
     */

    public onChange(e: React.FormEvent): void {
        if (this.props.onChange) {
            this.props.onChange(e);
        } else if (_.isFunction(this.props.onFormUpdate)) {
            let value = (e.target as HTMLInputElement).value;
            if (this.props.type === "number" && _.isNumber(this.props.maxNumberLength) && value.length > this.props.maxNumberLength) {
                value = value.slice(0, this.props.maxNumberLength);
            }
            if (this.props.format === "thousand") {
                const match = value && value.match(/\d/g);
                value = match ? match.join("") : "";
            }
            this.props.onFormUpdate(this.props.name, value);
        }
        if (!this.state.dirty) {
            const latestTidyValue = this.props.value ? this.props.value.toString() : Input.emptyValue;
            this.setState({latestTidyValue, dirty: true});
        }
    }

    public onBlur(e: React.FocusEvent): void {
        const {value, name, onBlur, onValueChange} = this.props;
        const {latestTidyValue, dirty} = this.state;

        if (onBlur) {
            onBlur(e);
        }
        else if (_.isFunction(onValueChange) && dirty && latestTidyValue !== value) {
            onValueChange!(name, value);
            this.setState({dirty: false});
        }

        this.setState({focused: false});
    }

    public onFocus(e: React.FocusEvent): void {
        this.setState({focused: true});
    }

    public onKeyPress(e: React.KeyboardEvent): void {
        if (e.key === "Enter") {
            this.setState({dirty: false});
        }
    }

    /**
     * Render methods
     */

    private renderLabel(): JSX.Element | null {
        const {id, label, helpText, helpPlacement, helpClassName, labelClassName} = this.props;

        // Help overlay
        let helpOverlay: JSX.Element | undefined;
        if (helpText) {
            const helpOverlayId = `${id}helpOverlay`;
            helpOverlay = (
                <div className="dib ml-md">
                    <HelpOverlay id={helpOverlayId} placement={helpPlacement || "right"} className={helpClassName}>
                        {helpText}
                    </HelpOverlay>
                </div>
            );
        }

        if (label) {
            return (
                <ControlLabel htmlFor={id} className={labelClassName}>
                    {label}
                    {helpOverlay}
                </ControlLabel>
            );
        }

        return null;
    }

    private renderInputHolder(): JSX.Element {
        let value = this.props.value || Input.emptyValue;

        if (this.props.format === "thousand" && !this.state.focused) {
            const parsed = parseInt(value as string, 10);
            value = value && parsed === parsed ? parsed.toLocaleString() : value;
        }

        const innerProps: any = _.assign({},
            _.omit(
                this.props, [
                    "groupClassName", "label", "info", "warning", "onValueChange", "onFormUpdate", "formName", "error", "helpText",
                    "helpPlacement", "labelClassName", "formControlHolderClassName", "dispatch", "requestState",
                    "maxNumberLength", "markable"
                ]
            ), {
                ref: "input",
                value,
                onBlur: this.onBlur.bind(this),
                onFocus: this.onFocus.bind(this),
                onChange: this.onChange.bind(this),
                onKeyPress: this.onKeyPress.bind(this)
            }
        );
        const input = this.props.componentClass ? <FormControl id={name} {...innerProps} /> : <FormControl type="text" id={name} {...innerProps} />;
        return this.props.formControlHolderClassName ? <div className={this.props.formControlHolderClassName}>{input}</div> : input;
    }

    public render(): JSX.Element | null {
        const {name, error, info, warning} = this.props;
        const groupClassName = classNames(this.props.groupClassName, this.props.markable && !Input.isEmpty(this.props.value) ? "is-active" : "");

        if (this.props.type === "hidden") {
            return null;
        }
        if (warning) {
            return (
                <FormGroup className={groupClassName} validationState="warning">
                    {this.renderLabel()}
                    {info}
                    {this.renderInputHolder()}
                    <FormControl.Feedback />
                </FormGroup>
            );
        }
        if (error) {
            const tooltip = (<Tooltip className="tooltip-error" id={`tooltip-${name}`}>{error}</Tooltip>);
            return (
                <FormGroup className={groupClassName} validationState="error">
                    {this.renderLabel()}
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        {this.renderInputHolder()}
                    </OverlayTrigger>
                    <FormControl.Feedback />
                </FormGroup>
            );
        }
        return (
            <FormGroup className={groupClassName}>
                {this.renderLabel()}
                {this.renderInputHolder()}
            </FormGroup>
        );
    }
}

export const ArrayInput = arrayField(Input);
