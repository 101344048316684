import * as React from "react";
import * as classnames from "classnames";

import StyleProps from "../../../../../shared/ts/interfaces/style";
import Icon from "../../icon/Icon";

export interface BaseAlertProps extends StyleProps {
    icon: string;
    onCloseClick: () => void;
    color?: string;
}


const BaseAlert: React.SFC<BaseAlertProps> = props => {
    const className = classnames("alert fs16 dt",props.className, {
        [`alert-${props.color}`]: !!props.color
    });

    return (
        <div className={className}>
            <div className="icon mr-lg">
                <Icon icon={props.icon} />
            </div>

            <div className="dtc vam">
                {props.children}
            </div>

            <div className="dtc vat pl-lg">
                <button className="close close-icon" type="button" onClick={props.onCloseClick}>
                    &times;
                </button>
            </div>
        </div>
    );
};

export default BaseAlert;
