import {isArray, each} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, Action} from "../../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";

import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {createFormActionTypes} from "../../../../../shared/ts/helpers/create_form_action_types";
import {createFormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {invalidateSurpriseAdvertsList} from "./fetch";
import {ISurpriseAd} from "../../reducers/surprise";
import {nphApiLink} from "../../../project/route/nph_api_link";

export const SURPRISE_ADD_PREFIX = "surprise_ad/ADD";
export const surpriseRequestTypes = createRequestActionTypes(SURPRISE_ADD_PREFIX);

export const addSurpriseFormTypes = createFormActionTypes(SURPRISE_ADD_PREFIX);
export const addSurpriseFormActions = createFormActions(addSurpriseFormTypes);

export const surpriseAdsAdd = (values: ISurpriseAd) => (dispatch: Dispatch): Promise<ISurpriseAd | null> => {
    dispatch<Action>({type: surpriseRequestTypes.start});
    return postRequest(nphApiLink.surpriseAd.add(), values)
        .then((surpriseAD: ISurpriseAd) => {
            dispatch<Action>({type: surpriseRequestTypes.success});
            dispatch(invalidateSurpriseAdvertsList());
            dispatch(addSuccessAlert("Dodano reklamę zakrytą"));
            return surpriseAD;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, SURPRISE_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", SURPRISE_ADD_PREFIX));
            }
            dispatch<ErrorAction>({type: surpriseRequestTypes.error, error: responseError.appError});
            return null;
        }));
};


export const CLEAR_SURPRISE_ADD_EDIT_VIEW_STATE = "surprise/add_edit/CLEAR_ADD_EDIT_VIEW_STATE";
export const clearSurpriseAddEditViewState = () => ({type: CLEAR_SURPRISE_ADD_EDIT_VIEW_STATE});

export const CLEAR_SURPRISE_ADD_EDIT_ERRORS = "surprise/add_edit/CLEAR_ADD_EDIT_ERRORS";
export const clearSurpriseAddEditErrors = () => ({type: CLEAR_SURPRISE_ADD_EDIT_ERRORS});
