import * as React from "react";

interface LoggedOutWrapperProps {}

export default class LoggedOutWrapper extends React.Component<LoggedOutWrapperProps, {}> {
    public render() {
        return (
            <section className="df fai-center fjc-center sign-in-out-holder">
                {this.props.children}
                <div className="psa t0 r0 b0 l0 black-opacity-light" />
            </section>
        );
    }
}
