import * as React from "react";
import * as classnames from "classnames";
import {isFunction} from "lodash";
import {WrappedFieldProps} from "redux-form";

import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {Store} from "../../../../project/reducer";
import FieldLabel from "../../FieldLabel";
import FieldError from "../../FieldError";


export type InputSize = "sm" | "lg";

interface InputFieldProps extends StyleProps, WrappedFieldProps<Store> {
    error?: string;
    type?: string;
    label?: string;
    placeholder?: string;
    onValueUpdate?: (name: string) => void;
    size: InputSize;
    groupClassName?: string;
    maxLength?: number;
}

interface InputFieldState {
    latestUpdateValue: string;
}

export default class InputField extends React.Component<InputFieldProps, InputFieldState> {

    constructor(props: InputFieldProps) {
        super(props);
        this.state = {latestUpdateValue: props.input.value};
    }

    protected onFocus = (e: React.MouseEvent) => {
        this.setState({latestUpdateValue: this.props.input.value});
    };

    private onBlur = (e: React.MouseEvent) => {
        const {input} = this.props;
        input.onBlur(e);

        if (isFunction(this.props.onValueUpdate)) {
            if (this.state.latestUpdateValue !== input.value) {
                // call only when value has changed
                this.props.onValueUpdate(input.name);
            }
        }
    };

    public render(): JSX.Element {
        const {error, input, label, type, placeholder, className, size, groupClassName, maxLength} = this.props;
        const holderClassName = classnames("form-group", groupClassName, error && "has-error");
        const fieldClassName = classnames("form-control", className, {
            [`input-${size}`]: !!size
        });

        // Field
        const field = (
            <input {...input}
                id={input.name}
                className={`${fieldClassName} form-control`}
                placeholder={placeholder}
                maxLength={maxLength}
                type={type}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
            />
        );

        return (
            <div className={holderClassName}>
                {label && <FieldLabel label={label} htmlFor={input.name}/>}
                {error ? <FieldError error={error}>{field}</FieldError> : field}
            </div>
        );
    }
}
