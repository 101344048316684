import * as React from "react";
import {WrappedFieldProps} from "redux-form";
import {isFunction} from "lodash";
import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {Store} from "../../../../project/reducer";
import FieldLabel from "../../FieldLabel";
import FieldError from "../../FieldError";
import * as classnames from "classnames";
import Tooltip from "../../../overlays/Tooltip";
import Icon from "../../../icon/Icon";
import Input from "../input/Input";

interface FileFieldProps extends StyleProps, WrappedFieldProps<Store> {
    label?: string;
    allowedExtensions?: string[];
    maxSize?: string;
    groupClassName?: string;
    error?: string;
    onValueUpdate?: (name: string) => void;
}

interface FileFieldState {
    triggerOnValueUpdate?: boolean;
    error?: string | null;
}

interface validationResult {
    success: boolean;
    error: string | null;
}

export default class FileField extends React.Component<FileFieldProps, FileFieldState> {
    private defaultInputValue = {
        name: "",
        base64: null,
        url: null
    };
    private inputRef: any;

    constructor(props: FileFieldProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.clearField = this.clearField.bind(this);
        this.state = {
            triggerOnValueUpdate: false,
            error: null
        };
    }

    public componentDidUpdate() {
        const {input: {name}, onValueUpdate} = this.props;
        if (this.state.triggerOnValueUpdate) {
            if (isFunction(onValueUpdate)) {
                this.setState({triggerOnValueUpdate: false});
                onValueUpdate(name);
            }
        }
    }

    private onValidationSuccess(file: File) {
        const FR = new FileReader();
        FR.onload = (e: any) => {
            const base64 = e.target.result;
            this.props.input.onChange({
                name: file.name,
                base64,
                url: null
            });
            this.setState({error: null, triggerOnValueUpdate: true});
        };
        FR.readAsDataURL(file);
    }

    private isFileValid(file: any): validationResult {
        const {props} = this;
        const resultSuccess = {
            success: true,
            error: null
        };
        const resultFailed = {
            success: false,
            error: ""
        };

        let fileSplit = file.name
            .toLowerCase()
            .split(".");
        let ext = fileSplit[fileSplit.length - 1];
        ext = ext || "";

        let errorMessage = null;
        let sizeIsValid = true;
        let extensionIsValid = true;

        if (props.allowedExtensions) {
            extensionIsValid = !(
                props.allowedExtensions
                && props.allowedExtensions.length
                && props.allowedExtensions.indexOf(ext) === -1
            );
            if (!extensionIsValid) {
                const extensions = props.allowedExtensions.join(', ');
                resultFailed.error = "Niepoprawny format pliku. Wspierane formaty to: " + extensions;
                return resultFailed;
            }
        }
        if (props.maxSize) {
            const maxSize = parseInt(props.maxSize);
            sizeIsValid = file.size <= maxSize;
            if (!sizeIsValid) {
                const fileSize = (maxSize / 1024 ** 2).toFixed(2);
                resultFailed.error = "Plik jest zbyt duży. Rozmiar pliku nie może przekroczyć: " + fileSize + " MB";
                return resultFailed;
            }
        }
        return resultSuccess;
    }

    private onChange(e: React.SyntheticEvent) {
        const {meta: {dirty}, input} = this.props;
        const target = e.target as any;
        let file = target.files && target.files.length && target.files[0];
        if (file) {
            const validationResult = this.isFileValid(file);
            if (validationResult.success) {
                this.onValidationSuccess(file);
            } else {
                this.setState({error: validationResult.error});
                this.props.input.onChange(this.defaultInputValue);
            }
        }
    }

    private clearField() {
        const {meta: {dirty}, input} = this.props;
        this.props.input.onChange(this.defaultInputValue);
        this.inputRef.value = "";
        this.setState({triggerOnValueUpdate: true});
    }

    private renderChooseButton(): JSX.Element {
        const {input, error} = this.props;
        return (
            <label className={`btn btn-default choose-button${input.value && input.value.name ? "-short" : ""}${error ? "-error" : ""}`} htmlFor={input.name}>
                {(input.value && input.value.name) ? "Zmień" : "Wybierz"}
            </label>
        );
    }

    private renderCancelButton(): JSX.Element {
        const {input, error} = this.props;
        return (
            <Tooltip content="Odrzuć plik" id={input.name} placement="top">
                <div className={`btn btn-default file-delete${input.value && input.value.name ? "-visible" : ""}${error ? "-error" : ""}`} onClick={this.clearField}>
                    <Icon icon="delete" />
                </div>
            </Tooltip>
        );
    }

    public render(): JSX.Element {
        const {error, input, label, className, allowedExtensions, groupClassName} = this.props;
        const holderClassName = classnames("form-group", groupClassName, error && "has-error");
        const fieldClassName = classnames("form-control", className);

        const errorMessage = error || this.state.error;

        const allowed = allowedExtensions
            && allowedExtensions.map(
                (ext: string): string => "." + ext
            ).join(",");

        const field = (
            <input
                ref={input => this.inputRef = input}
                name={input.name}
                id={input.name}
                className={`${fieldClassName} file-field`}
                type="file"
                accept={allowed}
                onChange={this.onChange}
            />
        );

        const renderField = (
            <div>
                {field}
                <div className="render-field-holder">
                    <div className="field-holder">
                        <label className="field-label" htmlFor={input.name} />
                        <Input
                            type="text"
                            name={`${input.name}__placeholder`}
                            placeholder={input.value && input.value.name || "Brak pliku"}
                            disabled
                            className="field-input"
                            groupClassName="mb-0"
                        />
                    </div>
                    <div className="button-holder">
                        {this.renderChooseButton()}
                        {this.renderCancelButton()}
                    </div>
                </div>
            </div>
        );

        return (
            <div className={holderClassName}>
                {label && <FieldLabel label={label} htmlFor={input.name}/>}
                {errorMessage ? <FieldError error={errorMessage}>{renderField}</FieldError> : renderField}
            </div>
        );
    }
}
