import * as React from "react";

import {middleware} from "../../../../shared/ts/decorators/middleware";
import {Panel, PanelBody} from "../../shared/panel/Panel";

import LoginForm from "../components/LoginForm";
import {completedAuth} from "../actions/completed_auth";


interface LoginViewProps {}


@middleware(completedAuth())
export default class LoginView extends React.Component<LoginViewProps, {}> {

    public render(): JSX.Element {
        return (
            <Panel className="login-holder mb-0 psr z1">
                <PanelBody className="panel-body df fd-row">
                    <div className="img-holder w50 dn pr-xl">
                        <div className="login-image t0 r20 b0 l0" />
                    </div>

                    <div className="login-form-holder">
                        <div className="w100">
                            <h3 className="login-form-header fwb mt-0 fs24">Zaloguj się do Panelu Handlowca</h3>

                            <LoginForm/>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        );
    }
}
