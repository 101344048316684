import {find, map, filter, assign} from "lodash";

import {SelectOption} from "../../../shared/ts/components/forms/select";


/**
 * Data interfaces
 */
interface ValueLabelType {
    value: number;
    label: string;
}

interface PropertyType {
    pk: number;
    name: string;
    name_plural: string;
}

/**
 * Main data object
 */
interface CachedData {
    campaign_business_statuses: ValueLabelType[];
    campaign_statuses: ValueLabelType[];
    country_codes: ValueLabelType[];
    offer_types: PropertyType[];
}

const _data: CachedData = {
    campaign_business_statuses: [],
    campaign_statuses: [],
    country_codes: [],
    offer_types: []
};

/**
 * Data API
 */
interface DataAPI {
    set: (value: any) => void;
    getName: (name: number) => any;
    getOptions: () => {value: string | number; label: string}[];
}

const createValueLabel = (field: keyof CachedData) => ({
    set: (valueLabels: ValueLabelType[]) => (_data[field] as ValueLabelType[]) = valueLabels,
    getName: (value: number) => {
        const found = find(_data[field] as ValueLabelType[], elem => elem.value === value);
        return found && found.label || "";
    },
    getOptions: () => map(_data[field] as ValueLabelType[], elem => ({value: elem.value, label: elem.label}))
});

const campaign_statuses = assign(createValueLabel("campaign_statuses"), {
    getSelectableOptions: (): {value: string | number; label: string}[] => {
        const blackList = [3, 4, 5];
        const field = "campaign_statuses";
        const selectable = filter(_data[field], elem => !blackList.includes(elem.value));
        return map(selectable, elem => ({value: elem.value, label: elem.label}));
    }
}) as DataAPI & {getSelectableOptions: () => SelectOption[]};

const country_codes = assign(createValueLabel("country_codes"), {
    getOptions: () => map(_data.country_codes, (code: ValueLabelType) => ({value: code.value, label: `${code.label} (+${code.value})`}))
}) as DataAPI;

const offer_types = {
    set: (types: PropertyType[]) => _data.offer_types = types,
    getName: (pk: number) => {
        const found = find(_data.offer_types, elem => elem.pk === pk);
        return found && found.name || "";
    },
    getNamePlural: (pk: number) => {
        const found = find(_data.offer_types, elem => elem.pk === pk);
        return found && found.name_plural || "";
    },
    getOptions: () => map(_data.offer_types, (p: PropertyType) => ({value: p.pk, label: p.name_plural}))
};


export const Data = {
    campaign_business_statuses: createValueLabel("campaign_business_statuses"),
    campaign_statuses,
    country_codes,
    offer_types
};
