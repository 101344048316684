import {create, each, isArray} from "lodash";

import {createRequestActionTypes, RequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, ResponseAction} from "../../../../../shared/ts/interfaces/dispatch";
import {patchRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {TDA_ADD_PREFIX, tdaRequestTypes} from "./add";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {ITdaAd} from "../../reducers/tda";

const createTdaEdit = (type: string, actionTypes: RequestActionTypes) => (id: number, jsonData: ITdaAd) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.tdaAd.edit({id});
    return patchRequest(urlString, jsonData)
        .then((tda: ITdaAd) => {
            dispatch<ResponseAction<any>>({type: actionTypes.success, result: tda});
            dispatch(addSuccessAlert("Zapisano kreację tda", type));
            return tda;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, TDA_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", EDIT_TDA_PATCH));
            }
            dispatch<ErrorAction>({type: tdaRequestTypes.error, error: responseError.appError});
            return null;
        }));
};

export const CLEAR_TDA_EDIT_VIEW_STATE = "tda/CLEAR_TDA_EDIT_VIEW_STATE";
export const clearTdaEditViewState = () => ({type: CLEAR_TDA_EDIT_VIEW_STATE});

const EDIT_TDA_PATCH = "tda/edit/EDIT_TDA_PATCH";
export const tdaEditPatchTypes = createRequestActionTypes(EDIT_TDA_PATCH);
export const tdaEditPatch = createTdaEdit(EDIT_TDA_PATCH, tdaEditPatchTypes);
