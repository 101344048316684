import * as React from "react";

interface IProps {
    src: string;
    height?: string;
    width?: string;
}

export const ImagePreview = (props: IProps) => {
    return (
        <div className="file-field-preview-auto-size">
            <img alt="" src={props.src} height={props.height} width={props.width} />
        </div>
    );
};
