import * as React from "react";
import * as _ from "lodash";
import * as classnames from "classnames";

import TableHeader, {TableHeaderProps} from "../../shared/table/TableHeader";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import Table from "../../../../shared/ts/components/table/Table";
import TableRow from "../../shared/table/TableRow";
import StyleProps from "../../../../shared/ts/interfaces/style";
import {SortDirection} from "../../../../shared/ts/decorators/sort";
import {IReel} from "../reducers/reels";
import {ReelListItem} from "./ReelListItem";

interface ReelListProps extends StyleProps {
    listItems: IReel[];
    onSort: (column: string) => void;
    sortName: string;
    sortDirection: SortDirection | null;
    loading?: boolean;
    onDeleteClick: (uuid: number) => void;
    onItemClick: (id: number) => void;
}

export class ReelList extends React.Component<ReelListProps, {}> {

    private renderLoader(): JSX.Element {
        return (
            <CenterBox className="vhc pt-lg">
                <Loader size="lg"/>
            </CenterBox>
        );
    }

    public render(): JSX.Element {
        const tbodyClassName = classnames({ "o40": !!this.props.loading });
        const loader = this.props.loading ? this.renderLoader() : null;
        const nativeListItem = _.map(this.props.listItems, (advert: IReel) => {
            const {title, create_date, pk, display_date_range, region} = advert;

            return <ReelListItem
                key={pk}
                title={title}
                createDate={create_date}
                id={pk}
                createdBy={advert.creator.name}
                region={region}
                displayDateRange={display_date_range}
                onDeleteClick={this.props.onDeleteClick}
                onClick={this.props.onItemClick}
            />;
        });

        const sortableHeaderProps = (sortName: string): TableHeaderProps => ({
            sortable: true,
            onHeaderClick: this.props.onSort,
            sortDirection: sortName === this.props.sortName ? this.props.sortDirection : null,
            sortName
        });

        return (
            <div className="psr">
                <Table hover className="table mb-0">
                    <thead>
                        <TableRow>
                            <TableHeader {...sortableHeaderProps("id")}>ID</TableHeader>

                            <TableHeader {...sortableHeaderProps("name")}>Nazwa</TableHeader>

                            <TableHeader {...sortableHeaderProps("create_date")}>Data utworzenia</TableHeader>

                            <TableHeader {...sortableHeaderProps("creator")}>Autor</TableHeader>

                            <TableHeader {...sortableHeaderProps("display_date_range")}>Data wyświetlania (od - do)</TableHeader>

                            <TableHeader {...sortableHeaderProps("region")}>Region</TableHeader>

                            <TableHeader />
                        </TableRow>
                    </thead>

                    <tbody className={tbodyClassName}>
                    {nativeListItem}
                    </tbody>
                </Table>

                {loader}
            </div>
        );
    }
}
