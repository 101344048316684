import {Dispatch} from "redux";
import {Action, ErrorAction, ResponseAction} from "../../../../shared/ts/helpers/interfaces";
import {patchRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {AddAlertAction, addSuccessAlert} from "../../../../shared/ts/actions/alert";
import {patchOfferGalleryOrderText} from "../../../../pd/ts/constants/texts/alert";
import {catch400, showError} from "../../../../shared/ts/helpers/errors";
import {alertText} from "../../../../shared/ts/constants/alert";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {nphApiLink} from "../../project/route/nph_api_link";

const PATCH_OFFER_GALLERY_ORDER = "external_partner/PATCH_OFFER_GALLERY_ORDER";
export const patchOfferGalleryOrderTypes = createRequestActionTypes(PATCH_OFFER_GALLERY_ORDER);

export const patchOfferGalleryOrder = (offerId: number, sortMap: Record<number, number>) => (dispatch: Dispatch) => {
    dispatch<Action>({type: patchOfferGalleryOrderTypes.start});

    const urlString = nphApiLink.offers.gallery.list({offerId});

    const sortedImgList = {
        image_sorts: sortMap
    };

    return patchRequest(urlString, sortedImgList)
        .then((res: undefined) => {
            dispatch({type: patchOfferGalleryOrderTypes.success, sortMap});
            dispatch<AddAlertAction>(addSuccessAlert(
                patchOfferGalleryOrderText.successTitle, patchOfferGalleryOrderText.successMessage, true, 5000
            ));
            return sortMap;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: patchOfferGalleryOrderTypes.error, error: responseError.appError});
            showError(dispatch, responseError.appError, alertText.error400Title, alertText.error400Message);
        }));
};
