import {map} from "lodash";

import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {Offer} from "../../shared/models/Offer";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {createDebouncedAction} from "../../common/create_debounced_request";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";


const asyncOffersSimple = (name: string): Promise<any> => {
    const urlString = appendQueryString(nphApiLink.offers.list(), {name, page_size: 10});

    return getRequest(urlString).then((json: any) => {
        const offers: Offer[] = json.results;
        return {
            options: offers.map((offer: Offer) => {
                return {
                    label: offer.name,
                    value: offer.pk,
                    pk: offer.pk,
                    name: offer.name,
                    type: offer.type,
                    vendor: offer.vendor
                };
            })
        };
    });
};
export const asyncOffers = createDebouncedAction(asyncOffersSimple, 500);

const asyncNativesOffersSimple = (name: string): Promise<any> => {
    const urlString = appendQueryString(nphApiLink.offers.list(), {name, page_size: 10});
    return getRequest(urlString).then((json: any) => {
        const offers: Offer[] = json.results;
        return {
            options: offers.map((offer: Offer) => {
                return {
                    label: offer.name,
                    value: offer.pk,
                    pk: offer.pk,
                    name: offer.name,
                    type: offer.type,
                    vendor: offer.vendor
                };
            })
        };
    });
};


import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {nphApiLink} from "../../project/route/nph_api_link";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";

const NATIVES_OFFER_SEARCH = "natives/offer_search";
export const nativesOfferSearchRequest = createRequestActionTypes(NATIVES_OFFER_SEARCH);

export const fetchOfferDetailForNatives = (dispatch: Dispatch) => (id: number): Promise<any> => {
    return getRequest(nphApiLink.offers.detail({offerId: id}))
        .then((offer: Offer) => {
            dispatch({type: nativesOfferSearchRequest.success, result: offer});
            return offer;
        })
        .catch(catch404(() => {
            return {
                label: id.toString(),
                value: id.toString()
            };
        }));
};

export const resetOfferDetailForNative = () => (dispatch: Dispatch) => dispatch({type: nativesOfferSearchRequest.reset});

export const asyncNativesOffer = createDebouncedAction(asyncNativesOffersSimple, 500);

export const fetchOffer = (id: number): Promise<any> => {
    return getRequest(nphApiLink.offers.detail({offerId: id}))
        .then((offer: Offer) => {
            return offer && {
                    label: offer.name,
                    value: offer.pk,
                    pk: offer.pk,
                    name: offer.name,
                    type: offer.type,
                    vendor: offer.vendor
                };
        })
        .catch(catch404(responseError => {
            return {
                label: id.toString(),
                value: id.toString()
            };
        }));
};

export const fetchOffers = (ids: number[]): Promise<any> => {
    const allPromises = map(ids, id => fetchOffer(id));
    return Promise.all(allPromises);
};
