import * as React from "react";
import {OverlayTrigger as BaseOverlayTrigger} from "react-bootstrap";

export type OverlayPlacement = "top" | "right" | "bottom" | "left";

export interface OverlayProps {
    overlay: React.ReactNode;
    placement?: OverlayPlacement;
}

export const Overlay: React.SFC<OverlayProps> = props => (
    <BaseOverlayTrigger overlay={props.overlay} placement={props.placement}>
        {props.children}
    </BaseOverlayTrigger>
);

Overlay.defaultProps = {
    overlay: "",
    placement: "top"
};

export default Overlay;
