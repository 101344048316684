import * as React from "react";
import * as _ from "lodash";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";
import ReactPaginate from "react-paginate";

type PaginationSize = "sm" | "lg";

interface PaginationProps extends StyleProps {
    activePage: number;
    totalCount: number;
    pageSize: number;
    size?: PaginationSize;
    onPageChange?: (page: number) => void;
}

export const Pagination: React.SFC<PaginationProps> = props => {
    const className = classnames(props.className, "pagination-holder" );
    const reactPaginateClassName = classnames(
        "pagination", {
            [`pagination-${props.size}`]: !!props.size
        });

    const pageCount = Math.ceil(props.totalCount / props.pageSize);

    const onPageChange = (page: {selected: number}) => {
        if (_.isFunction(props.onPageChange)) {
            props.onPageChange(page.selected + 1);
        }
    };
    // There's no need to render pagination
    if (_.isNaN(pageCount) || pageCount <= 1) {
        return <span />;
    }

    return (
        <div className={className}>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={<span>&hellip;</span>}
                breakClassName="break-me"
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
                containerClassName={reactPaginateClassName}
                activeClassName="active"
                forcePage={props.activePage - 1}
                onPageChange={onPageChange}
            />
        </div>
    );
};
