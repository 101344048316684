import {isArray, each} from "lodash";

import {RequestActionTypes, createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {
    Action,
    Dispatch, ErrorAction, LatestQueryAction, PaginatedResponse,
    PaginatedResponseAction
} from "../../../../../shared/ts/interfaces/dispatch";
import {getRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {Dictionary} from "../../../../../shared/ts/interfaces/structure";
import {PAGE_PARAM} from "../../../../../shared/ts/constants/queryParams";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {addErrorAlert} from "../../../shared/alerts/actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {appendQueryString} from "../../../../../shared/ts/helpers/append_query_string";
import {ITdaAd} from "../../reducers/tda";

export const createTdaFetch = (type: string, actionTypes: RequestActionTypes) => (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch<LatestQueryAction>({ type: actionTypes.start, latestQuery: queryObj });

    const urlString = appendQueryString(nphApiLink.tdaAd.base(), queryObj);

    return getRequest(urlString)
        .then((json: PaginatedResponse<ITdaAd>) => {
            dispatch<PaginatedResponseAction<ITdaAd>>({
                type: actionTypes.success,
                results: json.results,
                pagination: {
                    pageSize: json.page_size,
                    total: json.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }
            });
            return json;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, type)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", type));
            }
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};

export const createTdaDetailFetch = (type: string, actionTypes: RequestActionTypes) => (id: number) => (dispatch: Dispatch) => {
    const urlString = nphApiLink.tdaAd.view({id});

    return getRequest(urlString)
        .then((json: ITdaAd) => {
            dispatch<Action>({
                type: actionTypes.success
            });
            return json;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};

export const TDA_FETCH = "hero/FETCH";
export const tdaRequest = createRequestActionTypes(TDA_FETCH);
export const tdaFetch = createTdaFetch(TDA_FETCH, tdaRequest);

export const TDA_FETCH_DETAIL = "tda/FETCH_DETAIL";
export const tdaDetailRequest = createRequestActionTypes(TDA_FETCH_DETAIL);
export const tdaFetchDetail = createTdaDetailFetch(TDA_FETCH_DETAIL, tdaDetailRequest);

export const invalidateTdaAdvertsList = () => ({type: tdaRequest.reset});
