import {isArray, each} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, Action} from "../../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";

import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {createFormActionTypes} from "../../../../../shared/ts/helpers/create_form_action_types";
import {createFormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {invalidateReelList} from "./fetch";
import {IReel, IReelFormValues} from "../../reducers/reels";
import {transformReelsFormToFormData} from "../../helpers/transform_reels_form_to_form_data";

export const REEL_ADD_PREFIX = "reel/ADD";
export const reelRequestTypes = createRequestActionTypes(REEL_ADD_PREFIX);

export const addReelFormTypes = createFormActionTypes(REEL_ADD_PREFIX);
export const addReelFormActions = createFormActions(addReelFormTypes);

export const reelAdd = (values: IReelFormValues) => (dispatch: Dispatch): Promise<IReel | null> => {
    dispatch<Action>({type: reelRequestTypes.start});

    return postRequest(nphApiLink.reels.add(), transformReelsFormToFormData(values), null, {addDefaultHeaders: false})
        .then((reel: IReel) => {
            dispatch<Action>({type: reelRequestTypes.success});
            dispatch(invalidateReelList());
            dispatch(addSuccessAlert("Dodano reel"));
            return reel;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, REEL_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", REEL_ADD_PREFIX));
            }
            dispatch<ErrorAction>({type: reelRequestTypes.error, error: responseError.appError});
            return null;
        }));
};


export const CLEAR_REEL_ADD_EDIT_VIEW_STATE = "reel/add_edit/CLEAR_ADD_EDIT_VIEW_STATE";
export const clearReelAddEditViewState = () => ({type: CLEAR_REEL_ADD_EDIT_VIEW_STATE});

export const CLEAR_REEL_ADD_EDIT_ERRORS = "reel/add_edit/CLEAR_ADD_EDIT_ERRORS";
export const clearReelAddEditErrors = () => ({type: CLEAR_REEL_ADD_EDIT_ERRORS});
