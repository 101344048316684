import * as React from "react";

import {ModalProps} from "../../../../../shared/ts/decorators/modalix";
import {IMobileNativesFormValues} from "../../../advertisments/reducers/natives";
import {MonthlyCreationAdPreview} from "./MonthlyCreationAdPreview";

interface IProps extends ModalProps {
    modalTitle?: JSX.Element | string;
    monthlyCreationFormData: IMobileNativesFormValues;
}

export const MonthlyCreationAdModal = (props: IProps) => {
    return (
        <div className="modal-dialog modal-primary modal-native-ad" >
            <div className="bd-0 modal-content" tabIndex={0}>
                <div className="modal-header-box p-md fs16 modal-header text-center">
                    <button className="btn-close close" type="button" onClick={() => props.onReject()}>&times;</button>
                    <h4 className="modal-title">{props.modalTitle || "Podgląd reklam"}</h4>
                </div>
                <MonthlyCreationAdPreview monthlyCreationFormData={props.monthlyCreationFormData} />
            </div>
        </div>
    );
};
