import * as React from "react";
import * as _ from "lodash";
import * as classnames from "classnames";

import TableHeader, {TableHeaderProps} from "../../shared/table/TableHeader";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import Table from "../../../../shared/ts/components/table/Table";
import TableRow from "../../shared/table/TableRow";
import StyleProps from "../../../../shared/ts/interfaces/style";
import {SortDirection} from "../../../../shared/ts/decorators/sort";
import {NativeListItem} from "./NativeListItem";
import {INativeAd} from "../reducers/natives";
import {OfferType} from "../../../../shared/ts/constants/OfferType";

interface NativeListProps extends StyleProps {
    natives: INativeAd[];
    onSort: (column: string) => void;
    sortName: string;
    sortDirection: SortDirection | null;
    loading?: boolean;
    onDeleteClick: (uuid: number) => void;
    onItemClick: (id: number) => void;
}

export class NativeList extends React.Component<NativeListProps, {}> {

    private renderLoader(): JSX.Element {
        return (
            <CenterBox className="vhc pt-lg">
                <Loader size="lg"/>
            </CenterBox>
        );
    }


    public render(): JSX.Element {
        const tbodyClassName = classnames({ "o40": !!this.props.loading });
        const loader = this.props.loading ? this.renderLoader() : null;
        const nativeListItem = _.map(this.props.natives, (native: INativeAd) => {
            const {desktop_name, mobile_name, desktop_type, desktop_vendor, create_date, id, mobile_image, desktop_image} = native;
            return <NativeListItem
                key={id}
                name={desktop_name || mobile_name}
                vendor={desktop_vendor || "-"}
                createDate={create_date}
                id={id}
                offerType={desktop_type || OfferType.NONE}
                generatedNatives={[desktop_image, mobile_image]}
                createdBy={native.user.name}
                onDeleteClick={this.props.onDeleteClick}
                onClick={this.props.onItemClick}
            />;
        });

        // Sortable table columns
        const sortableHeaderProps = (sortName: string): TableHeaderProps => ({
            sortable: true,
            onHeaderClick: this.props.onSort,
            sortDirection: sortName === this.props.sortName ? this.props.sortDirection : null,
            sortName
        });

        return (
            <div className="psr">
                <Table hover className="table mb-0">
                    <thead>
                        <TableRow>
                            <TableHeader {...sortableHeaderProps("advert_name")}>Nazwa inwestycji</TableHeader>

                            <TableHeader {...sortableHeaderProps("desktop_vendor")}>Deweloper</TableHeader>

                            <TableHeader {...sortableHeaderProps("create_date")}>Data utworzenia</TableHeader>

                            <TableHeader>Wygenerowane reklamy</TableHeader>

                            <TableHeader {...sortableHeaderProps("user")}>Utworzył/a</TableHeader>

                            <TableHeader />
                        </TableRow>
                    </thead>

                    <tbody className={tbodyClassName}>
                    {nativeListItem}
                    </tbody>
                </Table>

                {loader}
            </div>
        );
    }
}
