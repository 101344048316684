import {postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {nphApiLink} from "../../project/route/nph_api_link";
import {IImageTag} from "./fetch_image_tags";


export const createImageTag = (offerId: number, imageId: number, tag: Omit<IImageTag, "id">): Promise<IImageTag> => {
    const url = nphApiLink.offers.gallery.tagBase({offerId, imageId});
    return postRequest(url, tag)
        .then((res: IImageTag) => {
            return res;
        });
};
