import * as React from "react";
import {useMemo, useRef} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {each, map, isEmpty, reduce} from "lodash";
import {Panel} from "../../../shared/panel/Panel";
import CenterBox from "../../../shared/layout/CenterBox";
import Loader from "../../../shared/loader/Loader";
import {IFabricApi, IFabricMode, initFabric} from "../draw_polygon/init_fabric";


const CANVAS_ID = "canvas";

interface IPolygonEntry {
    color: string;
    id: number;
    text: JSX.Element;
    plan_polygon: [string, string][];
}
interface IProps {
    image: string | null;
    offerName: string;
    polygons: IPolygonEntry[];
    onInitCanvas: (api: IFabricApi) => void;
    onModeChange: (mode: IFabricMode) => void;
    onDrawComplete: () => void;
}

export const PlanItemCanvasHolder = (props: IProps) => {

    /**
     * Image initialization
     */

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const onImageLoad = (e: React.SyntheticEvent) => {
        const canvas = canvasRef.current;
        if (canvas == null) {
            throw new Error("BuildingPlanView: canvas is not ready on image load");
        }
        const img = e.currentTarget;
        canvas.width = img.clientWidth;
        canvas.height = img.clientHeight;
        initCanvas();
    };

    /**
     * Canvas initialization
     */

    const apiRef = useRef<IFabricApi | null>(null);
    const [isFabricInitialized, setIsFabricInitialized] = useState(false);
    const [polygonTooltip, setPolygonTooltip] = useState<{id: number; x: number, y: number} | null>(null);

    const initCanvas = () => {
        apiRef.current = initFabric(CANVAS_ID, {containerClass: "canvas-holder"}, {
            onDrawComplete: props.onDrawComplete,
            onModeChange: mode => {
                setPolygonTooltip(null);
                props.onModeChange(mode);
            },
            onPolygonClick: (id: number) => apiRef.current && apiRef.current.editStart(id),
            onPolygonMouseOver: (id, polygon, polygonTag) => {
                const {x, y} = polygonTag.getCenterPoint();
                setPolygonTooltip({id, x, y});
            },
            onPolygonMouseOut: (id, polygon, polygonTag) => {
                setPolygonTooltip(null);
            }
        });
        props.onInitCanvas(apiRef.current);
        setIsFabricInitialized(true);
    };

    const shouldPrintRef = useRef(true);
    useEffect(() => {
        if (!shouldPrintRef.current) {
            return;
        }
        if (!isFabricInitialized || isEmpty(props.polygons)) {
            return;
        }
        // display all polygons
        each(props.polygons, polygonElem => {
            if (!polygonElem.plan_polygon) {
                return;
            }
            const fractionPolygon = map(polygonElem.plan_polygon, point => ({fractionX: point[0], fractionY: point[1]}));
            apiRef.current && apiRef.current.printPolygon(polygonElem.id, fractionPolygon, polygonElem.color);
        });
        shouldPrintRef.current = false;
    }, [isFabricInitialized, props.polygons]);

    /**
     * Render
     */
    const polygonMap = useMemo(() => reduce(props.polygons, (acc, p) => ({...acc, [p.id]: p}), {}) as Record<number, IPolygonEntry>, [props.polygons]);
    const selectedPolygon = polygonTooltip && polygonMap[polygonTooltip.id] || null;

    return (
        <Panel color="default" className="df fjc-center fai-center bgc-black bdrs-lg overflow-h">
            <div className="psr">
                {props.image ? (
                    <>
                        <img src={props.image}
                             className="plan-offers-polygon-img"
                             alt={props.offerName}
                             onLoad={onImageLoad}
                        />

                        <canvas id={CANVAS_ID} ref={canvasRef}/>

                        {(polygonTooltip && selectedPolygon) && (
                            <div className="psa tac pv-md ph-lg bgc-white bdrs-md plan-item-canvas-tooltip"
                                 style={{
                                     left: polygonTooltip.x,
                                     top: polygonTooltip.y,
                                     transform: `translate(-53%, calc(-100% - 12px))`
                                 }}
                            >
                                {selectedPolygon.text}
                            </div>
                        )}
                    </>
                ) : (
                    <CenterBox className="vhc">
                        <Loader size="lg"/>
                    </CenterBox>
                )}
            </div>
        </Panel>
    );
};
