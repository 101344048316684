import * as _ from "lodash";
import {Dictionary} from "lodash";
import * as queryString from "query-string";
import {PlainRoute} from "react-router";

let routes: Dictionary<string> = {};


export interface RouteConfiguration extends PlainRoute {
    name?: string;
}


/**
 * Parsuje config routingu react-router lub podobny i tworzy plaski slownik routingow
 * config nie musi być poprawnym configiem react-routera, moze np. nie miec property "component"
 * interesuje nas tylko atrybut "path" oraz "name"
 * @param routeConfig
 */
export function parseRouteConfig(routeConfig: RouteConfiguration[]): void {
    _.each(routeConfig, (rootConfig: RouteConfiguration) => {
        parsePath(rootConfig, null, null);
    });
}

/**
 * Parsuje rekurencyjnie config routingu, doklejajac poprzedni namespace oraz path
 * @param pathConfig
 * @param namespace
 * @param path
 */
function parsePath(pathConfig: RouteConfiguration, namespace: string | null, path: string | null): void {
    let parsedPath: string;
    if (!path) {
        parsedPath = pathConfig.path!;
    } else if (path === "/") {
        parsedPath = "/" + pathConfig.path!;
    } else {
        parsedPath = path + /*"/" + */pathConfig.path!;
    }
    // when name is empty (undefined) do not add anything to the path
    const nextPathName = pathConfig.name ? ":" + pathConfig.name : "";
    let name = namespace ? namespace + nextPathName : pathConfig.name;
    routes[name!] = parsedPath;
    // jesli route ma dzieci to wywolujemy rekurencyjnie uwzgledniajac biezacy namespace i path
    if (pathConfig.childRoutes) {
        _.each(pathConfig.childRoutes, (childRouteConfig: RouteConfiguration) => {
            parsePath(childRouteConfig, name!, parsedPath);
        });
    }
}

/**
 * Zwraca konkretna sciezke url dla podanej nazwy oraz parametrow/query
 * @param pathName
 * @param params
 * @returns {string}
 */
export function url(pathName: string, params?: any, queryArr?: any): string {
    if (!routes[pathName]) {
        throw new Error("Nie znaleziono ścieżki: " + pathName);
    }

    let path: string = routes[pathName];
    if (params) {
        let pathParts = path.split("/");
        let parsedPathParts: string[] = [];
        // todo przerobic na for i zrobic break
        _.each(pathParts, (pathPart: string) => {
            _.each(params, (val: any, key: any) => {
                if (pathPart === ":" + key) {
                    pathPart = val;
                }
            });
            parsedPathParts.push(pathPart);
        });
        path = parsedPathParts.join("/");
    }

    path += generateQueryString(queryArr);

    return path;
}

export function generateQueryString(queryArr: any | any[]): string {
    let queryStr = "";
    if (!_.isEmpty(queryArr)) {
        if (!_.isArray(queryArr)) {
            queryArr = [queryArr];
        }
        let first = true;
        _.each(queryArr, (query: any) => {
            if (!_.isEmpty(query)) {
                // jesli wszystkie klucze w obiekcie sa undefined to wtedy zwraca pusty string
                if (queryString.stringify(query) === "") {
                    return;
                }

                if (first) {
                    queryStr += "?";
                    first = false;
                } else {
                    queryStr += "&";
                }
                queryStr += queryString.stringify(query);
            }
        });
    }
    return queryStr;
}
