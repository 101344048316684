import {isArray, each} from "lodash";

import {RequestActionTypes, createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {
    Action,
    Dispatch,
    ErrorAction,
    LatestQueryAction,
    PaginatedResponse,
    PaginatedResponseAction
} from "../../../../../shared/ts/interfaces/dispatch";
import {getRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {Dictionary} from "../../../../../shared/ts/interfaces/structure";
import {PAGE_PARAM} from "../../../../../shared/ts/constants/queryParams";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {addErrorAlert} from "../../../shared/alerts/actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {appendQueryString} from "../../../../../shared/ts/helpers/append_query_string";
import {IReelsCreator} from "../../reducers/reelsCreator";

export const createReelsCreatorsFetch = (type: string, actionTypes: RequestActionTypes) => (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch<LatestQueryAction>({ type: actionTypes.start, latestQuery: queryObj });

    const urlString = appendQueryString(nphApiLink.reels.creator.list(), queryObj);

    return getRequest(urlString)
        .then((json: PaginatedResponse<IReelsCreator>) => {
            dispatch<PaginatedResponseAction<IReelsCreator>>({
                type: actionTypes.success,
                results: json.results,
                pagination: {
                    pageSize: json.page_size,
                    total: json.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }
            });
            return json;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, type)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", type));
            }
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};
export const createReelsCreatorDetailFetch = (type: string, actionTypes: RequestActionTypes) => (reelCreatorId: number) => (dispatch: Dispatch) => {
    const urlString = nphApiLink.reels.creator.view({creatorId: reelCreatorId});

    return getRequest(urlString)
        .then((json: IReelsCreator) => {
            dispatch<Action>({
                type: actionTypes.success
            });
            return json;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};

export const REEL_CREATOR_FETCH = "reel_creator/FETCH";
export const reelCreatorRequest = createRequestActionTypes(REEL_CREATOR_FETCH);
export const reelsCreatorFetch = createReelsCreatorsFetch(REEL_CREATOR_FETCH, reelCreatorRequest);

export const REEL_CREATOR_FETCH_DETAIL = "reel_creator/FETCH_DETAIL";
export const reelCreatorDetailRequest = createRequestActionTypes(REEL_CREATOR_FETCH_DETAIL);
export const reelCreatorFetchDetail = createReelsCreatorDetailFetch(REEL_CREATOR_FETCH_DETAIL, reelCreatorDetailRequest);

export const invalidateReelsCreatorList = () => ({type: reelCreatorRequest.reset});
