import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {map, sortBy, Dictionary} from "lodash";
import {RouterProps, RouterState} from "../../../../shared/ts/interfaces/router";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentBody from "../../project/components/content/ContentBody";
import {Store} from "../../project/reducer";
import {fetchPlanOffer, IPlanOffer, IPlanOfferGallery, resetPlanOffer} from "../actions/fetch_plan_offer_actions";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import {IFabricApi, IFabricMode} from "../components/draw_polygon/init_fabric";
import {PlanItemCanvasHolder} from "../components/add_plan/PlanItemCanvasHolder";
import {PlanItemHeader} from "../components/add_plan/PlanItemHeader";
import {PlanType} from "./OfferPlanView";
import {url} from "../../../../shared/ts/helpers/routing";
import {BoundAction} from "../../../../typings/custom_typings/custom_redux";
import {fetchPlanBuilding, IPlanBuilding, resetPlanBuilding} from "../actions/fetch_plan_building_actions";
import {PlanBuildingAddImage} from "../components/building/PlanBuildingAddImage";
import {fetchPlanFloorList, IPlanFloor, resetPlanFloorList} from "../actions/fetch_plan_floor_actions";
import {PlanBuildingFloorListItem} from "../components/building/PlanBuildingFloorListItem";
import {patchFloorPlan} from "../actions/patch_floor_plan";
import {AssignFloorModal} from "../components/building/AssignFloorModal";
import {fromCustomPolygonId, isCustomPolygonId, toCustomPolygonId} from "../components/floor/AssignPropertyModal";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {deletePlanPolygon, updatePlanPolygon} from "../actions/plan_polygon_actions";
import {Modal} from "../../../../shared/ts/modals/Modal";
import {Button} from "../../shared/button/Button";
import {fetchVendorGroupList, resetVendorGroupList} from "../../vendor/actions/fetch_vendor_group_list";


interface IStateProps {
    offer: IPlanOffer | null;
    building: IPlanBuilding | null;
    floors: IPlanFloor[];
    floorsRequest: RequestState;
    listLatestQuery: Dictionary<string>;
}
interface IActionsProps {
    actions: {
        fetchPlanOffer: BoundAction<typeof fetchPlanOffer>;
        resetPlanOffer: typeof resetPlanOffer;
        fetchVendorGroupList: BoundAction<typeof fetchVendorGroupList>;
        resetVendorGroupList: typeof resetVendorGroupList;
        fetchPlanBuilding: BoundAction<typeof fetchPlanBuilding>;
        resetPlanBuilding: typeof resetPlanBuilding;
        fetchPlanFloorList: BoundAction<typeof fetchPlanFloorList>;
        resetPlanFloorList: typeof resetPlanFloorList;
        // patch
        deletePlanPolygon: BoundAction<typeof deletePlanPolygon>;
        updatePlanPolygon: BoundAction<typeof updatePlanPolygon>;
        patchFloorPlan: BoundAction<typeof patchFloorPlan>;
    };
}
interface IProps extends IActionsProps, IStateProps, RouterState, RouterProps {}

const BuildingPlanViewC = (props: IProps) => {

    const [estateExists, setEstateExists] = useState(false);
    useEffect(() => {
        (async () => {
            const offerId = parseInt(props.params["offer_id"], 10);
            const offer = await props.actions.fetchPlanOffer(offerId);
            if (offer == null) {
                throw new Error(`BuildingPlanView: cannot fetch offer ${offerId}`);
            }
            const [groups] = await Promise.all([
                props.actions.fetchVendorGroupList(offer.vendor.pk, offer.pk),
                props.actions.fetchPlanBuilding(parseInt(props.params["building_id"], 10)),
                props.actions.fetchPlanFloorList(parseInt(props.params["building_id"], 10))
            ]);
            if (groups && groups[0]) {
                setEstateExists(true);
            }
        })();
        return () => {
            props.actions.resetPlanOffer();
            props.actions.resetVendorGroupList();
            props.actions.resetPlanBuilding();
            props.actions.resetPlanFloorList();
        };
    }, []);

    const apiRef = useRef<IFabricApi | null>(null);
    const [assignFloorModalState, setAssignFloorModalState] = useState<number | null>(null);
    const [mode, setMode] = useState<IFabricMode>({type: "view"});

    /**
     * Polygon modification logic
     */

    const onPolygonEditStop = async () => {
        if (mode.type !== "edit") {
            throw new Error(`BuildingPlanView, onPolygonEditStop: wrong mode ${mode.type}`);
        }
        if (apiRef.current == null) {
            throw new Error("BuildingPlanView, onPolygonEditStop: apiRef is not defined");
        }
        const polygon = apiRef.current.editStop();
        const apiPolygon = map(polygon, point => [point.fractionX, point.fractionY] as [string, string]);
        // patch floor with polygon
        if (isCustomPolygonId(mode.polygonId)) {
            const customId = fromCustomPolygonId(mode.polygonId);
            await props.actions.updatePlanPolygon(customId, {polygon: apiPolygon, building: parseInt(props.params["building_id"], 10)});
        }
        else {
            const floorId = mode.polygonId; // floor ID equals polygon ID
            await props.actions.patchFloorPlan(floorId, {plan_polygon: apiPolygon});
        }
    };

    const [deleteModalState, setDeleteModalState] = useState<number | null>(null);
    const deletePolygon = async () => {
        const polygonId = deleteModalState as number;
        if (isCustomPolygonId(polygonId)) {
            const customId = fromCustomPolygonId(polygonId);
            await props.actions.deletePlanPolygon(customId);
        }
        else {
            await props.actions.patchFloorPlan(polygonId, {plan_polygon: null});
        }
        apiRef.current && apiRef.current.remove(polygonId);
        setDeleteModalState(null);
    };

    /**
     * Render
     */

    const sortedFloors = useMemo(() => sortBy(props.floors, floor => floor.floor), [props.floors]);
    const floorPolygonEntries = useMemo(() => map(props.floors, f => ({id: f.id, plan_polygon: f.plan_polygon, text: (<span>{`Piętro ${f.floor}`}</span>), color: "#00f"})), [props.floors]);
    const customPolygonEntries = useMemo(() => {
        if (props.building == null) {
            return [];
        }
        return map(props.building.polygons, p => ({id: toCustomPolygonId(p.id), plan_polygon: p.polygon, text: (<span>{`Piętro ${p.name}`}</span>), color: "#00f"}));
    }, [props.building]);

    const polygonEntries = useMemo(() => [...floorPolygonEntries, ...customPolygonEntries], [floorPolygonEntries, customPolygonEntries]);

    const renderBody = () => {
        const paramOfferId = parseInt(props.params["offer_id"], 10);
        const paramBuildingId = parseInt(props.params["building_id"], 10);
        const {offer, building} = props;
        // do not render until the right data is fetched
        if (offer == null || offer.pk !== paramOfferId || building == null || building.id !== paramBuildingId || props.floorsRequest === RequestState.Waiting) {
            return (
                <CenterBox className="vhc">
                    <Loader size="lg"/>
                </CenterBox>
            );
        }

        const photos: IPlanOfferGallery[] = [{image: offer.main_image, pk: 0, sort: 0}, ...offer.gallery];

        return (
            <ContentBody className="h100 psr">
                <PlanItemHeader
                    onBackClick={() => props.router.push(estateExists ?
                        url("app:in:offerPlans:estate", {offer_id: offer.pk}) :
                        url("app:in:offerPlans:list", null, props.listLatestQuery)
                    )}
                    offerName={offer.name}
                    type={PlanType.BUILDING}
                    buildingName={building.name}
                    canvasMode={mode.type}
                    drawStartPolygon={() => apiRef.current && apiRef.current.drawStart()}
                    drawStopPolygon={() => apiRef.current && apiRef.current.drawStop()}
                    editStopPolygon={onPolygonEditStop}
                    renderAdditionalHeader={() => (
                        <PlanBuildingAddImage buildingId={building.id} offerGallery={photos} changeMode/>
                    )}
                />

                {building.plan_image ? (
                    <PlanItemCanvasHolder
                        key={building.plan_image}
                        offerName={offer.name}
                        image={building.plan_image}
                        polygons={polygonEntries}
                        onInitCanvas={api => apiRef.current = api}
                        onModeChange={setMode}
                        onDrawComplete={() => setAssignFloorModalState(-1)}
                    />
                ) : (
                    <PlanBuildingAddImage buildingId={building.id} offerGallery={photos}/>
                )}

                <ul className="list-unstyled">
                    {map(sortedFloors, floor => floor.plan_polygon && (
                        <PlanBuildingFloorListItem
                            key={floor.id}
                            offerId={offer.pk}
                            buildingId={building.id}
                            floor={floor}
                            deletePolygon={setDeleteModalState}
                            editAssignment={() => setAssignFloorModalState(floor.id)}
                            editPolygon={() => apiRef.current && apiRef.current.editStart(floor.id)}
                        />
                    ))}

                    {map(building.polygons, polygon => (
                        <PlanBuildingFloorListItem
                            key={toCustomPolygonId(polygon.id)}
                            offerId={offer.pk}
                            disableLink={true}
                            buildingId={building.id}
                            floor={{
                                plan_image: null,
                                floor: polygon.name,
                                id: polygon.id
                            }}
                            deletePolygon={id => setDeleteModalState(toCustomPolygonId(id))}
                            editAssignment={() => setAssignFloorModalState(toCustomPolygonId(polygon.id))}
                            editPolygon={() => apiRef.current && apiRef.current.editStart(toCustomPolygonId(polygon.id))}
                        />
                    ))}
                </ul>
            </ContentBody>
        );
    };

    return (
        <Main>
            <Content>
                <ContentHeader>
                    <ContentHeaderTitle>
                        Plany inwestycji
                    </ContentHeaderTitle>
                </ContentHeader>

                {renderBody()}

                <AssignFloorModal
                    assignFloorModalState={assignFloorModalState}
                    setAssignFloorModalState={setAssignFloorModalState}
                    apiRef={apiRef}
                    floors={sortedFloors}
                    buildingPolygons={props.building && props.building.polygons}
                    buildingId={parseInt(props.params["building_id"], 10)}
                />

                <Modal
                    modalState={!!deleteModalState}
                    onModalClose={() => setDeleteModalState(null)}
                    type="window"
                    header="Czy na pewno chcesz usunąć poligon piętra?"
                >
                    <div className="df fjc-center fai-center p-xxl">
                        <Button
                            onClick={deletePolygon}
                            className="pv-md ph-xl mr-lg"
                            color="default"
                            type="button"
                        >
                            Tak
                        </Button>

                        <Button
                            onClick={() => setDeleteModalState(null)}
                            className="pv-md ph-xl"
                            color="default"
                            type="button"
                        >
                            Nie
                        </Button>
                    </div>
                </Modal>
            </Content>
        </Main>
    );
};

export const BuildingPlanView = connect(mapStateToProps, mapActionsToProps)(BuildingPlanViewC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        offer: state.plan.main.offer,
        building: state.plan.building.detail,
        floors: state.plan.floor.list,
        floorsRequest: state.plan.floor.fetchRequest,
        listLatestQuery: state.plan.offer.offerPlansList.latestQuery
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        actions: bindActionCreators<Record<keyof IActionsProps["actions"], any>, IActionsProps["actions"]>({
            fetchPlanOffer,
            resetPlanOffer,
            fetchVendorGroupList,
            resetVendorGroupList,
            fetchPlanBuilding,
            resetPlanBuilding,
            fetchPlanFloorList,
            resetPlanFloorList,
            // patch
            deletePlanPolygon,
            updatePlanPolygon,
            patchFloorPlan
        }, dispatch)
    };
}
