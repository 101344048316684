import {IReelFormValues} from "../reducers/reels";
import {reelActionButtonTypeOptions} from "../constants/reel_action_button_type_options";

type IFormValues = Pick<IReelFormValues,
    | "action_button_enabled"
    | "action_button_type"
    | "action_button_name"
    | "action_button_url"
    | "action_button_offer"
    | "action_button_property_enabled"
    | "action_button_property"
>;

export const getReelsFormActionButtonProperties = (formValues: IFormValues) => {
    if (!formValues.action_button_enabled) {
        return {
            action_button_enabled: false
        };
    }

    if (!formValues.action_button_type || formValues.action_button_type.toString() === reelActionButtonTypeOptions[0].value) {
        return {
            action_button_enabled: true,
            action_button_name: formValues.action_button_name,
            action_button_type: +reelActionButtonTypeOptions[0].value,
            action_button_url: formValues.action_button_url
        };
    }

    return {
        action_button_enabled: true,
        action_button_name: formValues.action_button_name,
        action_button_type: +reelActionButtonTypeOptions[1].value,
        action_button_offer: formValues.action_button_offer,
        ...(formValues.action_button_property_enabled ? {action_button_property: formValues.action_button_property} : {})
    };
};
