import * as React from "react";
import * as _ from "lodash";
import * as moment from "moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import * as classNames from "classnames";

import {FormComponent, FormComponentProps, FormComponentState} from "./formComponent";
import {DateTimePicker, DatePickerObj, DatePickerPopperPlacement} from "./dateTimePicker";
import {RangeValue} from "./range";
import {Dict} from "../../helpers/interfaces";
import Moment = moment.Moment;
import {HelpOverlay} from "../help_overlay";

// todo interfejs zaimportowac
interface DatePickerRangeProps extends FormComponentProps<DateRange> {
    format?: string;
    label?: string | JSX.Element;
    id: string;
    time?: boolean;
    keepInterval?: boolean;
    inputFormatDatetime: string;
    inputFormatDate: string;
    displayFormat: string;
    groupClassName?: string;
    labelClassName?: string;
    required?: boolean;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
    datePickerProps?: any;
    leftPopperPlacement?: DatePickerPopperPlacement;
    rightPopperPlacement?: DatePickerPopperPlacement;
    leftGroupClassName?: string;
    rightGroupClassName?: string;
    leftPlaceholder?: string;
    rightPlaceholder?: string;
}

interface DatePickerRangeState extends FormComponentState {
    interval?: number;
}

interface DateRange {
    lower: DatePickerObj;
    upper: DatePickerObj;
}

enum DateType {
    Lower,
    Uppper
}

export class DateTimePickerRange extends FormComponent<DateRange, DatePickerRangeProps, DatePickerRangeState> {

    public static toJSON(name: string, values: any): any {
        let value: RangeValue = {};
        if (values[name].lower) {
            value.lower = DateTimePicker.parseDateToString(values[name].lower);
        }
        if (values[name].upper) {
            value.upper = DateTimePicker.parseDateToString(values[name].upper);
        }
        return {
            [name]: value
        };
    }

    public static toFormData(name: string, values: any): any {
        let value: Dict = {};
        if (values[name] && values[name].lower) {
            value[name + "_0"] = DateTimePicker.parseDateToString(values[name].lower);
        }
        if (values[name] && values[name].upper) {
            value[name + "_1"] = DateTimePicker.parseDateToString(values[name].upper);
        }
        return value;
    }

    public static toFormDataPost(name: string, values: any): any {
        let value: Dict = {};
        if (values[name] && values[name].lower) {
            value[name + ".lower"] = DateTimePicker.parseDateToString(values[name].lower);
        }
        if (values[name] && values[name].upper) {
            value[name + ".upper"] = DateTimePicker.parseDateToString(values[name].upper);
        }
        return value;
    }

    public static fromJSON(name: string, values: any): any {
        return {
            [name]: {
                lower: values[name] && values[name].lower && DateTimePicker.getDateObjFromMoment(moment.utc(values[name].lower), values[name].lower && values[name].lower.length > 10) || null,
                upper: values[name] && values[name].upper && DateTimePicker.getDateObjFromMoment(moment.utc(values[name].upper), values[name].upper && values[name].upper.length > 10) || null
            }
        };
    }

    public static fromFormData(name: string, values: any): any {
        return {
            [name]: {
                lower: values[name + "_0"] && DateTimePicker.getDateObjFromMoment(moment.utc(values[name + "_0"]), values[name + "_0"].length > 10) || null,
                upper: values[name + "_1"] && DateTimePicker.getDateObjFromMoment(moment.utc(values[name + "_1"]), values[name + "_1"].length > 10) || null
            }
        };
    }

    public static isEmpty(value: DateRange): boolean {
        return value == null || (DateTimePicker.isEmpty(value.lower) && DateTimePicker.isEmpty(value.upper));
    }

    public static isEqual(val1: DateRange, val2: DateRange): boolean {

        return (!val1 && !val2) || (val1 && val2 && DateTimePicker.isEqual(val1.lower, val2.lower) && DateTimePicker.isEqual(val1.upper, val2.upper));
    }

    // NOTE: Nie usuwać, metoda wywoływana dynamicznie
    public static renderValue(val: DateRange): any {
        let valLower = val.lower && val.lower.date && `od ${moment(val.lower.date).format("DD.MM.YYYY")}`;
        let valUpper = val.upper && val.upper.date && `do ${moment(val.upper.date).format("DD.MM.YYYY")}`;

        return valLower && valUpper ? `${valLower} ${valUpper}` : valUpper ? valUpper : valLower;
    }

    private defaultDisplayFormat = "DD.MM.YYYY";
    private defaultInputFormatDatetime = "";
    private defaultInputFormatDate = "";

    public componentWillReceiveProps(nextProps: DatePickerRangeProps, nextState: DatePickerRangeState): void {
        this.setInterval(nextProps.value);
    }

    public shouldComponentUpdate(nextProps: DatePickerRangeProps, nextState: DatePickerRangeState): boolean {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
    }

    /**
     * Form component implementation
     */

    public setInterval(value: DateRange): void {
        if (value && this.props.keepInterval) {
            let lowerTime = this.createMomentFromDateObj(value.lower);
            let upperTime = this.createMomentFromDateObj(value.upper);
            if (lowerTime.isValid() && upperTime.isValid()) {
                let interval = upperTime.unix() - lowerTime.unix();
                this.setState({interval});
            } else {
                this.setState({interval: 0});
            }
        }
    }

    /**
     * Helpers
     */

    private getDisplayFormat = () => this.props.displayFormat || this.defaultDisplayFormat;
    private getFormatDatetime = () => this.props.inputFormatDatetime || this.defaultInputFormatDatetime;
    private getFormatDate = () => this.props.inputFormatDate || this.defaultInputFormatDate;

    private createMoment(date?: string, format?: string): Moment {
        if (!date) {
            return moment();
        }

        if (format) {
            return moment.utc(date, format, true);
        }

        if (date.length > this.getFormatDate().length) {
            return moment.utc(date, this.getFormatDatetime(), true);
        } else {
            return moment.utc(date, this.getFormatDate(), true);
        }
    }

    private createMomentFromDateObj(dateObj?: DatePickerObj, format?: string) {
        return this.createMoment(DateTimePicker.parseDateToString(dateObj), format);
    }

    private generateProps(type: DateType): any {
        let props: any = _.assign({
            time: this.props.time,
            value: type === DateType.Lower ? this.props.value && this.props.value.lower : this.props.value && this.props.value.upper,
            onFormUpdate: (name: string, date: DatePickerObj): void => this.onChange(type, date),
            onValueChange: (name: string, date: DatePickerObj): void => this.onValueChange(type, date),
            ref: type === DateType.Lower ? "lower" : "upper",
            name: this.props.name + (type === DateType.Lower ? "_0" : "_1"),
            timeFirst: type === DateType.Uppper,
            inputFormatDate: this.getFormatDate(),
            inputFormatDatetime: this.getFormatDatetime(),
            displayFormat: this.getDisplayFormat()
        }, this.props.datePickerProps);

        let lowerTime = this.createMomentFromDateObj(this.props.value && this.props.value.lower);
        let upperTime = this.createMomentFromDateObj(this.props.value && this.props.value.upper);

        if (lowerTime.isValid() && upperTime.isValid()) {
            props.startDate = lowerTime;
            props.endDate = upperTime;
        }

        return props;
    }

    /**
     * Callbacks
     */

    private onChange(type: DateType, date: DatePickerObj): void {
        let newValue: DateRange;
        if (type === DateType.Lower) {

            newValue = _.assign({}, this.props.value || {upper: null}, {lower: date}) as DateRange;

            if (this.props.keepInterval && this.state && this.state.interval) {
                let lowerTime = this.createMomentFromDateObj(date, this.props.time ? this.props.inputFormatDatetime : undefined);
                let upperTime = this.createMomentFromDateObj(this.props.value && this.props.value.upper, this.props.time ? this.props.inputFormatDatetime : undefined);

                if (lowerTime.isValid() && upperTime.isValid() && this.state.interval) {
                    let upper = lowerTime.clone().add(this.state.interval, "seconds");
                    newValue.upper = DateTimePicker.getDateObjFromMoment(upper, upper.format().length > 10);
                }
            }
        } else {
            newValue = _.assign({}, this.props.value || {lower: null}, {upper: date}) as DateRange;
        }


        if (_.isFunction(this.props.onFormUpdate)) {
            this.props.onFormUpdate(this.props.name, newValue);
        }
    }

    private onValueChange(type: DateType, date: DatePickerObj): void {
        if (_.isFunction(this.props.onValueChange)) {
            this.props.onValueChange(this.props.name, this.props.value);
        }
    }

    /**
     * Render methods
     */

    private renderLabel(forName: string): JSX.Element | null {

        let helpOverlay: JSX.Element | undefined;
        if (this.props.helpText) {
            const helpOverlayId = `${this.props.id}helpOverlay`;
            helpOverlay = (
                <div className="help-overlay dib ml-md">
                    <HelpOverlay id={helpOverlayId} placement={this.props.helpPlacement || "right"} className={this.props.helpClassName}>
                        {this.props.helpText}
                    </HelpOverlay>
                </div>
            );
        }

        if (this.props.label) {
            return (
                <div>
                    <label htmlFor={forName} className={`control-label ${this.props.labelClassName}`}>
                        {this.props.label} {helpOverlay}
                    </label>
                </div>
            );
        }

        return null;
    }

    private tooltipRenderer = (props: {error: any}): JSX.Element => {
        const EmptyTooltip = () => <span />;
        return props.error ? <Tooltip className="tooltip-error" id={`tooltip-${this.props.name}`}>{props.error}</Tooltip> : <EmptyTooltip />;
    }

    private defaultRenderer(props: {dateTimePickerLower: JSX.Element; dateTimePickerUpper: JSX.Element; error?: any}): JSX.Element {
        const hasError = !!props.error;
        const groupClassName = classNames(hasError ? "has-error" : "", "datepicker-range", this.props.groupClassName, this.props.markable && !DateTimePickerRange.isEmpty(this.props.value) ? "is-active" : "");

        return (
            <div className={groupClassName}>
                {this.renderLabel(this.props.id)}
                <OverlayTrigger placement="top" overlay={this.tooltipRenderer(this.props)}>
                    <div className="row">
                        <div className={hasError ? "col-xs-6" : "col-xs-12 col-sm-6"}>
                            <div className="psr range-field">
                                {props.dateTimePickerLower}
                                {hasError && (<span className="glyphicon glyphicon-remove form-control-feedback" />)}
                            </div>
                        </div>

                        <div className={hasError ? "col-xs-6" : "col-xs-12 col-sm-6"}>
                            <div className={hasError ? "has-error psr" : "psr range-field"}>
                                {props.dateTimePickerUpper}
                                {hasError && (<span className="glyphicon glyphicon-remove form-control-feedback" />)}
                            </div>
                        </div>
                    </div>
                </OverlayTrigger>
            </div>
        );
    }

    public render(): JSX.Element {
        const { error } = this.props;

        return this.defaultRenderer({
            dateTimePickerLower: (
                <DateTimePicker
                    {...this.generateProps(DateType.Lower)}
                    id={this.props.id}
                    popperPlacement={this.props.leftPopperPlacement}
                    groupClassName={this.props.leftGroupClassName}
                    placeholder={this.props.leftPlaceholder}
                />
            ),
            dateTimePickerUpper: (
                <DateTimePicker
                    {...this.generateProps(DateType.Uppper)}
                    popperPlacement={this.props.rightPopperPlacement}
                    groupClassName={this.props.rightGroupClassName}
                    placeholder={this.props.rightPlaceholder}
                />
            ),
            error
        });
    }
}
