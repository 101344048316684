import * as React from "react";
import {Link} from "react-router";
import Icon from "../../shared/icon/Icon";
import StyleProps from "../../../../shared/ts/interfaces/style";
import {url} from "../../../../shared/ts/helpers/routing";

interface NavPanelProps extends StyleProps {
    onPanelToggle: React.MouseEventHandler;
}


const NavPanelHeaderC: React.SFC<NavPanelProps> = props => {
    return (
        <div className="nav-panel-header fs-0 fg-0 fb-a">
            <Link to={url("app:in")} className="logo df fd-row fg-1 fs-0 fb-a">
                <div className="df fai-center fjc-center">
                    <img className="logo-icon" src={require("../../../img/logos/logo.svg")} alt="Nowy Panel Handlowca"/>
                </div>

                <div className="df fai-center fjc-center">
                    <img className="logo-text dn ml-md" src={require("../../../img/logos/text.svg")} alt="Nowy Panel Handlowca"/>
                </div>
            </Link>

            <div className="control-bar df fai-center fjc-center" onClick={props.onPanelToggle}>
                <Icon icon="arrow-right" className="c-white"/>
            </div>
        </div>
    );
};

const NavPanelHeader = NavPanelHeaderC;
export default NavPanelHeader;


