import {nphApiLink} from "../../project/route/nph_api_link";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch} from "redux";
import {IFloorPlanPolygon} from "./plan_polygon_actions";

export interface IPlanFloor {
    id: number;
    plan: string | null;
    plan_image: string | null;
    floor: number;
    plan_image_height: number | null;
    plan_image_width: number | null;
    plan_polygon: [string, string][];
    polygons: IFloorPlanPolygon[];
    building: number;
}

/**
 * Floor list
 */

const FETCH_PLAN_FLOOR_LIST = "offer-plans/floors/FETCH_PLAN_FLOOR_LIST";
export const fetchPlanFloorListTypes = createRequestActionTypes(FETCH_PLAN_FLOOR_LIST);

export const fetchPlanFloorList = (buildingId: number) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPlanFloorListTypes.start});
    const url = appendQueryString(nphApiLink.floors.list(), {building: buildingId, page_size: 1000});
    return getRequest(url)
        .then((res) => {
            dispatch({type: fetchPlanFloorListTypes.success, results: res.results});
            return res.results;
        });
};

export const resetPlanFloorList = () => ({type: fetchPlanFloorListTypes.reset});

/**
 * Floor detail
 */

const FETCH_PLAN_FLOOR_DETAIL = "offer-plans/floors/FETCH_PLAN_FLOOR_DETAIL";
export const fetchPlanFloorTypes = createRequestActionTypes(FETCH_PLAN_FLOOR_DETAIL);

export const fetchPlanFloor = (floorId: number) => (dispatch: Dispatch): Promise<IPlanFloor> => {
    dispatch({type: fetchPlanFloorTypes.start});
    const url = nphApiLink.floors.detail({floorId});
    return getRequest(url)
        .then((res: IPlanFloor) => {
            dispatch({type: fetchPlanFloorTypes.success, result: res});
            return res;
        });
};

export const resetPlanFloor = () => ({type: fetchPlanFloorTypes.reset});
