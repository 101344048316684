import * as React from "react";
import * as _ from "lodash";
import * as ReactSelect from "react-select";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import * as classNames from "classnames";

import {FormComponent, FormComponentProps} from "./formComponent";
import {HelpOverlay} from "../help_overlay";


export interface SelectProps<T> extends FormComponentProps<T> {
    // lib props
    options?: SelectOption[];
    searchable?: boolean;
    searchInside?: boolean;
    menuContainerStyle?: any;
    async?: boolean;
    disabled?: boolean;
    debug?: boolean;
    allowCreate?: boolean;
    id?: string;
    onChange?: (value: SelectOption) => void;
    onBlur?: React.EventHandler<React.FocusEvent>;
    onInputBlur?: (e: Event) => void;
    placeholder?: string;
    required?: boolean;
    showAllValues?: boolean;
    noResultLimit?: boolean;
    showInputPlaceholder?: boolean;
    selectFocusedOnBlur?: boolean;
    optionRenderer?: any;
    valueRenderer?: any;
    generateCustomMultiselectLabel?: any;
    optgroups?: boolean;
    menuUp?: boolean;
    menuClassName?: string;
    resultLimit?: number; // NOTICE: `noResultLimit` props for ReactSelect causes options sequence to mutate and limit visible options to 30 items
    multi?: boolean;
    clearable?: boolean;
    popoverPlacement?: "top" | "bottom" | "left" | "right";

    // lib async options
    loadOptions?: (name: string, limit?: number) => Promise<any>;
    getOption?: (id: string) => Promise<any>;
    limit?: number;

    // wrapper props
    label?: string | JSX.Element;
    emptyLabel?: string;
    labelClassName?: string;
    groupClassName?: string;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
    afterLabel?: JSX.Element;
    dummy?: boolean;
    autoFocus?: boolean;
    tetherOptions?: any;
}

export interface SelectState {
    value?: SelectOption | null;
    lastOnChangeValue?: SelectValue | null;
    isLoading?: boolean;
    isLoadingValue?: any;
    disabled?: boolean;
    didValueChange?: boolean;
}

export interface SelectOption {
    value: string;
    label: string;
    groupId?: number;
}

export interface SelectValue {
    value: string;
    option?: SelectOption;
}


export interface SelectGroup {
    name: string;
    id: number;
}


export class Select extends FormComponent<SelectValue | null, SelectProps<SelectValue | null>, {}> {
    private _state: SelectState = {};
    private _prevState: SelectState = {};

    public static toJSON(name: string, storeValues: any): any {
        let value = storeValues[name];
        let valueToSend: string | null = null;
        if (value != null && value.value !== "") {
            valueToSend = value.value;
        }
        return {
            [name]: valueToSend
        };
    }

    public static fromJSON(name: string, values: any): {[name: string]: SelectValue | null} {
        return {
            [name]: values[name] != null ? {value: values[name].toString()} : null
        };
    }

    public static toFormData(name: string, storeValues: any): any {
        let value = storeValues[name];
        if (!Select.isEmpty(value)) {
            return {
                [name]: value.value
            };
        }
        return {};
    }

    public static toFormDataPost(name: string, values: any): any {
        return Select.toFormData(name, values);
    }

    public static fromFormData(name: string, values: any): {[name: string]: SelectValue | null} {
        return {
            [name]: values[name] != null ? {value: values[name].toString()} : null
        };
    }

    public static isEmpty(value: SelectValue | null): boolean {
        return value == null || !value.value;
    }

    public static isEqual(val1?: SelectValue, val2?: SelectValue): boolean {
        let value1 = val1 && val1.value.toString();
        let value2 = val2 && val2.value.toString();
        return _.isEqual(value1, value2);
    }


    public static renderValue(val: SelectValue | null): any {
        return val && val.option && val.option.label;
    }


    public constructor(props: any) {
        super(props);
        this._state = {
            didValueChange: false
        };
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onLabelClick = this.onLabelClick.bind(this);
    }

    /**
     * React methods
     */

    public componentDidMount() {
        if (this.props.autoFocus) {
            (this.refs["select"] as any).focus();
        }
    }

    public shouldComponentUpdate(nextProps: any, nextState: any) {
        return !_.isEqual(this.props, nextProps);
    }

    public componentWillMount(): void {
        if (this.props.value) {
            if (this.props.async) {
                this.getAsyncOption(this.props.value.value);
            } else {
                this.setValue(this.props.value.value.toString());
            }
        }
        else if (this.props.emptyLabel) {
            this.setValue("");
        }
    }

    public componentWillReceiveProps(newProps: SelectProps<SelectValue | null>): void {

        // jesli wartosc jest ta sama ale formularz zostal zresetowany i nie ma optiona w nowych propsach (czyli nie ma w storze)
        // to sytuacja gdy fetch action zresetuje formularz na podstawie danych z query stringa, do przemyslenia
        if (!this.props.dummy && newProps.value && !newProps.value.option && this._state.value && this._state.value.value.toString() == newProps.value.value.toString()) {
            this.updateForm({value: this._state.value.value, option: this._state.value});
        }


        if (this.props.async) {
            if (newProps.value != null) {
                if (this.shouldLoadAsyncOption(newProps.value.value)) {
                    // // console.log("S LA", this.props.name, true);
                    this.getAsyncOption(newProps.value.value);
                } else {
                    // // console.log("S LA", this.props.name, false);
                }
            } else {
                this.callback = null;

                if (this._state.value != null) {
                    this._state.didValueChange = true;
                    this.updateForm(null);
                }
                //// console.log("set value", null)
                this._state.value = null;
            }
        } else {
            const newValue = newProps.value && newProps.value.value != null && newProps.value.value.toString() || "";
            this.setValue(newValue, newProps.options);
        }
    }

    public componentDidUpdate(prevProps: SelectProps<SelectValue | null>): void {

        if (this._state.didValueChange && _.isFunction(this.props.onValueChange) && this.hasValueChanged(prevProps)) {
            this._state.didValueChange = false;
            this._state.lastOnChangeValue = this.props.value;
            // console.log("S VC", this.props.name, y++);
            this.props.onValueChange(this.props.name, this.props.value);
        }

        this._prevState = _.clone(this._state);
    }


    private hasValueChanged(prevProps: SelectProps<SelectValue | null>): boolean {
        let curVal = this.props.value ? this.props.value.value : null;
        let prevVal = prevProps.value ? prevProps.value.value : null;

        return (this.props.value != this._state.lastOnChangeValue)
                && !_.isEqual(this.props.value, this._state.lastOnChangeValue)
                && !_.isEqual(curVal, prevVal);
    }

    /**
     * Form component implementation
     */

    public getValue(): string {
        return this._state.value ? this._state.value.value : "";
    }

    protected setValue(value: string, options?: SelectOption[]): void {
        let option = this.getOptionByValue(value, options);

        let oldValue = this._state.value;

        //// console.log("set value", option)
        this._state.value = option;

        if (!oldValue || !_.isEqual(oldValue, option)) {
            if (!this._state.value) {
                this._state.didValueChange = true;
            }
            if (!_.isEqual(this._state.value, option)) {
                // console.log("S OFU 2", this.props.name, {value, option})
                this.updateForm({value, option});
            }
        }

    }

    public isEmpty(): boolean {
        return !this.getValue();
    }

    /**
     * Callbacks
     */

    private onLabelClick(): void {
        (this.refs["select"] as any).focus();
    }

    private onSelectChange(option: SelectOption): void {
        let value: string = option ? option.value : "";

        if (this.getValue() === value) {
            return;
        }

        if (value || !this.props.searchable) {
            this._state.didValueChange = true;
        }

        this._state.value = option;
        this.forceUpdate();

        this.updateForm(value ? {value, option} : null);

    }

    private onBlur(e: FocusEvent): void {
        this._state.didValueChange = true;
    }

    /**
     * Helpers
     */

    private updateForm(value: SelectValue | null): void {
        if (!this.props.onFormUpdate) {
            return;
        }

        this.props.onFormUpdate(this.props.name, value);
    }

    private shouldLoadAsyncOption(value: string): boolean {
        // todo zastanowic sie czy drugi warunek jest potrzebny
        let result = value !== "" && (!this._state.isLoadingValue || value.toString() !== this._state.isLoadingValue.toString()) &&
            (!this._state.value || value.toString() !== this._state.value.value.toString());

        return result;
    }

    // todo hack na async przy czyszczeniu formularza
    private callback: Function | null = null;

    public getDisplayField(): string | null {
        if (this._state && this._state.value) {
            return this._state.value.label;
        }

        return null;
    }

    private getAsyncOption(id: string): void {
        if (this.props.debug) {
            // console.log("get async option");
        }
        this._state.isLoading = true;
        this._state.isLoadingValue = id;
        this._state.disabled = true;

        // todo hack na async, patrzy wyzej
        this.callback = (option: SelectOption) => {
            if (this.props.debug) {
                // console.log("set state 1");
            }

            let oldValue = this._state.value;

            if (!oldValue || !_.isEqual(oldValue, option)) {
                if (!this._state.value) {
                    this._state.didValueChange = true;
                }

                if (!_.isEqual(this._state.value, option)) {
                    this.updateForm({value: option.value, option});
                }
            }

            //// console.log("set value", option)
            this._state.value = option;
            this.forceUpdate();

        };

        this.props.getOption!(id).then((option: SelectOption) => {
            if (this.props.debug) {
                // console.log("set state 2");
            }
            this._state.isLoading = false;
            this._state.isLoadingValue = null;
            this._state.disabled = false;

            this.callback && this.callback(option);
        });
    }

    private getOptionByValue(value: string, options?: SelectOption[]): SelectOption | undefined {
        let selectedOption = _.find(this.prepareOptions(options), option => option.value.toString() === value);
        if (!selectedOption && this.props.allowCreate) {
            selectedOption = {label: value, value: value};
        }

        return selectedOption;
    }

    private prepareOptions(options?: SelectOption[]): SelectOption[] {
        if (this.props.emptyLabel) {
            let cloned = _.clone(options || this.props.options!);
            cloned.unshift({
                label: this.props.emptyLabel,
                value: ""
            });
            return cloned;
        }
        return options || this.props.options!;
    }

    private getSelectedValue(): SelectOption | undefined {
        if (this.props.async) {
            return this._state && this._state.value!;
        }
        //return _.find(this.props.options, option => option.value.toString() == this.getValue());
        return this.getOptionByValue(this.getValue());
    }

    /**
     * Render methods
     */

    private renderLabel(): JSX.Element | null {
        const {id, label, afterLabel, helpText, helpPlacement, helpClassName} = this.props;

        // Help overlay
        let helpOverlay: JSX.Element | undefined;
        if (helpText) {
            const helpOverlayId = `${id}helpOverlay`;
            helpOverlay = (
                <div className="dib ml-md">
                    <HelpOverlay id={helpOverlayId} placement={helpPlacement || "right"} className={helpClassName}>
                        {helpText}
                    </HelpOverlay>
                </div>
            );
        }

        if (label) {
            return (
                <label onClick={this.onLabelClick} htmlFor={id} className={`control-label ${this.props.labelClassName}`}>
                    {label}
                    {helpOverlay}
                    {afterLabel}
                </label>
            );
        }

        return null;
    }

    public render(): JSX.Element {
        // // console.log("S R", this.props.name, x++);

        const { name, error } = this.props;
        const groupClassName = classNames("form-group", this.props.groupClassName, this.props.markable && !Select.isEmpty(this.props.value) ? "is-active" : "");
        const groupClassNameError = classNames("has-error psr", groupClassName);
        let props = _.assign({
            clearable: false,
            searchable: false,
            onBlurResetsInput: false,
            isLoading: this._state && this._state.isLoading
        }, this.props, {
            ref: "select",
            value: this.getSelectedValue(),
            onChange: this.props.onChange || this.onSelectChange,
            onBlur: this.props.onBlur || this.onBlur,
            disabled: this.props.disabled || this._state.disabled,
            options: this.props.options && this.prepareOptions()
        });

        let component = <ReactSelect {...props} />;
        if (this.props.async) {
            component = <ReactSelect.Async {...props} />;
        }

        if (error) {
            const tooltip = (<Tooltip className="tooltip-error" id={`tooltip-${name}`}>{error}</Tooltip>);

            return (
                <div className={groupClassNameError}>
                    {this.renderLabel()}
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <div className="psr">
                            {component}
                            <span className="glyphicon glyphicon-remove form-control-feedback" />
                        </div>
                    </OverlayTrigger>
                </div>
            );
        }
        else {
            return (
                <div className={groupClassName}>
                    {this.renderLabel()}
                    {component}
                </div>
            );
        }
    }
}
