import {RequestActionTypes} from "../create_request_action_types";
import {PaginationState} from "../pagination";
import {PaginatedResponseAction} from "../../interfaces/dispatch";


export const reducePagination = (requestTypes: RequestActionTypes) =>
    (state: PaginationState = {pageSize: 0, total: 0}, action: PaginatedResponseAction<void>): PaginationState => {
        switch (action.type) {
            case requestTypes.success:
                return action.pagination;
            default:
                return state;
        }
    };

/**
 * New approach
 */
// TODO: unify with `PaginationState`
export interface IPaginationState {
    count: number;
    next?: string;
    pageSize: number;
    previous?: string;
}

export interface IPaginatedResponseAction<TResults> {
    type: string;
    results: TResults;
    pagination: IPaginationState;
}

export interface IResponseAction<TResults> {
    type: string;
    results: TResults;
}


export const reducePaginationWithCount = (requestTypes: RequestActionTypes) =>
    (state: IPaginationState = {pageSize: 0, count: 0}, action: IPaginatedResponseAction<void>): IPaginationState => {
        switch (action.type) {
            case requestTypes.success:
                return action.pagination;
            default:
                return state;
        }
    };
