import {browserHistory} from "react-router";

import * as auth from "../../../../shared/ts/helpers/auth";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, AsyncAction, Action} from "../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {url} from "../../../../shared/ts/helpers/routing";
import {nphApiLink} from "../../project/route/nph_api_link";


export const LOG_OUT_PREFIX = "authentication/LOG_OUT";
export const logOutRequest = createRequestActionTypes(LOG_OUT_PREFIX);


export const logOut = (): AsyncAction => (dispatch: Dispatch) => {
    dispatch<Action>({type: logOutRequest.start});
    return postRequest(nphApiLink.users.logout())
        .then((json: void) => {
            auth.logOut();
            browserHistory.push(url("app:out:login"));
            dispatch<Action>({type: logOutRequest.success});
            return false; // we should not continue after redirect
        })
        .catch((e: any) => {
            auth.logOut();
            browserHistory.push(url("app:out:login"));
            dispatch<Action>({type: logOutRequest.success}); // logOut is always successful
            return false;
        });
};
