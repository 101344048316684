import { RequestActionTypes } from "../create_request_action_types";
import {LatestQueryAction} from "../../interfaces/dispatch";
import {Dictionary} from "../../interfaces/structure";


export const reduceLatestQuery = (requestTypes: RequestActionTypes) =>
    (state: Dictionary<string> = {}, action: LatestQueryAction): Dictionary<string> => {
        switch (action.type) {
            case requestTypes.start:
                return action.latestQuery;
            default:
                return state;
        }
    };
