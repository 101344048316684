import {each, isArray} from "lodash";

import {createRequestActionTypes, RequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, ResponseAction} from "../../../../../shared/ts/interfaces/dispatch";
import {patchRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {ISurpriseAd} from "../../reducers/surprise";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {SURPRISE_ADD_PREFIX, surpriseRequestTypes} from "./add";
import {nphApiLink} from "../../../project/route/nph_api_link";

/**
 * POST Factories
 */

const createSurpriseEdit = (type: string, actionTypes: RequestActionTypes) => (id: number, jsonData: ISurpriseAd) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.surpriseAd.edit({id});
    return patchRequest(urlString, jsonData)
        .then((native: ISurpriseAd) => {
            dispatch<ResponseAction<any>>({type: actionTypes.success, result: native});
            dispatch(addSuccessAlert("Zapisano reklamę zakrytą", type));
            return native;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, SURPRISE_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", EDIT_SURPRISE_PATCH));
            }
            dispatch<ErrorAction>({type: surpriseRequestTypes.error, error: responseError.appError});
            return null;
        }));
};

/**
 * Clear Edit View
 */
export const CLEAR_SURPRISE_EDIT_VIEW_STATE = "surprise/CLEAR_SURPRISE_EDIT_VIEW_STATE";
export const clearSurpriseEditViewState = () => ({type: CLEAR_SURPRISE_EDIT_VIEW_STATE});


const EDIT_SURPRISE_PATCH = "surprise/edit/EDIT_SURPRISE_PATCH";
export const surpriseEditPatchTypes = createRequestActionTypes(EDIT_SURPRISE_PATCH);
export const surpriseEditPatch = createSurpriseEdit(EDIT_SURPRISE_PATCH, surpriseEditPatchTypes);

