import * as React from "react";
import * as classNames from "classnames";
import * as _ from "lodash";

import {Loader} from "./loader";
import {Icon} from "./icon";
import {Link} from "react-router";

export type IconPlacement = "left" | "right";
export type LoaderSize = "loader-xs" | "loader-sm";
export type LoaderColor = "loader-dark" | "loader-light";
export type buttonSize = "xs" | "xsmall" | "sm" | "small" | "lg" | "large";


interface ButtonProps {
    className?: string;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler;
    href?: string;
    urlTarget?: string;
    disabled?: boolean;
    color?: string;
    size?: buttonSize;
    type?: "button" | "submit" | "reset";
    loaderState?: boolean;
    icon?: string;
    iconPlacement?: IconPlacement;
    iconSeparated?: boolean;
    iconSeparatedFullWidth?: boolean;
    iconFontSizeClass?: string;
    loaderSize?: LoaderSize;
    loaderColor?: LoaderColor;
}

export class Button extends React.Component<ButtonProps, any> {

    public static defaultProps: ButtonProps = {
        iconPlacement: "left",
        loaderSize: "loader-sm",
        loaderColor: "loader-light",
        type: "button"
    };

    public render(): JSX.Element {
        const {className, children, loaderState,
            icon, iconPlacement, iconSeparated, iconSeparatedFullWidth, iconFontSizeClass,
            loaderSize, loaderColor
        } = this.props;

        const buttonBaseProps = _.omit(
            this.props,
            [
                "color",
                "size",
                "href",
                "urlTarget",
                "children",
                "iconPlacement",
                "iconSeparated",
                "iconSeparatedFullWidth",
                "iconFontSizeClass",
                "loaderSize",
                "loaderState",
                "loaderColor"
            ]
        );

        let classItem = classNames(
            icon ? "btn-icon" : "",
            iconSeparated ? "btn-icon-separated" : "",
            "psr",
            className
        );

        // Ikona
        let iconItem: JSX.Element | undefined;
        if (icon) {
            iconItem = <Icon icon={icon}/>;
        }

        // Zawartość przycisku (loader/children)
        let buttonContent: JSX.Element | undefined;
        const loader = <Loader size={loaderSize} color={loaderColor} button />;

        if (loaderState) {
            buttonContent = <span><span className="o0 h100">{children}</span>{loader}</span>;
        } else {
            buttonContent = children as JSX.Element;
        }
        buttonContent = <span className="btn-content">{buttonContent}</span>;

        // Kompletny przycisk (ikona, zawartość)
        let buttonItem: JSX.Element | undefined;

        if (icon) {
            const iconFontSizeCls = iconFontSizeClass || "";

            // Icon - left side
            if (iconPlacement === "left") {
                const iconMarginCls = iconSeparated ? "" : "mr-md";
                buttonItem = (
                    <div className="df fd-row fai-center overflow-vi">
                        <div className="pr-0">
                            <span className={`${iconFontSizeCls} ${iconMarginCls}`}>{iconItem}</span>
                        </div>
                        <div
                            className={`overflow-vi tal ${iconSeparated ? "bgc-white" : ""} ${iconSeparatedFullWidth ? "w100" : "wa"}`}>
                            {buttonContent}
                        </div>
                    </div>
                );
            }
            // Icon - right side
            else if (iconPlacement === "right") {
                const iconMarginCls = iconSeparated ? "" : "ml-md";
                buttonItem = (
                    <div className="df fd-row fai-center fjc-sb overflow-vi">
                        <div
                            className={`overflow-vi tal ${iconSeparated ? "bgc-white" : ""} ${iconSeparatedFullWidth ? "w100" : "wa"}`}>
                            {buttonContent}
                        </div>
                        <div className="overflow-vi pl-0">
                            <span className={`${iconFontSizeCls} ${iconMarginCls}`}>{iconItem}</span>
                        </div>
                    </div>
                );
            }
        }
        // No icon
        else {
            buttonItem = buttonContent;
        }

        const color = this.props.color ? `btn-${this.props.color}` : "btn-default";
        const size = this.props.size ? `btn-${this.props.size}` : "";

        if (this.props.href) {
            return (
                <Link
                    className={className}
                    to={this.props.href}
                    onClick={this.props.onClick ? this.props.onClick : undefined}
                    target={this.props.urlTarget ? this.props.urlTarget : undefined}
                >
                    {buttonContent}
                </Link>
            );
        }

        return (
            <button {...buttonBaseProps} onClick={this.props.onClick}  className={`btn ${classItem} ${color} ${size}`} ref="button">
                {buttonItem}
            </button>
        );
    }
}
