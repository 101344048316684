import * as React from "react";

interface IProps {
    src?: string;
    srcType?: string;
    height?: string;
    width?: string;
}

export const VideoPreview = (props: IProps) => {
    const srcType = props.srcType || "video/mp4";
    const ref = React.useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = React.useState(false);

    const handleVideoClick = () => {
        if (!ref.current) {
            return;
        }

        if (ref.current.paused) {
            ref.current.play();
            setIsPlaying(true);
        } else {
            ref.current.pause();
            setIsPlaying(false);
        }
    };

    return (
        <div className="file-field-preview-auto-size video-preview" onClick={handleVideoClick}>
            <video ref={ref} height={props.height} width={props.width}>
                <source src={props.src} type={srcType} />
            </video>
            <button type="button" className={`action-btn ${isPlaying ? "hide-controls" : ""}`}>
                {isPlaying ? (
                    <span style={{paddingLeft: "1px"}}>⏸︎</span>
                ) : (
                    <span style={{paddingLeft: "3px"}}>►</span>
                )}
            </button>
        </div>
    );
};
