import * as React from "react";

import {Panel, PanelBody, PanelFooter, PanelHeader} from "../../../shared/panel/Panel";
import {Button} from "../../../shared/button/Button";
import {ElementClasses, Form, FormProps, FormState} from "../../../../../shared/ts/components/form";
import {Input} from "../../../../../shared/ts/components/forms/input";
import Icon from "../../../shared/icon/Icon";
import {File, FileValue} from "../../../../../shared/ts/components/forms/file";
import {CreationType} from "../../CreationType";
import {ModalOpener} from "../../../advertisments/components/ModalOpener";
import {includes} from "lodash";


interface IProps extends FormProps {
    handlePreview: () => void;
    submitAndGenerate: () => void;
    submitAndGenerateHtml: () => void;
    updateImageAfterCropping: (key: string, image?: string) => void;
    initialImage: string | Blob;
}

interface IState extends FormState {
    show_crop_v2_image: boolean;
}

export interface ITdaAdFormValues {
    name: string;
    background_color: string;
    desktop_image: FileValue;
    mobile_image: FileValue;
}

export type ITdaAdCreation = Record<keyof ITdaAdFormValues, string> & {
    id: number;
    type: CreationType;
};

export class TdaAdCreationForm extends Form<IProps, IState> {
    public static elementClasses: ElementClasses = {
        "name": {
            type: Input,
            label: "Nazwa"
        },
        "background_color": {
            type: Input,
            label: "Kolor tła (RGB, HEX, HSL, RGBA, HSLA)"
        },
        "desktop_image": {
            type: File,
            label: "Grafika tda (pełnoekranowa)"
        },
        "mobile_image": {
            type: File,
            label: "Grafika tda (mobilna)"
        }
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            show_crop_v2_image: false
        };
    }

    private handleResettingImage = (e: React.MouseEvent, fileFieldName: "mobile_image" | "desktop_image") => {
        e.preventDefault();
        this.triggerCrop(fileFieldName);
        this.props.updateImageAfterCropping(fileFieldName);
    };

    private triggerCrop = (name: "mobile_image" | "desktop_image") => {
        const stateName = `show_crop_${name}` as keyof IState;
        this.setState({[stateName]: true} as Pick<IState, keyof IState>);
        window.setTimeout(() => this.setState({[stateName]: false} as Pick<IState, keyof IState>), 500);
    };

    private renderFile = (fileFieldName: "mobile_image" | "desktop_image", width: number, height: number) => {
        return (
            <div>
                <File {...this.generateProps(fileFieldName)}
                      allowedExtensions={["jpg", "jpeg", "png"]}
                      preview
                      previewPath={this.props.values[fileFieldName].base64value}
                      label={`Plik (${width}x${height})`}
                />

                <div className="psr t-5 mb-xl">
                    {this.props.initialImage && (
                        <Button size="sm" color="primary-ghost" onClick={(e) => this.handleResettingImage(e, fileFieldName)}>
                            Pobierz ponownie zdjęcie
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    public render(): JSX.Element {
        return (
            <form>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df df-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="screen" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Kreacja TDA
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <h3 className="fs16 fwb">Informacje o reklamie</h3>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-sm-4">
                                        <Input {...this.generateProps("name")} />
                                    </div>
                                </div>

                                <div className="bdbw-sm bdbs-solid bdbc-black-o10 mb-xxxl"/>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <div>
                                            <Input {...this.generateProps("background_color")} />
                                        </div>
                                    </div>
                                </div>
                                <h3 className="fs16 fwb">Materiały graficzne</h3>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <div>
                                            <h3 className="fs14 fwb mt-0">Grafika pełnoekranowa</h3>
                                            {this.renderFile("desktop_image", 3300, 660)}
                                        </div>
                                        <div>
                                            <h3 className="fs14 fwb mt-0">Grafika mobilna</h3>
                                            {this.renderFile("mobile_image", 1125, 1452)}
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>

                            <PanelFooter className="tar pv-xl">
                                <Button color="primary-ghost" size="lg" className="mr-xl" onClick={this.props.handlePreview}>Podgląd kreacji</Button>

                                <Button color="success" size="lg" className="mr-xl" onClick={this.props.onSubmit}>Zapisz kreację</Button>

                                <Button color="primary" size="lg" className="mr-xl" onClick={this.props.submitAndGenerate}>Zapisz i Wygeneruj kreację</Button>
                                <Button color="warning" size="lg" onClick={this.props.submitAndGenerateHtml}>Zapisz i Wygeneruj HTML</Button>
                            </PanelFooter>
                        </Panel>
                    </div>
                </div>
            </form>
        );
    }
}
