import * as React from "react";
import {useRef} from "react";
import {Button} from "../../../shared/button/Button";
import {Icon} from "../../../../../shared/ts/components/icon";


interface IProps {
    width: number;
    height: number;
    tagType: "create" | "update";
    tagDescription: string;
    onAcceptClick: (description: string) => void;
    onRejectClick: () => void;
}

export const TagForm = (props: IProps) => {

    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    const onSubmit = () => {
        if (descriptionRef.current == null) {
            throw new Error("TagForm: no description ref");
        }
        const description = descriptionRef.current.value;
        props.onAcceptClick(description);
    };

    return (
        <div className="panel bgc-white p-lg bdrs-lg" style={{
            width: `${props.width}px`,
            height: `${props.height}px`,
            boxShadow: "0 2px 18px 0 rgba(0,0,0,0.36)"
        }}>
            <div className="df fjc-sb mb-md">
                <span className="fs17 fwb c-gray-darker">
                    {props.tagType === "create" ? "Dodaj tag" : "Edytuj tag"}
                </span>

                <span className="curp c-gray-darker" onClick={props.onRejectClick}>
                    <Icon icon="delete" light />
                </span>
            </div>

            <textarea ref={descriptionRef}
                      defaultValue={props.tagDescription}
                      placeholder="Nazwa tagu"
                      className="bgc-white mb-md bdrs-md bds-solid bdw-sm bdc-gray-light"
                      style={{height: "60px", width: "190px", resize: "none"}}
            />

            <div className="df fd-row fw-nowrap">
                <Button className="fg-1" color="link" onClick={props.onRejectClick}>
                    Anuluj
                </Button>

                <Button className="fg-1" color="success" onClick={onSubmit}>
                    {props.tagType === "create" ? "Dodaj" : "Zapisz"}
                </Button>
            </div>
        </div>
    );
};
