import * as React from "react";
import * as _ from "lodash";
import * as classnames from "classnames";

import TableHeader, {TableHeaderProps} from "../../shared/table/TableHeader";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import Table from "../../../../shared/ts/components/table/Table";
import TableRow from "../../shared/table/TableRow";
import StyleProps from "../../../../shared/ts/interfaces/style";
import {SortDirection} from "../../../../shared/ts/decorators/sort";
import {ISurpriseAd} from "../reducers/surprise";
import {SurpriseListItem} from "./SurpriseListItem";

interface SurpriseListProps extends StyleProps {
    listItems: ISurpriseAd[];
    onSort: (column: string) => void;
    sortName: string;
    sortDirection: SortDirection | null;
    loading?: boolean;
    onDeleteClick: (uuid: number) => void;
    onItemClick: (id: number) => void;
}

export class SurpriseList extends React.Component<SurpriseListProps, {}> {

    private renderLoader(): JSX.Element {
        return (
            <CenterBox className="vhc pt-lg">
                <Loader size="lg"/>
            </CenterBox>
        );
    }


    public render(): JSX.Element {
        const tbodyClassName = classnames({ "o40": !!this.props.loading });
        const loader = this.props.loading ? this.renderLoader() : null;
        const nativeListItem = _.map(this.props.listItems, (advert: ISurpriseAd) => {

            const {name, create_date, id} = advert;

            return <SurpriseListItem
                key={id}
                name={name}
                createDate={create_date}
                id={id}
                createdBy={advert.user.name}
                onDeleteClick={this.props.onDeleteClick}
                onClick={this.props.onItemClick}
            />;
        });

        // Sortable table columns
        const sortableHeaderProps = (sortName: string): TableHeaderProps => ({
            sortable: true,
            onHeaderClick: this.props.onSort,
            sortDirection: sortName === this.props.sortName ? this.props.sortDirection : null,
            sortName
        });

        return (
            <div className="psr">
                <Table hover className="table mb-0">
                    <thead>
                        <TableRow>
                            <TableHeader {...sortableHeaderProps("name")}>Nazwa inwestycji</TableHeader>

                            <TableHeader {...sortableHeaderProps("create_date")}>Data utworzenia</TableHeader>

                            <TableHeader {...sortableHeaderProps("user")}>Utworzył/a</TableHeader>

                            <TableHeader />
                        </TableRow>
                    </thead>

                    <tbody className={tbodyClassName}>
                    {nativeListItem}
                    </tbody>
                </Table>

                {loader}
            </div>
        );
    }
}
