import * as React from "react";
import {RouterState} from "react-router";

import {errorPage} from "../../../../shared/ts/decorators/errorPage";
import {middleware} from "../../../../shared/ts/decorators/middleware";
import {initBugsnag} from "../../../../shared/ts/middleware/init_bugsnag";
import Alerts from "../../shared/alerts/Alerts";
import NotFound from "./NotFound";


const BUGSNAG_KEY = "2f51d02457c840358d97f03c3eaba0bd";


interface AppWrapperProps {}


@errorPage(NotFound)
@middleware(initBugsnag(BUGSNAG_KEY))
export default class AppWrapper extends React.Component<AppWrapperProps & RouterState, {}> {
    public render() {
        return (
            <section className="df fd-row vh100">
                <Alerts location={this.props.location} />
                {this.props.children}
            </section>
        );
    }
}
