export interface OfferRooms {
    [key: number]: {
        area: {
            min: number;
            max: number;
        },
        count: number,
        price: {
            min: number;
            max: number;
        }
    };
}

export const prepareRoomRanges = (rooms: OfferRooms) => {
    const availableRoomOptions = Object.keys(rooms);

    return {
        lower: availableRoomOptions[0],
        upper: availableRoomOptions[availableRoomOptions.length - 1]
    };
};
