import * as React from "react";
import {useState, useCallback, useMemo} from "react";
import {reduce} from "lodash";
import {OfferGalleryListItem} from "./OfferGalleryImageListItem";
import {ITagGalleryOfferGallery} from "../actions/fetch_offer_for_tag_gallery";

interface IProps {
    gallery: ITagGalleryOfferGallery[];
    onGalleryUpdate: (sortMap: Record<number, number>) => void;
    onTagButtonClick: (imagePk: number) => void;
}

export const OfferGalleryImageList = (props: IProps) => {
    const [images, setImages] = useState<ITagGalleryOfferGallery[]>([...props.gallery]);

    const onDragImage = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const withoutDragElem = [...images.slice(0, dragIndex), ...images.slice(dragIndex + 1)];
            setImages([...withoutDragElem.slice(0, hoverIndex), images[dragIndex], ...withoutDragElem.slice(hoverIndex)]);
        },
        [images]
    );

    const onDropImage = useCallback(() => {
            const sortMap = reduce(images, (acc, image, idx) => ({...acc, [image.pk]: idx + 1}), {});
            props.onGalleryUpdate(sortMap);
    }, [images]);

    const imageIndexMap = useMemo(
        () => reduce<ITagGalleryOfferGallery, Record<number, number>>(images, (acc, image, idx) => ({...acc, [image.pk]: idx}), {}),
        [images]
    );

    return (
        <div className="df fw-wrap p-xl bgc-white bdrs-lg" style={{maxWidth: "1285px"}}>
            {images.map(image => {
                return (
                    <OfferGalleryListItem
                        key={image.pk}
                        imagePk={image.pk}
                        imageSrc={image.image}
                        imageIndexMap={imageIndexMap}
                        onDragImage={onDragImage}
                        onDropImage={onDropImage}
                        onTagButtonClick={props.onTagButtonClick}
                    />
                );
            })}
        </div>
    );
};
