import * as React from "react";
import Icon from "../../shared/icon/Icon";
import {Link} from "react-router";
import {url} from "../../../../shared/ts/helpers/routing";
import Tooltip from "../../shared/overlays/Tooltip";
import StyleProps from "../../../../shared/ts/interfaces/style";


interface NavPanelProfileProps extends StyleProps {
    user: string;
}

const NavPanelProfileC: React.SFC<NavPanelProfileProps> = props => {
    return (
        <div className="nav-panel-profile fs15 overflow-h w100 df fd-row fai-center">
            <div className="name-holder dn w100 fs14">
                <span className="name truncate db">{props.user}</span>
            </div>

            <div className="icons">
                <Tooltip content="Wyloguj" placement="right" id="navPanelLogoutTooltip">
                    <Link to={url("app:out:logout")} className="df curp c-white fs20" style={{width: "40px"}}>
                        <Icon className="w100" icon="power"/>
                    </Link>
                </Tooltip>
            </div>
        </div>
    );
};


const NavPanelProfile = NavPanelProfileC;
export default NavPanelProfile;
