import * as _ from "lodash";


export const ADD_ALERT = "alert/ADD_ALERT";
export const REMOVE_ALERT = "alert/REMOVE_ALERT";

export interface AddAlertAction {
    type: string;
    data: {
        style: string;
        msg: string[];
        title?: string;
        repeat?: boolean;
        time?: number;
    };
}

export interface RemoveAlertAction {
    type: string;
    data: {
        id: string;
    };
}


function addAlert(style: string, title: string, msg: string | string[], repeat: boolean = false, time: number = 0): AddAlertAction {
    let body: string[] = _.isArray(msg) ? msg as any : [msg as any];
    return {
        type: ADD_ALERT,
        data: { style, msg: body, repeat, time, title }
    };
}

export function addErrorAlert(title: string, msg: string | string[], repeat: boolean = false, time: number = 0): AddAlertAction {
    return addAlert("danger", title, msg, repeat, time);
}

export function addSuccessAlert(title: string, msg: string | string[], repeat: boolean = false, time: number = 0): AddAlertAction {
    return addAlert("success", title, msg, repeat, time);
}

export function addWarningAlert(title: string, msg: string | string[], repeat: boolean = false, time: number = 0): AddAlertAction {
    return addAlert("warning", title, msg, repeat, time);
}

export function addInfoAlert(title: string, msg: string | string[], repeat: boolean = false, time: number = 0): AddAlertAction {
    return addAlert("info", title, msg, repeat, time);
}

export function removeAlert(id: string): RemoveAlertAction {
    return {
        type: REMOVE_ALERT,
        data: { id }
    };
}
