import {each, isArray} from "lodash";

import {createRequestActionTypes, RequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, ResponseAction} from "../../../../../shared/ts/interfaces/dispatch";
import {patchRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {REEL_CREATOR_ADD_PREFIX, reelCreatorRequestTypes} from "./add";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {IReelsCreator} from "../../reducers/reelsCreator";

const createReelCreatorEdit = (type: string, actionTypes: RequestActionTypes) => (id: number, jsonData: Partial<IReelsCreator>) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.reels.creator.edit({creatorId: id});
    return patchRequest(urlString, jsonData)
        .then((reelsCreator: IReelsCreator) => {
            dispatch<ResponseAction<any>>({type: actionTypes.success, result: reelsCreator});
            dispatch(addSuccessAlert("Zapisano autora", type));
            return reelsCreator;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, REEL_CREATOR_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza w polach związanych z autorem", EDIT_REEL_CREATOR_PATCH));
            }
            dispatch<ErrorAction>({type: reelCreatorRequestTypes.error, error: responseError.appError});
            return null;
        }));
};

export const CLEAR_REEL_CREATOR_EDIT_VIEW_STATE = "reel_creator/CLEAR_REEL_EDIT_VIEW_STATE";
export const clearReelEditViewState = () => ({type: CLEAR_REEL_CREATOR_EDIT_VIEW_STATE});

const EDIT_REEL_CREATOR_PATCH = "reel_creator/edit/EDIT_REEL_PATCH";
export const reelCreatorEditPatchTypes = createRequestActionTypes(EDIT_REEL_CREATOR_PATCH);
export const reelCreatorEditPatch = createReelCreatorEdit(EDIT_REEL_CREATOR_PATCH, reelCreatorEditPatchTypes);
