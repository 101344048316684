import * as _ from "lodash";
import {request, requestBlob, safeJsonParse} from "./request";
import {catch400, catch415} from "./errors";
import {AppError} from "./util";


/**
 * Zwraca treść wpisu nagłówka HTTP odpowiedzialnego za autoryzację.
 */
export const authorizationHeaderData = (() => {
    type IAuthorizationHeaderFunction = (prefix?: string) => { [s: string]: string };
    let _getAuthorizationHeaderFunction: IAuthorizationHeaderFunction = () => {
        throw new Error("authorizationHeaderData: please initialize authorization function first");
    };
    return {
        init: (getAuthorizationHeaderFunction: IAuthorizationHeaderFunction) => {
            _getAuthorizationHeaderFunction = getAuthorizationHeaderFunction;
        },
        get: (prefix?: string) => _getAuthorizationHeaderFunction(prefix)
    }
})();

/**
 * Przujmuje status odpowiedzi HTTP i JSON tej odpowiedzi.
 * Zwraca obiekt zawierający: status odpowiedzi, błędy pól formularza, błędy ogólne.
 */
function parseErrors(status: number, json: any): AppError {
    let appError: AppError = {
        status,
        fieldErrors: {},
        alerts: []
    };
    _.each(json, (err: string[], key: string) => {
        if (key === "non_field_errors") {
            appError.alerts = err;
        } else {
            appError.fieldErrors[key] = err;
        }
    });
    return appError;
}

function parseFieldErrors(request: Promise<any>): Promise<any> {
    return request.catch(catch400(responseError => {
        responseError.appError = parseErrors(400, safeJsonParse(responseError.responseBody));
        throw responseError;
    })).catch(catch415(responseError => {
        responseError.appError = parseErrors(415, safeJsonParse(responseError.responseBody));
        throw responseError;
    }));
}

function parseOptions(options?: any): any {
    let parsed = options || {};
    // validate authorization headers
    let authHeader = {};
    if (!(options && options.headers && options.headers["Authorization"])) {
        authHeader = authorizationHeaderData.get();
    }
    // validate PD related options
    parsed.headers = _.assign({}, parsed.headers, authHeader);
    return parsed;
}

export function getRequest(url: string, requestId: string | null = null, options?: any): Promise<any> {
    return parseFieldErrors(request("get", url, requestId, parseOptions(options)));
}

export function postRequest<T>(url: string, data?: T, requestId: string | null = null, options?: any): Promise<any> {
    return parseFieldErrors(request("post", url, requestId, _.assign({}, parseOptions(options), {data})));
}

export function patchRequest<T>(url: string, data?: T, requestId: string | null = null, options?: any): Promise<any> {
    return parseFieldErrors(request("PATCH", url, requestId, _.assign({}, parseOptions(options), {data})));
}

export function deleteRequest(url: string, requestId: string | null = null, options?: any): Promise<any> {
    return parseFieldErrors(request("delete", url, requestId, parseOptions(options)));
}

export function getBlobRequest(url: string, requestId: string | null = null, options?: any): Promise<any> {
    return parseFieldErrors(requestBlob("get", url, requestId, parseOptions(options)));
}
