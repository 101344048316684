import {isArray, each} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, Action} from "../../../../../shared/ts/interfaces/dispatch";
import {postRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";

import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {createFormActionTypes} from "../../../../../shared/ts/helpers/create_form_action_types";
import {createFormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {invalidateTdaAdvertsList} from "./fetch";
import {ITdaAd} from "../../reducers/tda";

export const TDA_ADD_PREFIX = "tda/ADD";
export const tdaRequestTypes = createRequestActionTypes(TDA_ADD_PREFIX);

export const addTdaFormTypes = createFormActionTypes(TDA_ADD_PREFIX);
export const addTdaFormActions = createFormActions(addTdaFormTypes);

export const tdaAdsAdd = (values: ITdaAd) => (dispatch: Dispatch): Promise<ITdaAd | null> => {
    dispatch<Action>({type: tdaRequestTypes.start});
    return postRequest(nphApiLink.tdaAd.add(), values)
        .then((tdaAD: ITdaAd) => {
            dispatch<Action>({type: tdaRequestTypes.success});
            dispatch(invalidateTdaAdvertsList());
            dispatch(addSuccessAlert("Dodano kreację TDA"));
            return tdaAD;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, TDA_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", TDA_ADD_PREFIX));
            }
            dispatch<ErrorAction>({type: tdaRequestTypes.error, error: responseError.appError});
            return null;
        }));
};


export const CLEAR_TDA_ADD_EDIT_VIEW_STATE = "tda/add_edit/CLEAR_ADD_EDIT_VIEW_STATE";
export const clearTdaAddEditViewState = () => ({type: CLEAR_TDA_ADD_EDIT_VIEW_STATE});

export const CLEAR_TDA_ADD_EDIT_ERRORS = "hero/add_edit/CLEAR_ADD_EDIT_ERRORS";
export const clearTdaAddEditErrors = () => ({type: CLEAR_TDA_ADD_EDIT_ERRORS});
