import * as React from "react";
import * as PropTypes from "prop-types";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import * as hoistStatics from "hoist-non-react-statics";
import * as _ from "lodash";

import {HocExtend, createHocExtend} from "./hoc_helpers";


type ModalWrapperProps = any;
type Component = any;
export type ModalShowFunction = <T extends ModalProps>(props: T, ShowComponent?: Component, showOptions?: ModalixOptions | null) => void;
export type ModalHideFunction = () => Promise<void>;


export interface HocModalix {
    modalix: {
        show: ModalShowFunction;
        hide: ModalHideFunction;
    }
}

// TODO: think about dropping `onConfirm` and `onReject` dependency - may be optional?
export interface ModalProps {
    onConfirm: (...args: any[]) => void;
    onReject: (...args: any[]) => void;
}

interface ModalixOptions {
    confirmOnEnter: boolean;
    rejectOnEsc: boolean;
    rejectOnBackdropClick: boolean;
    hideOnConfirm: boolean;
    hideOnReject: boolean;
    removeOnUnmount: boolean;
    focusModal: boolean;
}
const defaultModalOptions: ModalixOptions = {
    confirmOnEnter: true,
    rejectOnEsc: true,
    rejectOnBackdropClick: true,
    hideOnConfirm: true,
    hideOnReject: true,
    removeOnUnmount: true,
    focusModal: true
};


export const modalix = (ModalComponent: Component, modalOptions: Partial<ModalixOptions> | null = null) => (InnerComponent: Component) => {

    class ModalWrapper extends React.Component<ModalWrapperProps, {}> {

        public static defaultProps: ModalWrapperProps = { hoc: {} };
        // required by context to work
        public static contextTypes = { store: PropTypes.object.isRequired };
        public context: { store: any; };
        // required by hoc to work
        private hocExtend: HocExtend = createHocExtend();
        // DOM elements
        private mainDiv: HTMLDivElement | null = null;
        private backdropDiv: HTMLDivElement | null;
        private modalDiv: HTMLDivElement | null;
        // local state for options
        private currentOptions: ModalixOptions | null = null;
        private wasModalOpenedBefore: boolean = false;

        constructor(props: ModalWrapperProps) {
            super(props);
            this.show = this.show.bind(this);
            this.hide = this.hide.bind(this);
        }

        /**
         * Lifecycle
         */

        public componentWillUnmount(): void {
            if (this.currentOptions && this.currentOptions.removeOnUnmount) {
                this.removeModalDOM();
            }
        }

        /**
         * Callback
         */

        private onBackdropClick = (options: ModalixOptions, onReject: () => void) => (e: any) => {
            if (e.target === this.modalDiv) { // actual clickable background element
                if (options.rejectOnBackdropClick && _.isFunction(onReject)) {
                    onReject();
                }
            }
        };

        // TODO: think about returning `onReject/onConfirm` result
        private onKeyDown = (options: ModalixOptions, onConfirm: () => void, onReject: () => void) => (e: any) => {
            if (e.key === "Escape" && options.rejectOnEsc) {
                onReject();
                return;
            }
            if (e.key === "Enter" && options.confirmOnEnter) {
                onConfirm();
                return;
            }
        };

        /**
         * Helper
         */

        private hasClass = (e: HTMLElement, name: string): boolean => {
            if (!(e && e.className)) {
                return false; // no valid classNames
            }
            const allClasses = ` ${e.className} `.replace(/[\n\t]/g, " ");
            return allClasses.indexOf(` ${name} `) > -1;
        };

        private addClass = (e: HTMLElement, name: string) => {
            if (e && !this.hasClass(e, name)) {
                e.className += ` ${name}`; // space before `name` is important
            }
        };

        private removeClass = (e: HTMLElement, name: string) => {
            if (e) {
                e.className = e.className.trim().replace(new RegExp(`\\b${name}\\b`), "");
            }
        };

        private createCallbackWithHide = (callback: (...args: any[]) => any) => (...args: any[]) => {
            this.hide();
            return callback(...args);
        };

        // Remove main div from body and clear global assignments (classes and listeners)
        private removeModalDOM = (): void => {
            if (this.mainDiv) {
                ReactDOM.unmountComponentAtNode(this.mainDiv);
            }
            // actual element remove
            if (this.mainDiv && this.mainDiv.parentElement) {
                this.mainDiv.parentElement!.removeChild(this.mainDiv);
                this.mainDiv = null;
            }
            // clear class required for content scroll
            if (this.wasModalOpenedBefore === false) {
                this.removeClass(document.body, "modal-open");
            }
        };

        /**
         * Ref
         */

        // Initialize modal functionality - assign proper classes and listeners
        private refWrapperDiv = (d: HTMLDivElement | null) => {
            if (d) {
                // add `modal-open` class to let modal content scroll
                this.wasModalOpenedBefore = this.hasClass(document.body, "modal-open");
                if (this.wasModalOpenedBefore === false) {
                    this.addClass(document.body, "modal-open");
                }
            }
        };

        // Initialize background animation
        private refBackdropDiv = (d: HTMLDivElement | null) => {
            this.backdropDiv = d;
            if (d) {
                // trigger fade-in animation on background
                setTimeout(() => this.addClass(d, "in"), 0);
            }
        };

        // Initialize modal content animation
        private refModalDiv = (options: ModalixOptions) => (d: HTMLDivElement | null) => {
            this.modalDiv = d;
            if (d) {
                // let modal content to display
                d.style.display = "block";
                // trigger fade-in animation on content
                setTimeout(() => this.addClass(d, "in"), 0);
                // some elements may catch events when focused
                if (options.focusModal) {
                    setTimeout(() => this.modalDiv && this.modalDiv.focus(), 0);
                }
            }
        };

        /**
         * HOC API
         */

        private show <T extends ModalProps>(showProps: T, ShowComponent: Component = null, showOptions: ModalixOptions | null = null): void {
            if (this.mainDiv) {
                return; // do not open second modal
            }
            const Modal = ShowComponent || ModalComponent;
            if (!Modal) {
                return console.error("Modal component is not defined");
            }

            // INFO: other ideas for options may be overlay, opacity, center, classNames
            const options = this.currentOptions = _.assign({}, defaultModalOptions, modalOptions, showOptions) as ModalixOptions;
            const props = _.assign({}, showProps, {
                onConfirm: options.hideOnConfirm ? this.createCallbackWithHide(showProps.onConfirm) : showProps.onConfirm,
                onReject: options.hideOnReject ? this.createCallbackWithHide(showProps.onReject) : showProps.onReject
            }) as T;

            // prepare div at the end of the body
            this.mainDiv = document.createElement("div");
            document.body.appendChild(this.mainDiv);
            // put actual modal inside
            ReactDOM.render(
                <Provider store={this.context.store}>
                    <div ref={this.refWrapperDiv} onKeyDown={this.onKeyDown(options, props.onConfirm, props.onReject)}>
                        <div className="modal-backdrop fade" ref={this.refBackdropDiv} />
                        <div className="modal fade" tabIndex={-1} role="dialog"
                             ref={this.refModalDiv(options)} onClick={this.onBackdropClick(options, props.onReject)}>
                            <Modal {...props} />
                        </div>
                    </div>
                </Provider>,
                this.mainDiv);
        };

        private hide (): Promise<void> {
            return new Promise<void>(resolve => {
                // trigger fade-out animations
                this.backdropDiv && this.removeClass(this.backdropDiv, "in");
                this.modalDiv && this.removeClass(this.modalDiv, "in");
                // remove with delay because of animation
                setTimeout(() => {
                    this.removeModalDOM();
                    resolve();
                }, 200);
            });
        }

        /**
         * Render
         */

        public render(): JSX.Element {
            const hocModalix: HocModalix = {
                modalix: {
                    show: this.show,
                    hide: this.hide
                }
            };
            return <InnerComponent {...this.props} hoc={this.hocExtend(this.props.hoc, hocModalix)} />;
        }
    }

    return hoistStatics(ModalWrapper, InnerComponent);
};
