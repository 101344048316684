import {ListView} from "../views/List";
import {AddView} from "../views/Add";
import {EditView} from "../views/Edit";
import {url} from "../../../../shared/ts/helpers/routing";


const adsRoute = {
    path: "reklamy-natywne/",
    name: "natives",
    indexRoute: {
        onEnter: (nextState: any, replace: any) => {
            replace(
                url("app:in:natives:list")
            );
        }
    },
    childRoutes: [
        {
            path: "lista/",
            name: "list",
            component: ListView
        },
        {
            path: "dodaj/",
            name: "add",
            component: AddView
        },
        {
            path: ":id/",
            name: "edit",
            component: EditView
        }
    ]
};

export default adsRoute;
