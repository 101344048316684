import {isPlainObject, isString, mapValues} from "lodash";

export function prefixPath<T extends Record<string, any>>(prefix: string, paths: T): T {
    return mapValues(paths, path => {
        if (isString(path)) {
            return prefix + path;
        }
        if (isPlainObject(path)) {
            return prefixPath(prefix, path);
        }
        throw new Error(`path has to be string or object: ${path}`);
    }) as T;
}
