import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";

interface ContextPanelProps extends StyleProps {
    opened: boolean;
}

export const ContextPanelC: React.SFC<ContextPanelProps> = props => {
    const className = classnames("context-panel-holder df fd-col fg-0 fs-0", props.opened ? "opened" : undefined);
    return (
        <aside className={className}>
            {props.children}
        </aside>
    );
};

const ContextPanel = ContextPanelC;
export default ContextPanel;
