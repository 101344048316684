import {combineReducers} from "redux";
import {map, filter} from "lodash";
import {fetchPlanFloorListTypes, fetchPlanFloorTypes, IPlanFloor} from "../actions/fetch_plan_floor_actions";
import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {ResponseAction} from "../../../../shared/ts/helpers/interfaces";
import {patchFloorPlanTypes} from "../actions/patch_floor_plan";
import {reducePaginatedResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response_with_reset";
import {reduceResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_response";
import {reduceResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_response_with_reset";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {fromJson} from "../../shared/form/translate/from_json";
import {
    createPlanPolygonTypes,
    deletePlanPolygonTypes,
    IFloorPlanPolygon,
    updatePlanPolygonTypes
} from "../actions/plan_polygon_actions";
import {assignFloorFormTypes} from "../actions/assign_floor_form";
import {assignFloorFormFields} from "../components/building/AssignFloorForm";


export interface IPlanFloorStore {
    detail: IPlanFloor | null;
    list: IPlanFloor[];
    assignFloorFormValues: Record<keyof typeof assignFloorFormFields, any>;
    fetchRequest: RequestState;
    patchRequest: RequestState;
}

export const planFloorReducer = combineReducers({
    detail: reduceReducers(
        reduceResponseWithReset(fetchPlanFloorTypes),
        reduceResponse(patchFloorPlanTypes), // (1) image, add/edit/delete polygon
        customFloorDetailReducer // update floor.polygons
    ),
    list: reduceReducers(
        reducePaginatedResponseWithReset(fetchPlanFloorListTypes),
        customFloorListReducer // (1) image, add/edit/delete polygon
    ),
    assignFloorFormValues: reduceFormData(assignFloorFormTypes, fromJson(assignFloorFormFields, {})),
    fetchRequest: reduceRequestState(fetchPlanFloorListTypes),
    patchRequest: reduceRequestState(patchFloorPlanTypes) // (1) image, add/edit/delete polygon
});

/**
 * Custom
 */
function customFloorListReducer(list: IPlanFloor[], action: ResponseAction<IPlanFloor>): IPlanFloor[] {
    if (action.type === patchFloorPlanTypes.success) {
        return map(list, floor => floor.id === action.result.id ? action.result : floor);
    }
    return list;
}

function customFloorDetailReducer(detail: IPlanFloor | null, action: ResponseAction<IFloorPlanPolygon>): IPlanFloor | null {
    if (detail == null) {
        return detail;
    }
    if (action.type === createPlanPolygonTypes.success) {
        return {...detail, polygons: [...detail.polygons, action.result]};
    }
    else if (action.type === updatePlanPolygonTypes.success) {
        return {...detail, polygons: map(detail.polygons, p => p.id === action.result.id ? action.result : p)};
    }
    else if (action.type === deletePlanPolygonTypes.success) {
        return {...detail, polygons: filter(detail.polygons, p => p.id !== (action as any).id)};
    }
    return detail;
}
