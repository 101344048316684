import * as React from "react";
import {Store} from "../../project/reducer";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchPlanOfferList, resetPlanOfferList, IPlanOffer} from "../actions/fetch_plan_offer_actions";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentBody from "../../project/components/content/ContentBody";
import {Panel, PanelBody} from "../../shared/panel/Panel";
import {PlanOfferList} from "../components/offer_list/PlanOfferList";
import {HocSort, sort} from "../../../../shared/ts/decorators/sort";
import {fetch, HocFetch} from "../../../../shared/ts/decorators/fetch";
import {HocPagination, pagination} from "../../../../shared/ts/decorators/pagination";
import {PAGE_PARAM, SORT_PARAM} from "../../../../shared/ts/constants/queryParams";
import {Pagination} from "../../shared/pagination/Pagination";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {AppError, RequestState} from "../../../../shared/ts/helpers/util";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import * as _ from "lodash";
import CenterBox from "../../shared/layout/CenterBox";
import Icon from "../../shared/icon/Icon";
import Loader from "../../shared/loader/Loader";
import ContextPanel from "../../context_panel/components/ContextPanel";
import {PlanOfferListContextPanelHeader} from "../components/offer_list/PlanOfferListContextPanelHeader";
import {planOfferListContextPanelActions} from "../../context_panel/reducers/context_panel";
import {closePanel, openPanel, openTab} from "../../context_panel/actions/context_panel";
import ContextPanelBody from "../../context_panel/components/ContextPanelBody";
import {TabsContent} from "../../../../shared/ts/components/tabs/TabsContent";
import {toFormData} from "../../shared/form/translate/to_form_data";
import {offerPlanListFormFields, PlanOfferListFilterForm} from "../components/offer_list/PlanOfferListFilterForm";
import {offerPlanListFormActions} from "../actions/offer_plan_list_form";
import {FormActions} from "../../../../shared/ts/helpers/create_form_actions";
import {fromFormData} from "../../shared/form/translate/from_form_data";


interface IStateProps {
    offers: IPlanOffer[];
    request: RequestState;
    pagination: PaginationState;
    contextPanelOpened: boolean;
    openedTabIndex: number;
    errors: AppError | null;
    formValues: Record<keyof typeof offerPlanListFormFields, any>;
}
interface IActionProps {
    formActions: FormActions;
    actions: {
        fetchPlanOfferList: typeof fetchPlanOfferList;
        resetPlanOfferList: typeof resetPlanOfferList;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
    };
}

interface HocSortProps {
    hoc: HocFetch & HocPagination & HocSort;
}

interface IProps extends IStateProps, IActionProps, HocSortProps {}

interface IState {}


const defaultPage = 1;
const defaultSort = "";

@pagination(PAGE_PARAM)
@sort(SORT_PARAM)
@fetch((state: Store) => state.plan.offer.offerPlansList, fetchActions, validateQuery)
@connect(mapStateToProps, mapActionsToProps)
export class OfferPlanListView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {open: false};
    }

    private onSortChange = (column: string): void => {
        this.props.hoc.sort.toggle(column, this.props.hoc.pagination.get());
    };

    private onContextPanelTabOpen = (tabId: number): void => {
        this.props.actions.openTab(planOfferListContextPanelActions.openTab, tabId);
        this.props.actions.openPanel(planOfferListContextPanelActions.openPanel);
    };

    private onContextPanelToggle = (): void => {
        if (this.props.contextPanelOpened) {
            this.props.actions.closePanel(planOfferListContextPanelActions.closePanel);
        } else {
            this.props.actions.openPanel(planOfferListContextPanelActions.openPanel);
        }
    };

    private onSubmit = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        this.props.hoc.fetch.reset(toFormData(offerPlanListFormFields, this.props.formValues));
    };

    private onReset = () => this.props.hoc.fetch.reset(toFormData(offerPlanListFormFields, {}));

    private renderList = (request: boolean) => (
        <Panel color="default" className="psr mb-xl">
            <PanelBody>
                <PlanOfferList
                    offers={this.props.offers}
                    onSort={this.onSortChange}
                    sortName={this.props.hoc.sort.getName()}
                    sortDirection={this.props.hoc.sort.getDirection()}
                    loading={request}
                />
            </PanelBody>
        </Panel>
    );

    private renderEmptyList = () => {
        return (
            <ContentBody className="df fg-1">
                <CenterBox>
                    <Icon icon="empty-list" className="empty-list-icon" />
                    <div className="tac fs30">Nic tu nie ma, zmień parametry szukania</div>
                </CenterBox>
            </ContentBody>
        );
    };

    private renderError = () => {
        return (
            <CenterBox>
                <Icon icon="empty-list" className="empty-list-icon" />
                <div className="error-message tac fs30">Popraw błędne parametry szukania</div>
            </CenterBox>
        );
    };

    private renderLoader = () => {
        return (
            <CenterBox className="vhc">
                <Loader size="lg" />
            </CenterBox>
        );
    };

    private renderContent = () => {
        const {request, offers} = this.props;

        if (request === RequestState.Success && _.isEmpty(offers)) {
            return this.renderEmptyList();
        }

        if (request === RequestState.Success || (request === RequestState.Waiting && !_.isEmpty(offers))) {
            return this.renderList(request === RequestState.Waiting);
        }

        if (request === RequestState.Error) {
            return this.renderError();
        }

        return this.renderLoader();
    };

    public render() {

        const {hoc, request, errors} = this.props;

        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>
                            Plany inwestycji
                        </ContentHeaderTitle>
                    </ContentHeader>

                    <ContentBody>
                        {this.renderContent()}

                        <div className="pagination-holder tac pss b-0">
                            <Pagination
                                totalCount={this.props.pagination.total}
                                pageSize={this.props.pagination.pageSize}
                                activePage={hoc.pagination.currentPage()}
                                onPageChange={hoc.pagination.set}
                            />
                        </div>
                    </ContentBody>
                </Content>

                <ContextPanel opened={this.props.contextPanelOpened}>
                    <PlanOfferListContextPanelHeader
                        opened={this.props.contextPanelOpened}
                        openedTabIndex={this.props.openedTabIndex}
                        onTabOpen={this.onContextPanelTabOpen}
                        onPanelToggle={this.onContextPanelToggle}
                    />

                    <ContextPanelBody className="filters-holder">
                        <TabsContent openedTabIndex={this.props.openedTabIndex}>
                            <PlanOfferListFilterForm
                                values={this.props.formValues}
                                errors={errors && errors.fieldErrors}
                                onChange={this.props.formActions.update}
                                onSubmit={this.onSubmit}
                                onReset={this.onReset}
                                request={request}
                            />
                        </TabsContent>
                    </ContextPanelBody>
                </ContextPanel>
            </Main>
        );
    }
}

/**
 * Connect
 */
function mapStateToProps(store: Store): IStateProps {
    return {
        offers: store.plan.offer.offerPlansList.offers,
        request: store.plan.offer.offerPlansList.requestState,
        pagination: store.plan.offer.offerPlansList.pagination,
        contextPanelOpened: store.plan.offer.offerPlansList.contextPanel.opened,
        openedTabIndex: store.plan.offer.offerPlansList.contextPanel.openedTabIndex,
        errors: store.plan.offer.offerPlansList.errors,
        formValues: store.plan.offer.offerPlanListFormValues
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionProps {
    return {
        formActions: bindActionCreators(offerPlanListFormActions, dispatch),
        actions: bindActionCreators({
            fetchPlanOfferList,
            resetPlanOfferList,
            openPanel,
            closePanel,
            openTab
        }, dispatch)
    };
}

/**
 * Fetch
 */
function fetchActions(dispatch: Dispatch, query: Dictionary<string>): void {
    dispatch(offerPlanListFormActions.replace(fromFormData(offerPlanListFormFields, _.omit(query, [PAGE_PARAM, SORT_PARAM]))));
    dispatch(fetchPlanOfferList(query));
}
function validateQuery(query: Dictionary<string>): Dictionary<string> {
    return {
        [PAGE_PARAM]: query[PAGE_PARAM] || defaultPage.toString(),
        [SORT_PARAM]: query[SORT_PARAM] || defaultSort
    };
}
