import {Action} from "../../../../shared/ts/interfaces/dispatch";

export const OPEN_PANEL = "navPanel/OPEN_PANEL";
export const CLOSE_PANEL = "navPanel/CLOSE_PANEL";

export const openPanel = (): Action => ({
   type: OPEN_PANEL
});

export const closePanel = (): Action => ({
   type: CLOSE_PANEL
});
