import * as React from "react";
import {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {map} from "lodash";

import {Panel, PanelBody, PanelHeader} from "../../shared/panel/Panel";
import Icon from "../../shared/icon/Icon";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import Main from "../../shared/layout/Main";
import {Store} from "../../project/reducer";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {BoundAction} from "../../../../typings/custom_typings/custom_redux";
import {RouterProps, RouterState} from "../../../../shared/ts/interfaces/router";
import {ITagsOffer} from "../actions/fetch_tags_offer_list";
import {fetchTagsOffer, IOfferTagsData, patchTagsOffer} from "../actions/fetch_tags_offer_detail";
import ContentHeaderControls from "../../project/components/content/ContentHeaderControls";
import {Button} from "../../shared/button/Button";
import {fetchAvailableTagsList, IOfferTagItem} from "../actions/fetch_tags_list";
import {offerTagsDetailFormFields, OfferTagsForm} from "../components/TagsForm";
import {offerTagsDetailFormActions} from "../actions/offer_tags_form";
import {FormActions} from "../../../../shared/ts/helpers/create_form_actions";
import {addSuccessAlert} from "../../shared/alerts/actions";

interface IActionProps {
    actions: {
        fetchTagsOffer: BoundAction<typeof fetchTagsOffer>;
        patchTagsOffer: BoundAction<typeof patchTagsOffer>;
        fetchAvailableTagsList: typeof fetchAvailableTagsList;
        addSuccessAlert: typeof addSuccessAlert;
    };
    formActions: FormActions;
}

interface IStateProps {
    offer: ITagsOffer | null;
    tags: IOfferTagItem[];
    tagsFormValues: Record<keyof typeof offerTagsDetailFormFields, any>;

}

interface IProps extends IActionProps, IStateProps, RouterState, RouterProps {}

export const OfferTagsOfferViewC = (props: IProps) => {
    const [stateOfferId, setOfferId] = useState<number>(0);
    useEffect(() => {
        (async () => {
            // validate offer id
            const offerIdParam = props.params["offer_id"];
            const offerId = parseInt(offerIdParam, 10);
            setOfferId(offerId);
            if (!isFinite(offerId)) {
                throw new Error(`invalid offer ID: ${offerId}`);
            }

            // validate offer data
            const offer = await props.actions.fetchTagsOffer(offerId);
            if (offer == null) {
                throw new Error(`cannot fetch offer ${offerId}`);
            }
            await initFormData(offer);

            // fetch Tags
            await props.actions.fetchAvailableTagsList(offer.region.pk);
            return () => props.formActions.clear();
        })();
    }, []);
    const initFormData = (offer: ITagsOffer) => {
        const tags = map(offer.tags, (tag) => ({label: tag.name, value: tag.id}));
        const banned_tags = map(offer.banned_tags, (tag) => ({label: tag.name, value: tag.id}));
        const ban_all = offer.ban_all;
        props.formActions.replace({tags, banned_tags, ban_all});
    };

    const onSubmit = () => {
        const tags = map(props.tagsFormValues.tags, (tag) => tag.value);
        const banned_tags = map(props.tagsFormValues.banned_tags, (tag) => tag.value);
        const ban_all = props.tagsFormValues.ban_all;
        props.actions.patchTagsOffer(stateOfferId, {tags, banned_tags, ban_all})
            .then((response) => {
                if (response != null) {
                    props.actions.addSuccessAlert("Zapisano Tagi.");
                    return true;
                }
            });
    };


    const tagOptions = map(props.tags, (tag) => ({value: tag.id.toString(), label: tag.name}));

    return (
        <Main>
            <Content>
                <ContentHeader>
                    <ContentHeaderTitle>
                        Tagi inwestycji
                    </ContentHeaderTitle>

                    <ContentHeaderControls>
                        <Button
                            className="ttu ph-xxl mr-xl"
                            type="submit"
                            color="success"
                            size="sm"
                            onClick={onSubmit}
                        >
                            Zapisz Tagi
                        </Button>
                    </ContentHeaderControls>
                </ContentHeader>

                <div className="overflow-y-a pt-xxxl pb-0 ph-xxxl">
                    <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                        <PanelHeader className="fs22 fwl">
                            <div className="df df-row fai-center">
                                <div className="fg-0">
                                    <div className="icon-holder fs20 psr bdrs-50p">
                                        <Icon className="vhc" icon="screen"/>
                                    </div>
                                </div>

                                <div className="fg-1 panel-header-text">
                                    Tagi inwestycji {props.offer != null && `- ${props.offer.name}`}
                                </div>
                            </div>
                        </PanelHeader>

                        <PanelBody>
                            <OfferTagsForm
                                tagOptions={tagOptions}
                                onChange={props.formActions.update}
                                onSubmit={onSubmit}
                                values={props.tagsFormValues}
                                errors={null}
                            />
                        </PanelBody>
                    </Panel>
                </div>
            </Content>
        </Main>
    );
};

export const OfferTagsOfferView = connect(mapStateToProps, mapActionsToProps)(OfferTagsOfferViewC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        offer: state.tags.offerDetail.offer,
        tags: state.tags.offerDetail.tags,
        tagsFormValues: state.tags.offerDetail.tagsForm
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionProps {
    return {
        formActions: bindActionCreators(offerTagsDetailFormActions, dispatch),
        actions: bindActionCreators<Record<keyof IActionProps["actions"], any>, IActionProps["actions"]>({
            fetchTagsOffer,
            patchTagsOffer,
            fetchAvailableTagsList,
            addSuccessAlert
        }, dispatch)
    };
}
