import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Main from "../../../shared/layout/Main";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import ContentBody from "../../../project/components/content/ContentBody";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {Button} from "../../../shared/button/Button";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {addHeroFormActions, clearHeroAddEditErrors, clearHeroAddEditViewState} from "../../../advertisments/actions/hero/add";
import {heroEditPatch} from "../../../advertisments/actions/hero/edit";
import {heroFetchDetail} from "../../../advertisments/actions/hero/fetch";
import {IHeroForms} from "../../../advertisments/reducers/hero";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {HeroAdCreationAdModal} from "../components/HeroAdCreationAdModal";
import {RouterState} from "../../../../../shared/ts/interfaces/router";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {HeroAdCreationForm, IHeroAdCreation} from "../components/HeroAdCreationForm";
import {CreationType} from "../../CreationType";
import {htmlToImgDownload} from "../../native/actions/html_to_img";
import {saveAsHtml} from "../../native/actions/save_as_html";
import {createFileName, urlToCroppedBase64} from "../../../../../shared/ts/helpers/urlToCroppedBase64";


interface IStateProps {
    advertDetail: IHeroForms;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    mobileFormActions: FormActions;
    actions: {
        heroEditPatch: typeof heroEditPatch;
        heroFetchDetail: typeof heroFetchDetail;
        clearHeroAddEditErrors: typeof clearHeroAddEditErrors;
        clearHeroAddEditViewState: typeof  clearHeroAddEditViewState;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
    };
}

interface IOwnProps extends RouterState {
    hoc: HocModalix;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    initialImage: string | Blob;
}

@modalix(HeroAdCreationAdModal)
class HeroAdCreationEditViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            initialImage: ""
        };
    }

    public async componentDidMount() {
        const creationId = parseInt(this.props.params["id"], 10);
        const creationDetail: IHeroAdCreation = await this.props.actions.heroFetchDetail(creationId) as any;
        this.triggerPopulatingDataToForm(creationDetail);
    }

    private triggerPopulatingDataToForm = (creation: IHeroAdCreation) => {
        const rawFormValues = HeroAdCreationForm.fromJSON(creation);
        this.props.mobileFormActions.update(rawFormValues);
        this.prepareBase64Images(creation);
    };

    private prepareBase64Images = async (creation: IHeroAdCreation) => {
        const desktopImage = creation.desktop_image && await urlToCroppedBase64(creation.desktop_image, 1536,  295);
        const mobileImage = creation.mobile_image && await urlToCroppedBase64(creation.mobile_image, 375,  484);

        const hasDesktopValues = desktopImage;
        hasDesktopValues && this.props.mobileFormActions.update({
            desktop_image: {
                ...this.props.advertDetail.form.desktop_image,
                selected: `main_${createFileName(creation.desktop_image, creation.id, "desktop")}`,
                base64value: desktopImage
            }
        });

        const hasMobileValues = mobileImage;
        hasMobileValues && this.props.mobileFormActions.update({
            mobile_image: {
                ...this.props.advertDetail.form.mobile_image,
                selected: `main_${createFileName(creation.mobile_image, creation.id, "mobile")}`,
                base64value: mobileImage
            }
        });
    };

    public componentWillUnmount(): void {
        this.props.actions.clearHeroAddEditViewState();
    }

    /**
     * Callback
    */

    private openPreviewModal = () => {
        this.props.hoc.modalix.show<any>({
            imageAdCreationFormData: this.props.advertDetail.form,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    private onImageCropped = (key: "mobile_image" | "desktop_image", image?: string) => {
        const imgOptions = this.getImgOptions(key, image);

        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.advertDetail.form[key],
                ...imgOptions
            }
        });
    };

    private getImgOptions = (key: "mobile_image" | "desktop_image", image?: string) => {
        if (image) {
            return {
                base64value: image
            }
        }
    }

    /**
     * Submit form
     */

    private onSubmit = (shouldGenerate: boolean = false, toHtml: boolean = false) => {
        const creation: IHeroAdCreation = {
            ...HeroAdCreationForm.toJSON(this.props.advertDetail.form),
            type: CreationType.HERO
        };

        this.handleFormSubmit(creation, shouldGenerate, toHtml);
    };

    private handleFormSubmit = (creation: IHeroAdCreation, shouldGenerate: boolean, toHtml: boolean) => {
        const heroAdId = parseInt(this.props.params["id"], 10);

        const updatedCreation = {
            ...creation
        };

        (this.props.actions.heroEditPatch(heroAdId, updatedCreation as any) as any)
            .then((response: IHeroAdCreation) => {
                if (response != null) {
                    if (shouldGenerate) {
                        if(toHtml) {
                            this.handleSaveHtml(response.name, response.id)
                        } else {
                            this.handleConvert(response.name, response.id);
                        }
                    }
                    this.props.actions.clearHeroAddEditErrors();
                }
            });
    };

    private handleConvert = (name: string, id: number) => {
        this.openPreviewModal();
        htmlToImgDownload(document.getElementById("v2-ad")!, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

     private handleSaveHtml = (name: string, id: number) => {
        this.openPreviewModal();
        const element = document.getElementById("v2-ad")!;
        saveAsHtml(element, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    /**
     * Render
     */

    public render() {
        const {request} = this.props.advertDetail;
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklama Hero</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit()}
                            >
                                Zapisz reklamę
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(true)}
                            >
                                Zapisz i Wygeneruj reklamę
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <HeroAdCreationForm
                            onSubmit={() => this.onSubmit(false)}
                            errors={this.props.advertDetail.errors ? this.props.advertDetail.errors.fieldErrors : null}
                            values={this.props.advertDetail.form}
                            updateFormAction={this.props.mobileFormActions.update}
                            handlePreview={() => this.openPreviewModal()}
                            submitAndGenerate={() => this.onSubmit(true)}
                            submitAndGenerateHtml={() => this.onSubmit(true, true)}
                            updateImageAfterCropping={this.onImageCropped}
                            initialImage={this.state.initialImage}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const HeroAdCreationEditView = connect(mapStateToProps, mapActionsToProps)(HeroAdCreationEditViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        advertDetail: store.adverts.hero.detail,
        contextPanelOpened: store.adverts.hero.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        mobileFormActions: bindActionCreators(addHeroFormActions, dispatch),
        actions: bindActionCreators({
            heroEditPatch,
            heroFetchDetail,
            openPanel,
            closePanel,
            openTab,
            clearHeroAddEditViewState,
            clearHeroAddEditErrors,
        }, dispatch)
    };
}
