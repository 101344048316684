import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";

interface MainProps extends StyleProps {}


export const MainC: React.SFC<MainProps> = props => {
    const className = classnames(props.className, "df fd-row fg-1 fs-1 fb-a main");
    return <main className={className} >{props.children}</main>;
};

const Main = MainC;
export default Main;
