import * as React from "react";
import {useEffect, useState} from "react";
import {Dictionary} from "lodash";
import {RouterProps, RouterState} from "../../../../shared/ts/interfaces/router";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentBody from "../../project/components/content/ContentBody";
import {Store} from "../../project/reducer";
import {fetchPlanOffer, IPlanOffer, IPlanOfferGallery} from "../actions/fetch_plan_offer_actions";
import {connect} from "react-redux";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {bindActionCreators} from "redux";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import {FormActions} from "../../../../shared/ts/helpers/create_form_actions";
import {Button} from "../../shared/button/Button";
import Icon from "../../shared/icon/Icon";
import {fetchVendorGroupList, IVendorGroup} from "../../vendor/actions/fetch_vendor_group_list";
import {fetchPlanBuildingList, IPlanBuilding} from "../actions/fetch_plan_building_actions";
import {patchVendorGroup} from "../../vendor/actions/patch_vendor_group";
import {patchBuildingPlan} from "../actions/patch_building_plan";
import {PlanOfferAddImage} from "../components/offer/PlanOfferAddImage";
import {PlanOfferAddImageModalBody} from "../components/offer/PlanOfferAddImageModalBody";
import {imgUrlToBase64} from "../utils/imgUrlToBase64";
import {offerAddImageFormActions} from "../actions/add_image_form";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {addPlanImageFormFields} from "../components/add_plan/AddImageForm";
import {url} from "../../../../shared/ts/helpers/routing";
import {BoundAction} from "../../../../typings/custom_typings/custom_redux";
import {postVendorGroup} from "../../vendor/actions/post_vendor_group";
import {OfferType} from "../../../../shared/ts/constants/OfferType";


export enum PlanType {
    OFFER = 1,
    BUILDING = 2,
    FLOOR = 3,
    ESTATE = 4
}

interface IActionProps {
    addImageFormActions: FormActions;
    actions: {
        fetchPlanBuildingList: BoundAction<typeof fetchPlanBuildingList>;
        fetchPlanOffer: BoundAction<typeof fetchPlanOffer>;
        fetchVendorGroupList: BoundAction<typeof fetchVendorGroupList>;
        patchBuildingPlan: BoundAction<typeof patchBuildingPlan>;
        patchVendorGroup: BoundAction<typeof patchVendorGroup>;
        postVendorGroup: BoundAction<typeof postVendorGroup>;
    };
}

interface IStateProps {
    offer: IPlanOffer | null;
    addImageFormValues: Record<keyof typeof addPlanImageFormFields, any>;
    // estate
    groups: IVendorGroup[];
    patchVendorGroupRequest: RequestState;
    // building
    buildings: IPlanBuilding[];
    patchBuildingRequest: RequestState;
    // offer-list
    listLatestQuery: Dictionary<string>;
}

interface IProps extends IActionProps, IStateProps, RouterState, RouterProps {}

const OfferPlanViewC = (props: IProps) => {
    const [buttonState, setButtonState] = useState({building: false, estate: false});

    useEffect(() => {
        (async () => {
            // validate offer id
            const offerIdParam = props.params["offer_id"];
            const offerId = parseInt(offerIdParam, 10);
            if (!isFinite(offerId)) {
                throw new Error(`invalid offer ID: ${offerId}`);
            }
            // validate offer data
            const offer = await props.actions.fetchPlanOffer(offerId);
            if (offer == null) {
                throw new Error(`cannot fetch offer ${offerId}`);
            }

            // validate offer group
            const groups = await props.actions.fetchVendorGroupList(offer.vendor.pk, offer.pk);
            if (groups.length > 0) { // we've found the group
                const group = groups[0];
                if (group.plan_image) {
                    return props.router.push(url("app:in:offerPlans:estate", {offer_id: offerId}));
                }
                else {
                    return setButtonState({building: false, estate: true});
                }
            }
            // we've found no group, so validate offer buildings
            const buildings = await props.actions.fetchPlanBuildingList(offer.pk);
            if (buildings.length > 0) { // we've found some buildings
                if (buildings.length === 1) { // only one building found
                    const building = buildings[0];
                    if (building.plan_image) {
                        return props.router.push(url("app:in:offerPlans:building", {offer_id: offerId, building_id: building.id}));
                    }
                    else {
                        return setButtonState({building: true, estate: false});
                    }
                }
                else { // more than one buildings
                    return setButtonState({building: false, estate: true});
                }
            }
            // there is no buildings and no estate
            throw new Error(`OfferPlanView initialization: there is no estate and no building for ${offerId} offer`);
        })();
    }, []);

    const onBackClick = () => {
        props.router.push(url("app:in:offerPlans:list", null, props.listLatestQuery));
    };

    /**
     * AddImage
     */

    const [addImageModalType, setAddImageModalType] = useState<"building" | "estate" | null>(null);

    const onSelectImage = async (photo: IPlanOfferGallery) => {
        if (props.offer == null) {
            throw new Error("OfferPlanView, onSelectImage: offer is not defined");
        }
        if (addImageModalType === "estate") {
            const estate = props.groups[0] ? props.groups[0] : await props.actions.postVendorGroup(props.offer.pk, props.offer.vendor.pk);
            const imageData = await imgUrlToBase64(photo.image);
            await handleEstateImage(props.offer.pk, props.offer.vendor.pk, estate.id, imageData);
        }
        else if (addImageModalType === "building") {
            const building = props.buildings[0];
            if (building == null) {
                throw new Error(`OfferPlanView onSelectImage: no building for ${props.offer.pk} offer`);
            }
            const imageData = await imgUrlToBase64(photo.image);
            await handleBuildingImage(props.offer.pk, building.id, imageData);
        }
    };

    const onUploadImage = async (e: React.FormEvent) => {
        e.preventDefault();
        if (props.offer == null) {
            throw new Error("OfferPlanView, onUploadImage: offer is not defined");
        }
        if (addImageModalType === "estate") {
            const estate = props.groups[0] ? props.groups[0] : await props.actions.postVendorGroup(props.offer.pk, props.offer.vendor.pk);
            const imageData = props.addImageFormValues.image.base64;
            await handleEstateImage(props.offer.pk, props.offer.vendor.pk, estate.id, imageData);
            props.addImageFormActions.clear();
        }
        else if (addImageModalType === "building") {
            const building = props.buildings[0];
            if (building == null) {
                throw new Error(`OfferPlanView onSelectImage: no building for ${props.offer.pk} offer`);
            }
            const imageData = props.addImageFormValues.image.base64;
            await handleBuildingImage(props.offer.pk, building.id, imageData);
            props.addImageFormActions.clear();
        }
    };

    const handleEstateImage = async (offerId: number, vendorId: number, groupId: number, imageData: string) => {
        await props.actions.patchVendorGroup(vendorId, groupId, {plan_image: imageData});
        setAddImageModalType(null);
        props.router.push(url("app:in:offerPlans:estate", {offer_id: offerId}));
    };

    const handleBuildingImage = async (offerId: number, buildingId: number, imageData: string) => {
        await props.actions.patchBuildingPlan(buildingId, {plan_image: imageData});
        setAddImageModalType(null);
        props.router.push(url("app:in:offerPlans:building", {offer_id: offerId, building_id: buildingId}));
    };

    /**
     * Render
     */

    return (
        <Main>
            <Content>
                <ContentHeader>
                    <ContentHeaderTitle>
                        Plany inwestycji
                    </ContentHeaderTitle>
                </ContentHeader>

                {props.offer ? (
                    <ContentBody className="h100 psr">
                        <div className="df fjc-sb fai-center mb-xxxl">
                            <div className="df fai-center">
                                <Button onClick={onBackClick} color="link" className="p-0 mr-md mt-xs">
                                    <Icon icon="arrow-left"/>
                                </Button>

                                <div className="fs24 fwb">
                                    {props.offer.name}
                                </div>
                            </div>
                        </div>

                        <PlanOfferAddImage
                            buildingButtonEnabled={buttonState.building}
                            buildingButtonHide={props.offer.type.pk === OfferType.HOUSE}
                            estateButtonEnabled={props.offer.type.pk === OfferType.HOUSE || buttonState.estate}
                            modalBody={(
                                <PlanOfferAddImageModalBody
                                    gallery={[{image: props.offer.main_image, pk: 0, sort: 0}, ...props.offer.gallery]}
                                    onSelectImage={onSelectImage}
                                    onUploadImage={onUploadImage}
                                    formValues={props.addImageFormValues}
                                    formActions={props.addImageFormActions}
                                    isPending={props.patchVendorGroupRequest === RequestState.Waiting || props.patchBuildingRequest === RequestState.Waiting}
                                />
                            )}
                            modalState={!!addImageModalType}
                            openModal={type => setAddImageModalType(type)}
                            closeModal={() => setAddImageModalType(null)}
                        />
                    </ContentBody>
                ) : (
                    <CenterBox className="vhc">
                        <Loader size="lg"/>
                    </CenterBox>
                )}
            </Content>
        </Main>
    );
};

export const OfferPlanView = connect(mapStateToProps, mapActionsToProps)(OfferPlanViewC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        offer: state.plan.main.offer,
        addImageFormValues: state.plan.main.addPlanImageForm,
        groups: state.plan.vendorGroup.list,
        patchVendorGroupRequest: state.plan.vendorGroup.patchRequest,
        buildings: state.plan.building.list,
        patchBuildingRequest: state.plan.building.patchRequest,
        listLatestQuery: state.plan.offer.offerPlansList.latestQuery
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionProps {
    return {
        addImageFormActions: bindActionCreators(offerAddImageFormActions, dispatch),
        actions: bindActionCreators<Record<keyof IActionProps["actions"], any>, IActionProps["actions"]>({
            fetchPlanBuildingList,
            fetchPlanOffer,
            fetchVendorGroupList,
            patchBuildingPlan,
            patchVendorGroup,
            postVendorGroup
        }, dispatch)
    };
}

