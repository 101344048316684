import {combineReducers} from "redux";

import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";

import {surpriseRequest} from "../actions/surprise/fetch";
import {
    campaignListContextPanel, ContextPanelState
} from "../../context_panel/reducers/context_panel";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {DesktopNativesForm} from "../components/DesktopNativesForm";

import {addSurpriseFormTypes, CLEAR_SURPRISE_ADD_EDIT_ERRORS, CLEAR_SURPRISE_ADD_EDIT_VIEW_STATE, surpriseRequestTypes } from "../actions/surprise/add";
import {Offer} from "../../shared/models/Offer";
import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {nativesOfferSearchRequest} from "../../offer/actions/fetch";
import {reduceResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_response_with_reset";
import {SurpriseAdCreationForm} from "../../creation/surprise/components/SurpriseAdCreationForm";
import {filterSurpriseListFormTypes} from "../actions/surprise/filter_surprise_list";
import {SurpriseListFilterForm} from "../components/context_panel/surprise/SurpriseListFilterForm";



interface ISurpriseImage {
    stored: string;
    selected: string;
    id: string;
    base64value: string;
}

export interface ISurpriseFormValues {
    image: ISurpriseImage; // old field, now used by MonthlyCreationForm
    logo: ISurpriseImage;
    name: string;
    title: string;
    description: string;
}


export interface ISurpriseForms {
    request: RequestState;
    errors: AppError | null;
    form: ISurpriseFormValues;
}

export interface ISurpriseAd {
    create_date: string;
    desktop_area: { lower: number, upper: number };
    image: string;
    logo: string;
    name: string;
    description: string;
    id: number;
    user: {
        pk: number;
        name: string;
    };
    update_date: string;
    type: number;
    offer: number;
}

export interface ISurpriseFilterForm {
    name: string;
    create_by: string;
}

export interface SurpriseStore {
    natives: ISurpriseAd[];
    pagination: PaginationState;
    fetch: {
        requestState: RequestState;
        errors: AppError | null;
        latestQuery: Dictionary<string>;
    };
    list: {
        contextPanel: ContextPanelState;
        filterForm: ISurpriseFilterForm;
    };
    detail: ISurpriseForms;
    nativesOfferDetail: Offer;
}


const clearAddEditView = (store: ISurpriseForms, action: Action): any => {
    switch (action.type) {
        case CLEAR_SURPRISE_ADD_EDIT_VIEW_STATE:
            return {
                form: {
                    image: {
                        id: Math.random().toString(36).substr(2, 10),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    logo: {
                        id: Math.random().toString(36).substr(2, 10),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    name: '',
                    mobile_offer_price_type:0
                },
                request: RequestState.None,
                errors: null
            };
        case CLEAR_SURPRISE_ADD_EDIT_ERRORS:
            return {
                ...store,
                errors: null
            };
        default:
            return store;
    }
};

const surprise = combineReducers({
    natives: reducePaginatedResponse(surpriseRequest),
    pagination: reducePagination(surpriseRequest),
    fetch: combineReducers({
        requestState: reduceRequestState(surpriseRequest),
        errors: reduceRequestErrors(surpriseRequest),
        latestQuery: reduceLatestQuery(surpriseRequest)
    }),
    list: combineReducers({
        contextPanel: campaignListContextPanel,
        filterForm: reduceFormData(filterSurpriseListFormTypes, SurpriseListFilterForm.fromJSON({}))
    }),
    detail: reduceReducers(combineReducers({
            request: reduceRequestState(surpriseRequestTypes),
            errors: reduceRequestErrors(surpriseRequestTypes),
            form: reduceFormData(addSurpriseFormTypes, SurpriseAdCreationForm.fromJSON({}))
        }),
        clearAddEditView
    ),
    nativesOfferDetail: reduceResponseWithReset(nativesOfferSearchRequest)
});

export default surprise;
