import * as React from "react";
import {withRouter} from "react-router";
import {Panel} from "../../../shared/panel/Panel";
import {Button} from "../../../shared/button/Button";
import Icon from "../../../shared/icon/Icon";
import {url} from "../../../../../shared/ts/helpers/routing";
import {RouterProps} from "../../../../../shared/ts/interfaces/router";
import {EmtpyImg} from "../../../../../shared/ts/components/plan_offers/EmptyImg";


interface IOwnProps {
    offerId: number;
    disableLink?: boolean;
    deletePolygon: (buildingId: number) => void;
    editPolygon: () => void;
    editAssignment: (buildingId: number) => void;
    building: {
        id: number;
        name: string;
        plan_image: string | null;
    };
}
interface IProps extends IOwnProps, RouterProps {}

const PlanEstateBuildingListItemC = (props: IProps) => {

    const building = props.building;
    const link = url("app:in:offerPlans:building", {offer_id: props.offerId, building_id: props.building.id});
    const onEditItemClick = () => props.router.push(link);

    return (
        <Panel color="default">
            <div className="df fjc-sb fai-center ph-lg pv-xl">
                {building.plan_image ? (
                    <img src={building.plan_image} width="155" height="90" />
                ) : (
                    <EmtpyImg className="bdrs-md bdc-gray-light bdw-sm bds-solid" width={155} height={90}/>
                )}

                <div className="fg-1 fs-1 ph-md">
                    <p className="fs22 fwb mb-xs">
                        Budynek {building.name}
                    </p>
                </div>

                <div className="fg-0 fs-0">
                    <Button color="default" size="sm" className="mr-sm" onClick={props.editPolygon}>
                        <Icon icon="pencil" className="mr-xs"/>
                        Edytuj poligon
                    </Button>

                    <Button color="default" size="sm" className="mr-sm" onClick={() => props.editAssignment(props.building.id)}>
                        <Icon icon="main-data" className="mr-xs"/>
                        Edytuj przypisanie
                    </Button>

                    {!props.disableLink && (
                        <Button color="default" size="sm" className="mr-sm" onClick={onEditItemClick}>
                            <Icon icon="arrow-right" className="mr-xs"/>
                            Przejdź do budynku
                        </Button>
                    )}

                    <Button color="danger" size="sm" onClick={() => props.deletePolygon(props.building.id)}>
                        <Icon icon="delete"/>
                    </Button>
                </div>
            </div>
        </Panel>
    );
};

export const PlanEstateBuildingListItem: React.ComponentClass<IOwnProps> = withRouter(PlanEstateBuildingListItemC);
