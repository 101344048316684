import * as React from "react";
import {reduxForm, FormProps} from "redux-form";

import {submitLoginForm} from "../actions/login";
import Input from "../../shared/form/fields/input/Input";
import {Button} from "../../shared/button/Button";
import Icon from "../../shared/icon/Icon";



export interface LoginFormValues {
    email: string;
    password: string;
}
export interface LoginFormProps extends FormProps<LoginFormValues, {}, {}> {
}

const LoginFormName = "LoginForm";

@reduxForm({
    form: LoginFormName
})
export default class LoginForm extends React.Component<LoginFormProps, {}> {

    private submit?: React.EventHandler<any>;

    constructor(props: LoginFormProps) {
        super(props);
        this.submit = props.handleSubmit && props.handleSubmit(submitLoginForm);
    }

    public render() {
        return (
            <form onSubmit={this.submit} className="loginForm">
                <div className="psr">
                    <Input
                        name="email"
                        type="text"
                        placeholder="Adres email"
                        autoFocus
                        className="input-lg input-email"
                    />

                    <Icon className="input-icon fs28 psa cpen t0 l10" icon="user" />
                </div>

                <div className="psr">
                    <Input
                        name="password"
                        type="password"
                        placeholder="Hasło"
                        className="input-lg input-password"
                    />

                    <Icon className="input-icon fs28 psa cpen t0 l10" icon="lock" />
                </div>

                <div className="tac pt-xxxl">
                    <Button type="submit" color="success" size="lg" submitting={this.props.submitting}>Zaloguj się</Button>
                </div>
            </form>
        );
    }
}
