import * as React from "react";
import * as _ from "lodash";

import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {AddAlertAction, RemoveAlertAction, ADD_ALERT, REMOVE_ALERT, REFRESH_ALERT} from "./actions";


/**
 * Types
 */
export interface AlertData {
    id: string;
    origin: string | null;
    content: React.ReactNode;
    time: number;
    remain: boolean;
}

/**
 * Helper
 */
const filterRemaining = (alerts: AlertData[]): AlertData[] => _.filter(alerts, (a: AlertData) => a.remain);
const filterWithoutOrigin = (alerts: AlertData[], origin: string): AlertData[] => _.filter(alerts, (a: AlertData) => a.origin !== origin);
const filterWithoutId = (alerts: AlertData[], id: string): AlertData[] => _.filter(alerts, (a: AlertData) => a.id !== id);

/**
 * Reducer
 */
export type AlertsStore = AlertData[];

const alerts = (list: AlertsStore = [], action: Action): AlertsStore => {
    switch (action.type) {
        case ADD_ALERT:
            const data = (action as AddAlertAction).alert;
            const currentAlerts = data.origin == null ? list : filterWithoutOrigin(list, data.origin);
            return [...currentAlerts, {...data}];
        case REMOVE_ALERT:
            const alertId = (action as RemoveAlertAction).id;
            return filterWithoutId(list, alertId);
        case REFRESH_ALERT:
            return filterRemaining(list);
        default:
            return list;
    }
};

export default alerts;
