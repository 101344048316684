import * as React from "react";
import {Panel} from "../../../shared/panel/Panel";
import {Button} from "../../../shared/button/Button";
import {Modal} from "../../../../../shared/ts/modals/Modal";


interface IProps {
    buildingButtonEnabled: boolean;
    estateButtonEnabled: boolean;
    buildingButtonHide?: boolean;
    modalBody: JSX.Element;
    modalState: boolean;
    openModal: (type: "building" | "estate") => void;
    closeModal: () => void;
}

export const PlanOfferAddImage = (props: IProps) => {
    return (
        <Panel color="default" className="plan-offer-panel">
            <div className="w100 h100 df fjc-center fai-center p-lg">
                {!props.buildingButtonHide && (
                    <Button onClick={() => props.openModal("building")}
                        type="button"
                        color="info"
                        size="lg"
                        className="mr-lg"
                        disabled={!props.buildingButtonEnabled}
                    >
                        Dodaj plan budynku
                    </Button>
                )}

                <Button onClick={() => props.openModal("estate")}
                        type="button"
                        color="info"
                        size="lg"
                        disabled={!props.estateButtonEnabled}
                >
                    Dodaj plan osiedla
                </Button>
            </div>

            <Modal
                modalState={props.modalState}
                onModalClose={() => props.closeModal()}
                type="window"
                header="Wybierz zdjęcie"
            >
                <div className="df fjc-sb overflow-a p-xxxl" style={{minWidth: "500px"}}>
                    {props.modalBody}
                </div>
            </Modal>
        </Panel>
    );
};
