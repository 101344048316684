import * as _ from "lodash";
import {Dictionary} from "lodash";

import {alertText} from "../constants/alert";
import {AddAlertAction, addErrorAlert} from "../actions/alert";


interface IAppError {
    status: number;
    fieldErrors: Dictionary<string[]>;
    alerts: string[];
}

interface Dispatch {
    <A extends {type: string}>(action: A): A;
}


export function showError(dispatch: Dispatch, appError: IAppError, title?: string, message?: string) {
    if (_.isArray(appError.alerts) && appError.alerts.length) {
        // non_field_errors found
        dispatch<AddAlertAction>(addErrorAlert(alertText.errorTitle, appError.alerts, true, 5000));
    } else if (title && message) {
        // when `title` or `message` is not defined - show no alerts
        dispatch<AddAlertAction>(addErrorAlert(title, message, true, 5000));
    }
}

/**
 * Response errors
 */
const ERROR_400 = "Bad Request";
const ERROR_401 = "Unauthorized";
const ERROR_403 = "Forbidden";
const ERROR_404 = "Not Found";
const ERROR_415 = "Unsupported Media Type";
const ERROR_504 = "Gateway Timeout";
const ERROR_5XX = "Server Error";
const ERROR_UNKNOWN_STATUS = "Unknown Status Error";
const ERROR_STALLED = "Response stalled";
const ERROR_UNKNOWN_ERROR = "Unknown Error";

class ResponseError {
    public name: string;
    public message: string;
    public responseBody: string;
    constructor(name: string, message: string, responseBody: string) {
        this.name = name;
        this.message = message;
        this.responseBody = responseBody;
    }
}

export class Response400 extends ResponseError {
    public appError: IAppError;
    constructor(responseBody: string, message: string) {
        super(ERROR_400, message, responseBody);
    }
}

export class Response401 extends ResponseError {
    constructor(responseBody: string, message: string) {
        super(ERROR_401, message, responseBody);
    }
}

export class Response403 extends ResponseError {
    public responseError: Object;
    constructor(responseBody: string, message: string) {
        super(ERROR_403, message, responseBody);
    }
}

export class Response404 extends ResponseError {
    constructor(responseBody: string, message: string) {
        super(ERROR_404, message, responseBody);
    }
}

export class Response415 extends ResponseError {
    public appError: IAppError;
    constructor(responseBody: string, message: string) {
        super(ERROR_415, message, responseBody);
    }
}

export class Response504 extends ResponseError {
    constructor(responseBody: string, message: string) {
        super(ERROR_504, message, responseBody);
    }
}

export class Response5xx extends ResponseError {
    constructor(responseBody: string, message: string) {
        super(ERROR_5XX, message, responseBody);
    }
}

export class ResponseUnknownStatus extends ResponseError {
    constructor(responseBody: string, message: string) {
        super(ERROR_UNKNOWN_STATUS, message, responseBody);
    }
}

export class ResponseStalledError extends ResponseError {
    constructor(responseBody: string, message: string) {
        super(ERROR_STALLED, message, responseBody);
    }
}

export class ResponseUnknownError {
    public name: string;
    public message: string;
    public originalError: Error;
    constructor(err: Error, message: string) {
        this.name = ERROR_UNKNOWN_ERROR;
        this.message = message;
        this.originalError = err;
    }
}

/**
 * Catch helpers
 */
const createCatchResponseError = <T extends Error>(responseError: any) => <R = void>(catchCallback: (err: T) => R) => (err: T): R => {
    if (err instanceof responseError) {
        return catchCallback(err);
    }
    else {
        throw err;
    }
};

export const catch400 = createCatchResponseError<Response400>(Response400);
export const catch401 = createCatchResponseError<Response401>(Response401);
export const catch403 = createCatchResponseError<Response403>(Response403);
export const catch404 = createCatchResponseError<Response404>(Response404);
export const catch415 = createCatchResponseError<Response415>(Response415);
export const catch504 = createCatchResponseError<Response504>(Response504);
export const catch5xx = createCatchResponseError<Response5xx>(Response5xx);
export const catchUnknownStatus = createCatchResponseError<ResponseUnknownStatus>(ResponseUnknownStatus);
export const catchUnknownError = createCatchResponseError<ResponseUnknownError>(ResponseUnknownError);
export const catchStalled = createCatchResponseError<ResponseStalledError>(ResponseStalledError);

