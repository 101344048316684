import * as React from "react";
import {map} from "lodash";
import {connect} from "react-redux";

import Alert from "./Alert";
import {AlertData} from "./reducer";
import {removeAlert, refreshAlerts} from "./actions";
import {Store} from "../../project/reducer";
import {Location} from "../interfaces/ReactRouter";

interface StateProps {
    alerts: AlertData[];
}
interface ActionsProps {
    removeAlert: typeof removeAlert;
    refreshAlerts: typeof refreshAlerts;
}
interface OwnProps {
    location: Location;
}
interface AlertsProps extends StateProps, ActionsProps, OwnProps {}


@connect(mapStateToProps, {removeAlert, refreshAlerts})
class Alerts extends React.Component<AlertsProps, {}> {

    public componentDidUpdate(prevProps: AlertsProps) {
        if (prevProps.location !== this.props.location) {
            this.props.refreshAlerts();
        }
    }

    public render() {
        const alerts = map(this.props.alerts, alert => (
            <Alert key={alert.id} alert={alert} removeAlert={this.props.removeAlert} />
        ));
        return <div className="psa t20 r20 break-all alerts">{alerts}</div>;
    }
}

export default Alerts as React.ComponentClass<OwnProps>;

/**
 * Connect
 */
function mapStateToProps(state: Store): StateProps {
    return {
        alerts: state.alerts
    };
}
