import {each, isArray} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {deleteRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {Dispatch, ErrorAction} from "../../../../../shared/ts/interfaces/dispatch";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {invalidateAdvertsList} from "./fetch";
import {nphApiLink} from "../../../project/route/nph_api_link";



export const NATIVE_DELETE = "natives/DELETE";
export const nativeDeleteRequest = createRequestActionTypes(NATIVE_DELETE);


export const nativesDelete = (uuid: number) => (dispatch: Dispatch) => {
    dispatch({type: nativeDeleteRequest.start});

    return deleteRequest(nphApiLink.natives.delete({id: uuid}))
        .then(() => {
            dispatch({type: nativeDeleteRequest.success});
            dispatch(invalidateAdvertsList());
            dispatch(addSuccessAlert("Usunięto reklamę"));
            return true;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, NATIVE_DELETE)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", NATIVE_DELETE));
            }
            dispatch<ErrorAction>({type: nativeDeleteRequest.error, error: responseError.appError});
            return null;
        }));
};
