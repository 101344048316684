import {prefixPath} from "../project/utils/prefix_path";

export const videoPreviewPaths = prefixPath("/preview-nph/", {
    reels: "reels/",
});

export const reelsPreviewLinks = {
    reels: createNoParamLink(videoPreviewPaths.reels),
}

/*
    Utils
 */
function createNoParamLink(path: string): () => string {
    return () => `${getRpUrl()}${path}`;
}

function getRpUrl() {
    const env = process as any && process.env;

    switch (env.RUN_ENV){
        case "staging":
            return "https://beta-dev-rp.rynekpierwotny.com"
        case "development":
            return "http://rp.localhost:55120"
        default:
            return "https://rynekpierwotny.pl"

    }
}
