import {RequestActionTypes, createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {
    Action,
    Dispatch, ErrorAction, LatestQueryAction, PaginatedResponse,
    PaginatedResponseAction
} from "../../../../../shared/ts/interfaces/dispatch";
import {getRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {Dictionary} from "../../../../../shared/ts/interfaces/structure";
import {PAGE_PARAM} from "../../../../../shared/ts/constants/queryParams";
import {ISurpriseAd} from "../../reducers/surprise";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {addErrorAlert} from "../../../shared/alerts/actions";
import {isArray, each} from "lodash";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {appendQueryString} from "../../../../../shared/ts/helpers/append_query_string";

/**
 * Factories
 */

export const createSurprisesFetch = (type: string, actionTypes: RequestActionTypes) => (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch<LatestQueryAction>({ type: actionTypes.start, latestQuery: queryObj }); // TODO: are typings ok?

    const urlString = appendQueryString(nphApiLink.surpriseAd.base(), queryObj);

    return getRequest(urlString)
        .then((json: PaginatedResponse<ISurpriseAd>) => {
            dispatch<PaginatedResponseAction<ISurpriseAd>>({
                type: actionTypes.success,
                results: json.results,
                pagination: {
                    pageSize: json.page_size,
                    total: json.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }
            });
            return json;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, type)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", type));
            }
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};
export const createSurprisesDetailFetch = (type: string, actionTypes: RequestActionTypes) => (id: number) => (dispatch: Dispatch) => {
    const urlString = nphApiLink.surpriseAd.view({id});

    return getRequest(urlString)
        .then((json: ISurpriseAd) => {
            dispatch<Action>({
                type: actionTypes.success
            });
            return json;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};
/**
 * List fetch
 */

export const SURPRISE_FETCH = "surprise/FETCH";
export const surpriseRequest = createRequestActionTypes(SURPRISE_FETCH);
export const surpriseFetch = createSurprisesFetch(SURPRISE_FETCH, surpriseRequest);

export const SURPRISE_FETCH_DETAIL = "surprise/FETCH_DETAIL";
export const surpriseDetailRequest = createRequestActionTypes(SURPRISE_FETCH_DETAIL);
export const surpriseFetchDetail = createSurprisesDetailFetch(SURPRISE_FETCH_DETAIL, surpriseDetailRequest);

export const invalidateSurpriseAdvertsList = () => ({type: surpriseRequest.reset});
