import {browserHistory} from "react-router";

import {isLoggedIn} from "../../../../shared/ts/helpers/auth";
import {url} from "../../../../shared/ts/helpers/routing";
import {Middleware} from "../../../../shared/ts/decorators/middleware";


/**
 * Redirect to main application view when user is logged in (used in LoginView view)
 */
export const completedAuth = (): Middleware => () => new Promise<boolean>((resolve, reject) => {
    if (!isLoggedIn()) {
        return resolve(true);
    }

    const {pathname, search} = window.location;
    const next = pathname !== "/" ? {next: encodeURIComponent(pathname + search)} : null; // remember next parameter in URL
    browserHistory.replace(url("app:in", null, next)); // replace URL
    return resolve(false);
});
