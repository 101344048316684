import * as React from "react";
import HeaderTab from "../../../context_panel/components/HeaderTab";
import Icon from "../../../shared/icon/Icon";


interface IProps {
    opened: boolean;
    openedTabIndex: number;
    onTabOpen: (tabId: number) => void;
    onPanelToggle: () => void;
}

export const NativeListContextPanelHeader: React.SFC<IProps> = props => {
    const tooltipPlacement = props.opened ? "bottom" : "left";

    return (
        <div className="fg-0 fs-0 fb-a overflow-h context-panel-header">
            <ul className="context-panel-tabs pl-0 df fai-stretch m-0 fd-col">
                <HeaderTab
                    id={1}
                    openedTabIndex={props.openedTabIndex}
                    onTabOpen={props.onTabOpen}
                    tooltipContent="Filtrowanie"
                    tooltipId="NativeListContextPanelTab-1"
                    tooltipPlacement={tooltipPlacement}
                    icon="search"
                />

                {/*<HeaderTab
                    id={2}
                    openedTabIndex={props.openedTabIndex}
                    onTabOpen={props.onTabOpen}
                    tooltipContent="Na skróty"
                    tooltipId="NativeListContextPanelTab-2"
                    tooltipPlacement={tooltipPlacement}
                    icon="download"
                />*/}

                <li className="toggler curp" onClick={props.onPanelToggle}>
                    <Icon className="fs14 dib" icon="arrow-left"/>
                </li>
            </ul>
        </div>
    );
};

