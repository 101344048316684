import {Dispatch} from "redux";
import {nphApiLink} from "../../project/route/nph_api_link";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {IGroupPlanPolygon} from "../../offer_plans/actions/plan_polygon_actions";


interface IVendorGroupResponse {
    count: number;
    results: IVendorGroup[];
}
export interface IVendorGroup {
    id: number;
    offers: number[];
    plan: null;
    plan_image: string | null;
    plan_image_height: null;
    plan_image_width: null;
    polygons: IGroupPlanPolygon[];
    vendor: number;
}

const FETCH_VENDOR_GROUP_LIST = "vendor_group/list";
export const fetchVendorGroupListTypes = createRequestActionTypes(FETCH_VENDOR_GROUP_LIST);

export const fetchVendorGroupList = (vendorId: number, offerId: number) => async (dispatch: Dispatch) => {
    dispatch({type: fetchVendorGroupListTypes.start});
    const url = nphApiLink.vendors.groupList({vendorId});
    return getRequest(appendQueryString(url, {offer: offerId}))
        .then((res: IVendorGroupResponse) => {
            dispatch({type: fetchVendorGroupListTypes.success, results: res.results});
            return res.results;
        });
};

export const resetVendorGroupList = () => ({type: fetchVendorGroupListTypes.reset});
