import {combineReducers} from "redux";

import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {campaignListContextPanel, ContextPanelState} from "../../context_panel/reducers/context_panel";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";
import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";

import {addTdaFormTypes, CLEAR_TDA_ADD_EDIT_ERRORS, CLEAR_TDA_ADD_EDIT_VIEW_STATE, tdaRequestTypes} from "../actions/tda/add";
import {tdaRequest} from "../actions/tda/fetch";
import {filterTdaListFormTypes} from "../actions/tda/filter_tda_list";
import {TdaListFilterForm} from "../components/context_panel/tda/TdaListFilterForm";
import {TdaAdCreationForm} from "../../creation/tda/components/TdaAdCreationForm";

interface ITdaImage {
    stored: string;
    selected: string;
    id: string;
    base64value: string;
}

export interface ITdaFormValues {
    name: string;
    background_color: string;
    desktop_image: ITdaImage;
    mobile_image: ITdaImage;
}

export interface ITdaForms {
    request: RequestState;
    errors: AppError | null;
    form: ITdaFormValues;
}

export interface ITdaAd {
    id: number;
    name: string;
    background_color: string;
    user: {
        pk: number;
        name: string;
    };
    desktop_image: string;
    mobile_image: string;
    update_date: string;
    type: number;
}

export interface ITdaFilterForm {
    name: string;
    create_by: string;
}

export interface TdaStore {
    natives: ITdaAd[];
    pagination: PaginationState;
    fetch: {
        requestState: RequestState;
        errors: AppError | null;
        latestQuery: Dictionary<string>;
    };
    list: {
        contextPanel: ContextPanelState;
        filterForm: ITdaFilterForm;
    };
    detail: ITdaForms;
}

const clearAddEditView = (store: ITdaForms, action: Action): any => {
    switch (action.type) {
        case CLEAR_TDA_ADD_EDIT_VIEW_STATE:
            return {
                form: {
                    background_color: '',
                    desktop_image: {
                        id: Math.random().toString(36).substr(2, 10),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    mobile_image: {
                        id: Math.random().toString(36).substr(2, 10),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    name: '',
                },
                request: RequestState.None,
                errors: null
            };
        case CLEAR_TDA_ADD_EDIT_ERRORS:
            return {
                ...store,
                errors: null
            };
        default:
            return store;
    }
};

const tda = combineReducers({
    natives: reducePaginatedResponse(tdaRequest),
    pagination: reducePagination(tdaRequest),
    fetch: combineReducers({
        requestState: reduceRequestState(tdaRequest),
        errors: reduceRequestErrors(tdaRequest),
        latestQuery: reduceLatestQuery(tdaRequest)
    }),
    list: combineReducers({
        contextPanel: campaignListContextPanel,
        filterForm: reduceFormData(filterTdaListFormTypes, TdaListFilterForm.fromJSON({}))
    }),
    detail: reduceReducers(combineReducers({
            request: reduceRequestState(tdaRequestTypes),
            errors: reduceRequestErrors(tdaRequestTypes),
            form: reduceFormData(addTdaFormTypes, TdaAdCreationForm.fromJSON({}))
        }),
        clearAddEditView
    )
});

export default tda;
