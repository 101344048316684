import * as React from "react";

import {NativeAdHolder} from "./NativeAdHolder";
import {IDesktopNativesFormValues, IMobileNativesFormValues} from "../../reducers/natives";
import {ModalProps} from "../../../../../shared/ts/decorators/modalix";
import {NativeFormType} from "../../helpers/constants";

interface IProps extends ModalProps {
    modalTitle?: JSX.Element | string;
    mobileFormData: IMobileNativesFormValues;
    desktopFormData: IDesktopNativesFormValues;
    distance: {
        center: boolean;
        distance: number;
        region: string;
    };
    minPrice: number;
    formType: NativeFormType;
}

export const NativeAdModal = (props: IProps) => {
    return (
        <div className="modal-dialog modal-primary modal-native-ad" >
            <div className="bd-0 modal-content" tabIndex={0}>
                <div className="modal-header-box p-md fs16 modal-header text-center">
                    <button className="btn-close close" type="button" onClick={() => props.onReject()}>&times;</button>
                    <h4 className="modal-title">{props.modalTitle || "Podgląd reklam"}</h4>
                </div>
                <NativeAdHolder mobileFormData={props.mobileFormData} desktopFormData={props.desktopFormData} distance={props.distance} minPrice={props.minPrice} formType={props.formType}/>
            </div>
        </div>
    );
};
