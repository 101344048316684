import * as React from "react";
import * as ReactSelect from "react-select";
import * as classnames from "classnames";
import {WrappedFieldProps} from "redux-form";
import {isFunction, isArray, isNumber, isEmpty, isString, reduce, find} from "lodash";
import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {Store} from "../../../../project/reducer";
import FieldLabel from "../../FieldLabel";
import FieldError from "../../FieldError";
import {SelectOption} from "../../../../../../shared/ts/components/forms/select";


interface SelectFieldProps extends StyleProps, WrappedFieldProps<Store> {
    creatable: boolean;
    async: boolean;
    error?: string;
    label?: string;
    options: SelectOption[];
    groupClassName?: string;
    menuUp?: boolean;
    fetchData?: (ids: number[]) => Promise<any>;
    onValueUpdate?: (name: string) => void;
    multi: boolean;
    hideLabel?: boolean;
}
interface SelectFieldState {
    isLoading?: boolean;
    loadingValues?: number[] | number;
    triggerOnValueUpdate?: boolean;
}


export default class SelectField extends React.Component<SelectFieldProps, SelectFieldState> {
    public static defaultProps = {
        clearable: false,
        searchable: false,
        ignoreAccents: false
    };

    constructor(props: SelectFieldProps) {
        super(props);
        this.state = {
            isLoading: false,
            loadingValues: [],
            triggerOnValueUpdate: false
        };
    }

    public componentDidUpdate(prevProps: SelectFieldProps) {
        const {input: {value, name, onChange}, onValueUpdate, async} = this.props;
        if (prevProps.input === this.props.input) {
            return;
        }
        if (async) {
            if (this.state.isLoading) {
                return;
            }
            if (prevProps.input === this.props.input) {
                return;
            }

            // async expects store values to be objects or array of objects
            // we check for values that are number or array of numbers to fetch those data
            if (!isFunction(this.props.fetchData)) { // TODO: maybe move to types-check
                console.error(`SelectField: ${name} has no fetchData action`);
                return;
            }
            if (isArray(value)) {
                // we need to fetch number values to make proper options from them
                const numberValues = reduce(value, (acc, v) => isNumber(v) ? [...acc, v] : acc, []) as number[];
                if (isEmpty(numberValues) === false) {
                    this.setState({isLoading: true, loadingValues: numberValues});

                    this.props.fetchData(numberValues).then((objValues: SelectOption[]) => {
                        onChange(objValues);
                        this.setState({isLoading: false});
                    });
                }
            }
            else {
                if (isNumber(value)) {
                    this.setState({isLoading: true, loadingValues: value});
                    this.props.fetchData([value]).then((objValues: SelectOption[]) => {
                        onChange(objValues[0]);
                        this.setState({isLoading: false});
                    });
                }
            }
        }

        if (this.state.triggerOnValueUpdate) {
            if (isFunction(onValueUpdate)) {
                onValueUpdate(name);
                this.setState({triggerOnValueUpdate: false});
            }
        }
    }

    /**
     * Callback
     */

    private onChange = (value: SelectOption | SelectOption[]): void => {
        const {input, multi, async} = this.props;
        const isEmptyValue = value == null || (isArray(value) && isEmpty(value));

        // resets on clicking X
        if (value === null) {
            input.onChange("");
            return this.setState({triggerOnValueUpdate: true});

        }
        if (!input.value && isEmptyValue) {
            return;
        }

        if (multi) {
            input.onChange(isEmptyValue ? [] : value);
            return this.setState({triggerOnValueUpdate: true});

        }
        else {
            if (async) {
                input.onChange(isEmptyValue ? null : value);
                return this.setState({triggerOnValueUpdate: true});

            }
            else {
                input.onChange(isEmptyValue ? null : (value as SelectOption).value);
                return this.setState({triggerOnValueUpdate: true});

            }
        }
    };

    /**
     * Helper
     */

    private getOptionByValue = (value: string | number | (string | number)[], options: SelectOption[]) => {
        if (isArray(value)) {
            // translate all strings/numbers to `SelectOption`
            const foundOptions = reduce(value, (acc: SelectOption[], s: string) => {
                if (!isString(s) && !isNumber(s)) {
                    return acc;
                }
                const option = find(this.props.options, (o: SelectOption) => o.value.toString() === s.toString());
                return option ? [...acc, option] : acc;
            }, []);
            return foundOptions;
        }
        else {
            if (isString(value) || isNumber(value)) {
                const option = find(this.props.options, (o: SelectOption) => o.value.toString() === value.toString());
                if (option) {
                    return option;
                }
                else {
                    // select needs object {value, label} as its value to property display option
                    if (this.props.creatable) {
                        return {value: value.toString(), label: value.toString()};
                    }
                }
            }
        }
    };

    private getAsyncOptionByValue = (value: any) => value === "" ? null : value;

    /**
     * Render
     */

    private renderSelect = (): JSX.Element => {
        const {input: {value, name}, async, className, ...restProps} = this.props;
        const fieldClassName = classnames("", className);
        const valueOption = async ? this.getAsyncOptionByValue(value) : this.getOptionByValue(value, this.props.options);
        const selectProps = {
            placeholder: "Wybierz",
            noResultsText: "Nie znaleziono wyników",
            searchPromptText: "Zacznij pisać żeby zobaczyć wyniki",
            loadingPlaceholder: "Ładowanie...",
            ...restProps,
            name,
            value: this.state.isLoading ? null : valueOption,
            isLoading: this.state.isLoading ? true : undefined,
            inputProps: {id: name},
            openOnFocus: true,
            className: fieldClassName,
            onChange: this.onChange,
            tabSelectsValue: false,
            // These 2 properties are responsible for cleaning input on blur when you were typing
            // Michał, do you know by any chance how to cast this? Or if this should be removed because is a dirty hack? ;p
            ref: name,
            onBlur: () => this.props.multi && (this.refs[name] as any).refs.select.clearInput()
        };

        if (async) {
            return <ReactSelect.Async {...selectProps} />;
        }
        return <ReactSelect {...selectProps} />;

    };

    public render(): JSX.Element {
        const {error, input, label, groupClassName, menuUp, hideLabel} = this.props;
        const holderClassName = classnames("psr form-group", groupClassName, error && "has-error", {"menu-outer-top": !!menuUp});
        const className = classnames({"hidden": !error});
        const errorField = error ? error : "";

        return (
            <div className={holderClassName}>
                {!hideLabel && label && <FieldLabel label={label} htmlFor={input.name} />}
                <FieldError error={errorField} className={className}><span>{this.renderSelect()}</span></FieldError>
            </div>
        );
    };
}
