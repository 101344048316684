import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch} from "redux";
import {nphApiLink} from "../../project/route/nph_api_link";
import {patchRequest} from "../../../../shared/ts/helpers/request_wrapper";


const PATCH_FLOOR_PLAN = "building-floor/patch";
export const patchFloorPlanTypes = createRequestActionTypes(PATCH_FLOOR_PLAN);

export const patchFloorPlan = (floorId: number, data: {}) => async (dispatch: Dispatch) => {
    dispatch({type: patchFloorPlanTypes.start});

    const url = nphApiLink.floors.detail({floorId});

    return patchRequest(url, data)
        .then((res) => {
            dispatch({type: patchFloorPlanTypes.success, result: res});
            return res;
        });
};
