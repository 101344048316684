import * as React from "react";
import {ModalProps} from "../../../../../shared/ts/decorators/modalix";
import {ImageEditor} from "../ImageEditor";

interface IProps extends ModalProps {
    modalTitle?: JSX.Element | string;
    image: string;
    name: string;
    onUpdate: (image: string) => void;
    height: number;
    width: number;
}

export const CropperModal = (props: IProps) => {
    return (
        <div className="modal-dialog modal-primary modal-crop-image" style={{height: 800}}>
            <div className="bd-0 modal-content" tabIndex={0}>
                <div className="modal-header-box p-md fs16 modal-header text-center">
                    <button className="btn-close close" type="button" onClick={() => props.onReject()}>&times;</button>
                    <h4 className="modal-title">Utwórz zdjęcie inwestycji</h4>
                </div>
                <ImageEditor initialImage={props.image} name={props.name} onUpdate={props.onUpdate} onReject={props.onReject} height={props.height} width={props.width}/>
            </div>
        </div>
    );
};
