import {map} from "lodash";

import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {Region} from "../../sms/models/Region";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {createDebouncedAction} from "../../common/create_debounced_request";
import {nphApiLink} from "../../project/route/nph_api_link";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";


const asyncRegionsSimple = (name: string): Promise<any> => {
    const urlString = appendQueryString(nphApiLink.regions(), {name: name, page_size: 20});

    return getRequest(urlString).then((json: any) => {
        const regions: Region[] = json.results;
        return {
            options: regions.map((region: Region) => {
                return {
                    label: region.short_name,
                    value: region.pk
                };
            })
        };
    });
};
export const asyncRegions = createDebouncedAction(asyncRegionsSimple, 500);

export const fetchRegion = (id: number): Promise<any> => {
    return getRequest(nphApiLink.regionsView({id}))
        .then((region: Region) => {
            return region && {
                    label: region.short_name,
                    value: region.pk
                };
        })
        .catch(catch404(responseError => {
            return {
                label: id.toString(),
                value: id.toString()
            };
        }));
};

export const fetchRegions = (ids: number[]): Promise<any> => {
    const allPromises = map(ids, id => fetchRegion(id));
    return Promise.all(allPromises);
};
