import * as React from "react";

import {middleware} from "../../../../shared/ts/decorators/middleware";

import {requireAuth} from "../../auth/actions/require_auth";
import {fetchVariables} from "../actions/fetch_variables";
import NavPanel from "../../nav_panel/components/NavPanel";
import {fetchAccountInfo} from "../actions/fetch_account_info";


interface LoggedInWrapperProps {}


@middleware(requireAuth(), fetchAccountInfo(), fetchVariables())
export default class LoggedInWrapper extends React.Component<LoggedInWrapperProps, {}> {
    public render() {
        return (
            <div className="vh100 df fd-row fg-1 fs-1 fb-a">
                <NavPanel/>
                {this.props.children}
            </div>
        );
    }
}
