import * as React from "react";
import * as _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {Modal as ModalBase, Sizes} from "react-bootstrap";

import {Button} from "../components/button";
import {hideModal} from "../actions/modal";
import {ModalStore} from "../reducers/modal";
import {modalTexts} from "../constants/texts/modal";

interface ModalStateProps {
    state?: ModalStore;
}

interface ModalActionProps {
    actions?: {
        hideModal: typeof hideModal;
    };
}

interface ModalProps extends ModalActionProps, ModalStateProps {
    id: string;
    onConfirm: (data?: any) => void;
    onReject?: (data?: any) => void;
    bsSize?: Sizes;
    bsStyle?: string;
    confirmBsStyle?: string;
    confirmBsSize?: Sizes;
    cancelBsStyle?: string;
    cancelBsSize?: Sizes;
    footerBsClass?: string;
    headerBsClass?: string;
    title?: string;
    confirmText?: string;
    cancelText?: string;
    className?: string;
    // Dodane aby nie renderowac odpowiednich elementow
    noFooter?: boolean;
    noConfirmBtn?: boolean;
    noCancelBtn?: boolean;
}

interface ModalState {
    show: boolean;
}


/**
 * Modal wrapper.
 */
@connect(mapStateToProps, mapActionsToProps)
export class OldModal extends React.Component<ModalProps, ModalState> {

    constructor(props: ModalProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.state = {
            show: false
        };
    }

    /**
     * React lifecycle methods
     */
    public componentWillReceiveProps(newProps: ModalProps): void {
        if (this.props.id && newProps.state && this.props.id === newProps.state.id) {
            this.setState({show: newProps.state.show});
        }
    }

    private onClose(e: React.MouseEvent): void {
        this.reject();
        this.closeModal();
    }

    private onConfirm(e: React.MouseEvent): void {
        // bez setTimeout jest blad na kalendarzu jesli zamykamy modal klikajac na button, temat do zbadania
        setTimeout(() => {
            this.confirm();
            this.closeModal();
        });
    }

    private onKeyPress(e: React.KeyboardEvent): void {
        if (e.which === 13) {
            this.confirm();
            this.closeModal();
        }
    }

    private closeModal(): void {
        this.props.actions!.hideModal(this.props.id);
    }

    private confirm(): void {
        this.props.onConfirm(this.props.state!.callbackParams);
    }

    private reject(): void {
        if (_.isFunction(this.props.onReject)) {
            this.props.onReject(this.props.state!.callbackParams);
        }
    }

    /**
     * Render methods
     */
    public render(): JSX.Element {
        const props = this.props;

        // Modal header class names (change background and text color)
        const headerClassName = props.bsStyle ? `bgc-${props.bsStyle} c-white` : "";

        // Modal size
        const bsSize = props.bsSize || "sm";

        let renderConfirmBtn: JSX.Element | null;
        if (!props.noConfirmBtn) {
            renderConfirmBtn = (
                <Button color={props.confirmBsStyle} size={props.confirmBsSize} onClick={this.onConfirm}>
                    {props.confirmText || modalTexts.defaults.confirm}
                </Button>
            );
        } else {
            renderConfirmBtn = null;
        }

        let renderCancelBtn: JSX.Element | null;
        if (!props.noCancelBtn) {
            renderCancelBtn = (
                <Button color={props.cancelBsStyle} size={props.cancelBsSize} onClick={this.onClose}>
                    {props.cancelText || modalTexts.defaults.cancel}
                </Button>
            );
        } else {
            renderCancelBtn = null;
        }

        let renderFooter: JSX.Element | null;
        if (!props.noFooter) {
            renderFooter = (
                <ModalBase.Footer bsClass={`modal-footer ${props.footerBsClass}`}>
                    {renderConfirmBtn}

                    {renderCancelBtn}
                </ModalBase.Footer>
            );
        } else {
            renderFooter = null;
        }

        return (
            <ModalBase onKeyPress={this.onKeyPress} bsSize={bsSize} show={this.state.show} onHide={this.onClose} className={props.className}>
                <ModalBase.Header bsClass={`modal-header ${props.headerBsClass ? props.headerBsClass : ""} ${headerClassName}`} closeButton>
                    <ModalBase.Title>
                        {props.title || modalTexts.defaults.title}
                    </ModalBase.Title>
                </ModalBase.Header>

                <ModalBase.Body>
                    {props.children || modalTexts.defaults.body}
                </ModalBase.Body>

                {renderFooter}
            </ModalBase>
        );
    }
}

function mapStateToProps(state: any): ModalStateProps {
    return {
        state: state.modal
    };
}

function mapActionsToProps(dispatch: Dispatch): ModalActionProps {
    return {
        actions: bindActionCreators({hideModal}, dispatch)
    };
}
