import * as lscache from "lscache";
import {Dictionary} from "lodash";

import {authorizationHeaderData} from "./request_wrapper";


// initialize authorization header function
authorizationHeaderData.init(() => {
    const apikey = getUserData().apikey;
    return (apikey ? {"Authorization": `Token ${apikey}`} : {}) as Dictionary<string>;
});

// auth data
const lsCacheKey = "userData";
let _userData: any = {
    apikey: null
};


export function logIn(newUserData: any): void {
    _userData = newUserData;
    lscache.set(lsCacheKey, _userData);
}

export function logOut(): void {
    _userData.apikey = null;
    _userData.permissions = null;
    lscache.remove(lsCacheKey);
}

export function restoreSession(): void {
    let storedUserData = lscache.get(lsCacheKey);
    if (storedUserData) {
        _userData = storedUserData;
    }
}

export function getUserData(): any {
    return _userData;
}

export function isLoggedIn(): boolean {
    return getUserData() && getUserData().apikey;
}
