import * as React from "react";
import * as classnames from "classnames";
import {Field, WrappedFieldProps} from "redux-form";

import TimePicker from "../time_picker/TimePicker";
import FieldLabel from "../../FieldLabel";
import {Store} from "../../../../project/reducer";
import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {createRange} from "../create_range";
import DatePicker from "../date_picker/DatePicker";
import FieldError from "../../FieldError";


/**
 * Field
 */
interface DateTimePickerFieldProps extends WrappedFieldProps<Store>, DateTimePickerProps, StyleProps {
    error?: string;
}

class DateTimePickerField extends React.Component<DateTimePickerFieldProps, {}> {

    private renderLabel(): JSX.Element | null {
        const {input: {name}, label} = this.props;
        if (!this.props.label) {
            return null;
        }

        return (
            <div className="col-xs-12">
                <FieldLabel label={label} htmlFor={`${name}__date`}/>
            </div>
        );
    }

    private renderFields(): JSX.Element {
        const {input: {name}} = this.props;
        return (
            <div className="col-xs-12">
                <div className="row">
                    <div className="col-xs-6">
                        <DatePicker name={`${name}__date`} disabled={this.props.disabled} groupClassName={this.props.groupClassName} />
                    </div>
                    <div className="col-xs-6">
                        <TimePicker name={`${name}__time`} disabled={this.props.disabled} groupClassName={this.props.groupClassName} />
                    </div>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const {error} = this.props;
        const holderClassName = classnames("row", error && "has-error", this.props.className);
        const fields = this.renderFields();

        return (
            <div className={holderClassName}>
                {this.renderLabel()}
                {error ? <FieldError error={error}>{fields}</FieldError> : fields}
            </div>
        );
    }
}

/**
 * Main
 */
interface DateTimePickerProps {
    name: string;
    label?: string;
    disabled?: boolean;
    groupClassName?: string;
    onValueUpdate?: (name: string) => void; //TODO: implement onValueUpdate on this component
}


export class DateTimePicker extends React.Component<DateTimePickerProps, {}> {
    public render(): JSX.Element {
        return (
            <Field
                {...this.props}
                component={DateTimePickerField}
            />
        );
    }
}

export const DateTimePickerRange = createRange(DateTimePicker);
