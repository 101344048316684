import {each, isArray} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {deleteRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {Dispatch, ErrorAction} from "../../../../../shared/ts/interfaces/dispatch";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {invalidateReelList} from "./fetch";
import {nphApiLink} from "../../../project/route/nph_api_link";

export const REEL_DELETE = "reel/DELETE";
export const reelDeleteRequest = createRequestActionTypes(REEL_DELETE);

export const reelDelete = (uuid: number) => (dispatch: Dispatch) => {
    dispatch({type: reelDeleteRequest.start});

    return deleteRequest(nphApiLink.reels.delete({reelId: uuid}))
        .then(() => {
            dispatch({type: reelDeleteRequest.success});
            dispatch(invalidateReelList());
            dispatch(addSuccessAlert("Usunięto reel"));
            return true;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, REEL_DELETE)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", REEL_DELETE));
            }
            dispatch<ErrorAction>({type: reelDeleteRequest.error, error: responseError.appError});
            return null;
        }));
};
