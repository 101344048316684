import * as React from "react";

interface IProps {
    className?: string;
    width?: number;
    height?: number;
}

export const EmtpyImg = (props: IProps) => {

    const className = props.className ? props.className : "";
    const width = props.width ? props.width : 157;
    const height = props.height ? props.height : 88;

    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <defs>
                <rect id="mask-a" width={width} height={height} x="0" y="0"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="mask-b" fill="#fff">
                    <use href="#mask-a"/>
                </mask>
                <use fill="#F5F5F5" href="#mask-a"/>
                <path fill="#ABABAB" d="M50.3029514,45.1149381 L91,88 L91,88 L-2,88 L38.6970486,45.1149381 C41.7384158,41.9100565 46.8020009,41.7775019 50.0068825,44.8188691 C50.1081383,44.9149588 50.2068617,45.0136823 50.3029514,45.1149381 Z" mask="url(#mask-b)"/>
                <path fill="#CCC" d="M110.228769,28.7282881 L158,88 L158,88 L50,88 L97.7712305,28.7282881 C100.543812,25.2882337 105.580148,24.7471397 109.020202,27.5197208 C109.46506,27.8782629 109.870227,28.2834304 110.228769,28.7282881 Z" mask="url(#mask-b)"/>
                <circle cx="70.5" cy="31.5" r="11.5" fill="#CCC" mask="url(#mask-b)"/>
            </g>
        </svg>

    );
};
