import {url} from "../../../shared/ts/helpers/routing";
import {ReelListView} from "./views/ReelListView";
import {ReelAddView} from "./views/ReelAddView";
import {ReelEditView} from "./views/ReelEditView";
import {ReelsCreatorListView} from "./views/ReelsCreatorListView";


export const videoFrontendRoute = {
    path: "wideo/",
    name: "video",
    indexRoute: {
        onEnter: (nextState: any, replace: any) => {
            replace(
                url("app:in:video:reel:list")
            );
        }
    },
    childRoutes: [
        {
            path: "reels/",
            name: "reel",
            childRoutes: [
                {
                    path: "autor/",
                    name: "creator",
                    component: ReelsCreatorListView
                },
                {
                    path: "lista/",
                    name: "list",
                    component: ReelListView
                },
                {
                    path: "dodaj/",
                    name: "add",
                    component: ReelAddView
                },
                {
                    path: ":id/",
                    name: "edit",
                    component: ReelEditView
                }
            ]
        },
    ]
};
