import * as _ from "lodash";
import {Dictionary} from "lodash";


/**
 * Assign
 */

export function assign<T extends (U | any), U>(state: T, stateChange: U): T {
    return <T>_.assign({}, state, stateChange);
}

export function assignAtIndex<T extends (U | any), U>(state: T[], idx: number, data: U): T[] {
    if (idx < 0 || state.length <= idx) {
        return state;
    }
    return [
        ...state.slice(0, idx),
        assign(state[idx], data),
        ...state.slice(idx + 1)
    ];
}

export function removeAtIndex<T>(state: T[], idx: number): T[] {
    if (idx < 0 || state.length <= idx) {
        return state;
    }
    return [
        ...state.slice(0, idx),
        ...state.slice(idx + 1)
    ];
}

export function assignByProp<T>(state: T[], props: any, data: T): T[] {
    const index = _.findIndex(state, props);
    return index === -1 ? state : assignAtIndex(state, index, data);
}


/**
 * Request
 */

export enum RequestState {
    None,
    Waiting,
    Success,
    Error
}

export interface AppError {
    status: number;
    fieldErrors: Dictionary<string[]>;
    alerts: string[];
}

export interface ErrorResp {
    [s: string]: string[];
}
