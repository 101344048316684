import * as React from "react";
import * as _ from "lodash";

import {FormComponent, FormComponentProps} from "../../../../shared/ts/components/forms/formComponent";


type ArrayFieldValue = any[];
interface ArrayFieldProps extends FormComponentProps<ArrayFieldValue> {
    arrayIndex: number;
}
interface ArrayFieldState {}


export const arrayField = (InnerComponent: any): any => {

    class ArrayField extends FormComponent<ArrayFieldValue, ArrayFieldProps, ArrayFieldState> {

        /**
         * Helper static
         */

        public static getInnerClass(): any {
            return InnerComponent;
        }

        public static isEmpty(value: string): boolean {
            return !_.isArray(value) || _.isEmpty(value);
        }

        private static emptyValue: ArrayFieldValue = [];

        /**
         * Transition static
         */

        private static mapTransform(functionName: string, name: string, values: any): any {
            return {
                [name]: _.map(values[name], (value: any) => {
                    const jsonData = InnerComponent[functionName](name, {[name]: value});
                    return jsonData[name];
                })
            };
        }

        public static toJSON(name: string, values: any): any {
            if (ArrayField.isEmpty(values[name])) {
                return {};
            }
            return ArrayField.mapTransform("toJSON", name, values);
        }

        public static toFormData(name: string, values: any): any {
            if (ArrayField.isEmpty(values[name])) {
                return {};
            }
            return ArrayField.mapTransform("toFormData", name, values);
        }

        public static toFormDataPost(name: string, values: any): any {
            return ArrayField.toFormData(name, values);
        }

        public static fromJSON(name: string, values: any): any {
            return ArrayField.mapTransform("fromJSON", name, values);
        }

        public static fromFormData(name: string, values: any): any {
            return ArrayField.mapTransform("fromFormData", name, values);
        }

        /**
         * Instance
         */

        constructor(props: ArrayFieldProps) {
            super(props);
            this.onValueChange = this.onValueChange.bind(this);
            this.onFormUpdate = this.onFormUpdate.bind(this);
        }

        /**
         * Callbacks
         */

        private onValueChange(name: string, nextValue: any): void {
            const {arrayIndex, value, onValueChange} = this.props;
            if (_.isFunction(onValueChange)) {
                let updated = _.clone(value);
                updated[arrayIndex] = nextValue;
                onValueChange(name, updated);
            }

        }

        private onFormUpdate(name: string, nextValue: any): void {
            const {arrayIndex, value, onFormUpdate} = this.props;
            if (_.isFunction(onFormUpdate)) {
                let updated = _.clone(value);
                updated[arrayIndex] = nextValue;
                onFormUpdate(name, updated);
            }
        }

        /**
         * Render
         */

        public render(): JSX.Element {
            const {arrayIndex, value, error} = this.props;
            const validValue = value || ArrayField.emptyValue;
            const validError = error || [];

            let innerProps = _.omit(this.props, ["arrayIndex", "ref"]) as Omit<ArrayFieldProps, "arrayIndex" | "ref">;
            innerProps.value = validValue[arrayIndex];
            innerProps.error = validError[arrayIndex];
            innerProps.onValueChange = this.onValueChange;
            innerProps.onFormUpdate = this.onFormUpdate;

            return <InnerComponent {...innerProps}/>;
        }
    }

    return ArrayField;
};
