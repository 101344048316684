import {get, isArray, isString, isEmpty} from "lodash";


export const createGenerateFieldProps = (formInstance: any, customProps: any = {}) => (name: string) => {
    let error = get(formInstance.props.fieldErrors, name) as string | undefined;
    error = isArray(error) ? error.join("\n") : error;
    error = isString(error) && !isEmpty(error) ? error : undefined;
    return {
        name,
        error,
        ...customProps
    };
};
