import {isArray, each} from "lodash";

import {createRequestActionTypes, RequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {createCampaignFetch, invalidateCampaignList} from "./fetch";
import {Campaign} from "../models/Campaign";
import {Dispatch, ResponseAction, ErrorAction} from "../../../../shared/ts/interfaces/dispatch";
import {patchRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../shared/ts/helpers/errors";
import {addErrorAlert, addSuccessAlert} from "../../shared/alerts/actions";
import {nphApiLink} from "../../project/route/nph_api_link";


/**
 * POST Factories
 */

const createCampaignPost = (type: string, actionTypes: RequestActionTypes) => (uuid: string, jsonData: Campaign) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.campaigns.edit({id: uuid});
    return patchRequest(urlString, jsonData)
        .then((campaign: Campaign) => {
            dispatch<ResponseAction<Campaign>>({type: actionTypes.success, result: campaign});
            dispatch(invalidateCampaignList());
            dispatch(addSuccessAlert("Zapisano kampanię SMS", type));
            return campaign;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, type)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", type));
            }
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};

/**
 * Clear Edit View
 */
export const CLEAR_EDIT_VIEW_STATE = "campaigns/add/CLEAR_EDIT_VIEW_STATE";
export const clearEditViewState = () => ({type: CLEAR_EDIT_VIEW_STATE});

/**
 * Edit fetch campaign
 */
const EDIT_CAMPAIGN_FETCH = "campaigns/edit/EDIT_CAMPAIGN_FETCH";
export const campaignEditFetchTypes = createRequestActionTypes(EDIT_CAMPAIGN_FETCH);
export const campaignEditFetch = createCampaignFetch(campaignEditFetchTypes);

/**
 * Edit patch campaign
 */
const EDIT_CAMPAIGN_POST = "campaigns/edit/EDIT_CAMPAIGN_POST";
export const campaignEditPatchTypes = createRequestActionTypes(EDIT_CAMPAIGN_POST);
export const campaignEditPatch = createCampaignPost(EDIT_CAMPAIGN_POST, campaignEditPatchTypes);

export const UPDATE_CAMPAIGN_PATCH_TIMESTAMP = "campaigns/edit/UPDATE_CAMPAIGN_PATCH_TIMESTAMP";
export function updateCampaignPatchTimestamp(timestamp: number): any {
    return {
        type: UPDATE_CAMPAIGN_PATCH_TIMESTAMP,
        timestamp
    };
}
