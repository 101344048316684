import {combineReducers} from "redux";

import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {campaignListContextPanel, ContextPanelState} from "../../context_panel/reducers/context_panel";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";
import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";

import {reelRequest} from "../actions/reels/fetch";
import {filterReelListFormTypes} from "../actions/reels/filter_reel_list";
import {
    addReelFormTypes,
    CLEAR_REEL_ADD_EDIT_ERRORS,
    CLEAR_REEL_ADD_EDIT_VIEW_STATE,
    reelRequestTypes
} from "../actions/reels/add";
import {ReelForm} from "../components/ReelForm";
import {ReelListFilterForm} from "../components/context_panel/reel/ReelListFilterForm";
import {getRandomId} from "../helpers/get_random_id";

export interface IReelFormValues {
    action_button_enabled: boolean;
    action_button_offer?: number;
    action_button_property_enabled?: boolean;
    action_button_property?: number;
    action_button_type?: number;
    action_button_name?: string;
    action_button_url?: string;
    display_in_offer_enabled: boolean;
    display_in_offer?: number | string;
    cover: string;
    cover_variant: string;
    creator: number | string;
    creator_image?: string;
    display_date_range: string;
    region?: number;
    title: string;
    type: number;
    video?: File;
}

export interface IReelForms {
    request: RequestState;
    errors: AppError | null;
    form: IReelFormValues;
}

export interface IReel {
    create_date: string;
    pk: number;
    title: string;
    creator: {
        image: string;
        name: string;
        pk: number;
    };
    action_button_enabled: boolean;
    action_button_offer?: number;
    action_button_property_enabled?: boolean;
    action_button_property?: number;
    action_button_type?: number;
    action_button_name?: string;
    action_button_url?: string;
    cover: string;
    cover_variant: string;
    display_in_offer?: number;
    display_date_range?: {lower: string, upper: string};
    region?: {
        pk: number;
        short_name: string;
    };
    type: number;
    video?: string;
}

export interface IReelFilterForm {
    creator: string;
    region: string;
    title: string;
    type: string;
}

export interface ReelStore {
    natives: IReel[];
    pagination: PaginationState;
    fetch: {
        requestState: RequestState;
        errors: AppError | null;
        latestQuery: Dictionary<string>;
    };
    list: {
        contextPanel: ContextPanelState;
        filterForm: IReelFilterForm;
    };
    detail: IReelForms;
}

const clearAddEditView = (store: IReelForms, action: Action): any => {
    switch (action.type) {
        case CLEAR_REEL_ADD_EDIT_VIEW_STATE:
            return {
                form: {
                    cover: {
                        id: getRandomId(),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    cover_variant: {
                        id: getRandomId(),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    video: {
                        id: getRandomId(),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    creator: {value: ""}
                },
                request: RequestState.None,
                errors: null
            };
        case CLEAR_REEL_ADD_EDIT_ERRORS:
            return {
                ...store,
                errors: null
            };
        default:
            return store;
    }
};

const reels = combineReducers({
    natives: reducePaginatedResponse(reelRequest),
    pagination: reducePagination(reelRequest),
    fetch: combineReducers({
        requestState: reduceRequestState(reelRequest),
        errors: reduceRequestErrors(reelRequest),
        latestQuery: reduceLatestQuery(reelRequest)
    }),
    list: combineReducers({
        contextPanel: campaignListContextPanel,
        filterForm: reduceFormData(filterReelListFormTypes, ReelListFilterForm.fromJSON({}))
    }),
    detail: reduceReducers(
        combineReducers({
            request: reduceRequestState(reelRequestTypes),
            errors: reduceRequestErrors(reelRequestTypes),
            form: reduceFormData(addReelFormTypes, ReelForm.fromJSON({}))
        }),
        clearAddEditView
    )
});

export default reels;
