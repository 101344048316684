import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../../shared/ts/interfaces/style";

interface ContentHeaderProps extends StyleProps {}

export const ContentHeaderC: React.SFC<ContentHeaderProps> = props => {
    const className = classnames(props.className, "header-holder ph-xxxl fs18");
    return (
        <div className={className}>
            <div className="df">{props.children}</div>
        </div>
    );
};

const ContentHeader = ContentHeaderC;
export default ContentHeader;
