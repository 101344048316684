import * as React from "react";
import * as _ from "lodash";
import shallowCompare = require("react-addons-shallow-compare");
import {RequestState} from "../../helpers/util";


export interface FormComponentProps<V> {
    name: string;
    onValueChange?: (name: string, newValue: V, shouldTriggerSubmitOnChange?: boolean) => void;
    onFormUpdate?: (name: string, newValue: V, errors?: any) => void;
    error: any;
    value: V;
    formName?: string;
    requestState?: RequestState;
    markable?: boolean;
}

export interface FormComponentState {}

export abstract class FormComponent<V, T extends FormComponentProps<V>, U extends FormComponentState> extends React.Component<T, U> {

    public static isEqual(val1: any, val2: any): boolean {
        return _.isEqual(val1, val2);
    }

    public static isEmpty(val: any): boolean {
        return val == null || val === "";
    }

    public static renderValue(val: any): any {
        return val && val.toString();
    }

    public shouldComponentUpdate(nextProps: FormComponentProps<V>, nextState: FormComponentState): boolean {
        return shallowCompare(this, nextProps, nextState);
    }
}
