import * as React from "react";
import {FormGroup} from "react-bootstrap";
import * as classNames from "classnames";
import {isEmpty} from "lodash";

import {IFormFieldProps} from "../../create_form";
import {HelpOverlay} from "../../../../../../shared/ts/components/help_overlay";

interface IProps extends IFormFieldProps<any> {
    label?: string | JSX.Element;
    groupClassName?: string;
    className?: string;
    id?: string;
    onChange: (name: string, value: any) => void;
    helpText?: any;
    helpPlacement?: string;
    helpClassName?: string;
    markable?: boolean;
}

export const FormCheckbox = (props: IProps) => {
    const onChange = (e: React.FormEvent): void => {
        let value = (e.target as HTMLInputElement).checked;
        props.onChange(props.name, value);
    };

    const {name, error, label, className, helpText, helpPlacement, helpClassName} = props;
    const groupClassName = classNames("form-group", props.groupClassName, props.markable && isEmpty(props.value) ? "is-active" : "");

    // Help overlay
    let helpOverlay: JSX.Element | undefined;
    if (helpText) {
        const helpOverlayId = `${name}helpOverlay`;
        helpOverlay = (
            <div className="dib vam ml-md">
                <HelpOverlay id={helpOverlayId} placement={helpPlacement || "right"} className={helpClassName}>
                    {helpText}
                </HelpOverlay>
            </div>
        );
    }

    const checkBox = (
        <div className="dib vam">
            <div className={`checkbox ${error ? "has-error" : ""}`}>
                <label>
                    <input
                        className={className}
                        type="checkbox"
                        id={name}
                        value={props.value}
                        onChange={onChange}
                        checked={props.value}
                    />
                    {label}
                </label>
            </div>
        </div>
    );

    return (
        <FormGroup bsClass={groupClassName}>
            {checkBox}{helpOverlay}
        </FormGroup>
    );
}
