import {reduce} from "lodash";
import DatePicker from "../fields/date_picker/DatePicker";
import DatePickerRange from "../fields/date_picker/DatePickerRange";
import Input from "../fields/input/Input";
import {Select, SelectAsync, SelectCreatable, SelectAsyncCreatable} from "../fields/select/Select";
import {DateTimePicker, DateTimePickerRange} from "../fields/date_time_picker/DateTimePicker";
import Textarea from "../fields/textarea/Textarea";
import TimePicker from "../fields/time_picker/TimePicker";
import {TimePickerRange} from "../fields/time_picker/TimePicker";
import Phone from "../fields/phone/Phone";
import File from "../fields/file/File";
import {FormCheckbox} from "../fields/checkbox/FormCheckbox";


type RawData = any;
type Json = any;
type FormFieldClass = any;


const strEmpty = (value: any, defaultValue: string = "") => value == null ? defaultValue : value;


export const fromJson = (types: {[key: string]: FormFieldClass}, json: Json): RawData => {
    return reduce(types, (acc: RawData, type: FormFieldClass, key: string) => {
        switch (type) {
            /**
             * Field
             */
            case DatePicker:
            case Input:
            case Select:
            case SelectAsync:
            case SelectCreatable:
            case SelectAsyncCreatable:
            case Textarea:
            case TimePicker:
                return {...acc, [key]: strEmpty(json[key])};
            case DateTimePicker:
                const dateTime = strEmpty(json[key], " ").split(" ");
                return {
                    ...acc,
                    [`${key}__date`]: dateTime[0],
                    [`${key}__time`]: dateTime[1]
                };
            case Phone:
                const phone = strEmpty(json[key], " ").split(" ");
                return {
                    ...acc,
                    [`${key}__code`]: parseInt(phone[0], 10),
                    [`${key}__number`]: phone[1]
                };
            case File:
                const fileName = strEmpty(json[key]) && json[key].substr(json[key].lastIndexOf("/") + 1);
                return {...acc, [key]: {url: json[key], name: fileName}};
            case FormCheckbox:
                return {[key]: !!json[key]};
            /**
             * Range
             */
            case DatePickerRange:
            case TimePickerRange:
                const lower = json[key] && json[key].lower;
                const upper = json[key] && json[key].upper;
                return {
                    ...acc,
                    [`${key}__lower`]: strEmpty(lower),
                    [`${key}__upper`]: strEmpty(upper)
                };
            case DateTimePickerRange:
                const lowerDateTime = strEmpty(json[key] && json[key].lower, " ").split(" ");
                const upperDateTime = strEmpty(json[key] && json[key].upper, " ").split(" ");
                return {
                    ...acc,
                    [`${key}__lower__date`]: lowerDateTime[0],
                    [`${key}__lower__time`]: lowerDateTime[1],
                    [`${key}__upper__date`]: upperDateTime[0],
                    [`${key}__upper__time`]: upperDateTime[1]
                };
            default:
                return console.error(`fromJson: not implemented for ${key}`);
        }
    }, {});
};
