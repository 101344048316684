import * as React from "react";

import {AlertData} from "./reducer";


interface AlertProps {
    alert: AlertData;
    removeAlert: (id: string) => void;
}


class Alert extends React.Component<AlertProps, {}> {

    private timeout: number;

    public componentDidMount() {
        if (this.props.alert.time) {
            this.timeout = window.setTimeout(() => this.props.removeAlert(this.props.alert.id), this.props.alert.time);
        }
    }

    public componentWillUnmount(): void {
        clearTimeout(this.timeout);
    }

    public render() {
        // TODO: give `removeAlert` props
        return React.Children.only(this.props.alert.content);
    }
}

export default Alert;
