import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {map, sortBy, Dictionary} from "lodash";
import {RouterProps, RouterState} from "../../../../shared/ts/interfaces/router";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentBody from "../../project/components/content/ContentBody";
import {Store} from "../../project/reducer";
import {fetchPlanOffer, IPlanOffer, IPlanOfferGallery, resetPlanOffer} from "../actions/fetch_plan_offer_actions";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import {IFabricApi, IFabricMode} from "../components/draw_polygon/init_fabric";
import {PlanItemCanvasHolder} from "../components/add_plan/PlanItemCanvasHolder";
import {PlanItemHeader} from "../components/add_plan/PlanItemHeader";
import {PlanType} from "./OfferPlanView";
import {url} from "../../../../shared/ts/helpers/routing";
import {BoundAction} from "../../../../typings/custom_typings/custom_redux";
import {fetchVendorGroupList, IVendorGroup, resetVendorGroupList} from "../../vendor/actions/fetch_vendor_group_list";
import {fetchPlanBuildingList, IPlanBuilding, resetPlanBuildingList} from "../actions/fetch_plan_building_actions";
import {patchBuildingPlan} from "../actions/patch_building_plan";
import {PlanEstateAddImage} from "../components/estate/PlanEstateAddImage";
import {PlanEstateBuildingListItem} from "../components/estate/PlanEstateBuildingListItem";
import {AssignBuildingModal} from "../components/estate/AssignBuildingModal";
import {
    deletePlanPolygon,
    IFloorPlanPolygon,
    updatePlanPolygon
} from "../actions/plan_polygon_actions";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {
    AssignPropertyModal,
    fromCustomPolygonId,
    isCustomPolygonId,
    toCustomPolygonId
} from "../components/floor/AssignPropertyModal";
import {OfferType} from "../../../../shared/ts/constants/OfferType";
import {
    fetchPlanPropertyListByOffers,
    IPlanProperty,
    resetPlanPropertyList
} from "../actions/fetch_plan_property_actions";
import {FloorPropertiesListItem} from "../components/floor/FloorPropertiesListItem";
import {patchPropertyPlan} from "../actions/patch_property_plan";
import {Modal} from "../../../../shared/ts/modals/Modal";
import {Button} from "../../shared/button/Button";
import {AreaFormat} from "../../../../shared/ts/components/NumbersFormater";


interface IStateProps {
    offer: IPlanOffer | null;
    group: IVendorGroup | null;
    buildings: IPlanBuilding[];
    buildingsRequest: RequestState;
    properties: IPlanProperty[];
    propertiesRequest: RequestState;
    listLatestQuery: Dictionary<string>;
}
interface IActionsProps {
    actions: {
        fetchPlanOffer: BoundAction<typeof fetchPlanOffer>;
        resetPlanOffer: typeof resetPlanOffer;
        fetchVendorGroupList: BoundAction<typeof fetchVendorGroupList>;
        resetVendorGroupList: typeof resetVendorGroupList;
        fetchPlanBuildingList: BoundAction<typeof fetchPlanBuildingList>;
        resetPlanBuildingList: typeof resetPlanBuildingList;
        fetchPlanPropertyListByOffers: BoundAction<typeof fetchPlanPropertyListByOffers>;
        resetPlanPropertyList: typeof resetPlanPropertyList;
        // patch
        deletePlanPolygon: BoundAction<typeof deletePlanPolygon>;
        updatePlanPolygon: BoundAction<typeof updatePlanPolygon>;
        patchBuildingPlan: BoundAction<typeof patchBuildingPlan>;
        patchPropertyPlan: BoundAction<typeof patchPropertyPlan>;
    };
}
interface IProps extends IActionsProps, IStateProps, RouterState, RouterProps {}

const EstatePlanViewC = (props: IProps) => {

    useEffect(() => {
        (async () => {
            const offerId = parseInt(props.params["offer_id"], 10);
            const offer = await props.actions.fetchPlanOffer(offerId);
            if (offer == null) {
                throw new Error(`EstatePlanView: cannot fetch offer ${offerId}`);
            }
            const groups = await props.actions.fetchVendorGroupList(offer.vendor.pk, offer.pk);
            if (groups == null || groups[0] == null) { // cannot fetch vendor group
                return props.router.push(url("app:in:offerPlans:list", null, props.listLatestQuery));
            }
            if (offer.type.pk === OfferType.HOUSE) {
                await props.actions.fetchPlanPropertyListByOffers(groups[0].offers);
            }
            else {
                await props.actions.fetchPlanBuildingList(groups[0].offers);
            }
        })();
        return () => {
            props.actions.resetPlanOffer();
            props.actions.resetVendorGroupList();
            props.actions.resetPlanBuildingList();
            props.actions.resetPlanPropertyList();
        };
    }, []);

    const apiRef = useRef<IFabricApi | null>(null);
    const [assignModalState, setAssignModalState] = useState<number | null>(null);
    const [mode, setMode] = useState<IFabricMode>({type: "view"});

    /**
     * Polygon modification logic
     */

    const onPolygonEditStop = async () => {
        if (mode.type !== "edit") {
            throw new Error(`EstatePlanView, onPolygonEditStop: wrong mode ${mode.type}`);
        }
        if (apiRef.current == null) {
            throw new Error("EstatePlanView, onPolygonEditStop: apiRef is not defined");
        }
        const polygon = apiRef.current.editStop();
        const apiPolygon = map(polygon, point => [point.fractionX, point.fractionY] as [string, string]);
        // patch building with polygon
        if (isCustomPolygonId(mode.polygonId)) {
            const customId = fromCustomPolygonId(mode.polygonId);
            await props.actions.updatePlanPolygon(customId, {polygon: apiPolygon, group: props.group!.id});
        }
        else {
            // building/property ID equals polygon ID
            if (props.offer && props.offer.type.pk === OfferType.HOUSE) {
                await props.actions.patchPropertyPlan(mode.polygonId, {plan_polygon: apiPolygon});
            }
            else {
                await props.actions.patchBuildingPlan(mode.polygonId, {plan_polygon: apiPolygon});
            }
        }
    };

    const [deleteModalState, setDeleteModalState] = useState<number | null>(null);
    const deletePolygon = async () => {
        const polygonId = deleteModalState as number;
        if (isCustomPolygonId(polygonId)) {
            const customId = fromCustomPolygonId(polygonId);
            await props.actions.deletePlanPolygon(customId);
        }
        else {
            if (props.offer && props.offer.type.pk === OfferType.HOUSE) {
                await props.actions.patchPropertyPlan(polygonId, {plan_polygon: null});
            }
            else {
                await props.actions.patchBuildingPlan(polygonId, {plan_polygon: null});
            }
        }
        apiRef.current && apiRef.current.remove(polygonId);
        setDeleteModalState(null);
    };

    /**
     * Render
     */

    const sortedBuildings = useMemo(() => sortBy(props.buildings, building => building.name), [props.buildings]);
    const sortedProperties = useMemo(() => sortBy(props.properties, property => property.number), [props.properties]);
    const buildingPolygonEntries = useMemo(() => map(props.buildings, b => ({id: b.id, plan_polygon: b.plan_polygon, text: (<span>{`Budynek ${b.name}`}</span>), color: "#00f"})), [props.buildings]);
    const polygonInfo = (p: any) => {
        if (props.offer && props.offer.type.pk === OfferType.HOUSE) {
            return (
                <div className="df fw-wrap">
                    {p.number && <span className="fwb">{`${p.number}`}</span>}
                    {p.name && <span className="fwb">{`${p.name}`}</span>}
                    {p.rooms && <span className="ml-md">{p.rooms} pok.</span>}
                    {p.area && <div className="ml-md"><AreaFormat area={p.area}/></div>}
                </div>
            );
        }
        return (
            <span>
                Budynek {p.name}
            </span>
        );
    };
    const propertyPolygonEntries = useMemo(() => map(props.properties, p => ({
        id: p.pk, plan_polygon: p.plan_polygon, color: "#00f",
        text: polygonInfo(p)
    })), [props.properties]);
    const customPolygonEntries = useMemo(() => {
        if (props.group == null) {
            return [];
        }
        return map(props.group.polygons, p => ({
            id: toCustomPolygonId(p.id), plan_polygon: p.polygon, color: "#00f",
            text: polygonInfo(p)
        }));
    }, [props.group]);
    const polygonEntries = useMemo(
        () => [...(props.offer && props.offer.type.pk === OfferType.HOUSE ? propertyPolygonEntries : buildingPolygonEntries), ...customPolygonEntries],
        [props.offer, buildingPolygonEntries, propertyPolygonEntries, customPolygonEntries]
    );

    const renderBody = () => {
        const paramOfferId = parseInt(props.params["offer_id"], 10);
        const {offer, group} = props;
        // do not render until the right data is fetched
        if (offer == null || offer.pk !== paramOfferId || group == null || (offer.type.pk === OfferType.HOUSE ? props.propertiesRequest : props.buildingsRequest) === RequestState.Waiting) {
            return (
                <CenterBox className="vhc">
                    <Loader size="lg"/>
                </CenterBox>
            );
        }

        const photos: IPlanOfferGallery[] = [{image: offer.main_image, pk: 0, sort: 0}, ...offer.gallery];

        return (
            <ContentBody className="h100 psr">
                <PlanItemHeader
                    onBackClick={() => props.router.push(url("app:in:offerPlans:list", null, props.listLatestQuery))}
                    offerName={offer.name}
                    type={PlanType.ESTATE}
                    canvasMode={mode.type}
                    drawStartPolygon={() => apiRef.current && apiRef.current.drawStart()}
                    drawStopPolygon={() => apiRef.current && apiRef.current.drawStop()}
                    editStopPolygon={onPolygonEditStop}
                    renderAdditionalHeader={() => (
                        <PlanEstateAddImage vendorId={offer.vendor.pk} estateId={group.id} offerGallery={photos} changeMode/>
                    )}
                />

                {group.plan_image ? (
                    <PlanItemCanvasHolder
                        key={group.plan_image}
                        offerName={offer.name}
                        image={group.plan_image}
                        polygons={polygonEntries}
                        onInitCanvas={api => apiRef.current = api}
                        onModeChange={setMode}
                        onDrawComplete={() => setAssignModalState(-1)}
                    />
                ) : (
                    <PlanEstateAddImage vendorId={offer.vendor.pk} estateId={group.id} offerGallery={photos}/>
                )}

                {offer.type.pk === OfferType.HOUSE ? (
                    <ul className="list-unstyled">
                        {map(sortedProperties, property => property.plan_polygon && (
                            <FloorPropertiesListItem key={property.pk} property={property}
                                                     deletePolygon={setDeleteModalState}
                                                     editAssignment={() => setAssignModalState(property.pk)}
                                                     editPolygon={() => apiRef.current && apiRef.current.editStart(property.pk)}
                                                     prefix="Nieruchomość"
                            />
                        ))}

                        {map(group.polygons, (polygon: IFloorPlanPolygon) => (
                            <FloorPropertiesListItem key={toCustomPolygonId(polygon.id)}
                                                     property={{plan_image: null, number: polygon.name, rooms: polygon.rooms, area: polygon.area, floor: null, for_sale: false, is_reserved: false, pk: polygon.id}}
                                                     deletePolygon={id => setDeleteModalState(toCustomPolygonId(id))}
                                                     editAssignment={() => setAssignModalState(toCustomPolygonId(polygon.id))}
                                                     editPolygon={() => apiRef.current && apiRef.current.editStart(toCustomPolygonId(polygon.id))}
                                                     prefix="Nieruchomość"
                            />
                        ))}
                    </ul>
                ) : (
                    <ul className="list-unstyled">
                        {map(sortedBuildings, building => building.plan_polygon && (
                            <PlanEstateBuildingListItem key={building.id} offerId={offer.pk} building={building}
                                                        deletePolygon={setDeleteModalState}
                                                        editAssignment={() => setAssignModalState(building.id)}
                                                        editPolygon={() => apiRef.current && apiRef.current.editStart(building.id)}
                            />
                        ))}
                        {map(group.polygons, polygon => (
                            <PlanEstateBuildingListItem key={toCustomPolygonId(polygon.id)} offerId={offer.pk} disableLink building={{id: polygon.id, name: polygon.name, plan_image: null}}
                                                        deletePolygon={id => setDeleteModalState(toCustomPolygonId(id))}
                                                        editAssignment={() => setAssignModalState(toCustomPolygonId(polygon.id))}
                                                        editPolygon={() => apiRef.current && apiRef.current.editStart(toCustomPolygonId(polygon.id))}
                            />
                        ))}
                    </ul>
                )}

            </ContentBody>
        );
    };

    return (
        <Main>
            <Content>
                <ContentHeader>
                    <ContentHeaderTitle>
                        Plany inwestycji
                    </ContentHeaderTitle>
                </ContentHeader>

               {renderBody()}

                {(props.offer && props.group) && (
                    props.offer.type.pk === OfferType.HOUSE ? (
                        <AssignPropertyModal
                            assignPropertyModalState={assignModalState}
                            setAssignPropertyModalState={setAssignModalState}
                            apiRef={apiRef}
                            properties={sortedProperties}
                            planPolygons={props.group && props.group.polygons as IFloorPlanPolygon[]} // instead of floorPolygons
                            groupId={props.group.id}
                            floorId={undefined}
                        />
                    ) : (
                        <AssignBuildingModal
                            assignBuildingModalState={assignModalState}
                            setAssignBuildingModalState={setAssignModalState}
                            apiRef={apiRef}
                            buildings={sortedBuildings}
                            groupPolygons={props.group && props.group.polygons}
                            groupId={props.group.id}
                        />
                    )
                )}

                <Modal
                    modalState={!!deleteModalState}
                    onModalClose={() => setDeleteModalState(null)}
                    type="window"
                    header={`Czy na pewno chcesz usunąć poligon ${(props.offer && props.offer.type.pk === OfferType.HOUSE) ? "nieruchomości" : "budynku"}?`}
                >
                    <div className="df fjc-center fai-center p-xxl">
                        <Button
                            onClick={deletePolygon}
                            className="pv-md ph-xl mr-lg"
                            color="default"
                            type="button"
                        >
                            Tak
                        </Button>

                        <Button
                            onClick={() => setDeleteModalState(null)}
                            className="pv-md ph-xl"
                            color="default"
                            type="button"
                        >
                            Nie
                        </Button>
                    </div>
                </Modal>
            </Content>
        </Main>
    );
};

export const EstatePlanView = connect(mapStateToProps, mapActionsToProps)(EstatePlanViewC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    const groups = state.plan.vendorGroup.list;
    return {
        offer: state.plan.main.offer,
        group: groups[0] ? groups[0] : null,
        buildings: state.plan.building.list,
        buildingsRequest: state.plan.building.fetchRequest,
        properties: state.plan.property.list,
        propertiesRequest: state.plan.property.fetchRequest,
        listLatestQuery: state.plan.offer.offerPlansList.latestQuery
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        actions: bindActionCreators<Record<keyof IActionsProps["actions"], any>, IActionsProps["actions"]>({
            fetchPlanOffer,
            resetPlanOffer,
            fetchVendorGroupList,
            resetVendorGroupList,
            fetchPlanBuildingList,
            resetPlanBuildingList,
            fetchPlanPropertyListByOffers,
            resetPlanPropertyList,
            // patch
            deletePlanPolygon,
            updatePlanPolygon,
            patchBuildingPlan,
            patchPropertyPlan
        }, dispatch)
    };
}
