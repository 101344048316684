import { FormActionTypes } from "../create_form_action_types";
import { assign } from "../util";
import {Dictionary} from "../../interfaces/structure";
import {RawDataAction} from "../../interfaces/dispatch";


export const reduceFormData = (formTypes: FormActionTypes, initialValue: Dictionary<any>) =>
    (state: Dictionary<any> = initialValue, action: RawDataAction): Dictionary<any> => {
        switch (action.type) {
            case formTypes.clear:
                return initialValue;
            case formTypes.update:
                return assign(state, action.rawData);
            case formTypes.replace:
                return action.rawData;
            default:
                return state;
        }
    };
