import * as React from "react";
import * as classNames from "classnames";
import {map} from "lodash";

import {FormComponent, FormComponentProps} from "./formComponent";
import {Button} from "../button";


type IRadioOptionValue = number | boolean | null;
interface IRadioOption {
    value: IRadioOptionValue;
    label: string;
}

interface IProps extends FormComponentProps<any> {
    className?: string;
    optionClassName?: string;
    radioGroupLabelClassName?: string;
    label: JSX.Element | string;
    options: IRadioOption[];
    onClick?: () => void;
}

export class RadioField extends FormComponent<number, IProps, {}> {
    public static initialValue = 0;

    public static toJSON(name: string, values: any): any {
        return {[name]: values[name] || RadioField.initialValue};
    }

    public static toFormData(name: string, values: any): any {
        let value = values[name];
        if (!RadioField.isEmpty(value)) {
            return {[name]: value};
        }
    }

    public static toFormDataPost(name: string, values: any): any {
        return RadioField.toFormData(name, values);
    }

    public static fromJSON(name: string, values: any): any {
        return {[name]: values[name] || RadioField.initialValue};
    }

    public static fromFormData(name: string, values: any): any {
        return {[name]: !!values[name]};
    }

    public static isEmpty(value: boolean): boolean {
        return !value;
    }

    public constructor(props: IProps) {
        super(props);
    }

    /**
     * Callback
     */

    private onChange = (value: IRadioOptionValue) => {
        const {onFormUpdate} = this.props;
        onFormUpdate && onFormUpdate(this.props.name, value);
    };

    private onLabelClick = () => {
        const firstOption = this.props.options && this.props.options[0];
        if (firstOption) {
            this.onChange(firstOption.value);
        }
    };

    /**
     * Render
     */

    private renderOption = (option: IRadioOption) => {
        const className = classNames("btn-radio df", this.props.optionClassName, {
            "active": this.props.value === option.value
        });

        return (
            <div key={option.label} className={className} onClick={() => this.onChange(option.value)}>
                <Button>{option.label}</Button>
            </div>
        );
    };

    public render() {
        const radioGroupStyle = classNames(this.props.className);
        const radioGroupLabelClassName = classNames(this.props.radioGroupLabelClassName);

        return (
            <div className={radioGroupStyle} onClick={this.props.onClick}>
                {this.props.label && <label onClick={this.onLabelClick} className={radioGroupLabelClassName}>{this.props.label}</label>}

                <div className="df fw-wrap fjc-sb">
                    {map(this.props.options, this.renderOption)}
                </div>
            </div>
        );
    }
}
