import * as React from "react";
import * as classNames from "classnames";

export type LoaderSize = "loader-xs" | "loader-sm";
export type LoaderColor = "loader-dark" | "loader-light";

interface LoaderProps {
    size?: LoaderSize;
    color?: LoaderColor;
    button?: boolean;
}


export const Loader: React.SFC<LoaderProps> = props => {
    const className = classNames("loader", props.size, props.color);

    let loader = (
        <div className="vhc">
            <div className={className}>
                <div className="fading-circle">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );

    if (!props.button) {
        loader = <div className="psr w100 h100">{loader}</div>;
    }

    return loader;
};

Loader.defaultProps = {
    color: "loader-dark"
};
