/**
 * Base text for modals.
 */
const textsBase = {
    removeTitle: "Potwierdzenie usunięcia",
    removeConfirm: "Usuń",
    confirm: "Tak",
    cancel: "Anuluj"
};


/**
 * Text for modals.
 */
export const modalTexts = {
    // Defaults
    defaults: {
        title: "Potwierdzenie",
        body: "Czy na pewno chcesz wykonać akcję?",
        confirm: textsBase.confirm,
        cancel: textsBase.cancel
    },

    // Remove
    remove: {
        title: textsBase.removeTitle,
        body: "Czy na pewno chcesz usunąć element?",
        confirm: textsBase.removeConfirm,
        cancel: textsBase.cancel
    },

    // Survey remove
    surveyRemove: {
        title: textsBase.removeTitle,
        body: "Czy na pewno chcesz usunąć ankietę oczekiwań?",
        confirm: textsBase.removeConfirm,
        cancel: textsBase.cancel
    },

    // Additional buyer
    additionalBuyerRemove: {
        title: textsBase.removeTitle,
        body: "Czy na pewno chcesz usunąć dodatkowego nabywcę?",
        confirm: textsBase.removeConfirm,
        cancel: textsBase.cancel
    },

    // Event remove
    eventRemove: {
        title: textsBase.removeTitle,
        body: "Czy na pewno chcesz usunąć zdarzenie?",
        confirm: textsBase.removeConfirm,
        cancel: textsBase.cancel
    },

    // Close client
    closeClient: {
        title: "Potwierdzenie zamknięcia",
        body: "Czy na pewno chcesz zamknąć klienta?",
        confirm: "Zamknij",
        cancel: textsBase.cancel
    },

    // Client resigns on tender
    tenderResignation: {
        title: "Potwierdzenie rezygnacji",
        body: "Czy na pewno klient zrezygnował z oferty?",
        confirm: textsBase.confirm,
        cancel: textsBase.cancel
    },

     // Delete Client
    deleteClient: {
        title: textsBase.removeTitle,
        body: "Czy jesteś pewien, że chcesz usunąć dane tego klienta. Operacja nieodwracalna.",
        confirm: textsBase.removeConfirm,
        cancel: textsBase.cancel
    },
};
