import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../../shared/ts/interfaces/style";

interface ContentHeaderControlsProps extends StyleProps {}

export const ContentHeaderControlsC: React.SFC<ContentHeaderControlsProps> = props => {
    const className = classnames(props.className, "nowrap");
    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

const ContentHeaderControls = ContentHeaderControlsC;
export default ContentHeaderControls;
