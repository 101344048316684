import {alertText} from "../../../../shared/ts/constants/alert";

// Remind password
export const remindPasswordText = {
    successTitle: alertText.successTitle,
    successMessage: (email: string) => `Za chwilę zostanie wysłana wiadomość e-mail na adres ${email}
     z prośbą o utworzenie nowego hasła. Pamiętaj, że takie wiadomości czasami wpadają do folderu SPAM.`
};

// Change password
export const changePasswordText = {
    successTitle: alertText.successTitle,
    successMessage: "Twoje hasło zostało zmienione."
};

// Edit account
export const editAccountText = {
    successTitle: alertText.successTitle,
    successMessage: "Twoje dane zostały zaktualizowane."
};

// Add event
export const addEventText = {
    successTitle: alertText.successTitle,
    successMessage: "Twoje zdarzenie zostało dodane do kalendarza."
};

// Edit event
export const editEventText = {
    successTitle: alertText.successTitle,
    successMessage: "Zdarzenie zostało zaktualizowane."
};

// Drag event
export const dragEventText = {
    successTitle: alertText.successTitle,
    successMessage: editEventText.successMessage
};

// Delete event
export const deleteEventText = {
    successTitle: alertText.successTitle,
    successMessage: "Zdarzenie zostało usunięte."
};

// Complete event
export const completeEventText = {
    successTitle: alertText.successTitle,
    successMessage: "Twoje zdarzenie zostało oznaczone jako 'zakończone'."
};

// Add additional buyer
export const addAdditionalBuyerText = {
    successTitle: alertText.successTitle,
    successMessage: "Dodano dodatkowego nabywcę."
};

// Edit additional buyer
export const editAdditionalBuyerText = {
    successTitle: alertText.successTitle,
    successMessage: "Zaktualizowano dane dodatkowego nabywcy."
};

// Delete additional buyer
export const deleteAdditionalBuyerText = {
    successTitle: alertText.successTitle,
    successMessage: "Usunięto dodatkowego nabywcę."
};

// Add client
export const addClientText = {
    successTitle: alertText.successTitle,
    successMessage: (name: string): string => `Dodano nowego klienta: ${name}.`,

    errorTitle: alertText.errorTitle,
    errorMessage: "Prosimy o uzupełnienie wszystkich wymaganych pól opisujących nowego klienta."
};

// Accept client
export const acceptClientText = {
    successTitle: alertText.successTitle,
    successMessage: "Zaakceptowano klienta."
};

// Update client's tenders
export const updateTendersText = {
    successTitle: alertText.successTitle,
    successMessage: (name: string): string => `Zaktualizowano oferty klienta ${name}.`
};

// Proffer properties to user
export const profferPropertiesText = {
    successTitle: alertText.successTitle,
    successMessage: (name: string): string => `Zapisano oferty klienta ${name}.`,
    errorTitle: alertText.errorTitle,
    errorMessage: "Wystąpił problem z zapisaniem ofert klienta."
};

// Assign application
export const assignApplicationText = {
    successTitle: alertText.successTitle,
    successMessage: "Zostałeś opiekunem zgłoszenia. Skontaktuj się z klientem!"
};

// Assign all client applications
export const assignAllClientApplicationsText = {
    successTitle: alertText.successTitle,
    successMessage: "Zostałeś opiekunem wszystkich zgłoszeń tego klienta. Skontaktuj się z nim!"
};

// Edit application
export const editApplicationText = {
    successTitle: alertText.successTitle,
    successMessage: `Zaktualizowano dane zgłoszenia`,

    errorTitle: alertText.errorTitle,
    errorMessage: "Prosimy o uzupełnienie wszystkich wymaganych pól opisujących zgłoszenie."
};

// Read all applications
export const readAllApplicationsText = {
    successTitle: alertText.successTitle,
    successMessage: `Zgłoszenia klienta zostały odczytane.`
};

// Forward application
export const forwardApplicationText = {
    successTitle: alertText.successTitle,
    successMessage: `Zgłoszenie zostało przesłane.`
};

// Change application trader
export const changeApplicationTraderText = {
    successTitle: alertText.successTitle,
    successMessage: `Opiekun został zmieniony.`
};

// Edit client
export const editClientText = {
    successTitle: alertText.successTitle,
    successMessage: (name: string): string => `Zaktualizowano dane klienta: ${name}.`,

    errorTitle: alertText.errorTitle,
    errorMessage: "Prosimy o uzupełnienie wszystkich wymaganych pól opisujących klienta."
};

// Close client
export const closeClientText = {
    successTitle: alertText.infoTitle,
    successMessage: "Klient został zamknięty i przeniesiony do grupy 'klienci zamknięci'. Jego dane nie będą już widoczne wśród" +
    " aktualnie obsługiwanych klientów."
};

// Cancel client
export const cancelClientText = {
    successTitle: alertText.infoTitle,
    successMessage: "Klient nie został zaakceptowany i trafi do grupy 'klienci zamknięci'."
};

// Export client list
export const exportClientList = {
    successTitle: alertText.successTitle,
    successMessage: "Lista klientów została wyeksportowana do pliku, który wysłaliśmy na Twój adres e-mail. Prosimy o sprawdzenie skrzynki pocztowej."
};

// Export client's applications list
export const exportApplicationsList = {
    successTitle: alertText.successTitle,
    successMessage: "Lista zgłoszeń została wyeksportowana do pliku, który wysłaliśmy na Twój adres e-mail. Prosimy o sprawdzenie skrzynki pocztowej."
};

// Add survey
export const addSurveyText = {
    successTitle: alertText.successTitle,
    successMessage: "Dodano ankietę oczekiwań."
};

// Edit survey
export const editSurveyText = {
    successTitle: alertText.successTitle,
    successMessage: "Zaktualizowano ankietę oczekiwań."
};

// Delete survey
export const deleteSurveyText = {
    successTitle: alertText.successTitle,
    successMessage: "Usunięto ankietę oczekiwań."
};

// Get API Token
export const getApiTokenText = {
    successTitle: alertText.successTitle,
    successMessage: "Klucz wygenerowany i skopiowany."
};

// Sources
export const addUserSourceText = {
    successTitle: alertText.successTitle,
    successMessage: "Dodano nowe źródło.",
    errorTitle: alertText.errorTitle,
    errorMessage: "Popraw błędy i spróbuj ponownie."
};

// Users add/edit
export const addUserText = {
    successTitle: alertText.successTitle,
    successMessage: "Dodano nowego użytkownika.",
    errorTitle: alertText.errorTitle,
    errorMessage: "Prosimy o uzupełnienie wszystkich wymaganych pól opisujących użytkownika. " +
    "Jeśli błąd wystąpi ponownie, skontaktuj się z naszą pomocą techniczną."
};
export const editUserText = {
    successTitle: alertText.successTitle,
    successMessage: "Zaktualizowano dane użytkownika.",
    errorTitle: alertText.errorTitle,
    errorMessage: "Prosimy o uzupełnienie wszystkich wymaganych pól opisujących użytkownika. " +
    "Jeśli błąd wystąpi ponownie, skontaktuj się z naszą pomocą techniczną."
};

// Offer add/edit
export const editOfferText = {
    successTitle: alertText.successTitle,
    successMessage: "Dane inwestycji zostały zapisane.",
    errorTitle: alertText.errorTitle,
    errorMessage: "Nie udało się zapisać danych inwestycji. Popraw błędy i spróbuj ponownie."
};

// StandardDescription add/edit
export const editStandardDescriptionText = {
    successTitle: alertText.successTitle,
    successMessage: "Informacje o standardzie wykończenia zostały zapisane.",
    errorTitle: alertText.errorTitle,
    errorMessage: "Nie udało się zapisać informacji o standardzie wykończenia inwestycji. Popraw błędy i spróbuj ponownie."
};

// Promotion add/edit
export const editPromotionText = {
    successTitle: alertText.successTitle,
    successMessage: "Dane inwestycji zostały zapisane.",
    errorTitle: alertText.errorTitle,
    errorMessage: "Nie udało się zapisać danych inwestycji. Popraw błędy i spróbuj ponownie."
};

// Property add/edit
export const editPropertyText = {
    successTitle: alertText.successTitle,
    successMessage: "Dane nieruchomości zostały zapisane.",
    errorTitle: alertText.errorTitle,
    errorMessage: "Nie udało się zapisać danych nieruchomości. Popraw błędy i spróbuj ponownie."
};

// Proffer start
export const startProfferText = {
    errorTitle: alertText.errorTitle,
    errorMessage: "Nie udało się zaofertować nieruchomości. Popraw błędy i spróbuj ponownie."
};

// External partners patch
export const patchPartnersText = {
    successTitle: alertText.successTitle,
    successMessage: "Poprawnie zapisano eksportowane inwestycje."
};

// Offer gallery order patch
export const patchOfferGalleryOrderText = {
    successTitle: alertText.successTitle,
    successMessage: "Poprawnie zmieniono kolejność zdjęć"
}

// Application settings
export const applicationSettingsText = {
    successTitle: alertText.successTitle,
    successMessage: "Poprawnie zapisano ustawienia aplikacji."
};


export const addDocumentText = {
    successTitle: alertText.successTitle,
    successMessage: (): string => `Dodano nowy dokument.`
};
export const signDocumentText = {
    successTitle: alertText.successTitle,
    successMessage: (): string => `Wysłano dokument do podpisu.`
};
export const deleteDocumentText = {
    successTitle: alertText.successTitle,
    successMessage: (): string => `Usunięto dokument.`
};

export const getDocumentStatusText = {
    infoTitle: alertText.infoTitle,
    successMessage: (): string => `Wysłano prośbę o sprawdzenie statusu. To możę chwię potrwać - spróbuj odświeżyć stronę za parę minut.`
};
