import * as React from "react";

import TableCell from "../../../shared/table/TableCell";
import {Button} from "../../../shared/button/Button";
import Icon from "../../../shared/icon/Icon";
import TableRow from "../../../shared/table/TableRow";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {RemoveModal} from "../../../shared/modal/RemoveModal";


interface IOwnProps {
    className?: string;
    id: number;
    name: string;
    reel_count: number;
    onDeleteClick: (id: number) => void;
    onClick: (id: number) => void;
}
interface IProps extends IOwnProps {
    hoc: HocModalix;
}

interface IState {}

@modalix(RemoveModal)
class ReelsCreatorListItemC extends React.Component<IProps, IState> {

    private onDeleteClick = (e: React.MouseEvent, reelsCreator: {id: number; name: string}) => {
        e.stopPropagation();
        this.props.hoc.modalix.show<any>({
            modalTitle: "Usunięcie reel",
            modalText: `Czy na pewno chcesz usunąć "${reelsCreator.name} (ID: ${reelsCreator.id})"?`,
            onConfirm: () => {
                this.props.onDeleteClick(reelsCreator.id);
            },
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    public render() {
        const {id, name, reel_count} = this.props;

        return (
            <TableRow className="curp" onClick={() => this.props.onClick(this.props.id)}>
                <TableCell className="vam">
                    {id}
                </TableCell>
                <TableCell className="vam">
                    {name}
                </TableCell>

                <TableCell className="vam tac">
                    {reel_count > 0 ? (
                        <span>Nie można usunąć: <br />- reelsy: {reel_count}</span>
                    ) : (
                        <Button color="danger" size="sm" onClick={(e: React.MouseEvent) => this.onDeleteClick(e, {id, name})}><Icon icon="delete" /></Button>
                    )}
                </TableCell>
            </TableRow>
        );
    }
}

export const ReelsCreatorListItem = ReelsCreatorListItemC as React.ComponentClass<IOwnProps>;
