import { saveAs } from "file-saver";

export const saveAsHtml = (elem: HTMLElement, name: string, id: number) =>
    {
        const elemHtml = elem.outerHTML
        const fileName = createFileName(name, id);
        let blob = new Blob([elemHtml], { type: "text/html;charset=utf-8" });
        saveAs(blob, fileName);
    };

const createFileName = (name: string, id: number) => `${name}_${id}.html`;
