import {Action} from "../interfaces/dispatch";


export const FORCE_ERROR_STATE_DISABLE = "error_page/FORCE_ERROR_STATE_DISABLE";
export const FORCE_ERROR_PAGE_ENABLE = "error_page/FORCE_ERROR_PAGE_ENABLE";
export const FORCE_BLANK_PAGE_ENABLE = "error_page/FORCE_BLANK_PAGE_ENABLE";


export interface ErrorPageAction extends Action {
    code: string;
    title: string | null;
    description: string | null;
    showBackButton: boolean;
}


export const disableForcedState = (): Action => ({ type: FORCE_ERROR_STATE_DISABLE });

export const enableErrorPage = (
    code: string = "400",
    title: string | null = null,
    description: string | null = null,
    showBackButton: boolean = true
): ErrorPageAction => ({
    type: FORCE_ERROR_PAGE_ENABLE,
    code,
    title,
    description,
    showBackButton
});

export const enableBlankPage = (): Action => ({ type: FORCE_BLANK_PAGE_ENABLE });
