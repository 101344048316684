import * as React from "react";

interface IProps {
    className?: string;
    width?: number;
    height?: number;
}

export const TagListEmpty = (props: IProps) => {

    const className = props.className ? props.className : "";
    const width = props.width ? props.width : 158;
    const height = props.height ? props.height : 160;

    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            {/*<defs>*/}
            {/*    <circle id="brak-tagow-a" cx="70" cy="70" r="70"/>*/}
            {/*    <polygon id="brak-tagow-b" points=".001 .904 72.329 .904 72.329 128.404 .001 128.404"/>*/}
            {/*</defs>*/}
            <g fill="none" fillRule="evenodd">
                {/*<use fill="#F7F7F7" xlink:href="#brak-tagow-a"/>*/}
                <circle fill="#F7F7F7" id="brak-tagow-a" cx="70" cy="70" r="70"/>
                <g transform="rotate(-55 72.275 21.945)">
                    <g transform="translate(0 .692)">
                        <mask id="brak-tagow-c" fill="#fff">
                            <polygon id="brak-tagow-b" points=".001 .904 72.329 .904 72.329 128.404 .001 128.404"/>
                        </mask>
                        <path fill="#ECECEC"
                              d="M64.6749775,128.404476 L7.65484607,128.404476 C3.42703563,128.404476 0.000539605673,124.975282 0.000539605673,120.747471 L0.000539605673,39.5125355 C0.000539605673,37.5915393 0.723611207,35.7379938 2.02406088,34.3269249 L30.5341266,3.37244552 C33.5667105,0.0808509166 38.7631131,0.0808509166 41.795697,3.37244552 L70.3057627,34.3269249 C71.6062124,35.7379938 72.329284,37.5915393 72.329284,39.5125355 L72.329284,120.747471 C72.329284,124.975282 68.902788,128.404476 64.6749775,128.404476"
                              mask="url(#brak-tagow-c)"/>
                    </g>
                    <path fill="#F7F7F7"
                          d="M46.6251678,31.361522 C46.6251678,37.1406987 41.9413905,41.8217779 36.1649118,41.8217779 C30.3884331,41.8217779 25.7046558,37.1406987 25.7046558,31.361522 C25.7046558,25.5823452 30.3884331,20.901266 36.1649118,20.901266 C41.9413905,20.901266 46.6251678,25.5823452 46.6251678,31.361522"/>
                    <path fill="#ECECEC"
                          d="M55.5596887 63.5244483L16.7701349 63.5244483C14.7358215 63.5244483 13.0873262 61.875953 13.0873262 59.8416396 13.0873262 57.8100242 14.7358215 56.1615289 16.7701349 56.1615289L55.5596887 56.1615289C57.5940021 56.1615289 59.2424974 57.8100242 59.2424974 59.8416396 59.2424974 61.875953 57.5940021 63.5244483 55.5596887 63.5244483M55.5596887 80.294853L16.7701349 80.294853C14.7358215 80.294853 13.0873262 78.6463577 13.0873262 76.6120443 13.0873262 74.5777309 14.7358215 72.9319336 16.7701349 72.9319336L55.5596887 72.9319336C57.5940021 72.9319336 59.2424974 74.5777309 59.2424974 76.6120443 59.2424974 78.6463577 57.5940021 80.294853 55.5596887 80.294853M37.093843 97.0628295L16.7695953 97.0628295C14.7352819 97.0628295 13.0894846 95.4170322 13.0894846 93.3827188 13.0894846 91.3484054 14.7352819 89.6999101 16.7695953 89.6999101L37.093843 89.6999101C39.1281563 89.6999101 40.7766517 91.3484054 40.7766517 93.3827188 40.7766517 95.4170322 39.1281563 97.0628295 37.093843 97.0628295"/>
                </g>
                <g transform="rotate(-45 84.053 23.502)">
                    <path fill="#DDD"
                          d="M68.4891429,135.143714 L8.10628571,135.143714 C3.62914286,135.143714 0.000571428571,131.512286 0.000571428571,127.035143 L0.000571428571,41.0094286 C0.000571428571,38.9751429 0.766285714,37.0122857 2.14342857,35.518 L32.3348571,2.738 C35.5462857,-0.747714286 41.0491429,-0.747714286 44.2605714,2.738 L74.452,35.518 C75.8291429,37.0122857 76.5948571,38.9751429 76.5948571,41.0094286 L76.5948571,127.035143 C76.5948571,131.512286 72.9662857,135.143714 68.4891429,135.143714"
                          transform="translate(0 .733)"/>
                    <path fill="#F7F7F7"
                          d="M49.3748571,32.3777143 C49.3748571,38.4977143 44.4148571,43.4548571 38.2977143,43.4548571 C32.1805714,43.4548571 27.2205714,38.4977143 27.2205714,32.3777143 C27.2205714,26.2577143 32.1805714,21.3005714 38.2977143,21.3005714 C44.4148571,21.3005714 49.3748571,26.2577143 49.3748571,32.3777143"/>
                    <path fill="#ECECEC"
                          d="M58.8362857 66.4374286L17.7591429 66.4374286C15.6048571 66.4374286 13.8591429 64.6917143 13.8591429 62.5374286 13.8591429 60.386 15.6048571 58.6402857 17.7591429 58.6402857L58.8362857 58.6402857C60.9905714 58.6402857 62.7362857 60.386 62.7362857 62.5374286 62.7362857 64.6917143 60.9905714 66.4374286 58.8362857 66.4374286M58.8362857 84.1968571L17.7591429 84.1968571C15.6048571 84.1968571 13.8591429 82.4511429 13.8591429 80.2968571 13.8591429 78.1425714 15.6048571 76.3997143 17.7591429 76.3997143L58.8362857 76.3997143C60.9905714 76.3997143 62.7362857 78.1425714 62.7362857 80.2968571 62.7362857 82.4511429 60.9905714 84.1968571 58.8362857 84.1968571M39.2814286 101.953714L17.7585714 101.953714C15.6042857 101.953714 13.8614286 100.210857 13.8614286 98.0565714 13.8614286 95.9022857 15.6042857 94.1565714 17.7585714 94.1565714L39.2814286 94.1565714C41.4357143 94.1565714 43.1814286 95.9022857 43.1814286 98.0565714 43.1814286 100.210857 41.4357143 101.953714 39.2814286 101.953714"/>
                </g>
                <path stroke="#979797" strokeWidth="2"
                      d="M33.6418735,46.8716104 C33.7225987,55.4735569 40.4586318,58.5229177 42.7431044,58.5147043 C45.0275769,58.5062895 47.5847768,57.3592729 49.439453,55.4158849 C52.0563619,52.6738056 53.7431044,48.3628136 53.7431044,43.5146036 C53.7431044,41.8262354 53.5385446,40.2030184 53.1613779,38.6885246 C51.6879107,32.7718919 47.5801,28.5146036 42.7431044,28.5146036 C40.5532837,28.5146036 38.5129155,29.3871706 36.7987785,30.8912429"
                      transform="rotate(145 43.692 43.515)"/>
            </g>
        </svg>
    );
};
