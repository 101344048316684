import * as React from "react";

import BaseAlert from "./BaseAlert";


interface ErrorAlertProps {
    onCloseClick: () => void;
}


const ErrorAlert: React.SFC<ErrorAlertProps> = props => {
    return (
        <BaseAlert color="danger" icon="delete-rounded" onCloseClick={props.onCloseClick}>{props.children}</BaseAlert>
    );
};

export default ErrorAlert;
