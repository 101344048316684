export interface FormActionTypes {
    clear: string;
    update: string;
    replace: string;
}


export const createFormActionTypes = (name: string) => ({
    clear: `${name}_FORM_CLEAR`,
    update: `${name}_FORM_UPDATE`,
    replace: `${name}_FORM_REPLACE`
});
