import * as React from "react";
import * as ReactAvatarEditor from "react-avatar-editor";
import Icon from "../../shared/icon/Icon";
import {Button} from "../../shared/button/Button";

interface IState {
    image: string;
    allowZoomOut: boolean;
    position: { x: number, y: number };
    scale: any;
    rotate: number;
    borderRadius: number;
    preview: any;
    width: any;
    height: any;
}

interface IProps {
    initialImage: string;
    name: string;
    onUpdate: (image: string) => void;
    width: number;
    height: number;
    onReject: () => void;
}

export class ImageEditor extends React.Component<IProps, IState> {

    public constructor(props: IProps) {
        super(props);
        this.state = {
            image: props.initialImage,
            allowZoomOut: false,
            position: {x: 0.5, y: 0.5},
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: props.width,
            height: props.height
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState, prevContext: any): void {
        if (prevState.preview !== this.state.preview) {
            this.props.onUpdate(this.state.preview.img);
        }
    }

    private editor: any;

    private handleSave = () => {
        const img = this.editor.getImageScaledToCanvas().toDataURL("image/jpeg");
        const rect = this.editor.getCroppingRect();

        this.setState(() => ({
            preview: {
                img,
                rect,
                scale: this.state.scale,
                width: this.state.width,
                height: this.state.height
            }
        }));
    };

    private handleScale = (e: any) => {
        const scale = parseFloat(e.target.value);
        this.setState({scale});
    };

    private handlePositionChange = (position: any) => {
        this.setState({position});
    };

    private setEditorRef = (editor: any) => {
        if (editor) {
            this.editor = editor;
        }
    };

    public render() {
        const AvatarEditor: any = ReactAvatarEditor;
        return (
            <div className="df fd-col fai-center bgc-white">
                <div className="bgc-black-o80 w100 df fd-col fai-center" style={{paddingTop: "50px", paddingBottom: "50px"}}>
                    <AvatarEditor
                        ref={this.setEditorRef}
                        scale={parseFloat(this.state.scale)}
                        width={this.state.width}
                        height={this.state.height}
                        position={this.state.position}
                        onPositionChange={this.handlePositionChange}
                        rotate={0}
                        borderRadius={0}
                        image={this.state.image}
                        className="editor-canvas"
                        crossOrigin="anonymous"
                    />
                </div>

                <div className="df fd-row fai-center fjc-center w100 pv-xxxl psr">
                    <div className="df fd-row fai-center w40">
                       <Icon icon="photo" className="fs13 mr-xl" />

                        <input
                            name="scale"
                            type="range"
                            onChange={this.handleScale}
                            min={this.state.allowZoomOut ? "0.1" : "1"}
                            max="2"
                            step="0.01"
                            defaultValue="1"
                        />

                        <Icon icon="photo" className="fs18 ml-xl" />
                    </div>


                    <div className="psa vc r20">
                        <Button color="default" onClick={this.props.onReject} className="mr-xl">Anuluj</Button>

                        <Button color="primary" onClick={this.handleSave}>Zapisz</Button>
                    </div>
                </div>
            </div>
        );
    }
}
