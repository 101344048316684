import * as React from "react";
import {includes} from "lodash";

import {Panel, PanelBody, PanelFooter, PanelHeader} from "../../shared/panel/Panel";

import {Button} from "../../shared/button/Button";
import {ElementClasses, Form, FormProps, FormState} from "../../../../shared/ts/components/form";
import {Input} from "../../../../shared/ts/components/forms/input";
import Icon from "../../shared/icon/Icon";
import {RadioField} from "../../../../shared/ts/components/forms/RadioField";
import {File} from "../../../../shared/ts/components/forms/file";
import {offerPriceTypeOptions} from "../helpers/constants";
import {ModalOpener} from "./ModalOpener";
import {Select} from "../../../../shared/ts/components/forms/select";
import {Range} from "../../../../shared/ts/components/forms/range";
import {Data} from "../../shared/cached_data";


interface IProps extends FormProps {
    handlePreview: () => void;
    submitAndGenerate: () => void;
    updateImageAfterCropping: (key: string, image?: string) => void;
    initialImage: string | Blob;
}

interface IState extends FormState {
    show_crop_mobile_image: boolean;
}

export class MobileNativesForm extends Form<IProps, IState> {
    public static elementClasses: ElementClasses = {
        "mobile_name": {
            type: Input,
            label: "Nazwa inwestycji"
        },
        "mobile_address": {
            type: Input,
            label: "Adres"
        },
        "mobile_region": {
            type: Input,
            label: "Region"
        },
        "mobile_offer_price_type": {
            type: RadioField
        },
        "mobile_price_type": {
            type: Select,
            label: "Netto / Brutto"
        },
        "mobile_individual_price_m2": {
            type: Input,
            label: "Cena indywidualna (zł/m2)"
        },
        "mobile_image": {
            type: File
        },
        "mobile_image_native": {
            type: File
        },
        "desktop_vendor_logo": {
            type: File,
        },
        "mobile_construction_end_date": {
            type: Input,
            label: "Termin oddania"
        },
        "mobile_type": {
            type: Select,
            label: "Rodzaj nieruchomości"
        },
        "mobile_properties_count_for_sale": {
            type: Input,
            label: "Liczba nieruchomości"
        },
        "mobile_area": {
            type: Range,
            label: "Powierzchnia"
        },
        "mobile_ranges_rooms": {
            type: Range,
            label: "Liczba pokoi"
        }
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            show_crop_mobile_image: false
        };
    }

    private handleResettingImage = (e: React.MouseEvent, fileFieldName: ("mobile_image")) => {
        e.preventDefault();
        this.triggerCrop(fileFieldName);
        this.props.updateImageAfterCropping(fileFieldName);
    };


    private triggerCrop = (name: ("mobile_image")) => {
        // this is too slightly over complex now, but it used to handle 3 different size images.
        // let's leave it this way for the possible future case of coming back to multi sizes.
        // what it does is always using "mobile_image" as name
        const stateName = `show_crop_${name}` as keyof IState;
        this.setState({[stateName]: true} as Pick<IState, keyof IState>);
        // just needed a trigger
        window.setTimeout(() => this.setState({[stateName]: false} as Pick<IState, keyof IState>), 500);
    };

    private renderFile = (fileFieldName: ("mobile_image"), width: number, height: number) => {
        const isInitialImage = this.props.values[fileFieldName] && includes(this.props.values[fileFieldName].selected, "main");
        const stateName = (this.state as any)[`show_crop_${fileFieldName}`];
        return (
            <div>
                <File {...this.generateProps(fileFieldName)}
                      allowedExtensions={["jpg", "jpeg", "png"]}
                      imgFilePreview
                      triggerCropOnFileChange={this.triggerCrop}
                      previewPath={this.props.values[fileFieldName].base64value}
                      label={`Do reklamy natywnej mobile (${width}x${height})`}
                />

                <div className="psr t-5 mb-xl">
                    {this.props.values[fileFieldName].base64value && (
                        <ModalOpener
                            isInitialImage={isInitialImage}
                            name={fileFieldName}
                            width={width}
                            initialImage={this.props.initialImage}
                            image={this.props.values[fileFieldName]}
                            isOpen={stateName}
                            height={height}
                            updateImageAfterCropping={(image: string) => this.props.updateImageAfterCropping(fileFieldName, image)}
                        />
                    )}

                    {this.props.initialImage && (
                        <Button size="sm" color="primary-ghost" onClick={(e) => this.handleResettingImage(e, fileFieldName)}>
                            Pobierz ponownie zdjęcie
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    public render(): JSX.Element {
        const offerTypesOptions = Data.offer_types.getOptions().map((i: any) => ({...i, value: i.value.toString()}));
        return (
            <form>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df df-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="mobile" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Reklama natywna Mobile
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <h3 className="fs16 fwb">Informacje o inwestycji</h3>

                                <p className="mb-xxxl w66 c-gray">
                                    Wybierając interesującą Cię inwestycje dane zostaną automatycznie załadowane bazując na aktualnych informacjach w ofercie. Możesz je edytować do indywidualnych potrzeb prowadzonej kampanii
                                </p>

                                <div className="row mb-xl">
                                    <div className="col-xs-12 col-sm-4">
                                        <Input {...this.generateProps("mobile_name")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-4">
                                        <Input {...this.generateProps("mobile_address")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-4">
                                        <Input {...this.generateProps("mobile_region")} />
                                    </div>
                               </div>

                                <div className="bdbw-sm bdbs-solid bdbc-black-o10 mb-xxxl" />

                                <h3 className="fs16 mb-xl fwb">Bullety informacyjne</h3>

                                <div className="row mb-xl">
                                    <div className="col-xs-12 col-sm-6">
                                        <Input {...this.generateProps("mobile_construction_end_date")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-3">
                                        <Select
                                            {...this.generateProps("mobile_type")}
                                            options={offerTypesOptions}
                                        />
                                    </div>

                                    <div className="col-xs-12 col-sm-3">
                                        <Input {...this.generateProps("mobile_properties_count_for_sale")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                        <Range {...this.generateProps("mobile_area")} type="number"/>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                        <Range {...this.generateProps("mobile_ranges_rooms")} type="number"/>
                                    </div>
                                </div>

                                <div className="bdbw-sm bdbs-solid bdbc-black-o10 mb-xxxl" />

                                <div className="row">
                                    <div className="col-xs-6">
                                        <h3 className="fs14 fwb mt-0">Ceny inwestycji</h3>

                                        <RadioField
                                            {...this.generateProps("mobile_offer_price_type")}
                                            options={offerPriceTypeOptions}
                                            className="offer-price-type-list mb-xl"
                                            optionClassName="w30 break-word"
                                        />
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <Input {...this.generateProps("mobile_individual_price_m2")} disabled={this.props.values["mobile_offer_price_type"] !== 2}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-6">
                                        <h3 className="fs14 fwb mt-0">Zdjęcie inwestycji</h3>

                                        {this.renderFile("mobile_image", 1125, 633)}
                                    </div>
                                </div>
                            </PanelBody>

                            <PanelFooter className="tar pv-xl">
                                <Button color="primary-ghost" size="lg" className="mr-xl" onClick={this.props.handlePreview}>Podgląd Mobile</Button>

                                <Button color="success" size="lg" className="mr-xl" onClick={this.props.onSubmit}>Zapisz Mobile</Button>

                                <Button color="primary" size="lg" onClick={this.props.submitAndGenerate}>Zapisz i Wygeneruj Mobile</Button>
                            </PanelFooter>
                        </Panel>
                    </div>
                </div>
            </form>
        );
    }
}

