import {Dictionary, each, isEmpty, isPlainObject, isArray} from "lodash";


export type TDataQuery<T> = {
    [P in keyof T]?: TDataQuery<T[P]> | boolean;
};

export const appendIncludeParams = <T>(pathname: string, dataQuery: TDataQuery<T>): string => {
    if (isEmpty(dataQuery)) {
        return pathname;
    }

    const includes: string[] = [];
    const addParams = (query: Dictionary<any>, parentKey?: string) => {
        const prefix = parentKey ? `${parentKey}.` : "";

        if (parentKey && isEmpty(query)) { // set default when keys are not defined
            return includes.push(`i[]=${prefix}*`);
        }

        // keys are defined - append them to array
        each(query, (value: boolean | Dictionary<any>, key: string) => {
            const currentKey = prefix + key;
            // handle array
            const actualValue = isArray(value) && value[0] ? value[0] : value;
            // handle object
            if (isPlainObject(actualValue)) {
                // we have deep object
                return addParams(actualValue as Dictionary<any>, currentKey);
            }
            // handle single value
            else if (actualValue) {
                // we have boolean value - add when truthly
                return includes.push(`i[]=${currentKey}`);
            }
        });
    };

    addParams(dataQuery); // fills `includes` array
    return `${pathname}?${includes.join("&")}`;
};
