import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as _ from "lodash";
import {fetch, HocFetch} from "../../../../shared/ts/decorators/fetch";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {PAGE_PARAM, SORT_PARAM} from "../../../../shared/ts/constants/queryParams";
import {sort, HocSort} from "../../../../shared/ts/decorators/sort";
import {pagination, HocPagination} from "../../../../shared/ts/decorators/pagination";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";
import {PanelBody, Panel} from "../../shared/panel/Panel";
import {Pagination} from "../../shared/pagination/Pagination";
import Main from "../../shared/layout/Main";
import {Button} from "../../shared/button/Button";
import {url} from "../../../../shared/ts/helpers/routing";
import {TabsContent} from "../../../../shared/ts/components/tabs/TabsContent";
import Icon from "../../shared/icon/Icon";
import CenterBox from "../../shared/layout/CenterBox";
import Loader from "../../shared/loader/Loader";
import {Store} from "../../project/reducer";
import ContextPanel from "../../context_panel/components/ContextPanel";
import ContextPanelBody from "../../context_panel/components/ContextPanelBody";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentBody from "../../project/components/content/ContentBody";
import Content from "../../project/components/content/Content";
import {openTab, openPanel, closePanel} from "../../context_panel/actions/context_panel";
import {campaignListContextPanelActions} from "../../context_panel/reducers/context_panel";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../project/components/content/ContentHeaderControls";
import {nativesFetch, invalidateAdvertsList} from "../actions/natives/fetch";
import {NativeList} from "../components/NativeList";
import {NativeListFilterForm} from "../components/context_panel/NativeListFilterForm";
import {NativeListContextPanelHeader} from "../components/context_panel/NativeListContextPanelHeader";
import {nativesDelete} from "../actions/natives/delete";
import {INativeAd, INativesFilterForm} from "../reducers/natives";
import {FormActions} from "../../../../shared/ts/helpers/create_form_actions";
import {filterNativeListFormActions} from "../actions/natives/filter_natives_list";
import {RouterProps} from "../../../../shared/ts/interfaces/router";
import {CreationType} from "../../creation/CreationType";


const defaultPage = 1;
const defaultSort = "-create_date";


interface IStateProps {
    natives: INativeAd[];
    pagination: PaginationState;
    request: RequestState;
    errors: AppError | null;
    contextPanelOpened: boolean;
    openedTabIndex: number;
    listFilterForm: INativesFilterForm;
}

interface IActionsProps {
    listFilterFormActions: FormActions;
    actions: {
        invalidateAdvertsList: typeof invalidateAdvertsList;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
        nativesDelete: typeof nativesDelete;
    };
}

interface IOwnProps {
    hoc: HocFetch & HocPagination & HocSort;
    onItemClick: (id: number) => void;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {
    additionalQuery: Record<string, string>;
    addNewItemUrl: string;
}

@pagination(PAGE_PARAM)
@sort(SORT_PARAM)
@fetch((state: Store) => state.adverts.natives.fetch, fetchActions, validateQuery)
export class ListViewC extends React.Component<IProps, {}> {

    /**
     * Lifecycle
     */

    public componentWillUnmount() {
        this.props.actions.invalidateAdvertsList();
    }

    /**
     * Callback
     */

    private onFilterSubmit = (values: Dictionary<any>) => {
        this.props.hoc.fetch.reset(values);
    };

    private onFilterClear = () => {
        this.props.listFilterFormActions.clear();
        this.props.hoc.fetch.reset({});
    };

    private onSortChange = (column: string): void => {
        this.props.hoc.sort.toggle(column, this.props.hoc.pagination.get());
    };

    private onContextPanelTabOpen = (tabId: number): void => {
        this.props.actions.openTab(campaignListContextPanelActions.openTab, tabId);
        this.props.actions.openPanel(campaignListContextPanelActions.openPanel);
    };

    private onContextPanelToggle = (): void => {
        if (this.props.contextPanelOpened) {
            this.props.actions.closePanel(campaignListContextPanelActions.closePanel);
        } else {
            this.props.actions.openPanel(campaignListContextPanelActions.openPanel);
        }
    };

    /**
     * Render
     */

    private renderEmptyList(): JSX.Element {
        return (
            <ContentBody className="df fg-1">
                <CenterBox>
                    <Icon icon="empty-list" className="empty-list-icon" />
                    <div className="tac fs30">Nic tu nie ma, zmień parametry szukania</div>
                </CenterBox>
            </ContentBody>
        );
    }

    private renderList(loading: boolean): JSX.Element {
        const {hoc, natives, pagination} = this.props;
        return (
            <ContentBody>
                <Panel color="default" className="psr mb-xl">
                    <PanelBody>
                        <NativeList
                            natives={natives}
                            onSort={this.onSortChange}
                            sortDirection={hoc.sort.getDirection()}
                            sortName={hoc.sort.getName()}
                            loading={loading}
                            onDeleteClick={this.props.actions.nativesDelete}
                            onItemClick={this.props.onItemClick}
                        />
                    </PanelBody>
                </Panel>

                <div className="pagination-holder tac pss b-0">
                    <Pagination
                        totalCount={pagination.total}
                        pageSize={pagination.pageSize}
                        activePage={hoc.pagination.currentPage()}
                        onPageChange={hoc.pagination.set}
                    />
                </div>
            </ContentBody>
        );
    }

    private renderLoader(): JSX.Element {
        return (
            <CenterBox className="vhc">
                <Loader size="lg" />
            </CenterBox>
        );
    }

    private renderError(): JSX.Element {
        return (
            <CenterBox>
                <Icon icon="empty-list" className="empty-list-icon" />
                <div className="error-message tac fs30">Popraw błędne parametry szukania</div>
            </CenterBox>
        );
    }

    private renderContent(): JSX.Element {
        const {request, natives} = this.props;

        if (request === RequestState.Success && _.isEmpty(natives)) {
            return this.renderEmptyList();
        }

        if (request === RequestState.Success || (request === RequestState.Waiting && !_.isEmpty(natives))) {
            return this.renderList(request === RequestState.Waiting);
        }

        if (request === RequestState.Error) {
            return this.renderError();
        }

        return this.renderLoader();
    }

    public render(): JSX.Element {
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Lista reklam natywnych</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button color="success" className="ttu" href={url(this.props.addNewItemUrl)} size="sm">
                                Dodaj reklamę
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    {this.renderContent()}
                </Content>

                <ContextPanel opened={this.props.contextPanelOpened}>
                    <NativeListContextPanelHeader
                        opened={this.props.contextPanelOpened}
                        openedTabIndex={this.props.openedTabIndex}
                        onTabOpen={this.onContextPanelTabOpen}
                        onPanelToggle={this.onContextPanelToggle}
                    />

                    <ContextPanelBody className="filters-holder">
                        <TabsContent openedTabIndex={this.props.openedTabIndex}>
                            <NativeListFilterForm
                                onSubmit={this.onFilterSubmit}
                                errors={this.props.errors ? this.props.errors.fieldErrors : null}
                                values={this.props.listFilterForm}
                                updateFormAction={this.props.listFilterFormActions.update}
                                clearForm={this.onFilterClear}
                            />
                            {/*<NativeShortcut />*/}
                        </TabsContent>
                    </ContextPanelBody>
                </ContextPanel>
            </Main>
        );
    }
}


/**
 * Connect
 */

export const ConnectedListView = connect(mapStateToProps, mapActionsToProps)(ListViewC);
export const ListView = withRouter(
    (props: RouterProps) => (
        <ConnectedListView
            additionalQuery={{type: [CreationType.DESKTOP.toString(), CreationType.MOBILE.toString(), CreationType.ALL.toString()]}}
            onItemClick={(id: number) => props.router.push(url("app:in:natives:edit", {id}))}
            addNewItemUrl="app:in:natives:add"
        />
    )
);


function mapStateToProps(store: Store): IStateProps {
    return {
        natives: store.adverts.natives.natives,
        pagination: store.adverts.natives.pagination,
        request: store.adverts.natives.fetch.requestState,
        errors: store.adverts.natives.fetch.errors,
        contextPanelOpened: store.adverts.natives.list.contextPanel.opened,
        openedTabIndex: store.adverts.natives.list.contextPanel.openedTabIndex,
        listFilterForm: store.adverts.natives.list.nativesFilterForm
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        listFilterFormActions: bindActionCreators(filterNativeListFormActions, dispatch),
        actions: bindActionCreators({
            invalidateAdvertsList,
            openPanel,
            closePanel,
            openTab,
            nativesDelete
        }, dispatch)
    };
}

/**
 * Fetch
 */
function fetchActions(dispatch: Dispatch, query: Dictionary<string>): void {
    dispatch(nativesFetch(query));
}


function validateQuery(query: Dictionary<string>, props: IProps): Dictionary<string> {
    return {
        [PAGE_PARAM]: query[PAGE_PARAM] || defaultPage.toString(),
        [SORT_PARAM]: query[SORT_PARAM] || defaultSort,
        ...props.additionalQuery
    };
}
