import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    addDesktopNativesFormActions,
    addMobileNativesFormActions,
    clearAddEditViewState,
    nativesAdd
} from "../actions/natives/add";
import {Store} from "../../project/reducer";

import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import Main from "../../shared/layout/Main";
import Content from "../../project/components/content/Content";
import ContentHeader from "../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../project/components/content/ContentHeaderControls";
import {Button} from "../../shared/button/Button";
import ContentBody from "../../project/components/content/ContentBody";
import {TabsContent} from "../../../../shared/ts/components/tabs/TabsContent";
import {NativesTypeTabs} from "../components/tabs/NativesTypeTabs";
import {DesktopNativesForm} from "../components/DesktopNativesForm";
import {MobileNativesForm} from "../components/MobileNativesForm";
import {FormActions} from "../../../../shared/ts/helpers/create_form_actions";
import {INativeAd, INativesForms} from "../reducers/natives";
import {closePanel, openPanel, openTab} from "../../context_panel/actions/context_panel";
import {NativeOffersAutocomplete} from "../components/NativeOffersAutocomplete";
import {advertsConvert, INativeFileNameData} from "../actions/natives/converts_canvas_to_jpg";
import {Offer} from "../../shared/models/Offer";
import {fetchOfferDetailForNatives, resetOfferDetailForNative} from "../../offer/actions/fetch";
import {getDate} from "../../../../shared/ts/helpers/formatData";
import {noop} from "../../../../shared/ts/helpers/lodash";
import {createFileName, urlBase64, urlToCroppedBase64} from "../../../../shared/ts/helpers/urlToCroppedBase64";
import {url} from "../../../../shared/ts/helpers/routing";
import {RouterProps} from "../../../../shared/ts/interfaces/router";
import {prepareRoomRanges} from "../../../../shared/ts/helpers/roomRanges";
import {getPreparedAdsElements, NativeFormType} from "../helpers/constants";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {HocModalix, modalix} from "../../../../shared/ts/decorators/modalix";
import {NativeAdModal} from "../components/ads_templates/NativeAdModal";

interface IStateProps {
    nativesDetail: INativesForms;
    nativesOfferDetail: Offer;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    desktopFormActions: FormActions;
    mobileFormActions: FormActions;
    fetchOfferDetailForNatives: (id: number | string) => Promise<any>;
    actions: {
        nativesAdd: typeof nativesAdd;
        resetOfferDetailForNative: typeof resetOfferDetailForNative;
        clearAddEditViewState: typeof clearAddEditViewState;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
        advertsConvert: typeof advertsConvert;
    };
}

interface IOwnProps extends RouterProps {
    hoc: HocModalix;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    openedFormTab: number;
    initialImage: string | Blob;
}

@modalix(NativeAdModal)
class AddViewC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            openedFormTab: 1,
            initialImage: ""
        };
    }

    public componentDidMount() {
        this.getDefaultFormValues();
    }

     public componentWillUnmount(): void {
        this.props.actions.clearAddEditViewState();
        this.props.actions.resetOfferDetailForNative();
    }

    private getDefaultFormValues = () => {
        const formValues = {
            desktop_offer_price_type: 0,
            mobile_offer_price_type: 0
        };

        this.props.desktopFormActions.update(formValues);
        this.props.mobileFormActions.update(formValues);
    };

    private handleTabs = (openedFormTab: number) => {
        this.setState({openedFormTab});
    };

    private handleConvert = (nativeData: INativeFileNameData, formType: NativeFormType) => {
        this.handleModalOpen(formType);
        this.props.actions.advertsConvert(getPreparedAdsElements(formType), nativeData);

        // do we want to wait?
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    private handleFormSubmit = (formValues: INativeAd, shouldGenerate: boolean) => {
        (this.props.actions.nativesAdd(formValues) as any)
            .then((response: any) => {
                if (response != null) {
                    if (shouldGenerate) {
                        this.handleConvert({name: response.desktop_name || response.mobile_name, id: response.id}, formValues.type);
                    }
                    return response.id;
                }
            }).then((id: number) => {
            if (id != null) {
                const link = url("app:in:natives:edit", {id});

                this.props.router.push(link);
            }
        });
    };

    private onSubmit = (formType: number, shouldGenerate: boolean = false) => {
        let nativeAdValue: INativeAd;
        switch (formType) {
            case NativeFormType.DESKTOP:
                nativeAdValue = {
                    ...DesktopNativesForm.toJSON(this.props.nativesDetail.desktopForm),
                    type: NativeFormType.DESKTOP,
                    offer: this.props.nativesOfferDetail && this.props.nativesOfferDetail.pk
                };

                break;
            case NativeFormType.MOBILE:
                nativeAdValue = {
                    ...MobileNativesForm.toJSON(this.props.nativesDetail.mobileForm),
                    type: NativeFormType.MOBILE,
                    offer: this.props.nativesOfferDetail && this.props.nativesOfferDetail.pk
                };
                break;
            case NativeFormType.ALL:
                nativeAdValue = {
                    ...DesktopNativesForm.toJSON(this.props.nativesDetail.desktopForm),
                    ...MobileNativesForm.toJSON(this.props.nativesDetail.mobileForm),
                    type: NativeFormType.ALL,
                    offer: this.props.nativesOfferDetail && this.props.nativesOfferDetail.pk
                };
                break;
            default:
                return null;
        }
        this.handleFormSubmit(nativeAdValue, shouldGenerate);
    };

    private prepareBase64Images = async (data: Offer) => {
        const initialImage = await urlBase64(data.main_image) as string;
        const desktopImage = await urlToCroppedBase64(data.main_image, 336,  189);
        const mobileImage = await urlToCroppedBase64(data.main_image, 375,  211);
        const vendorLogo = data.vendor.logo && await urlBase64(data.vendor.logo);
        this.setState({initialImage});
        this.props.desktopFormActions.update({
            desktop_image: {
                ...this.props.nativesDetail.desktopForm.desktop_image,
                selected: `main_${createFileName(data.name, data.pk, "desktop")}`,
                base64value: desktopImage
            },
            desktop_vendor_logo: {
                ...this.props.nativesDetail.desktopForm.desktop_vendor_logo,
                selected: `main_${createFileName(data.vendor.name, data.pk)}`,
                base64value: vendorLogo
            }
        });
        this.props.mobileFormActions.update({
            mobile_image: {
                ...this.props.nativesDetail.mobileForm.mobile_image,
                selected: `main_${createFileName(data.name, data.pk, "mobile")}`,
                base64value: mobileImage
            }
        });
    };

    private updateDesktopImages = (image?: string) => {
        const imgOptions = image ?
            {base64value: image} :
            {base64value: this.state.initialImage, selected: createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk)};
        this.props.desktopFormActions.update({
            desktop_image: {
                ...this.props.nativesDetail.desktopForm.desktop_image,
                ...imgOptions
            }
        });
    };
    private updateMobileImages = (key: ("mobile_image"), image?: string) => {
        // similar to mobileForm triggerCrop logic is overly complex for the purpose of (previous and) future possible different size images
        const imgOptions = image ?
            {base64value: image} :
            {base64value: this.state.initialImage, selected: createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk)};
        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.nativesDetail.mobileForm[key],
                ...imgOptions
            }
        });
    };

    private prepareDesktopValues = (data: Offer) => {
        return {
            desktop_area: {
                lower: data.stats.ranges_area_min,
                upper: data.stats.ranges_area_max
            },
            desktop_construction_end_date: getDate(data),
            desktop_distance: data.stats.distance_from_region.distance,
            desktop_distance_region: data.stats.distance_from_region.region,
            desktop_individual_price_m2: "",
            desktop_name: data.name,
            desktop_properties_count_for_sale: data.stats.properties_count_for_sale,
            desktop_ranges_rooms: prepareRoomRanges(data.stats.rooms_ranges),
            desktop_address: data.address,
            desktop_type: {
                value: data.type.pk.toString()
            },
            desktop_price_type: {
                value: data.price_type ? data.price_type : "0"
            },
            desktop_vendor: data.vendor.name,
            type: NativeFormType.DESKTOP
        };
    };

    private prepareMobileValues = (data: Offer) => {
        return {
            mobile_area: {
                lower: data.stats.ranges_area_min,
                upper: data.stats.ranges_area_max
            },
            mobile_construction_end_date: getDate(data),
            mobile_name: data.name,
            mobile_individual_price_m2: "",
            mobile_address: `${data.street_name} ${data.street_number ? data.street_number : ""}`,
            mobile_properties_count_for_sale: data.stats.properties_count_for_sale,
            mobile_ranges_rooms: prepareRoomRanges(data.stats.rooms_ranges),
            mobile_type: {
                value: data.type.pk.toString()
            },
            mobile_region: data.region.short_name,
            type: NativeFormType.MOBILE
        };
    };

    private triggerPopulatingDataToForm = (offer: Offer) => {
        this.prepareBase64Images(offer);

        const desktopFormData = this.prepareDesktopValues(offer);
        this.props.desktopFormActions.update(desktopFormData);

        const mobileFormData = this.prepareMobileValues(offer);
        this.props.mobileFormActions.update(mobileFormData);
    };

    private handleSearchedOfferUpdate = async (data: any) => {
        if (data.offer != null) {
            await this.props.fetchOfferDetailForNatives(data.offer.value);
            this.triggerPopulatingDataToForm(this.props.nativesOfferDetail);
        }
    };

    private handleModalOpen = (formType: NativeFormType) => {
        const minPrice = this.props.nativesOfferDetail && this.props.nativesOfferDetail.stats.ranges_price_m2_min || 0;
        const distanceData = this.props.nativesOfferDetail && this.props.nativesOfferDetail.stats.distance_from_region || null;
        this.props.hoc.modalix.show<any>({
            mobileFormData: this.props.nativesDetail.mobileForm,
            desktopFormData: this.props.nativesDetail.desktopForm,
            distance: distanceData,
            minPrice: minPrice,
            formType,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    public render() {
        const {request} = this.props.nativesDetail;
        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklamy natywne</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.handleModalOpen(NativeFormType.ALL)}
                            >
                                Podgląd Reklam
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(NativeFormType.ALL)}
                            >
                                Zapisz Reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(NativeFormType.ALL, true)}
                            >
                                Zapisz i Wygeneruj Reklamy
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <NativesTypeTabs onTabOpen={this.handleTabs} openedTabIndex={this.state.openedFormTab} errors={this.props.nativesDetail.errors}/>

                        <NativeOffersAutocomplete
                            onSubmit={noop}
                            errors={null}
                            values={this.props.nativesOfferDetail}
                            updateFormAction={this.handleSearchedOfferUpdate}
                        />

                        <TabsContent openedTabIndex={this.state.openedFormTab}>
                            {/*<Notifications formValues={this.props.formValues}/>*/}
                            {this.state.openedFormTab === NativeFormType.DESKTOP && (
                                <DesktopNativesForm
                                    onSubmit={() => this.onSubmit(NativeFormType.DESKTOP, false)}
                                    errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                                    values={this.props.nativesDetail.desktopForm}
                                    updateFormAction={this.props.desktopFormActions.update}
                                    handlePreview={() => this.handleModalOpen(NativeFormType.DESKTOP)}
                                    submitAndGenerate={() => this.onSubmit(NativeFormType.DESKTOP, true)}
                                    updateImageAfterCropping={this.updateDesktopImages}
                                    initialImage={this.state.initialImage}
                                />
                            )}

                            {this.state.openedFormTab === NativeFormType.MOBILE && (
                                <MobileNativesForm
                                    onSubmit={() => this.onSubmit(NativeFormType.MOBILE, false)}
                                    errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                                    values={this.props.nativesDetail.mobileForm}
                                    updateFormAction={this.props.mobileFormActions.update}
                                    handlePreview={() => this.handleModalOpen(NativeFormType.MOBILE)}
                                    submitAndGenerate={() => this.onSubmit(NativeFormType.MOBILE, true)}
                                    updateImageAfterCropping={this.updateMobileImages}
                                    initialImage={this.state.initialImage}
                                />
                            )}
                        </TabsContent>
                    </ContentBody>
                </Content>

                {/*<ContextPanel opened={this.props.contextPanelOpened}>
                    <NativeAddContextPanelHeader
                        opened={this.props.contextPanelOpened}
                        openedFormTab={this.state.openedFormTab}
                        onTabOpen={this.onContextPanelTabOpen}
                        onPanelToggle={this.onContextPanelToggle}
                    />

                    <ContextPanelBody className="filters-holder">
                        <TabsContent openedFormTab={this.state.openedFormTab}>
                            <NativeShortcut />
                        </TabsContent>
                    </ContextPanelBody>
                </ContextPanel>*/}
            </Main>
        );
    }
}

export const AddView = connect(mapStateToProps, mapActionsToProps)(AddViewC);


/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        nativesDetail: store.adverts.natives.detail,
        nativesOfferDetail: store.adverts.natives.nativesOfferDetail,
        contextPanelOpened: store.adverts.natives.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        desktopFormActions: bindActionCreators(addDesktopNativesFormActions, dispatch),
        mobileFormActions: bindActionCreators(addMobileNativesFormActions, dispatch),
        fetchOfferDetailForNatives: fetchOfferDetailForNatives(dispatch),
        actions: bindActionCreators({
            nativesAdd,
            openPanel,
            closePanel,
            openTab,
            clearAddEditViewState,
            resetOfferDetailForNative,
            advertsConvert
        }, dispatch)
    };
}


