import {combineReducers} from "redux";

import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {campaignListContextPanel, ContextPanelState} from "../../context_panel/reducers/context_panel";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {ReelsCreatorListFilterForm} from "../components/context_panel/reelsCreator/ReelsCreatorListFilterForm";
import {reelCreatorRequest} from "../actions/reels_creator/fetch";
import {reelCreatorRequestTypes} from "../actions/reels_creator/add";
import {filterReelCreatorListFormTypes} from "../actions/reels_creator/filter_reel_creator_list";

export interface IReelsCreator {
    pk: number;
    name: string;
    image: string;
    reel_count: number;
}

export interface IReelsCreatorFilterForm {}

export interface ReelsCreatorStore {
    natives: IReelsCreator[];
    pagination: PaginationState;
    fetch: {
        requestState: RequestState;
        errors: AppError | null;
        latestQuery: Dictionary<string>;
    };
    list: {
        contextPanel: ContextPanelState;
        filterForm: IReelsCreatorFilterForm;
    };
}

const reelsCreator = combineReducers({
    natives: reducePaginatedResponse(reelCreatorRequest),
    pagination: reducePagination(reelCreatorRequest),
    fetch: combineReducers({
        requestState: reduceRequestState(reelCreatorRequest),
        errors: reduceRequestErrors(reelCreatorRequest),
        latestQuery: reduceLatestQuery(reelCreatorRequest)
    }),
    list: combineReducers({
        contextPanel: campaignListContextPanel,
        filterForm: reduceFormData(filterReelCreatorListFormTypes, ReelsCreatorListFilterForm.fromJSON({}))
    }),
    detail: reduceReducers(
        combineReducers({
            request: reduceRequestState(reelCreatorRequestTypes),
            errors: reduceRequestErrors(reelCreatorRequestTypes)
        })
    )
});

export default reelsCreator;
