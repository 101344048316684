import * as React from "react";
import {Field} from "redux-form";

import StyleProps from "../../../../../../shared/ts/interfaces/style";
import PhoneField from "./PhoneField";

interface PhoneProps extends StyleProps {
    id?: string;
    type?: string;
    name: string;
    label?: string;
    groupClassName?: string;
    onValueUpdate?: (name: string) => void;
    hideLabel?: boolean;
}

export default class Phone extends React.Component<PhoneProps, {}> {
    public render(): JSX.Element {
        return (
            <Field
                {...this.props}
                component={PhoneField}
            />
        );
    }
}
