import {each, isArray} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {deleteRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {Dispatch, ErrorAction} from "../../../../../shared/ts/interfaces/dispatch";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {invalidateHeroAdvertsList} from "./fetch";
import {nphApiLink} from "../../../project/route/nph_api_link";

export const HERO_DELETE = "hero/DELETE";
export const heroDeleteRequest = createRequestActionTypes(HERO_DELETE);

export const heroDelete = (uuid: number) => (dispatch: Dispatch) => {
    dispatch({type: heroDeleteRequest.start});

    return deleteRequest(nphApiLink.natives.delete({id: uuid}))
        .then(() => {
            dispatch({type: heroDeleteRequest.success});
            dispatch(invalidateHeroAdvertsList());
            dispatch(addSuccessAlert("Usunięto baner"));
            return true;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, HERO_DELETE)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", HERO_DELETE));
            }
            dispatch<ErrorAction>({type: heroDeleteRequest.error, error: responseError.appError});
            return null;
        }));
};
