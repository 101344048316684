import {Dictionary} from "../../../shared/ts/interfaces/structure";


/**
 * Provides debounce and cache functionality for given request action.
 * Cache saves received data by first parameter of `requestAction` as a key.
 */
export const createDebouncedAction = (requestAction: (...args: any[]) => Promise<any>, debounceTime: number, enableCache: boolean = true) => {

    let cache: Dictionary<any> = {};
    let prevTimeout = 0;
    let prevReject: ((error: Error) => void) = () => undefined;

    return (...args: any[]) => {
        if (prevTimeout) {
            clearTimeout(prevTimeout);
            prevTimeout = 0;
            prevReject(new Error("debounce"));
        }

        const cacheKey = args[0].toString();
        if (enableCache && cache[cacheKey]) {
            return Promise.resolve(cache[cacheKey]);
        }

        return new Promise<any>((resolve, reject) => {
            prevReject = reject;
            prevTimeout = window.setTimeout(() => {
                prevTimeout = 0;
                requestAction(...args).then((res: any) => {
                    if (enableCache) {
                        cache[cacheKey] = res;
                    }
                    resolve(res);
                });
            }, debounceTime);
        });
    };
};
