import * as React from "react";
import {Button} from "../../../shared/button/Button";
import Icon from "../../../shared/icon/Icon";
import {PlanType} from "../../views/OfferPlanView";

interface IProps {
    onBackClick: () => void;
    drawStartPolygon: () => void;
    drawStopPolygon: () => void;
    editStopPolygon: () => void;
    offerName: string | JSX.Element;
    buildingName?: string; // for building view
    floorName?: string; // for floor view
    type: PlanType;
    canvasMode: string;
    renderAdditionalHeader: () => JSX.Element;
}

export const PlanItemHeader = (props: IProps) => {

    const drawPolygon = () => {
        props.drawStartPolygon();
    };

    const generateTitle = () => {
        switch (props.type) {
            case PlanType.OFFER:
                return (
                    <div className="fs24 fwb">
                        {props.offerName}
                    </div>
                );
            case PlanType.BUILDING:
                return (
                    <div className="fs24 fwb">
                        <p className="mb-0">
                            Budynek {props.buildingName}
                        </p>

                        <p className="fs16 m-0 fwn c-link">
                            {props.offerName}
                        </p>
                    </div>
                );
            case PlanType.FLOOR:
                return (
                    <div className="fs24 fwb">
                        <p className="mb-0">
                            Piętro {props.floorName}
                        </p>

                        <p className="fs16 m-0 fwn c-link">
                            {props.offerName}
                        </p>
                    </div>
                );
            case PlanType.ESTATE:
                return (
                    <div className="fs24 fwb">
                        <p className="mb-0">
                            Osiedle
                        </p>

                        <p className="fs16 m-0 fwn c-link">
                            {props.offerName}
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };

    const generateButtons = () => {
        switch (props.canvasMode) {
            case "view":
                return (
                    <Button onClick={drawPolygon} type="button" color="default" className="pv-md ph-xl">
                        <Icon icon="pencil" className="mr-md"/>

                        Rysuj poligon
                    </Button>
                );
            case "edit":
                return (
                    <Button onClick={props.editStopPolygon} type="button" color="success" className="pv-md ph-xl">
                        <Icon icon="pencil" className="mr-md"/>

                        Zakończ edycję
                    </Button>
                );
            case "draw":
                return (
                    <Button onClick={props.drawStopPolygon} type="button" color="danger" className="pv-md ph-xl">
                        <Icon icon="pencil" className="mr-md"/>

                        Przerwij rysowanie
                    </Button>
                );
            default:
                return (
                    <Button onClick={drawPolygon} type="button" color="default" className="pv-md ph-xl">
                        <Icon icon="pencil" className="mr-md"/>

                        Rysuj poligon
                    </Button>
                );
        }
    };

    return (
        <div className="df fjc-sb fai-center mb-xxxl">
            <div className="df fai-center">
                <Button onClick={props.onBackClick} color="link" className="p-0 mr-md mt-xs">
                    <Icon icon="arrow-left"/>
                </Button>

                {generateTitle()}
            </div>

            {props.renderAdditionalHeader && props.renderAdditionalHeader()}

            {generateButtons()}
        </div>
    );
};
