import * as React from "react";
import {asyncVendors, fetchVendors} from "../../../vendor/actions/fetch";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {Button} from "../../../shared/button/Button";
import {createForm, FormProps, HocForm} from "../../../shared/form/create_form";
import {FormInput} from "../../../shared/form/fields/input/FormInput";
import Input from "../../../shared/form/fields/input/Input";
import {SelectAsync} from "../../../shared/form/fields/select/Select";
import {FormSelect} from "../../../shared/form/fields/select/FormSelect";


export const offerPlanListFormFields = {
    name: Input,
    vendor: SelectAsync
};

interface IOwnProps extends FormProps {
    request: RequestState;
}
interface IProps extends IOwnProps {
    hoc: HocForm<typeof offerPlanListFormFields>;
}

export const PlanOfferListFilterForm = createForm<IOwnProps>()((props: IProps) => {
    const {fieldProps} = props.hoc.form;
    return (
        <div className="pv-md ph-xl fg-1">
            <form onSubmit={props.onSubmit}>

                <FormInput {...fieldProps("name")} autoFocus type="text" label="Nazwa inwestycji"/>

                <FormSelect {...fieldProps("vendor")} label="Nazwa dewelopera"
                            showAllValues showInputPlaceholder options={[]}
                            loadOptions={asyncVendors} fetchData={fetchVendors}
                            searchable async creatable={false} multi={false}
                />

                <div className="tac">
                    <Button
                        type="submit"
                        color="success"
                        size="lg"
                        submitting={props.request === RequestState.Waiting}
                    >
                        Wyszukaj
                    </Button>


                    <Button color="link" onClick={props.onReset}>
                        wyczyść pola
                    </Button>
                </div>
            </form>
        </div>
    );
});
