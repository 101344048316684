import * as React from "react";
import {withRouter} from "react-router";
import {ConnectedListView} from "../../../advertisments/views/List";
import {RouterProps} from "../../../../../shared/ts/interfaces/router";
import {url} from "../../../../../shared/ts/helpers/routing";
import {CreationType} from "../../CreationType";


export const NativeCreationListView = withRouter(
    (props: RouterProps) => (
        <ConnectedListView
            additionalQuery={{type: CreationType.NATIVE.toString()}}
            onItemClick={(id: number) => props.router.push(url("app:in:creation:native:edit", {id}))}
            addNewItemUrl="app:in:creation:native:add"
        />
    )
);
