import * as React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as _ from "lodash";
import * as hoistStatics from "hoist-non-react-statics";

import {RequestState} from "../helpers/util";
import {createHocExtend, HocExtend, fromQueryToURL} from "./hoc_helpers";
import {RouterProps} from "../helpers/decorators";
import {Dispatch} from "../interfaces/dispatch";
import {RouterState} from "../interfaces/router";
import {Dictionary} from "../interfaces/structure";


const DEBUG = false;

const debugLog = (...args: any[]) => DEBUG ? console.log("FETCH debug: ", ...args) : null;


export interface HocFetch {
    fetch: {
        reset: (...queries: Dictionary<any>[]) => void;
    };
}

interface FetchStateProps {
    _requestState: RequestState;
    _latestQuery: Dictionary<any>;
}
interface FetchProps extends FetchStateProps, RouterState, RouterProps {
    dispatch: Dispatch;
    hoc: any;
}

interface FetchableStore {
    requestState: RequestState;
    latestQuery: Dictionary<any>;
}

/**
 * `fetch` decorator - for watching changes in query string and calling callback function when found
 * @param stateSelector - Store selector that gives object with `requestState` and `latestQuery`
 * @param fetchAction   - callback action called when proper part of query string differs
 * @param validateQuery -
 * @param includes      - array of attributes` keys to be watched for
 * @param excludes      - array of attributes` keys to not to be watched for
 */
export function fetch<T>(
    stateSelector: (store: T) => FetchableStore,
    fetchAction: (dispatch: Dispatch, query: Dictionary<any>, props?: any) => void,
    validateQuery: (query: Dictionary<any>, props: any) => Dictionary<any> = () => ({}),  // is empty object (has no attributes) by default
    includes: string[] | null = null,                                      // null -> include all attributes by default
    excludes: string[] | null = null                                       // null -> exclude no attributes by default
) {
    return (InnerComponent: any): any => {

        @withRouter
        @connect(mapStateToProps)
        class Fetch extends React.Component<FetchProps, {}> {

            public static defaultProps: any = {hoc: {}};

            private hocExtend: HocExtend = createHocExtend();
            private hocFetch: HocFetch = {
                fetch: {
                    reset: this.reset.bind(this)
                }
            };

            /**
             * Lifecycle
             */

            public componentDidMount(): void {
                this.afterRender(this.props);
            }

            public componentDidUpdate(): void {
                this.afterRender(this.props);
            }

            private afterRender(props: FetchProps): void {
                const query = props.location.query;
                const finalQuery = _.assign({}, query, validateQuery(query, props)); // append validated URL parameters
                if (!_.isEqual(query, finalQuery)) {
                    debugLog("replace query: ", query, finalQuery);
                    return props.router!.replace(fromQueryToURL(props.location, finalQuery));
                }
                if (
                    props._requestState === RequestState.None ||                                                     // request state is not valid
                    _.isNull(props._latestQuery) ||                                                                  // latest is not valid
                    !_.isEqual(this.selectAttributes(props._latestQuery), this.selectAttributes(finalQuery)) // URL params differ with the latest one
                ) {
                    debugLog("fetch reason: ",
                        props._requestState === RequestState.None,
                        _.isNull(props._latestQuery),
                        !_.isEqual(this.selectAttributes(props._latestQuery), this.selectAttributes(finalQuery)),
                        " attributes: ", this.selectAttributes(props._latestQuery), this.selectAttributes(finalQuery)
                        );
                    return fetchAction(props.dispatch, finalQuery, props);
                }
            }

            /**
             * Helpers
             */

            private selectAttributes(query: Dictionary<any>): Dictionary<any> {
                const picked = includes === null ? query : _.pick(query, includes);
                return excludes === null ? picked : _.omit(picked, excludes);
            }

            /**
             * HOC API
             */

            private reset(...queries: Dictionary<any>[]): void {
                this.props.router!.push(fromQueryToURL(this.props.location, ...queries));
            }

            /**
             * Render
             */

            public render(): JSX.Element {
                const innerProps = _.omit(this.props, ["_requestState", "_latestQuery"]);
                return <InnerComponent {...innerProps} hoc={this.hocExtend(this.props.hoc, this.hocFetch)}/>;
            }
        }

        function mapStateToProps(state: T): FetchStateProps {
            const selected = stateSelector(state);
            if (_.isUndefined(selected.latestQuery)) {
                console.warn('fetch could not find property "latestQuery"');
            }
            if (_.isUndefined(selected.requestState)) {
                console.warn('fetch could not find property "requestState"');
            }
            return {
                _requestState: selected.requestState,
                _latestQuery: selected.latestQuery
            };
        }

        return hoistStatics(Fetch, InnerComponent);
    };
}
