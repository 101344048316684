import * as React from "react";
import {Tooltip as BaseTooltip} from "react-bootstrap";
import * as classnames from "classnames";
import {Overlay, OverlayPlacement} from "./Overlay";
import StyleProps from "../../../../shared/ts/interfaces/style";

interface TooltipProps extends StyleProps {
    id: string;
    content: React.ReactNode;
    placement?: OverlayPlacement;
}

const Tooltip: React.SFC<TooltipProps> = props => {
    const className = classnames(props.className);

    const overlay = (
        <BaseTooltip id={props.id} className={className}>{props.content}</BaseTooltip>
    );

    return (
        <Overlay overlay={overlay} placement={props.placement}>
            {props.children}
        </Overlay>
    );
};

export default Tooltip;
