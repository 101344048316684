import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import Main from "../../../shared/layout/Main";
import Content from "../../../project/components/content/Content";
import ContentHeader from "../../../project/components/content/ContentHeader";
import ContentHeaderTitle from "../../../project/components/content/ContentHeaderTitle";
import ContentHeaderControls from "../../../project/components/content/ContentHeaderControls";
import {Button} from "../../../shared/button/Button";
import ContentBody from "../../../project/components/content/ContentBody";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {closePanel, openPanel, openTab} from "../../../context_panel/actions/context_panel";
import {RouterState} from "../../../../../shared/ts/interfaces/router";
import {createFileName, urlBase64, urlToCroppedBase64} from "../../../../../shared/ts/helpers/urlToCroppedBase64";
import {RequestState} from "../../../../../shared/ts/helpers/util";
import {HocModalix, modalix} from "../../../../../shared/ts/decorators/modalix";
import {Offer} from "../../../shared/models/Offer";
import {fetchOffer, fetchOfferDetailForNatives, resetOfferDetailForNative} from "../../../offer/actions/fetch";
import {surpriseEditPatch} from "../../../advertisments/actions/surprise/edit";
import {saveAsHtml} from "../../native/actions/save_as_html";
import {htmlToImgDownload} from "../../native/actions/html_to_img";
import {ISurpriseAdCreation, SurpriseAdCreationForm} from "../components/SurpriseAdCreationForm";
import {SurpriseAdCreationAdModal} from "../components/SurpriseAdCreationAdModal";
import {CreationType} from "../../CreationType";
import {ISurpriseForms} from "../../../advertisments/reducers/surprise";
import {surpriseFetchDetail} from "../../../advertisments/actions/surprise/fetch";
import {
    addSurpriseFormActions,
    clearSurpriseAddEditErrors,
    clearSurpriseAddEditViewState
} from "../../../advertisments/actions/surprise/add";

interface IStateProps {
    nativesDetail: ISurpriseForms;
    nativesOfferDetail: Offer | null;
    contextPanelOpened: boolean;
}

interface IActionsProps {
    mobileFormActions: FormActions;
    fetchOfferDetailForNatives: (id: number | string) => Promise<any>;
    actions: {
        fetchOffer: typeof fetchOffer;
        surpriseEditPatch: typeof surpriseEditPatch;
        surpriseFetchDetail: typeof surpriseFetchDetail;
        clearSurpriseAddEditErrors: typeof clearSurpriseAddEditErrors;
        clearSurpriseAddEditViewState: typeof  clearSurpriseAddEditViewState;
        resetOfferDetailForNative: typeof resetOfferDetailForNative;
        openPanel: typeof openPanel;
        closePanel: typeof closePanel;
        openTab: typeof openTab;
    };
}

interface IOwnProps extends RouterState {
    hoc: HocModalix;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {}

interface IState {
    initialLogo?: string | Blob;
    initialImage: string | Blob; // base64 representation of offer's main image
}

@modalix(SurpriseAdCreationAdModal)
class SurpriseAdCreationEditViewC extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {
            initialImage: "",
            initialLogo: ""
        };
    }

    /**
     * Lifecycle
     */

    public async componentDidMount() {
        const creationId = parseInt(this.props.params["id"], 10);
        const creationDetail: ISurpriseAdCreation = await this.props.actions.surpriseFetchDetail(creationId) as any; // ANY: bind action typing problem
        this.triggerPopulatingDataToForm(creationDetail);
    }

    public componentWillUnmount(): void {
        this.props.actions.clearSurpriseAddEditViewState();
        this.props.actions.resetOfferDetailForNative();
    }

    private triggerPopulatingDataToForm = (native: ISurpriseAdCreation) => {
        const rawFormValues = SurpriseAdCreationForm.fromJSON(native);
        this.props.mobileFormActions.update(rawFormValues);
        this.prepareBase64Images(native); // at the very end, because it is async anyway
    };

    // This is hack over `ImageAdCreationForm.fromJSON(native)` and overwrites previous `mobileFormActions.update` because is async.
    // Unfortunately we upload base64 blob every time we click save, even though we use PATCH (and should update only changes).
    // That enables crop button for every file URL.
    private prepareBase64Images = async (data: ISurpriseAdCreation) => {
        const initialImage = this.props.nativesOfferDetail && this.props.nativesOfferDetail.main_image && await urlBase64(this.props.nativesOfferDetail.main_image) as string;

        const image = data.image && await urlToCroppedBase64(data.image, 375,  211);
        const logo = data.logo && await urlToCroppedBase64(data.image, 80,  60);

        this.setState({initialImage} as IState);
        const hasMobileValues = image;
        hasMobileValues && this.props.mobileFormActions.update({
            image: {
                ...this.props.nativesDetail.form.image,
                selected: `main_${createFileName(data.name, data.id, "mobile")}`,
                base64value: image
            },
            logo: {
                ...this.props.nativesDetail.form.logo,
                selected: `main_${createFileName(data.logo, data.id, "mobile")}`,
                base64value: logo
            }
        });
    };

    /**
     * Submit form
     */

    private onSubmit = (shouldGenerate: boolean = false, toHtml: boolean = false) => {
        const creation: ISurpriseAdCreation = {
            ...SurpriseAdCreationForm.toJSON(this.props.nativesDetail.form),
            type: CreationType.IMAGE
        };
        this.handleFormSubmit(creation, shouldGenerate, toHtml);
    };

    private handleFormSubmit = (creation: ISurpriseAdCreation, shouldGenerate: boolean, toHtml: boolean) => {
        const creationId = parseInt(this.props.params["id"], 10);

        (this.props.actions.surpriseEditPatch(creationId, creation as any) as any) // ANY: actions are based on old creation types
            .then((response: ISurpriseAdCreation) => {
                if (response != null) {
                    if (shouldGenerate) {
                        if (toHtml) {
                            this.handleSaveHtml(response.name, response.id);
                        } else {
                            this.handleConvert(response.name, response.id);
                        }
                    }
                    this.props.actions.clearSurpriseAddEditErrors();
                }
            });
    };

    private handleConvert = (name: string, id: number) => {
        this.openPreviewModal();
        htmlToImgDownload(document.getElementById("v2-ad")!, name, id);
        // do we want to wait?
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    private handleSaveHtml = (name: string, id: number) => {
        this.openPreviewModal();
        const element = document.getElementById("v2-ad")!;
        saveAsHtml(element, name, id);
        window.setTimeout(() => {
            this.props.hoc.modalix.hide();
        }, 3000);
    };

    /**
     * Callback
     */

    private openPreviewModal = () => {
        this.props.hoc.modalix.show<any>({
            imageAdCreationFormData: this.props.nativesDetail.form,
            onReject: () => this.props.hoc.modalix.hide()
        });
    };

    private onImageCropped = (key: ("image"), image?: string) => {
        // similar to mobileForm triggerCrop logic is overly complex for the purpose of (previous and) future possible different size images
        const imgOptions = image ?
            {base64value: image} :
            {base64value: this.state.initialImage, selected: this.props.nativesOfferDetail ? createFileName(this.props.nativesOfferDetail.name, this.props.nativesOfferDetail.pk) : "initial_image"};
        this.props.mobileFormActions.update({
            [key]: {
                ...this.props.nativesDetail.form[key],
                ...imgOptions
            }
        });
    };

    /**
     * Render
     */

    public render() {
        const {request} = this.props.nativesDetail;

        return (
            <Main>
                <Content>
                    <ContentHeader>
                        <ContentHeaderTitle>Reklamy natywne</ContentHeaderTitle>

                        <ContentHeaderControls>
                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="primary-ghost"
                                size="sm"
                                onClick={() => this.openPreviewModal()}
                            >
                                Podgląd Reklam
                            </Button>

                            <Button
                                className="ttu ph-xxl mr-xl"
                                type="submit"
                                color="success"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit()}
                            >
                                Zapisz Reklamy
                            </Button>

                            <Button
                                className="ttu ph-xxl"
                                type="submit"
                                color="primary"
                                size="sm"
                                submitting={request === RequestState.Waiting}
                                onClick={() => this.onSubmit(true)}
                            >
                                Zapisz i Wygeneruj Reklamy
                            </Button>
                        </ContentHeaderControls>
                    </ContentHeader>

                    <ContentBody>
                        <SurpriseAdCreationForm
                            onSubmit={() => this.onSubmit(false, false)}
                            errors={this.props.nativesDetail.errors ? this.props.nativesDetail.errors.fieldErrors : null}
                            values={this.props.nativesDetail.form}
                            updateFormAction={this.props.mobileFormActions.update}
                            handlePreview={() => this.openPreviewModal()}
                            submitAndGenerate={() => this.onSubmit(true, false)}
                            submitAndGenerateHtml={() => this.onSubmit(true, true)}
                            updateImageAfterCropping={this.onImageCropped}
                            initialImage={this.state.initialImage}
                        />
                    </ContentBody>
                </Content>
            </Main>
        );
    }
}

export const SurpriseAdCreationEditView = connect(mapStateToProps, mapActionsToProps)(SurpriseAdCreationEditViewC);

/**
 * Connect
 */

function mapStateToProps(store: Store): IStateProps {
    return {
        nativesDetail: store.adverts.surprise.detail,
        nativesOfferDetail: store.adverts.surprise.nativesOfferDetail,
        contextPanelOpened: store.adverts.surprise.list.contextPanel.opened
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        mobileFormActions: bindActionCreators(addSurpriseFormActions, dispatch),
        fetchOfferDetailForNatives: fetchOfferDetailForNatives(dispatch),
        actions: bindActionCreators({
            fetchOffer,
            surpriseEditPatch,
            surpriseFetchDetail,
            openPanel,
            closePanel,
            openTab,
            clearSurpriseAddEditViewState,
            clearSurpriseAddEditErrors,
            resetOfferDetailForNative,
        }, dispatch)
    };
}
