import * as _ from "lodash";

import {generateQueryString} from "../../../../shared/ts/helpers/routing";
import {authorizationHeaderData, getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {nphApiLink} from "../../project/route/nph_api_link";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {appendIncludeParams, TDataQuery} from "../../actions/query_interfaces/append_include_params";
import {regionQuery} from "../../actions/query_interfaces/region_query";
import {IPaginatedResponse} from "../../actions/query_interfaces/response_interfaces";
import {Property} from "../../property/models/Property";
import {defaultOffersTypeOptions} from "../constants/offers_typ";

const fetchOptions = (): RequestInit => {
    return {
        method: "GET",
        headers: _.assign(authorizationHeaderData.get(), {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }) as any
    };
};

/**
 * REEL CREATOR
 */
export interface ReelCreator {
    pk: number;
    name: string;
    image: string;
}

export function getReelsCreators(name: string, limit = 20): Promise<{options: {label: string; value: ReelCreator["pk"]; image: ReelCreator["image"]}[]}> {
    return fetch(nphApiLink.reels.creator.list() + generateQueryString({name, page_size: limit}), fetchOptions())
        .then((res: Response) => res.json())
        .then((json: any) => {
            let reelsCreators: ReelCreator[] = json.results;
            return {
                options: reelsCreators.map((reelCreator: ReelCreator) => {
                    return {
                        label: reelCreator.name,
                        value: reelCreator.pk,
                        image: reelCreator.image
                    };
                })
            };
        });
}

export function getReelCreator(id?: number): Promise<any> {
    if (!id) {
        return Promise.resolve({
            label: "",
            value: ""
        });
    }

    return getRequest(nphApiLink.reels.creator.view({creatorId: id}))
        .then((reelCreator: ReelCreator) => {
            return reelCreator && {
                label: reelCreator.name,
                value: reelCreator.pk,
                image: reelCreator.image
            };
        })
        .catch(catch404(responseError => {
            return {
                label: id,
                value: id
            };
        }));
}

/**
 * OFFERS
 */

export interface Offer {
    pk: number;
    name: string;
    type: number;
    vendor_obj: Vendor;
    address: string;
    main_image: string;
    site_url: string;
}

export function getOffers(name: string, limit = 20, type = defaultOffersTypeOptions): Promise<any> {
    return fetch(nphApiLink.offers.list() + generateQueryString({name, page_size: limit, type}), fetchOptions())
        .then((res: Response) => res.json())
        .then((json: any) => {
            let offers: Offer[] = json.results;
            return {
                options: offers.map((offer: Offer) => {
                    return {
                        label: offer.name,
                        value: offer.pk,
                        pk: offer.pk,
                        name: offer.name,
                        type: offer.type,
                        address: offer.address,
                        vendor_obj: offer.vendor_obj,
                        main_image: offer.main_image
                    };
                })
            };
        });
}

export function getOffer(id: number): Promise<any> {
    return getRequest(nphApiLink.offers.detail({offerId: id}))
        // .then((res: Response) => res.json())
        .then((offer: Offer) => {
            return offer && {
                label: offer.name,
                value: offer.pk,
                pk: offer.pk,
                name: offer.name,
                type: offer.type,
                address: offer.address,
                vendor_obj: offer.vendor_obj,
                main_image: offer.main_image
            };

        })
        .catch(catch404(responseError => {
            return {
                label: id,
                value: id
            };
        }));
}

/**
 * Property
 */
export interface PropertyOption {
    label: string;
    value: number;
    pk: number;
    name: string;
}

export const getProperties = (offerId: number) => (propertyNumber: string, limit = 20, for_sale = "True"): Promise<{ options: PropertyOption[] }> => {
    return getRequest(nphApiLink.properties.list() + generateQueryString({number: propertyNumber, page_size: limit, for_sale, offer: offerId}))
        .then((json: any) => {
            let properties: Property[] = json.results;
            return {
                options: properties.map((property: Property) => {
                    return {
                        label: property.number,
                        value: property.pk,
                        pk: property.pk,
                        name: property.number,
                        area: property.area,
                        rooms: property.rooms,
                        floor: property.floor,
                        plan: property.plan,
                        plan_image: property.plan_image
                    };
                })
            };
        });
};

export function getProperty(id: number): Promise<PropertyOption | { label: string; value: string }> {
    return getRequest(nphApiLink.properties.detail({propertyId: id}))
        .then((property: Property) => {
            return property && {
                label: property.number,
                value: property.pk,
                pk: property.pk,
                name: property.number,
                area: property.area,
                rooms: property.rooms,
                floor: property.floor,
                plan: property.plan,
                plan_image: property.plan_image
            };

        })
        .catch(catch404(responseError => {
            return {
                label: id.toString(),
                value: id.toString()
            };
        }));
}

/**
 * VENDOR
 */
export interface Vendor {
    pk: number;
    name: string;
}

/**
 * region
 */
interface ISelectRegion {
    pk: number;
    short_name: string;
}
const selectRegionParams: TDataQuery<typeof regionQuery> = {
    id: true,
    short_name: true
};
const selectRegionListApiLink = appendIncludeParams<typeof regionQuery>(nphApiLink.regions(), selectRegionParams);
export function getRegions(name: string, limit = 20): Promise<any> {
    return getRequest(appendQueryString(selectRegionListApiLink, {name: name, page_size: limit}))
        .then((res: IPaginatedResponse<ISelectRegion[]>) => {
            const regions: ISelectRegion[] = res.results;
            return {
                options: regions.map((region: ISelectRegion) => {
                    return {
                        label: region.short_name,
                        value: region.pk
                    };
                })
            };
        });
}

export function getRegion(regionId?: number): Promise<any> {
    if (!regionId) {
        return Promise.resolve({
            label: "",
            value: ""
        });
    }

    const link = appendIncludeParams<typeof regionQuery>(nphApiLink.regionsView({id: regionId}), selectRegionParams);
    return getRequest(link)
        .then((res: ISelectRegion) => {
            return res && {
                label: res.short_name,
                value: res.pk
            };
        })
        .catch(catch404(responseError => {
            return {
                label: regionId.toString(),
                value: regionId.toString()
            };
        }));
}

