import {nphApiLink} from "../../project/route/nph_api_link";
import {patchRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch} from "redux";


const PATCH_BUILDING_PLAN = "building/patch";
export const patchBuildingPlanTypes = createRequestActionTypes(PATCH_BUILDING_PLAN);

export const patchBuildingPlan = (buildingId: number, data: {}) => async (dispatch: Dispatch) => {
    dispatch({type: patchBuildingPlanTypes.start});
    const url = nphApiLink.buildings.detail({buildingId});
    return patchRequest(url, data)
        .then((res) => {
            dispatch({type: patchBuildingPlanTypes.success, result: res});
            return res.results;
        });
};
