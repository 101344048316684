import {FormActionTypes} from "./create_form_action_types";
import {Dictionary} from "../interfaces/structure";
import {Action, RawDataAction} from "../interfaces/dispatch";


export interface FormActions {
    clear: () => Action;                        // clears entire form
    update: (data: Dictionary<any>) => RawDataAction;      // updates only given fields
    replace: (data: Dictionary<any>) => RawDataAction;     // replaces entire form with given data
}

export const createFormActions = (formTypes: FormActionTypes) => ({
    clear: (): Action => ({ type: formTypes.clear }),
    update: (rawData: Dictionary<any>) => ({ type: formTypes.update, rawData }),
    replace: (rawData: Dictionary<any>) => ({ type: formTypes.replace, rawData })
});
