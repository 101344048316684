import * as React from "react";
import {reduce} from "lodash";
import {Button} from "../../../shared/button/Button";
import {FormSelect} from "../../../shared/form/fields/select/FormSelect";
import {createForm, FormProps, HocForm} from "../../../shared/form/create_form";
import {Select} from "../../../shared/form/fields/select/Select";
import {IPlanBuilding} from "../../actions/fetch_plan_building_actions";
import Input from "../../../shared/form/fields/input/Input";
import Icon from "../../../shared/icon/Icon";
import {FormInput} from "../../../shared/form/fields/input/FormInput";


export const assignBuildingFormFields = {
    building: Select,
    name: Input,
    custom: Select
};

interface IOwnProps extends FormProps {
    onClose: () => void;
    buildings: IPlanBuilding[];
    initialBuildingId: number;
}

interface IProps extends IOwnProps {
    hoc: HocForm<typeof assignBuildingFormFields>;
}

export const AssignBuildingForm = createForm<IOwnProps>()((props: IProps) => {

    const {fieldProps} = props.hoc.form;

    const generateBuildingOptions = reduce(props.buildings, (acc, building) => {
        if (building.plan_polygon && props.initialBuildingId !== building.id) {
            // do not include buildings related to other polygons
            // but explicitly add (do not exclude) building with `initialbuildingId` ID
            return acc;
        }
        return [...acc, {
            value: building.id.toString(),
            label: building.name == null ? "Nieokreślony" : `Budynek ${building.name}`
        }];
    }, []);


    const renderForm = () => {
        if (!props.values.custom) {
            return generateBuildingOptions.length > 0 ? (
                <FormSelect
                    {...fieldProps("building")}
                    label="Wybierz budynek"
                    showAllValues
                    options={generateBuildingOptions}
                    async={false}
                    creatable
                    multi={false}
                />
            ) : (
                <p className="c-danger">
                    Brak budynków do przypisania
                </p>
            );
        } else {
            return (
                <>
                    <Button color="link" size="sm" className="p-0 mb-lg"
                            onClick={() => fieldProps("custom").onChange("custom", false)}>
                        <Icon icon="arrow-left" className="mr-sm"/>
                        Wróć
                    </Button>

                    <div>
                        <FormInput
                            {...fieldProps("name")}
                            autoFocus
                            type="text"
                            label="Nazwa budynku"
                        />
                    </div>
                </>
            );
        }
    };

    return (
        <form className="p-xxxl " onSubmit={props.onSubmit} style={{minWidth: "400px"}}>
            <div className="mb-xxxl select-floor-form psr">
                {renderForm()}
            </div>

            {!props.values.custom && (
                <Button
                    onClick={() => fieldProps("custom").onChange("custom", true)}
                    color="link"
                    type="button"
                    className="p-0 mb-xl c-info tdn-h"
                >
                    <Icon icon="pencil" className="mr-md"/>
                    Dodaj ręcznie piętra
                </Button>
            )}

            <div className="df fjc-end">
                <Button type="button" color="default" size="lg" className="mr-lg" onClick={props.onClose}>
                    Anuluj
                </Button>

                <Button color="success" type="submit" size="lg" disabled={props.values.custom ? !props.values.name : !props.values.building}>
                    Zapisz
                </Button>
            </div>
        </form>
    );
});
