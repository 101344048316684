import {fabric} from "fabric";
import {Canvas, ICircleOptions, IEvent, Line, Point, Polygon} from "fabric/fabric-impl";
import {find, each, map} from "lodash";
import {IFabricPolygon, IFullCircle} from "./init_fabric";


interface IDrawEvents {
    onFinish: (resPoints: IFabricPolygon) => void;
}

export const initDraw = (canvas: Canvas, events: IDrawEvents): () => void => {

    const pointArray: IFullCircle[] = [];     // array that contains every rendered circle
    const lineArray: Line[] = [];             // array that contains every rendered line
    let activeLine: Line | null = null;     // line that is dynamic on mouse move (last object in `lineArray`)
    let activeShape: Polygon | null = null; // shape that is dynamic on mouse move

    canvas.on("mouse:down", onMouseDown);
    canvas.on("mouse:move", onMouseMove);

    /**
     * Mouse events
     */

    function onMouseDown(event: IEvent) {
        const point = find(pointArray, p => event.target && (event.target as IFullCircle).id === p.id) as IFullCircle | undefined;
        if (point == null) { // click outside of `pointArray` points
            return addPoint(event);
        }
        if (point.id === pointArray[0].id) { // click on the first point - close polygon
            return handleLastPoint();
        }
    }

    function onMouseMove(event: IEvent) {
        if (activeLine == null || activeShape == null) {
            return;
        }
        const {x, y} = canvas.getPointer(event.e);
        // update line render
        activeLine.set({ x2: x, y2: y });
        // update shape render - replace last polygon's point
        const points = activeShape.get("points") as Point[];
        points[pointArray.length] = {x, y} as Point; // NOTE: we update element one after current points array
        activeShape.set({points});
        // render
        canvas.renderAll();
    }

    /**
     * Drawing logic
     */

    function addPoint(event: IEvent) {
        const {x, y} = canvas.getPointer(event.e);

        // render line
        const line = new fabric.Line([x, y, x, y], getLineOptions());
        activeLine = line;
        lineArray.push(line);
        canvas.add(line);

        // render point (circle)
        const circle = new fabric.Circle(getCircleOptions(x, y, pointArray.length === 0)) as IFullCircle;
        pointArray.push(circle);
        canvas.add(circle);

        // render shape
        if (activeShape) { // update active shape
            const points = activeShape.get("points") as Point[];
            points.push({x, y} as Point);
            activeShape.set({points: points});
        }
        else { // create active shape
            const polygon = new fabric.Polygon([{x, y}], getPolygonOptions());
            activeShape = polygon;
            canvas.add(polygon);
        }
    }

    function handleLastPoint() {
        if (activeLine == null || activeShape == null) {
            return;
        }
        const {left: x, top: y} = pointArray[0];
        // update first point
        pointArray[0].set(getCircleOptions(x as number, y as number, false)).bringToFront();
        // update last line
        activeLine.set({x2: x, y2: y});
        // update shape
        const points = activeShape.get("points") as Point[];
        activeShape.set({points: points.slice(0, -1)}); // NOTE: we remove one added element from `mouse:move` event
        // finish drawing
        events.onFinish(finishDrawing());
    }

    function finishDrawing(): IFabricPolygon {
        // remove events
        canvas.off("mouse:down", onMouseDown);
        canvas.off("mouse:move", onMouseMove);
        // clear canvas
        activeLine && canvas.remove(activeLine);
        activeShape && canvas.remove(activeShape);
        each(pointArray, point => canvas.remove(point));
        each(lineArray, line => canvas.remove(line));
        // pass current state
        return map(pointArray, p => ({x: p.left!, y: p.top!}));
    }

    return finishDrawing;
};

/**
 * Helpers
 */
export function getCircleOptions(left: number, top: number, isFirst: boolean) {
    return {
        radius: 5,
        fill: isFirst ? "#0c0" : "#006",
        stroke: "#009",
        strokeWidth: 0.5,
        left,
        top,
        id: getRandomId(),
        evented: isFirst,   // enable click to close polygon
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        objectCaching: false
    } as ICircleOptions;
}
export function getLineOptions() {
    return {
        strokeWidth: 2,
        stroke: "#00f",
        evented: false,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        objectCaching: false
    };
}
export function getPolygonOptions() {
    return {
        stroke: "#0c0",
        strokeWidth: 1,
        fill: "#00f",
        opacity: 0.3,
        evented: false,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        objectCaching: false
    };
}
function getRandomId() {
    const min = 99;
    const max = 999999;
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    return new Date().getTime() + random;
}
