import * as React from "react";
import * as qs from "qs";

import {IMobileNativesFormValues} from "../../../advertisments/reducers/natives";
import {creationPreviewLinks} from "../../creation_preview_path";

interface IProps {
    monthlyCreationFormData: IMobileNativesFormValues;
}

interface IMonthlyCreationPreviewData {
    mobile_address: string;
    mobile_region: string;
    mobile_vendor: string;
    mobile_name: string;
    mobile_image: {
        m_img_375x484: string;
    } | null;
}

export function MonthlyCreationAdPreview(props: IProps) {
    const data: IMonthlyCreationPreviewData = {
        ...props.monthlyCreationFormData,
        mobile_image: {
            m_img_375x484: props.monthlyCreationFormData.mobile_image.stored
        }
    };

    const query = qs.stringify(data);

    const previewUrl = `${creationPreviewLinks.monthlyCreation()}?${query}`;

    return <iframe frameBorder={0} src={previewUrl} width="100%" height="600px"></iframe>
}
