import * as React from "react";
import * as qs from "qs";

import {CreationType} from "../../CreationType";
import {creationPreviewLinks} from "../../creation_preview_path";
import {ITdaFormValues} from "../../../advertisments/reducers/tda";

interface IProps {
    tdaAdCreationData: ITdaFormValues;
}

interface ITdaAdCreationData {
    id: number;
    url: string;
    background_color: string;
    desktop_image:  {
        d_img_1100x220: string;
        d_img_2200x440: string;
        d_img_3300x660: string;
    }
    mobile_image: {
        m_img_375x484: string;
        m_img_750x968: string;
        m_img_1125x1452: string;
    };
    type: CreationType.TDA;
}

export function TdaAdCreationAdPreview(props: IProps) {
    const data: ITdaAdCreationData = {
        ...props.tdaAdCreationData,
        id: 123,
        url: "#",
        type: CreationType.TDA,
        background_color: props.tdaAdCreationData.background_color,
        desktop_image: {
            d_img_1100x220: props.tdaAdCreationData.desktop_image.stored,
            d_img_2200x440: props.tdaAdCreationData.desktop_image.stored,
            d_img_3300x660: props.tdaAdCreationData.desktop_image.stored
        },
        mobile_image: {
            m_img_375x484: props.tdaAdCreationData.mobile_image.stored,
            m_img_750x968: props.tdaAdCreationData.mobile_image.stored,
            m_img_1125x1452: props.tdaAdCreationData.mobile_image.stored
        }
    }

    const query = qs.stringify(data)
    const previewUrl = `${creationPreviewLinks.tdaAdCreation()}?${query}`;

    return <iframe frameBorder={0} src={previewUrl} width="100%" height="600px"></iframe>;
}
