import * as React from "react";
import * as classnames from "classnames";
import {withRouter} from "react-router";
import {RouterProps} from "../../../../shared/ts/interfaces/router";
import {Button} from "../../shared/button/Button";


export interface NotFoundViewProps extends RouterProps {
    className?: string;
}

@withRouter
export default class NotFound extends React.Component<NotFoundViewProps, {}> {

    constructor(props: NotFoundViewProps) {
        super(props);
        this.onBackClick = this.onBackClick.bind(this);
    }

    private onBackClick(e: React.MouseEvent): void {
        this.props.router!.goBack();
    }

    public render(): JSX.Element {

        const className = classnames(`${this.props.className} vh100 df fai-center fjc-center`);

        return (
            <div className={className}>
                <div className="fg-1 fs-1 p-xxxl holder">
                    <div className="image"></div>
                </div>

                <div className="holder db">
                    <h2 className="fs80 c-black">Ops!</h2>

                    <p className="c-black fs20 mb-xl">
                        Przepraszam, nie możemy wyświetlić tej strony. Możliwe, że zmienił się jej adres lub została usunięta. Skorzystaj z menu głównego lub wyszukiwarki by znaleźć informacje, których szukasz.
                    </p>

                    <Button color="primary" onClick={this.onBackClick}>
                        wróć do poprzedniej strony
                    </Button>
                </div>
            </div>
        );

    }
}
