import * as React from "react";
import {Field, WrappedFieldProps} from "redux-form";
import {isFunction} from "lodash";

import StyleProps from "../../../../../../shared/ts/interfaces/style";
import {Store} from "../../../../project/reducer";
import FieldError from "../../FieldError";
import Input from "../input/Input";
import * as classnames from "classnames";
import {Data} from "../../../cached_data";
import FieldLabel from "../../FieldLabel";
import SelectField from "../select/SelectField";


interface PhoneFieldProps extends StyleProps, WrappedFieldProps<Store> {
    name: string;
    label?: string;
    allowedExtensions?: string[];
    maxSize?: string;
    error?: string;
    groupClassName?: string;
    onValueUpdate?: (name: string) => void;
}

export default class FileField extends React.Component<PhoneFieldProps, {}> {
    constructor(props: PhoneFieldProps) {
        super(props);
    }

    private onValueUpdate = () => {
        const {input} = this.props;
        if (isFunction(this.props.onValueUpdate)) {
            this.props.onValueUpdate(input.name);
        }
    };

    public render(): JSX.Element {
        const {error, input, label, className, groupClassName} = this.props;
        const holderClassName = classnames(groupClassName, error && "has-error");

        const field = (
            <div className={`${holderClassName} df fg-1 fs-1 fb-0 fai-end`}>
                <div className="phone-prefix-holder fg-0 fs-0">
                    <Field {...this.props} component={SelectField} name={`${input.name}__code`} className="phone-prefix"
                           onValueUpdate={this.onValueUpdate}
                           options={Data.country_codes.getOptions()}/>
                </div>

                <div className="phone-number-holder fg-1 fs-1 fb-0">
                    <Input
                        name={`${input.name}__number`}
                        id={`${input.name}__number`}
                        className={`${className} phone-number`}
                        type="number"
                        onValueUpdate={this.onValueUpdate}
                    />
                </div>
            </div>
        );

        return (
            <div>
                {label && <FieldLabel label={label} htmlFor={`${input.name}__number`} />}
                {error ? <FieldError error={error}>{field}</FieldError> : field}
            </div>
        );
    }
}
