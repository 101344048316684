import {catch400, catch403, catch404} from "../../../../shared/ts/helpers/errors";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {Middleware} from "../../../../shared/ts/decorators/middleware";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, Action, ErrorAction} from "../../../../shared/ts/interfaces/dispatch";
import {logOut} from "../../auth/actions/logout";
import {nphApiLink} from "../route/nph_api_link";


const ACCOUNT_INFO_PREFIX = "account_info/fetch";
export const fetchAccountInfoTypes = createRequestActionTypes(ACCOUNT_INFO_PREFIX);


export interface MyAccountResponse {
    email: string;
    name: string;
    pk: number;
}


export const fetchAccountInfo = (): Middleware => (dispatch: Dispatch) => {
    dispatch<Action>({type: fetchAccountInfoTypes.start});

    return getRequest(nphApiLink.users.me())
        .then((json: MyAccountResponse) => {
            dispatch({type: fetchAccountInfoTypes.success, result: json});
            return json;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: fetchAccountInfoTypes.error, error: responseError.appError});
        }))
        .catch(catch403(err => {
            dispatch(logOut());
        }))
        .catch(catch404(responseErrpr => {
            dispatch<Action>({type: fetchAccountInfoTypes.error});
        }));
};
