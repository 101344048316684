import * as React from "react";
import {includes} from "lodash";
import {Panel, PanelBody, PanelFooter, PanelHeader} from "../../shared/panel/Panel";

import {Button} from "../../shared/button/Button";
import {ElementClasses, Form, FormProps, FormState} from "../../../../shared/ts/components/form";
import {Input} from "../../../../shared/ts/components/forms/input";
import {Range} from "../../../../shared/ts/components/forms/range";
import {Select} from "../../../../shared/ts/components/forms/select";
import {Data} from "../../shared/cached_data";
import Icon from "../../shared/icon/Icon";
import {RadioField} from "../../../../shared/ts/components/forms/RadioField";
import {File} from "../../../../shared/ts/components/forms/file";
import {offerPriceTypeOptions} from "../helpers/constants";
import {ModalOpener} from "./ModalOpener";
import {OfferPriceTypeOptions} from "../../../../shared/ts/constants/OfferType";


interface IProps extends FormProps {
    handlePreview: () => void;
    submitAndGenerate: () => void;
    updateImageAfterCropping: (image?: string) => void;
    initialImage: string | Blob;
}

interface IState extends FormState {
    show_crop_desktop_image: boolean;
}

export class DesktopNativesForm extends Form<IProps, IState> {
    public static elementClasses: ElementClasses = {
        "desktop_name": {
            type: Input,
            label: "Nazwa inwestycji"
        },
        "desktop_vendor": {
            type: Input,
            label: "Deweloper"
        },
        "desktop_address": {
            type: Input,
            label: "Adres"
        },
        "desktop_distance": {
            type: Input,
            label: "Odległość od (km)"
        },
        "desktop_distance_region": {
            type: Input,
            label: "Odległość od (miasto)"
        },
        "desktop_offer_price_type": {
            type: RadioField
        },
        "desktop_construction_end_date": {
            type: Input,
            label: "Termin oddania"
        },
        "desktop_type": {
            type: Select,
            label: "Rodzaj nieruchomości"
        },
        "desktop_price_type": {
            type: Select,
            label: "Netto / Brutto"
        },
        "desktop_properties_count_for_sale": {
            type: Input,
            label: "Liczba nieruchomości"
        },
        "desktop_area": {
            type: Range,
            label: "Powierzchnia"
        },
        "desktop_ranges_rooms": {
            type: Range,
            label: "Liczba pokoi"
        },
        "desktop_individual_price_m2": {
            type: Input,
            label: "Cena indywidualna (zł/m2)"
        },
        "desktop_image": {
            type: File,
            label: "Zdjęcie"
        },
         "desktop_vendor_logo": {
            type: File
        }
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            show_crop_desktop_image: false
        };
    }

    private handleResettingImage = (e: React.MouseEvent) => {
        e.preventDefault();
        this.triggerCrop();
        this.props.updateImageAfterCropping();
    };

    private triggerCrop = () => {
        this.setState({show_crop_desktop_image: true});
        // just needed a trigger
        window.setTimeout(() => this.setState({show_crop_desktop_image: false}), 500);
    };

    public render(): JSX.Element {
        const isInitialImage = this.props.values["desktop_image"] && includes(this.props.values["desktop_image"].selected, "main");
        const offerTypesOptions = Data.offer_types.getOptions().map((i: any) => ({...i, value: i.value.toString()}));
        return (
            <form>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df df-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="screen" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Reklama natywna Desktop
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <h3 className="fs16 fwb">Informacje o inwestycji</h3>

                                <p className="mb-xxxl w66 c-gray">
                                    Wybierając interesującą Cię inwestycje dane zostaną automatycznie załadowane bazując na aktualnych informacjach w ofercie. Możesz je edytować do indywidualnych potrzeb prowadzonej kampanii
                                </p>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <Input {...this.generateProps("desktop_name")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <Input {...this.generateProps("desktop_address")} />
                                    </div>
                                </div>

                                <div className="row mb-xl">
                                    <div className="col-xs-12 col-sm-4">
                                        <Input {...this.generateProps("desktop_vendor")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-4">
                                        <File {...this.generateProps("desktop_vendor_logo")}
                                              allowedExtensions={["jpg", "jpeg", "png"]}
                                              imgFilePreview
                                              previewPath={this.props.values["desktop_vendor_logo"].base64value}
                                              groupClassName="mb-0"
                                              label="Logo dewelopera (proporcja 4:3)"
                                        />
                                    </div>

                                    <div className="col-xs-12 col-sm-4">
                                        <div className="row">
                                            <div className="col-xs-5">
                                                <Input {...this.generateProps("desktop_distance")} type={"number"} />
                                            </div>

                                            <div className="col-xs-7">
                                                <Input {...this.generateProps("desktop_distance_region")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bdbw-sm bdbs-solid bdbc-black-o10 mb-xxxl" />

                                <h3 className="fs16 mb-xl fwb">Bullety informacyjne</h3>

                                <div className="row mb-xl">
                                    <div className="col-xs-12 col-sm-6">
                                        <Input {...this.generateProps("desktop_construction_end_date")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-3">
                                        <Select
                                            {...this.generateProps("desktop_type")}
                                            options={offerTypesOptions}
                                        />
                                    </div>

                                    <div className="col-xs-12 col-sm-3">
                                        <Input {...this.generateProps("desktop_properties_count_for_sale")} />
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                        <Range {...this.generateProps("desktop_area")} type="number"/>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                        <Range {...this.generateProps("desktop_ranges_rooms")} type="number"/>
                                    </div>
                                </div>

                                <div className="bdbw-sm bdbs-solid bdbc-black-o10 mb-xxxl" />

                                <div className="row">
                                    <div className="col-xs-6">
                                        <h3 className="fs14 fwb mt-0">Ceny inwestycji</h3>

                                        <RadioField
                                            {...this.generateProps("desktop_offer_price_type")}
                                            options={offerPriceTypeOptions}
                                            className="offer-price-type-list mb-xl"
                                            optionClassName="w30 break-word"
                                        />

                                        <div className="row">
                                            <div className="col-xs-6">
                                                <Input {...this.generateProps("desktop_individual_price_m2")}
                                                       disabled={this.props.values["desktop_offer_price_type"] !== 2}/>
                                            </div>

                                            <div className="col-xs-6">
                                                <Select
                                                    {...this.generateProps("desktop_price_type")}
                                                    options={OfferPriceTypeOptions}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-6">
                                        <File {...this.generateProps("desktop_image")}
                                              allowedExtensions={["jpg", "jpeg", "png"]}
                                              imgFilePreview
                                              triggerCropOnFileChange={this.triggerCrop}
                                              previewPath={this.props.values["desktop_image"].base64value}
                                              label="Zdjęcie inwestycji"
                                        />

                                        <div className="psr t-5 mb-xl">
                                            {this.props.values["desktop_image"].base64value && (
                                                <ModalOpener
                                                    height={189}
                                                    image={this.props.values["desktop_image"]}
                                                    initialImage={this.props.initialImage}
                                                    isInitialImage={isInitialImage}
                                                    isOpen={this.state.show_crop_desktop_image}
                                                    name={"desktop_image"}
                                                    updateImageAfterCropping={(image: string) => this.props.updateImageAfterCropping(image)}
                                                    width={336}
                                                />
                                            )}

                                            {this.props.initialImage && (
                                                <Button size="sm" color="primary-ghost"
                                                        onClick={this.handleResettingImage}
                                                >
                                                    Pobierz ponownie zdjęcie
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>

                            <PanelFooter className="tar pv-xl">
                                <Button color="primary-ghost" size="lg" className="mr-xl" onClick={this.props.handlePreview}>Podgląd Desktop</Button>

                                <Button color="success" size="lg" className="mr-xl" onClick={this.props.onSubmit}>Zapisz Desktop</Button>

                                <Button color="primary" size="lg" onClick={this.props.submitAndGenerate}>Zapisz i Wygeneruj Desktop</Button>
                            </PanelFooter>
                        </Panel>
                    </div>
                </div>
            </form>
        );
    }
}

