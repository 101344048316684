import * as React from "react";
import Icon from "./Icon";
import StyleProps from "../../../../shared/ts/interfaces/style";

export type DirectionArrow = "up" | "down";

interface DirectionArrowsProps extends StyleProps {
    active?: DirectionArrow;
}

const DirectionArrows: React.SFC<DirectionArrowsProps> = props => {
    const arrowUpStyle = props.active === "up" ? "icon-caret-up-active" : "icon-caret-up";
    const arrowDownStyle = props.active === "down" ? "icon-caret-down-active" : "icon-caret-down";

    return (
        <div className="dib overflow-h lh0 mr-sm">
            <Icon className={arrowUpStyle} icon="caret-up db fs16 psr t5" />
            <Icon className={arrowDownStyle} icon="caret-down db fs16 psr" />
        </div>
    );
};

export default DirectionArrows;
