import { RequestActionTypes } from "../create_request_action_types";
import { AppError } from "../util";
import {ErrorAction} from "../../interfaces/dispatch";


export const reduceRequestErrors = (requestTypes: RequestActionTypes) =>
    (state: AppError | null = null, action: ErrorAction): AppError | null => {
        switch (action.type) {
            case requestTypes.success:
                return null;
            case requestTypes.error:
                return action.error;
            default:
                return state;
        }
    };

export const reduceRequestErrorsWithReset = (requestTypes: RequestActionTypes) =>
    (state: AppError | null = null, action: ErrorAction): AppError | null => {
        switch (action.type) {
            case requestTypes.success:
            case requestTypes.reset:
                return null;
            case requestTypes.error:
                return action.error;
            default:
                return state;
        }
    };
