import {combineReducers} from "redux";
import {map, filter} from "lodash";
import {fetchPlanBuildingTypes, fetchPlanBuildingListTypes, IPlanBuilding} from "../actions/fetch_plan_building_actions";
import {reduceResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_response";
import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {patchBuildingPlanTypes} from "../actions/patch_building_plan";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {fromJson} from "../../shared/form/translate/from_json";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";
import {reduceResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_response_with_reset";
import {assignBuildingFormFields} from "../components/estate/AssignBuildingForm";
import {assignBuildingFormTypes} from "../actions/asssign_building_form";
import {IPlanFloor} from "../actions/fetch_plan_floor_actions";
import {ResponseAction} from "../../../../shared/ts/helpers/interfaces";
import {
    createPlanPolygonTypes,
    deletePlanPolygonTypes, IBuildingPlanPolygon,
    updatePlanPolygonTypes
} from "../actions/plan_polygon_actions";


export interface IPlanBuildingStore {
    list: IPlanBuilding[];
    detail: IPlanBuilding | null;
    assignBuildingFormValues: Record<keyof typeof assignBuildingFormFields, any>;
    fetchRequest: RequestState;
    patchRequest: RequestState;
}

export const planBuildingReducer = combineReducers({
    list: reduceReducers(
        reducePaginatedResponse(fetchPlanBuildingListTypes),
        customBuildingListReducer  // (1) image, add/edit/delete polygon
    ),
    detail: reduceReducers(
        reduceResponseWithReset(fetchPlanBuildingTypes),
        reduceResponse(patchBuildingPlanTypes), // (1) image, add/edit/delete polygon
        customBuildingDetailReducer // update building.polygons
    ),
    assignBuildingFormValues: reduceFormData(assignBuildingFormTypes, fromJson(assignBuildingFormFields, {})),
    fetchRequest: reduceRequestState(fetchPlanBuildingListTypes),
    patchRequest: reduceRequestState(patchBuildingPlanTypes) // (1) image, add/edit/delete polygon
});

/**
 * Custom reducers
 */
function customBuildingListReducer(list: IPlanFloor[], action: ResponseAction<IPlanFloor>): IPlanFloor[] {
    if (action.type === patchBuildingPlanTypes.success) {
        return map(list, building => building.id === action.result.id ? action.result : building);
    }
    return list;
}

function customBuildingDetailReducer(detail: IPlanBuilding | null, action: ResponseAction<IBuildingPlanPolygon>): IPlanBuilding | null {
    if (detail == null) {
        return detail;
    }
    if (action.type === createPlanPolygonTypes.success) {
        return {...detail, polygons: [...detail.polygons, action.result]};
    }
    else if (action.type === updatePlanPolygonTypes.success) {
        return {...detail, polygons: map(detail.polygons, p => p.id === action.result.id ? action.result : p)};
    }
    else if (action.type === deletePlanPolygonTypes.success) {
        return {...detail, polygons: filter(detail.polygons, p => p.id !== (action as any).id)};
    }
    return detail;
}
