import * as React from "react";
import * as _ from "lodash";
import {Tooltip, OverlayTrigger} from "react-bootstrap";
import * as classNames from "classnames";

import {FormComponent, FormComponentProps, FormComponentState} from "./formComponent";
import {Input, InputProps} from "./input";
import {Dict} from "../../helpers/interfaces";
import {assign} from "../../helpers/util";

interface RangeProps extends FormComponentProps<RangeValue> {
    label?: string | JSX.Element;
    type?: string;
    groupClassName?: string;
    disabled?: boolean;
}
interface RangeState extends FormComponentState {}

export interface RangeValue {
    lower?: string;
    upper?: string;
}

enum RangeType {
    Lower,
    Upper
}


export class Range extends FormComponent<RangeValue, RangeProps, RangeState> {

    public static toJSON(name: string, values: any): any {
        let value: RangeValue = {};
        if (values[name] && values[name].lower) {
            value.lower = values[name].lower;
        }
        if (values[name] && values[name].upper) {
            value.upper = values[name].upper;
        }
        return {
            [name]: value
        };
    }

    public static toFormData(name: string, values: any): any {
        let value: Dict = {};
        if (values[name] && values[name].lower) {
            value[name + "_0"] = values[name].lower;
        }
        if (values[name] && values[name].upper) {
            value[name + "_1"] = values[name].upper;
        }
        return value;
    }

    public static toFormDataPost(name: string, values: any): any {
        let value: Dict = {};
        if (values[name] && values[name].lower) {
            value[name + ".lower"] = values[name].lower;
        }
        if (values[name] && values[name].upper) {
            value[name + ".upper"] = values[name].upper;
        }
        return value;
    }

    public static fromJSON(name: string, values: any): any {
        return {
            [name]: {
                lower: values[name] && values[name].lower != null ? values[name].lower.toString() : "",
                upper: values[name] && values[name].upper != null ? values[name].upper.toString() : ""
            }
        };
    }

    public static fromFormData(name: string, values: any): any {
        return {
            [name]: {
                lower: values[name + "_0"] || "",
                upper: values[name + "_1"] || ""
            }
        };
    }

    public static isEmpty(value: RangeValue): boolean {
        return !value || (!value.lower && !value.upper);
    }

    // NOTE: Nie usuwać, metoda wywoływana dynamicznie
    public static renderValue(val: RangeValue): any {
        let valLower = val.lower && `od ${val.lower}`;
        let valUpper = val.upper && `do ${val.upper}`;

        return `${valLower} ${valUpper}`;
    }


    public shouldComponentUpdate(nextProps: RangeProps, nextState: RangeState): boolean {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
    }


    protected generateProps(type: RangeType): InputProps {
        return assign(_.omit(this.props, "label", "error", "markable") as InputProps, {
            ref: type === RangeType.Lower ? "lower" : "upper",
            name: this.props.name + (type === RangeType.Lower ? "_0" : "_1"),
            placeholder: type === RangeType.Lower ? "Od" : "Do",
            value: this.props.value && (type === RangeType.Lower ? this.props.value.lower : this.props.value.upper),
            onBlur: this.onBlur.bind(this),
            onFormUpdate: (name: string, value: string) => this.onChange(type, name, value),
            error: null
        });
    }


    /**
     * Callbacks
     */

    public onChange(type: RangeType, name: string, value: string): void {
        let newValue: RangeValue;
        if (type === RangeType.Lower) {
            newValue = _.assign({}, {
                lower: value,
                upper: this.props.value && this.props.value.upper != null ? this.props.value.upper.toString() : ""
            });
        } else {
            newValue = _.assign({}, {
                lower: this.props.value && this.props.value.lower != null ? this.props.value.lower.toString() : "",
                upper: value
            });
        }
        if (_.isFunction(this.props.onFormUpdate)) {
            this.props.onFormUpdate(this.props.name, newValue);
        }
    }

    public onBlur(e: React.FormEvent): void {
        if (_.isFunction(this.props.onValueChange)) {
            this.props.onValueChange(this.props.name, this.props.value);
        }
    }


    /**
     * Render methods
     */

    public render(): JSX.Element {
        const {name, error, groupClassName} = this.props;
        const isActive = classNames(this.props.markable && !Range.isEmpty(this.props.value) ? "is-active" : "");

        if (error) {
            const tooltip = (<Tooltip className="tooltip-error" id={`tooltip-${name}`}>{error}</Tooltip>);
            return (
                <div className={`row has-error ${isActive}`}>
                    <div className="col-xs-12">
                        <label className="control-label" htmlFor={`${name}_0`}>{this.props.label}</label>
                    </div>
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <div className="col-xs-12">
                            <div className="row">
                                <div className="col-xs-6 psr">
                                    <Input {...this.generateProps(RangeType.Lower)} id={`${name}_0`}
                                        groupClassName={groupClassName}
                                        disabled={!!this.props.disabled} />
                                    <span className="form-control-feedback glyphicon glyphicon-remove r5" />
                                </div>
                                <div className="col-xs-6 psr">
                                    <Input {...this.generateProps(RangeType.Upper)} groupClassName={groupClassName}
                                        disabled={!!this.props.disabled} />
                                    <span className="form-control-feedback glyphicon glyphicon-remove r5" />
                                </div>
                            </div>
                        </div>
                    </OverlayTrigger>
                </div>
            );
        }
        else {
            return (
                <div className={`row ${isActive}`}>
                    <div className="col-xs-12">
                        <label className="control-label" htmlFor={`${name}_0`}>{this.props.label}</label>
                    </div>
                    <div className="col-xs-6">
                        <Input {...this.generateProps(RangeType.Lower)} id={`${name}_0`}
                            groupClassName={groupClassName}
                            disabled={!!this.props.disabled} />
                    </div>
                    <div className="col-xs-6">
                        <Input {...this.generateProps(RangeType.Upper)} groupClassName={groupClassName}
                            disabled={!!this.props.disabled} />
                    </div>
                </div>
            );
        }
    }
}
