import * as React from "react";
import {useEffect} from "react";
import {RefObject} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {map, find, isFinite} from "lodash";
import {AssignFloorForm, assignFloorFormFields} from "./AssignFloorForm";
import {Modal} from "../../../../../shared/ts/modals/Modal";
import {Store} from "../../../project/reducer";
import {Dispatch} from "../../../../../shared/ts/interfaces/dispatch";
import {assignFloorFormActions} from "../../actions/assign_floor_form";
import {patchFloorPlan} from "../../actions/patch_floor_plan";
import {FormActions} from "../../../../../shared/ts/helpers/create_form_actions";
import {BoundAction} from "../../../../../typings/custom_typings/custom_redux";
import {IFabricApi} from "../draw_polygon/init_fabric";
import {IPlanFloor} from "../../actions/fetch_plan_floor_actions";
import {
    createPlanPolygon,
    deletePlanPolygon,
    IBuildingPlanPolygon, IPlanPolygon, PlanPolygonPropertyStatus,
    updatePlanPolygon
} from "../../actions/plan_polygon_actions";
import {fromCustomPolygonId, isCustomPolygonId, toCustomPolygonId} from "../floor/AssignPropertyModal";


interface IStateProps {
    assignFloorFormValues: Record<keyof typeof assignFloorFormFields, any>;
}
interface IActionsProps {
    assignFloorFormActions: FormActions;
    actions: {
        createPlanPolygon: BoundAction<typeof createPlanPolygon>;
        deletePlanPolygon: BoundAction<typeof deletePlanPolygon>;
        updatePlanPolygon: BoundAction<typeof updatePlanPolygon>;
        patchFloorPlan: BoundAction<typeof patchFloorPlan>;
    };
}
interface IOwnProps {
    assignFloorModalState: number | null;
    setAssignFloorModalState: (state: number | null) => void;
    apiRef: RefObject<IFabricApi>;
    floors: IPlanFloor[];
    buildingPolygons: IBuildingPlanPolygon[] | null;
    buildingId: number;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {}

const AssignFloorModalC = (props: IProps) => {

    useEffect(() => {
        const polygonId = props.assignFloorModalState;
        if (polygonId == null || props.buildingPolygons == null) {
            return;
        }

        if (polygonId === -1) {
            props.assignFloorFormActions.replace({status: PlanPolygonPropertyStatus.UNAVAILABLE.toString()});
            return;
        }

        if (isCustomPolygonId(polygonId)) {
            const customId = fromCustomPolygonId(polygonId);
            const value = find(props.buildingPolygons, p => p.id === customId) as IBuildingPlanPolygon;
            props.assignFloorFormActions.replace({custom: true, name: value.name, property_count: value.property_count});
        }
        else {
            props.assignFloorFormActions.replace({custom: false, floor: polygonId.toString()});
        }
    }, [props.assignFloorModalState]);

    const onAssignFloorModalSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (props.apiRef.current == null || props.assignFloorModalState == null) {
            throw new Error(`onAssignPropertyModalSubmit: error state`);
        }
        const polygon = props.apiRef.current.getPolygon(props.assignFloorModalState);
        if (polygon == null) {
            throw new Error(`onAssignPropertyModalSubmit: no polygon with id ${props.assignFloorModalState}`);
        }
        const apiPolygon = map(polygon, point => [point.fractionX, point.fractionY] as [string, string]);
        const values = props.assignFloorFormValues;
        if (values.custom) { // custom form sent
            const planPolygonData: IPlanPolygon = {building: props.buildingId, polygon: apiPolygon, name: values.name, property_count: values.propertyCount};
            // decide on prev id
            if (isCustomPolygonId(props.assignFloorModalState)) { // prev is custom, so update that
                const customId = fromCustomPolygonId(props.assignFloorModalState);
                await props.actions.updatePlanPolygon(customId, planPolygonData);
                // there is nothing to clear
            }
            else { // prev is property or none (add polygon)
                const custom = await props.actions.createPlanPolygon(planPolygonData);
                const prevId = props.assignFloorModalState;
                if (prevId !== -1) { // edit - clear old assignment
                    await props.actions.patchFloorPlan(prevId, {plan_polygon: null});
                }
                props.apiRef.current.changeId(prevId, toCustomPolygonId(custom.id));
            }
        }
        else { // standard property form sent
            const formFloorIdStr = props.assignFloorFormValues.floor;
            const formFloorId = parseInt(formFloorIdStr, 10);
            if (!isFinite(formFloorId)) {
                throw new Error(`onAssignPropertyModalSubmit: cannot parse formFloorId from ${formFloorIdStr}`);
            }
            if (props.assignFloorModalState === formFloorId) { // edit without property assignment change
                return closeAssignFloorModal(); // close modal
            }
            // decide on prev id
            if (isCustomPolygonId(props.assignFloorModalState)) { // prev is custom
                const customId = fromCustomPolygonId(props.assignFloorModalState);
                await props.actions.patchFloorPlan(formFloorId, {plan_polygon: apiPolygon});
                await props.actions.deletePlanPolygon(customId);
                props.apiRef.current.changeId(toCustomPolygonId(customId), formFloorId);
            }
            else { // prev is property or none (add polygon)
                await props.actions.patchFloorPlan(formFloorId, {plan_polygon: apiPolygon});
                const prevId = props.assignFloorModalState;
                if (prevId !== -1) { // edit - clear old assignment
                    await props.actions.patchFloorPlan(prevId, {plan_polygon: null});
                }
                props.apiRef.current.changeId(prevId, formFloorId);
            }
        }
        closeAssignFloorModal();
    };

    const onAssignFloorModalClose = () => {
        if (props.assignFloorModalState === -1) { // new polygon drawn
            props.apiRef.current && props.apiRef.current.remove(props.assignFloorModalState);
        }
        closeAssignFloorModal();
    };

    const closeAssignFloorModal = () => {
        props.setAssignFloorModalState(null);
        props.assignFloorFormActions.clear();
    };

    return (
        <Modal
            modalState={!!props.assignFloorModalState}
            onModalClose={onAssignFloorModalClose}
            type="window"
            header="Przypisz piętro"
        >
            <AssignFloorForm
                values={props.assignFloorFormValues}
                errors={null}
                onChange={props.assignFloorFormActions.update}
                onSubmit={onAssignFloorModalSubmit}
                onClose={onAssignFloorModalClose}
                floors={props.floors}
                initialFloorId={props.assignFloorModalState!}
            />
        </Modal>
    );
};

export const AssignFloorModal: React.ComponentClass<IOwnProps> = connect(mapStateToProps, mapActionsToProps)(AssignFloorModalC);

/**
 * Connect
 */
function mapStateToProps(state: Store): IStateProps {
    return {
        assignFloorFormValues: state.plan.floor.assignFloorFormValues
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        assignFloorFormActions: bindActionCreators(assignFloorFormActions, dispatch),
        actions: bindActionCreators<Record<keyof IActionsProps["actions"], any>, IActionsProps["actions"]>({
            createPlanPolygon,
            deletePlanPolygon,
            updatePlanPolygon,
            patchFloorPlan
        }, dispatch)
    };
}
