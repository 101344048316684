import {Action} from "../interfaces/dispatch";
import {ErrorPageAction, FORCE_ERROR_STATE_DISABLE, FORCE_ERROR_PAGE_ENABLE, FORCE_BLANK_PAGE_ENABLE} from "../actions/error_page";


// implemented error page types
export enum ForcedPage {
    None,
    ErrorPage,
    BlankPage
}

export interface ErrorPageStore {
    forcedPage: ForcedPage;
    code: string | null;
    title: string | null;
    description: string | null;
    showBackButton: boolean;
}


const initialState: ErrorPageStore = {
    forcedPage: ForcedPage.None,
    code: null,
    title: null,
    description: null,
    showBackButton: true
};

export function errorPage(state: ErrorPageStore = initialState, action: Action): ErrorPageStore {
    switch (action.type) {
        case FORCE_ERROR_PAGE_ENABLE:
            const errAction = action as ErrorPageAction;
            return {
                ...state,
                forcedPage: ForcedPage.ErrorPage,
                code: errAction.code,
                title: errAction.title,
                description: errAction.description,
                showBackButton: errAction.showBackButton
            };
        case FORCE_BLANK_PAGE_ENABLE:
            return {...state, forcedPage: ForcedPage.BlankPage};
        case FORCE_ERROR_STATE_DISABLE:
            return initialState;
        default:
            return state;
    }
}
