import * as _ from "lodash";

import {Dict} from "./interfaces";


type ICmpFunction = (val1: any, val2: any) => boolean;
export const isPartiallyEqual = (value: Dict, other: Dict, keys: string[], cmp?: ICmpFunction): boolean => {
    if (cmp) {
        return _.isEqualWith(_.pick(value, keys), _.pick(other, keys), cmp);
    }
    else {
        return _.isEqual(_.pick(value, keys), _.pick(other, keys));
    }
};
export const containsOtherObject = (value: Dict, other: Dict, cmp?: ICmpFunction) => isPartiallyEqual(value, other, _.keys(other), cmp);


export interface Memoized {
    (...arg: any[]): any;
}

export const createMemoize = (fun: any) => {
    let memoArgs: any[];
    let memoRes: any;
    return (...args: any[]) => {
        if (!_.isEmpty(args) && _.isEqual(args, memoArgs)) {
            return memoRes;
        }
        memoArgs = args;
        memoRes = fun(...args);
        return memoRes;
    }
};

export const noop = (e: any) => {};
