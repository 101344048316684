import * as React from "react";
import File from "../../../shared/form/fields/file/File";
import {createForm, FormProps, HocForm} from "../../../shared/form/create_form";
import {Button} from "../../../shared/button/Button";
import {FormFile} from "../../../shared/form/fields/file/FormFile";


export const addPlanImageFormFields = {
    image: File
};

interface IOwnProps extends FormProps {
    isPending: boolean;
}

interface IProps extends IOwnProps {
    hoc: HocForm<typeof addPlanImageFormFields>;
}

export const AddImageForm = createForm<IOwnProps>()((props: IProps) => {

    const {fieldProps} = props.hoc.form;
    const fileMaxSize = 10 * 1024 ** 2;
    const fileAllowedExtensions = ["jpg", "jpeg", "png", "pdf"];

    return (
        <form onSubmit={props.onSubmit} className="df fd-col fai-center fjc-center">
            <FormFile
                {...fieldProps("image")}
                maxSize={fileMaxSize.toString()}
                groupClassName="image-field-file w100 mb-xxxl"
                allowedExtensions={fileAllowedExtensions}
            />

            <Button type="submit" color="success" size="lg">
                Zapisz zdjęcie
            </Button>
        </form>
    );
});
