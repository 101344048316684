import {Dispatch} from "redux";

import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {nphApiLink} from "../../project/route/nph_api_link";
import {getRequest, patchRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {ITagsOffer} from "./fetch_tags_offer_list";

const FETCH_TAGS_OFFER_DETAIL = "offer/tags-offers/FETCH_TAGS_OFFER_DETAIL";
export const fetchTagsOfferTypes = createRequestActionTypes(FETCH_TAGS_OFFER_DETAIL);

const PATCH_TAGS_OFFER_DETAIL = "offer/tags-offers/PATCH_TAGS_OFFER_DETAIL";
export const patchhTagsOfferTypes = createRequestActionTypes(PATCH_TAGS_OFFER_DETAIL);

export const fetchTagsOffer = (offerId: number) => (dispatch: Dispatch): Promise<ITagsOffer | null> => {
    dispatch({type: fetchTagsOfferTypes.start});
    const url = nphApiLink.offers.detail({offerId});
    return getRequest(url)
        .then((res: ITagsOffer) => {
            dispatch({type: fetchTagsOfferTypes.success, result: res});
            return res;
        })
        .catch(catch404(err => {
            dispatch({type: fetchTagsOfferTypes.error});
            return null;
        }));
};

export interface IOfferTagsData {
    tags: number[];
    banned_tags: number[];
    ban_all: boolean;
}

export const patchTagsOffer = (offerId: number, data: IOfferTagsData) => (dispatch: Dispatch): Promise<IOfferTagsData | null> => {
    dispatch({type: patchhTagsOfferTypes.start});
    const url = nphApiLink.offers.detail({offerId});
    return patchRequest(url, data)
        .then((res: IOfferTagsData) => {
            dispatch({type: patchhTagsOfferTypes.success, result: res});
            return res;
        })
        .catch(catch404(err => {
            dispatch({type: patchhTagsOfferTypes.error});
            return null;
        }));
};

