import {isArray, each} from "lodash";

import {RequestActionTypes, createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {
    Action,
    Dispatch, ErrorAction, LatestQueryAction, PaginatedResponse,
    PaginatedResponseAction
} from "../../../../../shared/ts/interfaces/dispatch";
import {getRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {Dictionary} from "../../../../../shared/ts/interfaces/structure";
import {PAGE_PARAM} from "../../../../../shared/ts/constants/queryParams";
import {IHeroAd} from "../../reducers/hero";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {addErrorAlert} from "../../../shared/alerts/actions";
import {nphApiLink} from "../../../project/route/nph_api_link";
import {appendQueryString} from "../../../../../shared/ts/helpers/append_query_string";

export const createHeroesFetch = (type: string, actionTypes: RequestActionTypes) => (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch<LatestQueryAction>({ type: actionTypes.start, latestQuery: queryObj });

    const urlString = appendQueryString(nphApiLink.heroAd.base(), queryObj);

    return getRequest(urlString)
        .then((json: PaginatedResponse<IHeroAd>) => {
            dispatch<PaginatedResponseAction<IHeroAd>>({
                type: actionTypes.success,
                results: json.results,
                pagination: {
                    pageSize: json.page_size,
                    total: json.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }
            });
            return json;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, type)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", type));
            }
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};
export const createHeroesDetailFetch = (type: string, actionTypes: RequestActionTypes) => (id: number) => (dispatch: Dispatch) => {
    const urlString = nphApiLink.heroAd.view({id});

    return getRequest(urlString)
        .then((json: IHeroAd) => {
            dispatch<Action>({
                type: actionTypes.success
            });
            return json;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};

export const HERO_FETCH = "hero/FETCH";
export const heroRequest = createRequestActionTypes(HERO_FETCH);
export const heroFetch = createHeroesFetch(HERO_FETCH, heroRequest);

export const HERO_FETCH_DETAIL = "hero/FETCH_DETAIL";
export const heroDetailRequest = createRequestActionTypes(HERO_FETCH_DETAIL);
export const heroFetchDetail = createHeroesDetailFetch(HERO_FETCH_DETAIL, heroDetailRequest);

export const invalidateHeroAdvertsList = () => ({type: heroRequest.reset});
