import {combineReducers} from "redux";

import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {campaignListContextPanel, ContextPanelState} from "../../context_panel/reducers/context_panel";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {RequestState, AppError} from "../../../../shared/ts/helpers/util";
import {Action} from "../../../../shared/ts/interfaces/dispatch";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";

import {heroRequest} from "../actions/hero/fetch";
import {filterHeroListFormTypes} from "../actions/hero/filter_hero_list";
import {addHeroFormTypes, CLEAR_HERO_ADD_EDIT_ERRORS, CLEAR_HERO_ADD_EDIT_VIEW_STATE, heroRequestTypes} from "../actions/hero/add";
import {HeroListFilterForm} from "../components/context_panel/hero/HeroListFilterForm";
import {HeroAdCreationForm} from "../../creation/hero/components/HeroAdCreationForm";

interface IHeroImage {
    stored: string;
    selected: string;
    id: string;
    base64value: string;
}

export interface IHeroFormValues {
    name: string;
    desktop_image: IHeroImage;
    mobile_image: IHeroImage;
}

export interface IHeroForms {
    request: RequestState;
    errors: AppError | null;
    form: IHeroFormValues;
}

export interface IHeroAd {
    create_date: string;
    id: number;
    name: string;
    user: {
        pk: number;
        name: string;
    };
    desktop_image: string;
    mobile_image: string;
    update_date: string;
    type: number;
}

export interface IHeroFilterForm {
    name: string;
    create_by: string;
}

export interface HeroStore {
    natives: IHeroAd[];
    pagination: PaginationState;
    fetch: {
        requestState: RequestState;
        errors: AppError | null;
        latestQuery: Dictionary<string>;
    };
    list: {
        contextPanel: ContextPanelState;
        filterForm: IHeroFilterForm;
    };
    detail: IHeroForms;
}

const clearAddEditView = (store: IHeroForms, action: Action): any => {
    switch (action.type) {
        case CLEAR_HERO_ADD_EDIT_VIEW_STATE:
            return {
                form: {
                    desktop_image: {
                        id: Math.random().toString(36).substr(2, 10),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    mobile_image: {
                        id: Math.random().toString(36).substr(2, 10),
                        stored:"",
                        selected:"",
                        base64value: null
                    },
                    name: '',
                },
                request: RequestState.None,
                errors: null
            };
        case CLEAR_HERO_ADD_EDIT_ERRORS:
            return {
                ...store,
                errors: null
            };
        default:
            return store;
    }
};

const hero = combineReducers({
    natives: reducePaginatedResponse(heroRequest),
    pagination: reducePagination(heroRequest),
    fetch: combineReducers({
        requestState: reduceRequestState(heroRequest),
        errors: reduceRequestErrors(heroRequest),
        latestQuery: reduceLatestQuery(heroRequest)
    }),
    list: combineReducers({
        contextPanel: campaignListContextPanel,
        filterForm: reduceFormData(filterHeroListFormTypes, HeroListFilterForm.fromJSON({}))
    }),
    detail: reduceReducers(combineReducers({
            request: reduceRequestState(heroRequestTypes),
            errors: reduceRequestErrors(heroRequestTypes),
            form: reduceFormData(addHeroFormTypes, HeroAdCreationForm.fromJSON({}))
        }),
        clearAddEditView
    )
});

export default hero;