import * as React from "react";


interface IProps {
    active?: boolean;
}

export const TagPoint = (props: IProps) => {
    const color = !props.active ? "#ff5a5f" : "#2dcaa7";

    return (
        <div className="bdrs-50p bdc-white bdw-md bds-solid" style={{height: "24px", width: "24px"}}>
            <div className="bdrs-50p" style={{height: "12px", width: "12px", backgroundColor: color, margin: "4px"}}/>
        </div>
    );
};
