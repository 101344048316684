import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";

interface PanelProps extends StyleProps {
    color?: string;
}

interface PanelHeaderProps extends StyleProps, PanelProps {}

interface PanelBodyProps extends StyleProps, PanelProps {}

interface PanelFooterProps extends StyleProps, PanelProps {}

export const Panel: React.SFC<PanelProps> = props => {
    const color = props.color && `panel-${props.color}`;
    const className = classnames("panel", color, props.className);

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

export const PanelHeader: React.SFC<PanelHeaderProps> = props => {
    const className = classnames("panel-heading", props.className);

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

export const PanelBody: React.SFC<PanelBodyProps> = props => {
    const className = classnames("p-lg", props.className);

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

export const PanelFooter: React.SFC<PanelFooterProps> = props => {
    const className = classnames("panel-footer", props.className);

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};
