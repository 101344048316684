import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";
import FlexScrollSection from "../../shared/layout/FlexScrollSection";

interface ContextPanelBodyProps extends StyleProps {}

export const ContextPanelBodyC: React.SFC<ContextPanelBodyProps> = props => {
    const className = classnames("", props.className);

    return (
        <FlexScrollSection className={className}>
            {props.children}
        </FlexScrollSection>
    );
};

const ContextPanelBody = ContextPanelBodyC;
export default ContextPanelBody;
