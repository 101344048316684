import {map} from "lodash";

import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {Vendor} from "../../sms/models/Vendor";
import {catch404} from "../../../../shared/ts/helpers/errors";
import {createDebouncedAction} from "../../common/create_debounced_request";
import {nphApiLink} from "../../project/route/nph_api_link";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";


const asyncVendorsSimple = (name: string): Promise<any> => {
    const urlString = appendQueryString(nphApiLink.vendors.list(), {name: name, page_size: 10});

    return getRequest(urlString).then((json: any) => {
        const vendors: Vendor[] = json.results;
        return {
            options: vendors.map((vendor: Vendor) => {
                return {
                    label: vendor.name,
                    value: vendor.pk
                };
            })
        };
    });
};
export const asyncVendors = createDebouncedAction(asyncVendorsSimple, 500);

const fetchVendor = (id: number): Promise<any> => {
    return getRequest(nphApiLink.vendors.detail({vendorId: id}))
        .then((vendor: Vendor) => {
            return vendor && {
                    label: vendor.name,
                    value: vendor.pk
                };
        })
        .catch(catch404(responseError => {
            return {
                label: id.toString(),
                value: id.toString()
            };
        }));
};

export const fetchVendors = (ids: number[]): Promise<any> => {
    const allPromises = map(ids, id => fetchVendor(id));
    return Promise.all(allPromises);
};
