import {combineReducers} from "redux";
import {AppError, RequestState} from "../../../../shared/ts/helpers/util";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {PaginationState} from "../../../../shared/ts/helpers/pagination";
import {
    ContextPanelState,
    tagsOfferListContextPanel
} from "../../context_panel/reducers/context_panel";
import {reducePaginatedResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_paginated_response";
import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {reduceLatestQuery} from "../../../../shared/ts/helpers/reduce_factory/reduce_latest_query";
import {reducePagination} from "../../../../shared/ts/helpers/reduce_factory/reduce_pagination";
import {reduceRequestErrors} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_errors";
import {reduceFormData} from "../../../../shared/ts/helpers/reduce_factory/reduce_form_data";
import {fromJson} from "../../shared/form/translate/from_json";
import {fetchTagsfferListTypes, ITagsOffer} from "../actions/fetch_tags_offer_list";
import {offerTagsListFormFields} from "../components/TagsOfferListFilterForm";
import {offerTagsListFormTypes} from "../actions/offer_tags_list_form";
import {reduceResponseWithReset} from "../../../../shared/ts/helpers/reduce_factory/reduce_response_with_reset";
import {fetchTagsOfferTypes} from "../actions/fetch_tags_offer_detail";
import {fetchAvailableTagsListTypes, IOfferTagItem} from "../actions/fetch_tags_list";
import {offerTagsDetailFormFields} from "../components/TagsForm";
import {offerTagsDetailFormTypes} from "../actions/offer_tags_form";


export interface ITagsOfferStore {
    offerList: {
        offers: ITagsOffer[];
        requestState: RequestState;
        latestQuery: Dictionary<string>;
        pagination: PaginationState;
        contextPanel: ContextPanelState;
        errors: AppError | null;
    };
    offerDetail: {
        offer: ITagsOffer | null;
        tags: IOfferTagItem[];
        tagsForm: Record<keyof typeof offerTagsDetailFormFields, any>;
    };
    offerListFormValues: Record<keyof typeof offerTagsListFormFields, any>;
}

export const tagsOfferReducer = combineReducers({
    offerList: combineReducers({
        offers: reducePaginatedResponse(fetchTagsfferListTypes),
        requestState: reduceRequestState(fetchTagsfferListTypes),
        latestQuery: reduceLatestQuery(fetchTagsfferListTypes),
        pagination: reducePagination(fetchTagsfferListTypes),
        contextPanel: tagsOfferListContextPanel,
        errors: reduceRequestErrors(fetchTagsfferListTypes)
    }),
    offerDetail: combineReducers({
        offer: reduceResponseWithReset(fetchTagsOfferTypes),
        tags: reduceResponseWithReset(fetchAvailableTagsListTypes),
        tagsForm: reduceFormData(offerTagsDetailFormTypes, fromJson(offerTagsDetailFormFields, {}))
    }),
    offerListFormValues: reduceFormData(offerTagsListFormTypes, fromJson(offerTagsListFormFields, {}))
});
