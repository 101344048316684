import {combineReducers} from "redux";
import {map} from "lodash";
import {reduceResponse} from "../../../../shared/ts/helpers/reduce_factory/reduce_response";
import {fetchOfferForTagGalleryTypes, ITagGalleryOffer} from "../actions/fetch_offer_for_tag_gallery";
import {reduceRequestState} from "../../../../shared/ts/helpers/reduce_factory/reduce_request_state";
import {RequestState} from "../../../../shared/ts/helpers/util";
import {patchOfferGalleryOrderTypes} from "../actions/patch_offer_gallery_order";
import {reduceReducers} from "../../../../shared/ts/helpers/reduce_factory/reduce_reducers";


export interface IGalleryTagsState {
    offer: ITagGalleryOffer | null;
    request: RequestState;
}

export interface IOfferStore {
    detail: {
        galleryTags: IGalleryTagsState;
    };
}

export const offerReducer = combineReducers({
    detail: combineReducers({
        galleryTags: combineReducers({
            offer: reduceReducers(
                reduceResponse(fetchOfferForTagGalleryTypes),
                customOfferReducer
            ),
            request: reduceRequestState(fetchOfferForTagGalleryTypes)
        })
    })
});


/**
 * Custom reducers
 */
function customOfferReducer(state: ITagGalleryOffer | null, action: {type: string; sortMap: Record<number, number>}): ITagGalleryOffer | null {
    if (action.type !== patchOfferGalleryOrderTypes.success) {
        return state;
    }
    if (state == null) {
        return state;
    }
    return {
        ...state,
        gallery: map(state.gallery, elem => ({...elem, sort: action.sortMap[elem.pk]})).sort((a, b) => a.sort - b.sort)
    };
}
