import {Canvas, Circle, ICanvasOptions, Polygon} from "fabric/fabric-impl";
import {fabric} from "fabric";
import {map} from "lodash";
import {initDraw} from "./init_draw";
import {initEdit} from "./init_edit";
import {initView} from "./init_view";


const FLOAT_PRECISION = 8;

export type IFullCircle = Circle & {id: number};
export type IFabricMode =    {type: "view"} |
                {type: "draw", finish: () => void} |
                {type: "edit", polygonId: number, finish: () => IFabricPolygon};
export type IFabricPolygon = {x: number; y: number}[];
type IFractionFabricPolygon = {fractionX: string; fractionY: string}[];
type IFullFabricPolygon = {x: number; y: number; fractionX: string; fractionY: string}[];

interface IFabricEvents {
    onDrawComplete: (polygon: IFullFabricPolygon) => void;
    onModeChange: (mode: IFabricMode) => void;
    onPolygonClick: (id: number) => void;
    onPolygonMouseOut: (id: number, polygon: Polygon, polygonTag: Circle) => void;
    onPolygonMouseOver: (id: number, polygon: Polygon, polygonTag: Circle) => void;
}
export interface IFabricApi {
    changeColor: (id: number, color: string) => void;
    changeId: (oldId: number, newId: number) => void;
    drawStart: (id?: number) => void;
    drawStop: () => void;
    editStart: (id: number) => void;
    editStop: () => IFullFabricPolygon;
    getPolygon: (id: number) => IFullFabricPolygon | null;
    printPolygon: (id: number, polygon: IFractionFabricPolygon, color: string) => void;
    remove: (id: number) => void;
}

export const initFabric = (canvasId: string, options: ICanvasOptions, events: Partial<IFabricEvents>): IFabricApi => {
    const canvas: Canvas = new fabric.Canvas(canvasId, options);
    canvas.selection = false;

    const _polygons: Record<number, IFabricPolygon> = {};
    let mode: IFabricMode = {type: "view"};
    const viewApi = initView(canvas, {
        onPolygonClick: id => events.onPolygonClick && events.onPolygonClick(id),
        onPolygonMouseOut: (id, polygon, polygonTag) => events.onPolygonMouseOut && events.onPolygonMouseOut(id, polygon, polygonTag),
        onPolygonMouseOver: (id, polygon, polygonTag) => events.onPolygonMouseOver && events.onPolygonMouseOver(id, polygon, polygonTag)
    });

    /**
     * Helper
     */

    const changeMode = (nextMode: IFabricMode) => {
        mode = nextMode;
        events.onModeChange && events.onModeChange(nextMode);
    };

    /**
     * API
     */

    return {
        changeColor: (id: number, color: string) => {
            if (mode.type !== "view") {
                throw new Error("changeColor: you need to be in view mode");
            }
            viewApi.changeColor(id, color);
        },
        changeId: (oldId: number, newId: number) => {
            if (mode.type !== "view") {
                throw new Error("changeId: you need to be in view mode");
            }
            if (oldId === newId) {
                return;
            }
            const polygon = _polygons[oldId];
            if (polygon == null) {
                throw new Error(`changeId: no polygon with ${oldId} ID`);
            }
            _polygons[newId] = polygon;
            delete _polygons[oldId];
            viewApi.changeId(oldId, newId);
        },
        drawStart: (id: number = -1) => {
            if (mode.type !== "view") {
                throw new Error("you need to be in view mode");
            }
            const onDrawFinish = (polygon: IFabricPolygon) => {
                // add polygon
                _polygons[id] = polygon;
                viewApi.addPolygon(id, polygon);
                changeMode({type: "view"});
                events.onDrawComplete && events.onDrawComplete(calculateFractionForPolygon(canvas, polygon));
            };
            // start polygon drawing
            const finish = initDraw(canvas, {onFinish: onDrawFinish});
            changeMode({type: "draw", finish});
        },
        drawStop: () => {
            if (mode.type !== "draw") {
                throw new Error("not in draw mode");
            }
            // break polygon drawing - save nothing
            mode.finish();
            changeMode({type: "view"});
        },
        editStart: (id: number) => {
            if (mode.type !== "view") {
                throw new Error("you need to be in view mode");
            }
            const polygon = _polygons[id];
            if (polygon == null) {
                throw new Error(`no polygon with ${id} ID`);
            }
            viewApi.removePolygon(id);
            const finish = initEdit(canvas, _polygons[id]);
            changeMode({type: "edit", polygonId: id, finish});
        },
        editStop: (): IFullFabricPolygon => {
            if (mode.type !== "edit") {
                throw new Error("not in edit mode");
            }
            // save polygon
            const polygon = mode.finish();
            _polygons[mode.polygonId] = polygon;
            viewApi.addPolygon(mode.polygonId, polygon);
            changeMode({type: "view"});
            return calculateFractionForPolygon(canvas, polygon);
        },
        getPolygon: (id: number): IFullFabricPolygon | null => {
            const polygon = _polygons[id];
            if (polygon == null) {
                return null;
            }
            return calculateFractionForPolygon(canvas, polygon);
        },
        printPolygon: (id: number, polygon: IFractionFabricPolygon, color: string): void => {
            const fullPolygon = calculateAbsoluteForPolygon(canvas, polygon);
            _polygons[id] = fullPolygon;
            viewApi.addPolygon(id, fullPolygon);
            viewApi.changeColor(id, color);
        },
        remove: (id: number) => {
            const polygon = _polygons[id];
            if (polygon == null) {
                throw new Error(`remove: no polygon with ${id} ID`);
            }
            viewApi.removePolygon(id);
            delete _polygons[id];
        }
    };
};

/**
 * Helper
 */

function calculateFractionForPolygon(canvas: Canvas, polygon: IFabricPolygon): IFullFabricPolygon {
    const width = canvas.getWidth();
    const height = canvas.getHeight();
    return map(polygon, ({x, y}) => ({
        x,
        y,
        fractionX: (x / width).toPrecision(FLOAT_PRECISION),
        fractionY: (y / height).toPrecision(FLOAT_PRECISION)
    }));
}

function calculateAbsoluteForPolygon(canvas: Canvas, polygon: IFractionFabricPolygon): IFullFabricPolygon {
    const width = canvas.getWidth();
    const height = canvas.getHeight();
    return map(polygon, ({fractionX, fractionY}) => ({
        x: parseFloat(fractionX) * width,
        y: parseFloat(fractionY) * height,
        fractionX,
        fractionY
    }));
}
