import {Middleware} from "../decorators/middleware";

declare function require(path: string): string;
declare var process: any;


interface BugsnagGlobal extends Window {
    Bugsnag: any;
}
export const bugsnagWindow = (<BugsnagGlobal> window);


let isInitialized = false;

export const initBugsnag = (apiKey: string): Middleware => () => {
    if (!isInitialized) {
        const bugsnag: any = require("../../js/bugsnag-4.min.js");

        const NODE_ENV = process.env.NODE_ENV;
        bugsnagWindow.Bugsnag = bugsnag({
            apiKey: apiKey,
            releaseStage: NODE_ENV,
            notifyReleaseStages: ["staging", "production"/*, "development"*/],
        });
        bugsnagWindow.Bugsnag.metaData = {
            developer: window.location
        };
        isInitialized = true;
    }
    return Promise.resolve(true);
};
