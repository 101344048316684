import * as React from "react";
import * as _ from "lodash";
import {reduxForm, FormProps} from "redux-form";

import {RequestState} from "../../../../shared/ts/helpers/util";
import Input from "../../shared/form/fields/input/Input";
import File from "../../shared/form/fields/file/File";
import Phone from "../../shared/form/fields/phone/Phone";
import {Button} from "../../shared/button/Button";
import {Panel, PanelHeader, PanelBody} from "../../shared/panel/Panel";
import {SelectAsync, Select} from "../../shared/form/fields/select/Select";
import {asyncOffers, fetchOffers} from "../../offer/actions/fetch";
import {asyncRegions, fetchRegions} from "../../region/actions/fetch";
import Icon from "../../shared/icon/Icon";
import DatePicker from "../../shared/form/fields/date_picker/DatePicker";
import DatePickerRange from "../../shared/form/fields/date_picker/DatePickerRange";
import TimePicker, {TimePickerRange} from "../../shared/form/fields/time_picker/TimePicker";
import Textarea from "../../shared/form/fields/textarea/Textarea";
import {Data} from "../../shared/cached_data";
import {Dispatch} from "../../../../shared/ts/interfaces/dispatch";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {createGenerateFieldProps} from "../../shared/form/generate_form_props";
import {offerRenderer} from "../../offer/helpers/offerRenderer";

export interface CampaignFormProps extends FormProps<Object, {}, {}> {
    onSubmit: (values: Object) => void;
    onValidate?: (values: Object) => void;
    onStatsFieldsUpdate: () => void;
    smsSender: (name: string) => void;
    request: RequestState;
    fieldErrors: Dictionary<string[]> | null;
    sendReminder: boolean | undefined;
    changeSendReminder: (event: React.FormEvent) => void;
}

export const CampaignFormName = "CampaignForm";
export const CampaignFormFields = {
    business_status: Select,
    status: Select,
    name: Input,
    offer: SelectAsync,
    regions: SelectAsync,
    sms_date: DatePicker,
    sms_time: TimePicker,
    opendays_date_range: DatePickerRange,
    opendays_time_range: TimePickerRange,
    sms_sender: Input,
    sms_text: Textarea,
    sms_notification_text: Textarea,
    slogan: Input,
    phone: Phone,
    highlight_description: Textarea,
    image: File,
    landing_page_url: Input,
    highlight_title: Input,
    address: Textarea,
    recipients_months_range: Input
};

const fileMaxSize = 10 * 1024 ** 2;
const fileAllowedExtensions = ["jpg", "jpeg", "png"];

export const formLabelsMap: Dictionary<string> = {
    status: "Status kampanii",
    business_status: "Status handlowy",
    name: "Nazwa kampanii",
    offer: "Inwestycja",
    regions: "Grupa docelowa",
    sms_date: "Data wysyłki SMS-a",
    sms_time: "Godzina wysyłki SMS-a",
    opendays_date_range: "Zakres dni otwartych",
    opendays_time_range: "Godziny dni otwartych",
    sms_text: "Treść SMS-a",
    sms_notification_text: "Treść SMS-a przypominającego",
    slogan: "Slogan",
    phone: "Telefon",
    highlight_description: "Opis",
    image: "Zdjęcie",
    landing_page_url: "Link do strony inwestycji",
    highlight_title: "Nagłówek",
    address: "Adres dni otwartych",
    sms_sender: "Nadawca",
    recipients_months_range: "Zakres grupy docelowej w miesiącach"
};


@reduxForm({form: CampaignFormName})
export class CampaignForm extends React.Component<CampaignFormProps, {}> {

    private submit?: React.EventHandler<any>;
    private validate?: React.EventHandler<any>;
    private onValueUpdate = (fieldName: string): void => {
        if (fieldName === "sms_sender") {
            this.props.smsSender("sms_sender");
        }
        return this.validate && this.validate(null);
    };
    private fieldProps = createGenerateFieldProps(this, {onValueUpdate: this.onValueUpdate});

    constructor(props: CampaignFormProps) {
        super(props);
        if (props.handleSubmit) {
            this.submit = props.handleSubmit((values: Object, dispatch: Dispatch) => {
                props.onSubmit(values);
            });
            this.validate = props.handleSubmit((values: Object) => {
                if (_.isFunction(this.props.onValidate)) {
                    this.props.onValidate(values);
                }
            });
        }
    }

    private onSmsUpdate = (fieldName: string) => {
        this.onValueUpdate(fieldName);
        this.props.onStatsFieldsUpdate();
    };

    private onRegionsUpdate = (fieldName: string) => {
        this.onValueUpdate(fieldName);
        this.props.onStatsFieldsUpdate();
    };

    public render(): JSX.Element {
        return (
            <form onSubmit={this.submit}>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df df-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="status" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Statusy
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <Select
                                            {...this.fieldProps("status")}
                                            label={formLabelsMap["status"]}
                                            options={Data.campaign_statuses.getSelectableOptions()}
                                        />
                                    </div>

                                    <div className="col-xs-6">
                                        <Select
                                            {...this.fieldProps("business_status")}
                                            label={formLabelsMap["business_status"]}
                                            // Dunno how to put those two value: string/number in line. They seem to be wrong.
                                            options={(Data.campaign_business_statuses.getOptions() as any)}
                                        />
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>

                    <div className="col-xs-12 col-lg-6 df">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df fd-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="main-data" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Dane podstawowe
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <Input
                                    {...this.fieldProps("name")}
                                    label={formLabelsMap["name"]}
                                    type="text"
                                    autoFocus
                                />

                                <SelectAsync
                                    {...this.fieldProps("offer")}
                                    label={formLabelsMap["offer"]}
                                    loadOptions={asyncOffers}
                                    fetchData={fetchOffers}
                                    optionRenderer={offerRenderer}
                                    clearable
                                    searchable
                                    showInputPlaceholder
                                    showAllValues
                                />

                                <SelectAsync
                                    {...this.fieldProps("regions")}
                                    onValueUpdate={this.onRegionsUpdate}
                                    label={formLabelsMap["regions"]}
                                    multi
                                    loadOptions={asyncRegions}
                                    fetchData={fetchRegions}
                                    clearable
                                    searchable
                                    showAllValues
                                    groupClassName="df fd-col"
                                />

                                <Input
                                    {...this.fieldProps("recipients_months_range")}
                                    label={formLabelsMap["recipients_months_range"]}
                                    type="number"
                                    onValueUpdate={this.onSmsUpdate}
                                />
                                <p className="help-text lh1-2 fs12 mb-lg">
                                    Grupa docelowa odbiorców dodanych do bazy w zdefiniowanym czasie - np. ostatnie 12 miesięcy.
                                </p>
                            </PanelBody>
                        </Panel>
                    </div>

                    <div className="col-xs-12 col-lg-6 df">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df fd-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="clock" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Czas trwania kampanii
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <DatePicker
                                            {...this.fieldProps("sms_date")}
                                            label={formLabelsMap["sms_date"]}
                                        />
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <TimePicker
                                            {...this.fieldProps("sms_time")}
                                            label={formLabelsMap["sms_time"]}
                                        />
                                    </div>
                                </div>

                                <DatePickerRange
                                    {...this.fieldProps("opendays_date_range")}
                                    label={formLabelsMap["opendays_date_range"]}
                                    groupClassName="label-nowrap"
                                />

                                <TimePickerRange
                                    {...this.fieldProps("opendays_time_range")}
                                    label={formLabelsMap["opendays_time_range"]}
                                    groupClassName="label-nowrap"
                                    upperHour={23}
                                />
                            </PanelBody>
                        </Panel>
                    </div>

                    <div className="col-xs-12 col-lg-6 df">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df fd-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="sms" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Wiadomości
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <Input
                                    {...this.fieldProps("sms_sender")}
                                    label={formLabelsMap["sms_sender"]}
                                    type="text"
                                    maxLength={11}
                                />

                                <Textarea
                                    {...this.fieldProps("sms_text")}
                                    className="sms-text-field"
                                    label={formLabelsMap["sms_text"]}
                                    onValueUpdate={this.onSmsUpdate}
                                    maxLength={400}
                                />

                                <div>
                                    <input id="smsReminder" type="checkbox" name="smsReminder"
                                           checked={this.props.sendReminder} onChange={this.props.changeSendReminder}
                                    />
                                    <label className="ml-sm" htmlFor="smsReminder">Wyślij SMS-a przypominającego</label>
                                </div>

                                {this.props.sendReminder && (
                                    <Textarea
                                        {...this.fieldProps("sms_notification_text")}
                                        className="sms-notification-text-field"
                                        onValueUpdate={this.onSmsUpdate}
                                        maxLength={400}
                                    />
                                )}
                            </PanelBody>
                        </Panel>
                    </div>

                    <div className="col-xs-12 col-lg-6 df">
                        <Panel color="default" className="df fd-col fg-1 fs-1 fb-a">
                            <PanelHeader className="fs22 fwl">
                                <div className="df fd-row fai-center">
                                    <div className="fg-0">
                                        <div className="icon-holder fs20 psr bdrs-50p">
                                            <Icon className="vhc" icon="globe" />
                                        </div>
                                    </div>

                                    <div className="fg-1 panel-header-text">
                                        Landing Page
                                    </div>
                                </div>
                            </PanelHeader>

                            <PanelBody>
                                <Input
                                    {...this.fieldProps("slogan")}
                                    label={formLabelsMap["slogan"]}
                                    type="text"
                                    maxLength={80}
                                />

                                <Phone {...this.fieldProps("phone")} label={formLabelsMap["phone"]} hideLabel />

                                <Input
                                    {...this.fieldProps("highlight_title")}
                                    label={formLabelsMap["highlight_title"]}
                                    type="text"
                                    maxLength={50}
                                />

                                <Textarea
                                    {...this.fieldProps("highlight_description")}
                                    className="highlight-description-field"
                                    label={formLabelsMap["highlight_description"]}
                                />
                                <p className="help-text lh1-2 fs12 mb-lg">
                                    Kliknięcie "Enter" w opisie powoduje tworzenie separatorów linii porządkujących tekst.
                                </p>

                                <File
                                    {...this.fieldProps("image")}
                                    label={formLabelsMap["image"]}
                                    maxSize={fileMaxSize}
                                    groupClassName="image-field-file"
                                    allowedExtensions={fileAllowedExtensions}
                                />

                                <Input
                                    {...this.fieldProps("landing_page_url")}
                                    label={formLabelsMap["landing_page_url"]}
                                    type="text"
                                />
                                <p className="help-text lh1-2 fs12 mb-lg">
                                    Strona internetowa inwestycji dostępna w Landing Page'u pod przyciskiem <em>"Zobacz stronę inwestycji"</em>.
                                    W przypadku niepodania adresu, przycisk kieruje do inwestycji na portalu rynekpierwotny.pl.
                                </p>

                                <Textarea
                                    {...this.fieldProps("address")}
                                    label={formLabelsMap["address"]}
                                />
                                <p className="help-text lh1-2 fs12 mb-lg">
                                    Lokalizacja dni otwartych. W przypadku niepodania adresu, domyślnie wyświetlony będzie adres biura sprzedaży.
                                </p>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>

                <Button className="dn" type="submit" submitting={this.props.request === RequestState.Waiting}>
                    Zapisz
                </Button>
            </form>
        );
    }
}
