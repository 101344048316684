export function getOfferTypeNamePlural(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkania";
        case OfferType.HOUSE:
            return "domy";
        case OfferType.COMMERCIAL:
            return "lokale użytkowe";
        case OfferType.NONE:
            return "";
        default:
            throw new Error("Unknown offer type");
    }
}

export enum OfferType {
    FLAT = 1,
    HOUSE = 2,
    COMMERCIAL = 3,
    NONE = 4
}


export function getOfferTypeNamePluralWhat(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkań";
        case OfferType.HOUSE:
            return "domów";
        case OfferType.COMMERCIAL:
            return "lokali użytkowych";
        default:
            throw new Error("Unknown offer type");
    }
}


export enum OfferPriceType {
    OFFER_PRICE_TYPE_NETTO = 1,
    OFFER_PRICE_TYPE_BRUTTO = 2
}

export const OfferPriceTypeOptions = [
    {value: "0", label: "-"},
    {value: "1", label: "NETTO"},
    {value: "2", label: "BRUTTO"}
];
