import {Dispatch} from "redux";
import {nphApiLink} from "../../project/route/nph_api_link";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";


interface IResult {
    count: number;
    results: IPlanProperty[];
}

export interface IPlanProperty {
    area: number;
    floor: number;
    floors: number;
    for_sale: boolean;
    number: string;
    gallery: [];
    is_reserved: boolean;
    pk: number;
    plan: string;
    plan_image: string;
    plan_polygon: [string, string][];
    rooms: number;
}

const FETCH_PLAN_PROPERTY_LIST = "offer-plans/properties/FETCH_PLAN_PROPERTY_LIST";
export const fetchPlanPropertyListTypes = createRequestActionTypes(FETCH_PLAN_PROPERTY_LIST);

export const fetchPlanPropertyList = (floorId: number) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPlanPropertyListTypes.start});
    const url = appendQueryString(nphApiLink.properties.list(), {floor: floorId, page_size: 1000});
    return getRequest(url)
        .then((res: IResult) => {
            dispatch({type: fetchPlanPropertyListTypes.success, results: res.results});
            return res.results;
        });
};

export const fetchPlanPropertyListByOffers = (offerIds: number[]) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPlanPropertyListTypes.start});
    const url = appendQueryString(nphApiLink.properties.list(), {offer: offerIds, page_size: 1000});
    return getRequest(url)
        .then((res: IResult) => {
            dispatch({type: fetchPlanPropertyListTypes.success, results: res.results});
            return res.results;
        });
};

export const resetPlanPropertyList = () => ({type: fetchPlanPropertyListTypes});
