import {each, isArray} from "lodash";

import {RequestActionTypes, createRequestActionTypes} from "../../../../shared/ts/helpers/create_request_action_types";
import {
    Dispatch, LatestQueryAction, PaginatedResponse,
    PaginatedResponseAction, ErrorAction, ResponseAction
} from "../../../../shared/ts/interfaces/dispatch";
import {getRequest} from "../../../../shared/ts/helpers/request_wrapper";
import {catch400, catch404} from "../../../../shared/ts/helpers/errors";
import {Dictionary} from "../../../../shared/ts/interfaces/structure";
import {PAGE_PARAM} from "../../../../shared/ts/constants/queryParams";

import {Campaign} from "../models/Campaign";
import {addErrorAlert} from "../../shared/alerts/actions";
import {enableErrorPage} from "../../../../shared/ts/actions/error_page";
import {appendQueryString} from "../../../../shared/ts/helpers/append_query_string";
import {nphApiLink} from "../../project/route/nph_api_link";


/**
 * Factories
 */

const createCampaignsFetch = (type: string, actionTypes: RequestActionTypes) => (queryObj: Dictionary<any>) => (dispatch: Dispatch) => {
    dispatch<LatestQueryAction>({ type: actionTypes.start, latestQuery: queryObj }); // TODO: are typings ok?

    const urlString = appendQueryString(nphApiLink.campaigns.base(), queryObj);
    return getRequest(urlString)
        .then((json: PaginatedResponse<Campaign>) => {
            dispatch<PaginatedResponseAction<Campaign>>({
                type: actionTypes.success,
                results: json.results,
                pagination: {
                    pageSize: json.page_size,
                    total: json.count,
                    page: parseInt(queryObj[PAGE_PARAM], 10)
                }
            });
            return json;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) { // non_field_errors found
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, type)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", type));
            }
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }));
};

export const createCampaignFetch = (actionTypes: RequestActionTypes) => (uuid: string) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.campaigns.view({id: uuid});
    return getRequest(urlString)
        .then((campaign: Campaign) => {
            dispatch<ResponseAction<Campaign>>({type: actionTypes.success, result: campaign});
            return campaign;
        })
        .catch(catch400(responseError => {
            dispatch<ErrorAction>({type: actionTypes.error, error: responseError.appError});
            return null;
        }))
        .catch(catch404(responseError => {
            dispatch(enableErrorPage("404"));
        }));
};

/**
 * List fetch
 */

export const CAMPAIGNS_FETCH = "campaigns/FETCH";
export const campaignsRequest = createRequestActionTypes(CAMPAIGNS_FETCH);
export const campaignsFetch = createCampaignsFetch(CAMPAIGNS_FETCH, campaignsRequest);
export const invalidateCampaignList = () => ({type: campaignsRequest.reset});
