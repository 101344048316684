import {prefixPath} from "../utils/prefix_path";

export const nphApiPath = prefixPath("/api/", {
    users: prefixPath("users/", {
        me: "me/",
        login: "login/",
        logout: "logout/"
    }),
    buildings: prefixPath("buildings/", {
        base: "",
        detail: ":buildingId(\\d+)/"
    }),
    campaigns: prefixPath("campaigns/", {
        base: "",
        detail: prefixPath(":id([-A-Za-z0-9]+)/", {
            base: "",
            sendTestSms: "send-test-sms/",
            validate: "validate/"
        }),
        stats: "stats/",
        validate: "validate/"
    }),
    floors: prefixPath("floors/", {
        base: "",
        detail: ":floorId(\\d+)/"
    }),
    natives: prefixPath("natives/", {
        base: "",
        detail: prefixPath(":id(\\d+)/", {
            base: "",
            validate: "validate/"
        })
    }),
    surprise: prefixPath("adverts/unveiled/", {
        base: "",
        detail: prefixPath(":id(\\d+)/", {
            base: "",
            validate: "validate/"
        })
    }),
    hero: prefixPath("adverts/hero/", {
        base: "",
        detail: prefixPath(":id(\\d+)/", {
            base: "",
            validate: "validate/"
        })
    }),
    tda: prefixPath("adverts/tda/", {
        base: "",
        detail: prefixPath(":id(\\d+)/", {
            base: "",
            validate: "validate/"
        })
    }),
    variables: "variables/",
    offers: prefixPath("offers/", {
        base: "",
        detail: prefixPath(":offerId(\\d+)/", {
            base: "",
            gallery: prefixPath("gallery/", {
                base: "",
                detail: prefixPath(":imageId(\\d+)/", {
                    base: "",
                    tags: prefixPath("tags/", {
                        base: "",
                        detail: ":tagId(\\d+)/"
                    })
                })
            })
        })
    }),
    planPolygons: prefixPath("plan_polygons/", {
        base: "",
        detail: ":planPolygonId(\\d+)/"
    }),
    properties: prefixPath("properties/", {
        base: "",
        detail: ":propertyId(\\d+)/"
    }),
    reels: prefixPath("reels/", {
        base: "",
        creator: prefixPath("creator/", {
            base: "",
            detail: ":creatorId(\\d+)/"
        }),
        reel: prefixPath("reel/", {
            base: "",
            detail: ":reelId(\\d+)/",
        })
    }),
    regions: prefixPath("regions/", {
        base: "",
        detail: ":id(\\d+)/"
    }),
    tags: prefixPath("tags/", {
        base: ""
    }),
    vendors: prefixPath("vendors/", {
        base: "",
        detail: prefixPath(":vendorId(\\d+)/", {
            base: "",
            group: prefixPath("group/", {
                base: "",
                detail: ":groupId(\\d+)/"
            })
        })
    }),
    members: prefixPath("members/", {
        base: "",
        detail: ":id(\\d+)/"
    })
});
