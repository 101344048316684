import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";
import TableColor from "./table_color";

interface TableRowProps extends StyleProps {
    color?: TableColor;
    onClick?: React.MouseEventHandler;
}

const TableRow: React.SFC<TableRowProps> = props => {
    const className = classnames(props.color, props.className);

    return <tr onClick={props.onClick} className={className}>{props.children}</tr>;
};

export default TableRow;
