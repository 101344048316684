import {each, isArray} from "lodash";

import {createRequestActionTypes, RequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {Dispatch, ErrorAction, ResponseAction} from "../../../../../shared/ts/interfaces/dispatch";
import {patchRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {IHeroAd} from "../../reducers/hero";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {HERO_ADD_PREFIX, heroRequestTypes} from "./add";
import {nphApiLink} from "../../../project/route/nph_api_link";

const createHeroEdit = (type: string, actionTypes: RequestActionTypes) => (id: number, jsonData: IHeroAd) => (dispatch: Dispatch) => {
    dispatch({type: actionTypes.start});

    const urlString = nphApiLink.heroAd.edit({id});
    return patchRequest(urlString, jsonData)
        .then((native: IHeroAd) => {
            dispatch<ResponseAction<any>>({type: actionTypes.success, result: native});
            dispatch(addSuccessAlert("Zapisano baner hero", type));
            return native;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, HERO_ADD_PREFIX)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", EDIT_HERO_PATCH));
            }
            dispatch<ErrorAction>({type: heroRequestTypes.error, error: responseError.appError});
            return null;
        }));
};

export const CLEAR_HERO_EDIT_VIEW_STATE = "hero/CLEAR_HERO_EDIT_VIEW_STATE";
export const clearHeroEditViewState = () => ({type: CLEAR_HERO_EDIT_VIEW_STATE});

const EDIT_HERO_PATCH = "hero/edit/EDIT_HERO_PATCH";
export const heroEditPatchTypes = createRequestActionTypes(EDIT_HERO_PATCH);
export const heroEditPatch = createHeroEdit(EDIT_HERO_PATCH, heroEditPatchTypes);
