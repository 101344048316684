import * as React from "react";
import * as classnames from "classnames";

import StyleProps from "../../../../shared/ts/interfaces/style";
import Loader from "../loader/Loader";
import {LoaderColor, LoaderSize} from "../loader/Loader";
import {Link} from "react-router";


type ButtonColor = "default" | "primary" | "success" | "info" | "warning" | "danger" | "link" | "primary-ghost";
type ButtonSize = "lg" | "sm" | "xs";
type ButtonType = "button" | "submit" | "reset";

interface IButtonProps extends StyleProps {
    type?: ButtonType;
    color?: ButtonColor;
    size?: ButtonSize;
    submitting?: boolean;
    loaderColor?: LoaderColor;
    loaderSize?: LoaderSize;
    loaderDelay?: number;
    onClick?: React.MouseEventHandler;
    href?: string;
    disabled?: boolean;
}

interface IButtonState {
    visibleLoader: boolean;
}

export class Button extends React.Component<IButtonProps, IButtonState> {

    public static defaultProps = {
        type: "button",
        loaderColor: "light",
        loaderDelay: 150
    };

    private loaderTimeout = 0;

    constructor(props: IButtonProps) {
        super(props);
        this.state = {visibleLoader: false};
    }

    public componentDidUpdate() {
        if (this.props.submitting) {
            this.showLoader();
        }
        else {
            this.hideLoader();
        }
    }

    /**
     * Helper
     */

    private showLoader = () => {
        if (this.loaderTimeout || this.state.visibleLoader) {
            return;
        }
        this.loaderTimeout = window.setTimeout(() => {
            this.loaderTimeout = 0;
            this.setState({visibleLoader: true});
        }, this.props.loaderDelay);
    };

    private hideLoader = () => {
        if (this.loaderTimeout) {
            window.clearTimeout(this.loaderTimeout);
            this.loaderTimeout = 0;
        }
        if (this.state.visibleLoader) {
            this.setState({visibleLoader: false});
        }
    };

    /**
     * Render
     */

    public render() {
        const props = this.props;
        // Button class names
        const className = classnames("btn", props.className, {
            [`btn-${props.color}`]: !!props.color,
            [`btn-${props.size}`]: !!props.size,
            "disabled": !!props.submitting || !!props.disabled
        });

        // Common props for link and button
        const buttonProps = {
            className
        };

        // Button original content
        let buttonContent = props.children;

        // Link styled like button
        if (props.href) {
            return <Link {...buttonProps} to={props.href}>{buttonContent}</Link>;
        }

        if (this.state.visibleLoader) {
            buttonContent = (
                <span>
                    <span className="h100 o0">{props.children}</span>
                    <Loader className="vhc" size={props.loaderSize} color={props.loaderColor}/>
                </span>
            );
        }

        return (
            <button {...buttonProps} type={props.type} onClick={props.onClick} disabled={props.disabled}>
                {buttonContent}
            </button>
        );
    }
}
