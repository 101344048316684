import * as React from "react";
import * as classnames from "classnames";
import StyleProps from "../../../../shared/ts/interfaces/style";


interface FieldLabelProps extends StyleProps {
    label?: string | undefined;
    htmlFor?: string | undefined;
}

const FieldLabel = (props: FieldLabelProps): JSX.Element => {
    const className = classnames("control-label", props.className);

    if (props.label) {
        return <label className={className} htmlFor={props.htmlFor}>{props.label}</label>;
    }

    return null as any;
};


export default FieldLabel;
