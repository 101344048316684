import { RequestActionTypes } from "../create_request_action_types";
import { RequestState } from "../util";
import {Action} from "../../interfaces/dispatch";


export const reduceRequestState = (requestTypes: RequestActionTypes) =>
    (state: RequestState = RequestState.None, action: Action): RequestState => {
        switch (action.type) {
            case requestTypes.start:
                return RequestState.Waiting;
            case requestTypes.success:
                return RequestState.Success;
            case requestTypes.error:
                return RequestState.Error;
            case requestTypes.reset:
                return RequestState.None;
            default:
                return state;
        }
    };
