import * as React from "react";
import {withRouter} from "react-router";
import {ITagsOffer} from "../actions/fetch_tags_offer_list";
import StyleProps from "../../../../shared/ts/interfaces/style";
import {RouterProps} from "../../../../shared/ts/interfaces/router";
import TableRow from "../../shared/table/TableRow";
import TableCell from "../../shared/table/TableCell";
import {url} from "../../../../shared/ts/helpers/routing";

interface IOwnProps {
    offer: ITagsOffer;
}

interface IProps extends IOwnProps, StyleProps, RouterProps {}


@withRouter
class TagsOfferListItemC extends React.Component<IProps, {}> {

    private onRowClick = () => this.props.router.push(url("app:in:offerTags:offer", {offer_id: this.props.offer.pk}));

    public render() {
        return (
            <TableRow className="curp" onClick={this.onRowClick}>
                <TableCell className="vam">
                    <div className="df fai-center">
                        <img className="mr-md" src={this.props.offer.main_image} width={90} height={50} />
                        <div className="df fd-col">
                            {this.props.offer.name}
                            <span className="fs12">({this.props.offer.type.name_plural})</span>
                        </div>
                    </div>
                </TableCell>

                <TableCell className="vam">
                    {this.props.offer.vendor.name}
                </TableCell>

                <TableCell className="vam c-gray">
                    {this.props.offer.address}
                </TableCell>
            </TableRow>
        );
    }
}

const TagsOfferListItem = TagsOfferListItemC as React.ComponentClass<IOwnProps>;
export default TagsOfferListItem;
