import * as React from "react";
import {Field} from "redux-form";
import StyleProps from "../../../../../../shared/ts/interfaces/style";
import DatePickerField from "./DatePickerField";


interface DatePickerProps extends StyleProps {
    id?: string;
    type?: string;
    name: string;
    label?: string;
    placeholder?: string;
    onValueUpdate?: React.EventHandler<any>;
    autoFocus?: boolean;
    disabled?: boolean;
    groupClassName?: string;
    popoverTargetOffset?: string;
    popoverAttachment?: string;
    popoverTargetAttachment?: string;
    minDate?: {};
    maxDate?: {};
}


export default class DatePicker extends React.Component<DatePickerProps, {}> {
    public render(): JSX.Element {
        const {id, className, ...restProps} = this.props;
        return (
            <Field
                {...restProps}
                component={DatePickerField}
                id={id}
                className={className}
            />
        );
    }
}
