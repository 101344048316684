import * as React from "react";

import BaseAlert from "./BaseAlert";


interface InfoAlertProps {
    onCloseClick: () => void;
}


const InfoAlert: React.SFC<InfoAlertProps> = props => {
    return (
        <BaseAlert color="info" icon="info-rounded" onCloseClick={props.onCloseClick}>{props.children}</BaseAlert>
    );
};

export default InfoAlert;
