import {prefixPath} from "../project/utils/prefix_path";

export const creationPreviewPaths = prefixPath("/preview-nph/", {
    nativeCreation: "native-creation/",
    monthlyCreation: "monthly-creation/",
    surpriseCreation: "surprise-creation/",
    imageAdCreation: "image-ad-creation/",
    heroAdCreation: "hero-creation/",
    tdaAdCreation: "tda-creation/"
});

export const creationPreviewLinks = {
    nativeCreation: createNoParamLink(creationPreviewPaths.nativeCreation),
    monthlyCreation: createNoParamLink(creationPreviewPaths.monthlyCreation),
    surpriseCreation: createNoParamLink(creationPreviewPaths.surpriseCreation),
    imageAdCreation: createNoParamLink(creationPreviewPaths.imageAdCreation),
    heroAdCreation: createNoParamLink(creationPreviewPaths.heroAdCreation),
    tdaAdCreation: createNoParamLink(creationPreviewPaths.tdaAdCreation)
}

/*
    Utils
 */
function createNoParamLink(path: string): () => string {
    return () => `${getRpUrl()}${path}`;
}

function getRpUrl() {
    const env = process as any && process.env;

    switch (env.RUN_ENV){
        case "staging":
            return "https://rynekpierwotny.top"
        case "development":
            return "https://local.rynekpierwotny.top:55120"
        default:
            return "https://rynekpierwotny.pl"

    }
}
