import {fabric} from "fabric";
import polylabel from "polylabel";
import {map} from "lodash";
import {Canvas, Circle, ICircleOptions, IEvent, Polygon} from "fabric/fabric-impl";
import {IFabricPolygon} from "./init_fabric";


interface IFabricViewApi {
    addPolygon: (id: number, points: IFabricPolygon) => void;
    changeColor: (id: number, color: string) => void;
    changeId: (oldId: number, newId: number) => void;
    removePolygon: (id: number) => void;
}
interface IViewEvents {
    onPolygonClick: (id: number) => void;
    onPolygonMouseOut: (id: number, polygon: Polygon, polygonTag: Circle) => void;
    onPolygonMouseOver: (id: number, polygon: Polygon, polygonTag: Circle) => void;
}

export const initView = (canvas: Canvas, events: IViewEvents): IFabricViewApi => {

    interface IPolygon extends Polygon {
        id: number;
    }
    interface ICircle extends Circle {
        id: number;
    }

    let _polygons: Record<number, IPolygon> = {};
    let _polygonTags: Record<number, ICircle> = {};

    function addPolygon(id: number, points: IFabricPolygon): void {
        const polygon: IPolygon = new fabric.Polygon(points, getPolygonOptions()) as IPolygon;
        polygon.set("id", id);
        canvas.add(polygon);
        _polygons[id] = polygon;
        addPolygonTag(id);
    }

    function addPolygonTag(id: number): void {
        const polygon = _polygons[id];
        const points = polygon.points!;
        const pointsFeed = [[...map(points, p => [p.x, p.y]), [points[0].x, points[0].y]]];
        const [x, y] = polylabel(pointsFeed);
        const polygonTag: ICircle = new fabric.Circle(getPolygonTagCircleOptions(x, y)) as ICircle;
        polygonTag.on("mousedown", onPolygonTagClickHandler);
        polygonTag.on("mouseout", onPolygonTagMouseOutHandler);
        polygonTag.on("mouseover", onPolygonTagMouseOverHandler);
        polygonTag.set("id", id);
        canvas.add(polygonTag);
        _polygonTags[id] = polygonTag;
    }

    function changeColor(id: number, color: string): void {
        const polygon = _polygons[id];
        polygon.set("fill", color);
        polygon.set("stroke", color);
        canvas.remove(polygon);
        canvas.add(polygon);
        const polygonTag = _polygonTags[id];
        canvas.remove(polygonTag);
        canvas.add(polygonTag);
    }

    function changeId(oldId: number, newId: number): void {
        // polygon
        const polygon = _polygons[oldId];
        polygon.set("id", newId);
        _polygons[newId] = polygon;
        delete _polygons[oldId];
        // polygon tag
        const polygonTag = _polygonTags[oldId];
        polygonTag.set("id", newId);
        _polygonTags[newId] = polygonTag;
        delete _polygonTags[oldId];
    }

    function removePolygon(id: number): void {
        const polygon = _polygons[id];
        polygon.off();
        canvas.remove(polygon);
        delete _polygons[id];
        removePolygonTag(id);
    }

    function removePolygonTag(id: number): void {
        const polygonTag = _polygonTags[id];
        polygonTag.off();
        canvas.remove(polygonTag);
        delete _polygonTags[id];
    }

    /**
     * Callback
     */

    const onPolygonTagClickHandler = (e: IEvent) => {
        const polygonTag = e.target as ICircle | undefined;
        if (polygonTag == null || polygonTag.id == null) {
            return;
        }
        events.onPolygonClick(polygonTag.id);
    };

    const onPolygonTagMouseOutHandler = (e: IEvent) => {
        const polygonTag = e.target as ICircle | undefined;
        if (polygonTag == null || polygonTag.id == null) {
            return;
        }
        const polygon = _polygons[polygonTag.id];
        events.onPolygonMouseOut(polygonTag.id, polygon, polygonTag);
    };

    const onPolygonTagMouseOverHandler = (e: IEvent) => {
        const polygonTag = e.target as ICircle | undefined;
        if (polygonTag == null || polygonTag.id == null) {
            return;
        }
        const polygon = _polygons[polygonTag.id];
        events.onPolygonMouseOver(polygonTag.id, polygon, polygonTag);
    };

    /**
     * API
     */

    return {addPolygon, changeColor, changeId, removePolygon};
};

/**
 * Helper
 */
export function getPolygonOptions() {
    return {
        stroke: "#00f",
        strokeWidth: 2,
        fill: "#00f",
        opacity: 0.4,
        evented: false,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        objectCaching: false
    };
}
export function getPolygonTagCircleOptions(left: number, top: number) {
    return {
        radius: 7,
        fill: "#FF5A5F",
        stroke: "#fff",
        strokeWidth: 1,
        left,
        top,
        evented: true,   // enable click to close polygon
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        objectCaching: false
    } as ICircleOptions;
}
