import Login from "../views/Login";
import Logout from "../views/Logout";


const authRoutes = [
    {
        path: "zaloguj/",
        component: Login,
        name: "login"
    },
    {
        path: "wyloguj/",
        component: Logout,
        name: "logout"
    }
];

export default authRoutes;
