import {Action} from "../../../../shared/ts/interfaces/dispatch";


export function openTab(actionType: string, tabIndex: number): Action & {tabIndex: number} {
    return {
        type: actionType,
        tabIndex
    };
}

export function openPanel(actionType: string): Action & {opened: boolean} {
    return {
        type: actionType,
        opened: true
    };
}

export function closePanel(actionType: string): Action & {opened: boolean} {
    return {
        type: actionType,
        opened: false
    };
}
