import * as React from "react";
import * as ReactDOM from "react-dom";
import {createStore, applyMiddleware, compose, combineReducers, Action} from "redux";
import {Provider} from "react-redux";
import {browserHistory, Router} from "react-router";
import {reducer as formReducer, FormReducer, FormStateMap} from "redux-form";
import * as Modal from "react-modal";

import {promiseMiddleware} from "../../../shared/ts/helpers/promiseMiddleware";
import {parseRouteConfig} from "../../../shared/ts/helpers/routing";
import {restoreSession} from "../../../shared/ts/helpers/auth";

import {nphAppRoutes} from "./route/nph_app_routes";
import appReducer from "./reducer";
import {logOut} from "../auth/actions/logout";

// parse paths
parseRouteConfig(nphAppRoutes);

// provide auth data from LocalStorage
restoreSession();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const finalCreateStore = composeEnhancers(
    // Middleware you want to use in development:
    applyMiddleware(promiseMiddleware(logOut()))
)(createStore);

const finalReducer = combineReducers({
    ...appReducer,
    form: formReducer as ((state: FormStateMap | undefined, action: Action) => FormStateMap & FormReducer)
});
const store = finalCreateStore(finalReducer);

ReactDOM.render(
    <Provider store={store}>
        <Router history={browserHistory} routes={nphAppRoutes}/>
    </Provider>,
    (document.getElementById as any)("root")
);

// Additional config
Modal.setAppElement("#root");
