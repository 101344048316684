import * as React from "react";
import * as hoistStatics from "hoist-non-react-statics";
import * as _ from "lodash";
import {connect} from "react-redux";

import {OldModal} from "../views/OldModal";
import {showModal, hideModal} from "../actions/modal";
import {guid} from "../helpers/utils";


export interface HocModal {
    modal: {
        show: (Modal: any, props: any, successCallback?: () => void, rejectCallback?: () => void) => void;
        hide: () => void;
    };
}

type OuterComponentProps = any;
interface OuterComponentState {
    ModalComponent: any;
    componentData: any;
}

// Store
export interface ModalCallbackParams {
    successCallback: () => void;
    rejectCallback: () => void;
}


export const modal = (holderClassName: string = "") => (InnerComponent: any): any => {

    @connect()
    class UniqueConfirm extends React.Component<OuterComponentProps, OuterComponentState> {

        private modalId: string = "";

        constructor(props: OuterComponentProps) {
            super(props);

            this.modalId = guid();

            this.state = { ModalComponent: () => <div></div>, componentData: null };
            this.show = this.show.bind(this);
            this.hide = this.hide.bind(this);
            this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
            this.onReject = this.onReject.bind(this);
        }

        /**
         * HOC
         */

        private show(ModalComponent: any, componentData: any, successCallback: () => void = () => {}, rejectCallback: () => void = () => {}): void {
            this.setState({ ModalComponent, componentData });
            // TODO: change this, do not keep functions in store
            // this decorator keeps there callbacks, but other use cases keep there `any` data
            this.props.dispatch!(showModal(this.modalId, { successCallback, rejectCallback }));
        }

        private hide(): void {
            this.props.dispatch!(hideModal(this.modalId));
        }

        /**
         * Helpers
         */

        private onSubmitConfirm(params: ModalCallbackParams): void {
            params.successCallback();
        }

        private onReject(params: ModalCallbackParams): void {
            params.rejectCallback();
        }

        /**
         * Render
         */

        public render(): JSX.Element {
            const hoc = _.assign({}, this.props.hoc, {
                modal: {
                    show: this.show,
                    hide: this.hide
                }
            });

            const { ModalComponent, componentData } = this.state;
            const modalProps = componentData && componentData.modalProps || {};

            // TODO: probably `hideModal` should not be default props for `ModalComponent`, I prefer explicit
            return (
                <span className={holderClassName}>
                    <OldModal
                        id={this.modalId}
                        onConfirm={this.onSubmitConfirm}
                        onReject={this.onReject}
                        {...modalProps}
                    >
                        <ModalComponent {...componentData} hideModal={this.hide} />
                    </OldModal>
                    <InnerComponent {...this.props} hoc={hoc}/>
                </span>
            );
        }
    }

    return hoistStatics(UniqueConfirm, InnerComponent);
};
