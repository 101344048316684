export const baseDomain = "rynekpierwotny.pl";


const domains = {
    production: {
        desktop: baseDomain,
        mobile: `m.${baseDomain}`
    },
    staging: {
        desktop: `dev.${baseDomain}`,
        mobile: `m-dev.${baseDomain}`
    },
    development: {
        desktop: "rp.localhost:8081",
        desktopSPA: "rp.localhost:9001",
        desktopApi: "rp.localhost:8081",
        mobile: "rpm.localhost:9001",
        mobileApi: "rpm.localhost:8081"
    }
};


export const rpSites = {
    production: {
        desktop: `https://${domains.production.desktop}`,
        mobile: `https://${domains.production.mobile}`
    },
    staging: {
        desktop: `https://${domains.staging.desktop}`,
        mobile: `https://${domains.staging.mobile}`
    },
    development: {
        desktop: `http://${domains.development.desktop}`,
        desktopSPA: `http://${domains.development.desktopSPA}`,
        desktopApi: `http://${domains.development.desktopApi}`,
        mobile: `http://${domains.development.mobile}`,
        mobileApi: `http://${domains.development.mobileApi}`
    }
};
