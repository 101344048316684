import * as React from "react";
import {withRouter} from "react-router";

import {RouterProps} from "../../../../shared/ts/helpers/decorators";


interface RedirectProps {
    path: string;
}


@withRouter
export default class Redirect extends React.Component<RedirectProps & RouterProps, {}> {

    public componentDidMount() {
        if (!this.props.path) {
            return console.error("Redirect component has no path defined");
        }
        this.props.router!.push(this.props.path);
    }

    public render() {
        return null;
    }
}
