import {each, isArray} from "lodash";

import {createRequestActionTypes} from "../../../../../shared/ts/helpers/create_request_action_types";
import {deleteRequest} from "../../../../../shared/ts/helpers/request_wrapper";
import {catch400} from "../../../../../shared/ts/helpers/errors";
import {Dispatch, ErrorAction} from "../../../../../shared/ts/interfaces/dispatch";
import {addErrorAlert, addSuccessAlert} from "../../../shared/alerts/actions";
import {invalidateReelsCreatorList} from "./fetch";
import {nphApiLink} from "../../../project/route/nph_api_link";

export const REEL_CREATOR_DELETE = "reel_creator/DELETE";
export const reelCreatorDeleteRequest = createRequestActionTypes(REEL_CREATOR_DELETE);

export const reelsCreatorDelete = (uuid: number) => (dispatch: Dispatch) => {
    dispatch({type: reelCreatorDeleteRequest.start});

    return deleteRequest(nphApiLink.reels.creator.delete({creatorId: uuid}))
        .then(() => {
            dispatch({type: reelCreatorDeleteRequest.success});
            dispatch(invalidateReelsCreatorList());
            dispatch(addSuccessAlert("Usunięto autora reelsów"));
            return true;
        })
        .catch(catch400(responseError => {
            const appError = responseError.appError;
            if (isArray(appError.alerts) && appError.alerts.length) {
                each(responseError.appError.alerts, (message: string) => dispatch(addErrorAlert(message, REEL_CREATOR_DELETE)));
            }
            else {
                dispatch(addErrorAlert("Popraw błędy formularza", REEL_CREATOR_DELETE));
            }
            dispatch<ErrorAction>({type: reelCreatorDeleteRequest.error, error: responseError.appError});
            return null;
        }));
};
