import * as React from "react";
import * as ReactSelect from "react-select";
import * as classnames from "classnames";
import {isFunction, isArray, isNumber, isEmpty, isString, reduce, find} from "lodash";
import FieldLabel from "../../FieldLabel";
import FieldError from "../../FieldError";
import {SelectOption} from "../../../../../../shared/ts/components/forms/select";
import {IFormFieldProps} from "../../create_form";


interface IProps extends IFormFieldProps<null | string | string[] | SelectOption | SelectOption[]> {
    creatable: boolean;
    async: boolean;
    label?: string;
    options: SelectOption[];
    menuUp?: boolean;
    fetchData?: (ids: number[]) => Promise<any>;
    multi: boolean;
    hideLabel?: boolean;
    groupClassName?: string;
    className?: string;
    // select props
    showAllValues?: boolean;
    showInputPlaceholder?: boolean;
    loadOptions?: (name: string, limit?: number) => Promise<any>;
    // getOption?: (id: string) => Promise<any>;
    resultLimit?: number;
    optionsLimit?: number;
}
interface IState {
    isLoading?: boolean;
    loadingValues?: number[] | number;
    triggerOnValueUpdate?: boolean;
}


export class FormSelect extends React.Component<IProps, IState> {
    public static defaultProps = {
        clearable: false,
        searchable: false,
        ignoreAccents: false
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
            loadingValues: [],
            triggerOnValueUpdate: false
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        const {async} = this.props;
        // if (prevProps.input === this.props.input) {
        //     return;
        // }
        if (async) {
            if (this.state.isLoading) {
                return;
            }
            // if (prevProps.input === this.props.input) {
            //     return;
            // }

            // async expects store values to be objects or array of objects
            // we check for values that are number or array of numbers to fetch those data
            if (!isFunction(this.props.fetchData)) { // TODO: maybe move to types-check
                console.error(`SelectField: ${name} has no fetchData action`);
                return;
            }
            if (isArray(this.props.value)) {
                // we need to fetch number values to make proper options from them
                const numberValues = reduce(this.props.value, (acc, v) => isNumber(v) ? [...acc, v] : acc, []) as number[];
                if (isEmpty(numberValues) === false) {
                    this.setState({isLoading: true, loadingValues: numberValues});

                    this.props.fetchData(numberValues).then((objValues: SelectOption[]) => {
                        this.props.onChange(this.props.name, objValues);
                        this.setState({isLoading: false});
                    });
                }
            }
            else {
                if (isNumber(this.props.value)) {
                    this.setState({isLoading: true, loadingValues: this.props.value});
                    this.props.fetchData([this.props.value]).then((objValues: SelectOption[]) => {
                        this.props.onChange(this.props.name, objValues[0]);
                        this.setState({isLoading: false});
                    });
                }
            }
        }

        if (this.state.triggerOnValueUpdate) {
            this.props.onAfterChange(name, this.props.value);
            this.setState({triggerOnValueUpdate: false});
        }
    }

    /**
     * Callback
     */

    private onChange = (value: SelectOption | SelectOption[]): void => {
        const {multi, async} = this.props;
        const isEmptyValue = value == null || (isArray(value) && isEmpty(value));

        // resets on clicking X
        if (value === null) {
            this.props.onChange(this.props.name, "");
            return this.setState({triggerOnValueUpdate: true});

        }
        if (!this.props.value && isEmptyValue) {
            return;
        }

        if (multi) {
            this.props.onChange(this.props.name, isEmptyValue ? [] : value);
            return this.setState({triggerOnValueUpdate: true});

        }
        else {
            if (async) {
                this.props.onChange(this.props.name, isEmptyValue ? null : value);
                return this.setState({triggerOnValueUpdate: true});

            }
            else {
                this.props.onChange(this.props.name, isEmptyValue ? null : (value as SelectOption).value);
                return this.setState({triggerOnValueUpdate: true});

            }
        }
    };

    /**
     * Helper
     */

    private getOptionByValue = (value: string | number | (string | number)[], options: SelectOption[], isMulti: boolean) => {
        if (isArray(value)) {
            if (isMulti) {
                const isEmptyValue = value == null || (isArray(value) && isEmpty(value));
                return isEmptyValue ? [] : value;
            }
            // translate all strings/numbers to `SelectOption`
            const foundOptions = reduce(value, (acc: SelectOption[], s: string) => {
                if (!isString(s) && !isNumber(s)) {
                    return acc;
                }
                const option = find(this.props.options, (o: SelectOption) => o.value.toString() === s.toString());
                return option ? [...acc, option] : acc;
            }, []);
            return foundOptions;
        }
        else {
            if (isString(value) || isNumber(value)) {
                const option = find(this.props.options, (o: SelectOption) => o.value.toString() === value.toString());
                if (option) {
                    return option;
                }
                else {
                    // select needs object {value, label} as its value to property display option
                    if (this.props.creatable) {
                        return {value: value.toString(), label: value.toString()};
                    }
                }
            }
        }
    };

    private getAsyncOptionByValue = (value: any) => value === "" ? null : value;

    /**
     * Render
     */

    private renderSelect = (): JSX.Element => {
        const {async, className, ...restProps} = this.props;
        const fieldClassName = classnames("", className);
        const valueOption = async ? this.getAsyncOptionByValue(this.props.value) : this.getOptionByValue(this.props.value as any, this.props.options, restProps.multi);
        const selectProps = {
            placeholder: "Wybierz",
            noResultsText: "Nie znaleziono wyników",
            searchPromptText: "Zacznij pisać żeby zobaczyć wyniki",
            loadingPlaceholder: "Ładowanie...",
            ...restProps,
            name,
            value: this.state.isLoading ? null : valueOption,
            isLoading: this.state.isLoading ? true : undefined,
            inputProps: {id: name},
            openOnFocus: true,
            className: fieldClassName,
            onChange: this.onChange,
            tabSelectsValue: false,
            // These 2 properties are responsible for cleaning input on blur when you were typing
            // Michał, do you know by any chance how to cast this? Or if this should be removed because is a dirty hack? ;p
            ref: name
        };

        if (async) {
            return <ReactSelect.Async {...selectProps} />;
        }
        return <ReactSelect {...selectProps} />;
    };

    public render(): JSX.Element {
        const {error, label, groupClassName, menuUp, hideLabel, options, optionsLimit, value} = this.props;
        const holderClassName = classnames("psr form-group", groupClassName, error && "has-error", {"menu-outer-top": !!menuUp});
        const className = classnames({"hidden": !error});
        const errorField = error ? error.join("\n") : "";
        const limitExceeded = optionsLimit && options.length === 0 && (value != null && isArray(value) && value.length) === optionsLimit;
        const limitExceededText = <span style={{color: "green"}}>Inwestycja zawiera już maksymalną liczbę (6) tagów.</span>;
        return (
            <div className={holderClassName}>
                {!hideLabel && label && <FieldLabel label={label} htmlFor={this.props.name} />}
                <FieldError error={errorField} className={className}><span>{this.renderSelect()}</span></FieldError>
                {limitExceeded && limitExceededText}
            </div>
        );
    }
}
