import {url} from "../../../shared/ts/helpers/routing";
import {NativeCreationListView} from "./native/views/NativeCreationListView";
import {NativeCreationAddView} from "./native/views/NativeCreationAddView";
import {NativeCreationEditView} from "./native/views/NativeCreationEditView";
import {MonthlyCreationListView} from "./monthly/views/MonthlyCreationListView";
import {MonthlyCreationAddView} from "./monthly/views/MonthlyCreationAddView";
import {MonthlyCreationEditView} from "./monthly/views/MonthlyCreationEditView";
import {ImageAdCreationListView} from "./image_ad/views/ImageAdCreationListView";
import {ImageAdCreationAddView} from "./image_ad/views/ImageAdCreationAddView";
import {ImageAdCreationEditView} from "./image_ad/views/ImageAdCreationEditView";
import {SurpriseAdCreationAddView} from "./surprise/views/SurpriseAdCreationAddView";
import {SurpriseAdCreationListView} from "./surprise/views/SurpriseAdCreationListView";
import {SurpriseAdCreationEditView} from "./surprise/views/SurpriseAdCreationEditView";
import {HeroAdCreationListView} from "./hero/views/HeroAdCreationListView";
import {HeroAdCreationAddView} from "./hero/views/HeroAdCreationAddView";
import {HeroAdCreationEditView} from "./hero/views/HeroAdCreationEditView";
import {TdaAdCreationAddView} from "./tda/views/TdaAdCreationAddView";
import {TdaAdCreationListView} from "./tda/views/TdaAdCreationListView";
import {TdaAdCreationEditView} from "./tda/views/TdaAdCreationEditView";


export const creationFrontendRoute = {
    path: "kreacje-reklamowe/",
    name: "creation",
    indexRoute: {
        onEnter: (nextState: any, replace: any) => {
            replace(
                url("app:in:creation:native:list")
            );
        }
    },
    childRoutes: [
        {
            path: "reklama-natywna/",
            name: "native",
            childRoutes: [
                {
                    path: "lista/",
                    name: "list",
                    component: NativeCreationListView
                },
                {
                    path: "dodaj/",
                    name: "add",
                    component: NativeCreationAddView
                },
                {
                    path: ":id/",
                    name: "edit",
                    component: NativeCreationEditView
                }
            ]
        },
        {
            path: "inwestycja-miesiaca/",
            name: "monthly",
            childRoutes: [
                {
                    path: "lista/",
                    name: "list",
                    component: MonthlyCreationListView
                },
                {
                    path: "dodaj/",
                    name: "add",
                    component: MonthlyCreationAddView
                },
                {
                    path: ":id/",
                    name: "edit",
                    component: MonthlyCreationEditView
                }
            ]
        },
        {
            path: "reklama-obrazkowa/",
            name: "image",
            childRoutes: [
                {
                    path: "lista/",
                    name: "list",
                    component: ImageAdCreationListView
                },
                {
                    path: "dodaj/",
                    name: "add",
                    component: ImageAdCreationAddView
                },
                {
                    path: ":id/",
                    name: "edit",
                    component: ImageAdCreationEditView
                }
            ]
        },
        {
            path: "reklama-niespodzianka/",
            name: "surprise",
            childRoutes: [
                {
                    path: "lista/",
                    name: "list",
                    component: SurpriseAdCreationListView
                },
                {
                    path: "dodaj/",
                    name: "add",
                    component: SurpriseAdCreationAddView
                },
                {
                    path: ":id/",
                    name: "edit",
                    component: SurpriseAdCreationEditView
                }
            ]
        },
        {
            path: "reklama-hero/",
            name: "hero",
            childRoutes: [
                {
                    path: "lista/",
                    name: "list",
                    component: HeroAdCreationListView
                },
                {
                    path: "dodaj/",
                    name: "add",
                    component: HeroAdCreationAddView
                },
                {
                    path: ":id/",
                    name: "edit",
                    component: HeroAdCreationEditView
                }
            ]
        },
        {
            path: "reklama-tda/",
            name: "tda",
            childRoutes: [
                {
                    path: "lista/",
                    name: "list",
                    component: TdaAdCreationListView
                },
                {
                    path: "dodaj/",
                    name: "add",
                    component: TdaAdCreationAddView
                },
                {
                    path: ":id/",
                    name: "edit",
                    component: TdaAdCreationEditView
                }
            ]
        },
    ]
};
