import * as React from "react";
import {Button} from "../../../../shared/button/Button";
import {Input} from "../../../../../../shared/ts/components/forms/input";
import {ElementClasses, Form, FormProps} from "../../../../../../shared/ts/components/form";

interface IProps extends FormProps {
    className?: string;
    clearForm: () => void;
}

interface IState {}

export class ReelsCreatorListFilterForm extends Form<IProps, IState> {
     public static elementClasses: ElementClasses = {
        "name": {
            type: Input,
            label: "Nazwa"
        }
    };

    constructor(props: IProps) {
        super(props);
    }

    public componentDidMount(): void {
        this.props.updateFormAction(this.getDefaultValues());
    }

    private getDefaultValues = () => ({
        name: ""
    });

    private onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.onSubmit(ReelsCreatorListFilterForm.toFormData(this.props.values));
    };

    public render() {
        return (
            <div className="pv-md ph-xl fg-1">
                <form>
                    <Input {...this.generateProps("name")} onBlur={this.onSubmit}/>

                    <div className="tac">
                        <div>
                            <Button
                                color="success"
                                size="lg"
                                submitting={false}
                                type={"submit"}
                                onClick={this.onSubmit}
                            >
                                Wyszukaj
                            </Button>
                        </div>
                        <div>
                            <Button color="link" onClick={this.props.clearForm}>
                                wyczyść pola
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
